
/** This module creates a provider for the AppAuthService that switches between
 *      the real service and the mock service and makes sure it is a singleton.
 *  @module
 */
import { MockAppAuthService } from 'utils/services/MockAppAuthService'
import { AppAuthService } from 'utils/services/AppAuthService'
import { EmbedAppAuthService } from 'utils/services/EmbedAppAuthService'
import { IS_EMBEDDED } from 'components/enums/QueryParams';

/** the one and only one instance of the AppAuthService. */
let singleton: AppAuthService;

/** this class creates either the real auth service or mock auth service depending on 
 *  whether we are in test mode. */
export class AuthServiceProvider {
    /** returns the singleton auth service.
     *  @returns the AppAuthService singleton. */
    static getService() : AppAuthService {
        const isRunbookViewRoute = window.location.pathname.includes('runbook-details');
        const isOnRouteWithReverseProxyAuth = IS_EMBEDDED && isRunbookViewRoute;

        // Handle switching between app services
        // @ts-expect-error
        if (isOnRouteWithReverseProxyAuth && !singleton?.isEmbed) {
            singleton = new EmbedAppAuthService();
        }

        // @ts-expect-error
        if (!isOnRouteWithReverseProxyAuth && singleton?.isEmbed) {
            singleton = new AppAuthService();
        }

        if (singleton) {
            return singleton;
        }

        if (process.env.REACT_APP_INTEGRATION_TESTING || process.env.NODE_ENV === 'test') {
            singleton = new MockAppAuthService();
        } else if (isOnRouteWithReverseProxyAuth) {
             singleton = new EmbedAppAuthService();
        } 
        else {
            singleton = new AppAuthService();
        }

        return singleton;
    }
}
