/** This module defines a React component that displays the network_device search result.
 *  @module
 */
import React from "react";
import { SearchResultsItemProps } from "../search-results-item/SearchResultsItem";
import { SearchItemHeader } from "../../search-item-header/SearchItemHeader";
import { LinkInfo, LinksGroup, LinkType } from "../../search-links-group/LinksGroup";
import { SearchItemDesc } from "../../search-item-desc/SearchItemDesc";
import { STRINGS } from "app-strings";
import { useUserPreferences } from "utils/hooks";
import { HIDDEN_STATES, INCIDENT_STATUS, PRIORITY } from "components/enums";
import { DEFAULT_SEARCH_PREF, SearchPreference } from "utils/services/UserPrefsTypes";
import { 
    getDeviceFacetForEngine, getFacetValue, getPriorityFacetForEngine, getStatusFacetForEngine 
} from "pages/incident-search/IncidentSearchUtils";
import { FACET_FIELDS } from "utils/services/SearchApiService";

/** Renders the search result for the network device.
 *  @param data the properties passed in.
 *  @returns JSX with the network device search result react component.*/
export function SearchNetworkDeviceItem({ data }: SearchResultsItemProps) {
    const userPreferences = useUserPreferences({
        listenOnlyTo: {search: {}}
    });
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};

    const links: Array<LinkInfo> = [
        {
            path: "incident-search",
            label: STRINGS.SEARCH.viewIncidents,
            type: LinkType.PAGE,
            params: {
                searchType: "incident",
                facets: {
                    [getDeviceFacetForEngine(searchPreferences.srchEngine)]: [data.formattedData.name],
                    [getPriorityFacetForEngine(searchPreferences.srchEngine)]: [
                        getFacetValue(PRIORITY.CRITICAL, searchPreferences.srchEngine), getFacetValue(PRIORITY.HIGH, searchPreferences.srchEngine), 
                        getFacetValue(PRIORITY.MODERATE, searchPreferences.srchEngine), getFacetValue(PRIORITY.LOW, searchPreferences.srchEngine)
                    ],
                    [getStatusFacetForEngine(searchPreferences.srchEngine)]: [
                        INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING, INCIDENT_STATUS.ON_HOLD, 
                        INCIDENT_STATUS.DISMISSED, INCIDENT_STATUS.CLOSED
                    ].filter(status => !HIDDEN_STATES.includes(status)).map(state => getFacetValue(state, searchPreferences.srchEngine))
                }
            }
        },
        {
            path: "incident-search",
            label: STRINGS.SEARCH.details,
            type: LinkType.PAGE,
            params: {
                searchType: "device",
                facets: {
                    [FACET_FIELDS.IP_ADDRESS]: [data.device.ipaddr]
                },
                inspectorBladeOpen: true
            }
        }
    ];
    return <div className="d-flex flex-column flex-grow-2">
        <SearchItemHeader type={data.type} title={data.formattedData.title ? data.formattedData.title : ""} />
        <SearchItemDesc>
            {data.device.name} ({data.device.ipaddr})
            {data.device?.location?.name ? " " +  STRINGS.SEARCH.locationText + " " + data.device.location.name : ""}
            {data.device?.reportedBy?.length ? " " +  STRINGS.SEARCH.seenOnText + " " + data.device.reportedBy.join(", ") : ""}
        </SearchItemDesc>
        <LinksGroup links={links} />
    </div>
}
