import { SEVERITY, SEVERITY_INDEX } from 'components/enums';
import React from 'react';
//import { Feature, Position } from 'geojson';

type Feature = any;
type Position = any;

export interface markerSupportedOptions {
    severity?: SEVERITY;
    selected?: boolean;
    hovered?: boolean;
    important?: boolean;
}
export interface locationMarkerProps {
    id: string;
    coordinate: Position;
    properties?: markerSupportedOptions;
}

// This component acts as a carrier for mapbox format feature's config. Rendering of
// this item in UI will be performed by mapbox API. Each feature will be converted to
// an appropriate UI item by mapbox on canvas using the paint config that's passed by
// the wrapping layer.
function MapFeature (props: Feature) {
    return <></>;
}

function LocationMarker (props: locationMarkerProps) {
    let markerImage = "marker-black";
    if (props.properties?.severity === SEVERITY.CRITICAL) {
        markerImage = "marker-red";
    } else if (props.properties?.severity === SEVERITY.DEGRADED) {
        markerImage = "marker-yellow";
    } else if (props.properties?.severity === SEVERITY.NORMAL) {
        markerImage = "marker-darkblue";
    }
    const markerProperties = {
        id: props.id,
        markerImage: markerImage,
        severity: props.properties?.severity || SEVERITY.UNKNOWN,
        severityIndex: SEVERITY_INDEX[props.properties?.severity || SEVERITY.UNKNOWN],
        selected: props.properties?.selected || false,
        hovered: props.properties?.hovered || false,
        important: props.properties?.important || false,
    };

    return <MapFeature
        type="Feature"
        id={props.id}
        properties={markerProperties}
        geometry={{
            type: "Point",
            coordinates: props.coordinate
        }}
    />;
}

export { LocationMarker };
