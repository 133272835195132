/** This module contains all the operators for the decision branch and logic nodes.
 *  @module
 */

import { STRINGS } from 'app-strings';
import { Operator } from 'components/common/compare-control/CompareControl';

export const enum Operators {
    gt = "gt",
    lt = "lt",
    gteq = "gteq",
    lteq = "lteq",
    eq = "eq",
    neq = "neq",
    eqAny = "eqAny",
    neqAny = "neqAny",
    seq = "seq",
    sneq = "sneq",
    oneOf = "oneOf",
    nOneOf = "nOneOf",
    cont = "cont",
    nCont = "nCont",
    regex = "regex",
    max = "max",
    min = "min",
    avg = "avg",
    _blank_ = "_blank_",
    changegt = "changegt",
    incgt = "incgt",
    decgt = "decgt",
    exist = "exist",
    nexist = "nexist",
    normal = "normal",
    nnormal = "nnormal",
    expectedhighlt = "expectedhighlt",
    expectedhighgt = "expectedhighgt",
    expectedlowlt = "expectedlowlt",
    expectedlowgt = "expectedlowgt",
    // These operations will be supported by Runbook Orchestrator but
    // UI will expose them only post-GA if they become necessary
    changelt = "changelt",
    inclt = "inclt",
    declt = "declt",
}

export type OperatorType = {
    [key in Operators]: Operator
}

// Key Operator object that Compare Control Operator format   This is used for 
// keys, variables and http keys.
export const KeyOperators: Partial<OperatorType> = {
    seq: {
        id: "seq",
        label: STRINGS.runbookEditor.operators.equals,
        alt: "EQ",
        types: ["string", "ipaddr", "statuscode", "timestamp", "property", "auth_profile", "alluvio_edge"]
    },
    sneq: {
        id: "sneq",
        label: STRINGS.runbookEditor.operators.nequals,
        alt: "NOT-EQ",
        types: ["string", "ipaddr", "statuscode", "property", "auth_profile", "alluvio_edge"]
    },
    eqAny: {
        id: "eqAny",
        label: STRINGS.runbookEditor.operators.equalsAny,
        alt: "EQ-ANY",
        types: ["string", "ipaddr", "statuscode"]
    },
    neqAny: {
        id: "neq",
        label: STRINGS.runbookEditor.operators.nequalsAny,
        alt: "NOT-EQ-ANY",
        types: ["string", "ipaddr", "statuscode"]
    },
    cont: {
        id: "cont",
        label: STRINGS.runbookEditor.operators.cont,
        alt: "CONTAINS",
        types: ["string", "ipaddr", "array"]
    },
    nCont: {
        id: "nCont",
        label: STRINGS.runbookEditor.operators.nCont,
        alt: "NOT-CONTAIN",
        types: ["string", "ipaddr", "array"]
    },
    oneOf: {
        id: "oneOf",
        label: STRINGS.runbookEditor.operators.oneOf,
        alt: "ONE-OF",
        multiInput: true,
        types: ["string", "ipaddr", "statuscode"]
    },
    nOneOf: {
        id: "nOneOf",
        label: STRINGS.runbookEditor.operators.nOneOf,
        alt: "NOT-ONE-OF",
        multiInput: true,
        types: ["string", "ipaddr", "statuscode"]
    },
    regex: {
        id: "regex",
        label: STRINGS.runbookEditor.operators.regex,
        alt: "REGEX",
        types: ["string", "ipaddr", "statuscode", "auth_profile", "alluvio_edge"]
    },
    exist: {
        id: "exist",
        label: STRINGS.runbookEditor.operators.exists,
        alt: "EXISTS",
        types: ["string", "integer", "float", "ipaddr", "boolean", /*"Boolean",*/ "array", "statuscode", "property"],
        excludedConditionTypes: ["variable"]
    },
    nexist: {
        id: "nexist",
        label: STRINGS.runbookEditor.operators.nexists,
        alt: "NOT-EXIST",
        types: ["string", "integer", "float", "ipaddr", "boolean", /*"Boolean",*/ "array", "statuscode", "property"],
        excludedConditionTypes: ["variable"]
    },
    eq: {
        id: "eq",
        label: "=",
        alt: "EQ",
        types: ["float", "integer", "boolean"/*, "Boolean"*/]
    },
    neq: {
        id: "neq",
        label: "!=",
        alt: "NOT-EQ",
        types: ["float", "integer", "boolean"/*, "Boolean"*/]
    },
    gt: {
        id: "gt",
        label: ">",
        alt: "GT",
        types: ["float", "integer", "statuscode"]
    },
    lt: {
        id: "lt",
        label: "<",
        alt: "LT",
        types: ["float", "integer", "statuscode"]
    },
    gteq: {
        id: "gteq",
        label: ">=",
        alt: "GT-EQ",
        types: ["float", "integer", "statuscode"]
    },
    lteq: {
        id: "lteq",
        label: "<=",
        alt: "LT-EQ",
        types: ["float", "integer", "statuscode"]
    }
}

// Metric Operator object that Compare Control Operator fromat
export const MetricOperators: Partial<OperatorType> = {
    eq: {
        id: "eq",
        label: "=",
        alt: "EQ"
    },
    neq: {
        id: "neq",
        label: "!=",
        alt: "NOT-EQ"
    },
    gt: {
        id: "gt",
        label: ">",
        alt: "GT"
    },
    lt: {
        id: "lt",
        label: "<",
        alt: "LT"
    },
    gteq: {
        id: "gteq",
        label: ">=",
        alt: "GT-EQ"
    },
    lteq: {
        id: "lteq",
        label: "<=",
        alt: "LT-EQ"
    },
}

// Metric Operators that apply when comparison data is available
export const MetricComparisonOperators: Partial<OperatorType> = {
    changegt: {
        id: "changegt",
        label: STRINGS.runbookEditor.operators.changegt,
        alt: "CHANGE-GT",
        comparisonReqd: true,
    },
    incgt: {
        id: "incgt",
        label: STRINGS.runbookEditor.operators.incgt,
        alt: "INCREASE-GT",
        comparisonReqd: true,
    },
    decgt: {
        id: "decgt",
        label: STRINGS.runbookEditor.operators.decgt,
        alt: "DECREASE-GT",
        comparisonReqd: true,
    },
    // These operations will be supported by Runbook Orchestrator but
    // UI will expose them only post-GA if they become necessary
    changelt: {
        id: "changelt",
        label: "Changed Less Than",
        alt: "CHANGE-LT",
        comparisonReqd: true,
    },
    inclt: {
        id: "inclt",
        label: "Increased Less Than",
        alt: "INCREASE-LT",
        comparisonReqd: true,
    },
    declt: {
        id: "declt",
        label: "Decreased Less Than",
        alt: "DECREASE-LT",
        comparisonReqd: true,
    },
}

// Metric Operators that apply for the trigger metric list
export const TriggerMetricOperators: Partial<OperatorType> = {
    eq: {
        id: "eq",
        label: "=",
        alt: "EQ",
        triggerMetricReqd: true,
    },
    neq: {
        id: "neq",
        label: "!=",
        alt: "NOT-EQ",
        triggerMetricReqd: true,
    },
    exist: {
        id: "exist",
        label: STRINGS.runbookEditor.operators.exists,
        alt: "EXISTS",
        triggerMetricReqd: true
    },
    nexist: {
        id: "nexist",
        label: STRINGS.runbookEditor.operators.nexists,
        alt: "NOT-EXIST",
        triggerMetricReqd: true
    },
}

// Metric Operators that apply when baseline/threshold data is available
export const MetricBaselineOperators: Partial<OperatorType> = {
    normal: {
        id: "normal",
        label: STRINGS.runbookEditor.operators.normal,
        alt: "NORMAL",
        triggerMetricReqd: true,
        impliedValue: "true"
    },
    nnormal: {
        id: "nnormal",
        label: STRINGS.runbookEditor.operators.nnormal,
        alt: "NOT-NORMAL",
        triggerMetricReqd: true,
        impliedValue: "true"
    },
    expectedhighgt: {
        id: "expectedhighgt",
        label: STRINGS.runbookEditor.operators.expectedhighgt,
        alt: "EXPECTED-HIGH-GT",
        triggerMetricReqd: true,
        //valueNotReqd: true,
        impliedValue: "true"
    },
    expectedhighlt: {
        id: "expectedhighlt",
        label: STRINGS.runbookEditor.operators.expectedhighlt,
        alt: "EXPECTED-HIGH-LT",
        triggerMetricReqd: true,
        //valueNotReqd: true,
        impliedValue: "true"
    },
    expectedlowgt: {
        id: "expectedlowgt",
        label: STRINGS.runbookEditor.operators.expectedlowgt,
        alt: "EXPECTED-LOW-GT",
        triggerMetricReqd: true,
        //valueNotReqd: true,
        impliedValue: "true"
    },
    expectedlowlt: {
        id: "expectedlowlt",
        label: STRINGS.runbookEditor.operators.expectedlowlt,
        alt: "EXPECTED-LOW-LT",
        triggerMetricReqd: true,
        //valueNotReqd: true,
        impliedValue: "true"
    },
}
export const TriggerBaselineOperators: Array<string> = Object.keys(MetricBaselineOperators).map(key => MetricBaselineOperators[key].alt);


// Aggregate Operator object that Compare Control Operator format
export const FunctionOperators: Partial<OperatorType> = {
    avg: {
        id: "avg",
        label: STRINGS.runbookEditor.operators.avg,
        alt: "AVG"
    },
    max: {
        id: "max",
        label: STRINGS.runbookEditor.operators.max,
        alt: "MAX"
    },
    min: {
        id: "min",
        label: STRINGS.runbookEditor.operators.min,
        alt: "MIN"
    }
}
