import React from 'react'
import "./TableTile.scss";
import { SEVERITY } from 'components/enums'
import { StatusLED } from 'components/common/status-led/StatusLED';
import { Icon, IconName } from "@tir-ui/react-components";
export enum TITLE_POSITION {
    top = "top",
    bottom = "bottom"
}

export interface TableTileProps {
    data?: Array<Array<string|number>>,
    className?: string,
    title?: string,
    titlePosition?: TITLE_POSITION,
    icon?: IconName,
    severity?: SEVERITY | undefined
}

export function TableTile (props: TableTileProps) {
    const titlePos = props.titlePosition || TITLE_POSITION.top;

    return (
        <div className={`table-tile d-flex flex-row justify-content-center ${props.className? props.className : ''}`}>
            { props.icon &&  <Icon icon={props.icon} iconSize={40} className="p-2 d-flex align-items-center tile-icon"/> }
            <div className="text-center d-flex flex-column justify-content-center flex-grow-1">
                {
                    props.severity ? (<StatusLED status={SEVERITY[props.severity]} label={titlePos === TITLE_POSITION.top ? props.title : undefined} className="justify-content-center"/>)
                    : (props.title && titlePos === TITLE_POSITION.top && <h5 className="m-0" aria-label="title">{props.title}</h5>)
                }
                <table className="table table-sm small m-0 bg-transparent text-left color-muted">
                    <tbody>{
                        props.data && props.data.map((dataRow, i) => {
                            return (<tr key={i}>{
                                dataRow.map((dataColumn, j) => {
                                    return (<td key={j}>{dataColumn}</td>);
                                })
                            }</tr>)
                        })
                    }</tbody>
                </table>
                { props.title && titlePos === TITLE_POSITION.bottom && <h6 aria-label="title">{props.title}</h6> }
            </div>
        </div>
    );
}
