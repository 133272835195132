import React from "react";
import "./StackedPercentageBar.scss";
export interface StackItem {
    color: string;
    value: number;
    label: string;
    id: string;
}
export type StackedPercentageBarProps = {
    stackItems: Array<StackItem>;
    onClick?: (string) => void;
};

export function StackedPercentageBar({ stackItems, onClick }: StackedPercentageBarProps) {
    let firstElement = true;
    const ml1 = "ml-1";
    const total = stackItems.reduce((sum: number, item: StackItem) => sum + item.value, 0);
    function handleOnClick(id: string) {
        if (onClick) {
            onClick(id);
        }
    }
    // Filter out items that do not need to be displayed.
    const elements: Array<JSX.Element> = stackItems.filter((item: StackItem) => item.value ? true : false).map((item, index) => {
        const element: JSX.Element = <div aria-label={item.label} key={index} onClick={() => { handleOnClick(item?.id) }} style={{ backgroundColor: item.color, width: getWidthPercentage(item.value, total) }} className={"percentage-bar " + (firstElement ? "" : ml1)} />
        firstElement = false;
        return element;
    });
    return (
        <div className="d-flex w-min-1 pt-1 stacked-percentage-bar">
            {elements}
        </div>
    )
}

function getWidthPercentage(count: number, total: number): string {
    return total > 0 ? ((count / total) * 100).toFixed(2) + "%" : "0%";
}
