import React from 'react';
import { Navbar } from "@blueprintjs/core";
import './SubHeader.scss';


export const SubHeader = (props: { children: React.ReactNode }) => {
    return (
        <div className="sub-nav-header">
            <Navbar>
                { props.children }
            </Navbar>
        </div>

    )
};
