import React, { useState } from 'react';
import classNames from 'classnames';
import { Classes, Divider, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { Form, InputField, TagListField } from 'components/common/form';
import { validIP, validCIDR } from 'utils/validators/Validators';
import { STRINGS } from 'app-strings';

export interface IIpConfigPanelProps {
	staticIpEnabled: boolean;
	staticIpCidrValue: string;
	staticIpGatewayValue: string;
	staticIpNameserversValues: React.ReactNode[];
	onChangeStaticIpEnabled: (staticIp: boolean) => void;
	onChangeStaticCidr: (event: React.FormEvent<HTMLInputElement>) => void;
	onChangeStaticIpGateway: (event: React.FormEvent<HTMLInputElement>) => void;
	onChangeStaticIpNameservers: (values: React.ReactNode[]) => void;
}

const IpConfigPanel: React.FC<IIpConfigPanelProps> = (props) => {
	const [staticCidrValid, setStaticCidrValid] = useState<boolean>(true);
	const [staticGatewayIpValid, setStaticGatewayIpValid] =
		useState<boolean>(true);
	const [staticIpNameserverValid, setStaticIpNameserverValid] =
		useState<boolean>(true);

	const handleStaticIpDhcpChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		props.onChangeStaticIpEnabled(
			event.currentTarget.value === 'dhcp' ? false : true
		);
	};

	const handleStaticIpAddressChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		if (event.currentTarget.value) {
			if (validCIDR(event.currentTarget.value)) {
				setStaticCidrValid(true);
			} else {
				setStaticCidrValid(false);
			}
		} else {
			setStaticCidrValid(true);
		}
		props.onChangeStaticCidr(event);
	};

	const handleStaticIpGatewayChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		if (event.currentTarget.value) {
			if (validIP(event.currentTarget.value)) {
				setStaticGatewayIpValid(true);
			} else {
				setStaticGatewayIpValid(false);
			}
		} else {
			setStaticGatewayIpValid(true);
		}
		props.onChangeStaticIpGateway(event);
	};

	const handleStaticIpNameserversChange = (
		values: React.ReactNode[]
	): void => {
		let invalidCount: number = 0;
		values.forEach((value) => {
			if (value && !validIP(value.toString())) {
				invalidCount++;
			}
		});

		invalidCount === 0 || values.length === 0
			? setStaticIpNameserverValid(true)
			: setStaticIpNameserverValid(false);

		props.onChangeStaticIpNameservers(values);
	};

	return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{
						STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
							.ipConfig.title
					}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={{
					static_ip_cidr: props.staticIpCidrValue,
					static_gateway_ip: props.staticIpGatewayValue,
					static_ip_nameservers: props.staticIpNameserversValues,
				}}
				loading={false}
			>
				<div className="row">
					<div className="col-md-6">
						<RadioGroup
							name="config_type"
							inline={true}
							onChange={(e) => handleStaticIpDhcpChange(e)}
							selectedValue={
								props.staticIpEnabled ? 'static_ip' : 'dhcp'
							}
						>
							<Radio
								label={
									STRINGS.DATA_SOURCES.addEdgeGatewayDialog
										.panels.ipConfig.fields.useDhcp
								}
								value="dhcp"
							/>
							<Radio
								label={
									STRINGS.DATA_SOURCES.addEdgeGatewayDialog
										.panels.ipConfig.fields.useStaticIp
								}
								value="static_ip"
							/>
						</RadioGroup>
						<InputField
							name="static_ip_cidr"
							type="text"
							className="input-field"
							required={true}
							label={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.ipConfig.fields.staticIpCidr
							}
							onChange={handleStaticIpAddressChange}
							placeholder={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.ipConfig.fieldPlaceholders.staticIpCidr
							}
							disabled={!props.staticIpEnabled}
						/>
						{!staticCidrValid && (
							<span className="text-danger align-top">
								*
								{
									STRINGS.DATA_SOURCES.addEdgeGatewayDialog
										.panels.ipConfig.validationErrors
										.invalidStaticIpAddress
								}
							</span>
						)}
						<InputField
							name="static_gateway_ip"
							type="text"
							className="input-field"
							required={true}
							label={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.ipConfig.fields.staticIpGateway
							}
							onChange={handleStaticIpGatewayChange}
							placeholder={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.ipConfig.fieldPlaceholders.staticIpGateway
							}
							disabled={!props.staticIpEnabled}
						/>
						{!staticGatewayIpValid && (
							<span className="text-danger align-top">
								*
								{
									STRINGS.DATA_SOURCES.addEdgeGatewayDialog
										.panels.ipConfig.validationErrors
										.invalidStaticIpGateway
								}
							</span>
						)}
					</div>
				</div>
				<div className="row">
					<div className="col-md-10">
						<TagListField
							name="static_nameserver_ips"
							required={true}
							label={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.ipConfig.fields.staticIpNameservers
							}
							className="input-field"
							inline={false}
							fill={true}
							placeholder={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.ipConfig.fieldPlaceholders
									.staticIpNameservers
							}
							tagProps={{
								intent: Intent.PRIMARY,
								large: false,
								minimal: false,
							}}
							onChange={handleStaticIpNameserversChange}
							values={props.staticIpNameserversValues}
							disabled={!props.staticIpEnabled}
						/>
						{!staticIpNameserverValid && (
							<span className="text-danger align-top">
								*
								{
									STRINGS.DATA_SOURCES.addEdgeGatewayDialog
										.panels.ipConfig.validationErrors
										.invalidIpNameservers
								}
							</span>
						)}
					</div>
				</div>
			</Form>
		</div>
	);
};

export { IpConfigPanel };
