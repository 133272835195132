/** This file defines a hook that can be used to get the custom properties from the data 
 *  access layer.
 *  @module */

import { Query } from "reporting-infrastructure/data-hub";
import { useQuery } from "./useQuery";
import { loader } from "graphql.macro";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { useMemo } from "react";
import { UserPreferences } from "utils/services/UserPrefsService";
import { useUserPreferences } from "./useUserPreferences";

/** these properties are under development and need to be hidden. */
export const HIDDEN_PROPS: string[] = [
    "Application Mapping", "Location Mapping", "Mute App", "Mute Apps at Location",
    "Application mapping", "Location mapping", "Mute app", "Mute apps at location"
];

/** this interface defines the parameters passed into the hook. */
interface UseCustomPropertiesProps {
}

/** this type defines the current query state. */
export type customQueryReturnProps = {
    /** a boolean value, true if the query is running and querying for data. */
    loading: boolean,
    /** the data from the last query. */
    data?: CustomProperty[],
    /** the error from the last query. */
    error: any,
};

/** this hook queries for the custom properties and returns them.
 *  @param props the properties that are passed into the hook. 
 *  @returns the loading status, data and errors.*/
export const useCustomProperties = (props: UseCustomPropertiesProps): customQueryReturnProps=> {
    let {loading, data, error} = useQuery({
        query: new Query(loader("./custom-properties.graphql")),
        skipGlobalFilters: true,
        timeNotRequired: true,
        lazy: false,
        noCache: true
    });
    const userPreferences = useUserPreferences({listenOnlyTo: {search: {savedQueries: [], showAdvancedSystemProperties: false}, explorer: {}, menu: {}}});

    const modifiedData = useMemo<CustomProperty[] | undefined>(() => {
        if (data) {
            const newData: CustomProperty[] = [];
            // console.log("data?.customProperties?.page",data?.customProperties?.page)
            if (data?.customProperties?.page?.length) {
                for (const prop of data.customProperties.page) {
                    const newProp: CustomProperty = {id: prop.id, name: prop.name, types: [], values: []};
                    if (userPreferences && !userPreferences?.search?.showAdvancedSystemProperties && HIDDEN_PROPS.includes(prop.name)) {
                        continue;
                    }
                    if (prop?.validTypes?.length) {
                        for (const validType of prop.validTypes) {
                            switch (validType.type) {
                                case "LOCATION":
                                    newProp.types.push("location");
                                    break;
                                case "APPLICATION":
                                    newProp.types.push("application");
                                    break;
                                case "NETWORK_INTERFACE":
                                    newProp.types.push("network_interface");
                                    break;
                                case "NETWORK_DEVICE":
                                    newProp.types.push("network_device");
                                    break;
                            }
                        }
                    }
                    if (prop.type) {
                        newProp.type = prop.type;
                    }
                    if (prop?.values?.length) {
                        for (const value of prop.values) {
                            newProp.values.push({id: value.id, name: value.name});
                        }
                    }
                    if (prop?.permissions?.length) {
                        newProp.permissions = prop.permissions;
                    }
                    newData.push(newProp);
                }
            }
            return newData;
        }
        return undefined;    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, userPreferences?.search?.showAdvancedSystemProperties]);


    return {loading, data: modifiedData, error};
}

/** Returns a list of system custom property names that should be hidden.
 *  @param userPreferences the UserPreference object with the search preferences that have the 
 *      showAdvancedSystemProperties setting that specifies whether some of the system custom 
 *      properties are hidden because they are under development.
 *  @returns Array of Strings with the system custom property names that should be hidden. */
export function getHiddenProps(userPreferences: UserPreferences | undefined): string[] {
    return userPreferences?.search?.showAdvancedSystemProperties ? [] : HIDDEN_PROPS;
}
