export enum SIZE {
    xs = "xs",
    s = "s",
    m = "m",
    l = "l",
    xl = "xl",
    xxl = "xxl",
}

export enum PANEL_SIZE {
    small = "300px",
    medium = "400px",
    large = "550px",
    xLarge = "1024px"
}
