import React, { useCallback, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { HTMLSelect } from '@blueprintjs/core';
import { InputField } from './InputField';
import { STRINGS } from "app-strings";

export type TextBoxWithDropdownProps = {
    label: string
    name: string
    required?: boolean
    placeholder?: string
    menuItems: Array<string>
}

export const initialTextBoxValue = (name) => {
    return {
        [`inputText_${name}`]: ''
    };
}

export const TextBoxWithDropdown = (props: TextBoxWithDropdownProps) => {

    const { setFieldValue } = useFormikContext();
    const textBoxName = `inputText_${props.name}`;
    const [meta] = useField({ name: textBoxName });
    const { value } = meta;
    const [selectedItem, setSelectedItem] = useState('');

    const dropdown = useCallback(() => {
        const options = [STRINGS.dropdownSelectText].concat(props.menuItems);
        return (
            <HTMLSelect
                options={options}
                onChange={(item) => { item.target.value !== STRINGS.dropdownSelectText && setSelectedItem(item.target.value); }}
            >
            </HTMLSelect>
        );
    }, [props.menuItems]);

    useEffect(() => {
        setFieldValue(props.name, `${value}${selectedItem}`)
    }, [value, selectedItem, props.name, setFieldValue]);

    return (

        <InputField
            label={props.label}
            placeholder={props.placeholder}
            name={textBoxName}
            required={props.required}
            rightElement={dropdown()}
            errorFieldName={props.name}
        />
    );
}
