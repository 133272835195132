/** This module contains the component that displays a dialog step that displays the threshold configuration
 *  in the capacity planning modal dialog.
 *  @module
 */
import React from 'react'
import { Callout, Classes, Divider, Label } from '@blueprintjs/core'
import classNames from 'classnames'
import { Form, SelectField, InputField } from 'components/common/form'
import { STRINGS } from 'app-strings'

/** this interface defines the properties that are passed in to the StepThreshold React component. */
interface ISelectThresholdProps {
    /** a number with the first threshold. */
    threshold1?: number;
    /** a function that sets the first threshold. */
    setThreshold1: (threshold: number) => void;
    /** a number with the second threshold. */
    threshold2?: number;
    /** a function that sets the second threshold. */
    setThreshold2: (threshold: number) => void;
    /** an object with the inputs. */
    inputs: any;
    /** an object with the edit information. */
    editInfos: any;
}

/** Renders the step threshold configuration React component.
 *  @param props the properties passed in.
 *  @returns JSX with the step threshold configuration React component.*/
const StepThreshold = (props: ISelectThresholdProps): JSX.Element => {
    const stepStrings = STRINGS.capacityPlanning.wizard.stepSelectThreshold;
    
    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p className='font-weight-bold'>{stepStrings.panelTitle}</p>
            <Divider className='ml-0' />
            <Form className='row pt-3' loading={false} initialValues={{ threshold1: props.threshold1, threshold2: props.threshold2 }}>
                <Label className='col-6 mt-2'>{stepStrings.labelThreshold1}</Label>
                <div className='col-6 mb-3'>
                    <InputField name="name"
                        placeholder={stepStrings.placeholder}
                        defaultValue={props.threshold1?.toString() || "80"}
                        onChange={e => props.setThreshold1(parseFloat(e.target.value))}
                        disabled={false} 
                        type='number'
                    />
                </div>

                <Label className='col-6 mt-2'>{stepStrings.labelThreshold2}</Label>
                <div className='col-6 mb-3'>
                    <InputField name="name"
                        placeholder={stepStrings.placeholder}
                        defaultValue={props.threshold2?.toString() || "100"}
                        onChange={e => props.setThreshold2(parseFloat(e.target.value))}
                        disabled={false} 
                        type='number'
                    />
                </div>

                <Label className='col-6 mt-2'>{stepStrings.labelTimeframe}</Label>
                <div className='col-6 mb-3'>
                    <SelectField name="select_timeframe"
                        disabled={false}
                        onChange={e => {
                            //props.setRunbookId(e.target.value);
                        }}
                        value={2}
                        >
                        <option value="1">1 Month</option>
                        <option value="3">3 Months</option>
                        <option value="6">6 Months</option>
                    </SelectField>
                </div>
                <Callout intent='primary'>{stepStrings.note}</Callout>
            </Form>
        </div>
    )
}

export default StepThreshold
