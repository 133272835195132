/** This file contains utilities for metric formatting.
 *  @module */
import { STRINGS } from "app-strings";
import { SEVERITY, SEVERITY_INDEX } from "components/enums";
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { formatDurationToString } from ".";

/** this inferface defines a resulting scaled unit value. */
export interface ScaledUnitValue {
    /** the value after scaling. */
    value: number;
    /** the unit after scaling. */
    unit: string;
    /** the formatting value and unit. */
    formatted: string;
}

/** this inferface defines a resulting formatted and scaled unit value. */
export interface FormattedUnitValue {
    /** the value after scaling. */
    value: string | number;
    /** the unit after scaling. */
    unit: string;
    /** the formatting value and unit. */
    formatted: string;
}

/** formats a value and unit.
 *  @param value the value
 *  @param unit the unit
 *  @returns a String with the formatted value.*/
export const defaultFormatter = (value: number, unit: string) => {
    if (!value && value !== 0) {
        return "-";
    }
    if (unit === 's' && value > 60) {
        // Not sure why this case was added but it does not make sense for small numbers
        // like 1.3 s, because it strips out the .3 s which is significant, it is 30%
        // of the value, so only due the elapsed time format for numbers where the 
        // decimals are insignificant.  If you have a value that is 1 min 25 s, then 
        // you probably do not care about the 30 ms that were stripped off, but you 
        // most certainly care when you have 1.3 s.  This was causing a problem for 
        // tables showing round trip time.
        return formatDurationToString(value);
    }
    if (Number.isInteger(value)) {
        return `${value}${unit ? " " + unit : ""}`
    } else {
        return `${value.toFixed(getPrecisionForValue(value))}${unit ? " " + unit : ""}`
    }
}

/** this type defines the metric formatter. */
export type MetricFormatter = (value: number, unit: string) => string

/** this interface defines the scaling options for the scaling function. */
export interface ScalingOptions {
    /** a number with the scale factor. */
    factor?: 1000 | 1024;
    /** the formatter function to be used instead of the default formatter. */
    formatter?: MetricFormatter;
}

/** Scales and formats a metric.  Depending on the metric, it will either return an unscaled fixed precision 
 *      value or a scaled value that is always greater than 0 but less than 1000.  The scaling will happend based
 *      on the unit type.
 *  @param value numeric value of metric
 *  @param unit unit of metric value. defaults to an empty string
 *  @param options scaling options
 *  @returns an object with the formatted value.*/
export const formatAndScaleMetricValue = (value: number, unit: Unit = new Unit(), options: ScalingOptions = {}): FormattedUnitValue => {
    const formatter = options?.formatter || defaultFormatter;
    if (!unit.isScalable()) {
        const preciseValue = precise(value, getPrecisionForValue(value));
        return  {
            value: preciseValue,
            unit: unit.getDisplayName(),
            formatted: preciseValue + " " + unit.getDisplayName()
        };
    } else {
        const result: ScaledUnitValue = scaleMetric(value, unit, options);
        return {
            value: formatter(result.value, ""),
            unit: result.unit,
            formatted: result.formatted,
        };
    }
 }

/** Scales a metric with a numeric value, such that the scaled value is always greater than 0 but less than 1000
 *  @param value numeric value of metric
 *  @param unit unit for the metric value.
 *  @param options scaling options.
 *  @returns the scaled value as a ScaledUnitValue.*/
export const scaleMetric = (value: number, unit: Unit = new Unit(), options: ScalingOptions = {}): ScaledUnitValue => {
    const formatter = options?.formatter || defaultFormatter;

    const unformattedResult = {
        value: isNaN(value) ? 0 : value,
        unit: unit.getDisplayName(),
        formatted: formatter(value, unit.getDisplayName())
    };
    
    if (value === 0) {
        return unformattedResult;
    }

    const scaleResult = unit.getScaledUnit(value, options?.factor);
    if (scaleResult.scale > 1) {
        const result = {
            value: value / scaleResult.scale,
            unit: scaleResult.unit.getDisplayName(),
            formatted: formatter(value / scaleResult.scale, scaleResult.unit.getDisplayName())
        };
        return result;
    }
    return unformattedResult;
}

/** Scales an array of metric values in such a way that the largest measurement in the array always lies between 0 - 1000.
 *      Rest of the values are scaled with the largest measurement's scaling factor.
 *  @param values an array of metric values
 *  @param unit base unit of metric values. defaults to an empty string
 *  @param options scaling options
 *  @returns .*/
export const scaleMetrics = (values: number[], unit: Unit = new Unit(), options: ScalingOptions = {}): ScaledUnitValue[] => {

    const formatter = options?.formatter || defaultFormatter

    const maxValue = Math.max(...values)
    const maxFormatted = scaleMetric(maxValue, unit, options)

    const scaleFactor = Math.round(maxValue / maxFormatted.value)

    const result: ScaledUnitValue[] = []

    values.forEach((value: number) => {
        const scaledValue = (value / scaleFactor)
        const scaledUnit = maxFormatted.unit
        result.push({
            value: scaledValue,
            unit: scaledUnit,
            formatted: formatter(scaledValue, scaledUnit)
        });
    })
    return result
}

export function getStatusAndInfoForMetric (data, thresholds) {
    let status;
    let allPointsGreaterThanThreshold = true;
    let atLeastOnePointExceededThreshold = false;
    let exceedingMultipleThresholds = false;
    const lastValue = data && data[data.length - 1];
    if (thresholds) {
        for (const threshold of thresholds) {
            // If all values are above threshold, consider it critical
            if (data) {
                for (const value of data) {
                    if (value < threshold) {
                        allPointsGreaterThanThreshold = false;
                    }
                    if (value > threshold) {
                        atLeastOnePointExceededThreshold = true;
                    }
                    if (atLeastOnePointExceededThreshold === true && allPointsGreaterThanThreshold === false) {
                        break;
                    }
                }
                if (allPointsGreaterThanThreshold) {
                    status = SEVERITY.CRITICAL;
                }
            }
            if (lastValue && lastValue > threshold) {
                // If exceeding one threshold
                if (status === undefined) {
                    status = SEVERITY.DEGRADED;
                // If multiple thresholds were provided and the metric value exceeds more than one
                } else {
                    exceedingMultipleThresholds = true;
                    status = SEVERITY.CRITICAL;
                }
            }
        }
    }
        
    let info = "";
    if (thresholds !== undefined) {
        if (status === SEVERITY.CRITICAL) {
            if (allPointsGreaterThanThreshold) {
                info = STRINGS.smartText.alwaysAboveThreshold;
            } else if (exceedingMultipleThresholds) {
                info = STRINGS.smartText.exceedingMultipleThresholds;
            } else {
                info = STRINGS.smartText.criticalState;
            }
        } else if (status === SEVERITY.DEGRADED) {
            info = STRINGS.smartText.exceedingThreshold;
        } else {
            if (atLeastOnePointExceededThreshold) {
                info = STRINGS.smartText.backWithinThreshold;
            } else {
                info = STRINGS.smartText.withinThreshold;
            }
        }
    }

    return {
        severity: status,
        info
    };
}

// Inputs are objects with key as metric
export function getMetricStatusText (metricsDataObj:{[x: string]: number[]}, thresholdsObj?:{[x: string]: number[]}, entityName?:string) {
    let statusObjects:{ metric: string, severity: SEVERITY, info: string }[] = [];
    let statusText;
    for (const metric in metricsDataObj) {
        const metricData = metricsDataObj[metric];
        statusObjects.push({
            metric,
            ...getStatusAndInfoForMetric(metricData, thresholdsObj && thresholdsObj[metric])
        });
    }
    // Filter and take only the metrics that exceeded thresholds and sort them based on worst severity level
    statusObjects = statusObjects
        .filter(a => SEVERITY_INDEX[a.severity] > 1)
        .sort((a, b) => { return Number(SEVERITY_INDEX[b.severity] || 0) - Number(SEVERITY_INDEX[b.severity] || 0); });
    if (statusObjects.length === 0) {
        statusText = (entityName ? entityName + " " : "") + STRINGS.smartText.normal;
    } else {
        statusText = (entityName ? entityName + " " : "") + STRINGS.smartText.havingHigh + " ";
        let metricsList = statusObjects.map(s => STRINGS.METRICS[s.metric]);
        if (metricsList.length > 2) {
            const lastMetric = metricsList.pop();
            statusText += metricsList.join(", ") + " " + STRINGS.and + " " + lastMetric;
        } else {
            statusText += metricsList.join(" " + STRINGS.and + " ");
        }
    }
    return statusText;
}

/** returns a String with the value truncated to a fixed number of digits.
 *  @param data the value
 *  @param digits the number of digits to display.
 *  @returns a String with the value formatted to a fixed number of digits.*/
export function precise(data: string | number, digits: number = 2) {
    if (data) {
        try {
            const num = Number.parseFloat(data.toString());
            if (num % 1 === 0) {
                return data;
            } else {
                return num.toFixed(digits)
            }
        } catch (e) {
            console.warn(`Not a number ${e}`);
        }
    }
    return data;
}

export function getPrecisionForValue (value: number) {
    // Scale upto a precision of 5 decimal points.
    let precision = 2;
    const absoluteValue = Math.abs(value);
    if (absoluteValue < 0.0001) {
        precision = 5;
    } else if (absoluteValue < 0.001) {
        precision = 4;
    } else if (absoluteValue < 0.01) {
        precision = 3;
    }
    return precision;
}
