import { STRINGS } from "app-strings";

export const validityOptions = STRINGS.apiAccess.validityOptions;
export const validityOptionsMapping = [
    {
        value: 3,
        label: validityOptions.threeMonths,
    },
    {
        value: 6,
        label: validityOptions.sixMonths,
    },
    {
        value: 12,
        label: validityOptions.twelveMonths,
    },
    {
        value: 18,
        label: validityOptions.eighteenMonths,
    },
    {
        value: 24,
        label: validityOptions.twentyfourMonths,
    },
    {
        value: 0,
        label: validityOptions.custom,
    },
];