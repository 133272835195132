import React from 'react';
// Uncomment to demo the geomap
//import { SEVERITY, SEVERITY_INDEX, STATUS_COLORS_DARKER } from 'components/enums';
//import { Source, Layer } from 'react-map-gl';
//import { Feature, FeatureCollection } from 'geojson';
//import { locationMarkerProps, MapLayerProps } from '.';
//import { Divide } from '@blueprintjs/icons/lib/esm/generated-icons/16px/paths';

interface markerMouseEventData {
    id: string,
    originalEvent: MouseEvent,
    properties: any
}
interface clusterMouseEventData {
    clusterID: string,
    originalEvent: MouseEvent,
    clusterProperties: any,
    //leaves: locationMarkerProps[]
}
interface mouseLeaveEventData {
    originalEvent: MouseEvent
}
export interface locationMarkerLayerProps {
    id: string
    children?: any

    onMouseEnterMarker?: (data: markerMouseEventData) => void
    onMouseLeaveMarker?: (data: mouseLeaveEventData) => void
    onMarkerClick?: (data: markerMouseEventData) => void

    onMouseEnterCluster?: (data: clusterMouseEventData) => void
    onMouseLeaveCluster?: (data: mouseLeaveEventData) => void
    onClusterClick?: (data: clusterMouseEventData) => void
}

/* uncomment to demo the geomap
function getLeavesInCluster (mouseEvent, properties, dataSourceID):Promise<locationMarkerProps[]> {
    const dataSource = mouseEvent.map?.getSource(dataSourceID);
    if (dataSource && properties.cluster_id) {
        return new Promise((resolve) => {
            dataSource.getClusterLeaves(properties.cluster_id, properties.point_count, 0, function (error, leaves) {
                if (error) {
                    console.error(error);
                    resolve([]);
                } else {
                    const features:locationMarkerProps[] = leaves.map(child => {
                        return {
                            id: child.properties?.id,
                            coordinate: child.geometry?.coordinates,
                            properties: child.properties
                        };
                    });
                    resolve(features);
                }
            });
        });
    } else {
        return new Promise(resolve => resolve([]));
    }
}

async function getClusterMouseEventData (mouseEvent, properties, dataSourceID) {
    const leaves: locationMarkerProps[] = await getLeavesInCluster(mouseEvent, properties, dataSourceID);
    return {
        clusterID: properties.cluster_id,
        clusterProperties: properties,
        originalEvent: mouseEvent,
        leaves
    };
}

function getMarkerMouseEventData (mouseEvent, properties) {
    return {
        id: properties?.id,
        originalEvent: mouseEvent,
        properties: properties
    }
}

function MapLayer (props?: MapLayerProps) {
    return <></>;
}
*/

function LocationMarkerLayer ({ ...props }:locationMarkerLayerProps) {
    /* uncomment to demo the geomap
    const dataSourceID = props.id;
    const locationClusterLayerID = dataSourceID + "_locationClusters";
    const locationClusterTextLayerID = dataSourceID + "_locationClusterText";
    const locationMarkerLayerID = dataSourceID + "_locationMarkers";
    const locationImportantMarkerLayerID = dataSourceID + "_locationImportant";

    // Methods to handle mouse events on any type of interactive element (marker or marker cluster)
    // These methods will redirect logic to the applicable more-specific methods.
    function onItemClicked (e: MouseEvent | any, feature) {
        const layerID = feature?.layer?.id;
        // If click happened on an individual marker
        if (layerID === locationMarkerLayerID) {
            onMarkerClick(e, feature.properties);
        // If mouseEnter happened on a marker cluster
        } else if (layerID === locationClusterLayerID) {
            onClusterClick(e, feature.properties)
        }
    }
    function onMouseEnterItem (e: MouseEvent | any, feature) {
        const layerID = feature?.layer?.id;
        // If mouseEnter happened on an individual marker
        if (layerID === locationMarkerLayerID) {
            onMouseEnterMarker(e, feature.properties);
        // If mouseEnter happened on a marker cluster
        } else if (layerID === locationClusterLayerID) {
            onMouseEnterCluster(e, feature.properties)
        }
    }
    function onMouseLeaveItem (e: MouseEvent | any, feature) {
        const layerID = feature?.layer?.id;
        if (layerID === locationMarkerLayerID) {
            onMouseLeaveMarker(e);
        // If mouseEnter happened on a marker cluster
        } else if (layerID === locationClusterLayerID) {
            onMouseLeaveCluster(e);
        } 
    }

    // Methods to handle mouse events on individual marker elements
    function onMouseEnterMarker (e: MouseEvent | any, data) {
        if (props.onMouseEnterMarker) {
            props.onMouseEnterMarker(getMarkerMouseEventData(e, data));
        }
    }
    function onMouseLeaveMarker (e: MouseEvent | any) {
        if (props.onMouseLeaveMarker) {
            props.onMouseLeaveMarker({ originalEvent: e.originalEvent });
        }
    }
    function onMarkerClick (e: MouseEvent | any, data) {
        if (props.onMarkerClick) {
            props.onMarkerClick(getMarkerMouseEventData(e, data));
        }
    }

    // Methods to handle mouse events on marker cluster elements
    async function onMouseEnterCluster (e: MouseEvent | any, featureProperties) {
        if (props.onMouseEnterCluster) {
            props.onMouseEnterCluster(await getClusterMouseEventData(e, featureProperties, dataSourceID));
        }
    }
    function onMouseLeaveCluster (e: MouseEvent | any) {
        if (props.onMouseLeaveCluster) {
            props.onMouseLeaveCluster({ originalEvent: e.originalEvent });
        }
    }
    async function onClusterClick (e: MouseEvent | any, featureProperties) {
        if (props.onClusterClick) {
            props.onClusterClick(await getClusterMouseEventData(e, featureProperties, dataSourceID));
        }
    }


    function convertChildrenToGeoJson (children) {
        const features:FeatureCollection = {
            type: "FeatureCollection",
            features: children ? React.Children.map(children, child => {
                if (child) {
                    const initializedMarker = child.type(child.props);
                    const feature:Feature = {
                        type: 'Feature',
                        id: initializedMarker.props.id,
                        geometry: initializedMarker.props.geometry,
                        properties: initializedMarker.props.properties || {}
                    };
                    return feature;
                }
            }) : []
        };
        return features;
    }
    // GeoJSON data will be passed to the source element. We also have to pass other params such as interactiveLayerIds and event handlers
    // to the consumer of this component which is GeoMap. This component cannot exist in the UI as a standalone. GeoMap component will have
    // logic to understand this component and do the necessary rendering.
    const childrenInGeoJsonFormat = convertChildrenToGeoJson(props.children);
    */

    return <><div>Uncomment code to get geomap to work</div>
    {/*<MapLayer key={props.id} interactiveLayerIds={childrenInGeoJsonFormat.features.length > 0 ? [locationClusterLayerID, locationMarkerLayerID] : undefined}
        onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseLeaveItem} onClick={onItemClicked}
    >
        <Source id={dataSourceID} type="geojson" data={childrenInGeoJsonFormat}
            // Tell the data source to cluster point data.
            cluster={true}
            // The radius in pixels to cluster points together.
            clusterRadius={15}
            // The maximium zoom level in which clustering occurs.
            // If you zoom in more than this, all points are rendered as symbols.
            clusterMaxZoom={6}
            clusterProperties={{
                criticalCount: ['+', ['case', ['==', ['get', 'severity'], SEVERITY.CRITICAL], 1, 0]],
                degradedCount: ['+', ['case', ['==', ['get', 'severity'], SEVERITY.DEGRADED], 1, 0]],
                normalCount: ['+', ['case', ['==', ['get', 'severity'], SEVERITY.NORMAL], 1, 0]],
                unknownCount: ['+', ['case', ['==', ['get', 'severity'], SEVERITY.UNKNOWN], 1, 0]],
                worstSeverity: ['max', ['get', 'severityIndex']],
                selected: ['any', ['get', 'selected']],
                hovered: ['any', ['get', 'hovered']],
                important: ['any', ['get', 'hovered']],
            }}
        >
            <Layer type="circle" id={locationClusterLayerID} source={dataSourceID}
                filter={['has', 'point_count']}
                paint={{
                    'circle-opacity': 0.8,
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        15, //Default of 20 pixel radius.
                        100,
                        20, //If point_count >= 100, radius is 30 pixels.
                        750,
                        30, //If point_count >= 750, radius is 40 pixels.
                    ],
                    'circle-color': [
                        'match',
                        ['get', 'worstSeverity'], //Input to match
                        SEVERITY_INDEX[SEVERITY.CRITICAL], STATUS_COLORS_DARKER.CRITICAL, // Match against , output if it matches
                        SEVERITY_INDEX[SEVERITY.DEGRADED], STATUS_COLORS_DARKER.DEGRADED,
                        SEVERITY_INDEX[SEVERITY.NORMAL], STATUS_COLORS_DARKER.NORMAL,
                        STATUS_COLORS_DARKER.UNKNOWN // Default value
                    ],
                    'circle-stroke-opacity': ['case', ['any', ['get', 'selected'], ['get', 'hovered']], 0.8, 0.3], 
                    'circle-stroke-width': 3,
                    'circle-stroke-color': ['case', ['any', ['get', 'selected'], ['get', 'hovered']], "#FFF",
                        [
                            'match',
                            ['get', 'worstSeverity'], //Input to match
                            SEVERITY_INDEX[SEVERITY.CRITICAL], STATUS_COLORS_DARKER.CRITICAL, // Match against , output if it matches
                            SEVERITY_INDEX[SEVERITY.DEGRADED], STATUS_COLORS_DARKER.DEGRADED,
                            SEVERITY_INDEX[SEVERITY.NORMAL], STATUS_COLORS_DARKER.NORMAL,
                            STATUS_COLORS_DARKER.UNKNOWN // Default value
                        ]
                    ]
                }}
            />
            <Layer type="symbol" id={locationClusterTextLayerID} source={dataSourceID}
                //Filter out clustered points from this layer.
                filter={['has', 'point_count']}
                layout={{
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-size': 12
                }}
                paint={{
                    'text-color': "#FFF"
                }}
            />
            <Layer type="circle" id={locationMarkerLayerID} source={dataSourceID}
                //Filter out clustered points from this layer.
                filter={['!', ['has', 'point_count']]}
                paint={{
                    'circle-opacity': ['case', ['get', 'selected'], 1, 0.8],
                    'circle-radius': [
                        'interpolate', ['linear'],
                        ['zoom'],
                        3, ['case', ['any', ['get', 'selected'], ['get', 'hovered']], 10, 4],
                        5, ['case', ['any', ['get', 'selected'], ['get', 'hovered']], 10, 8]
                    ],
                    'circle-color': [
                        'match',
                        ['get', 'severity'], //Input to match
                        SEVERITY.CRITICAL, STATUS_COLORS_DARKER.CRITICAL, // Match against , output if it matches
                        SEVERITY.DEGRADED, STATUS_COLORS_DARKER.DEGRADED,
                        SEVERITY.NORMAL, STATUS_COLORS_DARKER.NORMAL,
                        STATUS_COLORS_DARKER.UNKNOWN // Default value
                    ],
                    'circle-stroke-opacity': ['case', ['get', 'hovered'], 0.3, 1],
                    'circle-stroke-width': [ 'case', ['any', ['get', 'selected'], ['get', 'hovered']], 4, 0 ],
                    'circle-stroke-color': [ 'case',
                        ['get', 'selected'],
                        '#FFF',
                        [
                            'match',
                            ['get', 'severity'], //Input to match
                            SEVERITY.CRITICAL, STATUS_COLORS_DARKER.CRITICAL, // Match against , output if it matches
                            SEVERITY.DEGRADED, STATUS_COLORS_DARKER.DEGRADED,
                            SEVERITY.NORMAL, STATUS_COLORS_DARKER.NORMAL,
                            STATUS_COLORS_DARKER.UNKNOWN // Default value
                        ]
                    ],
                }}
            />
            <Layer type="symbol" id={locationImportantMarkerLayerID} source={dataSourceID}
                //Filter out clustered points from this layer.
                filter={['all', ['!', ['has', 'point_count']], ['get', 'important']]}
                layout={{
                    'text-field': '★',
                    'text-size': [
                        'interpolate', ['linear'],
                        ['zoom'],
                        3, 8,
                        5, 15
                    ]
                }}
                paint={{
                    'text-color': "#FFF"
                }}
            />
        </Source>
    </MapLayer>*/}</>;
}

export { LocationMarkerLayer };
