/** This module contains the component that displays a dialog step that displays the analysis chart
 *  in the capacity planning modal dialog.
 *  @module
 */
import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import AnalysisChart from './AnalysisChart';

/** this interface defines the properties that are passed in to the StepResult React component. */
interface IStepResultsProps {
    /** a number with the first threshold. */
    threshold1?: number;
    /** a number with the second threshold. */
    threshold2?: number;
    /** an object with the inputs. */
    inputs: any;
    /** an object with the edit information. */
    editInfos: any;
}

/** Renders the step results React component.
 *  @param props the properties passed in.
 *  @returns JSX with the step results component.*/
const StepResults = (props: IStepResultsProps): JSX.Element => {
    //const stepStrings = STRINGS.capacityPlanning.wizard.stepResults;

    return (
        <div className={classNames(Classes.DIALOG_BODY)} style={{}/*{width: "900px", height: "700px"}*/}>
            <AnalysisChart height={430} timeout={2 * 1000} threshold1={props.threshold1 || 10} threshold2={props.threshold2 || 10} />
        </div>
    )
}

export default StepResults
