import React, { useState } from 'react';
import { Button, Card, Intent } from '@blueprintjs/core';

import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider'

import { getRoutes } from 'config';
import { STRINGS } from 'app-strings';

import { IconNames, Page, Property, PropertyList, ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { clearAllUserPreferences } from 'utils/stores/UserPreferencesStore';

const AuthService = AuthServiceProvider.getService()

function UserProfile() {
    const ROUTES = getRoutes()
    const openUserProfile = () => {
        const url =
            'https://account.activedirectory.windowsazure.com/r/#/profile';
        return window.open(url, '_blank');
    };
    const [userPrefClearInProgress, setUserPrefClearInProgress] = useState(false);
    return (
        <Page {...ROUTES.profile}>
            <Card>
                <PropertyList id="user-profile">
                    <Property
                        id="user-profile-username"
                        label={STRINGS.USER_PROFILE_PAGE.properties.username}
                        value={AuthService.getUserAccount().getUsername()}
                    />
                    <Property
                        id="user-profile-name"
                        label={STRINGS.USER_PROFILE_PAGE.properties.name}
                        value={AuthService.getUserAccount().getName()}
                    />
                </PropertyList>
                <br />
                <Button
                    icon={IconNames.USER}
                    intent={Intent.PRIMARY}
                    onClick={openUserProfile}
                    text={STRINGS.USER_PROFILE_PAGE.manageAccount}
                />
                <Button
                    loading={userPrefClearInProgress}
                    className="ml-2"
                    onClick={async () => {
                        setUserPrefClearInProgress(true);
                        try {
                            await clearAllUserPreferences();
                            SuccessToaster({
                                message: STRINGS.USER_PROFILE_PAGE.clearPrefsSuccess
                            });
                        } catch (ex) {
                            console.error(ex);
                            ErrorToaster({
                                message: STRINGS.USER_PROFILE_PAGE.clearPrefsError
                            });
                        }
                        setUserPrefClearInProgress(false);
                    }}
                    text={STRINGS.USER_PROFILE_PAGE.clearPreferences}
                />
            </Card>
        </Page>
    );
}

export { UserProfile };
