import React, { useEffect, useState } from "react";
import moment from "moment";
import "./TimeRangeSelector.css";
import { GlobalEvent } from "reporting-infrastructure/event-hub/GlobalEvent";
import { globalSubject } from "reporting-infrastructure/event-hub/EventHub";
import { GlobalEventTypes } from "reporting-infrastructure/event-hub";
import { subscribeToGlobalEvents } from "reporting-infrastructure/event-hub/EventHub";
import { TimePeriodViewLast } from "components/common/time-period-view-last/TimePeriodViewLast";
import { TimePeriodMoreView } from "components/common/time-period-more-view/TimePeriodMoreView";
import { Icon, IconNames } from "@tir-ui/react-components";
import { DURATION, useGlobalTime } from "utils/hooks";
import { TIME_RANGE } from "utils/hooks/useGlobalTime";
import { TIME_FORMAT } from 'components/enums';
import { Button } from "@blueprintjs/core";
import { RangeInfo, RESOLUTION, ResolutionInfo } from "../time-range-slider/TimeRangeSlider";
import { TimeRangeSlider } from "../time-range-slider/TimeRangeSlider";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";


enum CUSTOM_TIME_RANGE {
    CUSTOM = "CUSTOM"
}
export const DISPLAY_TIME_FORMAT = TIME_FORMAT.DISPLAY_TIME_FORMAT;
export type TIME_OPTION = DURATION | CUSTOM_TIME_RANGE;
export const TIME_OPTION_VALUES = { ...DURATION, ...CUSTOM_TIME_RANGE };
export enum TIME_PERIOD_UNIT {
    minute = "minute",
    hour = "hour",
    day = "day",
    months = "months",
    years = "years",
}
const DURATION_TO_RESOLUTION_MAP = {
    MIN_15: RESOLUTION.M15,
    MIN_30: RESOLUTION.M30,
    HOUR_1: RESOLUTION.H1,
    HOUR_6: RESOLUTION.H6,
    DAY_1: RESOLUTION.D1,
    DAY_7: RESOLUTION.W1,
    LAST_1_MONTH: RESOLUTION.M1,
    LAST_6_MONTH: RESOLUTION.M3,
    LAST_1_YEAR: RESOLUTION.M3
}

export interface TimeRangeSelectorProps {
    /** Selected Duration */
    selectedDuration?: TIME_OPTION;
    /** Last_View  visible select Options*/
    viewLastOptions?: Array<DURATION>;
    /** More_ ViewVisible select Options*/
    viewMoreOptions?: Array<DURATION>;
    /** Applicable only for custom date*/
    timePeriod?: TIME_RANGE;
}

const DEFAULT_VIEW_LAST_OPTIONS = [DURATION.HOUR_1, DURATION.HOUR_6, DURATION.DAY_1, DURATION.DAY_7];
const DEFAULT_VIEW_MORE_OPTIONS = [DURATION.HOUR_1, DURATION.HOUR_6, DURATION.DAY_1, DURATION.DAY_7, DURATION.LAST_1_MONTH, DURATION.LAST_6_MONTH, DURATION.LAST_1_YEAR];

export function TimeRangeSelector({
    viewLastOptions = DEFAULT_VIEW_LAST_OPTIONS,
    viewMoreOptions = DEFAULT_VIEW_MORE_OPTIONS,
    ...props
}: TimeRangeSelectorProps) {

    let [resolution, setResolution] = useState(RESOLUTION.M15);
    let [prevResolution, setPrevResolution] = useState(RESOLUTION.M15);

    const handleRangeSelection = (newPosition: RangeInfo) => {
        handleTimeWindowChange(TIME_OPTION_VALUES.CUSTOM, { startTime: newPosition.startTime, endTime: newPosition.endTime })
    }
    const handleResolutionSelection = (newRes: ResolutionInfo) => {
        if (newRes === undefined || newRes.value === undefined) {
            //Resolution rejected. fallback to previous resolution
            setResolution(prevResolution);
            // setResolutionInSteps(getResolutionIndex(prevResolution));
        } else {
            setResolution(newRes.value);
            setPrevResolution(newRes.value);
            // setResolutionInSteps(newRes.index);
        }
    }
    // Note: Enable this function if zoom control needs to be displayed.
    // let [resolutionInSteps, setResolutionInSteps] = useState(getResolutionIndex(RESOLUTION.M15));
    // function handleZoomSelection(val) {
    //     console.log(`posittion ${val.position}`);
    //     console.log(RESOLUTION[labels[val.position].id]);
    //     setPrevResolution(resolution);
    //     setResolution(RESOLUTION[getResolution(val.position)]);
    //     // setResolutionInSteps(val.position);
    // }

    const { time, absoluteTime, setDuration, setTimeRange, durationToTimeRange } = useGlobalTime();
    const [shiftRightButtonState, setShiftRightButtonState] = useState(false);
    let timeFromStore = time;
    let startTime = 0,
        endTime = 1;
    let durationValue = props.selectedDuration;
    // If selectedDuration option is provided as a prop, this control will work in a controlled mode.
    // If not, it will automatically pick up the time infromation from global time store using the hook.
    if (props.selectedDuration) {
        /** Validate for CUSTOM time option startTime and endTime needs be valid */
        if (props.selectedDuration === TIME_OPTION_VALUES.CUSTOM) {
            if (
                props.timePeriod &&
                props.timePeriod.startTime !== 0 &&
                props.timePeriod.endTime !== 1 &&
                props.timePeriod.startTime < props.timePeriod.endTime
            ) {
                startTime = props.timePeriod.startTime;
                endTime = props.timePeriod.endTime;
            } else {
                /** Default Time Range Selection in DateRangePicker */
                startTime = new Date(Date.now() - 86400 * 1000).valueOf();
                endTime = new Date().valueOf();
            }
        } else {
            let timeRangeFromDuration = durationToTimeRange(props.selectedDuration);
            startTime = timeRangeFromDuration.startTime;
            endTime = timeRangeFromDuration.endTime;
        }
    } else {
        if (timeFromStore.duration) {
            startTime = absoluteTime.startTime;
            endTime = absoluteTime.endTime;
            durationValue = DURATION[timeFromStore.duration];
        } else if (timeFromStore.startTime && timeFromStore.endTime) {
            startTime = timeFromStore.startTime;
            endTime = timeFromStore.endTime;
            durationValue = TIME_OPTION_VALUES.CUSTOM;
        }
    }

    const [timeRange, setTimeRangeState] = useState({
        startTime: startTime,
        endTime: endTime,
    });

    const handleGlobalEvents = (globalEvent: GlobalEvent) => {
        console.debug("Global event received" + JSON.stringify(globalEvent));
        setTimeRangeState(globalEvent.getValue());
        if (!globalEvent) {
            return;
        }
    };

    useEffect(() => {
        const setRightShiftButtonStatus = () => {
            const curTime = moment.utc().valueOf();
            const curTimeFormatted = moment.utc(curTime).format('LLL');
            const endTimeFormatted = moment.utc(timeRange.endTime).format('LLL');
            if (curTimeFormatted !== endTimeFormatted) {
                setShiftRightButtonState(false);
            } else {
                setShiftRightButtonState(true);
            }
        };
        // update shift button status everytime the time range changes.
        setRightShiftButtonStatus();
        // Check every 10 seconds to see if the shift button needs to be enabled.
        const interval = setInterval(setRightShiftButtonStatus, 1000 * 10);
        return () => {
            clearInterval(interval);
        };
    }, [timeRange]);

    useEffect(() => {
        const globalEventsSubscription = subscribeToGlobalEvents(
            handleGlobalEvents,
            "MockDALData",
            [{ type: GlobalEventTypes.TimeChange }]
        );
        return () => {
            globalEventsSubscription.unsubscribe();
        };
    }, []);

    const [timeWindow, setTimeWindow] = useState(durationValue);

    /** Fire time range change global event */
    function globalEventTimeChange(result: TIME_RANGE) {
        globalSubject.next(
            new GlobalEvent(
                result,
                "TimeRangeSelector",
                GlobalEventTypes.TimeChange
            )
        );
    }

    function setTimeRangeChange(
        timeOption: TIME_OPTION,
        timeRange?: TIME_RANGE
    ) {
        let timeRangeToSet;
        if (timeOption === TIME_OPTION_VALUES.CUSTOM) {
            if (
                timeRange &&
                timeRange.startTime !== 0 &&
                timeRange.endTime !== 1
            ) {
                timeRangeToSet = timeRange;
            } else {
                /** Default Time Range Selection in DateRangePicker */
                timeRangeToSet = {
                    startTime: new Date(Date.now() - 21600 * 1000).valueOf(),
                    endTime: new Date().valueOf(),
                };
            }
        } else {
            const timeDuration: string = TIME_OPTION_VALUES[timeOption];
            timeRangeToSet = durationToTimeRange(DURATION[timeDuration])
        }
        setTimeRangeState(timeRangeToSet);
        globalEventTimeChange(timeRangeToSet);
    }

    /** Callback function to handle timeRange Change */
    function handleTimeWindowChange(
        timeOption: TIME_OPTION,
        timeRange?: TIME_RANGE
    ) {
        setTimeRangeChange(timeOption, timeRange);
        setTimeWindow(timeOption);
        if (timeOption === TIME_OPTION_VALUES.CUSTOM) {
            if (timeRange) {
                setTimeRange(timeRange);
            }
        } else {
            setResolution(DURATION_TO_RESOLUTION_MAP[timeOption]);
            setDuration(DURATION[timeOption]);
        }
    }

    function hasTimeRangeChanged(startTime: number, newStartTime: number, endTime: number, newEndTime: number): boolean {
        const startTimeStr = moment.utc(startTime).format('LLL');
        const newStartTimeStr = moment.utc(newStartTime).format('LLL');
        const endTimeStr = moment.utc(endTime).format('LLL');
        const newEndTimeStr = moment.utc(newEndTime).format('LLL');
        return (startTimeStr !== newStartTimeStr || endTimeStr !== newEndTimeStr) ? true : false;
    }

    function shiftForward() {
        const curTime = moment.utc().valueOf();
        const timeToShift = endTime - startTime;
        let newStartTime = endTime;
        let newEndTime = endTime + timeToShift;
        if (newEndTime > curTime) {
            newEndTime = curTime;
            newStartTime = curTime - timeToShift;
        }
        if (hasTimeRangeChanged(startTime, newStartTime, endTime, newEndTime)) {
            handleTimeWindowChange(TIME_OPTION_VALUES.CUSTOM, { startTime: newStartTime, endTime: newEndTime })
        }
    }

    function shiftBackward() {
        const timeToShift = endTime - startTime;
        const newEndTime = startTime;
        const newStartTime = startTime - timeToShift;
        if (hasTimeRangeChanged(startTime, newStartTime, endTime, newEndTime)) {
            handleTimeWindowChange(TIME_OPTION_VALUES.CUSTOM, { startTime: newStartTime, endTime: newEndTime })
        }
    }

    useEffect(() => {
        if (timeWindow) {
            setTimeRangeChange(timeWindow, timeRange);
        }
        // Disabling exhaustive-deps check because the method setTimeRange is created on every render
        // and is also used elsewhere. If it weren't used anywhere else, we could've moved it inside useEffect.
        // This is throwing a warning in console if not disabled.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeWindow]);
    return (
        <div>
            {timeRange.startTime !== 0 && (
                <div
                    className="time-range-select-view justify-content-end"
                    aria-label="time-range-selector-output"
                >
                    <div className="d-flex w-100 align-items-center justify-content-end pr-2 d-lg-none">
                        {formatToLocalTimestamp(new Date(timeRange.startTime))} - {formatToLocalTimestamp(new Date(timeRange.endTime))}
                    </div>
                    <div className="d-none d-lg-flex w-100">
                        <Button aria-label="shift date time back" className="" onClick={(e) => shiftBackward()} minimal={true}>
                            <Icon icon={IconNames.CHEVRON_LEFT} iconSize={16} className="mx-1 arrow-button" />
                        </Button>
                        <div className="mt-3" style={{ paddingLeft: "", flexGrow: 2 }}>
                            <div style={{ "width": "100%" }}>
                                <TimeRangeSlider startTime={timeRange.startTime} endTime={timeRange.endTime} resolution={resolution} onNewRangeSelection={handleRangeSelection} onNewResolutionSelection={handleResolutionSelection} />
                            </div>
                            {/*   Uncomment this if zoom control needs to be enabled in the future.
                                <div className="zoom-ctrl mt-5">
                                    <span className="center mr-2">
                                    <Icon icon={IconNames.PLUS} iconSize={12} />
                                    </span>
                                    <span style={{display:"inline-block", zIndex:9, width:"50%"}}>
                                    <RangeSlider segments={labels} id="resolutionslider" trackStepsPerUnit={1} onNewRangeSelection={handleZoomSelection} startHandlePosition={resolutionInSteps} /> 
                                    </span>
                                    <span className="center ml-2" style={{zIndex:0}} >
                                    <Icon  icon={IconNames.MINUS} iconSize={12} />
                                    </span>
                                </div> */}
                        </div>
                        <Button aria-label="shift date time forward" className="" onClick={(e) => shiftForward()} minimal={true} disabled={shiftRightButtonState}>
                            <Icon icon={IconNames.CHEVRON_RIGHT} iconSize={16} className="mx-1 arrow-button" onClick={(e) => shiftForward()} />
                        </Button>
                    </div>
                    <div className="time-range-select-view-right text-nowrap">
                        <TimePeriodViewLast
                            selectedOption={timeWindow}
                            showTimePeriodOptions={viewLastOptions}
                            durationSelectionHandler={handleTimeWindowChange}
                        />
                        <TimePeriodMoreView
                            selectedOption={timeWindow}
                            timeRange={timeRange}
                            showTimePeriodOptions={viewMoreOptions}
                            durationSelectionHandler={handleTimeWindowChange}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
