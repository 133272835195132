/** This module contains a component that represents a condition value.  A conidition is 
 *  an expression like "key operator value", so this component represents the value in 
 *  that expression.
 *  @module
 */
import React from "react";
import { DefaultFieldRenderer } from "./BuiltinValueFields";
import { WrapFieldInDisplayMode } from "../../WrapFieldInDisplayMode";

/** defines the condition value type. */
export type conditionValueType = any;

/** this interface defines the properties passed into the value field render. */
interface ValueFieldRendererProps {
    /** onChange callback when user changes the field value. */
    onChange: ({ value }: {value: conditionValueType}) => void;
    /** the value. */
    value: conditionValueType;
    /** the handler for when editing is done. */
    onDone: () => void;
}

/** this interface defines the properties passed into the ConditionValue React component. */
export interface ConditionValueProps {
    /** a string with the classes that should be applied to this component. */
    className?: string;
    /** Pass as true to make this field non-editable */
    readOnly?: boolean;
    /** Actual value. This can either be the full keyItem or just the value string */
    value?: conditionValueType;
    /** onChange callback when user changes the condition value either in the dropdown or the textbox as applicable */
    onChange?: (newValue: conditionValueType) => void;
    /** the handler for when editing is done. */
    onDone?: () => void;
    /** A custom formatter that can be used for controlling how the displayed value will be rendered */
    displayValueFormatter?: (props: { value: any }) => React.ReactNode;
    /** the render for the field. */
    fieldRenderer?: (props: ValueFieldRendererProps) => React.ReactNode;
    /** A custom renderer that can be used to completely replace the values editor control with whatever you'd like */
    customRenderer?: (props: { value?: conditionValueType }) => React.ReactNode;
}

/** Renders the condition value.  A condition is an expression like "key operator value", so this component represents 
 *      the value in that expression.
 *  @param props the properties object passed in.
 *  @returns the JSX with the condition value React component. */
export function ConditionValue ({
    className,
    readOnly = false,
    value,
    onChange,
    displayValueFormatter,
    fieldRenderer = DefaultFieldRenderer,
    customRenderer,
}: ConditionValueProps) {
    let contentsJsx;

    if (customRenderer) {
        contentsJsx = customRenderer({ value });
    } else if (readOnly) {
        const formattedValue = displayValueFormatter ?
            displayValueFormatter({ value }) :
            value;
        contentsJsx = formattedValue;
    } else {
        contentsJsx = <WrapFieldInDisplayMode
            value={value}
            displayValueFormatter={displayValueFormatter}
            onChange={onChange}
            fieldRenderer={fieldRenderer}
        />;
    }
    return <div className={"condition-value add-padding display-mode" + (className ? " " + className : "")}>
        {contentsJsx}
    </div>;
}
