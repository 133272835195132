/**
 * This is a fake api service to be used to develop in parallel with the backend while the api is not ready yet,
 * it matches 1 to 1 the real api service, it just returns what we would expect from the api (given in input)
 */
class ApiService {

    static async get(endpoint: string, params?: object, isPublic:boolean = false, response?: object, success?: boolean, timeout?: number): Promise<object> {
        return ApiService.fakeApiCall(response, success, timeout);
    }

    static async put(endpoint: string, payload?: object, isPublic:boolean = false, response?: object, success?: boolean, timeout?: number): Promise<object> {
        console.log('Mock PUT to:', endpoint);
        console.log('With payload:', payload);
        return ApiService.fakeApiCall(response, success, timeout);
    }

    static async post(endpoint: string, payload?: object, isPublic:boolean = false, response?: object, success?: boolean, timeout?: number): Promise<object> {
        console.log('Mock POST to:', endpoint);
        console.log('With payload:', payload);
        return ApiService.fakeApiCall(response, success, timeout);
    }

    static async patch(endpoint: string, payload?: object, isPublic:boolean = false, response?: object, success?: boolean, timeout?: number): Promise<object> {
        console.log('Mock PATCH to:', endpoint);
        console.log('With payload:', payload);
        return ApiService.fakeApiCall(response, success, timeout);
    }

    static async delete(endpoint: string, isPublic:boolean = false, response?: object, success?: boolean, timeout?: number): Promise<object> {
        console.log('Mock DELETE to:', endpoint);
        return ApiService.fakeApiCall(response, success, timeout);
    }


    private static async fakeApiCall(response?: object, success: boolean = true, timeout: number = 500): Promise<object> {

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                if (success) {
                    const r = response || {message: 'all good'};
                    resolve(r);
                } else {
                    const r = response || {message: 'this is an error returned by an api call'};
                    reject(r);
                }

            }, timeout);
        });

    }

}

export { ApiService };
