import React, { useRef } from 'react';
import "./Sortable.scss";

export interface SortableItem {
    record: any;
    contents?: React.ReactNode;
}

export interface SortableProps {
    items: Array<SortableItem>;
    onChange: (reorderedItems: Array<SortableItem>) => void;
    direction?: "vertical" | "horizontal";
    /** If this flags is passed as true, then dragging will happen only
     * when started from elements within the child that has class name
     * `drag-handle` in it */
    dragOnlyUsingHandles?: boolean;
    /** Callback for when user starts dragging an item to sort */
    onDragStart?: () => void;
    /** Callback for when user either lets the dragged item go or drops it in a spot thereby completing a reorder */
    onDragEnd?: () => void;
}

export function Sortable ({
    items,
    onChange,
    direction = "vertical",
    dragOnlyUsingHandles = false,
    onDragStart,
    onDragEnd,
}: SortableProps) {

    function reorderList (fromIndex:number, toIndex:number) {
        if (fromIndex !== toIndex && toIndex !== fromIndex + 1) {
            const sourceItem = items[fromIndex];
            const goingUp = toIndex > fromIndex;
            const selectedItemsCopy = [...items];
            selectedItemsCopy.splice(fromIndex, 1);
            selectedItemsCopy.splice(toIndex + (goingUp ? -1 : 0), 0, sourceItem);
            onChange(selectedItemsCopy);
        }
    };
    const dragState = useRef<{
        draggedElementIndex: number,
        draggedElement?: Element,
        lastDraggedOverElement?: Element,
    }>({
        draggedElementIndex: -1
    });
    const sortableContainer = useRef<HTMLDivElement|null>(null);
    const mouseDownOnDragHandle = useRef(false);
    return (<div
        className={"sortable " + direction + " " + (dragOnlyUsingHandles ? "drag-handles-only" : "drag-anywhere")}
        ref={sortableContainer}
    >
    {
        items.map((item, index) => {
            const dragHandleProps = dragOnlyUsingHandles ? {
                    onMouseDown: e => {
                        if ((e.target as Element).classList?.contains("drag-handle") || (e.target as Element).closest(".drag-handle")) {
                            mouseDownOnDragHandle.current = true;
                        }
                    },
                    onMouseUp: () => {
                        mouseDownOnDragHandle.current = false;
                    }
                } : {};
            return <div
                key={item.record?.id || index}
                className="sort-content"
                data-item-index={index}
                draggable={true}
                {...dragHandleProps}
                onDragStart={e => {
                    const okToDrag = dragOnlyUsingHandles === false || mouseDownOnDragHandle.current;
                    if (okToDrag) {
                        e.currentTarget.classList.add("dragging");
                        e.dataTransfer.effectAllowed = "move";
                        dragState.current.draggedElement = e.currentTarget;
                        dragState.current.draggedElementIndex = Number(e.currentTarget.getAttribute("data-item-index"));
                        sortableContainer.current?.classList.add("drag-in-progress");
                        if (onDragStart) {
                            onDragStart();
                        }
                    } else {
                        e.preventDefault();
                    }
                }}
                onDragEnd={e => {
                    e.currentTarget.classList.remove("dragging");
                    sortableContainer.current?.classList.remove("drag-in-progress");
                    if (dragState.current?.lastDraggedOverElement) {
                        dragState.current.lastDraggedOverElement.classList.remove("dragged-over");
                        if (dragState.current.draggedElement) {
                            const sourceTagIndex = Number(dragState.current.draggedElement.getAttribute("data-item-index"));
                            const targetTagIndex = Number(dragState.current.lastDraggedOverElement.getAttribute("data-item-index"));
                            reorderList(sourceTagIndex, targetTagIndex);
                        }
                    }
                    dragState.current = { draggedElementIndex: -1 };
                    if (onDragEnd) {
                        onDragEnd();
                    }
                }}
                onDragEnter={e => {
                    e.dataTransfer.dropEffect = "move";
                    // If dragged over element is not the element being dragged
                    if (dragState.current?.draggedElement !== e.currentTarget) {

                        const sourceTagIndex = dragState.current.draggedElementIndex;
                        const targetTagIndex = Number(e.currentTarget.getAttribute("data-item-index"));
                        if (targetTagIndex !== sourceTagIndex + 1) {
                            e.currentTarget.classList.add("dragged-over");
                            if (dragState.current?.lastDraggedOverElement && dragState.current.lastDraggedOverElement !== e.currentTarget) {
                                dragState.current.lastDraggedOverElement.classList.remove("dragged-over");
                            }
                            dragState.current.lastDraggedOverElement = e.currentTarget;
                        }
                    }
                }}
                onDragOver={e => {
                    // Prevent ghost copy from flying back after drop is completed.
                    // TBD: Doesn't work when user drags over an element but then drags it away and drops it. It would be nice to fix this when possible
                    e.preventDefault();
                }}              
            >
                {item.contents || (typeof item.record === "string" ? item.record : JSON.stringify(item.record))}
            </div>
        })
    }
    <div
        className="sort-content drop-in-end"
        data-item-index={items.length}
        onDragEnter={e => {
            e.dataTransfer.dropEffect = "move";
            e.currentTarget.classList.add("dragged-over");
            if (dragState.current?.lastDraggedOverElement && dragState.current.lastDraggedOverElement !== e.currentTarget) {
                dragState.current.lastDraggedOverElement.classList.remove("dragged-over");
            }
            dragState.current.lastDraggedOverElement = e.currentTarget;
        }}
        onDragOver={e => {
            // Prevent ghost copy from flying back after drop is completed.
            // TBD: Doesn't work when user drags over an element but then drags it away and drops it. It would be nice to fix this when possible
            e.preventDefault();
        }}              
    ></div>
    </div>);
}
