import React from 'react';
import classNames from 'classnames';
import { Classes, Divider } from '@blueprintjs/core';
import { Form, InputField } from 'components/common/form';
import { LangEN, STRINGS } from 'app-strings';
import * as yup from 'yup';
import { FormikProps } from 'formik';

export interface IBasicAuthParamsPanelProps {
	isEdit: boolean;
	authProfileUsername?: string;
	authProfilePassword?: string;
	onChangeUsername: (event) => void;
	onChangePassword: (event) => void;
}


const BasicAuthParamsPanel: React.FC<IBasicAuthParamsPanelProps> = (props) => {
	const translations: LangEN["thirdPartyIntegrations"]["addAuthProfile"]["panels"]["basicAuthParams"] = STRINGS.thirdPartyIntegrations.addAuthProfile.panels.basicAuthParams;
	const validationSchema = yup.object().shape({
		profile_username: yup
			.string()
			.required()
			.max(2048)
			.label(translations.fields.profileUsername.label),
		profile_password: props.isEdit ?
			yup
				.string()
				.max(2048)
				.label(translations.fields.profilePassword.label) :
			yup
				.string()
				.required()
				.max(2048)
				.label(translations.fields.profilePassword.label)
	});

	return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{
						translations.title
					}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={{
					profile_username: props.authProfileUsername,
					profile_password: props.authProfilePassword,
				}}
				loading={false}
				validationSchema={validationSchema}
			>
				{(formProps: FormikProps<object>) =>
					<>
						<InputField
							name="profile_username"
							type="text"
							required={true}
							label={
								translations.fields.profileUsername.label
							}
							placeholder={
								translations.fields.profileUsername.placeholder
							}
							onBlur={(event) => {
								const value = event.target.value?.trim();

								formProps.handleBlur(event);
								props.onChangeUsername({
									target: {
										value: value
									}
								});
							}}
							onChange={props.onChangeUsername}
							value={props.authProfileUsername}
							disabled={false}
						/>
						<InputField
							name="profile_password"
							type="password"
							showPasswordOption={true}
							required={true}
							label={translations.fields.profilePassword.label}
							placeholder={
								props.isEdit ?
									translations.fields.profilePassword.placeholderForEdit :
									translations.fields.profilePassword.placeholder
							}
							onBlur={(event) => {
								const value = event.target.value?.trim();

								formProps.handleBlur(event);

								props.onChangePassword({
									target: {
										value: value
									}
								});
							}}
							onChange={(e) => {
								props.onChangePassword(e)
							}}
							value={props.authProfilePassword}
							disabled={false}
						/>
					</>
				}
			</Form>
		</div>
	);
};

export { BasicAuthParamsPanel };
