import React from 'react';
import { ErrorMessage, useFormikContext } from "formik";

export const FieldError = (props: { name: string, className?: string }) => {
    const formikContext = useFormikContext();
    const isError = formikContext.errors[props.name];
    if(isError) {
        return (
            <span className={ `field-error ${ props.className }` }>
                <ErrorMessage name={ props.name }/>
            </span>
        )
    } else {
        return null;
    }
}
