/** This module contains utilities for editing transform nodes and validating transform nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "../../types/GraphTypes";
import { GenericKey, NodeUtils } from "../../../../../utils/runbooks/NodeUtil";
import { DataOceanKey, DataOceanMetadata } from "../data-ocean/DataOceanMetadata.type";
import { KeyDefinition } from "./OutputDataElement";
import { DataOceanUtils } from "../data-ocean/DataOceanUtils";
import { getProperties } from "utils/runbooks/RunbookUtils";
import { OUTPUT_ELEMENTS_LIMIT } from "./OutputDataBlock";
import { OutputDefinitionMethods } from "./TransformNodeEditor";
import { RunbookContext, VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { INCIDENT_SCOPE, RUNTIME_SCOPE, SUBFLOW_SCOPE, StructuredVariableType } from "utils/runbooks/VariablesUtils";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";

export type KeysMetadata = {
    /** the keys that are supported by the Data Ocean. */
    keys: Record<string, DataOceanKey>;
}

/** an enum with all of the valid transform node properties. */
export enum TRANSFORM_NODE_EDIT_PROPS {
    /** the transform template property. */
    TRANSFORM_TEMPLATE = "transformTemplate",
    /** the output data format property. */
    OUTPUT_DATA_FORMAT   = "outputDataFormat",
    /** the synthetic metrics property. */
    SYNTH_METRICS   = "synthMetrics",
    /** the synthetic keys property. */
    SYNTH_KEYS      = "synthKeys",
    /** debug property */
    DEBUG = 'debug',
    /** the variable where to load from. */
    USE_VARIABLE_DEFINITION   = "useVariableDefinition",
}

/** this interface defines a transform key, which extends a generic key and adds the 
 *  additional attributes needed by the transform node. */
export interface TransformKey extends GenericKey {
    /** the data ocean id if this key is tied to a data ocean key.  If it is not tied to the data ocean then
     *  this will be undefined. */
    dataOceanId?: string;
    /** if this key is tied to a data ocean key, this property contains the expanded list of data ocean 
     *  subkeys that are in the data ocean meta data. */
    dataOceanKeys?: Array<GenericKey>;
    /** a boolean value, true if this is a required key, false otherwise. */
    required?: boolean;
    /** a boolean value, true if this is a synthetic key, false otherwise. */
    synthetic?: boolean;
}

/** Utility class for transform node,*/
export class TransformNodeUtils extends NodeUtils {
    /** the error messages for the transform node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.transformNode;
    
    /** Check if transform node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on all the nodes.
     *  @param variables the map of variables by scope.
     *  @param customProperties the array of CustomProperty objects with the custom properties for 
     *       all the entity types. 
     *  @returns  is node valid. */
    static isNodeValid(
        nodeId: string | undefined | null, graphDef: GraphDef, variables: VariableContextByScope,
        customProperties: CustomProperty[]
    ): boolean {
        if (!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        this.validateNode(nodeId, errors, graphDef, variables, customProperties)
        return errors.length === 0;
    }

    /** Check if a transform node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes.
     *  @param variables the map of variables by scope. 
     *  @param customProperties the array of CustomProperty objects with the custom properties for 
     *       all the entity types. */
    static validateNode(
        nodeId: string, errors: string[], graphDef: GraphDef, variables: VariableContextByScope, 
        customProperties: CustomProperty[]
    ): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables, customProperties);
        TransformNodeUtils.validateNodePropertiesFromGraphDef(curNode, getProperties(curNode), errors, curNode?.name, variables);
    }

    /** Validates the property values for a given transform node
     *  @param selectedNode the UniversalNode that wraps the react-flow node.
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @param variables the map of variables by scope.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodePropertiesFromGraphDef(
        selectedNode: NodeDef | undefined, currentProperties: Record<string, any>, errors: Array<string>, label: string,
        variables?: VariableContextByScope
    ): Array<string> {
        if (!currentProperties.transformTemplate) {
            errors.push(TransformNodeUtils.errMsgs.transformTemplate);
        }
        if (!currentProperties.outputDataFormat) {
            errors.push(TransformNodeUtils.errMsgs.outputDataFormat);
        }
        if (currentProperties.synthKeys?.length === 0 && currentProperties.synthMetrics?.length === 0) {
            errors.push(TransformNodeUtils.errMsgs.oneKeyOrMetric);
        }
        if (currentProperties.synthKeys?.length > OUTPUT_ELEMENTS_LIMIT || currentProperties.synthMetrics?.length > OUTPUT_ELEMENTS_LIMIT) {
            errors.push(TransformNodeUtils.errMsgs.tooManyKeysOrMetrics);
        }
        if (currentProperties.synthKeys?.length > 0) {
            const error = TransformNodeUtils.validateNewLabelsNotInDataOceanLabels(currentProperties.synthKeys);
            if (error) {
                errors.push(error);
            }
        }
        if (currentProperties.useVariableDefinition) {
            const structuredVariables: string[] = NodeUtils.getStructuredVariablesList(variables);
            const variableScope = currentProperties.useVariableDefinition.split('.')[0];
            if (!structuredVariables.includes(currentProperties.useVariableDefinition)) {
                if (variableScope === RUNTIME_SCOPE) {
                    errors.push(TransformNodeUtils.errMsgs.incompatibleRuntimeVariable);
                } else if (variableScope === INCIDENT_SCOPE) {
                    errors.push(TransformNodeUtils.errMsgs.incompatibleIncidentVariable);
                } else if (variableScope === SUBFLOW_SCOPE) {
                    errors.push(TransformNodeUtils.errMsgs.incompatibleSubflowVariable);
                }
            } else {
                // Check if a key or metric was deleted from variable definition and it is used in the transform node
                const variableDefinition = variables?.[variableScope === SUBFLOW_SCOPE ? RUNTIME_SCOPE : variableScope]["structuredVariables"].find((item) => item.name === currentProperties.useVariableDefinition);
                const error = TransformNodeUtils.validateMissingKeysOrMetrics(variableDefinition, currentProperties);
                if (error) {
                    errors.push(error);
                }
            }
        }
        return errors;
    }

    /** Validates the property values for a given transform node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        currentProperties: Record<string, any>, errors: Array<string>
    ): Array<string> {
        if (!currentProperties.transformTemplate) {
            errors.push(TransformNodeUtils.errMsgs.transformTemplate);
        }
        if (!currentProperties.outputDataFormat) {
            errors.push(TransformNodeUtils.errMsgs.outputDataFormat);
        }
        if (currentProperties.outputDataProperties.length === 0 && currentProperties.outputDataMetrics.length === 0) {
            errors.push(TransformNodeUtils.errMsgs.oneKeyOrMetric);
        }
        if (currentProperties.outputDataProperties.length > OUTPUT_ELEMENTS_LIMIT || currentProperties.outputDataMetrics.length > OUTPUT_ELEMENTS_LIMIT) {
            errors.push(TransformNodeUtils.errMsgs.tooManyKeysOrMetrics);
        }
        if (currentProperties.outputDataProperties.length > 0) {
            const error = TransformNodeUtils.validateNewLabelsNotInDataOceanLabels(currentProperties.outputDataProperties);
            if (error) {
                errors.push(error);
            }
        }
        if (currentProperties.outputDefinitionMethod === OutputDefinitionMethods.USE_VARIABLE_DEFINITION && !currentProperties.useVariableDefinition) {
            errors.push(TransformNodeUtils.errMsgs.varToLoadFrom);
        }
        return errors;
    }

    /** Validates that the new added labels for keys or metrics do not match any existent data ocean label
     *  @param properties the output data keys configured for node
     *  @returns an error message if applicable. */
    static validateNewLabelsNotInDataOceanLabels(properties) {
        const allLabels = TransformNodeUtils.getAllDataOceanLabels();
        for (const data of properties) {
            if (data.type === 'string' && data.label?.length === 0) {
                return TransformNodeUtils.errMsgs.emptyVariable;
            }

            if (!data.dataOceanId) {
                if (allLabels.includes(data.label)) {
                    return TransformNodeUtils.errMsgs.labelInDataOcean;
                }
            }
        }
    }

    /** Function to get a list with all the labels existend in data ocean metadata
     *  @returns a list of strings with all data ocean labels. */
    static getAllDataOceanLabels() {
        const objMetaData = DataOceanUtils.dataOceanMetaData;
        var dataOceanLabels: Array<string> = [];
        for (const element in objMetaData.keys) {
            dataOceanLabels.push(objMetaData.keys[element].label);
            const objKeys = objMetaData.keys as Object;
            let nestedElements = TransformNodeUtils.getCompleteExpandedKeys(objKeys as KeysMetadata, element);
            for (const nestedElement of nestedElements) {
                dataOceanLabels.push(nestedElement.label);
            }
        }
        return dataOceanLabels;
    }
    
    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param inputType the object type that is currently being displayed in the logic node.
     *  @returns an array with all the key definitions. */
    static getCompleteExpandedKeys(objMetricMetaData: KeysMetadata, inputType: string): Array<KeyDefinition> {
        const expandedKeys: Array<KeyDefinition> = [];
        if (objMetricMetaData && inputType) {
                const keyDef = objMetricMetaData[inputType];
                if (keyDef?.type === "object") {
                    TransformNodeUtils.expandCompleteKeyProperties(keyDef, inputType, expandedKeys);
                } else {
                    expandedKeys.push({
                        "label": objMetricMetaData[inputType]?.label,
                        "id": inputType,
                        "type": objMetricMetaData[inputType]?.type,
                        "unit": objMetricMetaData[inputType]?.unit ? objMetricMetaData[inputType].unit : "none",
                        "required": objMetricMetaData[inputType]?.primary_key ? objMetricMetaData[inputType].primary_key : false
                    });
                }
        }
        return expandedKeys;
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param keyDef the Data Ocean key definition or one of the property definitions.
     *  @param parents a string with the parent information that should be appended to the key id.
     *  @param expandedKeys an array with all the keys in it for example {id: network_server.ipaddr, label: "Host", type: "string"}. */
    static expandCompleteKeyProperties(keyDef: DataOceanKey, parents: string, expandedKeys: Array<KeyDefinition>): void {
        if (!keyDef.properties) {
            expandedKeys.push({ id: parents, label: keyDef.label, type: keyDef.type, unit: keyDef.unit ? keyDef.unit : "none", required: keyDef.primary_key ? keyDef.primary_key : false});
        } else {
            for (const property in keyDef.properties) {
                TransformNodeUtils.expandCompleteKeyProperties(keyDef.properties[property], parents + "." + property, expandedKeys);
            }
        }
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param objMetricMetaData the JSON with the Data Ocean API details.
     *  @param customProperties the array of CustomProperty objects that has the list of custom properties for all entity types.
     *  @param synthKeys the array of transform node keys.
     *  @returns an array with all the key definitions. */
    static getExpandedKeysForSynthKeys(objMetricMetaData: DataOceanMetadata, customProperties: CustomProperty[], synthKeys: Array<TransformKey>): Array<TransformKey> {
        let expandedTransformKeys: Array<TransformKey> = [];
        synthKeys.forEach((key) => {
            if (key.dataOceanId) {
                let expandedKeys: Array<GenericKey> = key.dataOceanKeys || [];
/*
                let expandedKeys: Array<GenericKey> = [];
                const keyId = key.dataOceanId;
                const keyDef = objMetricMetaData.keys[keyId];
                NodeUtils.expandKeyProperties(keyDef, keyId, expandedKeys);
*/                
                expandedTransformKeys = expandedTransformKeys.concat(expandedKeys);
                RunbookContext.addCustomPropertiesToExpandedKeys(expandedTransformKeys, key.dataOceanId, customProperties);
            } else {
                expandedTransformKeys.push({...key, synthetic: true});
            }
        });
        return expandedTransformKeys;
    }

    /** returns whether or not the specified object type has any of the specified keys.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param synthKeys the array of transform node keys.
     *  @param keys a string array with the keys to check for.  If any match then the function should return true.
     *  @returns returns true if any of the keys are used by this object type. */
    static hasAnyKey(objMetricMetaData: DataOceanMetadata, synthKeys: Array<TransformKey>, keys: Array<string>): boolean {
        if (keys && objMetricMetaData && synthKeys?.length) {
            for (const key of synthKeys) {
                if (key.dataOceanId) {
                    const keyId = key.dataOceanId;
                    if (keys.includes(keyId)) {
                        return true;
                    } else if (DataOceanUtils.hasAnySubKey(DataOceanUtils.dataOceanMetaData.keys[keyId].properties, keys)) {
                        return true;
                    }
                }
            };    
        }
        return false;
    }

    /** Checks if all the subkeys in metadata match the subkeys saved in the runbook definition
     *  @param node node information from graph definition
     *  @returns a warning message if applicable. */
    static allSubkeysInMetadata(node: NodeDef) {
        const keysMetaData = DataOceanUtils.dataOceanMetaData.keys as Object;
        const runbookDefSubkeys = node?.properties?.find((item) => item.key === "synthKeys")?.value;
        for (const keyElem of runbookDefSubkeys) {
            if (keyElem.dataOceanId) {
                const expandedKeys = TransformNodeUtils.getCompleteExpandedKeys(keysMetaData as DataOceanMetadata, keyElem.dataOceanId);
                let keysInGraph: Array<string> = [];
                let keysInMetadata: Array<string> = [];
                for (const key of keyElem.dataOceanKeys) {
                    keysInGraph.push(key.id);
                }
                for (const key of expandedKeys) {
                    keysInMetadata.push(key.id);
                }
                const diff =  keysInMetadata.filter((element) => {
                    return !keysInGraph.includes(element);
                });
                if (diff.length > 0) {
                    return TransformNodeUtils.errMsgs.keysNotInSyncWithMetadata;
                }
            }
        }
    }

    /** Validates that all keys and metrics from transform node
     * are present in the definition of the variable that is used
     *  @param variableDefinition the definition of the variable that is used in the transform node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @returns an the corresponding error if it's the case. */
    static validateMissingKeysOrMetrics(variableDefinition, currentProperties: Record<string, any>) {
        const transformKeys: string[] = [];
        const transformMetrics: string[] = [];
        const variableKeys: string[] = [];
        const variableMetrics: string[] = [];
        if (variableDefinition.type !== StructuredVariableType.CUSTOM) {
            for (const key of currentProperties.synthKeys[0].dataOceanKeys) {
                transformKeys.push(key.label);
            }
        } else {
            for (const key of currentProperties.synthKeys) {
                transformKeys.push(key.label);
            }
        }
        for (const metric of currentProperties.synthMetrics) {
            transformMetrics.push(metric.label);
        }
        for (const key of variableDefinition.keys) {
            variableKeys.push(key.label);
        }
        for (const metric of variableDefinition.metrics) {
            variableMetrics.push(metric.label);
        }
        let missingKeys = transformKeys.filter(item => variableKeys.indexOf(item) < 0);
        let missingMetrics = transformMetrics.filter(item => variableMetrics.indexOf(item) < 0);
        if (missingKeys.length > 0 || missingMetrics.length > 0) {
            return TransformNodeUtils.errMsgs.incompatibleKeysOrMetrics;
        }
    }
}
