import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { itemType } from "../common/select/SelectInput";

/** an enum which defines all the valid incident status values. */
export enum INCIDENT_STATUS {
    NEW             = "NEW",
    INVESTIGATING   = "INVESTIGATING",
    ON_HOLD         = "ON_HOLD",
    CLOSED          = "CLOSED",
    DISMISSED       = "DISMISSED",
};

/** A map of index values for incident statuses to be used when sorting. */
export const INCIDENT_STATUS_INDEX = {
    [INCIDENT_STATUS.DISMISSED]: 1,
    [INCIDENT_STATUS.CLOSED]: 2,
    [INCIDENT_STATUS.ON_HOLD]: 3,
    [INCIDENT_STATUS.INVESTIGATING]: 4,
    [INCIDENT_STATUS.NEW]: 5,
}

/** a constant which maps the status enum value to a label. */
export const INCIDENT_STATUS_TO_LABEL_MAP: Record<string, string> = {
    [INCIDENT_STATUS.NEW]:              STRINGS.incidentStatus.new,
    [INCIDENT_STATUS.INVESTIGATING]:    STRINGS.incidentStatus.investigating,
    [INCIDENT_STATUS.ON_HOLD]:          STRINGS.incidentStatus.onHold,
    [INCIDENT_STATUS.CLOSED]:           STRINGS.incidentStatus.closed,
    [INCIDENT_STATUS.DISMISSED]:        STRINGS.incidentStatus.dismissed,
}

/** a constant which maps the status enum values to it's matching icon. */
export const INCIDENT_STATUS_TO_ICON_MAP: Record<string, string> = {
    [INCIDENT_STATUS.NEW]:              IconNames.NOTIFICATIONS,
    [INCIDENT_STATUS.INVESTIGATING]:    IconNames.USER,
    [INCIDENT_STATUS.ON_HOLD]:          IconNames.STOPWATCH,
    [INCIDENT_STATUS.CLOSED]:           IconNames.TICK_CIRCLE,
    [INCIDENT_STATUS.DISMISSED]:        IconNames.TRASH,
}

/***
 * returns a list of label value pair of statuses
 */
export const getIncidentStatusItemsListForDropdown = (): Array<itemType> => {
    const itemsList = new Array<itemType>();

    Object.keys(INCIDENT_STATUS).filter(status => {
        return !HIDDEN_STATE_KEYS.includes(status);
    }).forEach( p => {
        const label = INCIDENT_STATUS_TO_LABEL_MAP[INCIDENT_STATUS[p]] || "";
        itemsList.push({
            value: p,
            label: label
        });
    })
    return itemsList;
};

/** this constant contains the keys for the hidden states. */
export const HIDDEN_STATE_KEYS: string[] = ["ON_HOLD", "DISMISSED"];
/** this constant contains the INCIDENT_STATUSes that are hidden. */
export const HIDDEN_STATES: INCIDENT_STATUS[] = [INCIDENT_STATUS.ON_HOLD, INCIDENT_STATUS.DISMISSED];
