
import { useLayoutEffect } from 'react';

const useAutoSizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
) => {
  const MAX_HEIGHT = 500;

  // this will calculate the height of textArea before DOM paints
  useLayoutEffect(() => {
    if (textAreaRef) {
        const scrollHeight = textAreaRef.scrollHeight;

        if (scrollHeight <= MAX_HEIGHT) {
            // the height value needs to be reseted first
            textAreaRef.style.height = 'inherit';
            textAreaRef.style.height = `${scrollHeight}px`;
        } else {
          textAreaRef.style.height = `${MAX_HEIGHT}px`
        }
    }
  }, [textAreaRef, textAreaRef?.value]);

  return textAreaRef;
};

export default useAutoSizeTextArea;