import React from "react";
import { Intent, Callout } from '@blueprintjs/core';
import { SinglePageLayout } from 'components/app/layouts/single-page/SinglePageLayout';
import { STRINGS } from 'app-strings';
import './ErrorPage.scss'

export type ErrorProps = {
    title?: string,
    errorCode?: string,
    errorMessage?: string
    code?: string,
    message?: string,
    suggestion?: string
}

let link = (
    <a
        href="https://support.riverbed.com/content/support/contact_support.html"
        target="_blank"
        rel="noopener noreferrer"
    >
        {STRINGS.SUPPORT_PAGE.techSupport.linkLabel}
    </a>
);
const renderContent = (content?: string, label?: string) => {
    if (content) {
        return (
            <p><b>{label}: </b>{content}</p>
        );
    }
};

export const ErrorPage = (props: { error: ErrorProps }) => {
    const { error } = props;
    console.error(error);
    return (
        <SinglePageLayout logo={{ show: true }}>
            <div className="error-page">
                <Callout intent={Intent.DANGER} title={error.title ? error.title : STRINGS.ERRORS.defaultTitle}>
                    <div className="error-content">
                        {renderContent(error.errorCode || error.code, STRINGS.ERRORS.LABELS.code)}
                        {renderContent(
                            error.errorMessage || error.message || STRINGS.ERRORS.defaultMessage,
                            STRINGS.ERRORS.LABELS.message
                        )}
                        {renderContent(error.suggestion, STRINGS.ERRORS.LABELS.suggestion)}
                    </div>
                </Callout>
                <br/>
                <Callout className="support"
                         intent={Intent.PRIMARY}>{link} {STRINGS.ERRORS.supportLinkText}</Callout>
            </div>
        </SinglePageLayout>
    )
};
