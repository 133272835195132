/** This module contains utilities for creating uuids and guids.
 *  @module
 */
import { v4, v5 } from 'uuid';

/** returns a v4 uuid.
 *  @returns a string with a V4 uuid.*/
export function getUuidV4(): string {
    return v4();
}

/** returns a v5 uuid.
 *  @param name a String with the name.
 *  @param namespace a String with the namespace uuid.
 *  @returns a string with a V5 uuid.*/
 export function getUuidV5(name, namespace): string {
    return v5(name, namespace);
}
