/** This module contains a set of utilities for getting and setting filters in the user preferences.
 *  @module
 */
import { FILTER_IN_USER_PREFS_KEY } from "utils/services/UserPrefsTypes";
import { FILTERS_OBJECT } from "./GlobalFiltersStore";
import { getUserPreferences, setUserPreferences } from "./UserPreferencesStore";
import { FILTER_NAME } from "utils/hooks";

/** A util-method to fetch and parse filters from user preferences
 *  By default, this will get filters stored globally per user.
 *  If you want to store filters specific to individual pages,
 *  you have to pass it's key from user preference as it's input parameter.
 *  @param filtersInUserPrefsKey the FILTER_IN_USER_PREFS_KEY key to clear out the specified filters.
 *  @returns a Promise that resolves to the resultant filter object. */
export async function getFiltersFromUserPreference (filtersInUserPrefsKey = FILTER_IN_USER_PREFS_KEY.global): Promise<FILTERS_OBJECT> {
    const preferences = await getUserPreferences();
    if (preferences && preferences[filtersInUserPrefsKey]) {
        // The || "{}" is just to make typescript happy
        return JSON.parse(preferences[filtersInUserPrefsKey] || "{}");
    } else {
        return {};
    }
}

/** A Util method to merge/override and save filters to user preferences.
 *  If merge is true, then we'll merge the passed filters object on top of whatever exists already in
 *  User preferences object. If some sort of a complicated merge is to be performed, it can be done
 *  externally and the pre-merged object can be passed in with the second optional merge flag as false.
 *  @param updatedFilters the FILTERS_OBJECT with the filters to be updated.
 *  @param filtersInUserPrefsKey the FILTER_IN_USER_PREFS_KEY key to clear out the specified filters.
 *  @returns a Promise that resolves to the resultant filter object. */
export async function saveFiltersToUserPreference (updatedFilters: FILTERS_OBJECT, merge = true, filtersInUserPrefsKey = FILTER_IN_USER_PREFS_KEY.global): Promise<FILTERS_OBJECT> {
    if (merge) {
        const currentFilters = await getFiltersFromUserPreference();
        Object.assign(currentFilters, updatedFilters);
        await setUserPreferences({
            [filtersInUserPrefsKey]: JSON.stringify(currentFilters)
        })
    } else {
        await setUserPreferences({
            [filtersInUserPrefsKey]: JSON.stringify(updatedFilters)
        })
    }
    return getFiltersFromUserPreference();
}

/** A Util method to clear one or more filters and save it to user preferences.
 *  @param filtersToClear the Array of FILTER_NAMEs to clear.
 *  @param filtersInUserPrefsKey the FILTER_IN_USER_PREFS_KEY key to clear out the specified filters.
 *  @returns a Promise that resolves to the resultant filter object. */
export async function clearFiltersFromUserPreference (filtersToClear: Array<FILTER_NAME>, filtersInUserPrefsKey = FILTER_IN_USER_PREFS_KEY.global): Promise<FILTERS_OBJECT> {
    // Updated filters stored in user preferences. Take care not do clear any values already stored for filters
    /// that aren't being consumed by this instance of GlobalFilters control
    const filtersFromUserPrefs = await getFiltersFromUserPreference();
    for (const filterID of filtersToClear) {
        if (filtersFromUserPrefs[filterID] !== undefined) {
            delete filtersFromUserPrefs[filterID];
        }
    }
    // Passing merge flag as false because we've performed the merge and deleted the keys of filters that got cleared
    await saveFiltersToUserPreference(filtersFromUserPrefs, false, filtersInUserPrefsKey);
    return getFiltersFromUserPreference(filtersInUserPrefsKey);
}
