import React, { useEffect, useState } from "react";
import {
    InstalledIntegrationStatus,
    IntegrationErrorDetails,
} from "../types/IntegrationTypes";
import { LangEN, STRINGS } from "app-strings";
import { IconNames, LoadingOverlay, Modal } from "@tir-ui/react-components";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IntegrationErrorItem from "../components/IntegrationErrorItem";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";

export interface IntegrationErrorInfo {
    status: InstalledIntegrationStatus;
    error: IntegrationErrorDetails;
}

export const IntegrationErrorModal = React.forwardRef((props: any, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorInfo, setErrorInfo] = useState<IntegrationErrorInfo | null>();
    const [integrationId, setIntegrationId] = useState<string | null>(null);
    const [integrationName, setIntegrationName] = useState<string | null>(null);

    useEffect(() => {
        const getStatusDetails = async (integrationId: string) => {
            try {
                setLoading(true);
                const statusDetails =
                    await IntegrationLibraryService.getIntegrationStatus(
                        integrationId
                    );

                if (statusDetails) {
                    setErrorInfo(statusDetails);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        if (integrationId) {
            getStatusDetails(integrationId);
        }
    }, [integrationId]);

    const errorData = errorInfo?.error;
    const mainErrorId = errorData?.innererror?.ErrorId;
    const translations: LangEN['INTEGRATIONS_PAGE']['errorModal'] = STRINGS.INTEGRATIONS_PAGE.errorModal;

    /**
     * Actions to control the Connector Modal from outside
     */
    React.useImperativeHandle(ref, () => ({
        toggleOpen() {
            setIsOpen(!isOpen);
        },
        setIntegrationId(id: string) {
            if (id) {
                setIntegrationId(id);
            }
        },
        setIntegrationName(name: string) {
            if (name) {
                setIntegrationName(name);
            }
        },
    }));

    /**
     * Handles closing of the modal
     */
    function handleCloseModal() {
        setIntegrationId(null); // reset active tab
        setIntegrationName(null); // reset active tab
        setErrorInfo(null);
        setIsOpen(false);
    }

    if (!errorData || !isOpen) {
        return <></>;
    }

    function renderError(error: IntegrationErrorDetails, level: number) {
        return <IntegrationErrorItem error={error} indentLevel={level} />;
    }

    /** Render the MODAL */
    return (
        <Modal
            title={STRINGS.formatString(
                translations.title,
                integrationName || ""
            )}
            onSubmit={() => {}}
            onClose={() => handleCloseModal()}
            usePortal={true}
            customLabels={{
                submit: translations.close,
            }}
            hideCancel={true}
        >
            <LoadingOverlay visible={loading}/>
            <div className="container">
                <Callout intent={Intent.DANGER} title={translations.calloutTitle}>
                    <h2 className="mt-2 font-size-medium">{translations.errorCodeTitle}</h2>
                    <CopyToClipboard text={mainErrorId}>
                        <Button
                            small
                            minimal
                            rightIcon={IconNames.DUPLICATE}
                            onClick={() => {}}
                            text={mainErrorId}
                        />
                    </CopyToClipboard>
                    <h2 className="mt-2 font-size-medium">{translations.errorDetailsTitle}</h2>
                    <ul>{renderError(errorData, 0)}</ul>
                </Callout>
            </div>
        </Modal>
    );
});
