/** This module contains the component for displaying a set of pagination controls that are independed of 
 *  the table.
 *  @module
 */
import React, { ReactNode, useState, useEffect } from "react";
import { Button, HTMLSelect } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { IconNames } from "@tir-ui/react-components";

/** This interface defines the properties passed into the pagination control React component.*/
interface PaginationControlProps {
    /** an integer with the index of the currently visible page. */
    pageIndex: number;
    /** the total number of pages. */
    numberOfPages: number;
    /** a boolean value, true if the table is loading, false otherwise. */
    loading: boolean;
    /** the default page size. */
    defaultPageSize?: number;
    /** the handler for page selection events. */
    onPageSelected?: (pageIndex: number) => void;
    /** the handler for page size selection events. */
    onPageSizeSelected?: (pageSize: number) => void;
}

/** Renders the pagination control view.
 *  @param props the properties passed in.
 *  @returns JSX with the pagination control component.*/
const PaginationControl = (props: PaginationControlProps) => {
    const [displayedPages, setDisplayedPages] = useState<{min: number, max: number}>({min: 0, max: Math.min(10, props.numberOfPages - 1)});

    useEffect(
        () => {
            setDisplayedPages({min: 0, max: Math.min(10, props.numberOfPages)});
        },
        [props.numberOfPages]
    );

    useEffect(
        () => {
            if (props.pageIndex === 0 && displayedPages.min > 0) {
                setDisplayedPages({min: 0, max: Math.min(10, props.numberOfPages)});
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.pageIndex]
    );

    let paginationButtons:Array<ReactNode> = [];
    if (props.pageIndex >= 9) {
        paginationButtons.push(<Button
            rightIcon={IconNames.DOUBLE_CHEVRON_LEFT}
            minimal
            key="page-start"
            data-testid="page-start"
            onClick={e => {
                if (props.onPageSelected) {
                    props.onPageSelected(0);
                }
                setDisplayedPages({min: 0, max: Math.min(10, props.numberOfPages)});
            }}
            disabled={props.loading || props.pageIndex === 0}
        />);    
    }

    paginationButtons.push(<Button
        icon={IconNames.CHEVRON_LEFT}
        minimal
        key="page-prev"
        data-testid="page-prev"
        onClick={(e) => {
            if (props.onPageSelected) {
                props.onPageSelected(props.pageIndex - 1);
            }
            if ((props.pageIndex - 1) === (displayedPages.min) && (props.pageIndex - 1) > 0) {
                setDisplayedPages({min: displayedPages.min - 1, max: displayedPages.max - 1});
            }
        }}
        text={STRINGS.previous}
        disabled={props.loading || props.pageIndex === 0}
    />);
    
    for (let i = displayedPages.min; i < displayedPages.max; i++) {
        paginationButtons.push(<Button
            key={"page-" + i}
            data-testid={"page-button-" + (i + 1)}
            active={i === props.pageIndex}
            minimal={i !== props.pageIndex}
            onClick={e => {
                if (props.onPageSelected) {
                    props.onPageSelected(i);
                }
                if (i === (displayedPages.min) && i > 0) {
                    setDisplayedPages({min: displayedPages.min - 1, max: displayedPages.max - 1});
                }
                if (i === (displayedPages.max - 1) && i < (props.numberOfPages - 1)) {
                    setDisplayedPages({min: displayedPages.min + 1, max: displayedPages.max + 1});
                }
            }}
            text={i + 1}
            disabled={props.loading}
            loading={false/*loadingMore*/}
        />)
    }
    
    paginationButtons.push(<Button
        rightIcon={IconNames.CHEVRON_RIGHT}
        minimal
        key="page-next"
        data-testid="page-next"
        onClick={e => {
            if (props.onPageSelected) {
                props.onPageSelected(props.pageIndex + 1);
            }
            if ((props.pageIndex + 1) === (displayedPages.max - 1) && (props.pageIndex + 1) < (props.numberOfPages - 1)) {
                setDisplayedPages({min: displayedPages.min + 1, max: displayedPages.max + 1});
            }
        }}
        text={STRINGS.next}
        disabled={props.loading || props.pageIndex >= (props.numberOfPages - 1)}
    />);   

    if (props.pageIndex < (props.numberOfPages - 9)) {
        paginationButtons.push(<Button
            rightIcon={IconNames.DOUBLE_CHEVRON_RIGHT}
            minimal
            key="page-end"
            data-testid="page-end"
            onClick={e => {
                if (props.onPageSelected) {
                    props.onPageSelected(props.numberOfPages - 1);
                }
                setDisplayedPages({min: Math.max(0, props.numberOfPages - 10), max: props.numberOfPages});
            }}
            disabled={props.loading || props.pageIndex === (props.numberOfPages - 1)}
        />);
    }

    return <div className="text-center d-flex justify-content-between w-100">
        <div>
            {paginationButtons}
        </div>
        {props.onPageSizeSelected && <HTMLSelect
            name="pagination-page-sizes"
            data-testid="pagination-page-sizes"
            fill={ false }
            value={String(props.defaultPageSize || 10)}
            onChange={ e => {
                const newPageSize = parseInt(e.currentTarget.value);
                if (props.onPageSizeSelected) {
                    props.onPageSizeSelected(newPageSize);
                }
            }}
        >
            <option label={STRINGS.show10} value="10"/>
            <option label={STRINGS.show20} value="20"/>
            <option label={STRINGS.show30} value="30"/>
            <option label={STRINGS.show40} value="40"/>
            <option label={STRINGS.show50} value="50"/>
            <option label={STRINGS.show100} value="100"/>
            {props.defaultPageSize && ![10, 20, 30, 40, 50, 100].includes(props.defaultPageSize) && <option 
                label={"Show " + props.defaultPageSize} value={String(props.defaultPageSize)} 
            />}
        </HTMLSelect>}
    </div>;
};

export default PaginationControl;
