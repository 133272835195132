import React from 'react';
import moment from 'moment';
import { CHART_COLORS } from 'components/enums';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import {
	TimeChart,
	TimeChartDatum,
} from 'components/common/time-chart/TimeChart';
import { LineStyle } from 'components/common/chart-base/ChartToolbar';
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { Rules } from 'components/enums/General';

import { STRINGS } from 'app-strings';

/** the properties passed into the React component. */
interface IncidentGenerationGraphProps {
	loading: boolean;
	data: Array<any> | undefined;
	error: string;
}

/** Renders the incident generation graph.
 *  @param props the properties passed in.
 *  @returns JSX with the incident generation graph.*/
export const IncidentGenerationGraph = (
	props: IncidentGenerationGraphProps
): JSX.Element => {
	const { error, data, loading } = props;
	const tsKeyData: Array<TimeChartDatum> = [];

	if (
		!loading &&
		data &&
		'monitor' in data &&
		'incidentGeneration' in data['monitor']
	) {
		const { byType } = data['monitor']['incidentGeneration'];
		const primaryData = byType as Array<any>[];

		if (primaryData && primaryData.length > 0) {
			const metricsSet = new Set();
			primaryData.forEach((item) => {
				(item['values'] as unknown as []).forEach((value) => {
					metricsSet.add(value['name']);
				});
			});

			Array.from(metricsSet).forEach((group, index) => {
				let ts: Array<any> = [];
				primaryData.forEach((item) => {
					(item['values'] as unknown as []).forEach((value) => {
						if (group === value['name']) {
							ts.push({
								x: item['timestamp'] * 1000,
								y:
									value['value'] || value['value'] === 0
										? value['value']
										: null,
							});
						}
					});
				});
				tsKeyData.push({
					groupName: String(Rules[String(group)]),
					groupId: String(index),
					metricName:
						STRINGS.HEALTH_MONITORING_PAGE.graphs.incidentGeneration
							.metricName,
					metricId: 'successRate',
					unit: new Unit('count'),
					data: ts,
				});
			});
		}
	}

	const chartOptions: Highcharts.Options = {
		title: {
			text: STRINGS.HEALTH_MONITORING_PAGE.graphs.incidentGeneration
				.title,
			style: {
				color: CHART_COLORS.TITLE_DEFAULT,
			},
			align: 'left',
			x: 45,
		},
		plotOptions: {
			series: {
				connectNulls: false,
			},
		},
		legend: {
			enabled: true,
			align: 'left',
			verticalAlign: 'bottom',
			floating: false,
		},
		xAxis: {
			labels: {
				formatter: function () {
					return moment(this.value)
						.format('MMM Do, HH:mm')
						.toString();
				},
				step: 2,
				enabled: true,
			},
		},
		time: {
			useUTC: false,
		},
	};

	return (
		<React.Fragment>
			<DataLoadFacade
				loading={loading}
				error={error}
				data={data}
				showContentsWhenLoading={true}
				transparent={true}
			>
				<TimeChart
					loading={loading}
					showChartSubtitle={false}
					height="350px"
					options={chartOptions}
					primaryData={tsKeyData}
					settings={{ style: LineStyle.area, showMore: false }}
					transparent={true}
					enableFullScreen={false}
				/>
			</DataLoadFacade>
		</React.Fragment>
	);
};
