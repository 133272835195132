/** This module creates a react component that can display a scrollable error list.
 *  @module
 */
import React from 'react';
import { Callout, Intent } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import DOMPurify from 'dompurify';
import './ScrollableErrorList.scss';

/** This interface defines the properties passed into the react-flow scrollable error list component.*/
export interface ScrollableErrorListProps {
    /** the list of items to be called out. */
    items: Array<string>;
    /** the intent to use for the callout, for example Intent.DANGER or Intent.WARNING.  See the 
     *  blueprint documentation for more values of the Intent parameter.*/
    intent?: Intent;
}

/** Renders the react-flow scrollable error list component.
 *  @param props the properties passed in.
 *  @returns JSX with the scrollable error list component.*/
export function ScrollableErrorList({intent = Intent.DANGER, ...props}: ScrollableErrorListProps): JSX.Element {
    const title = (intent === Intent.WARNING ? STRINGS.WARNINGS.defaultTitle : STRINGS.ERRORS.defaultTitle);
    const className = (intent === Intent.WARNING ? "scrollable-warnings" : "scrollable-errors");
    let controlId = 0;
    return (
        <Callout title={title} intent={intent} className={className}>
            {
                props.items.map(error => {
                    return (
                        <div key={ controlId++ } dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(error)}}/>
                    );
                })
            }
        </Callout>
    );
};
