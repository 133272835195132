import { GlobalEventTypes, WidgetEventTypes } from '../EventTypes';

export class EventBase {
    type: WidgetEventTypes| GlobalEventTypes;
    value: any;
    source: string;
    target: Array<string>| undefined;
    constructor(value: any, source: string, type: WidgetEventTypes| GlobalEventTypes, target?: Array<string> ) {
        this.value = value;
        this.source = source;
        this.type = type;
        this.target = target;
    }
    getValue() {
        return this.value;
    }
    setValue(value: any) {
        this.value = value;
    }
}
