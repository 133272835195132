/** This module contains the component for the dashboard page.  The dashboard page displays
 *      and edits a dashboard.
 *  @module
 */

import React, { useRef, useState, useEffect } from "react";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { SDWAN_ICONS } from "components/sdwan/enums";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import DashboardToolbar, { ToolbarAction } from "./views/toolbar/DashboardToolbar";
import Layout from "components/common/vis-framework/layout/Layout";
import { LAYOUT_TYPE, LayoutConfig } from "components/common/vis-framework/layout/Layout.type";
import { INTERACTION_TYPE, InteractionConfig, WIDGET_TYPE } from "components/common/vis-framework/widget/Widget.type";
import DashboardWidget, { DashboardWidgetRef, WidgetEditAction } from "./views/widget/DashboardWidget";
import { DashboardConfig, DashboardWidgetConfig } from "./Dashboard.type";
import { getUuidV4 } from "utils/unique-ids/UniqueIds";
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AnchorButton, Button, InputGroup, TextArea } from "@blueprintjs/core";
import { useQueryParams } from "utils/hooks";
import { PARAM_NAME } from "components/enums/QueryParams";
import { getFakeDashboard } from "pages/dashboard-list/views/dashboard-list/DashboardList";
import { PieStyle } from "components/common/chart-base/ChartToolbar";
import AutoUpdateControl, { AutoUpdateState, TimeType } from "components/common/auto-update/AutoUpdateControl";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { IconNames } from "@tir-ui/react-components";
import "./DashboardsPage.scss";

const initAutoUpdate: AutoUpdateState = {
    enabled: true,
    interval: 1,
    lastUpdate: 60* Math.floor(new Date().getTime() / (60 * 1000)),
    sequenceNumber: 0
};
export const AutoUpdateContext = React.createContext(initAutoUpdate);

/** Renders the dashboard page.
 *  @param props the properties passed in.
 *  @returns JSX with the dashboard page component.*/
const DashboardsPage = (props): JSX.Element => {
    const { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.rbConfigId, PARAM_NAME.rbConfigNm, PARAM_NAME.devel] });
    //const showDebugInformation = params[PARAM_NAME.debug] === "true";
    const [loadDashboard, setLoadDashboard] = useState<boolean>(true);

    const loading = useRef<boolean>(false);

    const [dashboardName, setDashboardName] = useState<string>("New Dashboard");
    const [dashboardDesc, setDashboardDesc] = useState<string>("");

    const [layout, setLayout] = useState<LayoutConfig>({type: LAYOUT_TYPE.GRID});

    const [edit, setEdit] = useState<boolean>(true);

    const [widgets, setWidgets] = useState<DashboardWidgetConfig[]>([{id: getUuidV4(), name: "New Widget 1", widgetType: WIDGET_TYPE.PIE, options: {style: PieStyle.donut}}]);
    
    const widgetRefs = useRef<Array<DashboardWidgetRef>>([]);

    const [dialogState, setDialogState] = useState<any>({showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null});

    useEffect(
        () => {
            async function retrieveDashboard() {
                try {
                    const dashboard = getFakeDashboard(params[PARAM_NAME.rbConfigId]); // await runbookService.getRunbook(currentRunbook.current.id);
                    if (dashboard) {
                        //resetHistory();
                        setEdit(false);
                        setDashboardName(dashboard.name);
                        setDashboardDesc(dashboard.description || "");
                        if (dashboard?.widgets?.length) {
                            setWidgets(dashboard.widgets);
                        }
                        if (dashboard?.layout) {
                            setLayout(dashboard.layout);
                        }
                        if (dashboard.endTime) {
                            const newAutoUpdate = Object.assign({}, autoUpdate);
                            newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                            newAutoUpdate.sequenceNumber++;
                            newAutoUpdate.time = dashboard.endTime;
                            newAutoUpdate.enabled = false;        
                            setAutoUpdate(newAutoUpdate);
                        }
                    }
                    //setLoading(false);
                } catch (error) {
                    console.error(error);
                    //setLoading(false);
                }
            }
            if (loadDashboard) {
                setLoadDashboard(false);
                if (params[PARAM_NAME.rbConfigId]) {
                    retrieveDashboard();
                } else {
                    console.log("need to handle this");
                }
                //setRunbookModified(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadDashboard]
    );

    const [autoUpdate, setAutoUpdate] = useState<any>(initAutoUpdate);

    return <PageWithHeader 
        name="Dashboards" 
        title={
            //<div>{dashboardName + " (Beta - for demos only)"}</div>
            <div className="ml-2 font-weight-bold w-100 text-center d-none d-sm-block">
                {dashboardName + " (Alpha)"}
                <span style={{verticalAlign: "text-bottom"}}>
                <WrapInTooltip tooltip="edit">
                    <AnchorButton minimal icon={IconNames.EDIT} onClick={() => {
                        showDashboardSettings(dashboardName, dashboardDesc, setDashboardName, setDashboardDesc, dialogState, setDialogState);
                    }}/>
                </WrapInTooltip>
                </span>
            </div>
        } 
        icon={SDWAN_ICONS.INCIDENT} showTimeBar={false}
        rightAlignedControls={<AutoUpdateControl autoUpdate={autoUpdate} timeType={TimeType.DURATION}
            showPlayPauseButton={true} onAutoUpdateChanged={(autoUpdate) => {
                setAutoUpdate(autoUpdate);
            }}
            showRefreshButton={!autoUpdate.enabled} showTimeControl
            className="d-none d-md-block"
        />}
    >
        <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <AutoUpdateContext.Provider value={autoUpdate}>
        <DashboardToolbar
            edit={edit} 
            notifyToolbarAction={(action: string, value: any): void => {
                switch (action) {
                    case ToolbarAction.ADD_WIDGET:
                        let maxWidgetIndex = 0;
                        for (const widget of widgets) {
                            if (widget.name.startsWith("New Widget ")) {
                                try {
                                    const widgetIndex: number = parseInt(widget.name.substring("New Widget ".length, widget.name.length));
                                    if (!Number.isNaN(widgetIndex)) {
                                        maxWidgetIndex = Math.max(maxWidgetIndex, widgetIndex);
                                    }
                                } catch (error) {}
                            }
                        }
                        const newWidgets = [...widgets];
                        newWidgets.push({id: getUuidV4(), name: "New Widget " + (maxWidgetIndex + 1), widgetType: WIDGET_TYPE.BAR});
                        setWidgets(newWidgets);
                        break;
                    case ToolbarAction.SET_LAYOUT:
                        setLayout({type: value});
                        break;
                    case ToolbarAction.EDIT:
                        setEdit(!edit);
/*
                        for (const widgetRef of widgetRefs.current) {
                            widgetRef.editWidget(edit);
                        }
*/
                        break;
                    case ToolbarAction.IMPORT:
                        importDashboard(setDialogState, setWidgets, setLayout, setDashboardName, setDashboardDesc, setAutoUpdate, autoUpdate);
                        break;
                    case ToolbarAction.EXPORT:
                        const dashboardState: DashboardConfig = {
                            id: getUuidV4(), 
                            name: dashboardName || "My Dashboard", 
                            description: dashboardDesc || "this is my new dashboard", 
                            layout, widgets
                        };
                        if (autoUpdate.time) {
                            dashboardState.endTime = autoUpdate.time;
                        }
                        exportDashboard(dashboardState, setDialogState);
                        break;
                    case ToolbarAction.SAVE:
                        saveDashboard(dialogState, setDialogState);
                        break;
                }
            }}
        />
        <DataLoadFacade loading={loading.current} error={undefined/*data.error*/} data={undefined/*data.data*/} showContentsWhenLoading={true} className="h-100">
            <Layout layout={layout} onLayoutChanged={(layout: any) => {
                setLayout(layout);
            }}
            >{
                widgets.map(widgetConfig => {
                    return <DashboardWidget 
                        key={widgetConfig.id} 
                        config={widgetConfig} 
                        widgets={widgets} 
                        layout={layout.type} 
                        isEditing={edit}
                        notifyWidgetEditAction={(type: WidgetEditAction, value?: any) => {
                            switch (type) {
                                case WidgetEditAction.CONFIG_CHANGE: {
                                    const config: DashboardWidgetConfig = value as DashboardWidgetConfig;
                                    const newWidgets: DashboardWidgetConfig[] = [...widgets];
                                    for (let index = 0; index < newWidgets.length; index++) {
                                        if (newWidgets[index].id === config.id) {
                                            newWidgets[index] = config;
                                            break;
                                        }
                                    }
                                    setWidgets(newWidgets);
                                    break;
                                }
                                case WidgetEditAction.DELETE: {
                                    const remainingWidgets: DashboardWidgetConfig[] = [];
                                    for (let index = 0; index < widgets.length; index++) {
                                        if (widgets[index].id !== widgetConfig.id) {
                                            remainingWidgets.push(widgets[index]);
                                        }
                                    }
                                    setWidgets(remainingWidgets);
                                    break;
                                }
                            }
                        }}
                        notifyInteraction={(type: INTERACTION_TYPE,  data: any[]) => {
                            //alert(JSON.stringify(entities));
                            if (type === INTERACTION_TYPE.GROUP) {
                                const groupInteraction: InteractionConfig | undefined = (widgetConfig.interactions || []).find(interactionConfig => interactionConfig.type === INTERACTION_TYPE.GROUP);
                                if (groupInteraction) {
                                    widgetRefs.current.forEach((widget) => {
                                        if (groupInteraction.widgetIds.includes(widget.getId())) {
                                            widget.notifyNewRunbookInput(data);
                                        }
                                    });    
                                }    
                            }
                         }}
                        ref={(element: DashboardWidgetRef) => widgetRefs.current.push(element)}
                    ></DashboardWidget>;
                })
            }</Layout>
        </DataLoadFacade>
        </AutoUpdateContext.Provider>
    </PageWithHeader>;
};
  
export default DashboardsPage;

/** Creates a popup that imports a dashboard into the dashboard viewer.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. 
 *  @param setWidgets sets the array of DashboardWidgetConfig objects. 
 *  @param setLayout sets the dashboard layout. 
 *  @param setDashboardName function to set the dashboard name.
 *  @param setDashboardDescr function to set the dashboard description.
 *  @param setAutoUpdate sets the autoupate state.
 *  @param autoUpdate the current AutoUpdate state. */
function importDashboard(
    setDialogState: (dialogState: DialogState) => void, 
    setWidgets: (widgetConfigs: DashboardWidgetConfig[]) => void,
    setLayout: (layout: LayoutConfig) => void,
    setDashboardName: (name: string) => void,
    setDashboardDescr: (name: string) => void,
    setAutoUpdate: (autoUpdate: AutoUpdateState) => void,
    autoUpdate: AutoUpdateState    
): void {
    const newDialogState: any = {showDialog: true, title: STRINGS.viewDashboard.importDialog.title};
    newDialogState.dialogContent = <>
        <div className="mb-3"><span>{STRINGS.viewDashboard.importDialog.preText}</span></div>
        <input id="runbook-file" type="file" />
    </>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
        const fileList = (document.getElementById("runbook-file") as any).files;
        if (fileList && fileList.length === 1) {
            const fileReader = new FileReader();
            fileReader.onload = async function () {
                const text: string = fileReader.result as string;
                if (text && text.length > 0) {
                    let errors: Array<string> = [];
                    try {
                        setDialogState(updateDialogState(newDialogState, true, true, []));
                        const importedDashboard: DashboardConfig = JSON.parse(text);
                        const returnedDashboard = await saveImportedDashboard(importedDashboard);
                        if (returnedDashboard?.name) {
                            setDashboardName(returnedDashboard.name);
                        }
                        setDashboardDescr(returnedDashboard.description || "");
                        if (returnedDashboard?.widgets?.length) {
                            setWidgets(returnedDashboard.widgets);
                        }
                        if (returnedDashboard?.layout) {
                            setLayout(returnedDashboard.layout);
                        }
                        if (returnedDashboard?.endTime) {
                            const newAutoUpdate = Object.assign({}, autoUpdate);
                            newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                            newAutoUpdate.sequenceNumber++;
                            newAutoUpdate.time = returnedDashboard.endTime;
                            newAutoUpdate.enabled = false;        
                            setAutoUpdate(newAutoUpdate);
                        }
                    } catch (error) {
                        const errorMsg = (error as any)?.response?.status === 422 && (error as any)?.response?.data?.details[0]?.code
                            ? STRINGS.runbookEditor.errors.codes[(error as any)?.response?.data?.details[0]?.code]
                            : STRINGS.formatString(STRINGS.runbooks.importDialog.errorText, {variant: "dashboard"}) + '<br/>' + error;
                        errors.push(errorMsg);
                    }
                    setDialogState(updateDialogState(newDialogState, errors?.length > 0, false, errors || []));
                }
            }
            fileReader.readAsText(fileList[0]);
        }
    }} text={STRINGS.runbooks.importDialog.btnText} />;
    setDialogState(newDialogState);
}

/** saves a new dashboard from the contents of the import file.
 *  @param dashboard the JSON object with the contents of the import file.
 *  @returns a Promise which resolves to the dashboard that was saved. */
async function saveImportedDashboard(dashboard: DashboardConfig): Promise<DashboardConfig> {
    return Promise.resolve(dashboard);

/*
    try {
        let name: string = "";
        let triggerType: InputType | undefined;
        let description: string = "";
        let disabled: boolean | null = null;
        let nodes: Array<RunbookNode> = [];
        let runtimeVariables: VariableCollection = {primitiveVariables: [], structuredVariables: []};
    
        // Nodered had two formats, the export format and the runbook format.  The export format has a list of nodes and there
        // is a node with the type=tab that has the information about the runbook, while the runbook format has an object with the 
        // runbook attributes and then a nodes attribute with the list of nodes.  We are trying to support both here.
        if (runbookExportFormat && Array.isArray(runbookExportFormat)) {
            for (const node of runbookExportFormat) {
                nodes.push(node);
            }
        } else {
            name = runbookExportFormat.name || "";
            description = runbookExportFormat.description || "";
            triggerType = runbookExportFormat.triggerType;
            nodes = runbookExportFormat.nodes ? runbookExportFormat.nodes : [];
            runtimeVariables = runbookExportFormat.runtimeVariables ? runbookExportFormat.runtimeVariables : {primitiveVariables: [], structuredVariables: []};
        }
    
        // Get the current list of runbook names
        const usedNames: Array<string> = [];
        const runbooks = await runbookService.getRunbooks();
        for (const savedRunbook of runbooks) {
            if (savedRunbook.name) {
                usedNames.push(savedRunbook.name as string);
            }
        }
    
        // Make sure the name is unique
        const nameRoot = name;
        let nameIndex = 1;
        while (usedNames.includes(name)) {
            name = nameRoot + " " + nameIndex++;
        }
    
        const newRunbook: RunbookConfig = {name: name, description: description, triggerType: triggerType, runtimeVariables, nodes};
        if (disabled !== null) {
            newRunbook.isReady = disabled;
        }
        // Shouldn't need to do this.
        delete newRunbook.id;
    
        // Update all node ids
        const newIdByOldId: Record<string, string> = {};
        for (const node of newRunbook.nodes!) {
            const oldId = node.id;
            node.id = getUuidV4();
            newIdByOldId[oldId] = node.id;
        }
    
        // Update all wires
        for (const node of newRunbook.nodes!) {
            if (node.wires) {
                for (const wire of node.wires) {
                    if (Array.isArray(wire)) {
                        for (let index = 0; index < wire.length; index++) {
                            wire[index] = newIdByOldId[wire[index]];
                        }
                    }
                }
            }
        }

        // Update filters on DO object
        updateDoFilters(newRunbook, newIdByOldId);

        // We want all imported runbooks to be inactive
        newRunbook.isReady = false;

        const graphDef = getGraphDefFromRunbookConfig(newRunbook, subflows);
        updateGraphDefWithErrors(graphDef, newRunbook);
        let hasError = false;
        if (graphDef) {
            if (graphDef.errors) {
                hasError = true;
            }
            if (!hasError && graphDef.nodes) {
                for (const node of graphDef.nodes) {
                    if (node.errors) {
                        hasError = true;
                        break;
                    }
                }
            }
        }
        newRunbook.isValidated = !hasError;

        // Save the new runbook
        const dupeRunbook = await runbookService.saveRunbook(newRunbook);
        return Promise.resolve(dupeRunbook as RunbookConfig);
    } catch (error) {
        return Promise.reject(error);
    }
*/
}

/** Creates a popup that saves the dashboard to Azure.
 *  @param newDialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @returns a Promise. */
/* istanbul ignore next */
async function saveDashboard(
    newDialogState: DialogState, setDialogState: (dialogState: DialogState) => void, 
): Promise<any> {
    const origName = "My Dashboard", origInfo = "My Description";
    newDialogState.title = STRINGS.viewDashboard.saveDialog.title;
    function SaveDialogContent(props: {showErrorSection?: boolean, showErrorButton: boolean, warnings?: number, errors?: number}): JSX.Element {
        return (<>
            <div className="mb-3"><span>{STRINGS.viewDashboard.saveDialog.text}</span></div>
            <table><tbody>
                <tr>
                    <td className="p-1"><label>{STRINGS.viewDashboard.saveDialog.nameLabel}</label></td> 
                    <td className="p-1"><InputGroup id="runbook-flow-name" type="text" 
                        defaultValue={origName} style={{width: "300px"}} 
                    /></td>
                </tr>
                <tr>
                    <td className="p-1"><label>{STRINGS.viewDashboard.saveDialog.descLabel}</label></td> 
                    <td className="p-1"><TextArea id="runbook-flow-desc" defaultValue={origInfo} 
                        style={{width: "300px", height: "200px"}}
                    /></td>
                </tr>
            </tbody></table>
        </>);
    };
    newDialogState.dialogContent = <SaveDialogContent showErrorButton={false}/>;
    function SaveDialogFooter(props: {showErrorButton: boolean, disabled?: boolean, forceEtag?: boolean}) {
        return <>
            <Button active={true} outlined={true} disabled={Boolean(props.disabled)}
                text={STRINGS.viewDashboard.saveDialog.saveBtnText}
                onClick={async (evt) => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;
    };
    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} />;
    setDialogState(updateDialogState(newDialogState, true, true, []));
}

/** exports the currently displayed dashboard.
 *  @param dashboard the object with the dashboard configuration.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function exportDashboard(dashboard: DashboardConfig, setDialogState: (dialogState: DialogState) => void): void {
    const newDialogState: any = {showDialog: true, loading: true, title: STRINGS.viewDashboard.exportDialog.title};
    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.viewDashboard.exportDialog.preText}</span></div>;
    newDialogState.dialogFooter = undefined;
    setDialogState(newDialogState);
    
    try {
        const newDialogState: any = {showDialog: true, title: STRINGS.viewDashboard.exportDialog.title};
        newDialogState.dialogContent = <>
            <div className="mb-3"><span>{STRINGS.viewDashboard.exportDialog.preText}</span></div>
            <textarea defaultValue={JSON.stringify(dashboard, null, 4)} style={{width: "470px", height: "350px"}} disabled={true} />
        </>;
        newDialogState.dialogFooter = <CopyToClipboard text={JSON.stringify(dashboard)}>
            <Button active={true} outlined={true} 
                text={STRINGS.viewDashboard.exportDialog.btnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>;
        setDialogState(newDialogState);
    } catch (error) {
        const afterDialogState: any = updateDialogState(newDialogState, true, false, [STRINGS.viewDashboard.exportDialog.errorText]);
        setDialogState(afterDialogState);
    }
}

/** Creates a popup that shows the dashboard settings.
 *  @param name the current dashboard name.
 *  @param decription the current dashboard description.
 *  @param setDashboardName function to set the dashboard name.
 *  @param setDashboardDescr function to set the dashboard description.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @returns a Promise. */
async function showDashboardSettings(
    name: string, description: string, 
    setDashboardName: (name: string) => void,
    setDashboardDescr: (name: string) => void,
    dialogState: DialogState, setDialogState: (dialogState: DialogState) => void
): Promise<any> {
    const newDialogState = updateDialogState(dialogState, true, false, [], []);

    newDialogState.title = STRINGS.dashboards.settingsDialog.title;
    function SaveDialogContent(props: {showErrorSection?: boolean, showErrorButton: boolean, warnings?: number, errors?: number}): JSX.Element {
        return (<>
            <div className="mb-3"><span>{STRINGS.dashboards.settingsDialog.settingsText}</span></div>
            <table><tbody>
                <tr>
                    <td className="p-1"><label>{STRINGS.runbookEditor.saveDialog.syncNameLabel}</label></td> 
                    <td className="p-1"><InputGroup id="runbook-flow-name" type="text" 
                        defaultValue={name} style={{width: "300px"}} 
                    /></td>
                </tr>
                <tr>
                    <td className="p-1"><label>{STRINGS.runbookEditor.saveDialog.syncDescLabel}</label></td> 
                    <td className="p-1"><TextArea id="runbook-flow-desc" defaultValue={description} 
                        style={{width: "300px", height: "200px"}}
                    /></td>
                </tr>
            </tbody></table>
        </>);
    };
    newDialogState.dialogContent = <SaveDialogContent showErrorButton={false}/>;

    function SaveDialogFooter(props: {showErrorButton: boolean, disabled?: boolean, forceEtag?: boolean}) {
        return <>
            <Button active={true} outlined={true} disabled={Boolean(props.disabled)}
                text={STRINGS.dashboards.settingsDialog.saveBtnText}
                onClick={async (evt) => {
                    const name = (document.getElementById("runbook-flow-name") as HTMLInputElement).value;
                    const desc = (document.getElementById("runbook-flow-desc") as HTMLInputElement).value;
                    setDashboardName(name);
                    setDashboardDescr(desc);
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;
    };
    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} />;
    newDialogState.loading = false;
    setDialogState(newDialogState);
}
