import React from 'react';
import { Outline, SIZE } from 'components/enums';
import { IconName, NavbarGroup } from '@blueprintjs/core';
import { RoundedLinkButton } from 'components/common/rounded-link-button/RoundedLinkButton';
import { STRINGS } from 'app-strings';
import { ShareLinkButton } from '../share-link-button/ShareLinkButton';
import { IconNames } from '@blueprintjs/icons';
import { openModal } from '../modal';
import { getURL, useQueryParams } from 'utils/hooks/useQueryParams';
import { computeWidgetContainerId } from 'reporting-infrastructure/utils/commonUtils';
import { PARAM_NAME } from 'components/enums/QueryParams';
import { SEARCH_TYPE } from 'pages/incident-search/IncidentSearchPage';

/** This interface defines the properties passed into the widget toolbar React component.*/
export interface WidgetToolBarProps {
    className?: string;
    /** ID of the incident associated with this trigger */
    incidentId?: string;
    /** ID of active selected runbook ID */
    runbookId?: string;
    /** ID that uniquely identifies a specific widget. This currently maps to the chartId in the runbook */
    widgetId?: string;
}

/** Creates the widget toolbar component with a "Pin to notes" and "share" button.
 *  @param props. refer to WidgetToolBarProps Interface
 *  @returns JSX widget toolbar component */
export function WidgetToolBar({ incidentId, runbookId, widgetId, className }: WidgetToolBarProps) {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.searchType] });
    const isRunbookOnDemand = params[PARAM_NAME.searchType] === SEARCH_TYPE.ondemandrunbooks;

    const getShareURL = () => {
        const contId = computeWidgetContainerId(runbookId, widgetId);
        return window.location.origin + getURL(undefined, {
            [PARAM_NAME.incidentId]: incidentId,
            [PARAM_NAME.runbookId]: runbookId,
            [PARAM_NAME.pageLocationId]: contId
        },
            { replaceQueryParams: false });
    };
    return <NavbarGroup >
        {!isRunbookOnDemand && 
            <RoundedLinkButton aria-label="pin a note" className="align-self-center header-text sub-header-props" 
                size={SIZE.s} outline={Outline.SHOW_ON_HOVER} text={STRINGS.pinNote} icon={IconNames.PIN as IconName} minimal={true}
                onClick={(e) => {
                    // console.log(`incidentId: ${incidentId} \nrunbookId: ${runbookId} \nwidgetId: ${widgetId}`);
                    openModal('addNotesModal', {
                        incidentId: incidentId,
                        runbookId: runbookId,
                        widgetId: widgetId,
                        onSuccess: () => {
                            //no-op
                        },
                    });
                }} />
        }
        <ShareLinkButton href={getShareURL()} aria-label="share link to widget" className="sub-header-props ml-1" outline={Outline.SHOW_ON_HOVER} />
    </NavbarGroup>

}
