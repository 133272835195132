export enum TIME_FORMAT {
    DEFAULT_FORMAT = "h:mm A MMM Do, Y",
    DISPLAY_DATE_ONLY_FORMAT = "MMM Do YYYY",
    DISPLAY_TIME_FORMAT = "MMM Do YYYY, h:mm A",
    DISPLAY_DATE_TIME_SHORT_FORMAT = "MMM Do, h:mm A",
    DISPLAY_TIME_ONLY_FORMAT = "h:mm A",
    DISPLAY_24HOUR_FORMAT = "MMM Do, Y H:mm",
    DISPLAY_DATE_INPUT = "MM/DD/YYYY h:mm A"
}
export const AUTOUPDATE_INTERVAL = 30000;
