/** This module creates a new node for the react-flow graph that represents a NetIM node.
 *  @module
 */
import React, { CSSProperties } from "react";
import { useHistory } from "react-router";
import { STRINGS } from 'app-strings';
import { IconName, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import { Icon, IconNames } from "@tir-ui/react-components";
import { PROVIDER_TYPES } from "components/enums";
import { PARAM_NAME } from "components/enums/QueryParams";
import { getURL, setQueryParams } from 'utils/hooks/useQueryParams';
import { openModal } from 'components/common/modal';
import { getURLPath } from "config";
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";
import { FACET_FIELDS } from "utils/services/SearchApiService";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { NetIMSupportedDeviceTypes, NETIM_TYPES_TO_LABEL_MAP, NetIMSupportedLinkTypes } from "utils/cloudim/TopologyUtils";

import './NetIMNode.scss';

export default function NetIMNode({ data }: NodeProps) {
    const history = useHistory();

    const handleStyle: CSSProperties = { visibility: "hidden" };
    const icons = {
        // NetIMSupportedDeviceTypes
        [NetIMSupportedDeviceTypes.SWITCH]: IconNames.NETIM_SWITCH,
        [NetIMSupportedDeviceTypes.ROUTER]: IconNames.NETIM_ROUTER,
        [NetIMSupportedDeviceTypes.FIREWALL]: IconNames.NETIM_FIREWALL,
        [NetIMSupportedDeviceTypes.LOADBALANCER]: IconNames.NETIM_LOAD_BALANCER,
        [NetIMSupportedDeviceTypes.HOST]: IconNames.NETIM_HOST,
        [NetIMSupportedDeviceTypes.WANACCELERATOR]: IconNames.NETIM_WAN_ACCELERATOR,
        [NetIMSupportedDeviceTypes.MULTILAYERSWITCH]: IconNames.NETIM_SWITCH,
        [NetIMSupportedDeviceTypes.PRINTER]: IconNames.NETIM_PRINTER,
        [NetIMSupportedDeviceTypes.UNIFIEDCOMMUNICATION]: IconNames.NETIM_UNIFIED_COMMUNICATION,
        [NetIMSupportedDeviceTypes.WIRELESS]: IconNames.NETIM_WIRELESS,
        [NetIMSupportedDeviceTypes.SDWAN]: IconNames.NETIM_SDWAN,
        [NetIMSupportedDeviceTypes.OTHER]: IconNames.DEVICES,
        // NetIMSupportedLinkTypes
        [NetIMSupportedLinkTypes.AGGREGATE]: IconNames.LINK,
        [NetIMSupportedLinkTypes.AGGREGATE_SERIAL_CLOUD]: IconNames.LINK,
        [NetIMSupportedLinkTypes.ETHERNET]: IconNames.LINK,
        [NetIMSupportedLinkTypes.FAST_ETHERNET]: IconNames.LINK,
        [NetIMSupportedLinkTypes.GIGABIT_ETHERNET]: IconNames.LINK,
        [NetIMSupportedLinkTypes.TEN_GIGABIT_ETHERNET]: IconNames.LINK,
        [NetIMSupportedLinkTypes.HUNDRED_GIGABIT_ETHERNET]: IconNames.LINK,
        [NetIMSupportedLinkTypes.LAN]: IconNames.LINK,
        [NetIMSupportedLinkTypes.LAN_CLOUD]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.ATM]: IconNames.LINK,
        [NetIMSupportedLinkTypes.ATM_CLOUD]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.ATM_PVC]: IconNames.LINK,
        [NetIMSupportedLinkTypes.ATM_PVP]: IconNames.LINK,
        [NetIMSupportedLinkTypes.ATM_SPVP]: IconNames.LINK,
        [NetIMSupportedLinkTypes.ATM_SVC]: IconNames.LINK,
        [NetIMSupportedLinkTypes.ATM_FRAME_RELAY_PVC]: IconNames.LINK,
        [NetIMSupportedLinkTypes.DATA_CONNECTION]: IconNames.LINK,
        [NetIMSupportedLinkTypes.E1]: IconNames.LINK,
        [NetIMSupportedLinkTypes.E3]: IconNames.LINK,
        [NetIMSupportedLinkTypes.FDDI]: IconNames.LINK,
        [NetIMSupportedLinkTypes.FRAME_RELAY_CLOUD]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.FRAME_RELAY_PVC]: IconNames.LINK,
        [NetIMSupportedLinkTypes.HSSI]: IconNames.LINK,
        [NetIMSupportedLinkTypes.IP_CLOUD]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.IP_RELAY_CLOUD]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.IPSEC_TUNNEL]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.ISDN]: IconNames.LINK,
        [NetIMSupportedLinkTypes.LOGICAL_IP_CLOUD]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.LOOPBACK]: IconNames.LINK,
        [NetIMSupportedLinkTypes.MPLS_LSP]: IconNames.LINK,
        [NetIMSupportedLinkTypes.OC3]: IconNames.LINK,
        [NetIMSupportedLinkTypes.OC12]: IconNames.LINK,
        [NetIMSupportedLinkTypes.OC192]: IconNames.LINK,
        [NetIMSupportedLinkTypes.PASSPORT_TRUNK]: IconNames.LINK,
        [NetIMSupportedLinkTypes.PNNI]: IconNames.LINK,
        [NetIMSupportedLinkTypes.POINT_TO_POINT]: IconNames.LINK,
        [NetIMSupportedLinkTypes.RADIO]: IconNames.LINK,
        [NetIMSupportedLinkTypes.SDWAN_VPN]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.SERIAL_CLOUD]: IconNames.CLOUD,
        [NetIMSupportedLinkTypes.SONET]: IconNames.LINK,
        [NetIMSupportedLinkTypes.STAR_LAN]: IconNames.LINK,
        [NetIMSupportedLinkTypes.T1]: IconNames.LINK,
        [NetIMSupportedLinkTypes.T3]: IconNames.LINK,
        [NetIMSupportedLinkTypes.TOKEN_RING]: IconNames.LINK,
        [NetIMSupportedLinkTypes.TUNNEL]: IconNames.LINK,
        [NetIMSupportedLinkTypes.VIRTUAL_LAN]: IconNames.LINK,
        [NetIMSupportedLinkTypes.VOICE_CONNECTION]: IconNames.LINK,
        [NetIMSupportedLinkTypes.OTHER]: IconNames.LINK,
    }

    let iconToUse: any = icons[data.type] ?? IconNames.BLANK;

    return (
        <ContextMenu2
            content={
                <Menu>
                    <MenuDivider title={
                        <div className="netimContextMenu">
                            <div className="icon" title={NETIM_TYPES_TO_LABEL_MAP[data.type]}>
                                <Icon icon={iconToUse} />
                            </div>
                            <div className="details">
                                <span>
                                    {data.label}
                                </span>
                                <span className="text-secondary display-9 font-weight-500">
                                    {NETIM_TYPES_TO_LABEL_MAP[data.type]}
                                </span>
                            </div>
                        </div>
                    } />
                    <MenuItem icon={IconNames.SEARCH} text={STRINGS.cloudim.topology.contextMenu.search} onClick={() => {
                        setQueryParams({ [PARAM_NAME.searchText]: data.label }, true);
                    }} />
                    <MenuItem icon={BPIconNames.MODAL as IconName} text={STRINGS.cloudim.topology.contextMenu.properties} onClick={() => {
                        openModal('cloudIMPropertiesModal', {
                            icon: iconToUse,
                            provider: PROVIDER_TYPES.ONPREM,
                            data: data,
                            onClose: () => {},
                        });
                    }} />
                    {/* Exclude Link Type as a search */}
                    {!(Object.values(NetIMSupportedLinkTypes).includes(data?.type)) && <MenuItem icon={BPIconNames.AREA_OF_INTEREST as IconName} text={STRINGS.cloudim.topology.contextMenu.explorerView} onClick={() => {
                        history.push(
                            getURL(getURLPath('explorer'), {
                                searchType: SEARCH_TYPE.device,
                                facets: {
                                    [FACET_FIELDS.NAME]: [data.label]
                                },
                            }, { replaceQueryParams: true })
                        );
                    }} />}
                    <MenuDivider />
                    <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.cloudim.topology.contextMenu.copy} onClick={() => {
                        navigator.clipboard.writeText(data.label);
                    }} />
                </Menu>
            }
        >
            <div className="netimNode" onClick={(e) => {
                const hasShowText = e.currentTarget.classList.contains("showText");
                hasShowText ? e.currentTarget.classList.remove("showText") : e.currentTarget.classList.add("showText");
            }}>
                <div className="icon" title={NETIM_TYPES_TO_LABEL_MAP[data.type]}>
                    <Icon icon={iconToUse} />
                </div>
                <Handle
                    type="target"
                    position={Position.Left}
                    style={handleStyle}
                />
                <Handle
                    type="source"
                    position={Position.Right}
                    style={handleStyle}
                />
                <div className="labelContainer">
                    <div className="details">
                        <span>
                            {data.label}
                        </span>
                    </div>
                </div>
            </div>
        </ContextMenu2>
    );
}
