import { FILTER_NAME } from "utils/hooks";

type callback = () => void;

let activeSupportedFilters: {[x:string]: number} = {};
let activeExcludedFilters: {[x:string]: number} = {};
let lastSupportedFiltersList: FILTER_NAME[] = [];
let callbacks: callback[] = [];

function addSupportedFilters (filters: FILTER_NAME[], excludedFilters?: FILTER_NAME[]): boolean {
    let supportedFiltersChanged = false;
    for (const filterName of filters) {
        if (!activeSupportedFilters[filterName]) {
            activeSupportedFilters[filterName] = 0;
        }
        activeSupportedFilters[filterName]++;
    }
    if (excludedFilters) {
        for (const filterName of excludedFilters) {
            if (!activeExcludedFilters[filterName]) {
                activeExcludedFilters[filterName] = 0;
            }
            activeExcludedFilters[filterName]++;
        }
    }
    supportedFiltersChanged = hasSupportedFiltersChanged();
    if (supportedFiltersChanged) {
        // Trigger a sync since the active supported filters changed
        triggerSync();
    }
    // Returns a boolean to indicate of supported filters changed or not
    return supportedFiltersChanged;
}

function removeSupportedFilters (filters: FILTER_NAME[], excludedFilters?: FILTER_NAME[]): boolean {
    let supportedFiltersChanged = false;
    for (const filterName of filters) {
        if (activeSupportedFilters[filterName]) {
            activeSupportedFilters[filterName]--;
        } else {
            console.warn("Attempted to remove " + filterName + " from supported filter map but not found!");
        }
    }
    if (excludedFilters) {
        for (const filterName of excludedFilters) {
            if (activeExcludedFilters[filterName]) {
                activeExcludedFilters[filterName]--;
            } else {
                console.warn("Attempted to remove " + filterName + " from excluded filter map but not found!");
            }
        }
    }
    supportedFiltersChanged = hasSupportedFiltersChanged();
    if (supportedFiltersChanged) {
        // Trigger a sync since the active supported filters changed
        triggerSync();
    }
    // Returns a boolean to indicate if supported filters changed or not
    return supportedFiltersChanged;
}

function hasSupportedFiltersChanged () {
    const currentSupportedFilters = getSupportedFilters().sort();
    let changed = false;
    if (currentSupportedFilters.length !==  lastSupportedFiltersList.length) {
        changed = true;
    } else {
        for (let i = 0; i < currentSupportedFilters.length; i++) {
            if (lastSupportedFiltersList[i] !== currentSupportedFilters[i]) {
                changed = true;
                break;
            }
        }
    }
    if (changed) {
        lastSupportedFiltersList = currentSupportedFilters;
    }
    return changed;
}

function getSupportedFilters (collectionType: "union" | "intersection" = "intersection"): FILTER_NAME[] {
    let filterList:FILTER_NAME[] = [];
    if (collectionType === "intersection") {
        let maxSupportedCount = 0;
        for (const filter in activeSupportedFilters) {
            if (activeSupportedFilters[filter] > maxSupportedCount && !activeExcludedFilters[filter]) {
                maxSupportedCount = activeSupportedFilters[filter];
            }
        }
        if (maxSupportedCount > 0) {
            for (const filter in activeSupportedFilters) {
                if (activeSupportedFilters[filter] === maxSupportedCount && !activeExcludedFilters[filter]) {
                    filterList.push(FILTER_NAME[filter]);
                }
            }
        }
    } else {
        for (const filter in activeSupportedFilters) {
            if (activeSupportedFilters[filter] > 0 && !activeExcludedFilters[filter]) {
                filterList.push(FILTER_NAME[filter]);
            }
        }
    }
    return filterList;
}

function triggerSync (): void {
    for (const callback of callbacks) {
        callback();
    }
}

function addChangeCallback (callback:() => void): void {
    callbacks.push(callback);
}

function removeChangeCallback (callback?:callback): void {
    callbacks = callbacks.filter(c => c !== callback);
}

export { getSupportedFilters, addSupportedFilters, removeSupportedFilters, addChangeCallback, removeChangeCallback };
