import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
	Button,
	ButtonProps,
	Classes,
	Dialog,
	DialogStep,
	MultistepDialog,
	Intent,
} from '@blueprintjs/core';
import {
	Icon,
	IconNames,
	ErrorToaster,
	LoadingOverlay,
	SuccessToaster,
} from '@tir-ui/react-components';
import { useStateSafePromise } from 'utils/hooks';
import { openConfirm } from 'components/common/modal';
import { BasicDetailsPanel } from './BasicDetailsPanel';
import { ConnectionConfigPanel } from './ConnectionConfigPanel';
import { IpConfigPanel } from './IpConfigPanel';
import {
	DataSourceService,
	gatewayTypeOptions,
	vmImageTypes,
} from 'utils/services/DataSourceApiService';
import { validIP } from 'utils/validators/Validators';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { ReviewPanel } from './ReviewPanel';

import { STRINGS } from 'app-strings';

import './AddEdgeGatewayModal.scss';

const AuthService = AuthServiceProvider.getService();

// const { HOST_NAME } = window['runConfig']
// 	? window['runConfig']
// 	: { HOST_NAME: '' };

const AddEdgeGatewayModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setGatewayId(id: string) {
			if (id) {
				setGatewayId(id);
			}
		},
		setEdit(edit: boolean) {
			setEditMode(edit);
		},
		handleOpen() {
			setIsOpen(!isOpen);
		},
	}));

	const { dataRefresh, setDelay } = props;
	const [editMode, setEditMode] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [finalDialogOpen, setFinalDialogOpen] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const [edgeGateway, setEdgeGateway] = useState<any>(undefined);
	const [gatewayId, setGatewayId] = useState<string | undefined>(undefined);
	const [gatewayDownloadType, setGatewayDownloadType] = useState<string>('');
	const [gatewayName, setGatewayName] = useState<string>('');
	const [gatewayType, setGatewayType] = useState<string>('');
	const [proxyEnabled, setProxyEnabled] = useState<boolean>(false);
	const [websocketsEnabled, setWebsocketsEnabled] = useState<boolean>(false);
	const [proxyHostname, setProxyHostname] = useState<string>('');
	const [proxyProtocol, setProxyProtocol] = useState<string>('');
	const [proxyPort, setProxyPort] = useState<string>('');
	const [proxyAuthEnabled, setProxyAuthEnabled] = useState<boolean>(false);
	const [proxyUsername, setProxyUsername] = useState<string>('');
	const [proxyPassword, setProxyPassword] = useState<string>('');
	const [staticIpConfigEnabled, setStaticIpConfigEnabled] =
		useState<boolean>(false);
	const [staticCidr, setStaticCidr] = useState<string>('');
	const [staticGatewayIp, setStaticGatewayIp] = useState<string>('');
	const [staticIpNameservers, setStaticIpNameservers] = useState<
		React.ReactNode[]
	>([]);
	const [staticIpNameserversValid, setStaticIpNameserversValid] =
		useState<boolean>(true);

	const [executeSafely] = useStateSafePromise();
	const [loading, setLoading] = useState(false);
	const fetchEdgeGateway = useCallback(
		(id: string | undefined) => {
			return executeSafely(DataSourceService.getEdgeGateway(id)).then(
				(response: any) => {
					setEdgeGateway(response);
					setValues(response);
					setLoading(false);
				},
				(error) => {
					console.error(error);
				}
			);
		},
		[executeSafely]
	);

	useEffect(() => {
		if (editMode && isOpen) {
			setLoading(true);
			fetchEdgeGateway(gatewayId);
		}
	}, [editMode, gatewayId, fetchEdgeGateway, isOpen]);

	const setValues = (item: any): void => {
		item.entity.attributes.name &&
			setGatewayName(item.entity.attributes.name);
		item.entity.attributes.image_type &&
			setGatewayType(
				gatewayTypeOptions[item.entity.attributes.image_type]
			);
		item.entity.attributes.websockets &&
			setWebsocketsEnabled(item.entity.attributes.websockets);
		if (
			item.entity.attributes.proxy_hostname ||
			item.entity.attributes.proxy_protocol ||
			item.entity.attributes.proxy_port
		) {
			setProxyEnabled(true);
			item.entity.attributes.proxy_hostname &&
				setProxyHostname(item.entity.attributes.proxy_hostname);
			item.entity.attributes.proxy_protocol &&
				setProxyProtocol(item.entity.attributes.proxy_protocol);
			item.entity.attributes.proxy_port &&
				setProxyPort(item.entity.attributes.proxy_port);
		}
		if (
			item.entity.attributes.proxy_username ||
			item.entity.attributes.proxy_password
		) {
			setProxyEnabled(true);
			setProxyAuthEnabled(true);
			item.entity.attributes.proxy_username &&
				setProxyUsername(item.entity.attributes.proxy_username);
			item.entity.attributes.proxy_password &&
				setProxyPassword(item.entity.attributes.proxy_password);
		}
		item.entity.attributes.static_ip_cidr_address &&
			setStaticCidr(item.entity.attributes.static_ip_cidr_address);
		item.entity.attributes.static_ip_gateway &&
			setStaticGatewayIp(item.entity.attributes.static_ip_gateway);
		item.entity.attributes.static_ip_nameservers &&
			setStaticIpNameservers(
				item.entity.attributes.static_ip_nameservers
			);

		(item.entity.attributes.static_ip_cidr_address ||
			item.entity.attributes.static_ip_gateway ||
			item.entity.attributes.static_ip_nameservers) &&
			setStaticIpConfigEnabled(true);
	};

	const validNameserverIps = (values: React.ReactNode[]): boolean => {
		let invalidCount: number = 0;
		values.forEach((value) => {
			if (value && !validIP(value.toString())) {
				invalidCount++;
			}
		});

		return invalidCount === 0;
	};

	const clearValues = () => {
		setIsOpen(false);
		setGatewayName('');
		setGatewayType('');
		setProxyEnabled(false);
		setWebsocketsEnabled(false);
		setProxyHostname('');
		setProxyProtocol('');
		setProxyPort('');
		setProxyAuthEnabled(false);
		setProxyUsername('');
		setProxyPassword('');
		setStaticIpConfigEnabled(false);
		setStaticCidr('');
		setStaticGatewayIp('');
		setStaticIpNameservers([]);
		setEdgeGateway(undefined);
	};

	const handleFinalDialogOpen = React.useCallback(
		() => setFinalDialogOpen(true),
		[]
	);

	const handleFinalDialogClose = React.useCallback(() => {
		setFinalDialogOpen(false);
		setEditMode(false);
	}, []);

	const handleSubmit = useCallback(() => {
		setSubmitting(true);
		const gatewayTypeKey =
			Object.keys(gatewayTypeOptions)[
				Object.values(gatewayTypeOptions).indexOf(
					gatewayType as unknown as gatewayTypeOptions
				)
			];

		const payload: any = {
			version: '0.0.1',
			timestamp: moment().valueOf().toString(),
			doctype: 'metadata',
			utid: AuthService.getTenantId(),
			entity: {
				kind: 'edge_gateway',
				attributes: {
					name: gatewayName,
					image_type: gatewayTypeKey,
					proxy_protocol:
						proxyProtocol && proxyEnabled ? proxyProtocol : null,
					proxy_hostname:
						proxyHostname && proxyEnabled ? proxyHostname : null,
					proxy_port: proxyPort && proxyEnabled ? proxyPort : null,
					proxy_username:
						proxyEnabled &&
						proxyAuthEnabled &&
						proxyUsername &&
						proxyPassword
							? proxyUsername
							: null,
					proxy_password:
						proxyEnabled &&
						proxyAuthEnabled &&
						proxyPassword &&
						proxyUsername
							? proxyPassword
							: null,
					websockets: websocketsEnabled,
					static_ip_cidr_address: staticIpConfigEnabled
						? staticCidr
						: null,
					static_ip_gateway: staticIpConfigEnabled
						? staticGatewayIp
						: null,
					static_ip_nameservers: staticIpConfigEnabled
						? staticIpNameservers
						: null,
				},
			},
		};

		const promise = editMode
			? DataSourceService.updateEdgeGateway(
					gatewayId,
					_.merge(edgeGateway, payload)
			  )
			: DataSourceService.createEdgeGateway(payload);
		return promise.then(
			(response) => {
				setDelay();
				setGatewayId(response.entity.attributes.id);
				setGatewayDownloadType(response.entity.attributes.image_type);
				SuccessToaster({
					message: editMode
						? STRINGS.DATA_SOURCES.addEdgeGatewayDialog.actions
								.messages.successfullyUpdated
						: STRINGS.DATA_SOURCES.addEdgeGatewayDialog.actions
								.messages.successfullyCreated,
				});
				setSubmitting(false);
				setIsOpen(false);
				setTimeout(() => {
					dataRefresh();
				}, 3000);
				handleFinalDialogOpen();
				setTimeout(clearValues, 2000);
			},
			(error) => {
				ErrorToaster({
					message: error.data.error.message,
				});
				setSubmitting(false);
			}
		);
	}, [
		editMode,
		gatewayId,
		gatewayName,
		gatewayType,
		websocketsEnabled,
		proxyEnabled,
		proxyHostname,
		proxyProtocol,
		proxyPort,
		proxyAuthEnabled,
		proxyUsername,
		proxyPassword,
		staticIpConfigEnabled,
		staticCidr,
		staticGatewayIp,
		staticIpNameservers,
		dataRefresh,
		setDelay,
		edgeGateway,
		handleFinalDialogOpen,
	]);

	const finalButtonProps: Partial<ButtonProps> = {
		intent: Intent.PRIMARY,
		onClick: handleSubmit,
		text: STRINGS.DATA_SOURCES.addEdgeGatewayDialog.buttons.submitBtnText,
		loading: submitting,
		disabled: gatewayName === '' || gatewayType === '' || submitting,
	};

	const setCursorPosition = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		const target = event.currentTarget;
		const selectionStart = Number(event.currentTarget.selectionStart);
		setTimeout(() => {
			// restore cursor position
			target && target.setSelectionRange(selectionStart, selectionStart);
		}, 0);
	};

	const handleGatewayNameChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setGatewayName(event.currentTarget.value);
	};

	const handleGatewayTypeChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setGatewayType(event.currentTarget.value);
	};

	const handleProxyEnabledChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setProxyEnabled(event.currentTarget.checked);
		if (!event.currentTarget.checked) {
			setProxyAuthEnabled(false);
			setProxyUsername('');
			setProxyPassword('');
		}
	};

	const handleProxyHostnameChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setProxyHostname(event.currentTarget.value);
	};

	const handleProxyProtocolChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setProxyProtocol(event.currentTarget.value);
	};

	const handleProxyAuthEnabledChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setProxyAuthEnabled(event.currentTarget.checked);
		if (!event.currentTarget.checked) {
			setProxyUsername('');
			setProxyPassword('');
		}
	};

	const handleProxyUsernameChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setProxyUsername(event.currentTarget.value);
	};

	const handleProxyPasswordChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setProxyPassword(event.currentTarget.value);
	};

	const handleProxyPortChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setProxyPort(event.currentTarget.value);
	};

	const handleWebsocketsEnabledChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setWebsocketsEnabled(event.currentTarget.checked);
	};

	const handleStaticIpEnabledChange = (staticIpEnabled: boolean): void => {
		setStaticIpConfigEnabled(staticIpEnabled);
	};

	const handleStaticCidrChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setStaticCidr(event.currentTarget.value);
	};

	const handleStaticIpGatewayChange = (
		event: React.FormEvent<HTMLInputElement>
	): void => {
		setCursorPosition(event);
		setStaticGatewayIp(event.currentTarget.value);
	};

	const handleStaticIpNameserversChange = (
		values: React.ReactNode[]
	): void => {
		setStaticIpNameservers(values);
		setStaticIpNameserversValid(validNameserverIps(values));
	};

	if (loading) {
		return <LoadingOverlay visible={true} />;
	} else
		return (
			<React.Fragment>
				<div id="multistep_dialog">
					<MultistepDialog
						className="tir-edge-gateways main-content"
						onClose={clearValues}
						finalButtonProps={finalButtonProps}
						title={
							editMode
								? STRINGS.DATA_SOURCES.addEdgeGatewayDialog
										.title.edit
								: STRINGS.DATA_SOURCES.addEdgeGatewayDialog
										.title.add
						}
						autoFocus={true}
						canEscapeKeyClose={true}
						canOutsideClickClose={false}
						enforceFocus={true}
						isCloseButtonShown={true}
						isOpen={isOpen}
						resetOnClose={true}
					>
						<DialogStep
							id="basic_details"
							className="tir-edge-gateways panel-content"
							panel={
								<BasicDetailsPanel
									onChangeGatewayName={
										handleGatewayNameChange
									}
									onChangeGatewayType={
										handleGatewayTypeChange
									}
									gatewayNameValue={gatewayName}
									gatewayTypeValue={gatewayType}
								/>
							}
							title={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.basicDetails.title
							}
							nextButtonProps={{
								disabled:
									gatewayName === '' ||
									gatewayType === '' ||
									gatewayType ===
										STRINGS.DATA_SOURCES
											.addEdgeGatewayDialog.panels
											.basicDetails.fields.chooseType,
							}}
						/>
						<DialogStep
							id="ip_config"
							className="tir-edge-gateways panel-content"
							panel={
								<IpConfigPanel
									onChangeStaticIpEnabled={
										handleStaticIpEnabledChange
									}
									onChangeStaticCidr={handleStaticCidrChange}
									onChangeStaticIpGateway={
										handleStaticIpGatewayChange
									}
									onChangeStaticIpNameservers={
										handleStaticIpNameserversChange
									}
									staticIpEnabled={staticIpConfigEnabled}
									staticIpCidrValue={staticCidr}
									staticIpGatewayValue={staticGatewayIp}
									staticIpNameserversValues={
										staticIpNameservers
									}
								/>
							}
							title={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.ipConfig.title
							}
							nextButtonProps={{
								disabled:
									staticIpConfigEnabled &&
									(!staticCidr ||
										!staticGatewayIp ||
										staticIpNameservers.length === 0 ||
										!staticIpNameserversValid),
							}}
						/>
						<DialogStep
							id="connection_config"
							className="tir-edge-gateways panel-content"
							panel={
								<ConnectionConfigPanel
									onChangeProxyEnabled={
										handleProxyEnabledChange
									}
									onChangeWebsocketsEnabled={
										handleWebsocketsEnabledChange
									}
									onChangeProxyHostname={
										handleProxyHostnameChange
									}
									onChangeProxyProtocol={
										handleProxyProtocolChange
									}
									onChangeProxyPort={handleProxyPortChange}
									onChangeProxyAuthEnabled={
										handleProxyAuthEnabledChange
									}
									onChangeProxyUsername={
										handleProxyUsernameChange
									}
									onChangeProxyPassword={
										handleProxyPasswordChange
									}
									proxyEnabled={proxyEnabled}
									proxyHostname={proxyHostname}
									proxyProtocol={proxyProtocol}
									proxyPort={proxyPort}
									proxyAuthEnabled={proxyAuthEnabled}
									proxyUsername={proxyUsername}
									proxyPassword={proxyPassword}
									websocketsEnabled={websocketsEnabled}
								/>
							}
							title={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.connectionConfig.title
							}
							nextButtonProps={{
								disabled: false,
							}}
						/>
						<DialogStep
							id="review"
							className="tir-edge-gateways panel-content"
							panel={
								<ReviewPanel
									gatewayName={gatewayName}
									gatewayType={gatewayType}
									staticIpConfigEnabled={
										staticIpConfigEnabled
									}
									staticCidr={staticCidr}
									staticGatewayIp={staticGatewayIp}
									staticIpNameservers={staticIpNameservers}
									proxyEnabled={proxyEnabled}
									websocketsEnabled={websocketsEnabled}
									proxyHostname={proxyHostname}
									proxyProtocol={proxyProtocol}
									proxyPort={proxyPort}
									proxyAuthEnabled={proxyAuthEnabled}
									proxyUsername={proxyUsername}
									proxyPassword={proxyPassword}
								/>
							}
							title={
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
									.review.title
							}
						/>
					</MultistepDialog>
					<FinalDialog
						editMode={editMode}
						gatewayId={gatewayId}
						gatewayType={gatewayDownloadType}
						finalDialogOpen={finalDialogOpen}
						handleFinalDialogClose={handleFinalDialogClose}
					/>
				</div>
			</React.Fragment>
		);
});

const FinalDialog = (props: any): JSX.Element => {
	const { gatewayId, gatewayType, editMode } = props;

	const handleScriptDownload = (id: string, type: string) => {
		openConfirm({
			message: (
				<span>
					<b>
						{
							STRINGS.DATA_SOURCES.dataSourceView.messages
								.downloadVmImageWarning
						}
					</b>{' '}
					{
						STRINGS.DATA_SOURCES.dataSourceView.messages
							.downloadIsoMsg
					}
				</span>
			),
			onConfirm: () => {
				if (type === 'Ova') {
					DataSourceService.downloadOvaFile(id).then(
						() => {
							SuccessToaster({
								message:
									STRINGS.DATA_SOURCES.finalDialog
										.downloadStarted,
							});
						},
						(error) => {
							ErrorToaster({
								message: error.message,
							});
						}
					);
				} else {
					DataSourceService.downloadInitScript(id).then(
						() => {
							SuccessToaster({
								message:
									STRINGS.DATA_SOURCES.finalDialog
										.downloadStarted,
							});
						},
						(error) => {
							ErrorToaster({
								message: error.message,
							});
						}
					);
				}
				SuccessToaster({
					message: STRINGS.DATA_SOURCES.finalDialog.downloadStarted,
				});
			},
			intent: Intent.WARNING,
			icon: IconNames.WARNING_SIGN,
		});
	};

	const handleVmDownload = (): void => {
		openConfirm({
			message: (
				<span>
					<b>
						{
							STRINGS.DATA_SOURCES.dataSourceView.messages
								.downloadVmImageExternalWarning
						}
					</b>{' '}
					{
						STRINGS.DATA_SOURCES.dataSourceView.messages
							.downloadVmImageRoutingMsg
					}{' '}
					({new URL(vmImageTypes[gatewayType]).hostname})
				</span>
			),
			onConfirm: () => {
				// const tenantId = AuthService.getTenantId();
				const anchor = document.createElement('a');
				anchor.setAttribute('target', '_blank');
				anchor.setAttribute('href', `${vmImageTypes[gatewayType]}`);
				anchor.click();
				anchor.remove();
				SuccessToaster({
					message: STRINGS.DATA_SOURCES.finalDialog.downloadStarted,
				});
			},
			intent: Intent.WARNING,
			icon: IconNames.WARNING_SIGN,
		});
	};

	const scriptDownloadLink = (
		<a
			href="/#"
			onClick={(e) => {
				handleScriptDownload(gatewayId, gatewayType);
				e.preventDefault();
			}}
		>
			{gatewayType !== 'Ova'
				? STRINGS.DATA_SOURCES.finalDialog.steps.step1.cloudInitScript
				: STRINGS.DATA_SOURCES.finalDialog.steps.step1.isoImage}
		</a>
	);

	const vmDownloadLink = (
		<a
			href="/#"
			onClick={(e) => {
				handleVmDownload();
				e.preventDefault();
			}}
		>
			{STRINGS.DATA_SOURCES.finalDialog.steps.step1.vmImage}
		</a>
	);

	return (
		<Dialog
			title={
				editMode
					? STRINGS.DATA_SOURCES.finalDialog.title.edit
					: STRINGS.DATA_SOURCES.finalDialog.title.add
			}
			isOpen={props.finalDialogOpen}
			onClose={props.handleFinalDialogClose}
			style={{ width: '600px' }}
		>
			<div className={Classes.DIALOG_BODY}>
				<p className="text-center">
					<Icon
						icon={IconNames.TICK}
						iconSize={20}
						intent={Intent.SUCCESS}
						className="ml-2 mr-2"
					/>
					<strong>
						{editMode
							? STRINGS.DATA_SOURCES.finalDialog.actions
									.successfullyUpdated
							: STRINGS.DATA_SOURCES.finalDialog.actions
									.successfullyAdded}
					</strong>
				</p>
				<p className="ml-2">
					<u>
						<strong>
							{STRINGS.DATA_SOURCES.finalDialog.nextSteps}
						</strong>
					</u>
				</p>
				<p className="ml-3">
					<strong>
						{' '}
						{STRINGS.DATA_SOURCES.finalDialog.steps.step1.text1}
						{vmDownloadLink}
						{STRINGS.DATA_SOURCES.finalDialog.steps.step1.text2}
						{scriptDownloadLink}
						{STRINGS.DATA_SOURCES.finalDialog.steps.step1.text3}
						<br />
						{STRINGS.DATA_SOURCES.finalDialog.steps.step1.hint}
					</strong>
				</p>
				<p className="ml-3">
					<strong>
						{STRINGS.DATA_SOURCES.finalDialog.steps.step2}
					</strong>
				</p>
				<p className="ml-3">
					<strong>
						{STRINGS.DATA_SOURCES.finalDialog.steps.step3}
					</strong>
				</p>
			</div>

			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button
						onClick={props.handleFinalDialogClose}
						intent={Intent.PRIMARY}
					>
						{STRINGS.DATA_SOURCES.finalDialog.buttons.closeBtnText}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export { AddEdgeGatewayModal };
