/** This module contains the layout panel for the graph.
 *  @module
 */
import React, { useReducer, useCallback, useRef } from "react"; 
import { Classes, Tree, Button } from "@blueprintjs/core";
import { clone } from "lodash";
import { GraphDef, NodeDef, NodeProperty } from "./types/GraphTypes";
import { NodeLibrary } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { getUuidV4 } from "utils/unique-ids/UniqueIds";
import './LayoutEditorPanel.css';

/** This interface defines the properties passed into the react-flow graph React component.*/
export interface LayoutEditorPanelProps {
    /** the NodeLibrary which has the nodes to be displayed in this panel. */
    nodeLibrary: NodeLibrary;
    /** a reference to the GraphDef object with information describing all the visible nodes in the graph. */
    GraphDef: GraphDef;
    /** the array of global nodes which are not visible in the graph. */
    globalNodes: Array<NodeDef>;
    /** the handler for global layout node creation events. */
    onGlobalLayoutNodeCreated?: (node: NodeDef) => void;
    /** the handler for global layout node deletion events. */
    onGlobalLayoutNodeDeleted?: (id: string) => void;
    /** the handler for global layout node edit events. */
    onGlobalLayoutNodeEdited?: (id: string) => void;
}

type NodePath = number[];

type TreeAction =
    | { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" }
    | { type: "SET_IS_SELECTED"; payload: { path: NodePath; isSelected: boolean } }
    | { type: "ADD_TAB"; payload: { 
            handleAddGroup: Function, handleCreateNode: Function, handleDeleteTab: Function, handleDeleteGroup: Function, 
            handleEditNode: Function 
        } }
    | { type: "ADD_EXISTING_TAB"; payload: { 
            id: string, name: string, handleAddGroup: Function, handleDeleteTab: Function, handleDeleteGroup: Function, handleEditNode: Function 
        } }
    | { type: "ADD_GROUP"; payload: { 
            parentId: string, parentIndex: number, handleCreateNode: Function, handleDeleteGroup: Function, handleEditNode: Function 
        } }
    | { type: "ADD_EXISTING_GROUP"; payload: { 
            id: string, name: string, parentId: string, parentIndex: number, handleDeleteGroup: Function, handleEditNode: Function 
        } }
    | { type: "ADD_CHART"; payload: { chartId: string, chartName: string, tabId: string, tabIndex: number, groupId: string, groupIndex: number, handleDeleteChart: Function, handleEditNode: Function } }
    | { type: "DELETE_TAB"; payload: { id: string, handleDeleteNode: Function } }
    | { type: "DELETE_GROUP"; payload: { id: string, handleDeleteNode: Function } }
    | { type: "DELETE_CHART"; payload: { id: string, groupId: string, handleDeleteNode: Function } }
    | { type: "UPDATE_TAB"; payload: { id: string, name: string, 
            handleAddGroup: Function, handleCreateNode: Function, handleDeleteTab: Function, handleDeleteGroup: Function, 
            handleEditNode: Function 
        } }
    | { type: "UPDATE_GROUP"; payload: { id: string, name: string, 
            handleCreateNode: Function, handleDeleteGroup: Function, handleEditNode: Function 
        } }
    | { type: "UPDATE_CHART"; payload: { id: string, name: string, handleDeleteChart: Function, handleEditNode: Function } };

/** executes the specified visit function for the specified set of nodes and their
 *      children.
 *  @param nodes the nodes for which the visit function is to be run.
 *  @param path the path.
 *  @param visitFn the visit function.*/
function forEachNode(nodes: any[] | undefined, visitFn: (node: any) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        visitFn(node);
        forEachNode(node.childNodes, visitFn);
    }
}

/** executes the specified callback for the tree node specified by the path.
 *  @param nodes the nodes in the tree.
 *  @param path the path.
 *  @param callback the callback function.*/
function forNodeAtPath(nodes: any[], path: NodePath, callback: (node: any) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

/** update the path for all nodes and their children.
 *  @param nodes the nodes to update the path.
 *  @param currentPath the current path for the current location in the tree.*/
function updatePath(nodes: any[], currentPath: Array<number>) {
    if (nodes) {
        for (let index = 0; index < nodes.length; index++) {
            nodes[index].path = ([] as Array<number>);
            nodes[index].path.push(index);
            if (nodes[index].childNodes && nodes[index].childNodes.length > 0) {
                updatePath(nodes[index].childNodes, nodes[index].path);
            }
        }
    }
}

/** the reducer function.
 *  @param state the current state of the tree.
 *  @param action the current action.
 *  @returns the new state of the tree after taking the specified action.*/
function treeReducer(state: any[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL": {
            const newState = clone(state);
            forEachNode(newState, node => (node.isSelected = false));
            return newState;
        }
        case "SET_IS_EXPANDED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState;
        }
        case "SET_IS_SELECTED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => {
                if (node) {
                    node.isSelected = action.payload.isSelected
                }
            });
            return newState;
        }
        case "ADD_EXISTING_TAB":
        case "ADD_TAB": {
            const newState = clone(state);
            const index = newState.length + 1;
            let id = getUuidV4();
            let name = "Tab " + index;
            if (action.type === "ADD_EXISTING_TAB") {
                id = action.payload.id;
                name = action.payload.name;
            } else {
                action.payload.handleCreateNode(id, name, "ui_tab", index);
            }
            newState.push({
                id: id, key: id, depth: 0, path: [index - 1], label: name, secondaryLabel: 
                <div key={id + "-tab-buttons"}>
                    <span key={id + "-tab-buttons-add-group"} className="bp3-icon-standard bp3-icon-add" aria-label="layout-add-group"
                        onClick={(evt) => {evt.preventDefault(); evt.stopPropagation(); action.payload.handleAddGroup(id, index - 1)}}>
                    </span>
                    <span key={id + "-tab-buttons-delete-tab"} className="bp3-icon-standard bp3-icon-delete" style={{marginLeft: "5px"}}
                        aria-label="layout-delete-tab"
                        onClick={(evt) => {evt.preventDefault(); evt.stopPropagation(); action.payload.handleDeleteTab(id)}}>
                    </span>
                    <span key={id + "-tab-buttons-edit-tab"} className="bp3-icon-standard bp3-icon-edit"  style={{marginLeft: "5px"}}
                        aria-label="layout-edit-tab" 
                        onClick={(evt) => {evt.preventDefault(); evt.stopPropagation(); action.payload.handleEditNode(id)}}>
                    </span>
                </div>
            });
            return newState;
        }
        case "ADD_EXISTING_GROUP":
        case "ADD_GROUP":
            const newState5 = clone(state);
            for (const node of newState5) {
                if (node.id === action.payload.parentId) {
                    if (!node.childNodes) {
                        node.childNodes = [] as Array<any>;
                    }
                    const groupIndex = node.childNodes.length + 1;
                    let id = getUuidV4();
                    let name = "Group " + groupIndex;
                    if (action.type === "ADD_EXISTING_GROUP") {
                        id = action.payload.id;
                        name = action.payload.name;
                    } else {
                        action.payload.handleCreateNode(id, name, "ui_group", groupIndex, action.payload.parentId);
                    }
                    node.childNodes.push({
                        id: id, key: id, depth: 1, path: [action.payload.parentIndex, groupIndex - 1], label: name, secondaryLabel:
                        <div key={id + "-group-buttons"}>
                            <span key={id + "-group-buttons-delete-group"} className="bp3-icon-standard bp3-icon-delete"  style={{marginLeft: "5px"}}
                                aria-label="layout-delete-group"
                                onClick={() => action.payload.handleDeleteGroup(id)}>
                            </span>
                            <span key={id + "-group-buttons-edit-group"} className="bp3-icon-standard bp3-icon-edit"  style={{marginLeft: "5px"}}
                                aria-label="layout-edit-group"
                                onClick={() => action.payload.handleEditNode(id)}>
                            </span>
                        </div> 
                    });
                    break;
                }
            }
            return newState5;
        case "ADD_CHART": {
            const newState = clone(state);
            const tabNode = state[action.payload.tabIndex];
            if (tabNode !== null && tabNode.id === action.payload.tabId) {
                const groupNode = tabNode.childNodes[action.payload.groupIndex];
                if (groupNode !== null && groupNode.id === action.payload.groupId) {
                    if (!groupNode.childNodes) {
                        groupNode.childNodes = [] as Array<any>;
                    }
                    const chartIndex = groupNode.childNodes.length + 1;
                    const id = action.payload.chartId;
                    const name = action.payload.chartName;
                    groupNode.childNodes.push({
                        id: id, key: id, depth: 2, path: [action.payload.tabIndex, action.payload.groupIndex, chartIndex - 1], label: name, 
                        secondaryLabel:
                        <div key={id + "-chart-buttons"}>
                            <span key={id + "-chart-buttons-delete-chart"} className="bp3-icon-standard bp3-icon-delete" style={{marginLeft: "5px"}}
                                aria-label="layout-delete-chart"
                                onClick={() => action.payload.handleDeleteChart(id, groupNode.id)}>
                            </span>
                            {/*<Button key={id + "-chart-buttons-edit-chart"} style={{marginLeft: "20px", height: "10px"}} 
                                aria-label="layout-edit-chart" type="submit" 
                                onClick={() => action.payload.handleEditNode(id)}>edit
                            </Button>*/}
                        </div> 
                    });
                }
            }
            updatePath(newState, []);
            return newState;
        }
        case "DELETE_TAB": {
            const newState = clone(state);
            for (let index = 0; index < newState.length; index++) {
                const tabNode = newState[index];
                if (tabNode.id === action.payload.id) {
                    if (tabNode.childNodes && tabNode.childNodes.length > 0) {
                        for (let groupIndex = tabNode.childNodes.length - 1; groupIndex >= 0; groupIndex--) {
                            const groupNode = tabNode.childNodes[groupIndex];
                            action.payload.handleDeleteNode(groupNode.id);
                            tabNode.childNodes.splice(groupIndex, 1);
                        }
                        delete tabNode.childNodes;
                    }
                    newState.splice(index, 1);
                    action.payload.handleDeleteNode(action.payload.id);
                    updatePath(newState, []);
                    break;
                }
            }
            return newState;
        }
        case "DELETE_GROUP": {
            const newState = clone(state);
            for (let index = 0; index < newState.length; index++) {
                const tabNode = newState[index];
                if (tabNode.childNodes && tabNode.childNodes.length > 0) {
                    for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                        const groupNode = tabNode.childNodes[groupIndex];
                        if (groupNode.id === action.payload.id) {
                            action.payload.handleDeleteNode(groupNode.id);
                            tabNode.childNodes.splice(groupIndex, 1);
                            if (tabNode.childNodes.length === 0) {
                                delete tabNode.childNodes;
                            }
                            updatePath(newState, []);
                            return newState;
                        }
                    }
                }
            }
            return newState;
        }
        case "DELETE_CHART": {
            const newState = clone(state);
            for (let index = 0; index < newState.length; index++) {
                const tabNode = newState[index];
                if (tabNode.childNodes && tabNode.childNodes.length > 0) {
                    for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                        const groupNode = tabNode.childNodes[groupIndex];
                        if (groupNode.id === action.payload.groupId && groupNode.childNodes && groupNode.childNodes.length > 0) {
                            for (let chartIndex = 0; chartIndex < groupNode.childNodes.length; chartIndex++) {
                                const chartNode = groupNode.childNodes[chartIndex];
                                if (chartNode.id === action.payload.id) {
                                    action.payload.handleDeleteNode(chartNode.id);
                                    groupNode.childNodes.splice(chartIndex, 1);
                                    if (groupNode.childNodes.length === 0) {
                                        delete groupNode.childNodes;
                                    }
                                    updatePath(newState, []);
                                    return newState;
                                }
                            }
                        }
                    }
                }
            }
            return newState;
        }
        case "UPDATE_TAB": {
            const newState = clone(state);
            for (let index = 0; index < newState.length; index++) {
                const tabNode = newState[index];
                if (tabNode.id === action.payload.id) {
                    const id = action.payload.id;
                    const name = action.payload.name;
                    tabNode.label = name;
                    tabNode.secondaryLabel = <div key={id + "-tab-buttons"}>
                        <span key={id + "-tab-buttons-add-group"} className="bp3-icon-standard bp3-icon-add" aria-label="layout-add-group"
                            onClick={(evt) => {evt.preventDefault(); evt.stopPropagation(); action.payload.handleAddGroup(id, index - 1)}}>
                        </span>
                        <span key={id + "-tab-buttons-delete-tab"} className="bp3-icon-standard bp3-icon-delete" style={{marginLeft: "5px"}}
                            aria-label="layout-delete-tab"
                            onClick={(evt) => {evt.preventDefault(); evt.stopPropagation(); action.payload.handleDeleteTab(id)}}>
                        </span>
                        <span key={id + "-tab-buttons-edit-tab"} className="bp3-icon-standard bp3-icon-edit" style={{marginLeft: "5px"}}
                            aria-label="layout-edit-tab"
                            onClick={(evt) => {evt.preventDefault(); evt.stopPropagation(); action.payload.handleEditNode(id);}}>
                        </span>
                    </div>;
                    return newState;
                }
            }
            return newState;
        }
        case "UPDATE_GROUP": {
            const newState = clone(state);
            for (let index = 0; index < newState.length; index++) {
                const tabNode = newState[index];
                if (tabNode.childNodes && tabNode.childNodes.length > 0) {
                    for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                        const groupNode = tabNode.childNodes[groupIndex];
                        if (groupNode.id === action.payload.id) {
                            const id = action.payload.id;
                            const name = action.payload.name;
                            groupNode.label = name;
                            groupNode.secondaryLabel = <div key={id + "-group-buttons"}>
                                <span key={id + "-group-buttons-delete-group"} className="bp3-icon-standard bp3-icon-delete" style={{marginLeft: "5px"}}
                                    aria-label="layout-delete-group"
                                    onClick={() => action.payload.handleDeleteGroup(id)}>
                                </span>
                                <span key={id + "-group-buttons-edit-group"} className="bp3-icon-standard bp3-icon-edit" style={{marginLeft: "5px"}}
                                    aria-label="layout-edit-group"
                                    onClick={() => action.payload.handleEditNode(id)}>
                                </span>
                            </div>;
                            return newState;
                        }
                    }
                }
            }
            return newState;
        }
        case "UPDATE_CHART": {
            const newState = clone(state);
            for (let index = 0; index < newState.length; index++) {
                const tabNode = newState[index];
                if (tabNode.childNodes && tabNode.childNodes.length > 0) {
                    for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                        const groupNode = tabNode.childNodes[groupIndex];
                        if (groupNode.childNodes && groupNode.childNodes.length > 0) {
                            for (let chartIndex = 0; chartIndex < groupNode.childNodes.length; chartIndex++) {
                                const chartNode = groupNode.childNodes[chartIndex];
                                if (chartNode.id === action.payload.id) {
                                    const id = action.payload.id;
                                    const name = action.payload.name;
                                    chartNode.label = name;
                                    chartNode.secondaryLabel = <div key={id + "-chart-buttons"}>
                                        <span key={id + "-chart-buttons-delete-chart"} className="bp3-icon-standard bp3-icon-delete" 
                                            aria-label="layout-delete-chart"
                                            onClick={() => action.payload.handleDeleteChart(id, groupNode.id)}>
                                        </span>
                                        {/*<Button key={id + "-chart-buttons-edit-chart"} style={{marginLeft: "20px", height: "10px"}} 
                                            aria-label="layout-edit-chart" type="submit" 
                                            onClick={() => action.payload.handleEditNode(id)}>edit
                                        </Button>*/}
                                    </div>;
                                    return newState;
                                }
                            }
                        }
                    }
                }
            }
            return newState;
        }
        default:
            return state;
    }
}

let handleCreateNodeInClosure;
let handleDeleteNodeInClosure;
let handleEditNodeInClosure;

const INITIAL_STATE: Array<any> = [];
//INITIAL_STATE.push({id: "1", key:"1", label:"Tab 1", isExpanded: true, childNodes: [{id: "2", key:"2", label:"Group 1"}]});
//INITIAL_STATE.push({id: "3", key:"3", label:"Tab 2"});

/** Renders the layout panel component.
 *  @param props the properties passed in.
 *  @returns JSX with the layout panel component.*/
export default function LayoutEditorPanel(props: LayoutEditorPanelProps): JSX.Element {
    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);
    
    const {onGlobalLayoutNodeCreated, onGlobalLayoutNodeDeleted, onGlobalLayoutNodeEdited, nodeLibrary} = props;
    handleCreateNodeInClosure = useCallback((id: string, name: string, type: string, order: number, parentId: string = "") => {
        const libraryNode = props.nodeLibrary.getNode(type);
        if (libraryNode) {
            const node: NodeDef = {id, name: name || "", type, info: ""};
            if (libraryNode.color) {
                node.color = libraryNode.color;
            }
            if (libraryNode.properties) {
                for (const prop of libraryNode.properties) {
                    let value = prop.default;
                    switch (prop.name) {
                        case "tab":
                            value = parentId;
                            break;
                        case "order":
                            value = order;
                            break;
                    }
                    if (value !== null && value !== undefined) {
                        if (!node.properties) {
                            node.properties = ([] as Array<NodeProperty>);
                        }
                        node.properties.push({key: prop.name, value: value});
                    }
                }
            }
            if (onGlobalLayoutNodeCreated) {
                onGlobalLayoutNodeCreated(node);
            }    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGlobalLayoutNodeCreated, nodeLibrary]);

    handleDeleteNodeInClosure = useCallback((id: string) => {
        if (onGlobalLayoutNodeDeleted) {
            onGlobalLayoutNodeDeleted(id);
        }
    }, [onGlobalLayoutNodeDeleted]);

    handleEditNodeInClosure = useCallback((id: string) => {
        if (onGlobalLayoutNodeEdited) {
            onGlobalLayoutNodeEdited(id);
        }
    }, [onGlobalLayoutNodeEdited]);

    /*  this selects and de-selects rows in the tree, there were exceptions in this 
        function when I was testing removing nodes from the tree, I don't think we
        really need to select and de-select nodes, so commenting this out.
    const handleNodeClick = useCallback(
        (node: any, nodePath: any, e: React.MouseEvent<HTMLElement>) => {
            const originallySelected = node.isSelected;
            if (!e.shiftKey) {
                dispatch({ type: "DESELECT_ALL" });
            }
            dispatch({
                payload: { path: nodePath, isSelected: originallySelected == null ? true : !originallySelected },
                type: "SET_IS_SELECTED",
            });
        },
        [],
    );
*/

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleDeleteGroup = useCallback((id: string) => {
        dispatch({
            payload: {id, handleDeleteNode: handleDeleteNodeInClosure},
            type: "DELETE_GROUP", 
        });
    }, []);

    const handleAddGroup = useCallback((parentId: string, parentIndex: number) => {
        dispatch({
            payload: {
                parentId, parentIndex, handleCreateNode: handleCreateNodeInClosure, handleDeleteGroup, 
                handleEditNode: handleEditNodeInClosure
            },
            type: "ADD_GROUP",
        });
    }, [handleDeleteGroup]);

    const handleDeleteTab = useCallback((id: string) => {
        dispatch({
            payload: {id, handleDeleteNode: handleDeleteNodeInClosure},
            type: "DELETE_TAB", 
        });
    }, []);

    const handleAddTab = useCallback(() => {
        dispatch({
            payload: {
                handleAddGroup, handleCreateNode: handleCreateNodeInClosure, handleDeleteTab, handleDeleteGroup, 
                handleEditNode: handleEditNodeInClosure
            },
            type: "ADD_TAB", 
        });
    }, [handleAddGroup, handleDeleteGroup, handleDeleteTab]);

    const handleDeleteChart = useCallback((id: string, groupId: string) => {
        dispatch({
            payload: {id, groupId, handleDeleteNode: handleDeleteNodeInClosure},
            type: "DELETE_CHART", 
        });
    }, []);

    const oldGraphDef = useRef<GraphDef>();
    const oldConfigNodes = useRef<Array<NodeDef>>();
    if (oldGraphDef.current !== props.GraphDef || oldConfigNodes.current !== props.globalNodes) {
        const chartTypes = ["ui_chart", "ui_pie_chart"];
        const globalNodeIds: Array<string> = [];
        const tabGlobalNodes: Array<NodeDef> = [];
        const groupGlobalNodesByTabId: Record<string, Array<NodeDef>> = {};

        let insertTabIndex = 0;
        const tabIndexById: Record<string, number> = {};
        const tabNodeById: Record<string, NodeDef> = {};
        const groupIndexById: Record<string, number> = {};
        const groupNodeById: Record<string, NodeDef> = {};
        for (insertTabIndex = 0; insertTabIndex < nodes.length; insertTabIndex++) {
            const tabNode = nodes[insertTabIndex];
            tabIndexById[nodes[insertTabIndex].id] = insertTabIndex;
            if (tabNode.childNodes) {
                for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                    const groupNode = tabNode.childNodes[groupIndex];
                    groupIndexById[groupNode.id] = groupIndex;
                }    
            }
        }
        for (const node of props.globalNodes) {
            switch (node.type) {
                case "ui_tab":
                    tabNodeById[node.id] = node;
                    break;
                case "ui_group":
                    groupNodeById[node.id] = node;
                    break;
            }
        }

        if (oldConfigNodes.current !== props.globalNodes) {
            for (const globalNode of props.globalNodes) {
                globalNodeIds.push(globalNode.id);
                switch (globalNode.type) {
                    case "ui_tab":
                        tabGlobalNodes.push(globalNode);
                        break;
                    case "ui_group":
                        let tabId = "";
                        if (globalNode.properties) {
                            for (const property of globalNode.properties) {
                                if (property.key === "tab" && property.value !== null && property.value !== undefined) {
                                    tabId = property.value;
                                    break;
                                }
                            }
                            if (!groupGlobalNodesByTabId[tabId]) {
                                groupGlobalNodesByTabId[tabId] = [] as Array<NodeDef>;
                            }    
                        }
                        groupGlobalNodesByTabId[tabId].push(globalNode);
                        break;
                }
            }
            const orderSortFn = (a: NodeDef, b: NodeDef) => {
                let orderA = 0;
                let orderB = 0;
                if (a.properties) {
                    for (const property of a.properties) {
                        if (property.key === "order" && property.value !== null && property.value !== undefined) {
                            orderA = property.value;
                            break;
                        }
                    }
                }
                if (b.properties) {
                    for(const property of b.properties) {
                        if (property.key === "order" && property.value !== null && property.value !== undefined) {
                            orderB = property.value;
                            break;
                        }
                    }
                }
                if (orderA < orderB) {
                    return -1;
                } else if (orderA > orderB) {
                    return 1;
                }
                return 0;
            };
            tabGlobalNodes.sort(orderSortFn);
            for (const tabId in groupGlobalNodesByTabId) {
                const groups = groupGlobalNodesByTabId[tabId];
                groups.sort(orderSortFn);
            }
        }

        // We want to do deletions first and charts first then, groups, then tabs

        // Delete any charts that no longer exist or that are in the wrong place
        if (oldGraphDef.current !== props.GraphDef) {
            const existingChartIds: Array<string> = [];
            const chartIdsByGroupId: Record<string, Array<string>> = {};
            for (const chartNode of props.GraphDef.nodes) {
                if (chartTypes.includes(chartNode.type)) {
                    existingChartIds.push(chartNode.id);
                    if (chartNode.properties) {
                        for (const prop of chartNode.properties) {
                            if (prop.key === "group") {
                                const groupId = prop.value;
                                if (!chartIdsByGroupId[groupId]) {
                                    chartIdsByGroupId[groupId] = ([] as Array<string>);
                                }
                                chartIdsByGroupId[groupId].push(chartNode.id);
                            }
                        }
                    }
                }
            }
            for (let tabIndex = 0; tabIndex < nodes.length; tabIndex++) {
                const tabNode = nodes[tabIndex];
                if (tabNode.childNodes) {
                    for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                        const groupNode = tabNode.childNodes[groupIndex];
                        if (groupNode.childNodes) {
                            for (let chartIndex = 0; chartIndex < groupNode.childNodes.length; chartIndex++) {
                                const chartNode = groupNode.childNodes[chartIndex];
                                if (!existingChartIds.includes(chartNode.id) || !chartIdsByGroupId[groupNode.id] || !chartIdsByGroupId[groupNode.id].includes(chartNode.id)) {
                                    // This chart no longer exists
                                    dispatch({
                                        payload: {
                                            id: chartNode.id, groupId: groupNode.id, handleDeleteNode: handleDeleteNodeInClosure
                                        },
                                        type: "DELETE_CHART", 
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        // Delete any global nodes that no longer exist
        if (oldConfigNodes.current !== props.globalNodes) {
            for (let tabIndex = 0; tabIndex < nodes.length; tabIndex++) {
                const tabNode = nodes[tabIndex];
                if (tabNode.childNodes) {
                    for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                        const groupNode = tabNode.childNodes[groupIndex];
                        if (!globalNodeIds.includes(groupNode.id)) {
                            // This group no longer exists
                            dispatch({
                                payload: {id: groupNode.id, handleDeleteNode: handleDeleteNodeInClosure},
                                type: "DELETE_GROUP", 
                            });
                        }
                    }
                    if (!globalNodeIds.includes(tabNode.id)) {
                        // This group no longer exists
                        dispatch({
                            payload: {id: tabNode.id, handleDeleteNode: handleDeleteNodeInClosure},
                            type: "DELETE_TAB", 
                        });
                    }
                }
            }
        }

        if (oldConfigNodes.current !== props.globalNodes) {
            // Update any tabs and groups that already exist
            for (const globalNode of props.globalNodes) {
                for (let tabIndex = 0; tabIndex < nodes.length; tabIndex++) {
                    const tabNode = nodes[tabIndex];
                    if (tabNode.id === globalNode.id) {
                        // Dispatch an update to the tab node
                        dispatch({
                            payload: {
                                id: globalNode.id, name: globalNode.name, 
                                handleAddGroup, handleCreateNode: handleCreateNodeInClosure, handleDeleteTab, handleDeleteGroup, 
                                handleEditNode: handleEditNodeInClosure
                            },
                            type: "UPDATE_TAB", 
                        });
                        break;
                    }
                    if (tabNode.childNodes) {
                        let found = false;
                        for (let groupIndex = 0; groupIndex < tabNode.childNodes.length; groupIndex++) {
                            const groupNode = tabNode.childNodes[groupIndex];
                            if (groupNode.id === globalNode.id) {
                                // Dispatch an update to the tab node
                                dispatch({
                                    payload: {
                                        id: globalNode.id, name: globalNode.name, 
                                        handleCreateNode: handleCreateNodeInClosure, handleDeleteGroup, 
                                        handleEditNode: handleEditNodeInClosure
                                    },
                                    type: "UPDATE_GROUP", 
                                });
                                found = true;
                                break;
                            }
                        }
                        if (found) {
                            break;
                        }
                    }
                }
            }

            // Add any tabs that don't exist, we are adding them at the end which might not be perfect, but it is
            // good for now.
            for (const globalTabNode of tabGlobalNodes) {
                let found = false;
                for (let tabIndex = 0; tabIndex < nodes.length; tabIndex++) {
                    const tabNode = nodes[tabIndex];
                    if (globalTabNode.id === tabNode.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    tabIndexById[globalTabNode.id] = insertTabIndex++;
                    dispatch({
                        payload: {
                            id: globalTabNode.id, name: globalTabNode.name, 
                            handleAddGroup, handleDeleteTab, handleDeleteGroup, handleEditNode: handleEditNodeInClosure
                        },
                        type: "ADD_EXISTING_TAB", 
                    });                
                }
            }

            // Add any groups that do not exist
            for (const tabId in groupGlobalNodesByTabId) {
                const groupNodes = groupGlobalNodesByTabId[tabId];
                let found = false;
                for (const checkGroupNode of groupNodes) {
                    const tabIndex = tabIndexById[tabId];
                    let insertGroupIndex = 0;
                    if (tabIndex < nodes.length) {
                        const tabNode = nodes[tabIndex];
                        if (tabNode.childNodes) {
                            for (insertGroupIndex = 0; insertGroupIndex < tabNode.childNodes.length; insertGroupIndex++) {
                                const groupNode = tabNode.childNodes[insertGroupIndex];
                                if (groupNode.id === checkGroupNode.id) {
                                    found = true;
                                    break;
                                }
                            }
                        }
                    }
                    if (!found) {
                        groupIndexById[checkGroupNode.id] = insertGroupIndex++;
                        dispatch({
                            payload: {
                                id: checkGroupNode.id, name: checkGroupNode.name, 
                                parentId: tabId, parentIndex: tabIndex, 
                                handleDeleteGroup, handleEditNode: handleEditNodeInClosure
                            },
                            type: "ADD_EXISTING_GROUP"
                        });
                    }
                }
            }
        }

        // Find any charts
        for (const chartNode of props.GraphDef.nodes) {
            if (chartTypes.includes(chartNode.type)) {
                // We found a chart, check and see if it should be in the 
                let groupId, groupNode, groupIndex;
                if (chartNode.properties) {
                    for (const prop of chartNode.properties) {
                        if (prop.key === "group") {
                            groupId = prop.value;
                            groupIndex = groupIndexById[groupId];
                            groupNode = groupNodeById[groupId];
                            break;
                        }
                    }
                }
                if (groupNode && groupId && groupIndex >= 0) {
                    let tabId, tabNode, tabIndex;
                    if (groupNode.properties) {
                        for (const prop of groupNode.properties) {
                            if (prop.key === "tab") {
                                tabId = prop.value;
                                tabIndex = tabIndexById[tabId];
                                tabNode = tabNodeById[tabId];
                                break;
                            }
                        }
                    }
                    if (tabNode && tabId && tabIndex >= 0) {
                        // We have the information on either the charts current location in the tree or the future location
                        let hasChart = false;
                        if (
                            nodes[tabIndex] && nodes[tabIndex].id === tabNode.id && nodes[tabIndex].childNodes &&
                            nodes[tabIndex].childNodes[groupIndex] && nodes[tabIndex].childNodes[groupIndex].id === groupNode.id &&
                            nodes[tabIndex].childNodes[groupIndex].childNodes
                        ) {
                            for (let chartIndex = 0; chartIndex < nodes[tabIndex].childNodes[groupIndex].childNodes.length; chartIndex++) {
                                if (nodes[tabIndex].childNodes[groupIndex].childNodes[chartIndex].id === chartNode.id) {
                                    hasChart = true;
                                    break;
                                }
                            }
                        }
                        if(hasChart) {
                            // We found the chart, now update it
                            dispatch({
                                payload: {
                                    id: chartNode.id, name: chartNode.name, 
                                    handleDeleteChart, handleEditNode: handleEditNodeInClosure
                                },
                                type: "UPDATE_CHART", 
                            });
                        } else {
                            // It isn't in the tree so add it.
                            dispatch({
                                payload: {
                                    chartId: chartNode.id, chartName: chartNode.name, 
                                    tabId: tabNode.id, tabIndex, 
                                    groupId: groupNode.id, groupIndex, 
                                    handleDeleteChart, handleEditNode: handleEditNodeInClosure
                                },
                                type: "ADD_CHART", 
                            });
                        }
                    }        
                }
            }
        }

        // The graph data in the parent is cloned whenever it changes to we can count on the reference
        // changing each time it is modified
        oldGraphDef.current = props.GraphDef;
        oldConfigNodes.current = props.globalNodes;
    }

    return <div className="layout-editor-content">
        <div style={{width: "100%"}}>Tabs
            <Button aria-label="layout-add-tab" type="submit" onClick={handleAddTab} style={{float: "right"}}>+tab</Button>
        </div>
        <br />
        <Tree contents={nodes} 
            //onNodeClick={handleNodeClick} 
            onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} className={Classes.ELEVATION_0}
        />
    </div>;
}

