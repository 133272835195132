import React from "react";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { parseTimeFromDAL } from "utils/hooks";
import { Variant } from "components/common/graph/types/GraphTypes";
import { loader } from "graphql.macro";
import { FormattedNote } from "components/common/formatted-note/FormattedNote";
import { useMutation } from "@apollo/client";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider'
import { ErrorToaster } from '@tir-ui/react-components';
import { STRINGS } from "app-strings";

const AuthService = AuthServiceProvider.getService();

export interface NotesViewProps {
    /** ID of the incident associated with this trigger */
    incidentId: string;
    /** the NotesList query result */
    notesQueryResult: any;
}
interface DeleteNoteMutationInput {
    note: {
        incidentId: string,
        id: string
    }
}

/**
 * Component that displays all the notes in reverse chronological order
 * @param props Refer to NotesViewProps for details on member attributes.
 * @return  JSX component
 */
export function NotesView({ incidentId, notesQueryResult }: NotesViewProps): JSX.Element {
    const { loading, data, error, run } = notesQueryResult;

    const [deleteNote] = useMutation<any, DeleteNoteMutationInput>(
        loader("./delete-notes-mutation.graphql"), {
        onCompleted: (data) => {
            console.log(data);
            run({ noCache: true });
        },
        onError: (err) => {
            ErrorToaster({
                message: STRINGS.addNotes.errorMsgDelete
            });
            console.error(err?.message);
        }
    });

    function onDelete(noteId: string) {
        deleteNote({
            variables: {
                note: {
                    incidentId: incidentId,
                    id: noteId
                }
            }
        });
    }
    let currentUser = AuthService.getUserAccount().getUsername()

    function onEdit() {
        run({ noCache: true, fetchPolicy: "network-only" });
    }
    const notes = data?.notes?.nodes || [];
    const notesList = notes.map((item, index) => {
        const showControlTools = currentUser === item?.user?.email;
        const title = item.userType === Variant.LIFECYCLE.toUpperCase() ? STRINGS.addNotes.lifecycleRunbook : item?.user?.name || STRINGS.addNotes.unknownUser;
        const desc = item.content;
        const timeStamp = parseTimeFromDAL(item.timestamp);
        return <li key={index}>
            <FormattedNote title={title} incidentId={incidentId} noteId={item.id} timestamp={timeStamp} description={desc} runbookId={item.runbookId}
                widgetId={item.widgetId} noteType={item.type} onDelete={onDelete} showControlTools={showControlTools}
                onEdit={onEdit}
                firstNote={index === notes.length - 1}
                userType={item.userType}
            />
        </li>
    })
    return <>
        <DataLoadFacade loading={loading} data={data} error={error} showContentsWhenLoading={true}>
            <ul className="no-bullets pt-3">
                {notesList}
            </ul>
        </DataLoadFacade>
    </>;
}
