import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';

import { TIRAuthentication, TIRAuthorization } from '@tir-ui/azure-auth';
import { LoadingOverlay } from '@tir-ui/react-components';

import { Main } from 'components/app/main/Main';
import { ErrorPage } from 'pages-unrouted/error/ErrorPage';

import { client } from 'utils/services/GraphqlService';
import { embeddedClient } from 'utils/services/GraphqlEmbedService';

// Task 6822: Disable Launch Darkly For Now
// import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'utils/appinsights';
import { IS_EMBEDDED } from 'components/enums/QueryParams';
import MainAppErrorDialog from 'components/modals/MainAppErrorDialog';
import EmbedAuthWrapper from 'components/app/embed-auth-wrapper/EmbedAuthWrapper';

import '../../utils/themes/app.scss';

const {
	ENV,
	// Task 6822: Disable Launch Darkly For Now
	// CLIENT_SIDE_ID,
} = window['runConfig'];

//extract tenant from uri
const isLocalHost = window.location.host.split('.')[0] === 'localhost:3000';

/** Renders the application.
 *  @param props the properties passed in.
 *  @returns JSX with the application component.*/
function App(): JSX.Element {
	const [showDialog, setShowDialog] = useState(false);
	const [errorData, setErrorData] = useState({});

	const isRunbookViewRoute = window.location.pathname.includes('runbook-details');
	const isOnRouteWithReverseProxyAuth = IS_EMBEDDED && isRunbookViewRoute;

    /**
	 * the app is currently tied by default to the "developers" tenant of the dev environment when ran locally
	 */
	if (process.env.REACT_APP_INTEGRATION_TESTING) {
		return (
			<AppInsightsContext.Provider value={reactPlugin}>
				<ApolloProvider client={client}>
					<Main />
				</ApolloProvider>
			</AppInsightsContext.Provider>
		);
	 } else if (isOnRouteWithReverseProxyAuth) { // Should bypass MSAL Auth when is embedded in iFrame
		return (
			<AppInsightsContext.Provider value={reactPlugin}>
				<MainAppErrorDialog showDialog={showDialog} errorData={errorData} onClose={onCloseErrorModal} />
				<ApolloProvider client={embeddedClient}>
					<EmbedAuthWrapper environment={ENV}>
						<Main shouldSkipGlobalDataStores={true}/>
					</EmbedAuthWrapper>
				</ApolloProvider>
			</AppInsightsContext.Provider>
		);
	} 
	else {
		return (
			<AppInsightsContext.Provider value={reactPlugin}>
				<MainAppErrorDialog showDialog={showDialog} errorData={errorData} onClose={onCloseErrorModal} />
				<TIRAuthentication
					errorComponent={ErrorPage}
					loadingComponent={LoadingOverlay}
					env={ENV}
					forceTenant={
						isLocalHost
							? window['runConfig']['LOCALHOST_TENANT']
							: undefined
					}
					isEmbedded={IS_EMBEDDED}
					triggerDialog={(data) => {
                        showErrorDialog(data);
                    }}
				>
					<TIRAuthorization
						loadingComponent={LoadingOverlay}
						errorComponent={ErrorPage}
                        env={ENV}
						product="gelato"
					>
						<ApolloProvider client={client}>
							<Main/>
						</ApolloProvider>
					</TIRAuthorization>
				</TIRAuthentication>
			</AppInsightsContext.Provider>
		);
	}

	function onCloseErrorModal() {
		setShowDialog(false);
		setErrorData({});
	}

	function showErrorDialog(data: any) {
		setErrorData(data);
	    setShowDialog(true);
	}
}

// Task 6822: Disable Launch Darkly For Now
// export default withLDProvider({
// 	clientSideID: CLIENT_SIDE_ID,
// })(App);
export default App;
