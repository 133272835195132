import React, { useState, useCallback } from 'react';
import classnames from 'classnames';

import { DETAILS } from './details';
import { Button } from '@blueprintjs/core';

import './PageDetailPaneRenderer.scss';
import { IconNames } from '@tir-ui/react-components';

//we do this to have a way to open modals anywhere in the application, this is much simpler than having a service or data store just for this
type openDetailsFunctionType = (detailsPane: (string | undefined), props?: object) => void;
let openDetails: openDetailsFunctionType = () => {
    // notify developer in case of misuse, since the real function gets initialized by the component instance
    console.error('To use openDetails functionality a DetailPane component has to be part of the layout');
};

/**
 * DetailPane is supposed to live inside Page component 
 * This may seem strange, but it simplifies the layout
 * and makes sure automatically that any detail pane gets destroyed when switching pages
 * //todo better document this
 * //todo might need to rename this and have a DetailPange component that hold the boilerplate to create standard detail panes (title, menu on top, etc)
 * //todo handle animating it instead of abrupt appearing
 * //todo handle onClose callback
 */
function PageDetailPaneRenderer() {

    const [detailPane, setDetailPane] = useState<string | undefined>(undefined);
    const [detailPaneProps, setDetailPaneProps] = useState<object | undefined>(undefined); // todo put here modal props type
    // detailsPaneFloating = true means show details panel aligned to the right. Yes, it is a little confusing.
    const [detailsPaneFloating, setDetailsPaneFloating] = useState(localStorage.getItem('details') !== 'bottom');

    openDetails = useCallback((name: string | undefined, props?: object) => {
        setDetailPane(name);
        setDetailPaneProps(props);
    }, [setDetailPane, setDetailPaneProps]);
    //todo dark theme!

    const closeDetails = () => {
        openDetails(undefined);
    };

    const toggleDetailsPaneFloating = () => {
        const newDetailsPaneFloatingState = !detailsPaneFloating;
        setDetailsPaneFloating(newDetailsPaneFloatingState);
        localStorage.setItem('details', newDetailsPaneFloatingState? '' : 'bottom' );
    };
    if (detailPane) {
        const DetailPane = DETAILS[detailPane];
        if (DetailPane) {
            return (
                <div className={classnames('detail-pane-container', "w-min-3", {floating: detailsPaneFloating})}>
                    <div className="detail-pane-buttons">
                        <div className="detail-pane-buttons-left">
                            <Button icon={IconNames.CROSS} minimal={true} onClick={closeDetails}/>
                        </div>
                        <div className="detail-pane-buttons-right">
                            <Button icon={detailsPaneFloating ? IconNames.LIST : IconNames.LIST_DETAIL_VIEW} minimal={true}
                                    onClick={toggleDetailsPaneFloating}/>
                        </div>
                    </div>
                    <DetailPane {...detailPaneProps}/>
                </div>
            );
        } else {
            console.error(`DetailPane "${detailPane}" is not defined.`);
        }

    }

    return null;
}

export { PageDetailPaneRenderer, openDetails };

