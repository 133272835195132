/** This module contains the component for handling and auto-complete from the search
 *  service.
 *  @module
 */
import React, { useState, useEffect, useRef } from "react";
import { ItemListRenderer, ItemRenderer, Suggest } from "@blueprintjs/select";
import { FixedSizeList as List } from 'react-window';
import { FIELDS, ORDERS, SearchItem, SearchResult } from "utils/services/SearchApiService";
import { isEqual }  from "lodash";
import { useApolloClient } from "@apollo/client";
import { SearchGraphqlApiService } from "utils/services/search/SearchGraphqlApiService";

/** the debounce time in milliseconds. */
const DEBOUNCE = 500;

const PRE_HIGHLIGHT: string = "<b style=\"background-color: yellow;\">";
const POST_HIGHLIGHT: string = "</b>";

/** This interface defines the properties passed into the runbook inputs form React component.*/
interface AutocompleteControlProps {
    /** the field to search on. */
    field: FIELDS;
    /** the field to get the reported by from. */
    reportedBy?: FIELDS;
    /** a boolean value which, if true will show the highlighted matching text.  If false, the strings
     *  are shown without any markup. */
    showHighlightedText?: boolean;
    /** a boolean value, if true a new item will be created from the users text, if false no new items will be allowed,
     *  and only items returned by the autcomplete service can be selected. */
    allowCreateItem?: boolean;
    /** a string with the placeholder text, if any. */
    placeholder?: string;
    /** the optional classname for the control. */
    className?: string;
    /** the item that should be selected by default. */
    defaultSelectedItem?: any;
    /** the handler for selection events. */
    onItemSelected?: (item: SearchItemDal | object | string) => void;
    /** a boolean value which is true if the AutocompleteControl component is rendered inside the Schedule Runbook wizard in edit mode */
    isScheduledRunbookInEditMode?: boolean;
}

export type SearchItemDal = {
    [key in FIELDS]?: SearchItem
};

/** Renders the autocomplete control component.
 *  @param props the properties passed into the component.
 *  @returns JSX with the autocomplete control component.*/
export const AutocompleteControl = React.forwardRef((props: AutocompleteControlProps, ref: any) =>  {
    const [items, setItems] = useState<Array<SearchItemDal>>([]);
    const [selectedSearchItem, setSelectedSearchItem] = useState<SearchItemDal | undefined>(undefined);
    const [query, setQuery] = useState<string>(
        props.isScheduledRunbookInEditMode && props.defaultSelectedItem?.[props.field]?.name
            ? props.defaultSelectedItem[props.field].name
            : "",
    );
    const previousQuery = useRef<string | undefined>(undefined);
    const [popoverProps, setPopoverProps] = useState({minimal: true, boundary: "viewport"});

    if (ref) {
        ref.current = {
            setPopoverProps: (props) => {
                setPopoverProps(props);
            },
        };    
    }

    const isMounted = useRef(false);
    useEffect(
        () => {
            isMounted.current = true;
            return () => { isMounted.current = false }
        }, 
        []
    );

    const timeoutId = useRef<number>(0);

    const apolloClient = useApolloClient();
    const searchGraphqlService = new SearchGraphqlApiService(apolloClient);

    useEffect(
        () => {
            async function runSearchQuery() {
                try { 
                    let result: SearchResult<SearchItem> | undefined = undefined;
                    if (query) {
                        const fuzzySuggestRequest = {
                            type: mapFieldPropToDalType(props.field),
                            search: query, top: 100, 
                            orderby: {field: FIELDS.name, order: ORDERS.desc},
                            count: true,
                            searchFields: mapFieldPropToDalSearchFields(props.field),
                            facets: []
                        };
                        const strictSuggestRequest = {
                            type: mapFieldPropToDalType(props.field),
                            search: `"${query}"`, top: 100, 
                            orderby: {field: FIELDS.name, order: ORDERS.desc},
                            count: true,
                            searchFields: mapFieldPropToDalSearchFields(props.field),
                            facets: []
                        };

                        if (props.isScheduledRunbookInEditMode && props.defaultSelectedItem?.[props.field]?.name === query) {
                            result = await searchGraphqlService.search(strictSuggestRequest);
                        } else {
                            const [fuzzySuggestResult, strictSuggestResult] =  
                                await Promise.allSettled([
                                    searchGraphqlService.search(fuzzySuggestRequest), 
                                    searchGraphqlService.search(strictSuggestRequest)]) as {status: 'fulfilled' | 'rejected', value: SearchResult<SearchItem>}[];
                            
                            if (strictSuggestResult?.value?.items?.length) {
                                result = {...strictSuggestResult.value, ...fuzzySuggestResult.value};
                            } else {
                                result = fuzzySuggestResult.value;
                            }
                        }
                    } else {
                        const searchRequest = {
                            type: mapFieldPropToDalType(props.field),
                            search: "", top: 100,
                            count: true,
                            orderby: {field: FIELDS.name, order: ORDERS.desc},
                            searchFields: mapFieldPropToDalSearchFields(props.field),
                            facets: []
                        };
                        result = await searchGraphqlService.search(searchRequest);    
                    }
                    if (result?.items && isMounted.current) {
                        const resultItemsParsedFromDal: Array<SearchItemDal> = [];
                        const resultItemsStartingWithQuery: Array<SearchItem> = [];
                        const resultItemsNotStartingWithQuery: Array<SearchItem> = [];
                        let resultItemsReordered: Array<SearchItem> = [];

                        if (query) {
                            result.items.forEach((item)=> {
                                if (item.name?.toLowerCase()?.startsWith(query)) {
                                    resultItemsStartingWithQuery.push(item);
                                } else {
                                    resultItemsNotStartingWithQuery.push(item);
                                }
                            });

                            resultItemsReordered = [...resultItemsStartingWithQuery, ...resultItemsNotStartingWithQuery];
                        } else {
                            resultItemsReordered = [...result.items];
                        }

                        resultItemsReordered.forEach((item)=> {
                            resultItemsParsedFromDal.push({
                                [props.field]: {
                                    "name": item.name,
                                    "uuid": item.id,
                                    "ipaddr": item.ipaddr,
                                    "ifindex": item.ifIndex,
                                    "ifdescr": item.ifDescription,
                                    "ifalias": item.ifAlias,
                                    "reportedBy": item.reportedBy?.map(reportedBy => {
                                        let reportedByItem: string | Array<string> = reportedBy.dataSourceType;
                                        if (reportedByItem.includes('_')) {
                                            reportedByItem = reportedByItem.toLowerCase();
                                            reportedByItem = reportedByItem.split('_');
                                            reportedByItem = reportedByItem.map(word => {
                                                if (word.length === 2) {
                                                    return word.toUpperCase();
                                                }
                                                return word.charAt(0).toUpperCase() + word.slice(1);
                                            });
                                            return reportedByItem.join('');
                                        } else if (reportedByItem === reportedByItem.toUpperCase()) {
                                            reportedByItem = reportedByItem.toLowerCase();
                                            return reportedByItem = reportedByItem.charAt(0).toUpperCase() + reportedByItem.slice(1);
                                        } else {
                                            return reportedByItem;
                                        }
                                    }),
                                    "location": {
                                        "name": item.location?.name,
                                        "uuid": item.location?.id,
                                    },
                                },
                            });
                        });
                        setItems(resultItemsParsedFromDal);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            if (query !== previousQuery.current) {
                if (previousQuery.current === undefined) {
                    // Initialize the query to the search result
                    previousQuery.current = query;
                    runSearchQuery();
                } else {
                    previousQuery.current = query;
                    timeoutId.current = setTimeout(() => {
                        timeoutId.current = 0;
                        runSearchQuery();
                    }, DEBOUNCE) as any;    
                }
            }
            return () => {
                clearDebounceTimeout(timeoutId);
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [query]
    );
    
    const ItemSuggest = Suggest.ofType<{label: string, value: any, type: string}>();

    const renderItemVirtualized: ItemRenderer<{label: string, value: any, type: string}> = (item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <div key={"ac" + item.label} onClick={handleClick} className={"pt-2 pb-2 d-flex justify-content-between align-items-center ac-suggest-row" + (modifiers.active ? " selected": "")}>
                {!props.showHighlightedText && <div style={{whiteSpace: "nowrap", overflowX: "clip", textOverflow: "ellipsis"}} className="ml-2 left-text-controls">{item.label}</div>}
                {props.showHighlightedText && <div style={{whiteSpace: "nowrap", overflowX: "clip", textOverflow: "ellipsis"}} className="ml-2 left-text-controls" dangerouslySetInnerHTML={{__html: getLabel(item, props.field, query)}} />}
                {!props.showHighlightedText && <div style={{minWidth: "90px", textAlign: "right", whiteSpace: "nowrap", overflowX: "clip", textOverflow: "ellipsis"}} className="mr-2 right-text-controls">{item.type}</div>}
                {props.showHighlightedText && <div style={{minWidth: "90px", textAlign: "right", whiteSpace: "nowrap", overflowX: "clip", textOverflow: "ellipsis"}} className="mr-2 right-text-controls" dangerouslySetInnerHTML={{__html: getType(item, props.field, query)}} />}
            </div>
        );
    };

    const renderMenuVirtualized: ItemListRenderer<{label: string, value: any, type: string}> = ({ items, itemsParentRef, query, renderItem, renderCreateItem }) => {
        let width = 500;
        for (const item of items) {
            const nChars = (item?.label?.length ? item.label.length : 0) + (item?.type?.length ? item.type.length : 0);
            if (nChars > 100) {
                width = 700;
                break;
            } else if (nChars > 70) {
                width = 600;
                break;
            }
        };
        const renderedItems = items.map(renderItem).filter(item => item != null);
        if (query) {
            const createItem = renderCreateItem();
            if (createItem) {
                renderedItems.push(createItem);
            }
        }
        const Row = ({ index, style }) => (
            <div style={style}>{renderedItems[index]}</div>
        );
        return (
            <List height={300} itemCount={renderedItems.length} itemSize={30} width={width} className="ac-suggest-list" >
                {Row}
            </List>
        );
    };

    let selectedItem: any = undefined;
    let menuItems: Array<{label: string, value: Record<string, any>, type: string}> = [];

    for (let index = 0; index < items.length; index++) {
        const searchItem = items[index];
        const name = getDisplayName(searchItem, props.field);
        const type: string | undefined = props.reportedBy ? getReportedBy(searchItem, props.field) : undefined;
        const menuItem = {label: name, value: searchItem, type: type || ""};
        menuItems.push(menuItem);
        const compareItem = {...searchItem};
        if (!selectedSearchItem) {
            delete compareItem["@search.score"];
            if (index === 0 && !props.defaultSelectedItem) {
                selectedItem = menuItem;
                setSelectedSearchItem(searchItem);
                if (props.onItemSelected) {
                    props.onItemSelected(searchItem);
                }
            } else if (
                props.defaultSelectedItem && (isEqual(removeEmpty(props.defaultSelectedItem), removeEmpty(compareItem)) ||
                (props.isScheduledRunbookInEditMode && props.defaultSelectedItem?.[props.field]?.name && props.defaultSelectedItem[props.field].name === compareItem[props.field]?.name))) {
                selectedItem = menuItem;
                if (props.onItemSelected && props.isScheduledRunbookInEditMode) {
                    props.onItemSelected(searchItem);
                }
                setSelectedSearchItem(searchItem);
            }
        } else if (isEqual(selectedSearchItem, searchItem)) {
            selectedItem = menuItem;
        }
    }

    return <ItemSuggest 
        items={menuItems} 
        selectedItem={selectedItem} 
        inputProps={{placeholder: props.placeholder}}
        itemRenderer={renderItemVirtualized}
        itemListRenderer={renderMenuVirtualized}
        onItemSelect={(item) => {
            let searchItem = item.value;
            if (typeof searchItem === "string") {
                // Check to see if the user possibly typed in something that existed in the list
                for (const testItem of items) {
                    if (getDisplayName(testItem, props.field) === searchItem) {
                        searchItem = testItem;
                        break;
                    }
                }            
            }
            setSelectedSearchItem(searchItem);
            if (props.onItemSelected) {
                props.onItemSelected(searchItem);
            }
        }}
        inputValueRenderer={(item) => {
            return item.label || "";
            //return item.value["@search.text"] || item.label || "";
        }}
        itemsEqual="value"
        // The search service now takes care of this
        //itemPredicate={(query, item, index, exactMatch) => {
        //    let label: string = item?.label || "";
        //    let type: string = item?.type || "";
        //    let queryText: string = query || "";
        //    return label.toLowerCase().includes(queryText.toLowerCase()) || 
        //        type.toLowerCase().includes(queryText.toLowerCase()) || false;
        //}}
        onQueryChange={(query: string, event) => setQuery(query)}
        popoverProps={popoverProps}
        createNewItemPosition="last"
        createNewItemFromQuery={props.allowCreateItem ? (query) => {return {label: query, value: query, type: ""}} : undefined}
        createNewItemRenderer={(query, active, handleClick) => {
            //return <MenuItem icon="add" text={`Create "${query}"`} active={active} onClick={handleClick} shouldDismissPopover={false} />;
            return <div key={"ac-" + query} onClick={handleClick} className={"pt-2 pb-2 d-flex justify-content-between align-items-center ac-suggest-row" + (active ? " selected": "")}>
                <div className="ml-2 left-controls d-flex flex-wrap align-items-center">{query}</div>
            </div>;
        }}
        className={props.className ? props.className : ""}
    />;
});

/** removes any empty properties.
 *  @param obj the initial object.
 *  @returns the object after the empty properties have been removed. */
function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce(
        (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }),
        {}
      );
}

/** returns the order by field for the specified search field.
 *  @param field the enumerated field value.
 *  @returns the field for the order by object. */
// function getOrderBy(field: FIELDS): FIELDS {
//     switch (field) {
//         case FIELDS.location:
//             return FIELDS.locName;
//         case FIELDS.application:
//             return FIELDS.appName;
//         case FIELDS.network_device:
//             return FIELDS.devIpaddr;
//         case FIELDS.network_interface:
//             return FIELDS.ifcName;
//         case FIELDS.impactedLocation:
//         case FIELDS.impactedApplication:
//         case FIELDS.impactedUser:
//             return field;
//     }
//     return field;
// }

/** returns the search fields for the specified field and reportedBy field.
 *  @param field the enumerated field value.
 *  @param reportedBy the enumerated reported by field value.
 *  @returns the Array of search fields. */
// function getSearchFields(field: FIELDS, reportedBy: FIELDS | undefined): Array<FIELDS> {
//     const fields: Array<FIELDS> = [getOrderBy(field)];
//     if (field === FIELDS.network_device) {
//         fields.push(FIELDS.devName);
//     } else if (field === FIELDS.network_interface) {
//         fields.push(FIELDS.ifcIpaddr);
//         //fields.push(FIELDS.ifcIfindex);
//     }
//     if (reportedBy) {
//         fields.push(reportedBy);
//     }
//     return fields;
// }

/** returns the display name for the specified search result and field.
 *  @param searchItem the SuggestItem with the search result.
 *  @param field the enumerated field value.
 *  @returns a String with the display name. */
function getDisplayName(searchItem: SearchItemDal, field: FIELDS): string {
    let name = "Unknown";
    switch (field) {
        case FIELDS.location:
            name = (searchItem as any).location?.name;
            break;
        case FIELDS.application:
            name = (searchItem as any).application?.name;
            break;
        case FIELDS.network_device:
            name = (searchItem as any).network_device?.name + " (" + (searchItem as any).network_device?.ipaddr + ")";
            //name = (searchItem as any).network_device?.ipaddr;
            break;
        case FIELDS.network_interface:
            name = (searchItem as any).network_interface?.name + " (" + (searchItem as any).network_interface?.ipaddr + ":" + (searchItem as any).network_interface?.ifindex + ")";
            //name = (searchItem as any).network_interface?.name;
            break;
        case FIELDS.impactedLocation:
            name = (searchItem as any).impactedLocation;
            break;
        case FIELDS.impactedApplication:
            name = (searchItem as any).impactedApplication;
            break;
        case FIELDS.impactedUser:
            name = (searchItem as any).impactedUser;
            break;
    }
    return name;
}

/** returns the reported by array for the specified search item.
 *  @param searchItem the search result.
 *  @param field the field that is being searched.
 *  @returns an array of strings with the reported by text or undefined. */
function getReportedByArray(searchItem: SearchItemDal, field: FIELDS): Array<string> | undefined {
    let reportedBy: Array<any> | undefined = undefined;
    switch (field) {
        case FIELDS.location:
            reportedBy = (searchItem as any).location?.reportedBy;
            break;
        case FIELDS.application:
            reportedBy = (searchItem as any).application?.reportedBy;
            break;
        case FIELDS.network_device:
            reportedBy = (searchItem as any).network_device?.reportedBy;
            break;
        case FIELDS.network_interface:
            reportedBy = (searchItem as any).network_interface?.reportedBy;
            break;
    }
    if (reportedBy && reportedBy.length > 1) {
        const items: Array<string> = [];
        reportedBy = reportedBy?.filter((item) => {
            const found = !items.includes(item);
            items.push(item);
            return found;
        });
    }
    return reportedBy;
}

/** returns the reported by information for the specified search item.
 *  @param searchItem the search result.
 *  @param field the field that is being searched.
 *  @returns a string with the reported by text or undefined. */
function getReportedBy(searchItem: SearchItemDal, field: FIELDS): string | undefined {
    let type: Array<string> | undefined = getReportedByArray(searchItem, field);
    return type ? type.join(", ") : undefined;
}

/** looks at the returned hightlighted text and determines if that is the label or the 
 *  reported by field and returns either the highlighted text or the label.
 *  @param menuItem the menu item whose label is to be returned.
 *  @param field the enumerated field value.
 *  @returns the highlighted text string or the label. */
function getLabel(menuItem: {label: string, value: any, type: string}, field: FIELDS, query: string): string {
    let label = menuItem.label;
    let highlightResult = query ? menuItem.value[field].name.replace(new RegExp(query, 'gi'),`${PRE_HIGHLIGHT}$&${POST_HIGHLIGHT}`) : `${PRE_HIGHLIGHT}${query}${POST_HIGHLIGHT}`;
    if (highlightResult) {
        let highlightResultCopy = highlightResult.replace(new RegExp(PRE_HIGHLIGHT, 'gi'), "");
        highlightResultCopy = highlightResultCopy.replace(new RegExp(POST_HIGHLIGHT, 'gi'), "");
        if (field === FIELDS.network_device) {
            let name = menuItem?.value?.network_device?.name;
            let ipaddr = menuItem?.value?.network_device?.ipaddr;
            if (highlightResultCopy === name) {
                return highlightResult + " (" + ipaddr + ")";
            } else if (highlightResultCopy === ipaddr) {
                return name + " (" + highlightResult + ")";
            }
        } else if (field === FIELDS.network_interface) {
            let name = menuItem?.value?.network_interface?.name;
            let ipaddr = menuItem?.value?.network_interface?.ipaddr;
            let ifindex = menuItem?.value?.network_interface?.ifindex;
            if (highlightResultCopy === name) {
                return highlightResult + " (" + ipaddr + ":" + ifindex + ")";
            } else if (highlightResultCopy === ipaddr) {
                return name + " (" + highlightResult + ":" + ifindex + ")";
            //} else if (highlightResultCopy === ifindex) {
            //    return name + " (" + ipaddr + ":" + highlightResult + ")";
            }
        } else {
            if (highlightResultCopy === label) {
                return highlightResult;
            }    
        }
    }
    return label;
}

/** looks at the returned hightlighted text and determines if that is the label or the 
 *  reported by field and returns either the highlighted text or the reported by (type).
 *  @param menuItem the menu item whose label is to be returned.
 *  @param field the field that is being searched.
 *  @returns the highlighted text string or the reported by (type). */
function getType(menuItem: {label: string, value: any, type: string}, field: FIELDS, query: string): string {
    let type = menuItem.type;
    if (query) {
        let reportedBy = getReportedByArray(menuItem.value, field);
        if (reportedBy?.length) {
            reportedBy = [...reportedBy];
            for (let index = 0; index < reportedBy.length; index++) {
                if (reportedBy[index].toLowerCase().includes(query.toLowerCase())) {
                    const regEx = new RegExp(query, 'gi');
                    reportedBy[index] = reportedBy[index].replace(regEx, `${PRE_HIGHLIGHT}$&${POST_HIGHLIGHT}`);
                }    
            }
            return reportedBy.join(", ");
        }
    }
    return type;
}

/** clears the timeout id.
 *  @param timeoutId the object returned by useRef that contains the timeout id. */
 function clearDebounceTimeout(timeoutId: {current: number}): void {
    if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = 0;
    }
}

/** looks at the field prop and returns the corresponding search type value that can be used with dal.
 *  @param field the value of the field prop.
 *  @returns the search type value that can be used with dal for a specific field prop value. */
function mapFieldPropToDalType(field: FIELDS): string {
    let dalType: string;
    switch (field) {
        case FIELDS.network_interface:
            dalType = "NetworkInterface";
            break;
        case FIELDS.network_device:
            dalType = "NetworkDevice";
            break;
        case FIELDS.application:
            dalType = "Application";
            break;
        case FIELDS.location:
            dalType = "Location";
            break;
        default:
            dalType = field;
    }
    return dalType; 
}

/** looks at the field prop and returns the corresponding search fields that can be used with dal.
 *  @param field the value of the field prop.
 *  @returns the search fields that can be used with dal for a specific field prop value. */
function mapFieldPropToDalSearchFields(field: FIELDS): Array<FIELDS> {
    let dalSearchFields: Array<FIELDS>;
    switch (field) {
        case FIELDS.network_interface:
            dalSearchFields = [FIELDS.NAME, FIELDS.IP_ADDRESS, FIELDS.LOCATION_NAME, FIELDS.DATA_SOURCE_TYPE_NAME];
            break;
        case FIELDS.network_device:
            dalSearchFields = [FIELDS.NAME, FIELDS.IP_ADDRESS, FIELDS.HOSTNAME, FIELDS.TYPE, FIELDS.LOCATION_NAME, FIELDS.DATA_SOURCE_TYPE_NAME];
            break;
        case FIELDS.application:
            dalSearchFields = [FIELDS.NAME, FIELDS.DATA_SOURCE_TYPE_NAME];
            break;
        case FIELDS.location:
            dalSearchFields = [FIELDS.NAME, FIELDS.TYPE, FIELDS.CITY, FIELDS.STATE, FIELDS.COUNTRY, FIELDS.DATA_SOURCE_TYPE_NAME];
            break;
        default:
            dalSearchFields = [FIELDS.NAME];
    }
    return dalSearchFields; 
}
