import React, { useEffect, useMemo, useRef, useState } from "react";
import { ConditionKey, ConditionKeyProps } from "./condition-key/ConditionKey";
import { ConditionOperation, ConditionOperationProps } from "./condition-operation/ConditionOperation";
import { ConditionValue, ConditionValueProps, conditionValueType } from "./condition-value/ConditionValue";
import { generateRandomID, getMatchingItemForValue, getValueForItem, keyItem, operationItem } from "./ConditionUtils";
import { isEqual } from 'lodash';
import "./Condition.scss";

export type ConditionValueType = {
    id?: string;
    category?: string,
    conditionKey?: string,
    operation?: string,
    value?: ConditionValueProps["value"],
}

export type RawConditionValueType = {
    id?: string,
    category?: string,
    conditionKey?: ConditionKeyProps["value"],
    operation?: ConditionOperationProps["value"],
    value?: ConditionValueProps["value"],
}

export interface ConditionProps {
    className?: string;

    id?: string;
    readOnly?: boolean;

    conditionCategory?: ConditionKeyProps["category"];
    conditionKey?: ConditionKeyProps["value"];
    conditionKeyList?: ConditionKeyProps["options"];

    
    operation?: ConditionOperationProps["value"];
    operationList?: ConditionOperationProps["options"];
    
    value?: ConditionValueProps["value"];
    
    onChange?: (newConditionValue: ConditionValueType, newRawCondition: RawConditionValueType) => void;
    /** Passing this flag as true will reset the selected operation to it's initial value when user changes condition key */
    clearOperationOnConditionKeyChange?: boolean;
    /** Passing this flag as true will reset the the value field to it's initial value when user changes condition key */
    clearValueOnConditionKeyChange?: boolean;
    /** Passing this flag as true will reset the the value field to it's initial value when user changes the operation */
    clearValueOnOperationChange?: boolean;

    conditionKeyProps?: ConditionKeyProps;
    /** The props object that will be used when building the operation field (If these props change dynamically, look at getOperationFieldProps) */
    operationProps?: ConditionOperationProps;
    /** The props object that will be used when building the 'value' field (If these props change dynamically, look at getValueFieldProps) */
    valueProps?: ConditionValueProps;

    /** A method that will be called if provided to build the props object of the "key" field dynamically */
    getKeyFieldProps?: (props: {
        /** Current value for the condition */
        condition: RawConditionValueType,
        /** This operator control's current set of props as derived form other properties */
        currentProps: Partial<ConditionOperationProps>,
    }) => Partial<ConditionKeyProps>;

    /** A method that will be called if provided to build the props object of the operation field dynamically */
    getOperationFieldProps?: (props: {
        /** Current value for the condition */
        condition: RawConditionValueType,
        /** This operator control's current set of props as derived form other properties */
        currentProps: Partial<ConditionOperationProps>,
    }) => Partial<ConditionOperationProps>;

    /** A method that will be called if provided to build the props object of the value field dynamically */
    getValueFieldProps?: (props: {
        /** Current value for the condition */
        condition: RawConditionValueType,
        /** This operator control's current set of props as derived form other properties */
        currentProps: Partial<ConditionValueProps>,
    }) => Partial<ConditionValueProps>;

    syncValueParamChanges?: boolean;
    cannotBeNested?: boolean;
}

export function Condition ({
    className,
    id = "c" + generateRandomID(),
    readOnly = false,
    conditionCategory,
    conditionKey,
    conditionKeyList,
    operation,
    operationList,
    value,
    onChange,
    clearOperationOnConditionKeyChange = false,
    clearValueOnConditionKeyChange = false,
    clearValueOnOperationChange = false,
    conditionKeyProps,
    operationProps,
    valueProps,
    getKeyFieldProps,
    getOperationFieldProps,
    getValueFieldProps,
    syncValueParamChanges = readOnly,
}: ConditionProps) {
    const [conditionKeyValue, _setConditionKeyValue] = useState<ConditionProps["conditionKey"] | undefined>(conditionKey || conditionKeyProps?.value);
    const [operationValue, _setOperation] = useState<ConditionProps["operation"] | undefined>(operation || operationProps?.value);
    const [conditionValue, setConditionValue] = useState<ConditionValueProps["value"] | undefined>(
        ((operation as any)?.raw as any)?.impliedValue !== undefined ? ((operation as any)?.raw as any)?.impliedValue : value || valueProps?.value
    );

    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);

    const setConditionKeyValue = function (newConditionKeyValue: keyItem) {
        _setConditionKeyValue(newConditionKeyValue);
        if (typeof newConditionKeyValue !== "string" && newConditionKeyValue?.operationProps?.value) {
            setOperation(newConditionKeyValue?.operationProps?.value);
        } else if (clearOperationOnConditionKeyChange) {
            setOperation("");
        }
        if (typeof newConditionKeyValue !== "string" && newConditionKeyValue?.valueProps?.value) {
            setConditionValue(newConditionKeyValue?.valueProps?.value);
        } else if (clearValueOnConditionKeyChange) {
            setConditionValue(null);
        }
    }

    const setOperation = function (newOperation: operationItem) {
        _setOperation(newOperation);
        if (typeof newOperation !== "string" && newOperation?.valueProps?.value) {
            setConditionValue(newOperation?.valueProps?.value);
        } else if (clearValueOnOperationChange) {
            setConditionValue(null);
        }
    }

    // If input param for conditionKey changes and is different from current value, update local state
    useEffect(() => {
        if (syncValueParamChanges) {
            let newConditionKey;
    
            if (conditionKey !== undefined && !isEqual(conditionKeyValue && getValueForItem(conditionKeyValue), conditionKey && getValueForItem(conditionKey))) {
                newConditionKey = conditionKey;
            } else if (conditionKeyProps?.value !== undefined && !isEqual(conditionKeyValue && getValueForItem(conditionKeyValue), conditionKeyProps.value && getValueForItem(conditionKeyProps.value))) {
                newConditionKey = conditionKeyProps.value;
            }
            if (newConditionKey) {
                setConditionKeyValue(newConditionKey);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionKey, conditionKeyProps?.value]);

    // If input param for operation changes and is different from current value, update local state
    useEffect(() => {
        if (syncValueParamChanges) {
            let newOperation;
    
            if (operation !== undefined && !isEqual(operationValue && getValueForItem(operationValue), operation && getValueForItem(operation))) {
                newOperation = operation;
            } else if (operationProps?.value !== undefined && !isEqual(operationValue && getValueForItem(operationValue), operationProps.value && getValueForItem(operationProps.value))) {
                newOperation = operationProps.value;
            }
            if (newOperation) {
                setOperation(newOperation);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operation, operationProps?.value]);

    // If input param for conditionValue changes and is different from current value, update local state
    useEffect(() => {
        if (syncValueParamChanges) {
            if (value !== undefined && !isEqual(conditionValue, value)) {
                setConditionValue(value);
            } else if (valueProps?.value !== undefined && !isEqual(value, valueProps.value)) {
                setConditionValue(valueProps.value);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, valueProps?.value]);

    function fireOnChangeEvent ({
        conditionKey = conditionKeyValue,
        operation = operationValue,
        value = conditionValue
    }: { conditionKey?: any, operation?: any, value?: any } = {}) {
        if (isMounted.current && onChange) {
            const category = typeof conditionCategory === "string" ? conditionCategory : undefined;
            onChange({
                id,
                category,
                conditionKey: conditionKey && getValueForItem(conditionKey),
                operation: operation && getValueForItem(operation),
                value: (operation?.raw as any)?.impliedValue === undefined ? value : (operation.raw as any)?.impliedValue,
            }, {
                id,
                category,
                conditionKey: conditionKey,
                operation: operation,
                value,
            });
        }
    };

    function onConditionKeyChange (newConditionKey: keyItem) {
        setConditionKeyValue(newConditionKey);
        fireOnChangeEvent({
            conditionKey: newConditionKey
        });
    };

    function onOperationChange (newOperation: operationItem) {
        setOperation(newOperation);
        fireOnChangeEvent({
            operation: newOperation
        });
    }

    function onValueChange (newValue: conditionValueType) {
        setConditionValue(newValue);
        fireOnChangeEvent({
            value: newValue
        });
    }

    let currentConditionState;
    if (getKeyFieldProps || getOperationFieldProps || getValueFieldProps) {
        currentConditionState = {
            id,
            category: typeof conditionCategory === "string" ? conditionCategory : undefined,
            conditionKey: conditionKeyValue && getValueForItem(conditionKeyValue),
            operation: operationValue && getValueForItem(operationValue),
            value: conditionValue,
        };
    }

    let mergedConditionKeyProps = conditionKeyProps;
    if (getKeyFieldProps) {
        mergedConditionKeyProps = getKeyFieldProps({
            condition: currentConditionState,
            currentProps: mergedConditionKeyProps || {},
        });
    }

    // What's with the below merges?
    // - Allows individual condition keys to control the allowed operations
    // - Allows individual condition keys and/or operations to control how the value field will behave
    // Example
    // . A condition key "Hello" can list the allowed operations keys are "World" and "Planet"
    // . And the operation "Planet" can define the value control as a dropdown with list of planets,
    // . While the operation "World" can define value control as a toggle switch that returns "yes" or "no"
    // Without the merges, the end-user developer would have to write this as logic inside the renderer methods.
    // This just makes it easier for the majority of simple straightforward cases.

    let mergedOperationProps = operationProps;
    // If currently selection condition key has property overrides for operation control, merge it
    // on top of `operatinProps` property to create the final merged version
    if (conditionKeyValue && typeof conditionKeyValue !== "string" && conditionKeyValue.operationProps) {
        mergedOperationProps = {
            ...(mergedOperationProps || {}),
            ...conditionKeyValue.operationProps,
        };
    }
    if (getOperationFieldProps) {
        mergedOperationProps = getOperationFieldProps({
            condition: currentConditionState,
            currentProps: mergedOperationProps || {},
        });
    }

    let mergedValueProps = valueProps;
    // If currently selection condition key has property overrides for value control, merge it
    // on top of `valueProps` property to create the final merged version
    if (conditionKeyValue && typeof conditionKeyValue !== "string" && conditionKeyValue.valueProps) {
        mergedValueProps = {
            ...(mergedValueProps || {}),
            ...conditionKeyValue.valueProps,
        };
    }
    // If currently selection operation value has property overrides for value control, merge it
    // on top of the already merged `valueProps` + condition key overrides object
    if (operationValue && typeof operationValue !== "string" && operationValue.valueProps) {
        mergedValueProps = {
            ...(mergedValueProps || {}),
            ...operationValue.valueProps,
        };
    }
    if (getValueFieldProps) {
        mergedValueProps = getValueFieldProps({
            condition: currentConditionState,
            currentProps: mergedValueProps || {},
        });
    }


    let operationFieldNotReqd = false;
    // If active condition key had `operationNotReqd` flag defined, pick that value
    if (conditionKeyValue && typeof conditionKeyValue !== "string" && conditionKeyValue.operationNotReqd !== undefined) {
        operationFieldNotReqd = conditionKeyValue.operationNotReqd;
    }

    let valueFieldNotReqd = false;
    const matchingOperation = useMemo(() => {
        if (typeof operationValue === "string" && operationList && operationList.length > 0) {
            return getMatchingItemForValue(operationList, operationValue);
        } else {
            return operationValue;
        }
    }, [operationValue, operationList]);
    const matchingConditionKey = useMemo(() => {
        if (typeof conditionKeyValue === "string" && conditionKeyList && conditionKeyList.length > 0) {
            return getMatchingItemForValue(conditionKeyList, conditionKeyValue);
        } else {
            return conditionKeyValue;
        }
    }, [conditionKeyValue, conditionKeyList]);
    // If active operation had `valueNotReqd` flag defined, pick that value
    if (matchingOperation && typeof matchingOperation !== "string" && matchingOperation.valueNotReqd !== undefined) {
        valueFieldNotReqd = matchingOperation.valueNotReqd;
    // If active operation didn't have an override but active condition key had `valueNotReqd` flag defined, pick that value
    } else if (matchingConditionKey && typeof matchingConditionKey !== "string" && matchingConditionKey.valueNotReqd !== undefined) {
        valueFieldNotReqd = matchingConditionKey.valueNotReqd;
    }
    return <div className={"condition" + (readOnly ? " read-only" : "") + (className ? " " + className : "")}>
        <ConditionKey
            readOnly={readOnly}
            options={conditionKeyList}
            category={conditionCategory}
            {...mergedConditionKeyProps}
            value={conditionKeyValue}
            onChange={onConditionKeyChange}
        />
        {
            !operationFieldNotReqd &&
            <ConditionOperation
                readOnly={readOnly}
                options={operationList}
                {...mergedOperationProps}
                value={operationValue}
                onChange={onOperationChange}
            />
        }
        {
            !valueFieldNotReqd && <ConditionValue
                readOnly={readOnly}
                {...mergedValueProps}
                value={conditionValue}
                onChange={onValueChange}
            />
        }
    </div>;
}
