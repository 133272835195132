/** an enum with the filters for the GraphQL queries.*/
export enum FILTER_NAME {
    siteId                  = "siteId",
    overlayId               = "overlayId",
    localSiteId             = "localSiteId",
    remoteSiteId            = "remoteSiteId",
    underlayId              = "underlayId",
    ips                     = "ips",
    hostId                  = "hostId",
    interfaceId             = "interfaceId",
    interfaceName           = "interfaceName",
    interfaceIds            = "interfaceIds",
    interfaceNames          = "interfaceNames",
    ifIndex                 = "ifIndex",
    deviceId                = "deviceId",
    deviceName              = "deviceName",
    deviceIds               = "deviceIds",
    deviceNames             = "deviceNames",
    deviceIp                = "deviceIp",
    applicationId           = "applicationId",
    applicationName         = "applicationName",
    applicationIds          = "applicationIds",
    applicationNames        = "applicationNames",
    customPropertyIds       = "customPropertyIds",
    locationId              = "locationId",
    locationName            = "locationName",
    locationIds             = "locationIds",
    locationNames           = "locationNames",
    unitDataType            = "type",
    incidentId              = "incidentId",
    incidentIds             = "incidentIds",
    triggerId               = "triggerId",
    sourceId                = "sourceId",
    issueId                 = "issueId",
    targetId                = "targetId",
    datasetId               = "datasetId",
    runbookId               = "runbookId",
    runbookDatasetId        = "runbookDatasetId",
    runbookConfigId         = "runbookConfigId",
    triggerTypes            = "triggerTypes",
    configurationIds        = "configurationIds",
    runbookSourceTypes      = "sourceTypes",
    clientHostIds           = "clientHostIds",
    serverHostIds           = "serverHostIds",
    priority                = "priority",
    incidentStatus          = "incidentStatus",
    indicatorMapping        = "indicatorMapping",
    kinds                   = "kinds",
    impactedLocationId      = "impactedLocationId",
    impactedApplicationId   = "impactedApplicationId",
    impactedUserId          = "impactedUserId",
    completionStatus        = "completionStatus",
    variant                 = "variant",
    token                   = "token",
    regions                 = "regions",
    latitude                = "latitude",
    longitude               = "longitude",
};
