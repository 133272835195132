/** This module contains utilities for editing aggregator nodes and validating aggregator nodes.
 *  @module
 */
import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "../../types/GraphTypes";
import { GenericKey, NodeUtils } from "../../../../../utils/runbooks/NodeUtil";
import { dataOceanNodes, getFirstParentOfTypeFromGraphDef, subflowNodes, transformNodes } from "utils/runbooks/RunbookUtils";
import { Elements } from "react-flow-renderer";
import { DataOceanUtils } from "../data-ocean/DataOceanUtils";
import { DataOceanMetadata } from "../data-ocean/DataOceanMetadata.type";
import { TransformKey } from "../transform/TransformNodeUtils";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";

/** an enum with all of the valid aggregator node properties. */
export enum AGGREGATOR_NODE_EDIT_PROPS {
    /** the group by property. */
    GROUP_BY        = "groupBy",
    /** the group by mode property. */
    GROUP_BY_MODE   = "groupByMode",
    /** the synthetic metrics property. */
    SYNTH_METRICS   = "synthMetrics",
    /** the synthetic keys property. */
    SYNTH_KEYS      = "synthKeys"
}

/** Utility class for logic node,*/
export class AggregateNodeUtil extends NodeUtils {
    /** the error messages for the logic node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.aggregateNode;
    
    /** Check if aggregate node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on al the nodes. 
     *  @param variables the map of variables by scope.
     *  @returns  is node valid. */
    static isNodeValid(nodeId: string | undefined | null, graphDef: GraphDef, variables?: VariableContextByScope): boolean {
        if (!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        this.validateNode(nodeId, errors, graphDef, variables)
        return errors.length === 0;
    }

    /** Check if a aggregate node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on al the nodes. 
     *  @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });

        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables);
        this.validateDONodeCompatiblity(curNode, errors, graphDef);
    }

    /** Validates the aggregate node against a parent data ocean node verifies if the input type and metric type matches
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *  @param graphDef - graph with info on al the nodes. */
    static validateDONodeCompatiblity(node: NodeDef | undefined, errors: string[], graphDef: GraphDef): void {
        if (!node) {
            return;
        }
        const doNode = this.getParentNode(node.id, graphDef, [...dataOceanNodes, ...transformNodes, ...subflowNodes]);
        if (!doNode) {
            errors.push(this.errMsgs.noParentDONode);
            return;
        }
        const isTimeSeries = this.getPropertyFromNode("timeSeries", doNode)?.value;
        if(isTimeSeries){
            errors.push(this.errMsgs.timeseriesNotSupported);
        }
    }

    /** Set default properties for an aggregator node element based on the trigger node and parent node if any
     *  @param node data ocean node whose property needs to be updated
     *  @param graphDef Graph Definition object to get information on trigger and parent nodes
     *  @param elements Properties will be updated on the element in the elements array matching the node. */
    static setDefaultPropertiesOnConnect(node: NodeDef, graphDef: GraphDef, elements: Elements): void {
        let groupByProp: any = undefined;
        let groupByProps = node.properties?.filter(prop => prop.key === "groupBy");
        if (!groupByProps?.length) {
            groupByProp = {key: "groupBy", value: []};
            node.properties?.push(groupByProp);
        } else {
            groupByProp = groupByProps[0];
        }
        groupByProp.value = [];

        let synthMetricsProp: any = undefined;
        let synthMetricsProps = node.properties?.filter(prop => prop.key === "synthMetrics");
        if (!synthMetricsProps?.length) {
            synthMetricsProp = {key: "synthMetrics", value: []};
            node.properties?.push(synthMetricsProp);
        } else {
            synthMetricsProp = synthMetricsProps[0];
        }
        synthMetricsProp.value = [];
        const doNode: NodeDef | null = getFirstParentOfTypeFromGraphDef(node, graphDef, dataOceanNodes);
        if (doNode && doNode.properties && node.properties) {
            // Set the columns
            const objType = NodeUtils.getPropertyFromNode("objType", doNode)?.value;
            if (objType) {
                const metrics = NodeUtils.getPropertyFromNode('metrics', doNode)?.value || [];
                synthMetricsProp.value = metrics.map(item => {
                    const metric = DataOceanUtils.dataOceanMetaData.metrics[item];
                    return {
                        function: "avg", id: "agg_" + metric.id, label: metric.label + " (avg)", included: true,
                        originalField: metric, type: metric.type, unit: metric.unit
                    };
                });
            }
        }
        elements.forEach(element => {
            if (element.id === node.id && element.data.properties.length > 0) {
                //update react graph element
                element.data.properties.filter(prop => prop.key === "groupBy")[0].value = groupByProp.value;
                element.data.properties.filter(prop => prop.key === "synthMetrics")[0].value = synthMetricsProp.value;
            }
        });
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the aggregator node 
     *      groupBy key.  Once the key is generated, the column def is created.
     *  @param objMetricMetaData the JSON with the Data Ocean API details.
     *  @param xformKeys these keys are used to get the key defs if the parent of the aggregator node is a transform node.
     *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
     *  @param groupBy the array of group by aggregator keys.
     *  @returns an array with all the key definitions. */
     static getExpandedKeysForGroupBy(
        objMetricMetaData: DataOceanMetadata, xformKeys: Array<TransformKey>, customProperties: CustomProperty[] ,groupBy: Array<string>
    ): Array<GenericKey> {
        let expandedGroupByKeys: Array<GenericKey> = [];
        if (groupBy?.length) {
            for (const groupByKey of groupBy) {
                if (groupByKey.length > 0) {
                    if (groupByKey.includes("custom.")) {
                        const id = groupByKey.substring(groupByKey.indexOf("custom.") + 7, groupByKey.indexOf(".name"));
                        for (const property of customProperties) {
                            if (property.id === id) {
                                const keyCol = { label: property.name, id: groupByKey, type: "string", unit: "" };
                                expandedGroupByKeys.push(keyCol);
                                break;
                            }
                        }
                    } else {
                        const metaDataKeys = groupByKey.split(".");
                        let keyDef: any = {properties: objMetricMetaData.keys};
                        for (const metaDataKey of metaDataKeys) {
                            keyDef = keyDef.properties[metaDataKey];
                        }
                        if (keyDef) {
                            const keyCol = { label: keyDef.label, id: groupByKey, type: keyDef.type, unit: keyDef.unit || "" };
                            expandedGroupByKeys.push(keyCol);
                        } else {
                            for (const xformKey of xformKeys) {
                                if (xformKey.id === groupByKey) {
                                    const keyCol = { ...xformKey, synthetic: true };
                                    expandedGroupByKeys.push(keyCol);    
                                    break;
                                }
                            }
                        }    
                    }
                }
            }
        }
        return expandedGroupByKeys;
    }
}
