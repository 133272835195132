/** This file defines the network summary view react component.  The network summary view React component 
 *  displays the network summary dashboard in a grid.
 *  @module */
import React, { useState, useLayoutEffect } from "react";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { IconNames } from "@tir-ui/react-components";
import { ImpactedLocationsView } from "./views/impacted-locations/ImpactedLocationsView";
import { ImpactedServicesView } from "./views/impacted-services/ImpactedServicesView";
import { IncidentPriorityTimeSeriesView } from "./views/incident-priority-timeseries/IncidentPriorityTimeSeriesView";
import { IncidentDistributionView } from "./views/incident-distribution/IncidentDistributionView";
import { IncidentUserImpactView } from "./views/incident-user-impact/IncidentUserImpactView";
import { STRINGS, HELP } from "app-strings";
import { GroupMetricEvent, GroupMetricSource } from "components/common/chart-base/ChartBase";
import { PriorityFilter } from "components/common/priority-filter/PriorityFilter";
import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard";
import { INCIDENT_STATUS, PRIORITY, SIZE } from "components/enums";
import AutoUpdateControl, { AutoUpdateState, TimeType } from "components/common/auto-update/AutoUpdateControl";
import { FILTER_NAME, useGlobalFilters, useUserPreferences } from "utils/hooks";
import { getURL } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import { useHistory } from "react-router";
import { FILTER_IN_USER_PREFS_KEY, DEFAULT_SEARCH_PREF, SearchPreference, SEARCH_ENGINE } from "utils/services/UserPrefsTypes";
import { OngoingIncidentsFilter } from "components/hyperion/controls/ongoing-incidents-filter/OngoingIncidentsFilter";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { PaywallDialog } from "./views/paywall-dialog/PaywallDialog";
import { 
    getFacetValue, getImpactedApplicationFacetForEngine, getImpactedLocationFacetForEngine, getPriorityFacetForEngine, 
    getStatusFacetForEngine, toCamelCase 
} from "pages/incident-search/IncidentSearchUtils";
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";

const AuthService  = AuthServiceProvider.getService();

const initAutoUpdate: AutoUpdateState = {
    enabled: true,
    interval: 1,
    lastUpdate: 60* Math.floor(new Date().getTime() / (60 * 1000)),
    sequenceNumber: 0
};
export const AutoUpdateContext = React.createContext(initAutoUpdate);

/** Renders the network summary dashboard page.
 *  @param props the properties passed in.
 *  @returns JSX with the network summary page component.*/
export default function NetworkSummaryPage(props): JSX.Element {
    const { filters } = useGlobalFilters({});
    const history = useHistory();
    const [paywall, setPaywall] = useState<string>('ACTIVE');
    const [autoUpdate, setAutoUpdate] = useState<any>(initAutoUpdate);

    const userPreferences = useUserPreferences({
        listenOnlyTo: {search: {}}
    });
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};

    useLayoutEffect(() => {
        if (paywall && autoUpdate) {
            setPaywall(AuthService.getSubscription() === false ? 'ACTIVE': 'INACTIVE');
            if (AuthService.getSubscription()) {
                const newAutoUpdate = autoUpdate;
                newAutoUpdate.enabled = false;
                setAutoUpdate(newAutoUpdate);
            }
        }
    }, [paywall, autoUpdate]);

    const handleSelection = (type: string, event: GroupMetricEvent) => {
        if (type === "distribution") {
            if (event.source === "legend") {
                let priority = event.groups?.length && event.metrics?.length && event.groups[0] === "priority" && PRIORITY[event.metrics[0].toUpperCase() || ""] ?
                    getFacetValue(PRIORITY[event.metrics[0].toUpperCase() || ""], searchPreferences.srchEngine) : undefined;
                let statusArray = event.groups?.length && event.metrics?.length && event.groups[0] === "status" && INCIDENT_STATUS[event.metrics[0].toUpperCase() || ""] ?
                    [getFacetValue(INCIDENT_STATUS[event.metrics[0].toUpperCase() || ""], searchPreferences.srchEngine)] : getAllFacetStates(searchPreferences.srchEngine);
                const facets = {};
                if (priority) {
                    facets[getPriorityFacetForEngine(searchPreferences.srchEngine)] = [priority];
                }
                if (statusArray?.length) {
                    facets[getStatusFacetForEngine(searchPreferences.srchEngine)] = statusArray;
                }
                if (filters.completionStatus === "ONGOING") {
                    switch (searchPreferences.srchEngine) {
                        case SEARCH_ENGINE.correlation_direct:
                            facets["incidents/isOngoing"] = [true];
                            break;
                        case SEARCH_ENGINE.correlation_dal:
                            facets["COMPLETION_STATUS"] = ["ONGOING"];
                            break;
                    }
                }
                history.push(getURL(getURLPath("incident-search"), {
                    searchType: SEARCH_TYPE.incident,
                    facets
                }, { replaceQueryParams: true }));
            } else {
                let priority = getFacetValue(event.groups.length ? event.groups[0].toUpperCase() : "", searchPreferences.srchEngine);
                let status = getFacetValue(event.metrics.length ? event.metrics[0].toUpperCase() : "", searchPreferences.srchEngine);
                const facets: any = {
                    [getStatusFacetForEngine(searchPreferences.srchEngine)]: [status], 
                    [getPriorityFacetForEngine(searchPreferences.srchEngine)]: [priority]
                };
                if (filters.completionStatus === "ONGOING") {
                    switch (searchPreferences.srchEngine) {
                        case SEARCH_ENGINE.correlation_direct:
                            facets["incidents/isOngoing"] = [true];
                            break;
                        case SEARCH_ENGINE.correlation_dal:
                            facets["COMPLETION_STATUS"] = ["ONGOING"];
                            break;
                    }
                }
                history.push(getURL(getURLPath("incident-search"), {
                    searchType: SEARCH_TYPE.incident,
                    facets
                }, { replaceQueryParams: true }));
            }
        } else {
            if (event.source !== GroupMetricSource.LEGEND) {
                let priority = getFacetValue(event.metrics.length ? event.metrics[0].toUpperCase() : "", searchPreferences.srchEngine);
                const facets: any = {
                    [getStatusFacetForEngine(searchPreferences.srchEngine)]: getAllFacetStates(searchPreferences.srchEngine), 
                    [getPriorityFacetForEngine(searchPreferences.srchEngine)]: [priority]
                };
                switch (type) {
                    case "location":
                        facets[getImpactedLocationFacetForEngine(searchPreferences.srchEngine)] = [event.groups.length ? event.groups[0] : undefined];
                        break;
                    case "application":
                        facets[getImpactedApplicationFacetForEngine(searchPreferences.srchEngine)] = [event.groups.length ? event.groups[0] : undefined];
                        break;
                    }
                if (filters.completionStatus === "ONGOING") {
                    switch (searchPreferences.srchEngine) {
                        case SEARCH_ENGINE.correlation_direct:
                            facets["incidents/isOngoing"] = [true];
                            break;
                        case SEARCH_ENGINE.correlation_dal:
                            facets["COMPLETION_STATUS"] = ["ONGOING"];
                            break;
                    }
                }
                history.push(getURL(getURLPath("incident-search"), {
                    searchType: SEARCH_TYPE.incident,
                    facets
                }, { replaceQueryParams: true }));
            }
        }
    };

    const handleIncidentSelection = (id: string | undefined | null) => {
        history.push(getURL(getURLPath("incident"), {
            f: {
                [FILTER_NAME.incidentId]: id
            }
        }, { replaceQueryParams: true }))
    };

    /** TBD: Showing temporary labels to indicate mock data. To be removed after real data is available */
    //const mockedContentJSX = <div className="opacity-7 display-10 float-right">{STRINGS.showingMockData}</div>;
    //const partiallyMockedContentJSX = <div className="opacity-7 display-10 float-right">{STRINGS.showingPartiallyMockedData}</div>;
    

    return (<>
        <PageWithHeader
            name="NetworkSummaryPage"
            showGlobalFilters={false}
            title={STRINGS.networkDashboard.title}
            icon={IconNames.GLOBE_NETWORK} showTimeBar={false}
            leftAlignedControls={<div className="d-flex align-items-baseline">
                <OngoingIncidentsFilter className="ml-2" userPrefsKey={FILTER_IN_USER_PREFS_KEY.networkHealthOverview}/>
                <PriorityFilter className="ml-2 pt-1 d-inline-flex" userPrefsKey={FILTER_IN_USER_PREFS_KEY.networkHealthOverview}/>
            </div>}
            rightAlignedControls={<AutoUpdateControl autoUpdate={autoUpdate} timeType={TimeType.DURATION}
                showPlayPauseButton={true} onAutoUpdateChanged={(autoUpdate) => {
                    setAutoUpdate(autoUpdate);
                }}
                showRefreshButton={!autoUpdate.enabled}
                className="d-none d-md-block"
            />}
            helpInfo={HELP.networkSummary}
        >
            <AutoUpdateContext.Provider value={autoUpdate}>
                <CardsHolder className="mx-3 mt-3">
                    <SummaryCard size="flex" height={SIZE.l} title={STRINGS.networkDashboard.userImpact} titlePadding={false} isSummary
                        className="w-min-4-force user-impact-list-card" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                        <IncidentUserImpactView onClickEvent={(id) => {handleIncidentSelection(id)}} />
                    </SummaryCard>
                    <SummaryCard size="flex" height={SIZE.l} title={STRINGS.networkDashboard.impactedLocations} titlePadding={false}  isSummary
                        className="w-min-4-force impacted-locations-card" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                        <ImpactedLocationsView onGroupMetricSelection={(event) => {handleSelection("location", event)}}/>
                    </SummaryCard>
                    <SummaryCard size="flex" height={SIZE.l} title={STRINGS.networkDashboard.impactedServices} titlePadding={false}  isSummary
                        className="w-min-4-force impacted-services-card  mr-0" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                        <ImpactedServicesView onGroupMetricSelection={(event) => {handleSelection("application", event)}}/>
                    </SummaryCard>
                </CardsHolder>
                <CardsHolder className="mx-3">
                    <SummaryCard size="flex" height={SIZE.l} title={STRINGS.networkDashboard.incidentDistribution} titlePadding={false} isSummary
                        className="w-min-4-force incident-distribution-card" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                        <IncidentDistributionView onGroupMetricSelection={(event) => handleSelection("distribution", event)}/>
                    </SummaryCard>
                    <SummaryCard size="flex" height={SIZE.l} title={STRINGS.networkDashboard.incidentPriority} titlePadding={false} isSummary 
                        className="w-min-4-force incident-priority-card mr-0" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                        <IncidentPriorityTimeSeriesView 
                            //onGroupMetricSelection={(event) => {handleSelection("application", event)}}
                        />
                    </SummaryCard>
                </CardsHolder>
            </AutoUpdateContext.Provider>
            <PaywallDialog subscriptionStatus={paywall}/>
        </PageWithHeader>
    </>);
};

/** returns the list of facet states that are used when no state is selected in the impact dashboard.  This
 *  is in the format that DAL filters accept.
 *  @returns a String array with the value of all the facet states. */
function getAllDalStatesForImpactDashboard(): INCIDENT_STATUS[] {
    return [INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING/*, INCIDENT_STATUS.ON_HOLD*/];
}

/** returns the list of facet states that are used when no state is selected in the impact dashboard.  This
 *  is in the format that cognitive search filters accept.
 *  @returns a String array with the value of all the facet states. */
function getAllFacetStates(engine: SEARCH_ENGINE | undefined): string[] {
    let states: string[] = getAllDalStatesForImpactDashboard();
    if (engine !== SEARCH_ENGINE.correlation_dal) {
        states = states.map(value => toCamelCase(value));
    }
    return states;
}
 
