import React from "react";
import { setUserPreferences } from "utils/stores/UserPreferencesStore";
import { Hint, HintProps } from "./Hint";
import { GuidedHintsStoreInstance } from "./useGuidedHintsStore";

export interface WrapInGuidedHintProps extends Partial<HintProps> {
    /** The key that matches to the hint in the `hints` array passed to the store  */
    hintKey: string;
    /** Instance of the hint store to use */
    hintStore: GuidedHintsStoreInstance;
}

/** This component should be used to wrap each piece of content that
 * has a guided hint. It takes a `hintKey` to indicate which hint
 * from the list of hints should be displayed for the specific instance.
 * A hints store instance also should be passed in whch will take care
 * of switching between hints as user interacts. Scroll below for an example. */
export function WrapInGuidedHint ({
    hintKey,
    hintStore,
    children,
    showDontShowAgainBtn,
    ...props
}: WrapInGuidedHintProps): JSX.Element {
    if (hintStore.activeHint?.hintKey === hintKey) {
        return <Hint
            hint={hintStore.activeHint?.hint || ""}
            setStringAsMarkup={hintStore.activeHint?.setStringAsMarkup}
            {...props}
            showPrevBtn={hintStore.data.first === false}
            onPrevious={() => {
                hintStore.showPreviousHint();
                if (props.onPrevious) {
                    props.onPrevious();
                }
            }}
            showNextBtn={hintStore.data.last === false}
            onNext={() => {
                hintStore.showNextHint();
                if (props.onNext) {
                    props.onNext();
                }
            }}
            onClose={(dontShowEver) => {
                hintStore.resetHints();
                if (hintStore.data.showHintStoryUserPreferenceKey && dontShowEver) {
                    setUserPreferences({
                        [hintStore.data.showHintStoryUserPreferenceKey]: "false"
                    });
                }
                if (props.onClose) {
                    props.onClose(dontShowEver);
                }
            }}
            showDontShowAgainBtn={showDontShowAgainBtn === undefined ? Boolean(hintStore.data.showHintStoryUserPreferenceKey) : showDontShowAgainBtn}
        >{children}</Hint>
    } else {
        return <>{children}</>;
    }
}

// Example of how to use Guided Hints
// function GuidedHintsExample () {
//     const planetsStory = useGuidedHintsStore({
//         hints: [
//             { hintKey: "mercury", hint: "Phew! It's hot over here!" },
//             { hintKey: "earth", hint: "Ahh.. That's feels just right!" },
//             { hintKey: "mars", hint: "Woah woah back up.. It's getting too cold" },
//             { hintKey: "neptune", hint: "The End! Were you looking for Pluto next? Sorry!" },
//         ],
//         // Open the first hint automatically (We could also activate showing
//         // the hints on demand by executing planetsStory.showNextHint to show the 
//         // first one or planetsStory.showHint("earth") to start at a specific one)
//         initActiveHint: "mercury",
//     });

//     return <div>
//         <h1>Planets! Tadaa!</h1>
//         <ul>
//             <WrapInGuidedHint hintKey="mercury" hintStore={planetsStory}><li>Mercury</li></WrapInGuidedHint>
//             <li>Venus</li>
//             <WrapInGuidedHint hintKey="earth" hintStore={planetsStory}><li>Earth</li></WrapInGuidedHint>
//             <WrapInGuidedHint hintKey="mars" hintStore={planetsStory}><li>Mars</li></WrapInGuidedHint>
//             <li>Jupiter</li>
//             <li>Saturn</li>
//             <li>Uranus</li>
//             <WrapInGuidedHint hintKey="neptune" hintStore={planetsStory}><li>Neptune</li></WrapInGuidedHint>
//         </ul>
//     </div>;
// }
