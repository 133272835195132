// Note: This hook works in conjunction with useQuery hook. Logic to update the active supported filters in a page
// will be triggered when components mount and unmount useQuery hooks. This hook will listen to the store with
// aggregated supported filters information and trigger component re-renders.
import { useEffect, useRef, useState } from 'react';
import { addChangeCallback, getSupportedFilters, removeChangeCallback } from 'utils/stores/GlobalSupportedFiltersStore';
import { isEqual } from "lodash";

function useSupportedFilters () {
    const [supportedFilters, setSupportedFilters] = useState(getSupportedFilters());
    const setSupportedFiltersRef = useRef(setSupportedFilters);
    setSupportedFiltersRef.current = setSupportedFilters;
    useEffect(() => {
        const onActiveSupportedFiltersChanged = () => {
            const newSupportedFilters = getSupportedFilters();
            if (!isEqual(supportedFilters, newSupportedFilters)) {
                setSupportedFiltersRef.current(getSupportedFilters());
            }
        };
        addChangeCallback(onActiveSupportedFiltersChanged);
        return () => removeChangeCallback(onActiveSupportedFiltersChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { supportedFilters }
}
export { useSupportedFilters }