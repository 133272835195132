import React, { useState } from "react";
import { ErrorToaster, SuccessToaster } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { FormModal } from "components/common/modal";
import { InputField } from "components/common/form";
import { HTMLSelect, Label } from "@blueprintjs/core";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import "./GenerateClientSecretsStepOneModal.scss";
import moment from "moment";
import { validityOptionsMapping } from "../constants";

interface CreateClientApplicationMutationInput {
    name: string;
    expiration: string;
}

export default function GenerateClientSecretsStepOneModal(props) {
    const maximumExpirationDays = 2 * 365; // 2 Years
    const DEFAULT_VALIDITY = 3; // months
    const [validity, setValidity] = useState(DEFAULT_VALIDITY);
    const [createClientApplication] = useMutation<
        any,
        CreateClientApplicationMutationInput
    >(loader("./../queries/create-client-application-mutation.graphql"), {
        onCompleted: (data) => {
            SuccessToaster({
                message: STRINGS.apiAccess.toastMessages.successCreateClient,
            });
            props.handleOpenSecondStep(data);
        },
        onError: (err) => {
            ErrorToaster({
                message: STRINGS.apiAccess.toastMessages.errorCreateClient,
            });
            console.error(err?.message);
        },
    });

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .label(STRINGS.apiAccess.generateClientSecretsStepOneModal.name)
            .required(),
        expirationTime: yup.string().required(),
        daysExpirationTime: yup.number().when([], {
            is: () => validity === 0,
            then: yup.number()
                .min(1, STRINGS.apiAccess.validationMessages.minDays)
                .max(maximumExpirationDays, STRINGS.apiAccess.validationMessages.maxDays)
                .required(STRINGS.apiAccess.validationMessages.daysRequired),
        }),
    });

    const formConfig = {
        initialValues: {
            name: "",
            expirationTime: moment().add("3", "months").unix(),
            daysExpirationTime: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            return createClientApplication({
                variables: {
                    name: values.name,
                    expiration:
                        validity !== 0
                            ? moment().add(validity, "months").unix().toString()
                            : moment()
                                  .add(values.daysExpirationTime, "days")
                                  .unix()
                                  .toString(),
                  },
            });
        },
    };

    return (
        <FormModal
            title={STRINGS.apiAccess.modalTitles.stepOne}
            formikProps={formConfig}
            {...props}
        >
            <div className="w-75 d-flex-main">
                <Label className="label-width">
                    {STRINGS.apiAccess.generateClientSecretsStepOneModal.name}
                </Label>
                <InputField
                    className="name-field"
                    placeholder={
                        STRINGS.apiAccess.generateClientSecretsStepOneModal
                            .namePlaceholder
                    }
                    name={"name"}
                    required={true}
                />
            </div>

            <div className="d-flex-main">
                <Label className="label-width mt-1">
                    {STRINGS.apiAccess.validity}
                </Label>
                <div className="d-flex-second">
                    <HTMLSelect
                        fill={true}
                        name={"expirationTime"}
                        options={validityOptionsMapping}
                        onChange={(event) => {
                            setValidity(+event?.currentTarget.value);
                        }}
                    />
                    <InputField
                        className={validity !== 0 ? "hide" : ""}
                        name={"daysExpirationTime"}
                        type="number"
                        required={true}
                    />
                    <p className={validity !== 0 ? "hide mt-1" : "mt-1"}>
                        {STRINGS.apiAccess.unitToCountOn}
                    </p>
                </div>
            </div>
        </FormModal>
    );
}
