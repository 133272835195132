import React from "react";
import { Modal, Icon, IconName } from "@tir-ui/react-components";
import { AWS_TYPES_TO_LABEL_MAP, NETIM_TYPES_TO_LABEL_MAP } from "utils/cloudim/TopologyUtils";
import { PROVIDER_TYPES, TIME_FORMAT } from "components/enums";
import { STRINGS } from "app-strings";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";

import './PropertiesModal.scss';

export interface PropertiesModalProps {
    icon: string | IconName;
    provider: PROVIDER_TYPES;
    data: any;
    onClose: () => void;
}

const PropertiesModal = ({ icon, provider, data, onClose }: PropertiesModalProps) => {
    let type = "";

    if (provider === PROVIDER_TYPES.AWS) {
        type = AWS_TYPES_TO_LABEL_MAP[data.type];
    } else if (provider === PROVIDER_TYPES.ONPREM) {
        type = NETIM_TYPES_TO_LABEL_MAP[data.type];
    }

    const tranverseObject = (obj: any) => {
        const getValue = (value: any) => {
            const acceptableValues = new Set([null, undefined, "number", "boolean", "string"]);

            if (acceptableValues.has(value) || acceptableValues.has(typeof value)) {
                return value + "";
            } else if (typeof value === "object") {
                return tranverseObject(value);
            }
            return "";
        }

        return (
            <ul>
                {Object.entries(obj).map(([key, value]) => (
                    <li key={key}>
                        <span className="key">{key}: </span>
                        <span className="value">{getValue(value)}</span>
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <Modal
            title={type + " " + STRINGS.cloudim.topology.modal.properties}
            usePortal={true}
            hideCancel={true}
            hideSubmit={true}
            onClose={() => onClose()}
        >
            <div className="propertiesModal">
                <div className="header">
                    <div className="profile">
                        <div className={"icon" + (provider ? " " + provider : "")} title={type}>
                            <Icon iconSize={40} icon={icon} />
                        </div>
                        <div className="details">
                            <span>
                                {data.label}
                            </span>
                            <span className="text-secondary display-9 font-weight-500">
                                {type}
                            </span>
                        </div>
                    </div>
                    <div>
                        {data?.timestamp && `${STRINGS.cloudim.topology.modal.lastUpdated} ${formatToLocalTimestamp(new Date(data.timestamp), TIME_FORMAT.DISPLAY_TIME_FORMAT)}`}
                    </div>
                </div>
                <hr />
                <div className="attributes">
                    {data?.attributes && tranverseObject(data.attributes)}
                </div>
            </div>
        </Modal>
    );
};

export default PropertiesModal;