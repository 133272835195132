import { ConditionBlock, ConditionBlockProps, ConditionBlockType, ConditionBlockValue } from "./condition-block/ConditionBlock";
import { ConditionProps } from "./condition/Condition";
import { isConditionBlock } from "./condition/ConditionUtils";
import React, { useRef, useState } from "react";

export interface ConditionTree extends ConditionBlockValue {};

export interface ConditionTreeBuilderProps {
    className?: string;
    readonly?: boolean;
    conditionTree?: ConditionTree;
    defaultConditionBlockProps?: Partial<ConditionBlockProps>;
    defaultConditionProps?: Partial<ConditionProps>;
    getConditionBlockProps?: ConditionBlockProps["getConditionBlockProps"];
    getConditionProps?: ConditionBlockProps["getConditionProps"];
    onChange?: (conditionTree: ConditionTree) => void;
}

export function ConditionTreeBuilder ({
    className,
    readonly = false,
    conditionTree = { blockType: ConditionBlockType.AND, conditions: [] },
    defaultConditionBlockProps = {},
    defaultConditionProps = {},
    onChange,
    getConditionBlockProps,
    getConditionProps,
}: ConditionTreeBuilderProps) {
    const conditionTreeStateRef = useRef(conditionTree || {});
    const conditionTreeState = conditionTreeStateRef.current;
    const [hideBlockType, setHideBlockType] = useState(conditionTreeState?.conditions?.length <= 1 &&
        (!conditionTreeState?.blockType || conditionTreeState?.blockType === ConditionBlockType.AND || conditionTreeState?.blockType === ConditionBlockType.OR));

    return <div className={"condition-tree" + (className ? " " + className : "")}>
        <ConditionBlock
            {...defaultConditionBlockProps}
            defaultConditionBlockProps={defaultConditionBlockProps}
            defaultConditionProps={defaultConditionProps}
            id={conditionTreeState.id}
            readOnly={readonly}
            blockType={conditionTreeState.blockType}
            conditions={conditionTreeState.conditions}
            hideBlockType={hideBlockType}
            onChange={updatedConditionTree => {
                conditionTreeStateRef.current = updatedConditionTree;
                if (onChange) {
                    onChange(updatedConditionTree);
                }
                
                if (hideBlockType) {
                    if (updatedConditionTree.conditions.length > 1 || (updatedConditionTree.conditions.length === 1 && isConditionBlock(updatedConditionTree.conditions[0]))) {
                        setHideBlockType(false);
                    }
                } else {
                    if (updatedConditionTree.conditions.length === 0 && !(
                        updatedConditionTree.blockType === ConditionBlockType.NOT_AND || updatedConditionTree.blockType === ConditionBlockType.NOT_OR
                    )) {
                        setHideBlockType(true);
                    }
                }
            }}
            hideDeNestBlock={conditionTreeState.conditions.length > 1 || (conditionTreeState.conditions.length > 0 && isConditionBlock(conditionTreeState.conditions[0]))}
            onNestBlock={() => { setHideBlockType(false); }}
            onDeNestBlock={() => {
                if (conditionTreeState.conditions.length <= 1) {
                    const updatedConditionTree = {
                        ...conditionTreeState,
                        blockType: (conditionTreeState.blockType === ConditionBlockType.NOT_AND || conditionTreeState.blockType === ConditionBlockType.NOT_OR) ?
                            ConditionBlockType.AND :
                            conditionTreeState.blockType,
                    };
                    conditionTreeStateRef.current = updatedConditionTree;
                    if (onChange) {
                        onChange(updatedConditionTree);
                    }
                    setHideBlockType(true);
                }
            }}
            getConditionBlockProps={getConditionBlockProps}
            getConditionProps={getConditionProps}
        />
    </div>;
}