/** This module contains the functional React component for rendering the data ocean key list.
 *  This control displays the list of keys but does not allow users to alter them right now.
 *  @module
 */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { MultiSelectInput } from "components/common/multiselect/MultiSelectInput";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { GenericKey } from 'utils/runbooks/NodeUtil';
import { GraphDef, NodeDef } from '../types/GraphTypes';
import { Context, RunbookContext, VariableContextByScope } from 'utils/runbooks/RunbookContext.class';
import { isSubflowNodeFromGraphDef, isTriggerNodeFromGraphDef } from 'utils/runbooks/RunbookUtils';
import { Icon } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { VariableContext } from 'utils/runbooks/VariableContext';
import { Column, DataSet } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { createDataset } from 'utils/runbooks/RunbookFakeDataUtils';
import { DataOceanMetric } from './data-ocean/DataOceanMetadata.type';
import { APP_ICONS } from 'components/sdwan/enums';

/** This interface defines the properties passed into the data ocean key React component.*/
export interface RunbookContextSummaryProps {
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties: any;
    /** the node definition, which has the properties from before the current properties were updated. */
    node: NodeDef;
    /** the graph definition, which has the properties from before the current properties were updated. */
    graphDef: GraphDef;
    /** a boolean value, if true expand the context when the component is first rendered, if false make the 
     *  user expand the chevron to see the context. */
    showInitially?: boolean;
    /** a boolean value, if true show the properties.  By default they will be shown. */
    showProperties?: boolean;
    /** a boolean value, if true show the metrics.  By default they will be shown. */
    showMetrics?: boolean;
    /** an optional boolean value, if true show the output example, if false or missing do not. */
    showOutputExample?: boolean;
    /** an optional boolean value, if true show the input example, if false or missing do not. */
    showInputExample?: boolean;
}

/** Renders the component to render the data ocean keys list.
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean keys component.*/
export function RunbookContextSummary(props: RunbookContextSummaryProps): JSX.Element | null {
    const { currentProperties, node, graphDef, showProperties = true, showMetrics = true } = props;

    const [runbookContext, setRunbookContext] = useState<RunbookContext>();
    const { getVariables } = useContext(VariableContext);
    const [showOutput, setShowOutput] = useState<boolean>(Boolean(props.showInitially));
    const [showAdvanced, setShowAdvanced] = useState<boolean>(Boolean(props.showInitially));

    const updatedNode = useRef<NodeDef>(node);
    const updatedGraphDef = useRef<GraphDef>(graphDef);
    const variables: VariableContextByScope = {
        runtime: getVariables(RUNTIME_SCOPE, true),
        incident: getVariables(INCIDENT_SCOPE, true),
        global: getVariables(GLOBAL_SCOPE, true)
    };

    const customProperties = useContext(CustomPropertyContext);

    // This is used to create a unique key for the multi-select component so it gets unmounted
    // and mounted after every change.  Right now the multi-select component does not support
    // updating the list of items and the selections once it is created.
    const updateCount = useRef<number>(0);

    useEffect(() => {
        // @ts-ignore
        if (DataOceanUtils.isDataOceanMetaDataInitialized()) {
            updatedNode.current = JSON.parse(JSON.stringify(node));
            updatedNode.current.properties = [];
            for (const key in currentProperties) {
                updatedNode.current.properties.push({key: key, value: currentProperties[key]});
            }
            updatedGraphDef.current = JSON.parse(JSON.stringify(graphDef));
            for (let index = 0; index < updatedGraphDef.current.nodes.length; index++) {
                if (updatedGraphDef.current.nodes[index].id === updatedNode.current.id) {
                    updatedGraphDef.current.nodes[index] = updatedNode.current;
                    break;
                }
            }
            let context = new RunbookContext(
                updatedNode.current, updatedGraphDef.current, DataOceanUtils.dataOceanMetaData, customProperties
            );
            updateCount.current++;
            setRunbookContext(context);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProperties, node, graphDef]);

    if (!runbookContext) {
        return null;
    }

    let contexts: Context[] = [];
    let triggerMetrics;
    if (isTriggerNodeFromGraphDef(node)) {
        const context: Context | undefined = runbookContext.getTriggerContext();
        if (context) {
            contexts.push(context);
        }
        triggerMetrics = runbookContext.getApplicableKeysMetrics(variables, customProperties)?.triggerMetrics;
    } else if (isSubflowNodeFromGraphDef(node)) {
        const numOutputs = node.wires?.out?.length || 0;
        for (let output = 0; output < numOutputs; output++) {
            const context: Context | undefined = runbookContext.getSubflowNodeContext(updatedNode.current, updatedGraphDef.current, output);
            if (context) {
                contexts.push(context);
            }
        }
    } else {
        const context: Context | undefined = runbookContext.getNodeContext(updatedNode.current, updatedGraphDef.current);
        if (context) {
            contexts.push(context);
        }
    }

    const parentContexts: Context[] = [...(runbookContext.getNodeContexts() || [])];
    if (!isTriggerNodeFromGraphDef(node) && runbookContext.getTriggerContext()) {
        parentContexts.unshift(runbookContext.getTriggerContext()!);
    }

    if (!contexts?.length) {
        return null;
    }

    const contextOutput: JSX.Element[] = [];
    for (let index = 0; index < contexts.length; index++) {
        let expandedKeys: Array<GenericKey> = contexts[index].expandedKeys;
        let metrics: Array<GenericKey> = contexts[index].metrics;
        contextOutput.push(
            <React.Fragment key={"context-" + index}>
            {(showOutput && showProperties && expandedKeys?.length > 0) && <tr>
                <td colSpan={ 2 }>
                    <div>{STRINGS.runbookEditor.nodeEditor.runbookContext.propertiesText}</div>
                    <div data-testid="runbook_context_summary_keys">
                        <MultiSelectInput
                            key={"runbook-context-key-multi-select-update-" + updateCount.current}
                            sortable
                            items={ expandedKeys.map(key => {
                                return { display: key.label, value: key.id }
                            }) }
                            selectedItems={ expandedKeys.map(key => {
                                return { display: key.label, value: key.id }
                            }) }
                            onChange={ updatedValues => {
                                // Nothing to change right now
                            } }
                            placeholder={ "" }
                            disabled={true}
                        />
                    </div>
                </td>
            </tr>}
            {(showOutput && showMetrics && metrics?.length > 0) && <tr>
                <td colSpan={ 2 }>
                    <div>{isTriggerNodeFromGraphDef(node) ? 
                        STRINGS.runbookEditor.nodeEditor.runbookContext.possibleMetricsText : 
                        STRINGS.runbookEditor.nodeEditor.runbookContext.metricsText}
                    </div>
                    <div data-testid="runbook_context_summary_metrics">
                        <MultiSelectInput
                            key={"runbook-context-metric-multi-select-update-" + updateCount.current}
                            sortable
                            items={isTriggerNodeFromGraphDef(node) ? triggerMetrics?.map(key => {
                                return { display: key.label, value: key.id }
                            }) :
                                metrics?.map(key => {
                                    return { display: key.label, value: key.id }
                                })
                            }
                            selectedItems={isTriggerNodeFromGraphDef(node) ? triggerMetrics?.map(key => {
                                return { display: key.label, value: key.id }
                            }) :
                                metrics?.map(key => {
                                    return { display: key.label, value: key.id }
                                })
                            }
                            onChange={ updatedValues => {
                                // Nothing to change right now
                            } }
                            placeholder={ "" }
                            disabled={true}
                        />
                    </div>
                </td>
            </tr>}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <tr><td className="display-8 font-weight-bold pt-2" colSpan={2}>
                <div className="d-inline-block clickable"
                    onClick={() => {
                        setShowOutput(!showOutput);
                    }}
                >
                    <Icon data-testid="show-output-section" className="align-middle mr-2" icon={showOutput ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                    {(showProperties || showMetrics) &&
                        <span>{STRINGS.runbookEditor.nodeEditor.runbookContext.outputSection}</span>
                    }
                </div>
            </td></tr>
            {contextOutput}
            {(props.showOutputExample || props.showInputExample) && <tr>
                <td className="display-8 font-weight-bold pt-2" colSpan={2}>
                    <div className="d-inline-block clickable"
                        onClick={() => {
                            setShowAdvanced(!showAdvanced);
                        }}
                    >
                        <Icon data-testid="show-advanced-section" className="align-middle mr-2" icon={showAdvanced ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                        <span>{STRINGS.runbookEditor.nodeEditor.runbookContext.advancedSection}</span>
                    </div>
                </td>
            </tr>}
            {showAdvanced && <>
                {props.showOutputExample && <tr>
                    <td colSpan={2}>
                        <div>{STRINGS.runbookEditor.nodeEditor.runbookContext.outputDataExampleHeader}</div>
                        <div>
                            <textarea
                                data-testid="node-context-data-example"
                                autoFocus
                                value={getExampleOutputText(contexts)}
                                disabled={true}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-2"
                            />
                        </div>
                    </td>
                </tr>}
                {props.showInputExample && <tr>
                    <td colSpan={2}>
                        <div>{STRINGS.runbookEditor.nodeEditor.runbookContext.inputDataExampleHeader}</div>
                        <div>
                            <textarea
                                data-testid="node-context-data-example"
                                autoFocus
                                value={getExampleOutputText(parentContexts)}
                                disabled={true}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-3"
                            />
                        </div>
                    </td>
                </tr>}
            </>}
        </React.Fragment>
    );
}

/** returns the example output text for the last context in the contexts array.
 *  @param contexts the Array of contexts.
 *  @returns a String with the example output text. */
export function getExampleOutputText(contexts: Context[]): string {
    let outputDataText = "";
    if (contexts.length > 0) {
        const context: Context = contexts[contexts.length - 1];
        let metricSet: Array<GenericKey> = context?.metrics;
        let keySet: Array<GenericKey> = context?.expandedKeys;
        if (keySet?.length || metricSet?.length) {
            let limit = Math.min(context.limit || 2, 5);
            const isTimeSeries = context.isTimeseries === true;
            const isMultiMetric = metricSet?.length > 1;
            const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 5/*getMaxLimitByType(doNode)*/);
            const duration = context.duration || 3600;
            const outputMetrics: Column[] = RunbookContext.sanitizeMetricsForOutput(
                metricSet as DataOceanMetric[], DataOceanUtils.dataOceanMetaData
            ) as Column[];
            const dataset: DataSet = createDataset(
                "" /*datasetId*/, isTimeSeries, duration, numDataPoints, ""/*getFilterInfo(doNode)*/, 
                keySet as Array<Column>, outputMetrics, undefined, 0
            );
            keySet = keySet?.map((key) => {
                const newKey = { ...key };
                delete (newKey as any).synthetic;
                return newKey;
            });
            outputDataText = JSON.stringify({data: dataset.datapoints, info: {keys: keySet, metrics: outputMetrics}}, null, 4);
        }
    }
    return outputDataText;
}
