/** This module defines a React component that displays the incident search result.
 *  @module
 */
import React from "react";
import { SearchItem, SearchResultsItemProps } from "../search-results-item/SearchResultsItem";
import { LinkInfo, LinkType } from "../../search-links-group/LinksGroup";
import { SearchStandardItem } from "../search-standard-item/SearchStandardItem";
import { formatToLocalTimestamp } from 'reporting-infrastructure/utils/formatters';
import { STRINGS } from "app-strings";
import { parseTimeFromDAL } from "utils/hooks";
import { PRIORITY_TO_LABEL_MAP } from "components/enums";

/** Renders the search result for an incident.
 *  @param data the properties passed in.
 *  @param rowIndex the index of the row in the search results.
 *  @returns JSX with the incident search result react component.*/
export function SearchIncidentItem({ data, rowIndex }: SearchResultsItemProps) {
    let additionalInfo: JSX.Element | undefined = undefined;

    let modifiedData: SearchItem = data;
    try {
        modifiedData = JSON.parse(JSON.stringify(data));
        const incident: any = data.incident;
        if (incident) {
            let type = "unknown";
            const entity = incident.indicators?.find((item) => item.isPrimary === true)?.entity;
            switch (entity?.kind) {
                case "network_interface":
                    type = STRINGS.interface;
                    break;
                case "network_device":
                    type = STRINGS.device;
                    break;
                case "application":
                    type = STRINGS.application;
                    break;
                case "application_location":
                    type = STRINGS.application_location;
                    break;
            }
            const descStrings: Array<string> = [];
            const entityName = entity?.network_interface?.name || entity?.network_device?.name || 
                entity?.application_location?.application?.name || entity?.name;
            let location = entity?.network_device?.location?.name || entity?.network_interface?.location?.name || 
                entity?.application_location?.location?.name || incident?.incidentLocation || "";
            if (location) {
                location = (entity?.kind === "application_location" ? " with users " : "") + " located at " + location;
            }
            descStrings.push(`${PRIORITY_TO_LABEL_MAP[(incident.priority || "").toUpperCase()]} ${incident.description}`);
            descStrings.push(`${STRINGS.SEARCH.descIncText1} ${entityName} ${location} ${STRINGS.SEARCH.descIncText2} ${type}`);
            // if (incident.earliestDetection) {
            if (incident.createdAt) {
                const earliestTrigger = incident.createdAt ? formatToLocalTimestamp(parseTimeFromDAL(Date.parse((incident.createdAt)))) : "";
                descStrings.push(`${STRINGS.SEARCH.descIncText3} ${earliestTrigger}`);
            }
            modifiedData.formattedData.description = descStrings.join(". ");

            if (incident.impactedUsers?.length || incident.impactedLocations?.length || incident.impactedApplications?.length) {
                const impacts: Array<string> = [];
                if (incident.impactedUsers?.length) {
                    let impactedUsers = incident.impactedUsers.map((user) => {
                        if (typeof user === "string") {
                            const [ip, name] = user?.split(";") || [];
                            return name ? name : ip;                    
                        } else {
                            return user.name || user.ipaddr || user.ipAddr;
                        }
                    });
                    impactedUsers = impactedUsers.filter((value: string, index: number) => impactedUsers.indexOf(value) === index);
                    impacts.push(`Impacted users: ${impactedUsers.join(", ")}`);
                }
                if (data.incident.impactedApplications?.length) {
                    let applications = data.incident.impactedApplications.map((impactedApplication) => {
                        return typeof impactedApplication === "string" ? impactedApplication : impactedApplication.name;
                    });
                    applications = applications.filter((value: string, index: number) => applications.indexOf(value) === index);
                    impacts.push(`Impacted applications: ${applications.join(", ")}`);
                }
                if (data.incident.impactedLocations?.length) {
                    let locations = data.incident.impactedLocations.map((impactedLocation) => {
                        return typeof impactedLocation === "string" ? impactedLocation : impactedLocation.name;
                    });
                    locations = locations.filter((value: string, index: number) => locations.indexOf(value) === index)
                    impacts.push(`Impacted locations: ${locations.join(", ")}`);
                }
                additionalInfo = <>{impacts.map((item, index) => <div key={"impact-" + index} className={index > 0 ? "mt-2" : ""}>{item}</div>)}</>;
            }
        }
    } catch (error) {
        console.error(error);
    } 

    const links: Array<LinkInfo> = [
        {
            path: "incident",
            label: STRINGS.SEARCH.details,
            type: LinkType.PAGE,
            params: {
                incidentId: data.id
            }
        }
    ];
    return <SearchStandardItem 
        data={modifiedData} links={links} showCharts={false} showHealth={false} additionalInfo={additionalInfo} 
        expandAdditionalInfo={rowIndex < 5} 
    />;
}
