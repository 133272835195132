/** This file defines the PieView React component.  The PieView React component wraps a 
 *  PieChart component and includes the query to get the data.  The PieChart React component renders a 
 *  a basic pie chart with n-groups and one metric.  The pie chart component can also render
 *  a donut chart.
 *  @module */
import React from "react";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { Column, BaseRunbookViewProps, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { PieChart, PieData } from "components/common/pie-chart/PieChart";
import { formatKeys } from "utils/runbooks/RunbookFormatter";
import { formatValue, getComparisonParameters, keysMatch } from "utils/runbooks/RunbookOutputUtils";
import { LegendPosition, PieStyle } from "components/common/chart-base/ChartToolbar";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { GroupMetricEvent } from "components/common/chart-base/ChartBase";

/** an interface that describes the properties that can be passed in to the component.*/
export interface PieViewProps extends BaseRunbookViewProps {
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
}

/** Creates the pie chart view, which is a component that displays one pie chart with analytics data.
 *  @param props an object with the properties passed to the pie chart view.
 *  @returns JSX with the pie chart component.*/
export const PieView = (props: PieViewProps): JSX.Element => {
    let {comparisonSuffix = ""} = getComparisonParameters(props.widget?.options?.comparedTo as string);
    let primaryDataset: DataSet | undefined = undefined;
    let comparisonDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            } else {
                comparisonDataset = dataset;
            }
        }
    }

    let {loading, data, error} = useQuery({
        query: new Query(loader("../table-view/summary-data-query.graphql")),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
		},
        skipGlobalFilters: true
    });

    const showLegend = props.widget?.options?.showLegend !== undefined ? props.widget.options.showLegend as boolean : true;
    const legendPosition = props.widget?.options?.legendPosition ? props.widget?.options?.legendPosition as any : LegendPosition.top;
    const selectedMetric: string | undefined = props.widget?.options?.metric as string | undefined;

    let pieData: Array<PieData> = [];
    let metric: string = "";
    let metricId: string = "";
    let unit: Unit = new Unit();
    if (primaryDataset) {
        data = {};
        data.datapoints = primaryDataset.datapoints;
    }
    if (!loading) {
        if (data && data.datapoints && primaryDataset?.keys && primaryDataset?.metrics && primaryDataset?.metrics?.length > 0) {
            let pieDataColumn: Column | undefined = undefined;
            for (let metricIndex = 0; metricIndex < primaryDataset.metrics.length; metricIndex++) {
                if ((selectedMetric && primaryDataset.metrics[metricIndex].id === selectedMetric) || (!selectedMetric && metricIndex === 0)) {
                    pieDataColumn = primaryDataset.metrics[metricIndex];
                }
            }
            if (pieDataColumn) {
                metric = pieDataColumn.label;
                metricId = pieDataColumn.id;
                unit = Unit.parseUnit(pieDataColumn.unit || "");

                for (const datapoint of data.datapoints) {
                    const label: string = formatKeys(primaryDataset.keys, datapoint.keys) as string;
                    const slice: PieData = {label: label, value: 0, group: datapoint.keys?.group};
                    let tv = formatValue(datapoint.data[pieDataColumn.id], pieDataColumn);
                    if (tv !== null && typeof tv === "number") {
                        slice.value = tv;
                    } else {
                        // We cannot show a null slice on the pie chart, it should not be shown as 0
                        continue;
                    }

                    // If there is comparison data add the comparison data
                    if (datapoint.keys && comparisonDataset?.datapoints) {
                        // There should only be one key so maybe we don't have to check this, let's do it anyway, it can't hurt
                        for (const checkDatapoint of comparisonDataset.datapoints) {
                            if (keysMatch(datapoint, checkDatapoint)) {
                                let compValue = formatValue(checkDatapoint.data[pieDataColumn.id], pieDataColumn);
                                if (compValue !== null && typeof compValue === "number") {
                                    slice.compValue = compValue;
                                }
                                break;
                            }
                        }
                    }                        
                    
                    pieData.push(slice);
                }
            }
        }
    }

    return <DataLoadFacade loading={loading || props.loading} error={error} data={data}>
            <PieChart pieData={pieData} title={metric} metric={metricId} unit={unit}
                settings={{
                    style: (props.widget?.options?.style === "donut" ? PieStyle.donut : PieStyle.pie),
                    showPercentage: (props.widget?.options?.showPercentage !== undefined ? props.widget.options.showPercentage as boolean : false),
                    showValue: (props.widget?.options?.showValue !== undefined ? props.widget.options.showValue as boolean : false),
                    showLegend, legendPosition, showMore: true, showIconsOnHover: true
                }}
                transparent={props?.transparent} height={props.height}
                enableFullScreen={true}  
                comparisonSuffix={comparisonSuffix}
                fullScreenTitle={props.widget?.name} hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
                onGroupMetricSelection={(event: GroupMetricEvent) => {
                    if (props.onGroupsAndMetricsSelected) {
                        props.onGroupsAndMetricsSelected([...(event.groups || [])], [...(event.metrics || [])]);
                    }
                }}
            />
    </DataLoadFacade>;
};
