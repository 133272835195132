/** This module contains the implementation for the activity log view.  The activity log
 *  view displays the list of activity log entries in the UI.
 *  @module
 */
import React, { useEffect } from "react";
import Markdown from "markdown-to-jsx";
import { Popover2 } from "@blueprintjs/popover2";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { FILTER_NAME, useQuery } from "utils/hooks";
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { DataNode } from "components/common/data-node/DataNode";
import { INCIDENT_STATUS } from "components/enums";
import { Variant } from "components/common/graph/types/GraphTypes";
import { translateIncidentStatus, translateRunbookStatus } from "utils/transform/transform";
import { ACTIVITY_TYPE } from "components/enums/NotesActivity";
import { RUNBOOK_STATUS } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { STRINGS } from "app-strings";

import './ActivityLogView.scss'


/** This interface defines the properties passed in to the React component. */
export interface ActivityLogViewProps {
    /** ID of the incident associated with this trigger */
    incidentId: string;
    /** toogle to force refresh the view */
    refreshLog?:boolean;
}

/** Displays a list of activity logs in reverse chronological order.
 *  @param props Refer to ActivityLogViewProps for details on member attributes.
 *  @return the JSX with the React component. */
export function ActivityLogView({ incidentId, refreshLog }: ActivityLogViewProps): JSX.Element {
    const filters = incidentId ? { incidentId: incidentId } : undefined
    const { loading, data, error, run } = useQuery({
        name: "ActivityList",
        query: new Query(loader("./activity-log-query.graphql")),
        lazy: true,
        filters: filters,
        requiredFilters: [FILTER_NAME.incidentId],
        consumedFilters: [FILTER_NAME.incidentId],
    });
    useEffect(()=>{
        run({noCache: true});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentId, refreshLog])
    const activityList = getFormattedData(data?.activityLogs?.page);
    return <DataLoadFacade loading={loading} data={data} error={error} showContentsWhenLoading={true} className={"basic"}>
        <ul className="no-bullets pt-4">
            {activityList}
        </ul>
    </DataLoadFacade>;
}

/** this function filters the activity entries and then formats them and returns the result.
 *  @param activities the list of activities.
 *  @returns the filtered and formatted activity log entries. */
function getFormattedData(activities) {
    if (!activities) {
        return [];
    }
    const filteredActivites = activities.filter((item) => item.type !== ACTIVITY_TYPE.DEBUG);

    return filteredActivites.map((item, index) => {
        if (item) {
            const { title, description, type } = getActivityInfo(item);
            const desc = <> {description}</>;
            const firstNode = index === filteredActivites.length - 1;
            // tidy up datanode for the first log. Remove description block.
            const showDescription = (firstNode && !description) ? false : true;
            return <li key={index}>
                <DataNode title={title} timestamp={item.timestamp} description={desc} showDescription={showDescription} firstNode={firstNode} nodeType={type}/>
            </li>;
        }
        return undefined;
    }).filter((item) => item);
}

/** returns the title and description of the activity log entry.
 *  @param item the activity entry.
 *  @returns an object with the title and description of the activity entry. */
export function getActivityInfo (item): { title: string, description: React.ReactNode, type: Variant } {
    const i18nStrings = STRINGS.activityLog;
    let title = "";
    let description = <></>;
    let type = Variant.INCIDENT;
    const userName =  item.user?.name;
    switch (item.type) {
        case ACTIVITY_TYPE.INCIDENT_CREATED:
            title = i18nStrings.incidentCreated;
            break;
        case ACTIVITY_TYPE.LIFECYCLE_NOTE_ADDED:
            title = i18nStrings.lifecycleNoteAdded;
            if (item.newValue && item.newValue.trim()){
                const popUpContent =<div className="p-2 pop-up-notes"><Markdown>{item.newValue}</Markdown></div>
                description = <>
                    {item.user.name} {i18nStrings.noteAddedDescPrefix}
                    <Popover2 className="tool-tip" content={popUpContent} transitionDuration={100} interactionKind="hover" openOnTargetFocus={false}>
                        <div className="desc-link ml-1">{i18nStrings.noteAddedDescLink}</div>
                    </Popover2>
                </>
            } else {
                description = <>{item.user.name} {i18nStrings.noteAddedDescPrefix} {i18nStrings.noteAddedDescLink}</>
            }
            type = Variant.LIFECYCLE
            break;
        case ACTIVITY_TYPE.INCIDENT_NOTE_ADDED:
            title = i18nStrings.incidentNoteAdded;
            if (item.newValue && item.newValue.trim()){
                const popUpContent =<div className="p-2 pop-up-notes"><Markdown>{item.newValue}</Markdown></div>
                description = <>
                    {item.user.name} {i18nStrings.noteAddedDescPrefix}
                    <Popover2 className="tool-tip" content={popUpContent} transitionDuration={100} interactionKind="hover" openOnTargetFocus={false}>
                        <div className="desc-link ml-1">{i18nStrings.noteAddedDescLink}</div>
                    </Popover2>
                </>
            } else {
                description = <>{item.user.name} {i18nStrings.noteAddedDescPrefix} {i18nStrings.noteAddedDescLink}</>
            }
            break;
        case ACTIVITY_TYPE.INCIDENT_NOTE_UPDATED:
            title = i18nStrings.incidentNoteUpdated;
            break;
        case ACTIVITY_TYPE.INCIDENT_NOTE_DELETED:
            title = i18nStrings.incidentNoteDeleted;
            break;
        case ACTIVITY_TYPE.INCIDENT_PRIORITY_CHANGED:
            title = i18nStrings.incidentPriorityChanged;
            break;
        case ACTIVITY_TYPE.INCIDENT_STATUS_CHANGED:
            title = i18nStrings.incidentStatusChanged;
            let descSeg;
            const stat = translateIncidentStatus(item.newValue);
            switch (stat) {
                case INCIDENT_STATUS.NEW:
                    descSeg = `${STRINGS.incidentStatus.new}`;
                    break;
                case INCIDENT_STATUS.INVESTIGATING:
                    descSeg = `${STRINGS.incidentStatus.investigating}`;
                    break;
                case INCIDENT_STATUS.ON_HOLD:
                    descSeg = `${STRINGS.incidentStatus.onHold}`;
                    break;
                case INCIDENT_STATUS.DISMISSED:
                    descSeg = `${STRINGS.incidentStatus.dismissed}`;
                    break;
                case INCIDENT_STATUS.CLOSED:
                    descSeg = `${STRINGS.incidentStatus.closed}`;
                    break;
            }
            description = <>{i18nStrings.incidentStatusDesc} <span className="font-italic font-weight-600">{descSeg}</span>
                {(userName) &&
                    <>
                        <span className="pl-1">
                            {STRINGS.by}
                        </span>
                        <span className="pl-1">
                            <i>{userName}</i>
                        </span>
                    </>
                }
            </>;
            break;
        case ACTIVITY_TYPE.LIFECYCLE_RUNBOOK_STATUS_CHANGED: 
            title = i18nStrings.lifecycleRunbookStatusChanged;
            let descLifecycle1 = <>{`${i18nStrings.runbookStatusDesc}`}</>;
            let descLifecycle2 = <></>;
            const newValue = translateRunbookStatus(item.newValue)
            switch (newValue) {
                case RUNBOOK_STATUS.NEW:
                    title = i18nStrings.runbookAdded;
                    descLifecycle1 = <>{i18nStrings.runbookDesc}</>
                    break;
                case RUNBOOK_STATUS.IN_PROGRESS:
                    descLifecycle2 = <>{STRINGS.runbookStatus.inProgress}</>;
                    break;
                case RUNBOOK_STATUS.SUCCEEDED:
                    descLifecycle2 = <>{STRINGS.runbookStatus.succeeded}</>;
                    break;
                case RUNBOOK_STATUS.SUCCEEDED_WITH_ERRORS:
                    descLifecycle2 = <>{STRINGS.runbookStatus.partiallySucceeded}</>;
                    break;
                case RUNBOOK_STATUS.DONE:
                    descLifecycle2 = <>{STRINGS.runbookStatus.done}</>;
                    break;
                case RUNBOOK_STATUS.CANCELED:
                    descLifecycle2 = <>{STRINGS.runbookStatus.canceled}</>;
                    break;
                case RUNBOOK_STATUS.FAILED:
                    descLifecycle2 = <>{STRINGS.runbookStatus.failed}</>;
                    break;
                case RUNBOOK_STATUS.UNKNOWN:
                    descLifecycle2 = <>{STRINGS.runbookStatus.unknown}</>;
                    break;
            }
            description = <> {descLifecycle1} <i>{descLifecycle2}</i></>;
            type = Variant.LIFECYCLE;
            break;
        case ACTIVITY_TYPE.RUNBOOK_STATUS_CHANGED:
            title = i18nStrings.runbookStatusChanged;
            let descSeg1 = <>{`${i18nStrings.runbookStatusDesc}`}</>;
            let descSeg2 = <></>;
            const newVal = translateRunbookStatus(item.newValue)
            switch (newVal) {
                case RUNBOOK_STATUS.NEW:
                    title = i18nStrings.runbookAdded;
                    descSeg1 = <>{i18nStrings.runbookDesc}</>
                    break;
                case RUNBOOK_STATUS.IN_PROGRESS:
                    descSeg2 = <>{STRINGS.runbookStatus.inProgress}</>;
                    break;
                case RUNBOOK_STATUS.SUCCEEDED:
                    descSeg2 = <>{STRINGS.runbookStatus.succeeded}</>;
                    break;
                case RUNBOOK_STATUS.SUCCEEDED_WITH_ERRORS:
                    descSeg2 = <>{STRINGS.runbookStatus.partiallySucceeded}</>;
                    break;
                case RUNBOOK_STATUS.DONE:
                    descSeg2 = <>{STRINGS.runbookStatus.done}</>;
                    break;
                case RUNBOOK_STATUS.FAILED:
                    descSeg2 = <>{STRINGS.runbookStatus.failed}</>;
                    break;
                case RUNBOOK_STATUS.UNKNOWN:
                    descSeg2 = <>{STRINGS.runbookStatus.unknown}</>;
                    break;
            }
            description = <> {descSeg1} <i>{descSeg2}</i></>;
            break;
        case ACTIVITY_TYPE.DEBUG:
            break;
    }
    return {
        title,
        description,
        type
    };
}
