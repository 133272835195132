/** This module contains constants and utilities that are used by the user defiend variables
 *  @module
 */
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils';
import { CustomProperty } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { RunbookContext } from './RunbookContext.class';
import { RunbookContextUtils } from './RunbookContextUtils.class';
import { getTypes } from 'utils/stores/GlobalDataSourceTypeStore';
import { DataOceanKey } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type';
import { GenericKey } from './NodeUtil';

export const RUNTIME_SCOPE = 'runtime';
export const SUBFLOW_SCOPE = 'subflow';
export const INCIDENT_SCOPE = 'incident';
export const GLOBAL_SCOPE = 'global';

export enum PrimitiveVariableType {
    INTEGER = "integer",
    STRING = "string",
    FLOAT = "float",
    IPADDR = "ipaddr",
    BOOLEAN = "boolean",
    TIMESTAMP = "timestamp",
    AUTH_PROFILE = "auth_profile",
    ALLUVIO_EDGE = "alluvio_edge",
    JSON = "json",
    CONNECTOR = "connector"
}
export const AUTH_AND_EDGE_KEYS = ["AUTH_PROFILE", "ALLUVIO_EDGE"];

export const CONNECTOR_KEYS = ["CONNECTOR"];

export const JSON_KEYS = ["JSON"];

export const EXCLUDED_INCIDENT_KEYS = ["AUTH_PROFILE", "ALLUVIO_EDGE", "CONNECTOR"];

export enum StructuredVariableType {
    INTERFACE = "network_interface",
    APPLICATION_LOCATION = "application",
    DEVICE = "network_device",
    PROTOCOL = "protocol",
    PROTOPORT = "protoport",
    DSCP = "dscp",
    LOCATION = "location",
    CLIENT_LOCATION = "client_location",
    SERVER_LOCATION = "server_location",
    NETWORK_HOST = "network_host",
    NETWORK_CLIENT = "network_client",
    NETWORK_SERVER = "network_server",
    NETWORK_CLIENT_SERVER = "network_client_server",
    CLIENT_SERVER_LOCATION = "client_server_location",
    NETWORK_CLIENT_SERVER_PROTOPORT = "network_client_server_protoport",
    // Ahmet asked us to exclude user device for now because the RBO does not support it
    //USER_DEVICE = "user_device",
    DATA_SOURCE = "data_source",
    CUSTOM = "custom"
}

export type PrimitiveVariable = {
    name: string,
    type: PrimitiveVariableType,
    isReadOnly: boolean,
    unit?: string,
    defaultValue?: string,
    value?: string
}

export type StructuredVariable = {
    name: string,
    type: StructuredVariableType,
    isTimeseries: boolean,
    isReadOnly: boolean,
    keys: ElementForStructuredVariable[],
    metrics: ElementForStructuredVariable[]
}

export type ElementForStructuredVariable = {
    id: string,
    label: string,
    type: PrimitiveVariableType,
    unit?: string,
    defaultValue?: string
}

export type VariableCollection = {
    primitiveVariables: PrimitiveVariable[];
    structuredVariables: StructuredVariable[];
}

export const RUNBOOK_SCOPE_BUILTIN_VARIABLES: PrimitiveVariable[] = [
    {name: "runtime.builtin.runbook.name", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "runtime.builtin.runbook.last_modified", type: PrimitiveVariableType.TIMESTAMP, isReadOnly: true},
    {name: "runtime.builtin.runbook.last_modified_by", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "runtime.builtin.runbook.created_on", type: PrimitiveVariableType.TIMESTAMP, isReadOnly: true},
    {name: "runtime.builtin.runbook.created_by", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "runtime.builtin.runbook.is_builtin", type: PrimitiveVariableType.BOOLEAN, isReadOnly: true},
    {name: "runtime.builtin.runbook.execution_id", type: PrimitiveVariableType.STRING, isReadOnly: true},
]

export const INCIDENT_SCOPE_BUILTIN_VARIABLES: PrimitiveVariable[] = [
    {name: "incident.builtin.title", type: PrimitiveVariableType.STRING, isReadOnly: false},
    {name: "incident.builtin.creation_time", type: PrimitiveVariableType.TIMESTAMP, isReadOnly: true},
    {name: "incident.builtin.priority", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.status", type: PrimitiveVariableType.STRING, isReadOnly: false},
    {name: "incident.builtin.primary_indicator.kind", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.metric.name", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.metric.value", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.threshold.value", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.baseline.value", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.start", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.end", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.application.name", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.name", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.ipaddr", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.serial_number", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.os_version", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.model", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.type", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.vendor", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.device.is_gateway", type: PrimitiveVariableType.BOOLEAN, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.name", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.ifindex", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.ipaddr", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.inbound_speed", type: PrimitiveVariableType.INTEGER, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.outbound_speed", type: PrimitiveVariableType.INTEGER, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.type", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.ifalias", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.ifdescr", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.interface.ifipaddrs", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.location.name", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.location.type", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.location.geo.city", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.location.geo.state", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.primary_indicator.location.geo.country", type: PrimitiveVariableType.STRING, isReadOnly: true},
    {name: "incident.builtin.url", type: PrimitiveVariableType.STRING, isReadOnly: true},
]

export const RUNTIME_BUILTIN_VARIABLES: PrimitiveVariable[] = [
    // {name: "runtime.builtin.interval_start", type: PrimitiveVariableType.STRING, isReadOnly: true},
    // {name: "runtime.builtin.interval_end", type: PrimitiveVariableType.STRING, isReadOnly: true},
]

export const EMPTY_PRIMITIVE_VARIABLE = { name: '', type: PrimitiveVariableType.INTEGER, isReadOnly: false };
export const EMPTY_KEYMETRIC_VARIABLE = { id: '', label: '', type: PrimitiveVariableType.INTEGER, unit: undefined };

export function getRuntimeBuiltinVariables(triggerType?: string): Array<PrimitiveVariable> {
    switch(triggerType) {
        case "application":
            return [
                ...RUNTIME_BUILTIN_VARIABLES,
                //{name: "runtime.builtin.input.app.<property>", type: PrimitiveVariableType.STRING, isReadOnly: true},
                //{name: "runtime.builtin.input.client_location.<property>", type: PrimitiveVariableType.STRING, isReadOnly: true},
            ];
        case "network_device":
            return [
                ...RUNTIME_BUILTIN_VARIABLES,
                //{name: "runtime.builtin.input.device.<property>", type: PrimitiveVariableType.STRING, isReadOnly: true},
            ];
        case "network_interface":
            return [
                ...RUNTIME_BUILTIN_VARIABLES,
                //{name: "runtime.builtin.input.interface.<property>", type: PrimitiveVariableType.STRING, isReadOnly: true},
            ];
        default:
            return RUNTIME_BUILTIN_VARIABLES;
    }
}

export function getTypeKeys(type: string, customProperties: CustomProperty[]): GenericKey[] {
    const listOfKeys: Record<string, DataOceanKey> = RunbookContextUtils.getAvailableKeysForKeyMap(DataOceanUtils.dataOceanMetaData.keys[type].properties as Record<string, DataOceanKey>, [], getTypes());
    let keys: ElementForStructuredVariable[] = [];
    for (let property in listOfKeys) {
        if (listOfKeys[property].type === 'object') {
            const nestedProperties = listOfKeys[property].properties;
            for (let nestedProperty in nestedProperties) {
                keys.push({
                    id: `${type}.${property}.${nestedProperty}`,
                    label: nestedProperties[nestedProperty].label,
                    type: nestedProperties[nestedProperty].type as PrimitiveVariableType,
                    unit: nestedProperties[nestedProperty].unit ? nestedProperties[nestedProperty].unit : 'none'
                });
            }
        } else {
            keys.push({
                id: `${type}.${property}`,
                label: listOfKeys[property].label,
                type: listOfKeys[property].type as PrimitiveVariableType,
                unit: listOfKeys[property].unit ? listOfKeys[property].unit : 'none'
            });
        }
    }
    RunbookContext.addCustomPropertiesToExpandedKeys(keys, type, customProperties);
    return keys;
}

export function sanitizeIncidentKeys(keys: GenericKey[]): GenericKey[] {
    if (keys.length) {
        const newKeys: GenericKey[] = keys.map(key => {
            const newKey: GenericKey = {id: key.id, label: key.label, type: key.type, unit: key.unit};
            if (key.hidden) {
                newKey.hidden = true;
            }
            return newKey;
        })
        return newKeys;
    }
    return keys;
}

export function getMetricUnitAndType(metric: string) {
    return {
        unit: DataOceanUtils.dataOceanMetaData.metrics[metric].unit,
        type: DataOceanUtils.dataOceanMetaData.metrics[metric].type
    }
}

export const removeTypename = (value: any) => {
    if (value === null || value === undefined) {
        return value;
    } else if (Array.isArray(value)) {
        return value.map(v => removeTypename(v));
    } else if (typeof value === 'object') {
        const newObj = {};
        Object.entries(value).forEach(([key, v]) => {
            if (key !== '__typename') {
                newObj[key] = removeTypename(v);
            }
        });
        return newObj;
    }
    return value;
};
