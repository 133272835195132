/** This module contains a basic connection graph widget that can display a simple
 *  topology.
 *  @module
 */
import React, { useState, useRef } from "react"; 
import ReactFlow, { 
    isNode, Edge, ArrowHeadType, Position, OnLoadParams, Elements, Node, 
    ConnectionLineType, useUpdateNodeInternals, Controls 
} from 'react-flow-renderer';
import dagre from 'dagre'; 
import { DIRECTION, GraphDef } from "../graph/types/GraphTypes";
import { isEqual } from "lodash";
import { IpNode } from "./IpNode";
import './ConnectionGraph.scss';

// The default edge type to use wherever an edge is created
const defaultEdgeType: ConnectionLineType = ConnectionLineType.SmoothStep; // Bezier

// The default edge style to use whenever an edge is created
const defaultEdgeStyle = { stroke: '#aaa', strokeWidth: '2px' };

// This indirectly controls edge length, choose 50 for shorter edges and 100 for longer edges
const defaultRankSep = 100;

// the default arrow type which is one of ArrowHeadType.ArrowClosed, ArrowHeadType.Arrow, undefined
const defaultArrowHeadType = ArrowHeadType.ArrowClosed;

/** The default layout direction, LR for horizontal, TB for vertical.*/
export let defaultDirection = "LR";

/** This interface defines the properties passed into the react-flow graph React component.*/
export interface ConnectionGraphProps {
    /** the GraphDef object with the graph nodes in it.*/
    graphDef: GraphDef;
    /** CSS Style class to apply */
    className?: string;
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
    /** the height of the component in pixels. */
    height?: string;
}

/** Renders the connection graph component.
 *  @param props the properties passed in.  These properties contain some of the meta data necessary to 
 *      draw the graph including the runbooks, and the nodes and edges that should be drawn in the graph.
 *  @returns JSX with the connection graph component.*/
export function ConnectionGraph(props : ConnectionGraphProps): JSX.Element {
    const updateNodeInternals = useUpdateNodeInternals();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();

    const previousGraphDef = useRef<GraphDef | null>(null);
    const passedElements = getElements(props.graphDef);

    const [elements, setElements] = useState<Elements>(passedElements);
    const onLayout = (direction: string, pElements?: Elements) => {
        const isHorizontal = direction === 'LR';
        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));
        dagreGraph.setGraph({ rankdir: direction, nodesep: 50, edgesep: 20, ranksep: defaultRankSep });

        const elems = pElements || elements;

        elems.forEach((el: any) => {
            if (isNode(el)) {
            dagreGraph.setNode(el.id, { width: 150, height: 50 });
            } else {
            dagreGraph.setEdge(el.source, el.target);
            }
        });

        dagre.layout(dagreGraph);

        const layoutedElements = elems.map((el: any) => {
            if (isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = undefined;
                el.targetPosition = isHorizontal ? Position.Left : Position.Top;
                el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
                // we need to pass a slighltiy different position in order to notify react flow about the change
                // @TODO how can we change the position handling so that we dont need this hack?
                el.position = { x: nodeWithPosition.x + Math.random() / 1000, y: nodeWithPosition.y };
            }
            return el;
        });

        setElements((layoutedElements as any));
    };
    const onLoad = (reactFlowInstance: OnLoadParams) => {
        setReactFlowInstance(reactFlowInstance);
        //reactFlowInstance.fitView();
        onLayout(defaultDirection);
    };  

    if (!isEqual(props.graphDef, previousGraphDef.current)) {
        previousGraphDef.current = props.graphDef;
        if (!props.graphDef.silent) {
            onLayout(defaultDirection, passedElements);
        }
        props.graphDef.silent = false;
    }

    function updateElementProps (
        nodeID?: string,
        nodeOverrides?: { style?: Object, data?: Object },
        edgeOverrides?: {
            nodeAsSource?: { style?: Object },
            nodeAsTarget?: { style?: Object },
        }
    ) {
        const { nodeAsSource, nodeAsTarget } = edgeOverrides || {};
        if (nodeID && (nodeOverrides || edgeOverrides)) {
            setElements(oldElements => {
                return oldElements.map((element:any) => {
                    if (nodeOverrides && element.id === nodeID) {
                        if (nodeOverrides.data) {
                            element.data = { ...(element.data || {}), ...nodeOverrides.data };
                        }
                        if (nodeOverrides.style) {
                            element.style = { ...(element.style || {}), ...nodeOverrides.style };
                        }
                    }
                    if (nodeAsSource && element.source === nodeID) {
                        if (nodeAsSource.style) {
                            element.style = { ...(element.style || {}), ...nodeAsSource.style };
                        }
                    }
                    if (nodeAsTarget && element.target === nodeID) {
                        if (nodeAsTarget.style) {
                            element.style = { ...(element.style || {}), ...nodeAsTarget.style };
                        }
                    }
                    return element;
                });
            });
            updateNodeInternals(nodeID);
        }
    }

    function onMouseEnterNode (event, node) {
        updateElementProps(node.id, {}, {
            // Highlighting edges that end in and start from this node
            nodeAsSource: { style: { stroke: "#2c9124" } },
            nodeAsTarget: { style: { stroke: "#2c9124" } },
        });
    }

    function onMouseLeaveNode (event, node) {
        updateElementProps(node.id, {}, {
            nodeAsSource: { style: { stroke: "" } },
            nodeAsTarget: { style: { stroke: "" } },
        });
    }

    return <div className={
        "connection-graph-component" +
        (props.transparent ? "" : " bg-light") + 
        (props.className ? " " + props.className : "")}
        style={{height: props.height ? props.height : "500px"}}
    >
        <ReactFlow
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            elements={elements as Elements<any>}
            connectionLineStyle={defaultEdgeStyle}
            connectionLineType={defaultEdgeType}
            nodeTypes={{input: IpNode, output: IpNode, default: IpNode}}
            onLoad={onLoad}
            onNodeMouseEnter={onMouseEnterNode}
            onNodeMouseLeave={onMouseLeaveNode} 
        >
            <Controls style={{position: "absolute", "bottom": 10}} showZoom={true} showFitView={true} showInteractive={true}/>
        </ReactFlow>
    </div>;
};

/** outputs the react-flow elements based on the GraphDef object.
 *  @param graphDef the GraphDef object with the graph data.
 *  @returns the Elements with the graph nodes and edges.*/
function getElements(graphDef: GraphDef): Elements {
    const elements: Elements = [];

    for (const node of graphDef.nodes) {
        let type = "default";
        switch (node?.wires?.direction) {
            case DIRECTION.IN:
                type = "output";
                break;
            case DIRECTION.OUT:
                type = "input";
                break;
            case DIRECTION.BOTH:
                type = "default";
                break;
        }
        const connectable = (node?.wires?.direction === DIRECTION.NONE || !node?.wires?.direction ? false : true);
        const nodeEl: Node<any> = {
            id: node.id,
            type,
            connectable,
            data: {
                label: node.name,
                type: node.type,
                info: node.info,
                color: node.color,
                wires: node.wires,
                icon: "DESKTOP",
            },
            position: {
                x : 0,
                y: 0
            },
        };
        // Handle the i18n keys for our own runbooks
        if (node.i18nNameKey) {
            nodeEl.data.i18nNameKey = node.i18nNameKey;
        }
        if (node.i18nInfoKey) {
            nodeEl.data.i18nInfoKey = node.i18nInfoKey;
        }

        nodeEl.style = {};
        //nodeEl.style.background = node.color;

        if (node.properties) {
            nodeEl.data.properties = node.properties;
        }
        elements.push(nodeEl);
    }

    let elIndex = 1;
    for (const edge of graphDef.edges) {
        const style = JSON.parse(JSON.stringify(defaultEdgeStyle));
        style.strokeWidth = edge.thickness ? edge.thickness : style.strokeWidth;
        const edgeDefn: Edge<any> = {
            id: "" + elIndex++, source: edge.fromNode, target: edge.toNode, type: defaultEdgeType, 
            style: style, arrowHeadType: defaultArrowHeadType, animated: false
        };
        if (edge.fromPort) {
            edgeDefn.sourceHandle = edge.fromPort;
        }
        if (edge.toPort) {
            edgeDefn.targetHandle = edge.toPort;
        }
        elements.push(edgeDefn);
    }

    return elements;
}
