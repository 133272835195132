/** This module creates a mock version of the AppAuthService which is a wrapper around 
 *      the TirAuthService
 *  @module
 */
import { ITenant } from '@tir-ui/azure-auth/dist/tenants';
import { AppUserAccount, AuthService } from 'utils/services/AppAuthService'

/** this class is an implementation of the auth service. */
export class MockAppAuthService implements AuthService {
    /** a boolean value, true if the user is logged in.  We are simulating the auth service so start with true. */
    private isLoggedIn = true;

    /** logs out the user. */
    logout(): void {
        this.isLoggedIn = false;
        console.debug('you logged out');
    }

    /** returns the tenant object with the tenant information.
     *  @returns the tenant object with the tenant information. */
    getTenant(): ITenant {
        return {
            tenant_id: this.getTenantId(),
            halt: false
        };
    }

    /** returns the auth token.
     *  @returns a promise with the token. */
    async getToken(): Promise<string> {
        return 'faketoken';
    }

    /** returns the tenant id.
     *  @returns a String with the tenant id. */
    getTenantId(): string {
        return 'tennantid';
    }

    /** returns a string with the region.
     *  @returns a String with the region. */
    getRegion(): string {
        return "region";
    }

    /** returns a string with the subscription status.
     *  @returns a String with the subscription status. */
    getSubscription(): boolean {
        return false;
    }

    /** returns the user account.
     *  @returns a reference to the user account object. */
    getUserAccount(): AppUserAccount {
        return {
            getUsername: () => 'tester@riverbed.com',
            getName: () => 'Tester',
            //getRoles: () => ['role.portfolio.admin'],
            getUserId: () => "123456"
        };
    }

    /** returns whether or not the user is authenticated.
     *  @returns a boolean value, true if we are authenticated, false otherwise. */
    isAuthenticated(): boolean {
        return this.isLoggedIn;
    }

    /** returns whether or not the user is an administrator of the whole portfoloio of products.
     *  @returns a boolean value, true if the user is admistrator of the whole portfolio of products. */
    isUserPortfolioAdmin(): boolean {
        return true;
    }

    /** returns whether or not the user is an administrator of the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is admistrator of the specified product. */
    isUserProductAdmin(product: string): boolean {
        return product === 'gelato';
    }

    /** returns whether or not the user has write access to the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is has write access to the specified product. */
    userHasWriteAccess(product: string): boolean {
        return product === 'gelato';
    }

    /** returns true if the user has all of the specified permissions.
     *  @param permissionss the array of permissions.
     *  @returns a boolean value, true if the user has all of the permissions. */
	userHasMultiplePermissions(permissionss: string[]): boolean {
        return true;
    }

    /** returns true if the user has one of the specified permissions.
     *  @param permissionss the array of permissions.
     *  @returns a boolean value, true if the user has one of the permissions. */
	userHasSinglePermission(permissions: string[]): boolean {
        return false;
    }
}
