/** This module contains the component for creating a basic dialog.
 *  @module
 */
import React from "react";
import { Classes, Dialog, Intent } from "@blueprintjs/core";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { ScrollableErrorList } from "components/common/error/ScrollableErrorList";
import './BasicDialog.scss'

/** this interface defines the dialog state object, which includes all parameters necessary to 
 *  render the dialog. */
export interface DialogState {
    /** specifies whether to show the dialog. */
    showDialog: boolean;
    /** specifies whether to show the loading spinner. */
    loading: boolean;
    /** specifies the title for the dialog. */
    title: string;
    /** specifies the dialog content.  This is the body of the dialog. */
    dialogContent: any;
    /** specifies the dialog footer, this should contain any buttons, etc. */
    dialogFooter: any;
    /** an array of strings with any errors that need to be displayed. */
    errors?: Array<string>;
    /** an array of strings with any warnings that need to be displayed. */
    warnings?: Array<string>;
    /** Can this dialog be closed using the standard X or by Escape key or by Clicking on overlay */
    closeable?: boolean;
    /** by default if the user clicks outside the dialog it will dismiss.  If this is set to 
     *  true, the user will not be able to click outside the dialog to dismiss it. */
    doNotAllowOutsideClick?: boolean;
}

/** this interface defines the properties that are passed in to the dialog. */
export interface BasicDialogProps {
    /** the class name to use to isolate an instance of a dialog from all other instances. */
    className?: string;
    /** the class name to use for the portal container */
    portalClassName?: string;
    /** the state that is used to display the dialog. */
    dialogState: DialogState;
    /** a handler for close events. */
    onClose: () => void;
}

/** Renders the basic dialog common component.
 *  @param props the properties passed in.
 *  @returns JSX with the basic dialog react component.*/
export function BasicDialog (props: BasicDialogProps): JSX.Element {
    const dialogState = props.dialogState;

    return (<>
        <Dialog title={dialogState.title} isOpen={dialogState.showDialog} usePortal={true} className={props.className}
            onClose={props.onClose}
            portalClassName={props.portalClassName}
            canOutsideClickClose={dialogState.closeable === false || dialogState.doNotAllowOutsideClick ? false : true}
            canEscapeKeyClose={dialogState.closeable === false ? false : true}
            isCloseButtonShown={dialogState.closeable === false ? false : true}
        >
            <DataLoadFacade loading={dialogState.loading} fullScreen={false} data={true} error={false} 
                showContentsWhenLoading={true} transparent={true} className="basic-dialog-load-facade"
            >
                {dialogState.dialogContent && <div className={Classes.DIALOG_BODY}>
                    {dialogState.dialogContent}
                </div>}
                {dialogState && dialogState.warnings && dialogState.warnings.length > 0 && <div className="basic-dialog-warning">
                    <ScrollableErrorList intent={Intent.WARNING} items={dialogState.warnings}/>
                </div>}
                {dialogState && dialogState.errors && dialogState.errors.length > 0 && <div className="basic-dialog-error">
                    <ScrollableErrorList items={dialogState.errors}/>
                </div>}
                {dialogState.dialogFooter && <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        {dialogState.dialogFooter}
                    </div>
                </div>}
            </DataLoadFacade>
        </Dialog>
    </>);
}
 
/** creates an updated dialog state from the specified parameters.
 *  @param dialogState the current dialog state that is used to create the new dialog state that will be returned.
 *  @param showDialog specifies whether to show the dialog.
 *  @param loading specifies whether to show the loading spinner.
 *  @param errors an array of strings with errors or empty array if no errors.
 *  @param warnings an array of strings with warnings or empty array if no warnings.
 *  @returns a new dialog state object. */
export function updateDialogState(
    dialogState: any, showDialog: boolean, loading: boolean, errors: Array<string>, warnings: Array<string> = []
): DialogState {
    const newDialogState: any = Object.assign({}, dialogState);
    newDialogState.showDialog = showDialog;
    newDialogState.loading = loading;
    newDialogState.errors = errors;
    newDialogState.warnings = warnings;
    return newDialogState;
}
