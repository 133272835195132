import React from "react";
import { RunbookPathTraversal } from "pages/incident-details/views/runbook-path-traversal/RunbookPathTraversal";
import { Button } from "@blueprintjs/core";
import { updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { STRINGS } from "app-strings";

export function openRunbookNodesTraversedDialog(runbookConfig, runbookOutput, setTraversalDialogState) {
    const newDialogState: any = { showDialog: true, title: "Debug Runbook Flow", };
    newDialogState.dialogContent = <RunbookPathTraversal
        runbook={runbookConfig}
        runbookOutput={runbookOutput}
    />;

    newDialogState.dialogFooter = <Button active={true} outlined={true} text={STRINGS.runbookOutput.okBtnText}
        onClick={() => {
            setTraversalDialogState(updateDialogState(newDialogState, false, false, []));
        }}
    />;

    setTraversalDialogState(updateDialogState(newDialogState, true, false, []));
};