/** This module contains the component for rendering the health monitoring section.
 * It consist of cloud status, Edges, metric streaming, indicator generation,
 * incident generation and runbook execution cards.
 *  @module
 */
import React from 'react';
import {
	TabbedSubPages,
	Tab,
} from 'components/common/layout/tabbed-sub-pages/TabbedSubPages';
import { loader } from 'graphql.macro';
import { useQuery } from 'utils/hooks';
import { Query } from 'reporting-infrastructure/types/Query';
import {
	DURATION,
	durationToRoundedTimeRange,
} from 'utils/stores/GlobalTimeStore';
import { DataIngestGraph } from 'pages/health-monitor/views/graphs/DataIngestGraph';
import { AnalyticsGraph } from 'pages/health-monitor/views/graphs/AnalyticsGraph';
import { IncidentGenerationGraph } from 'pages/health-monitor/views/graphs/IncidentGenerationGraph';
import { RunbookSuccessFailuresGraph } from 'pages/health-monitor/views/graphs/RunbookSuccessFailuresGraph';
import { RunbookCompletionTimeGraph } from 'pages/health-monitor/views/graphs/RunbookCompletionTimeGraph';
import { STRINGS } from 'app-strings';

/** Renders the health monitoring tabs view.
 *  @param props the properties passed in.
 *  @returns JSX with the health monitoring tabs component.*/
const HealthMonitorTabsView = (): JSX.Element => {
	const { loading, data, error } = useQuery({
		name: 'HealthMonitor',
		query: new Query(loader('./health-monitor.graphql')),
		// @ts-ignore
		queryVariables: {
			...durationToRoundedTimeRange(DURATION.DAY_1),
		},
	});

	return (
		<React.Fragment>
			<TabbedSubPages className="pt-2">
				<Tab
					id="data-ingest"
					title={STRINGS.HEALTH_MONITORING_PAGE.tabs.dataIngest.title}
				>
					<div className="pt-3">
						<DataIngestGraph
							data={data}
							loading={loading}
							error={error}
						/>
					</div>
				</Tab>
				<Tab
					id="analytics"
					title={STRINGS.HEALTH_MONITORING_PAGE.tabs.analytics.title}
				>
					<div className="pt-3">
						<AnalyticsGraph
							data={data}
							loading={loading}
							error={error}
						/>
					</div>
				</Tab>
				<Tab
					id="incident-generation"
					title={
						STRINGS.HEALTH_MONITORING_PAGE.tabs.incidentGeneration
							.title
					}
				>
					<div className="pt-3">
						<IncidentGenerationGraph
							data={data as any[]}
							loading={loading}
							error={error}
						/>
					</div>
				</Tab>
				<Tab
					id="runbooks"
					title={STRINGS.HEALTH_MONITORING_PAGE.tabs.runbooks.title}
				>
					<div className="pt-3">
						<RunbookSuccessFailuresGraph
							data={data as any[]}
							loading={loading}
							error={error}
						/>
						<RunbookCompletionTimeGraph
							data={data as any[]}
							loading={loading}
							error={error}
						/>
					</div>
				</Tab>
				{/*
					<Tab
						id="alluvio-pipeline"
						title={
							STRINGS.HEALTH_MONITORING_PAGE.tabs.alluvioPipeline
								.title
						}
					></Tab>
				*/}
			</TabbedSubPages>
		</React.Fragment>
	);
};

export { HealthMonitorTabsView };
