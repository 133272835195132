/** This module contains the functional React component for rendering the data ocean duration control.
 *  This control allows users to pick the query duration from a list of durations.
 *  @module
 */
import React, { useState } from "react";
import { HTMLSelect } from "@blueprintjs/core";
import { STRINGS } from "app-strings";

/** This interface defines the properties passed into the data ocean duration toggle React component.*/
export interface DataOceanDurationProps {
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties: any;
    /** a string with the label to show in front of the duration field. */
    label: string;
    /** a unique integer that when it changes forces a re-render of this component. */
    sequence?: number;
    /** the handler for the duration change event. */
    onDurationChanged: () => void;
}

/** Renders the component to render the data ocean duration field.
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean duration component.*/
 export function DataOceanDuration (props: DataOceanDurationProps): JSX.Element {
    const { currentProperties = {}, label = STRINGS.incidents.columns.duration, onDurationChanged } = props;

    const [duration, setDuration] = useState<number>(currentProperties['duration']);
    if (duration !== currentProperties['duration']) {
        // This line is needed because when the summary/time series radio buttons are toggled it updates 
        // currentProperties['duration'] and forces a re-render.  Since the control is controlled by the duration
        // state variable we have to call the setDuration function if the currentProperties['duration'] property, 
        // does not match the controlled state.
        setDuration(currentProperties['duration']);
    }

    return <tr>
            <td colSpan={2}>
            <label className="mb-0 pb-1">{label}</label><br />
            <HTMLSelect
                name="time.start"
                data-testid="data_ocean_duration"
                fill={ true }
                value={ duration }
                onChange={ e => {
                    const newDuration = parseInt(e.currentTarget.value);
                    currentProperties['duration'] = newDuration;
                    setDuration(newDuration);
                    onDurationChanged();
                } }
            >
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration15 } value="900"/>
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration30 } value="1800"/>
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration45 } value="2700"/>
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration60 } value="3600"/>
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration240 } value="14400"/>
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration480 } value="28800"/>
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration720 } value="43200"/>
                <option label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.duration1440 } value="86400"/>
            </HTMLSelect>
        </td>
    </tr>;
}
