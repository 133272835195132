/** This file defines some base types for the charts.
 *  @module */

import { ChartToolbarControls } from "./ChartToolbar";

 
/** an interface that describes the common properties that can be passed in to a chart component.*/
export interface BaseChartProps {
    /** Pass this boolean as true to have a transparent background for the chart */
    transparent?: boolean;
    /** a boolean value if true hide the box shadow, if false, show it.  By default this is assumed to be false. */
    hideShadow?: boolean;
    /** Additional CSS classes to apply to container */
    className?: string;
    /** an optional array of colors to be used for the chart series.  If nothing is
     *  specified, the default chart colors are used. */
    seriesColors?: Array<string>;
    /** a string with the chart width. */
    width?: string;
    /** a string with the chart height. */
    height?: string;
    /** additional highcharts options that should be applied to the chart. */
    options?: Highcharts.Options;
    /** the handler for the click event. */
    onGroupMetricSelection?: (event: GroupMetricEvent) => void;
    /** When passed as true, fullscreen control will appear on top right corner */
    enableFullScreen?: boolean;
    /** the title to show in the dialog for fullscreen mode. */
    fullScreenTitle?: string;
    /** the controls that should be displayed in the toolbar. */
    controls?: Array<ChartToolbarControls>;
}

/** an interface that describes the group metric selection event object.*/
export interface GroupMetricEvent {
    /** a string with the source, legend, label, or series. */
    source: GroupMetricSource;
    /** a boolean value which specifies whether the groups and metrics are selected or de-selected */
    selected: boolean;
    /** an array with with the groups. */
    groups: any[];
    /** a string array with the metric ids. */
    metrics: string[];
}

/** this enum has all the valid sources for the GroupMetricEvent. */
export enum GroupMetricSource {
    /** the event comes from the legend. */
    LEGEND  = "legend",
    /** the event comes from the series. */
    SERIES  =  "series"
}
