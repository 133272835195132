import React, { useEffect, useState, ReactNode } from "react";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { FeatureFlagUnions } from "utils/services/FeatureFlagService";
import { tenantFF } from "utils/stores/FeatureFlagStore";
import "./FeatureFlag.scss";

export const BEM_FF_SPINNER = `FF-Spinner`;
export const BEM_FF_FALLBACK = `FF-Fallback`;
export const BEM_FF_FALLBACK_TEXT = `FF-Fallback-text`;
export const BEM_FF_CONTAINER = `FF-container`;

type FeatureFlagProps = {
    /** name of faeture flag to check */
    flagName: FeatureFlagUnions;
    /** component to show when feature is enabled */
    children: ReactNode;
    /** component to show when feature is disabled */
    fallback?: ReactNode;
};

type SuspenseWrapperProps = {
    loading: boolean;
    fallback: ReactNode;
    children: ReactNode;
};

/** Default "fallback" message */
const FallBack = () => {
    return (
        <section className={BEM_FF_FALLBACK}>
            <p className={BEM_FF_FALLBACK_TEXT}>Feature has not been enabled</p>
        </section>
    );
};

/**
 * Wrapper to show fallback content while loading is true
 */
const SuspenseWrapper = ({
    loading,
    fallback,
    children,
}: SuspenseWrapperProps) => {
    if (loading) {
        return <>{fallback}</>;
    }
    return <>{children}</>;
};

/**
 * Used for feature flags. Centering children is baked in.
 */
export const FeatureFlag = ({
    flagName,
    children,
    fallback = <FallBack />,
}: FeatureFlagProps) => {
    const [isFeatureEnabled, setIsFeatureEnabled] = useState<
        boolean | undefined
    >(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const ffObj = tenantFF();
        setIsFeatureEnabled(ffObj[flagName]);
        setIsLoading(false);
    }, [flagName]);

    return (
        <section className={BEM_FF_CONTAINER}>
            <SuspenseWrapper
                loading={isLoading}
                fallback={
                    <Spinner
                        data-testid={BEM_FF_SPINNER}
                        size={SpinnerSize.STANDARD}
                    />
                }
            >
                {isFeatureEnabled ? children : fallback}
            </SuspenseWrapper>
        </section>
    );
};
