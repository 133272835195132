/** This module defines a React component that displays the runbook search result.
 *  @module
 */
import React, { useState } from "react";
import { SearchResultsItemProps } from "../search-results-item/SearchResultsItem";
import { SearchItemHeader } from "../../search-item-header/SearchItemHeader";
import { LinkInfo, LinksGroup, LinkType } from "../../search-links-group/LinksGroup";
import { SearchItemDesc } from "../../search-item-desc/SearchItemDesc";
import { PARAM_NAME } from "components/enums/QueryParams";
import { getRunbookMetaData } from "utils/runbooks/RunbookUtils";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { STRINGS } from "app-strings";
import { Variant } from "components/common/graph/types/GraphTypes";
import { runRunbookOnDemand } from "pages/runbook-invocations/views/runbook-invocations-list/RunbookInvocationsUtils";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { useUserPreferences } from "utils/hooks";
import { useHistory } from "react-router-dom";

/** Renders the search result for the runbook.
 *  @param data the properties passed in.
 *  @returns JSX with the runbook search result react component.*/
export function SearchRunbookItem({ data }: SearchResultsItemProps) {
    const userPreferences = useUserPreferences({listenOnlyTo: {onDemandRunbookInputs: {runbooks: {}}}});
    const [dialogState, setDialogState] = useState<any>({ showDialog: false, title: "Run runbook on-demand", loading: false, dialogContent: null, dialogFooter: null });
    
    const history = useHistory();

    const links: Array<LinkInfo> = [
        {
            path: "create-runbook",
            label: STRINGS.SEARCH.details,
            type: LinkType.PAGE,
            params: {
                [PARAM_NAME.rbConfigId]: data.id,
                [PARAM_NAME.rbConfigNm]: data?.formattedData?.name,
                [PARAM_NAME.variant]: data.runbook.variant
            }
/*
        },
        {
            path: "on-demand-runbooks",
            label: STRINGS.SEARCH.runOnDemand,
            type: LinkType.PAGE,
            params: {
                [PARAM_NAME.rbConfigId]: data.id,
                [PARAM_NAME.rbConfigNm]: data?.formattedData?.name
            }
*/
        }
    ];

    const {description, trigger, createdTime, lastUpdatedTime} = getRunbookMetaData(data?.runbook);
    const isReady = data?.runbook?.isReady === true;
    const variant = data?.runbook?.variant === Variant.LIFECYCLE ? "lifecycle" : data?.runbook?.variant === Variant.SUBFLOW ? "subflow" : data?.runbook?.variant === Variant.ON_DEMAND ? "on-demand" : "incident";
    const isFactory = data?.runbook?.isFactory || "";
    const createdBy = data?.runbook?.createdByUser || "";
    const lastUpdatedBy = data?.runbook?.lastUpdatedUser || "";
    const aOrAn = isFactory || (!isFactory && data?.runbook?.variant !== Variant.INCIDENT && data?.runbook?.variant !== Variant.ON_DEMAND) ? "a" : "an";
    const fmtDescription = `${description ? description + ".  " : ""}` + 
        `The runbook is ${aOrAn} ${isFactory ? "built-in " : ""}${variant} runbook` +
        `${data?.runbook?.variant !== Variant.SUBFLOW && data?.runbook?.variant !== Variant.ON_DEMAND ? ` triggered by ${trigger}` : ''}` + 
        `${data?.runbook?.variant === Variant.SUBFLOW && data?.runbook?.variant !== Variant.ON_DEMAND ? "" : " that is" + (isReady ? "" : " not") + " ready for automation"}. ` +
        `Created by ${createdBy} on ${createdTime ? formatToLocalTimestamp(createdTime) : "-"} and ` +
        `last updated by ${lastUpdatedBy} at ${lastUpdatedTime ? formatToLocalTimestamp(lastUpdatedTime) : "-"}`;
    return <div className="d-flex flex-column flex-grow-2">
        {data?.runbook?.variant === Variant.ON_DEMAND && data?.runbook?.id && <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} className="runbook-on-demand-inputs-dialog" />}
        <SearchItemHeader type={data.type} title={data.formattedData.title ? data.formattedData.title : ""} />
        <SearchItemDesc>
            {fmtDescription ? fmtDescription : ""}
        </SearchItemDesc>
        <div className="d-flex align-items-center">
            <LinksGroup links={links} />
            {data?.runbook?.variant === Variant.ON_DEMAND && data?.runbook?.id && <Button aria-label="runbook-new-button" icon={IconNames.ADD} minimal
                className="mt-4 ml-3" onClick={(e) => { runRunbookOnDemand(setDialogState, data.runbook.id, userPreferences?.onDemandRunbookInputs, history); }}
                text={STRINGS.runbookInvocations.runRunbook}
            />}
        </div>
    </div>
}
