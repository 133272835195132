/** This module contains the component for editing a navigator widget's columns or other configuration
 *      properties.
 *  @module
 */
import React, { useState, useEffect, useMemo } from "react";
import { clone } from "lodash";
import { InputGroup, Button, Checkbox, Intent } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { APP_ICONS } from "components/sdwan/enums";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { STRINGS } from "app-strings";
import "./WidgetEditor.scss";

/** this interface defines the properties passed into WidgetEditor React component. */
export interface WidgetEditorProps {
    selectedColumns: Array<string>;
    displayColumns: Array<string>;
    columnsChange: (columns: Array<string>) => void;
    bladeClose: () => void;
}

const group_column = {
    group_column: {
        id: "group_column",
        label: "Name & Details",
        type: "string",
        category: "Group column",
    },
};

const WidgetEditor = (props: WidgetEditorProps): JSX.Element => {
    const metrics = useMemo<any>(() => {
        return { ...group_column, ...DataOceanUtils.dataOceanMetaData.metrics };
    }, []);

    const [columns, setColumns] = useState<Array<string>>(props.displayColumns);
    const [selectedColumns, setSelectedColumns] = useState<Array<string>>(
        props.selectedColumns || []
    );
    const [openCategories, setOpenCategories] = useState<Array<string>>([]);
    const [columnFilterValue, setColumnFilterValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const categories = Object.values(metrics)
        .map((metric: any) => {
            return metric.category;
        })
        .filter((value, index, array) => array.indexOf(value) === index);

    useEffect(() => {
        const columns = props.displayColumns;
        if (columnFilterValue) {
            setColumns(
                columns?.filter((columnId) => {
                    const name =
                        columnId === "group_column"
                            ? group_column.group_column.label
                            : metrics[columnId]?.label;
                    return name
                        .toLocaleLowerCase()
                        .includes(columnFilterValue.toLowerCase());
                })
            );
        } else {
            setColumns(columns);
        }
    }, [columnFilterValue, metrics, props.displayColumns]);

    const columnFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={() => {
                setColumnFilterValue("");
            }}
        />
    );

    const handleListsFilterChange = (event: any) => {
        setColumnFilterValue(event.target.value);
    };

    const handleColumnsChange = (event: any) => {
        if (!event.target.checked) {
            const columns = selectedColumns.filter(function (column) {
                return column !== event.target.id;
            });
            setSelectedColumns(columns);
        } else {
            setSelectedColumns((selectedColumns) => [
                ...selectedColumns,
                event.target.id,
            ]);
        }
        props.columnsChange(selectedColumns);
    };

    return (
        <div className="widget-editor p-2 pt-3">
            <span className="pl-1 display-9 font-weight-bold">
                {STRINGS.navigators.widgetEditor.columns}
            </span>
            <div className="widget-editor-list-items font-weight-normal pt-2">
                <InputGroup
                    id={"columnsFilterInput"}
                    leftIcon="search"
                    rightElement={columnFilterClearIcon}
                    onChange={handleListsFilterChange}
                    placeholder={STRINGS.incidents.impactSummaryView.search}
                    value={columnFilterValue}
                    className="mb-3 mt-2"
                />
                {categories.length ? (
                    categories.map((category, index) => {
                        let showCategory = false;
                        Object.keys(metrics).map((metric) => {
                            if (
                                metrics[metric].category === category &&
                                columns.includes(metric)
                            ) {
                                showCategory = true;
                            }
                            return undefined;
                        });
                        if (showCategory) {
                            return (
                                <div
                                    key={index}
                                    className="widget-editor-category mb-1"
                                >
                                    <div
                                        key={"category-" + index}
                                        onClick={() => {
                                            if (
                                                !openCategories.includes(
                                                    category
                                                )
                                            ) {
                                                setOpenCategories(
                                                    (columnCategoryOpen) => [
                                                        ...columnCategoryOpen,
                                                        category,
                                                    ]
                                                );
                                            } else {
                                                let openedCategories =
                                                    clone(openCategories);
                                                openedCategories.splice(
                                                    openedCategories.indexOf(
                                                        category
                                                    ),
                                                    1
                                                );
                                                setOpenCategories(
                                                    openedCategories
                                                );
                                            }
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Icon
                                            icon={
                                                openCategories.includes(
                                                    category
                                                )
                                                    ? APP_ICONS.SECTION_OPEN
                                                    : APP_ICONS.SECTION_CLOSED
                                            }
                                        />
                                        <span className="pl-1 font-weight-bold">
                                            {category}
                                        </span>
                                    </div>

                                    {openCategories.includes(category) &&
                                        Object.keys(metrics).map((metric) => {
                                            if (columns.includes(metric)) {
                                                return (
                                                    metrics[metric].category ===
                                                        category && (
                                                        <div
                                                            key={
                                                                "column-" +
                                                                metric
                                                            }
                                                            className="widget-editor-item display-9 mt-2 ml-3"
                                                        >
                                                            <Checkbox
                                                                type="checkbox"
                                                                id={metric}
                                                                label={
                                                                    category ===
                                                                    group_column
                                                                        .group_column
                                                                        .category
                                                                        ? group_column
                                                                              .group_column
                                                                              .label
                                                                        : DataOceanUtils
                                                                              .dataOceanMetaData
                                                                              .metrics[
                                                                              metric
                                                                          ]
                                                                              ?.label
                                                                }
                                                                checked={selectedColumns.includes(
                                                                    metric
                                                                )}
                                                                onChange={
                                                                    handleColumnsChange
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                );
                                            } else return null;
                                        })}
                                </div>
                            );
                        } else return null;
                    })
                ) : (
                    <div className="widget-editor-item display-9">
                        {STRINGS.navigators.widgetEditor.noColumnsToEdit}
                    </div>
                )}
            </div>
            <div className="mt-2">
                <Button
                    className="mr-2"
                    aria-label="cancel"
                    type="button"
                    onClick={() => {
                        props.bladeClose();
                    }}
                >
                    {STRINGS.navigators.widgetEditor.buttons.close}
                </Button>
                <Button
                    intent={Intent.SUCCESS}
                    aria-label="submit"
                    disabled={false}
                    type="submit"
                    onClick={() => {
                        setLoading(true);
                        props.columnsChange(selectedColumns);
                        setTimeout(() => {
                            setLoading(false);
                        }, 500);
                    }}
                    loading={loading}
                >
                    {STRINGS.navigators.widgetEditor.buttons.apply}
                </Button>
            </div>
        </div>
    );
};

export { WidgetEditor };
