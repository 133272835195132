import React, { useState } from "react";
import { IconNames, Modal } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { Button, Callout, Intent } from "@blueprintjs/core";
import "./GenerateClientSecretsStepTwoModal.scss";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    ClientApp,
    ClientAppSecretData,
} from "pages/api-access/types/ApiAccessTypes";
import { openModal } from "components/common/modal";
import { TIME_FORMAT } from "components/enums/Time";
import { Tooltip2, Classes } from "@blueprintjs/popover2";

export const GenerateClientSecretsStepTwoModal = React.forwardRef(
    (_props: any, ref) => {
        const [clientData, setClientData] = useState<ClientApp>({
            id: "",
            name: "",
            secrets: [],
        });

        const [isOpen, setIsOpen] = useState(false);

        React.useImperativeHandle(ref, () => ({
            setClientData(data: ClientApp) {
                if (data) {
                    setClientData(data);
                }
            },
            handleOpen() {
                setIsOpen(!isOpen);
            },
        }));

        if (!isOpen) {
            return <></>;
        }

        return (
            <Modal
                title={STRINGS.apiAccess.modalTitles.stepTwo}
                customLabels={{
                    submit: STRINGS.apiAccess.modalButtons.submitStepTwo,
                }}
                hideCancel={true}
                buttons={[
                    {
                        label: STRINGS.apiAccess.modalButtons.addAnotherSecret,
                        action: () => handleAddAnotherSecret(),
                    },
                ]}
            >
                <div className="container">
                    <div className="row">
                        <span className="col-sm-3">
                            {STRINGS.apiAccess.labels.clientName}
                        </span>
                        <span className="col font-weight-bold">
                            {clientData.name}
                        </span>
                    </div>
                    <div className="row">
                        <span className="col-sm-3">
                            {STRINGS.apiAccess.labels.clientId}
                        </span>
                        {renderClientId(clientData.id)}
                    </div>
                    <table className="table mt-4">
                        <thead>
                            <tr>
                                <th scope="col">
                                    {
                                        STRINGS.apiAccess.addClientSecretModal
                                            .columns.description
                                    }
                                </th>
                                <th scope="col">
                                    {
                                        STRINGS.apiAccess.addClientSecretModal
                                            .columns.secret
                                    }
                                </th>
                                <th scope="col">
                                    {
                                        STRINGS.apiAccess.addClientSecretModal
                                            .columns.expiration
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientData.secrets.map((secret, index) => (
                                <tr key={secret.secretText || index}>
                                    <td>{secret.description}</td>
                                    <td>
                                        {secret.secretText}
                                        <CopyToClipboard
                                            text={secret.secretText}
                                            className="ml-2"
                                        >
                                            <Button
                                                small
                                                minimal
                                                icon={IconNames.DUPLICATE}
                                                onClick={() => {}}
                                            />
                                        </CopyToClipboard>
                                    </td>
                                    <td>
                                        {moment
                                            .unix(secret.expiration)
                                            .format(
                                                TIME_FORMAT.DISPLAY_TIME_FORMAT
                                            )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Callout intent={Intent.PRIMARY}>
                        <div className="row">
                            <span className="col-md-2 font-weight-bold">
                                {
                                    STRINGS.apiAccess
                                        .generateClientSecretsStepOneModal
                                        .infoMessageTitle
                                }
                            </span>
                            <span className="col-md-10">
                                {
                                    STRINGS.apiAccess
                                        .generateClientSecretsStepOneModal
                                        .infoMessage
                                }
                            </span>
                        </div>
                    </Callout>
                </div>
            </Modal>
        );

        /**
         * Render the client id with the copy to clipboard tooltip
         * 
         * @returns JSX Element
         */
        function renderClientId(id: string) {
            return (
                <span className="col font-weight-bold">
                    {id}
                    <Tooltip2
                        className={Classes.TOOLTIP2_INDICATOR + " border-0"}
                        content={STRINGS.apiAccess.copyToClipboard}
                    >
                        <CopyToClipboard text={id} className="ml-2">
                            <Button
                                small
                                minimal
                                icon={IconNames.DUPLICATE}
                                onClick={() => {}}
                            />
                        </CopyToClipboard>
                    </Tooltip2>
                </span>
            );
        }

        /**
         * Open a new modal for adding another secret
         */
        function handleAddAnotherSecret() {
            openModal("addClientSecretModal", {
                clientId: clientData.id,
                clientName: clientData.name,
                handleClientSecretCreated: (data: ClientAppSecretData) => {
                    if (!data?.createClientApplicationSecret) {
                        return;
                    }

                    setClientData({
                        ...clientData,
                        secrets: [
                            ...clientData.secrets,
                            data.createClientApplicationSecret,
                        ],
                    });
                },
            });
        }
    }
);
