import { useEffect } from 'react';
import { paramsObj, useQueryParams } from 'utils/hooks/useQueryParams';

export interface VarsObjFormat {[x:string]: string}
export interface useViewCollectionParams {
    // The default value to be returned as the 'activeView' when there isn't a value set in the URL
    defaultActiveView?: string
    // Override the key in the URL's query parameter that should be used to store the activeView value.
    viewURLKey?: string
    // A map where the key is the name of variable attributes that will be returned in vars object
    // and the value is the key that will be used to store this value in query params section of URL
    varToQueryParamMap?: { [x:string]: string }
    // If we want some of the variables to default to a value when it's cleared out, it will be provided
    // in this object.
    varDefaults?: VarsObjFormat
}

const DEFAULT_VIEW_URL_KEY = "v";


function useViewCollection ({ viewURLKey = DEFAULT_VIEW_URL_KEY, defaultActiveView, varToQueryParamMap, varDefaults }: useViewCollectionParams = {}) {
    let queryParamDefaults:VarsObjFormat = getInitialParams();
    const { params, setQueryParams, clearQueryParams } = useQueryParams({
        listenOnlyTo: [viewURLKey, ...Object.values(varToQueryParamMap || {})], // Render only when the view key or one of the vars of interest updates
        paramDefaults: queryParamDefaults
    });

    // Build an object with default values if varDefaults was provided.
    // This method will map the values to their appropriate keys by
    // looking them up from varToQueryParamMap
    function getInitialParams () {
        let queryParamDefaults;
        if (varDefaults && varToQueryParamMap) {
            queryParamDefaults = {};
            for (const key in varDefaults) {
                if (varToQueryParamMap[key] !== undefined) {
                    const keyInUrlParam = varToQueryParamMap[key];
                    queryParamDefaults[keyInUrlParam] = varDefaults[key];
                }
            }
        }
        return queryParamDefaults;
    }

    useEffect(() => {
        return () => {
            if (varToQueryParamMap) {
                // Clear the view key and other variables that were mapped using this instance of the hook
                clearQueryParams([viewURLKey, ...Object.values(varToQueryParamMap)]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const activeView = params[viewURLKey] || defaultActiveView;
    // Extract only the variables that we're interested in and return them
    const vars:VarsObjFormat = getCurrentVars();

    function getCurrentVars () {
        let vars:VarsObjFormat = {};
        for (const key in varToQueryParamMap) {
            const mappedURLKey = varToQueryParamMap[key];
            const valueFromQueryParam = params[mappedURLKey];
            if (valueFromQueryParam !== null && valueFromQueryParam !== undefined) {
                vars[key] = String(valueFromQueryParam);
            }
        }
        return vars;
    }

    function updateView (activeView:string|number|null = null, vars:paramsObj|undefined|null = null, options?:{ replaceVars?: boolean, addEntryInHistory?: boolean }) {
        const replaceVars = options?.replaceVars || false;
        const addEntryInHistory = options?.addEntryInHistory || true;
        // By default, passed variables will be merged with existing ones. To clear all known vars and use only the passed values, mergeWithExistingVars flag should be explicitly passed as false.
        let updateData:paramsObj = { [viewURLKey]: activeView };
        if (varToQueryParamMap && vars) {
            for (const key in varToQueryParamMap) {
                // If user input has a value for the variable in question
                if (vars[key] !== undefined) {
                    updateData[varToQueryParamMap[key]] = vars[key];
                } else if (replaceVars) {
                    updateData[varToQueryParamMap[key]] = "";
                }
            }
        }
        setQueryParams(updateData, addEntryInHistory);
    }

    function resetView (addEntryInHistory = true) {
        let resetData:paramsObj = { [viewURLKey]: defaultActiveView || "" };
        if (varToQueryParamMap) {
            for (const key in varToQueryParamMap) {
                resetData[varToQueryParamMap[key]] = varDefaults && varDefaults[key] ? varDefaults[key] : "";
            }
        }
        setQueryParams(resetData, addEntryInHistory);
    }

    return {
        activeView,
        vars,
        updateView,
        resetView
    };
};

export { useViewCollection };

