import React from "react";
import { HealthPercentageBar } from "../health-percentage-bar/HealthPercentagedBar";
import { HealthSummaryLED } from "../health-summary-led/HealthSummaryLED";
import { Mode, StatusLED } from "../status-led/StatusLED";
import { SEVERITY, SIZE } from "components/enums";
import { IconName } from "@tir-ui/react-components";

export type HealthSummaryComboProps = {
    normal?: number | undefined;
    degraded?: number | undefined;
    critical?: number | undefined;
    unknown?: number | undefined;
    icon?: IconName;
    title?: string;
    size?: SIZE;
    className?: string;
    onItemClick?:(e: SEVERITY) => void;
};

export function HealthSummaryCombo(props: HealthSummaryComboProps) {
    function handleOnClick(status: SEVERITY) {
        if (props.onItemClick) {
            props.onItemClick(status);
        }
    }
    return (
        <span className={"d-block text-black " + (props.size === SIZE.s ? "w-min-2" : "w-min-2-5") + (props.className ? " " + props.className : "")}>
            {(props.icon || props.title) && <StatusLED icon={props.icon} label={props.title} className="display-8 font-weight-bold text-expanded-small"/>}
            <div className={props.size === SIZE.s ? "mt-1" : "mt-2"}>
                {HealthPercentageBar({ ...props, ...{ onItemClick: handleOnClick }})}
            </div>
            <div className={props.size === SIZE.s ? "mt-1" : "mt-2"}>
                {HealthSummaryLED({ ...props, ...{ onItemClick: handleOnClick, mode: props.onItemClick ? Mode.button : undefined }})}
            </div>
        </span>
    );
}
