import { IconName, IconNames } from "@tir-ui/react-components";

export const SDWAN_ICONS:{[x:string]:IconName} = {
    SITE:           IconNames.SITE,
    SEARCH:         IconNames.SEARCH,
    LINK:           IconNames.SWAP_HORIZONTAL,
    APP:            IconNames.APPLICATIONS,
    APP_SINGLE:     IconNames.APPLICATION,
    DEVICE:         IconNames.DESKTOP,
    HOST:           IconNames.DESKTOP,
    ALERT:          IconNames.WARNING_SIGN,
    INTERNET:       IconNames.GLOBE_NETWORK,
    MPLS:           IconNames.DATA_LINEAGE,
    LTE:            IconNames.CELL_TOWER,
	INTERFACE:      IconNames.GROUP_OBJECTS,
	SITE_CONFIG:    IconNames.COG,
    RUNBOOK:        IconNames.FLOW_BRANCH,
    RUNBOOK_OUTPUT: IconNames.CONTROL,
    INCIDENT:       IconNames.ISSUE,
    TRIGGER:        IconNames.PULSE,
    AZURE_MONITOR:  IconNames.AZURE_MONITOR,
    LOCATION:       IconNames.LOCATE,
    APPLICATION:    IconNames.APPLICATIONS,
    CUSTOM_PROPERTY:IconNames.TAG,
};

export const APP_ICONS:{[x:string]:IconName} = {
    ADD:            IconNames.PLUS,
    AI:             IconNames.PREDICTIVE_ANALYSIS,
    USER_MENU:      IconNames.USER,
    LIGHT_THEME:    IconNames.FLASH,
    DARK_THEME:     IconNames.MOON,
    CLOSE:          IconNames.CROSS,
    SIDEBAR_CLOSED: IconNames.MENU,
    SIDEBAR_OPEN:   IconNames.CROSS,
    SECTION_CLOSED: IconNames.CHEVRON_RIGHT,
    SECTION_OPEN:   IconNames.CHEVRON_DOWN,
    LOG_OUT:        IconNames.LOG_OUT,
    LINK:           IconNames.LINK,
    SHARE:          IconNames.SHARE,
    VIEW:           IconNames.EYE_OPEN,
	EDIT:           IconNames.EDIT,
	ERROR:          IconNames.HEART_BROKEN,
	INFO:           IconNames.INFO_SIGN,
    FILTER:         IconNames.FILTER,
    SORTABLE:       IconNames.DOUBLE_CARET_VERTICAL,
    SORT_ASC:       IconNames.CARET_UP,
    SORT_DESC:      IconNames.CARET_DOWN,
    RESET:          IconNames.RESET,
    NORMAL_PRIORITY:IconNames.STAR_EMPTY,
    HIGH_PRIORITY:  IconNames.STAR,
    VIEW_IN_MAP:    IconNames.SEND_TO_MAP,
    TIMING:         IconNames.STOPWATCH,
    DETECTED_ON:    IconNames.FEED,
    RERUN:          IconNames.REPEAT,
    DELETE:         IconNames.DELETE,
    DUPLICATE:      IconNames.DUPLICATE,
    CHART:          IconNames.CHART,
    CHART_BAR:      IconNames.TIMELINE_BAR_CHART,
    CHART_PIE:      IconNames.PIE_CHART,
    CHART_TIMESERIES: IconNames.TIMELINE_AREA_CHART,
    CHART_BUBBLE:   IconNames.HEATMAP,
    CHART_CORRELATION: IconNames.SCATTER_PLOT,
    CARDS:          IconNames.REMOVE_ROW_BOTTOM,
    TABLE:          IconNames.TH,
    GAUGE:          IconNames.DASHBOARD,
    CHART_CONNECTION: IconNames.DIAGRAM_TREE,
    FUNCTION:       IconNames.FUNCTION,
    SWITCH:         IconNames.FLOW_BRANCH,
    JOIN:           IconNames.DATA_LINEAGE,
    SUBFLOW:        IconNames.CODE_BLOCK,
    PROCESS_DATA:   IconNames.RANDOM,
    DELAY:          IconNames.STOPWATCH,
    DATABASE:       IconNames.DATABASE,
    START:          IconNames.WALK,
    INJECT:         IconNames.POWER,
    DEBUG:          IconNames.CONSOLE,
    FLAG:           IconNames.FLAG,
    CATCH:          IconNames.HAND,
    TEMPLATE:       IconNames.WIDGET,
    TAG:            IconNames.TAG,
    IP_ADDRESS:     IconNames.IP_ADDRESS,
    DESKTOP:        IconNames.DESKTOP,
    AZURE_MONITOR:  IconNames.AZURE_MONITOR,
    HELP:           IconNames.HELP,
    DIAGNOSIS:      IconNames.DIAGNOSIS,
    LOCK:           IconNames.LOCK,
    DRAG_HANDLE_VERTICAL: IconNames.DRAG_HANDLE_VERTICAL,
    TIMELINE_EVENTS: IconNames.TIMELINE_EVENTS};
