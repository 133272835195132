import { Button } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import React from "react";
import './NumericPagination.scss'
export interface NumericPaginationProps {
    totalPages: number;
    activePageNumber?: number;
    visibleRange?: number;
    onClick: (arg0: number) => void;
}
export function NumericPagination({ totalPages, activePageNumber = 0, visibleRange = 10, onClick }: NumericPaginationProps) {
    const actualVisibleRange = totalPages < visibleRange ? totalPages : visibleRange;
    const items = Array(actualVisibleRange).fill(null);
    if (totalPages <= 1) {
        return <></>;
    }
    const midPoint = Math.floor((visibleRange - 1) / 2)
    let startRange = (activePageNumber - midPoint <= 0) ? 0 : (activePageNumber + midPoint >= totalPages - 1) ? totalPages - visibleRange : (activePageNumber - midPoint);
    startRange = actualVisibleRange < visibleRange ? 0 : startRange;
    function handleClick(e) {
        onClick(parseInt(e.currentTarget.value));
    }
    function next() {
        onClick(activePageNumber + 1)
    }
    function previous() {
        onClick(activePageNumber - 1)
    }
    const links = items.map((item, index) => {
        const buttonId = startRange + index;
        let active = buttonId === activePageNumber;
        return <Button minimal={true} active= {active} key={buttonId} value={buttonId} onClick={handleClick} type="button"> {buttonId + 1}</Button>
    })
    return <div className="d-flex flex-row numeric-pagination">
        {activePageNumber !== 0 &&
            <Button minimal={true} onClick={previous} type="button" > {STRINGS.previous}</Button>
        }
        {links}
        {activePageNumber !== totalPages - 1 &&
            <Button minimal={true} onClick={next} type="button" > {STRINGS.next} </Button>
        }
    </div>
}