/** This module contains the component for rendering the impact summary.  The impact summary
 *  consists of a users card, a locations card, and an applications card.
 *  @module
 */
import React from 'react';
import { loader } from 'graphql.macro';
import { SIZE } from 'components/enums';
import { useQuery, FILTER_NAME } from 'utils/hooks';
import { SDWAN_ICONS } from 'components/sdwan/enums';
import { Query } from 'reporting-infrastructure/types/Query';
import { IconTitle } from 'components/common/icon-title/IconTitle';
import { CardsHolder } from 'components/common/layout/cards-holder/CardsHolder';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { SitesCard } from 'pages/incident-details/views/impact-summary/cards/SitesCard';
import { UsersCard } from 'pages/incident-details/views/impact-summary/cards/UsersCard';
import { ApplicationsCard } from 'pages/incident-details/views/impact-summary/cards/ApplicationsCard';
import { STRINGS } from 'app-strings';
import { Incident } from 'pages/incident-list/Incident.type';
import { ImpactedItem } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';

/** the properties passed into the React component. */
interface IncidentImpactSummaryViewProps {
    /** a string with the incident Id. */
    incidentId?: string;
    /** the expires after timestamp. */
    expiresAfter?: number;
    /** a string with the class to use at the root of this component. */
    className?: string;
    /** a boolean value, if true the title icons should be displayed if false do not show the title icons. */
    showTitleIcons?: boolean;
    /** the handler to use for details requests. */
    onDetails?: (icon: string, title: string, subTitle: string, data: string[]) => void;
    /** a boolean value, if true show the footer, if false do not, the default is true. */
    showFooter?: boolean;
}

/** Renders the impact summary.
 *  @param props the properties passed in.
 *  @returns JSX with the impact summary component.*/
const IncidentImpactSummaryView = (props: IncidentImpactSummaryViewProps): JSX.Element => {
	const { loading, data, error } = useQuery({
		name: 'IncidentImpactSummaryView',
		query: new Query(loader('./incident-impact-summary.graphql')),
		requiredFilters: [FILTER_NAME.incidentId],
		filters: {
			[FILTER_NAME.incidentId]: props.incidentId
				? props.incidentId
				: undefined,
		},
		//consumedFilters: [FILTER_NAME.incidentId],
		timeNotRequired: true,
		expiresAfter: props.expiresAfter ? { timestamp: props.expiresAfter } : undefined,
	});

	const [incident]: Incident[] = data?.incidents?.nodes || [];
	
    // The impacted users are now coming from search and they are packed as ip;name
    let impactedUsers: ImpactedItem[] | string[] = incident?.impactedUsers || [];
    impactedUsers = impactedUsers.map((user) => {
        return user?.name || user?.deviceName || user?.ipAddress || "";
    });

    return (
		<div className={"incident-impact-summary" + (props.className ? " " + props.className : "" )}>
			<IconTitle
				icon={props.showTitleIcons ? SDWAN_ICONS.ALERT : undefined}
				title={STRINGS.incidents.impactSummaryView.title} className="mb-2 font-weight-500"
				size={SIZE.m}
			/>
			<DataLoadFacade loading={loading} error={error} data={data}>
				<CardsHolder className="pb-3 w-max-12">
					<UsersCard
						count={incident?.impactedUsersCount}
						data={impactedUsers as string[]}
                        onDetails={props.onDetails}
                        showFooter={props.showFooter}
					/>
					<SitesCard
						count={incident?.impactedLocationsCount}
						data={incident?.impactedLocations?.map(item => (item.name || ""))}
                        onDetails={props.onDetails}
                        showFooter={props.showFooter}
					/>
					<ApplicationsCard
						count={incident?.impactedApplicationsCount}
						data={incident?.impactedApplications?.map(item => (item.name || ""))}
                        onDetails={props.onDetails}
                        showFooter={props.showFooter}
					/>
				</CardsHolder>
			</DataLoadFacade>
		</div>
	);
};

export { IncidentImpactSummaryView };
