import React from 'react'
import { Modal } from '@tir-ui/react-components'
import { getIndicatorSummary } from 'pages/incident-details/views/primary-indicator/PrimaryIndicatorView'
import { IndicatorDetailedView } from 'components/hyperion/views/indicator-detailed-view/IndicatorDetailedView'
import { isBaselineIndicatorType } from 'components/enums'
import { IndicatorsListView } from 'components/hyperion/views/indicators-list-view/IndicatorsListView'
import { RunbookPathTraversal } from 'pages/incident-details/views/runbook-path-traversal/RunbookPathTraversal'
import { FILTER_NAME, useQuery } from 'utils/hooks'
import { Query } from 'reporting-infrastructure/types/Query'
import { loader } from 'graphql.macro'
import { STRINGS } from 'app-strings'
import './TimelineModal.scss';

export default function TimelineModal(props) {

    const runbooks = useQuery({
        query: new Query(loader("../../../pages/riverbed-advisor/views/runbook-view/runbooks.graphql")),
        requiredFilters: [FILTER_NAME.incidentIds, FILTER_NAME.runbookId],
        filters: {
            [FILTER_NAME.incidentIds]: props.incidentId ? [props.incidentId] : props.runbookId ? [] : undefined,
            [FILTER_NAME.runbookId]: props.runbookId
        },
        skipGlobalFilters: true,
        timeNotRequired: true,
    });

    return (
        <Modal
            title={STRINGS.timelineOutputsView.dialogTitle}
            hideCancel
            onClose={() => props.onClose()}
            onSubmit={() => props.onClose()}
            customLabels={{
                submit: STRINGS.primaryIndicatorView.okBtnText,
            }}>
            <div className='timeline-dialog'>
                {['runbookIncident', 'lifecycleEvent'].includes(props.eventType) &&
                    <RunbookPathTraversal runbook={JSON.parse(props.template)} runbookOutput={runbooks?.data?.runbooks?.nodes[0]} />
                }

                {props.eventType === 'primaryIndicator' && (
                    <>
                        {props.objMetricMetaData &&
                            getIndicatorSummary(props.objMetricMetaData, props.primaryIndicator?.entity, props.incident, props.primaryIndicator)
                        }
                        <IndicatorDetailedView
                            className="flex-grow-1"
                            indicatorMapping={props.indicatorMappings[0]}
                            indicatorTimeRange={props.timeRange}
                            isBaselineType={isBaselineIndicatorType(props.indicatorMappings[0].kind)}
                            annotationIndicator={props.primaryIndicator}
                            annotationIncident={props.incident}
                            incidentTimeRange={props.timeRange}
                            isPrimaryIndicator
                            runbooks={props.runbook}
                        />
                    </>
                )}

                {props.eventType === 'correlatedIndicator' &&
                    <IndicatorsListView
                        indicatorMappings={props.mappedIds.map(indicator => ({
                            entityId: indicator.entity.id,
                            metric: indicator.metric,
                            kind: indicator.kind,
                            sourceId: indicator.entity.source.id
                        }))}
                        indicatorsTimeRange={props.timeRange}
                        primaryEntityId={props.primaryIndicator?.entity?.id}
                        primaryIndicator={props.primaryIndicator}
                        incident={props.incident}
                        runbooks={props.runbook}
                    />
                }
            </div>

        </Modal>
    )
}
