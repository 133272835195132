import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from '@tir-ui/react-components';
import { CONDITION_TREE_ICONS, getDisplayValueForItem, getValueForItem, selectComplexItem, selectItem } from 'components/common/condition-tree-builder/condition/ConditionUtils';
import React, { useMemo, useState } from 'react';

interface metricItemWithAggregators extends selectComplexItem {
    aggregators?: selectItem[];
}

export interface MetricWithAggregatorWrapperProps {
    /** Default chosen metric when rendering the field */
    metric?: string;
    /** Default chosen aggregator when rendering the field */
    aggregator?: string;
    /** List of metric options. Each option will have a set of applicable aggergators
     * under it. If there are no aggregators, then the aggregator field will not be
     * rendered for that metric */
    metrics?: metricItemWithAggregators[];
    /** Callback to be called when either the value or unit is changed */
    onChange?: (props: { metric?: string, aggregator?: string }) => void;
}

/** This component is used to render a metric picker and an aggregation
 * option picker if it applies for that specific metric
 */
export function MetricWithAggregatorWrapper ({
    metric,
    aggregator,
    metrics,
    onChange,
}: MetricWithAggregatorWrapperProps) {
    const [valueState, setValueState] = useState({
        metric,
        aggregator,
    });
    const matchingMetricOption = useMemo(() => {
        return metrics?.find(m => {
            return getValueForItem(m) === valueState.metric;
        })
    }, [valueState, metrics]);

    const matchingAggregatorOption = useMemo(() => {
        return matchingMetricOption?.aggregators?.find(a => {
            return getValueForItem(a) === valueState.aggregator;
        }) || (matchingMetricOption?.aggregators?.length ? matchingMetricOption?.aggregators[0] : undefined);
    }, [matchingMetricOption, valueState.aggregator]);

    function fireOnChange (metric?: string, aggregator?: string) {
        if (onChange) {
            onChange({ metric, aggregator });
        }
    }
    return <div
        className="metric-and-aggregator-wrapper"
        onBlur={e => {
            if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget as Node)) {
                e.stopPropagation();
            }
        }}
    >
        {
            Boolean(matchingMetricOption?.aggregators?.length) && <Popover2
                lazy
                autoFocus={false}
                enforceFocus={false}
                content={<Menu className="h-max-4 overflow-auto">{
                    matchingMetricOption?.aggregators?.map(item => {
                        const aggregator = getValueForItem(item);
                        return <MenuItem
                            key={"ag-" + aggregator}
                            text={getDisplayValueForItem(item)}
                            active={matchingAggregatorOption === item}
                            onClick={() => {
                                setValueState({
                                    metric: valueState.metric,
                                    aggregator: aggregator,
                                });
                                fireOnChange(valueState.metric, aggregator);
                            }}
                        />;
                    })
                }</Menu>}
            >
                <span className="display-holder mr-1">
                    {matchingAggregatorOption ? getDisplayValueForItem(matchingAggregatorOption) : ""}
                    <Icon icon={CONDITION_TREE_ICONS.DROPDOWN} iconSize={10} className="dropdown-icon"/>
                </span>
            </Popover2>
        }
        <Popover2
            lazy
            autoFocus={false}
            enforceFocus={false}
            defaultIsOpen={!valueState.metric}
            content={<Menu className="h-max-4 overflow-auto">{
                metrics?.map(item => {
                    const metric = getValueForItem(item);
                    return <MenuItem
                        key={"m-" + metric}
                        text={getDisplayValueForItem(item)}
                        active={matchingMetricOption === item}
                        onClick={() => {
                            const matchingAggregatorOption = item.aggregators?.find(a => {
                                return getValueForItem(a) === valueState.aggregator;
                            }) || (item.aggregators?.length ? item.aggregators[0] : undefined);
                            const aggregator = matchingAggregatorOption ? getValueForItem(matchingAggregatorOption) : undefined;
                            setValueState({
                                metric: metric,
                                aggregator: aggregator,
                            });
                            fireOnChange(metric, aggregator);
                        }}
                    />;
                })
            }</Menu>}
        >
            <span className="display-holder">
                {valueState.metric && matchingMetricOption && getDisplayValueForItem(matchingMetricOption)}
                <Icon icon={CONDITION_TREE_ICONS.DROPDOWN} iconSize={10} className="dropdown-icon"/>
            </span>
        </Popover2>
        
    </div>
}