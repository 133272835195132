/** This module contains the implementation for the runbook output tab.  The runbook output
 *  tab displays the contents of the runbook in a tab in the trigger table.
 *  @module
 */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { FILTERS_OBJECT, parseTimeFromDAL } from "utils/hooks";
import { STRINGS } from "app-strings";
import { RunbookTemplateView } from "components/hyperion/views/runbooks/runbook-template-view/RunbookTemplateView";
import { RunbookView } from "pages/riverbed-advisor/views/runbook-view/RunbookView";
import { APP_ICONS, SDWAN_ICONS } from "components/sdwan/enums";
import { PRIORITY, PRIORITY_COLORS, SIZE, TIME_FORMAT } from "components/enums";
import { ViewCollection } from "components/common/layout/view-collection/ViewCollection";
import { OneColumnContainer } from "components/common/layout/containers/one-column-container/OneColumnContainer";
import { useViewCollection } from "components/common/layout/view-collection/useViewCollection";
import { Button, ButtonGroup, Menu, MenuItem, Position, Intent } from "@blueprintjs/core";
import { IconTitle } from "components/common/icon-title/IconTitle";
import { ErrorToaster, Icon, IconNames, SuccessToaster } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { RunbookOutput, RUNBOOK_STATUS, RUNBOOK_STATUS_PROPS } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME, useQueryParams } from "utils/hooks";
import { runbookService } from 'utils/runbooks/RunbookUtils';
import { RunbookConfig } from "utils/services/RunbookApiService";
import { PARAM_NAME, INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { PriorityLEDFormatter } from "reporting-infrastructure/utils/formatters/priority-led-formatter/PriorityLEDFormatter";
import { ErrorDialogContent } from "./ErrorDialogContent";
import { Entity, getEntityDescriptionForRunbook } from "utils/runbooks/EntityUtils";
import { DebugDialogContent } from "./DebugDialogContent";
import RunbookTimeControl from "pages/view-runbook/views/view-runbook/RunbookTimeControl";
import { useMutation } from "@apollo/client";
import { Incident } from "pages/incident-list/Incident.type";
import { Variant } from "components/common/graph/types/GraphTypes";
import { getURL } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import './RunbookOutputsTabView.scss';

// If this is true filter out all but the completed runbooks, Damien only wants to show successful runbook in the list
const showOnlyCompletedRunbooksInList = true;

/** This interface defines the inputs that are passed in to the re-run runbook mutation query. */
interface RerunRunbookMutationInput {
    /** a String with the incident id. */
    incidentId: string,
    /** an Object with the end time for the runbook run. */
    time: object
}

/** an interface that describes the properties that can be passed in to the component.*/
export interface runbookOutputsTabViewProps {
    /** An optional filters object that can be passed through to 
     * the trigger list graphql query if needed.*/
    filters?: FILTERS_OBJECT;
    /** ID of the incident associated with this trigger */
    incidentId: string;
    /** ID of active selected runbook ID */
    runbookId?: string;
    /** if true allow the user to re-run the runbook, if false do not allow a re-run. */
    enableRerunRunbook?: boolean;
    /** pass the runbok to parent component */
    setRunbook?: Function;
}

/** Creates the runbook outputs tab, which is a component that displays the runbook output.
 *  @param props an object with the properties passed to the runbook outputs view.
 *  @returns JSX with the runbook outputs component.*/
export function RunbookOutputsTabView({ filters, runbookId, incidentId, enableRerunRunbook, setRunbook }: runbookOutputsTabViewProps): JSX.Element {
    const { params, setQueryParams, clearQueryParam } = useQueryParams({
        listenOnlyTo: [PARAM_NAME.runbookId, PARAM_NAME.pageLocationId]
    });

    // We are using a separate query to fetch triggers for the active incident right now.
    // In the future, triggers list will come from incident query itself.
    const primaryIndicatorQuery = useQuery({
        name: "PrimaryIndicatorForRunbookOUtput",
        query: new Query(loader("../primary-indicator/primary-indicator-query.graphql")),
        //consumedFilters: [FILTER_NAME.incidentId],
        requiredFilters: [FILTER_NAME.incidentId],
        lazy: true,
        timeNotRequired: true,
    });

    const [rerunRunbookMutation] = useMutation< any, RerunRunbookMutationInput > (
        loader("./rerun-runbook-mutation.graphql"), {
            onCompleted: (data) => {
                SuccessToaster({
                    message: STRINGS.rerunRunbook.successMsgTriggerRerun
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: STRINGS.rerunRunbook.errorMshTriggerRerun
                });
                console.error( err?.message);
            }
        }
    );

    const [rerunEndTime, setRerunEndTime] = useState(0);

    useEffect(() => {
        if (rerunEndTime !== 0) {
            rerunRunbookMutation({
                variables: {
                    incidentId: incidentId,
                    time: { endTime: rerunEndTime.toString()}
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerunEndTime]);

    useEffect(() => {
        if (incidentId) {
            primaryIndicatorQuery.run({
                filters: {
                    [FILTER_NAME.incidentId]: incidentId
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentId]);

    let entity: Entity | undefined = undefined;
    if (primaryIndicatorQuery?.data?.incidents?.nodes?.length) {
        const incident: Incident = primaryIndicatorQuery?.data?.incidents?.nodes[0];
        if (incident.indicatorMappings?.length) {
            for (const mapping of incident.indicatorMappings) {
                if (mapping.isPrimary) {
                    entity = mapping.entity;
                    break;
                }
            }

        }
    }

    let { loading, data, run } = useQuery({
        query: new Query(loader("./runbook-list.graphql")),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.variant],
        filters: {
            [FILTER_NAME.incidentId]: (incidentId ? incidentId : undefined),
            [FILTER_NAME.variant]: Variant.INCIDENT.toUpperCase()
        },
        skipGlobalFilters: true,
        timeNotRequired: true,
        lazy: true,
    });
    const [selectedRunbook, setSelectedRunbook] = useState<RunbookOutput>();
    const count = useRef<number>(0);
    const timeoutId = useRef<number>(0);
    const queryKeys = useRef<{ incidentId: string }>({ incidentId: "" });
    useEffect(() => {
        if (incidentId !== queryKeys.current.incidentId) {
            run({
                filters: {
                    [FILTER_NAME.incidentId]: incidentId,
                    [FILTER_NAME.variant]: Variant.INCIDENT.toUpperCase()
                },
                // Always fetch runbook data fresh because it could've changed since the last pull
                fetchPolicy: "network-only"
            });
        } else if (selectedRunbook && selectedRunbook.status === RUNBOOK_STATUS.IN_PROGRESS) {
            timeoutId.current = setTimeout(() => {
                timeoutId.current = 0;
                run({
                    filters: {
                        [FILTER_NAME.incidentId]: incidentId,
                        [FILTER_NAME.variant]: Variant.INCIDENT.toUpperCase()
                    },
                    // Always fetch runbook data fresh because it could've changed since the last pull
                    fetchPolicy: "network-only"
                });
                count.current++;
            }, 1 * 60 * 1000) as any;
        }
        // Update the current runbook based on the query param.
        const newRunbookId = params[PARAM_NAME.runbookId];
        if (newRunbookId !== selectedRunbook?.id) {
            const newRunBook = data?.runbooks?.nodes?.find((rb) => rb.id === newRunbookId);
            setSelectedRunbook(newRunBook);
        }
        queryKeys.current = { incidentId: incidentId };
        return () => {
            clearInProgressTimeout(timeoutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentId, params[PARAM_NAME.runbookId], count.current]);

    const history = useHistory();
    const [runbookConfigs, setRunbookConfigs] = useState<Array<RunbookConfig>>([]);
    useEffect(
        () => {
            async function fetchMyAPI() {
                let configs: Array<RunbookConfig> = [];
                try {
                    configs = await runbookService.getRunbooks(Variant.INCIDENT, false) as Array<RunbookConfig>;
                } catch (error) {
                    console.log(error);
                }
                setRunbookConfigs(configs);
            }
            fetchMyAPI();
            return () => {
                clearQueryParam(PARAM_NAME.runbookId);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    let lastRunbookInfo: { status: string, timestamp: string, id: string, errors?: any[] } | undefined = undefined;
    const runbookMenuItems: Array<JSX.Element> = [];
    if (!loading) {
        if (data && data?.runbooks?.nodes?.length > 0) {
            for (let runbookIndex = data.runbooks.nodes.length - 1; runbookIndex >= 0; runbookIndex--) {
                const runbook = JSON.parse(JSON.stringify(data.runbooks.nodes[runbookIndex]));

                if (!lastRunbookInfo) {
                    lastRunbookInfo = { status: runbook.status, timestamp: runbook.timestamp, id: runbook.id };
                } else if (parseFloat(runbook.timestamp) > parseFloat(lastRunbookInfo.timestamp)) {
                    lastRunbookInfo = { status: runbook.status, timestamp: runbook.timestamp, id: runbook.id };
                }
                if (runbook.errors) {
                    lastRunbookInfo.errors = runbook.errors;
                }

                if (!runbook.id || runbookId === "") {
                    // Do not display runbooks with no id
                    continue;
                }

                if (showOnlyCompletedRunbooksInList) {
                    if (!runbook.status || (runbook.status !== RUNBOOK_STATUS.DONE && runbook.status !== RUNBOOK_STATUS.SUCCEEDED && runbook.status !== RUNBOOK_STATUS.SUCCEEDED_WITH_ERRORS)) {
                        // Do not display runbooks with status not equal to succeeded
                        continue;
                    }
                }

                if (
                    (!params[PARAM_NAME.runbookId] && runbookMenuItems.length === 0) ||
                    (
                        params[PARAM_NAME.runbookId] === runbook.id &&
                        selectedRunbook?.status !== runbook.status
                    )
                ) {
                    setSelectedRunbook(runbook)
                    // Pass second parameter as true to add a new entry to history only when it was manually changed by the user.
                    // When auto-selecting the first runbook, we will not have a runbook ID query parameter.
                    setQueryParams({ [PARAM_NAME.runbookId]: runbook.id }, Boolean(params[PARAM_NAME.runbookId]));
                    // If query param had a runboook ID in it and the selected runbook reference we have is not pointing to the active runbook
                    // referenced by the query parameter, then set it. This condition can happen when user clicks the browser's back or forward button
                    // causing the query param to have changed while the selectedRunbook ref is still pointing to the outdated runbook object.
                } else if (params[PARAM_NAME.runbookId] === runbook.id && selectedRunbook?.id !== runbook.id) {
                    setSelectedRunbook(runbook)
                }

                runbook.name = STRINGS.runbooks.unknownRunbookName;
                const time = parseTimeFromDAL(runbook.timestamp || "0");
                if (runbook.template) {
                    const template = JSON.parse(runbook.template);
                    runbook.name = template.name || STRINGS.runbooks.unknownRunbookName;
                }
                runbookMenuItems.push(
                    <MenuItem text={<>
                        <PriorityLEDFormatter priority={runbook.priority} showStatusAsBackground />
                        <span className="ml-2">{runbook.name + " - " + formatToLocalTimestamp(time, TIME_FORMAT.DISPLAY_TIME_FORMAT)}</span>
                    </>}
                        active={false} key={runbook.id} onClick={() => {
                            setSelectedRunbook(runbook)
                            setQueryParams({ [PARAM_NAME.runbookId]: runbook.id }, true);
                        }}
                    />
                );
            }
        }

        if (runbookMenuItems.length === 0) {
            // We have an empty list
            runbookMenuItems.push(
                <MenuItem text={STRINGS.runbooks.noRunbooks} active={false} disabled={true} key="no-runbooks" />
            );
        }
    }

    const [runbookTemplate, setRunbookTemplate] = useState<any>(undefined);
    const onRunbookTemplateAvailable = template => {
        if (template?.id && runbookTemplate?.id !== template?.id) {
            setRunbookTemplate(template);
        }
    };
    const { activeView, updateView, resetView } = useViewCollection({
        viewURLKey: "rboutview"
    });

    const [runbookOutput, setRunbookOutput] = useState<RunbookOutput | undefined>(undefined);
    const onRunbookOutputAvailable = runbook => {
        if (runbook) {
            setRunbookOutput(runbook);
            setRunbook && setRunbook(runbook);
        }
    };

    const [dialogState, setDialogState] = useState<any>(
        { showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null }
    );

    return <>
        <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} className="runbook-outputs-dialog" />
        <ViewCollection activeView={runbookTemplate ? activeView : "runbook"}>
            <div key="runbook" className="runbook-output-tab">
                {false && createDemoHeader(runbookTemplate, updateView)}
                {false && createPlHeader(runbookMenuItems, selectedRunbook, updateView)}
                {INCIDENT_DETAILS_STYLE === "table" && createOneLineHeader(runbookMenuItems, selectedRunbook, runbookConfigs, runbookOutput, lastRunbookInfo, incidentId, enableRerunRunbook === true, runbookTemplate !== undefined, history, updateView, setRerunEndTime, setDialogState)}
                {INCIDENT_DETAILS_STYLE !== "table" && createHeaderWithMoreButton(runbookMenuItems, selectedRunbook, runbookConfigs, runbookOutput, entity, lastRunbookInfo, incidentId, enableRerunRunbook === true, runbookTemplate !== undefined, history, updateView, setRerunEndTime, setDialogState)}
                {selectedRunbook && (selectedRunbook.status === RUNBOOK_STATUS.SUCCEEDED || selectedRunbook.status === RUNBOOK_STATUS.SUCCEEDED_WITH_ERRORS || selectedRunbook.status === RUNBOOK_STATUS.DONE) &&
                    <RunbookView
                        incidentId={incidentId}
                        runbookId={selectedRunbook?.id}
                        onRunbookTemplateAvailable={onRunbookTemplateAvailable}
                        onRunbookReceived={onRunbookOutputAvailable}
                        className={"pb-4 runbook-output-tab-runbook-bg" + (INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" ? "" : " px-4")}
                    />}
                {(loading || (selectedRunbook && selectedRunbook.status === RUNBOOK_STATUS.IN_PROGRESS)) && <div className="p-4 runbook-output-tab-runbook-bg">
                    <DataLoadFacade key="no-runbook-message" loading={true} loadingText={loading ? undefined : STRINGS.incidents.runbookOutputs.inProgressMessage} />
                </div>}
            </div>
            <OneColumnContainer key="template" showBackButton={true} onBackClicked={() => resetView()} noPadding={true} className="m-3">
                <RunbookTemplateView template={runbookTemplate} output={runbookOutput} variant={Variant.INCIDENT} />
            </OneColumnContainer>
        </ViewCollection>
    </>;
}

/** returns the header that was used in the Aternity demo.
 *  @param runbookTemplate the runbook template which is used to display the graph of the runbook.
 *  @param updateView the function that is used to update the view
 *  @returns a JSX.Element with the header contents or null. */
/* istanbul ignore next */
function createDemoHeader(runbookTemplate: any, updateView: Function): JSX.Element {
    return runbookTemplate && <div className="d-flex mb-3 align-items-center">
        {runbookTemplate.label && <IconTitle icon={SDWAN_ICONS.RUNBOOK_OUTPUT} className="mr-2" title={runbookTemplate.label} size={SIZE.l} showIconWithoutBg={true} />}
        <ButtonGroup minimal={true}>
            <Button icon={<Icon icon={APP_ICONS.VIEW} />} onClick={() => updateView("template")}>{STRINGS.runbooks.viewTemplate}</Button>
            <Button icon={<Icon icon={APP_ICONS.RERUN} />} disabled={true}>{STRINGS.runbooks.rerun}</Button>
        </ButtonGroup>
    </div>;
}

/** returns the header that was used in the PL mock-ups.
 *  @param runbookMenuItems the menu that displays the list of runbooks in a combo box.
 *  @param selectedRunbook the currently selected runbook.
 *  @param updateView the function that is used to update the view
 *  @returns a JSX.Element with the header content. */
/* istanbul ignore next */
function createPlHeader(
    runbookMenuItems: Array<JSX.Element>, selectedRunbook: RunbookOutput | undefined, updateView: Function
): JSX.Element {
    let selectedRunbookTime = "";
    if (selectedRunbook && selectedRunbook.timestamp) {
        const time = parseTimeFromDAL(selectedRunbook.timestamp);
        selectedRunbookTime = formatToLocalTimestamp(time, TIME_FORMAT.DISPLAY_TIME_FORMAT);
    }

    return <div className="d-flex mb-5 align-items-top">
        <div className="p-4 mr-5 runbook-output-tab-runbook-bg">
            <div>{STRINGS.incidents.runbookOutputs.runbookName}</div>
            <div className="mb-2 font-weight-bold" >{selectedRunbook ? selectedRunbook.name : ""}</div>
            <div>{STRINGS.incidents.runbookOutputs.runbookStart}</div>
            <div className="mb-2 font-weight-bold" >{selectedRunbookTime}</div>
            <div>{STRINGS.incidents.runbookOutputs.runbookStatus}</div>
            <div className="font-weight-bold" >{selectedRunbook?.status && RUNBOOK_STATUS_PROPS[selectedRunbook.status] ? RUNBOOK_STATUS_PROPS[selectedRunbook.status].label : RUNBOOK_STATUS_PROPS[RUNBOOK_STATUS.UNKNOWN].label}</div>
        </div>
        <div className="mr-4">
            <Popover2 minimal position={Position.BOTTOM_RIGHT} content={
                <Menu>{runbookMenuItems}</Menu>
            } >
                <Button rightIcon={IconNames.CHEVRON_DOWN} text={(selectedRunbook && selectedRunbook.variant === Variant.INCIDENT.toUpperCase())
                    ? selectedRunbook.name + " - " + selectedRunbookTime : STRINGS.runbooks.noRunbooks}
                    className="text-nowrap" small={true}
                />
            </Popover2>
        </div>
        <div className="btn-link mr-4" onClick={() => alert("clicked")}>
            {STRINGS.incidents.runbookOutputs.reRunRunbook}
        </div>
        <div className="btn-link" onClick={() => updateView("template")}>
            {STRINGS.incidents.runbookOutputs.openRunbook}
        </div>
    </div>;
}

/** returns the latest version of the header from Damien's mock-ups.
 *  @param runbookMenuItems the menu that displays the list of runbooks in a combo box.
 *  @param selectedRunbook the currently selected runbook.
 *  @param runbookConfigs the array of runbook configurations.
 *  @param runbookOutput the RunbookOutput that was received by the RunbookView and returned via a callback.
 *  @param lastRunbookInfo the information sent back from the last runbook run.
 *  @param incidentId the incident id of the displayed incident.
 *  @param enableRerunRunbook a boolean which is true if the rerun runbook button should be enabled.
 *  @param enableOpenRunbook a boolean which if true specifies we should enable the open runbook button.
 *  @param history the history object that can be used to push new URLs/states into the browser history.
 *  @param updateView the function that is used to update the view.
 *  @param setDialogState the function that is used to set the dialog state from the useState hook.
 *  @param setRerunEndTime the function that is used to set the dialog state from the useState hook.
 *  @returns a JSX.Element with the header content. */
function createOneLineHeader(
    runbookMenuItems: Array<JSX.Element>, selectedRunbook: RunbookOutput | undefined,
    runbookConfigs: Array<RunbookConfig>, runbookOutput: RunbookOutput | undefined,
    lastRunbookInfo: { status: string, timestamp: string, id: string } | undefined,
    incidentId: string, enableRerunRunbook: boolean, enableOpenRunbook: boolean,
    history: any, updateView: Function, setRerunEndTime, setDialogState: (dialogState: any) => void
): JSX.Element {
    // Removing this for now because the automation rules have made it difficult to determine if there is an 
    // active runbook for this incident.  We would have to check the mappings, but the mappings also have conditions
    // which the UI cannot evaluate, so do we really know that there is an active runbook for this condition?  We
    // could tell the user if there are absolutely no active runbooks, but even that is difficult.  We would need
    // to check the runbook trigger and compare that with the automation mappings.
    const { selectedRunbookTime, runbookModifiedTime /*, isActive, isOneOfTriggerActive*/ } = checkForActiveRunbookChanges(runbookConfigs, selectedRunbook);
    const { hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug } = checkForErrorsVariablesAndDebug(runbookOutput);

    return <div className="d-flex align-items-center runbook-output-tab-runbook-bg py-2 px-2 m-3 rounded">
        <div className="mr-4">
            <Popover2 minimal position={Position.BOTTOM_RIGHT} content={
                <Menu>{runbookMenuItems}</Menu>
            } >
                <Button rightIcon={IconNames.CHEVRON_DOWN} text={
                    (selectedRunbook && selectedRunbook.variant === Variant.INCIDENT.toUpperCase()) ? 
                    <>
                        <PriorityLEDFormatter priority={selectedRunbook.priority} showStatusAsBackground />
                            <span className="ml-2">{selectedRunbook.name + " - " + selectedRunbookTime}</span>
                    </> : STRINGS.runbooks.noRunbooks
                }
                    className="text-nowrap" small={true}
                />
            </Popover2>
        </div>
        <Button icon={<Icon icon={IconNames.REPEAT} iconSize={12} />} small={true} intent={Intent.PRIMARY} disabled={!enableRerunRunbook} minimal style={{ color: "#4da3ff" }} className="mr-2"
            onClick={() => {
                rerunRunbook(setRerunEndTime, setDialogState);
            }}>
            <span className="btn-link">{STRINGS.incidents.runbookOutputs.reRunRunbook}</span>
        </Button>
        <Button icon={<Icon icon={IconNames.FOLDER_OPEN} iconSize={12} />} small={true} intent={Intent.PRIMARY} disabled={!enableOpenRunbook} minimal style={{ color: "#4da3ff" }} onClick={() => updateView("template")}>
            <span className="btn-link">{STRINGS.incidents.runbookOutputs.openRunbook}</span>
        </Button>
        {(runbookModifiedTime /*|| !isActive || !isOneOfTriggerActive*/) && <div className="d-flex flex-wrap justify-content-center flex-grow-1 align-items-center">
            <Icon icon={IconNames.WARNING_SIGN} iconSize={14} className="mr-2" style={{ color: (/*!isOneOfTriggerActive ? PRIORITY_COLORS[PRIORITY.CRITICAL] :*/ PRIORITY_COLORS[PRIORITY.MODERATE]) }} />
            {runbookModifiedTime && <span className="mr-2">{STRINGS.formatString(STRINGS.incidents.runbookOutputs.modifiedWarning, runbookModifiedTime)}</span>}
            {/*
                {(!isActive && isOneOfTriggerActive) && <span className="mr-2">{STRINGS.incidents.runbookOutputs.switchWarning}</span>}
                {(!isActive && !isOneOfTriggerActive) && <span>{STRINGS.incidents.runbookOutputs.noActiveError1}
                    <Button minimal disabled={false} className="" text={<span className="btn-link">{STRINGS.incidents.runbookOutputs.noActiveError2}</span>}
                        onClick={() => history.push(getURL(getURLPath("runbooks"), {}))}
                        style={{ paddingLeft: "4px", paddingRight: "4px", paddingBottom: "8px" }}
                    />
                    {STRINGS.incidents.runbookOutputs.noActiveError3}
                </span>}
            */}
        </div>}
        <div className="d-flex flex-wrap justify-content-end flex-grow-1">
            {hasError && runbookOutput && <Icon className="mr-2" icon={IconNames.ERROR} intent={Intent.DANGER} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{ cursor: "pointer" }} />}
            {hasWarning && !hasError && runbookOutput?.datasets?.length && <Icon className="mr-2" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{ cursor: "pointer" }} />}
            {!hasWarning && !hasError && (hasVariables || hasInput || hasIndicators) && runbookOutput && <Icon className="mr-2" icon={IconNames.WARNING_SIGN} intent={Intent.SUCCESS} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{ cursor: "pointer" }} />}
            <span className="mr-2">{STRINGS.incidents.runbookOutputs.runbookLastRun}:</span>
            <span className="pr-2 font-weight-bold" >{lastRunbookInfo?.status && RUNBOOK_STATUS_PROPS[lastRunbookInfo.status] ? RUNBOOK_STATUS_PROPS[lastRunbookInfo.status].label : RUNBOOK_STATUS_PROPS[RUNBOOK_STATUS.UNKNOWN].label}</span>
            {hasDebug && runbookOutput?.datasets?.length && <Icon className="ml-2" icon={IconNames.DIAGNOSIS} onClick={() => {
                showDebugDialog(runbookOutput, setDialogState);
            }} style={{ cursor: "pointer" }} />}
        </div>
    </div>;
}

/** returns the latest version of the header from Damien's mock-ups.
 *  @param runbookMenuItems the menu that displays the list of runbooks in a combo box.
 *  @param selectedRunbook the currently selected runbook.
 *  @param runbookConfigs the array of runbook configurations.
 *  @param runbookOutput the RunbookOutput that was received by the RunbookView and returned via a callback.
 *  @param entity the Entity that the incident was triggered on.
 *  @param lastRunbookInfo the information sent back from the last runbook run.
 *  @param incidentId the incident id of the displayed incident.
 *  @param enableRerunRunbook a boolean which is true if the rerun runbook button should be enabled.
 *  @param enableOpenRunbook a boolean which if true specifies we should enable the open runbook button.
 *  @param history the history object that can be used to push new URLs/states into the browser history.
 *  @param updateView the function that is used to update the view.
 *  @param setDialogState the function that is used to set the dialog state from the useState hook.
 *  @param setRerunEndTime the function that is used to set the dialog state from the useState hook.
 *  @returns a JSX.Element with the header content. */
function createHeaderWithMoreButton(
    runbookMenuItems: Array<JSX.Element>, selectedRunbook: RunbookOutput | undefined,
    runbookConfigs: Array<RunbookConfig>, runbookOutput: RunbookOutput | undefined,
    entity: Entity | undefined,
    lastRunbookInfo: { status: string, timestamp: string, id: string, errors?: any[] } | undefined,
    incidentId: string, enableRerunRunbook: boolean, enableOpenRunbook: boolean,
    history: any, updateView: Function, setRerunEndTime, setDialogState: (dialogState: any) => void
): JSX.Element {
    // Removing this for now because the automation rules have made it difficult to determine if there is an 
    // active runbook for this incident.  We would have to check the mappings, but the mappings also have conditions
    // which the UI cannot evaluate, so do we really know that there is an active runbook for this condition?  We
    // could tell the user if there are absolutely no active runbooks, but even that is difficult.  We would need
    // to check the runbook trigger and compare that with the automation mappings.
    const { selectedRunbookTime, runbookModifiedTime /*, isActive, isOneOfTriggerActive*/ } = checkForActiveRunbookChanges(runbookConfigs, selectedRunbook);
    const { hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug } = checkForErrorsVariablesAndDebug(runbookOutput);

    const moreMenuItems: Array<JSX.Element> = [];
    moreMenuItems.push(
        <MenuItem disabled={!enableRerunRunbook} text={STRINGS.incidents.runbookOutputs.reRunRunbook} active={false} key={"rerun"} icon={IconNames.REPEAT}
            onClick={() => {
                rerunRunbook(setRerunEndTime, setDialogState);
            }}
        />
    );
    moreMenuItems.push(
        <MenuItem disabled={!enableOpenRunbook} text={STRINGS.incidents.runbookOutputs.seeNodesTraversed} active={false} key={"open"} icon={IconNames.GRAPH}
            onClick={() => {
                updateView("template");
            }}
        />
    );
    moreMenuItems.push(
        <MenuItem disabled={!enableOpenRunbook || !selectedRunbook} text={STRINGS.incidents.runbookOutputs.editRunbook} active={false} key={"edit"} icon={IconNames.EDIT}
            onClick={() => {
                if (selectedRunbook?.template) {
                    const selectedRunbookConfig = JSON.parse(selectedRunbook.template);
                    history.push(getURL(
                        getURLPath("create-runbook"),
                        {   [PARAM_NAME.rbConfigId]: selectedRunbookConfig.id,
                            [PARAM_NAME.rbConfigNm]: selectedRunbookConfig.name,
                            [PARAM_NAME.variant]: Variant.INCIDENT
                        },
                        { replaceQueryParams: true}
                    ));    
                }
            }}
        />
    );

    const entityText: JSX.Element = getEntityDescriptionForRunbook(entity, runbookOutput?.mapping?.triggerType);

    return <div className={"d-flex align-items-center runbook-output-tab-runbook-bg " + (INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" ? " ml-1 mb-2" : " px-2 m-3 rounded")}>
        {entityText}
        {(runbookModifiedTime /*|| !isActive || !isOneOfTriggerActive*/) && <div className="d-flex flex-wrap justify-content-center flex-grow-1 align-items-center overflow-auto">
            <Icon icon={IconNames.WARNING_SIGN} iconSize={14} className="mr-2" style={{ color: (/*!isOneOfTriggerActive ? PRIORITY_COLORS[PRIORITY.CRITICAL] :*/ PRIORITY_COLORS[PRIORITY.MODERATE]) }} />
            {runbookModifiedTime && <span className="mr-2">{STRINGS.formatString(STRINGS.incidents.runbookOutputs.modifiedWarning, runbookModifiedTime)}</span>}
            {/*
                {(!isActive && isOneOfTriggerActive) && <span className="mr-2">{STRINGS.incidents.runbookOutputs.switchWarning}</span>}
                {(!isActive && !isOneOfTriggerActive) && <span>{STRINGS.incidents.runbookOutputs.noActiveError1}
                    <Button minimal disabled={false} className="" text={<span className="btn-link">{STRINGS.incidents.runbookOutputs.noActiveError2}</span>}
                        onClick={() => history.push(getURL(getURLPath("runbooks"), {}))}
                        style={{ paddingLeft: "4px", paddingRight: "4px", paddingBottom: "8px" }}
                    />
                    {STRINGS.incidents.runbookOutputs.noActiveError3}
                </span>}
            */}
        </div>}
        <div className="d-flex flex-wrap justify-content-end flex-grow-1 align-items-center overflow-auto">
            <div className="mr-2">
                <Popover2 minimal position={Position.BOTTOM_RIGHT} content={
                    <Menu>{runbookMenuItems}</Menu>
                } >
                    <Button rightIcon={IconNames.CHEVRON_DOWN} text={
                        (selectedRunbook && selectedRunbook.variant === Variant.INCIDENT.toUpperCase()) ? 
                            <span className="ml-2">{selectedRunbook.name + " - " + selectedRunbookTime}</span>
                        : STRINGS.runbooks.noRunbooks
                    }
                        className="text-nowrap" small={true}
                    />
                </Popover2>
            </div>
            {hasError && runbookOutput && <Icon className="mr-2" icon={IconNames.ERROR} intent={Intent.DANGER} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{ cursor: "pointer" }} />}
            {hasWarning && !hasError && runbookOutput?.datasets?.length && <Icon className="mr-2" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{ cursor: "pointer" }} />}
            {!hasWarning && !hasError && (hasVariables || hasInput || hasIndicators) && runbookOutput && <Icon className="mr-2" icon={IconNames.INFO_SIGN} intent={Intent.SUCCESS} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{ cursor: "pointer" }} />}
            <span className="mr-2">{STRINGS.incidents.runbookOutputs.runbookLastRun}:</span>
            <span className="pr-2 font-weight-bold" >{lastRunbookInfo?.status && RUNBOOK_STATUS_PROPS[lastRunbookInfo.status] ? RUNBOOK_STATUS_PROPS[lastRunbookInfo.status].label : RUNBOOK_STATUS_PROPS[RUNBOOK_STATUS.UNKNOWN].label}</span>
            {!runbookOutput && lastRunbookInfo?.errors?.length && <Icon className="mr-2" icon={IconNames.ERROR} intent={Intent.DANGER} onClick={() => {
                showErrorAndWarningDialog(lastRunbookInfo as RunbookOutput, setDialogState, false);
            }} style={{ cursor: "pointer" }} />}
            {hasDebug && runbookOutput?.datasets?.length && <Icon className="ml-2" icon={IconNames.DIAGNOSIS} onClick={() => {
                showDebugDialog(runbookOutput, setDialogState);
            }} style={{ cursor: "pointer" }} />}
            <div onClick={(e) => { e.stopPropagation(); }}>
                <Popover2 position={Position.BOTTOM_RIGHT}
                    interactionKind={Popover2InteractionKind.CLICK}
                    content={
                        <Menu>{moreMenuItems}</Menu>
                    } >
                    <Button aria-label="incident-details-runbook-output-more-button"
                        icon={IconNames.MORE} minimal className="incident-details-runbook-output-action-icon ml-2"
                        disabled={false} onClick={(e) => { }}
                    />
                </Popover2>
            </div>
        </div>
    </div>;
}

/** produces parameters necessary to fill out the warnings on active runbook incosistencies.
 *  @param runbookConfigs the array of runbook configurations.
 *  @param selectedRunbook the currently selected runbook.
 *  @returns all the parameters necessary to fill out the warnings for active runbook changes. */
function checkForActiveRunbookChanges(
    runbookConfigs: Array<RunbookConfig>, selectedRunbook: RunbookOutput | undefined
): { selectedRunbookTime: string, runbookModifiedTime: string /*, activeRunbookName: string | undefined, isActive: boolean, isOneOfTriggerActive: boolean*/ } {
    // We could use this in the future to try and figure out what is active.  Not sure if we can be perfect.
    //const eventMappings: MappingConfig[] = getEventMappings();
    let selectedRunbookTime = "", runbookModifiedTime;
    /*
    let activeRunbookName: string | undefined = undefined;
    let isActive = true, isOneOfTriggerActive = true;
    */
    if (selectedRunbook && selectedRunbook.timestamp) {
        // Get the formatted time with the runbook run time
        const time = parseTimeFromDAL(selectedRunbook.timestamp);
        selectedRunbookTime = formatToLocalTimestamp(time, TIME_FORMAT.DISPLAY_TIME_FORMAT);

        // Check to see if any warnings should be displayed for the runbook
        try {
            if (selectedRunbook.template) {
                const template: RunbookConfig = JSON.parse(selectedRunbook.template);
                if (template.id && runbookConfigs.length > 0) {
                    //isActive = false;
                    //isOneOfTriggerActive = false;
                    for (const runbookConfig of runbookConfigs) {
                        if (runbookConfig.id === template.id) {
                            const updateTime = parseTimeFromDAL(runbookConfig.lastUpdatedTime);
                            // This was copied up from below it is the only thing still accurate
                            if (time && updateTime && updateTime.getTime() > time.getTime()) {
                                runbookModifiedTime = formatToLocalTimestamp(updateTime, TIME_FORMAT.DISPLAY_TIME_FORMAT);
                            }
                            /*
                            const triggerType = runbookConfig.triggerType;
                            if (!runbookConfig.isReady) {
                                isOneOfTriggerActive = false;
                                for (const runbookConfigChk of runbookConfigs) {
                                    if (runbookConfigChk.id !== runbookConfig.id) {
                                        if (runbookConfigChk.triggerType === triggerType && runbookConfigChk.isReady) {
                                            isOneOfTriggerActive = true;
                                            activeRunbookName = runbookConfigChk.name;
                                            break;
                                        }
                                    }
                                }
                            } else {
                                isActive = true;
                                isOneOfTriggerActive = true;
                                activeRunbookName = runbookConfig.name;
                                if (time && updateTime && updateTime.getTime() > time.getTime()) {
                                    runbookModifiedTime = formatToLocalTimestamp(updateTime, TIME_FORMAT.DISPLAY_TIME_FORMAT);
                                }
                            }
                            */
                        }
                    }
                }
            }
        } catch (error) {
            console.log("Error parsing runbook template");
        }
    }
    return { selectedRunbookTime, runbookModifiedTime /*, activeRunbookName, isActive, isOneOfTriggerActive*/ };
}

/** checks the runbook output for errors and debug information.
 *  @param runbookOutput the RunbookOutput object to check for errors.
 *  @returns an object with the hasWarning and hasError flags. */
function checkForErrorsVariablesAndDebug(
    runbookOutput?: RunbookOutput
): { hasWarning: boolean, hasError: boolean, hasVariables: boolean, hasInput: boolean, hasIndicators: boolean, hasDebug: boolean } {
    let hasError = false, hasWarning = false, hasVariables = false/*true*/, hasInput = false, hasIndicators = false, hasDebug = false;
    if (runbookOutput) {
        if (runbookOutput.datasets) {
            for (const dataset of runbookOutput.datasets) {
                if (dataset.info?.error) {
                    hasError = true;
                }
                if (dataset.info?.warning) {
                    hasWarning = true;
                }
                if (dataset.debug) {
                    hasDebug = true;
                }
            }    
        }
        if (runbookOutput.errors?.length) {
            hasError = true;
        }
        if (
            runbookOutput.variableResults && 
            (runbookOutput.variableResults.primitiveVariables?.length || runbookOutput.variableResults.structuredVariables?.length)
        ) {
            hasVariables = true;
        }
        if (runbookOutput.triggerInputs) {
            hasInput = true;
        }
        if (runbookOutput.indicators?.length) {
            hasIndicators = true;
        }
    }
    return { hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug };
}



/** re-runs the runbook.
 *  @param setDialogState
 *  @param setRerunEndTime .*/
function rerunRunbook(
    setRerunEndTime, setDialogState: (dialogState: any) => void
) {
    setRerunEndTime(0);
    const newDialogState: any = { showDialog: true, loading: false, title: STRINGS.incidents.runbookOutputs.reRunDialogTitle };
    const introText: string = STRINGS.runbookInvocations.invokeReRunRunbookDialogIntroText  ;
    var endTime;
    newDialogState.dialogContent = <div>
        <div className="mb-3"><span>{introText}</span></div>
        <tbody>
            <tr key="end-time">
                <td className="p-1"><label>{STRINGS.viewRunbooks.inputEndTimeLabel}</label></td>
                <td className="p-1"><RunbookTimeControl onTimeChange={(time: number | undefined) => endTime = time ? time : 0 }/></td>
            </tr>
        </tbody>
    </div>
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={(evt) => {
        setRerunEndTime(endTime ? (endTime/1000).toFixed(9) : (Date.now()/1000).toFixed(9));
        setDialogState(updateDialogState(newDialogState, false, false, []));
    }} text={STRINGS.runbooks.okBtnText} />;
    setDialogState(newDialogState);
}

/** clears the timeout id.
 *  @param timeoutId the object returned by useRef that contains the timeout id. */
function clearInProgressTimeout(timeoutId: { current: number }): void {
    if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = 0;
    }
}

/** Creates a popup that displays the runbook error information.
 *  @param runbookOutput the output from the runbook.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param debug a boolean value, if true show debug information, if false, do not. */
function showErrorAndWarningDialog(
    runbook: RunbookOutput, setDialogState: (dialogState: any) => void, debug: boolean
): void {
    const dialogState: any = { showDialog: true, loading: false, title: STRINGS.incidents.runbookOutputs.errorDialogTitle };
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <ErrorDialogContent runbook={runbook} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the runbook debug information.
 *  @param runbookOutput the output from the runbook.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    runbook: RunbookOutput, setDialogState: (dialogState: any) => void
): void {
    let json = undefined;
    const dialogState: any = { showDialog: true, loading: false, title: STRINGS.incidents.runbookOutputs.debugDialogTitle };
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <DebugDialogContent runbook={runbook} onJsonOutputChanged={(jsOutput) => { json = jsOutput }} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.copyBtnText} onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(json));
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}
