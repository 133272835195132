/** This file defines the on demand input editor React component.  The on demand input editor allows you 
 *  to define the inputs that the on demand runbook is expecting.
 *  @module */
import React from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { SubflowInputNodeEditor } from "../subflow-input/SubflowInputNodeEditor";

/** Component for editing the properties of a on demand input node
 *  @returns a JSX component with the on demand input node editor. */
export const OnDemandInputNodeEditor = React.forwardRef((props: SimpleNodeEditorProps, ref: any): JSX.Element => {
    return <SubflowInputNodeEditor {...props} ref={ref}/>
});
