import { LoadingOverlay } from '@tir-ui/react-components';
import React, { useEffect, useState } from 'react'
import { EmbedAppAuthService } from '../../../utils/services/EmbedAppAuthService';

type Props = {
  environment: string,
  children: React.ReactNode
}

// This will initialize the Embed Auth Tenant
/* istanbul ignore next */
const EmbedAuthWrapper = ({ environment, children }: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTenant = () => {
      return EmbedAppAuthService.init().then(() => {
        console.debug(`Embed Auth Service loaded succesfully`)
        //load the application
        setLoading(false);
      },
      (err: any) => {
        console.error(err);
        // requested tenant does not exist case
        setLoading(false);
      }
    )
    }

    loadTenant();
  }, []);

  if (loading) {
    return <LoadingOverlay />
  }

  return (<div>{children}</div>)
}

export default EmbedAuthWrapper