/** This file defines the trigger editor React component. The trigger editor allows you 
 *  to configure the time control for the trigger, by selecting the time reference and offset.
 *  @module */
import React, { useState } from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { HELP, STRINGS } from "app-strings";
import { UniversalNode } from "../../UniversalNode";
import { InputType } from "../../types/GraphTypes";
import { HTMLSelect, Radio, RadioGroup } from "@blueprintjs/core";
import { TriggerNodeUtils, TRIGGER_NODE_EDIT_PROPS } from "./TriggerNodeUtils";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp";
import { NodeLibraryNode } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { SHOW_CONTEXT } from "components/enums/QueryParams";
import { RunbookContextSummary } from "../RunbookContextSummary";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface TriggerNodeProperties {
    /** a string with the type of trigger, such as network_interface or network_device. */
    triggerType?: string;
    /** an enum with the time reference which is one of primary indicator or runbook executin. */
    timeReference?: TimeReferenceOptions;
    /** integer with the time offset in seconds. */
    timeOffset?: number;
}

const offsetOptions = [
    { value: 0, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.noOffset },
    { value: 15 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.fifteenMins },
    { value: 30 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.thirtyMins },
    { value: 45 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.fortyfiveMins },
    { value: 60 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.sixtyMins }
];

/** an enum that specifies the possible time reference options. */
export enum TimeReferenceOptions {
    /** the enumerated value for primary indicator option. */
    PRIMARY_INDICATOR = "PRIMARY_INDICATOR",
    /** the enumerated value for runbook execution option. */
    RUNBOOK_EXECUTION = "RUNBOOK_EXECUTION",
}

/** Component for editing the properties of a Trigger node
 *  @param selectedNode - Currently selected active trigger node
 *  @param libraryNode- Selected trigger node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the trigger node editor. */
export const TriggerNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const [curProperties, setCurProperties] = useState<TriggerNodeProperties>({
        triggerType: selectedNode?.getProperty("triggerType") ? selectedNode?.getProperty("triggerType") : InputType.INTERFACE,
        timeReference: selectedNode?.getProperty(TRIGGER_NODE_EDIT_PROPS.TIME_REFERENCE) ? selectedNode?.getProperty(TRIGGER_NODE_EDIT_PROPS.TIME_REFERENCE) as TimeReferenceOptions : TimeReferenceOptions.RUNBOOK_EXECUTION,
        timeOffset: selectedNode?.getProperty(TRIGGER_NODE_EDIT_PROPS.TIME_OFFSET) !== undefined ? selectedNode?.getProperty(TRIGGER_NODE_EDIT_PROPS.TIME_OFFSET) : 900,
    });

    /**
     * Update the selected node properties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being editied
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: TriggerNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        selectedNode.setProperty(TRIGGER_NODE_EDIT_PROPS.TIME_REFERENCE, curProperties?.timeReference);
        selectedNode.setProperty(TRIGGER_NODE_EDIT_PROPS.TIME_OFFSET, curProperties?.timeOffset);
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            TriggerNodeUtils.validateNodeProperties(
                curProperties,
                errorMessages,
            );
            return errorMessages;
        }
    };

    return (
        <>
            <tr>
                <td className="display-7 font-weight-bold pt-2 pb-3" colSpan={2}>
                    <InlineHelp 
                        helpMapping={HELP.RunbookNodeCategory?.Trigger?.[selectedNode?.getProperty('triggerType')?.replace('.', '_')]?.DQRefTime}>
                        {STRINGS.runbookEditor.nodeEditor.dataQueryReferenceTime}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <em>{STRINGS.runbookEditor.nodeLibrary.propertyLabels.queryNodes}</em>{STRINGS.runbookEditor.nodeLibrary.propertyLabels.referenceQueryBasedOn}
                </td>
            </tr>
            <tr>
                <td className="pt-2" colSpan={2}>
                    <RadioGroup
                        name="time_control"
                        onChange={(event) => {
                            const updateTimeReference = {
                                timeReference: event.currentTarget.value as TimeReferenceOptions,
                                timeOffset: event.currentTarget.value === TimeReferenceOptions.RUNBOOK_EXECUTION ? 900 : curProperties.timeOffset
                            }
                            setCurProperties({ ...curProperties, ...updateTimeReference });
                        }}
                        selectedValue={curProperties?.timeReference}
                        inline={true}
                        className="pl-4 align-self-center"
                    >
                        <Radio
                            label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.primaryIndicatorStart}
                            value={TimeReferenceOptions.PRIMARY_INDICATOR}
                            disabled={curProperties.triggerType === InputType.WEBHOOK}
                            className="mb-0 mr-2"
                        />
                        <span className="mr-2">+</span>
                        <HTMLSelect
                            name="time_interval"
                            disabled={curProperties.timeReference === TimeReferenceOptions.RUNBOOK_EXECUTION}
                            options={offsetOptions}
                            value={curProperties?.timeOffset}
                            onChange={(event) => {
                                const updateTimeOffset = {
                                    timeOffset: parseInt(event.currentTarget.value),
                                }
                                setCurProperties({ ...curProperties, ...updateTimeOffset });
                            }}>
                        </HTMLSelect>
                        <Radio
                            label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.runbookExecution}
                            value={TimeReferenceOptions.RUNBOOK_EXECUTION}
                            className="mb-0 pt-2 position-relative d-block"
                        />
                    </RadioGroup>
                </td>
            </tr>
            <RunbookContextSummary
                currentProperties={JSON.parse(JSON.stringify(curProperties))}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={SHOW_CONTEXT} showInputExample={SHOW_CONTEXT}
            />
        </>
    )
});
