import React, { useState, useEffect } from "react";
import { useQueryParams } from "utils/hooks";
import { PARAM_NAME } from "components/enums/QueryParams";
import { Elements, OnLoadParams } from "react-flow-renderer";
import { Button, Checkbox, Divider, HTMLSelect, InputGroup } from "@blueprintjs/core";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { LayoutOptions } from "elkjs/lib/elk.bundled.js";
import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from 'app-strings';
import { getElements, getGraphDef } from "utils/cloudim/TopologyUtils";
import { algorithmOptions, directionOptions, defaultOptions, overlapRemovalOption, getLayoutedGraph, presetOptions } from "utils/cloudim/ELKLayout";

import './LayoutControl.css';

export interface LayoutControlProps {
    showLayoutOptions?: boolean;
    elements: Elements;
    updateElements: (element: Elements) => void;
    reactFlowInstance: OnLoadParams | undefined;
    debug?: boolean;
}

export interface LayoutControlHandle {
    getOptions: () => LayoutOptions;
    getOverlapRemoval: () => boolean;
}

const LayoutControl = React.forwardRef(({ showLayoutOptions, elements, updateElements, reactFlowInstance, debug }: LayoutControlProps, ref) => {
    React.useImperativeHandle(ref, () => ({
        getOptions: () => options,
        getOverlapRemoval: () => overlapRemoval,
    }));

    const { params, setQueryParams } = useQueryParams({
        listenOnlyTo: [PARAM_NAME.layoutOption, PARAM_NAME.overlapRemoval]
    })

    // Preset options listed here: "src\utils\cloudim\ELKLayout.ts"
    const [preset, setPreset] = useState<string>(params[PARAM_NAME.layoutOption] ? params[PARAM_NAME.layoutOption] : "");
    const [overlapRemoval, setOverlapRemoval] = useState<boolean>(params[PARAM_NAME.overlapRemoval] === "true" ? true : false);
    const [options, setOptions] = useState<LayoutOptions>(showLayoutOptions ? defaultOptions : presetOptions.Box);

    // Check params
    useEffect(() => {
        // Check preset
        const layoutOptionParam = params[PARAM_NAME.layoutOption] ? params[PARAM_NAME.layoutOption] : "";
        setPreset(layoutOptionParam);
        if (presetOptions[layoutOptionParam]) {
            setOptions(options => ({ ...options, ...presetOptions[layoutOptionParam] }));
            onLayout(presetOptions[layoutOptionParam]);
        } else {
            // If we don't have any params, set the default layout based on if we show the layouts
            const layoutOption = showLayoutOptions ? defaultOptions : presetOptions.Box;
            setOptions(layoutOption);
            onLayout(layoutOption);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.layoutOption]);

    useEffect(() => {
        // Check overlap removal
        const overlapRemovalParam = params[PARAM_NAME.overlapRemoval] === "true" ? true : false;
        setOverlapRemoval(overlapRemovalParam);
        if (overlapRemovalParam) {
            onLayout(overlapRemovalOption);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.overlapRemoval]);

    // Add additional debug settings here.
    const debugSettings = [
        {
            label: STRINGS.cloudim.topology.layoutControl.algorithmOption,
            algorithm: "elk.algorithm",
            config: {
                input: "htmlselect",
                options: algorithmOptions
            }
        },
        {
            label: STRINGS.cloudim.topology.layoutControl.directionOption,
            algorithm: "elk.direction",
            config: {
                input: "htmlselect",
                options: directionOptions
            }
        },
        {
            label: STRINGS.cloudim.topology.layoutControl.nodeNodeSpacingOption,
            algorithm: "elk.spacing.nodeNode",
            config: {
                input: "inputgroup",
                type: "number"
            }
        },
        {
            label: STRINGS.cloudim.topology.layoutControl.componentComponentSpacingOption,
            algorithm: "elk.spacing.componentComponent",
            config: {
                input: "inputgroup",
                type: "number"
            }
        }
    ];

    const onLayout = async (pOptions?: LayoutOptions) => {
        const graphDef = getGraphDef(elements);

        try {
            const layoutOptions = pOptions || options;

            let layoutedGraph = await getLayoutedGraph(graphDef.nodes, graphDef.edges, layoutOptions);

            // Prevents the overlap removal layout option to be called twice
            if (overlapRemoval && pOptions !== overlapRemovalOption) {
                layoutedGraph = await getLayoutedGraph(layoutedGraph.nodes, layoutedGraph.edges, overlapRemovalOption);
            }

            updateElements(getElements(layoutedGraph));
            if (reactFlowInstance) {
                reactFlowInstance.fitView();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const popupContent = () => {
        return (
            <div className="layoutControlContainer" data-testid="layoutControlContainer">
                <div>
                    <span>{STRINGS.cloudim.topology.layoutControl.presetOptions}</span>
                    <HTMLSelect
                        data-testid="presetLayoutSelection"
                        value={preset}
                        options={Object.keys(presetOptions)}
                        onChange={async (e) => {
                            const value = e.currentTarget.value;
                            setQueryParams({ [PARAM_NAME.layoutOption]: value }, true);
                        }}
                    />
                </div>
                <Checkbox type="checkbox"
                    data-testid="overlapRemovalCheckbox"
                    label={STRINGS.cloudim.topology.layoutControl.applyOverlapRemoval}
                    checked={overlapRemoval}
                    onChange={(e: any) => {
                        const checked = e.target.checked;
                        setQueryParams({ [PARAM_NAME.overlapRemoval]: checked }, true);
                    }}
                />
                {debug &&
                    <>
                        <Divider />
                        {debugSettings.map((setting) => {
                            let inputComponent = <></>;
                            switch (setting.config.input) {
                                case "htmlselect":
                                    inputComponent = <HTMLSelect
                                        value={options[setting.algorithm]}
                                        options={setting.config.options}
                                        onChange={(e) => {
                                            let value = {};
                                            value[setting.algorithm] = e.currentTarget.value;
                                            setOptions({ ...options, ...value });
                                        }}
                                    />
                                    break;
                                case "inputgroup":
                                    inputComponent = <InputGroup
                                        type={setting.config.type}
                                        value={options[setting.algorithm]}
                                        onChange={(e) => {
                                            let value = {};
                                            value[setting.algorithm] = e.currentTarget.value;
                                            setOptions({ ...options, ...value });
                                        }}
                                    />
                                    break;
                                default:
                                    break;
                            }
                            return <div>
                                <label>
                                    {setting.label}
                                </label>
                                {inputComponent}
                            </div>
                        })}
                        <Button intent="primary" text={STRINGS.cloudim.topology.layoutControl.applyLayout} onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onLayout();
                        }} />
                    </>
                }
            </div>
        );
    };


    return (
        showLayoutOptions ? <div style={{ position: "absolute", right: 10, top: 10, zIndex: 4 }}>
            <Popover2
                content={popupContent()}
                interactionKind={Popover2InteractionKind.CLICK}
            >
                <Button data-testid="layoutButton" icon={IconNames.MORE} />
            </Popover2>
        </div> : <></>
    );
});

export default LayoutControl;