import React, { useEffect, useState } from 'react'
import { Button, Callout, Checkbox, Classes, Divider, Intent, Label, Radio, RadioGroup } from '@blueprintjs/core'
import { ErrorToaster, IconNames, SuccessToaster } from '@tir-ui/react-components'
import { STRINGS } from 'app-strings'
import classNames from 'classnames'
import { Form, InputField, SelectField } from 'components/common/form'
import { isEmpty, find } from 'lodash'
import moment from "moment";
import { Colors } from "@blueprintjs/core";
import { useQuery } from 'utils/hooks'
import { Query } from 'reporting-infrastructure/types/Query'
import { loader } from 'graphql.macro'
import { useMutation, useApolloClient } from '@apollo/client'
import { openConfirm } from 'components/common/modal'
import * as yup from 'yup';
import { DateInput, TimePrecision } from '@blueprintjs/datetime'
import { TIME_FORMAT } from 'components/enums'
import { convertIfIndexToString, formatPostDate, getInputs } from './ScheduleRunbookModal'
import './ScheduleRunbookModal.scss'

interface IScheduleRunbookProps {
    runbookID: string;
    description?: string;
    inputs: any;
    schedule?: any;
    setScheduleSummary?: (any) => void;
    setSchedule: (any) => void;
    setScheduleType: (any) => void;
    scheduleType: string;
    canEdit: boolean;
    setCanEdit: (any) => void;
    editInfos: any;
    loadingSchedules: boolean;
    setLoadingSchedules: (any) => void;
}

const StepSchedule = (props: IScheduleRunbookProps) => {
    const stepStrings = STRINGS.scheduleRunbook.wizard.panelSchedule;

    const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const repeatFreq = {
        HOUR: 24,
        DAY: 31,
        WEEK: 4
    }
    const repeatFreqLabel = {
        HOUR: "hourly",
        DAY: "daily",
        WEEK: "weekly"
    }
    const freqUnits = {
        HOUR: stepStrings.labelHrs,
        DAY: stepStrings.labelDays,
        WEEK: stepStrings.labelWeeks
    }

    const apolloClient = useApolloClient();

    const today = new Date();
    const resetSchedule = { name: '', every: '', repeat: '', startOn: today, daysOfWeek: [] };
    const [popOpen, setPopOpen] = useState(false);
    const [triggeredDelete, setTriggeredDelete] = useState(0);

    const { data } = useQuery({
        name: 'RunbookSchedules',
        query: new Query(loader('./runbook-schedules-query.graphql')),
        noCache: true
    });

    useEffect(() => {
        if (data && props.editInfos) {
            props.setLoadingSchedules(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const [DeleteRunbookSchedule] = useMutation<any, any>(
        loader('./delete-schedule-mutation.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message: stepStrings.queryMessages.success,
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: stepStrings.queryMessages.error,
                });
                console.error(err?.message);
            },
        }
    );

    const handleWeekDayChange = (day) => {
        props.setSchedule(prevState => ({
            ...prevState,
            daysOfWeek: props.schedule?.daysOfWeek?.includes(day) ?
                props.schedule.daysOfWeek.filter(x => x !== day) :
                [...props.schedule.daysOfWeek, day]
        })
        )
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        const validateInput = (value) => {
            return value.replace(/[^a-zA-Z0-9-_ ]/g, '');
        };
        const validatedValue = validateInput(value);
        props.setSchedule(prevState => ({
            ...prevState,
            [name]: validatedValue
        }));
    };

    const handleDelete = async () => {
        const payload = {
            variables: {
                input: {
                    name: props.schedule.name
                }
            }
        }
        try {
            await DeleteRunbookSchedule(payload);
            apolloClient.refetchQueries({
                include: "active",
            });
            props.setSchedule(resetSchedule);
            setTriggeredDelete(triggeredDelete+1);
        } catch (error) {
            console.log(error)
        }
    }

    const formatGetDate = (dateValue) => {
        if (typeof dateValue === 'string') {
            // convert from nanosecs to Date
            const asDate = new Date(parseFloat(dateValue) * 1000);
            return asDate
        } else {
            return dateValue
        }
    }

    const summary = `${stepStrings.summary.runs} 
    ${props.schedule.every} 
    ${freqUnits[props.schedule.repeat]} 
    ${(props.schedule.repeat === "WEEK" && !isEmpty(props.schedule?.daysOfWeek)) ? `each ${props.schedule.daysOfWeek}` : ''} 
    ${stepStrings.summary.starting} ${moment(formatGetDate(props.schedule.startOn)).format("hh:mm A on MMM Do, YYYY")}` || ''

    useEffect(() => {
        if (props?.setScheduleSummary) {
            props?.setScheduleSummary(summary);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary]);

    const validationSchema = yup.object().shape({
        saved_schedule: yup.string(),
        schedule_type: yup.string(),
        name: yup
            .string()
            .when([], {
                is: () => props.scheduleType === 'isNew' && find(data?.runbookScheduleConfigs, ['name', props.schedule.name]),
                then: yup.string().required(stepStrings.warnings.noDups),
            })
            .when([], {
                is: () => props.scheduleType === 'isNew' && !props.schedule.name,
                then: yup.string().required(stepStrings.warnings.required),
            })
            .max(1024, 'Name cannot exceed 1024 characters')
    });

    const [UpdateRunbookSchedule] = useMutation<any, any>(
        loader('./edit-schedule-mutation.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message: stepStrings.queryMessages.editSuccess,
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: stepStrings.queryMessages.errorSuccess,
                });
                console.error(err?.message);
            },
        }
    );

    const handleSave = async () => {
        const mappedInputs = convertIfIndexToString(props.inputs?.detection?.entity);
        const payload = {
            variables: {
                input: {
                    name: props.schedule.name,
                    startOn: formatPostDate(props.schedule.startOn),
                    repeat: props.schedule.repeat,
                    every: Number(props.schedule.every),
                    description: props.description,
                    daysOfWeek: props.schedule.daysOfWeek,
                    runbook: {
                        tenantId: props.inputs?.utid,
                        detection: {
                            id: props.inputs.detection.id,
                            entity: mappedInputs
                        },
                        incidentId: props.inputs.incidentId,
                        inputs: getInputs(props.inputs),
                        runbookId: props.runbookID
                    }
                }
            }
        }
        try {
            await UpdateRunbookSchedule(payload);
            apolloClient.refetchQueries({
                include: "active",
            });
            props.setCanEdit(false)
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p className='font-weight-bold'>{stepStrings.title}</p>
            <Divider className='ml-0' />
            <Form initialValues={{ name: '' }} loading={false} validationSchema={validationSchema}>
                <div className="row pt-3">
                    <div className='col-4'>
                        <RadioGroup name="schedule_type"
                            selectedValue={props.scheduleType ? props.scheduleType : !props.editInfos ? "isNew" : "isSaved"}
                            onChange={(e) => {
                                if (props.canEdit) {
                                    openConfirm({
                                        message: stepStrings.warnings.isEdit,
                                        onConfirm: () => {
                                            props.setScheduleType(e.currentTarget?.value);
                                            props.setSchedule(resetSchedule);
                                            props.setCanEdit(false);
                                        },
                                        intent: Intent.PRIMARY,
                                        icon: IconNames.WARNING_SIGN,
                                    })
                                } else {
                                    props.setScheduleType(e.currentTarget?.value);
                                    props.setSchedule(resetSchedule);
                                    props.setCanEdit(false);
                                }
                            }}>
                            <Radio className='py-2' label={stepStrings.labelSaved} value="isSaved" />
                            {!props.editInfos && <Radio className='pt-1' label={stepStrings.labelNew} value="isNew" />}
                        </RadioGroup>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className='w-100'>
                                <SelectField name="saved_schedule" className="w-100"
                                    disabled={props.scheduleType === "isNew" || props.editInfos}
                                    onChange={e => {
                                        const selectedSchedule = find(data?.runbookScheduleConfigs, ['name', e.target.value]);
                                        // if (props.canEdit) {
                                        //     openConfirm({
                                        //         message: stepStrings.warnings.isEdit,
                                        //         onConfirm: () => {
                                        //             // here
                                        //         },
                                        //         intent: Intent.PRIMARY,
                                        //         icon: IconNames.WARNING_SIGN,
                                        //     })
                                        // }
                                        if (selectedSchedule) {
                                            props.setSchedule({ ...selectedSchedule })
                                        } else {
                                            props.setSchedule(resetSchedule);
                                        }
                                        props.setCanEdit(false); // add warning on unsaved changes
                                    }}
                                    value={props.schedule.name}
                                >
                                    <option value="">Select Schedule</option>
                                    {data?.runbookScheduleConfigs?.map((x, i) => <option key={i} value={x.name}>{x.name}</option>)}
                                </SelectField>
                            </div>
                        </div>
                        {!props.editInfos && <div className="row">
                            <div className="w-100">
                                <InputField name="name"
                                    placeholder={stepStrings.placeholder}
                                    value={props.scheduleType === "isSaved" ? '' : props.schedule.name}
                                    onChange={handleFieldChange}
                                    disabled={props.scheduleType === "isSaved"} />
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="pt-5 px-5 pb-2 bg-light">
                    <div className="row">
                        <Label className='col-2 mt-1'>{stepStrings.labelStart}</Label>
                        <div className='col-9'>
                            <DateInput
                                className="schedule-runbook-date-picker"
                                formatDate={date => moment(date).format(TIME_FORMAT.DISPLAY_DATE_INPUT)}
                                parseDate={str => new Date(str)}
                                maxDate={moment(today).add(1, 'y').toDate()}
                                value={formatGetDate(props.schedule.startOn)}
                                timePrecision={TimePrecision.MINUTE}
                                timePickerProps={{
                                    useAmPm: true,
                                    selectAllOnFocus: true
                                }}
                                showActionsBar
                                popoverProps={{
                                    isOpen: popOpen,
                                    onClose: () => setPopOpen(false)
                                }}
                                onChange={date => {
                                    props.setSchedule(prevState => ({
                                        ...prevState,
                                        startOn: date
                                    }))
                                }}
                                rightElement={
                                    <Button aria-label="date-selector-clear-button" icon={IconNames.CALENDAR} minimal
                                        className="date-selector-clear-icon"
                                        disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                        onClick={() => setPopOpen(true)}
                                    />
                                }
                                disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                minDate={
                                    props.scheduleType !== 'isSaved' || !props.schedule.name ? 
                                        new Date() : 
                                        new Date(
                                            props.schedule.startOn * 1000 < Date.now() ? 
                                                props.schedule.startOn * 1000 : 
                                                Date.now()
                                        )
                                }
                            />
                            <div className='bp3-text-small pb-2'>
                                {stepStrings.labelEST}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Label className='col-2 mt-1'>{stepStrings.labelRepeat}</Label>
                        <div className='col-3'>
                            <SelectField name="repeat"
                                disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                onChange={(e) => {
                                    handleFieldChange(e)
                                    props.setSchedule(prev => ({ ...prev, daysOfWeek: [], every: 0 }));
                                }}
                                value={props.schedule.repeat}
                            >
                                <option value=""></option>
                                {Object.keys(repeatFreq).map((x, i) => <option key={i} value={x}>{repeatFreqLabel[x]}</option>)}
                            </SelectField>
                        </div>
                        {props.schedule.repeat === "WEEK" && <>
                            <Label className='col-1 mt-1'>{stepStrings.labelOn}</Label>
                            <div className='col-6'>
                                <div className='days-of-week'>
                                    {daysOfWeek.map(day => (
                                        <Checkbox key={day} inline
                                            value={day}
                                            disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                            checked={!isEmpty(props.schedule.daysOfWeek) && props.schedule.daysOfWeek.includes(day)}
                                            onChange={() => handleWeekDayChange(day)}
                                        />
                                    ))}
                                </div>
                                {props.schedule.daysOfWeek.length === 0 && <span className='field-error day-error'>{stepStrings.warnings.dayRequired}</span>}
                            </div>
                        </>}
                    </div>
                    <div className="row">
                        <Label className='col-2 mt-1'>{stepStrings.labelEvery}</Label>
                        <div className='col-2'>
                            <SelectField name="every"
                                disabled={(props.scheduleType === 'isSaved' && !props.canEdit) || !props.schedule.repeat}
                                onChange={handleFieldChange}
                                value={props.schedule.every}
                            >
                                <option value=""></option>
                                {Array.from(Array(repeatFreq[props.schedule.repeat]).keys()).map((n, i) => <option key={i} value={n + 1}>{n + 1}</option>)}
                            </SelectField>
                        </div>
                        <span className="mt-1">{freqUnits[props.schedule.repeat]}</span>
                    </div>
                    <div className='text-center bp3-text-small py-1'>
                        {Object.values(props.schedule).every(value => value || [true, false].includes(value as any)) && summary}
                    </div>
                    {/* Show when editing a saved runbook schedule OR 
                        when trying to delete a schedule used by other Runbooks*/}
                    {props.scheduleType === "isSaved" && props.canEdit &&
                        <Callout intent='warning' icon={IconNames.WARNING_SIGN} className='my-2'>
                            Changes to this schedule will apply to all runbooks already using this schedule.
                        </Callout>
                    }
                    {<div className="row justify-content-end">
                        {(props.scheduleType === "isSaved" && props.canEdit) &&
                            <>
                                <Button style={{ color: Colors.WHITE }}
                                    minimal
                                    intent="primary"
                                    className='mr-2'
                                    icon={IconNames.UNDO}
                                    onClick={(e) => {
                                        const selectedSchedule = find(data?.runbookScheduleConfigs, ['name', props.schedule.name]);
                                        props.setSchedule(selectedSchedule); // maybe warning of unsaved
                                        props.setCanEdit(false);
                                    }}
                                >
                                    {stepStrings.ctas.revert}
                                </Button>
                                <Button style={{ color: Object.values(props.schedule).every(value => value || [true, false].includes(value as any)) ? Colors.WHITE : Colors.GRAY3 }}
                                    minimal
                                    disabled={
                                        !Object.values(props.schedule).every(value => value || 
                                            [true, false].includes(value as any)) ||
                                            (props.schedule.repeat === "WEEK" && props.schedule.daysOfWeek.length === 0)
                                        }
                                    intent="primary"
                                    icon={IconNames.FLOPPY_DISK}
                                    onClick={handleSave}
                                >
                                    {stepStrings.ctas.save}
                                </Button>
                            </>
                        }

                        {(Object.values(props.schedule).every(value => value || [true, false].includes(value as any)) && props.scheduleType === 'isSaved' && !props.canEdit) &&
                            <>
                                {data?.runbookScheduleConfigs && !data.runbookScheduleConfigs?.find(item => item.name === props.schedule.name)?.hasRunbooks && props.schedule.name && 
                                <Button style={{ color: Colors.WHITE }}
                                    minimal
                                    intent="primary"
                                    icon={IconNames.TRASH}
                                    onClick={() => {
                                        openConfirm({
                                            message: stepStrings.warnings.delete,
                                            onConfirm: () => handleDelete(),
                                            intent: Intent.PRIMARY,
                                            icon: IconNames.TRASH,
                                        })

                                    }}
                                    className="mr-1"
                                >{stepStrings.ctas.delete}</Button>}
                                <Button style={{ color: Colors.WHITE }}
                                    minimal
                                    intent="primary"
                                    icon={IconNames.EDIT}
                                    disabled={!props.schedule.name}
                                    onClick={() => props.setCanEdit(true)}>{stepStrings.ctas.edit}</Button>
                            </>
                        }
                    </div>}
                </div>
            </Form>
        </div>
    )
}

export default StepSchedule
