/** This file defines the set variable element for the variables editors. A set variable element
 *  allows the user to edit either the scope of the variable or the name.
 *  @module */
import { Button, HTMLSelect } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import React, { useEffect, useRef, useState } from 'react';
import {sortBy, groupBy} from 'lodash';
import { setNativeValue } from 'reporting-infrastructure/utils/commonUtils';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE, SUBFLOW_SCOPE } from 'utils/runbooks/VariablesUtils';
import { StructuredVariablesByScope } from '../set-variable-complex/SetComplexVariableEditor';
import { PrimitiveVariablesByScope } from './SetSimpleVariablesEditor';
import { Variant, VARIANTS_WITH_FULL_SET_VARIABLE_SCOPES } from '../../types/GraphTypes';
import './SimpleVariablesEditor.scss';


/** this interface defines the set variable element definition. */
export interface SetVariableElementDefinition {
    /** the id of the element. */
    id: string;
    /** the name of the element */
    name: string,
}

/** list used to populate the selector for variable scope for all runbooks except subflows. */
const FULL_VARIABLE_SCOPE_OPTIONS = [
    { value: RUNTIME_SCOPE, label: STRINGS.runbookEditor.nodeEditor.variables.scopeOptions.runbookExecution },
    { value: INCIDENT_SCOPE, label: STRINGS.runbookEditor.nodeEditor.variables.scopeOptions.incidentScope },
    { value: GLOBAL_SCOPE, label: STRINGS.runbookEditor.nodeEditor.variables.scopeOptions.globalScope },
];

/** list used to populate the selector for variable scope for subflows. */
const SUBFLOW_SCOPE_OPTIONS = [
    { value: RUNTIME_SCOPE, label: STRINGS.runbookEditor.nodeEditor.variables.scopeOptions.subflowExecution }
];

/** list used to populate the selector for variable scope for on-demand runbooks. */
const SIMPLE_VARIABLE_SCOPE_OPTIONS = [
    { value: RUNTIME_SCOPE, label: STRINGS.runbookEditor.nodeEditor.variables.scopeOptions.runbookExecution }
];

/** this interface defines the properties passed into the SetVariableElement react component. */
export interface SetVariableElementProps {
    /** onRemove handler called when a variable is removed */
    onRemoveSimpleVariableElementClicked?: (id: string) => void;
    /** onChange handler called when variable name changes */
    onChange?;
    /** the initial definition that is used for this element. */
    simpleVariableElementDefinition: SetVariableElementDefinition;
    /** boolean true if element is first from a list used in css to display labels */
    isFirstItem: boolean;
    /** list of variables that can be shown as options in the dropdown for variable name */
    optionsList: PrimitiveVariablesByScope | StructuredVariablesByScope;
    /** a String with the type of node. */
    nodeType?: string;
    /** this list of selected variables. */
    selectedVariables?: any;
    /** the current runbook variant that is being edited. */
    variant?: Variant;
}

/** Component for editing the variables list from Set simple variables node editor or the variable from Set complex variable node editor.
 *  @returns a JSX component with the scope selector and variable name selector. */
export function SetVariableElement(
    { 
        onRemoveSimpleVariableElementClicked, onChange, simpleVariableElementDefinition, selectedVariables, 
        isFirstItem, optionsList, nodeType, variant
    }: SetVariableElementProps
): JSX.Element {
    const [selectedFilter, setSelectedFilter] = useState(
        simpleVariableElementDefinition?.name ? 
            simpleVariableElementDefinition?.name.split('.')[0] === SUBFLOW_SCOPE ? RUNTIME_SCOPE : simpleVariableElementDefinition?.name.split('.')[0] : 
            RUNTIME_SCOPE
    );
    const [optionsSelected, setOptionsSelected] = useState(optionsList?.[selectedFilter] || []);
    const [selectedVarsWithoutCurrent, setSelectedVarsWithoutCurrent] = useState(selectedVariables);

    const hiddenValueInput = useRef<HTMLInputElement>(null);
    function triggerNativeOnChange() {
        if (hiddenValueInput.current) {
            setNativeValue(hiddenValueInput.current, new Date().getTime());
        }
    }

    useEffect(() => {
        setOptionsSelected(optionsList?.[selectedFilter] || []);
    }, [selectedFilter, optionsList]);


    useEffect(() => {
        setSelectedVarsWithoutCurrent(selectedVariables?.filter(item => item !== simpleVariableElementDefinition?.name));
    }, [simpleVariableElementDefinition?.name, selectedVariables]);

    const scopeOptions = variant === Variant.SUBFLOW 
        ? SUBFLOW_SCOPE_OPTIONS 
        : VARIANTS_WITH_FULL_SET_VARIABLE_SCOPES.includes(variant as Variant) ? FULL_VARIABLE_SCOPE_OPTIONS : SIMPLE_VARIABLE_SCOPE_OPTIONS;

    const sortedAvailableVariables = getSortedVariables(optionsSelected, selectedVarsWithoutCurrent);

    return <>
        <div className="d-flex flex-row align-items-end">
            <input type="text" className="d-none" ref={hiddenValueInput} defaultValue={new Date().getTime()} />
            <div className="d-flex flex-column pr-2 w-100">
                <label className={"bp3-inline" + (isFirstItem ? "" : " d-none")}>{STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.scopeLabel}</label>
                <HTMLSelect
                    aria-label="role-select"
                    fill={true}
                    options={scopeOptions}
                    value={selectedFilter}
                    onChange={(event) => {
                        setSelectedFilter(event.target.value);
                        onChange({
                            id: simpleVariableElementDefinition?.id,
                            name: ''
                        });
                    }}
                />
            </div>
            <div className="d-flex flex-column pr-2 w-100">
                <label className={"bp3-inline" + (isFirstItem ? "" : " d-none")}>{STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.varNameLabel}</label>
                <HTMLSelect
                    aria-label="variable-select"
                    fill={true}
                    value={simpleVariableElementDefinition?.name || "select-an-item"}
                    placeholder={STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.varNamePlaceholder}
                    options={sortedAvailableVariables}
                    onChange={e => {
                        onChange({
                            id: simpleVariableElementDefinition?.id,
                            name: e.currentTarget.value
                        });
                    }}
                />
            </div>
            <div className="d-flex flex-column">
                <Button
                    minimal
                    className={nodeType === 'set_structured_variable' ? 'd-none' : ''}
                    title={STRINGS.runbookEditor.nodeEditor.tooltips.removeOutput}
                    icon={IconNames.CROSS}
                    onClick={() => {
                        if (onRemoveSimpleVariableElementClicked) {
                            triggerNativeOnChange();
                            onRemoveSimpleVariableElementClicked(simpleVariableElementDefinition?.id);
                        }
                    }}
                />
            </div>
        </div>
    </>
};

/**
 * Get sorted and grouped variables into bultin/custom
 * 
 * @param optionsSelected 
 * @param selectedVarsWithoutCurrent 
 * 
 * @returns the sorted array
 */
function getSortedVariables(optionsSelected: any, selectedVarsWithoutCurrent: any) {
    const availableVariables = [
        ...optionsSelected
            ?.filter((item) => !selectedVarsWithoutCurrent?.includes(item.name))
            .map(item => { return { label: item.name, value: item.name }; })
    ];

    const groupedAvailableVariables = groupBy(availableVariables, ((el) => {
        if (!el?.label) {
            return el;
        }

        return el.label.includes('builtin') ? 'builtIn' : 'custom';
    }));

    const sortedAvailableVariables = [
        ({ label: STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.selectVariable, value: 'select-an-item' }), 
        ...sortBy(groupedAvailableVariables.custom, (el) => el.label.toLowerCase()), 
        ...sortBy(groupedAvailableVariables.builtIn, (el) => el.label.toLowerCase())
    ];

    return sortedAvailableVariables;
}

