import { NumberFieldRenderer } from 'components/common/condition-tree-builder/condition/condition-value/BuiltinValueFields';
import { getDisplayValueForItem, getValueForItem, selectItem } from 'components/common/condition-tree-builder/condition/ConditionUtils';
import React, { useState } from 'react';

export interface ValueAndUnitWrapperProps {
    /** Default value when rendering the field */
    value?: number;
    /** Default unit when rendering the field. If three are no unit options provided, then this will be displayed in a read-only state */
    unit?: string;
    /** Callback to be called when either the value or unit is changed */
    onChange?: ({ value, unit }: { value: number, unit: string }) => void;
    /** List of options to display in the units dropdown */
    unitOptions?: selectItem[];
}

/** This component is used to render a number field + unit dropdown.
 * If you wish to have a custom field instead of the number field, feel free to
 * enhance this component and add a fieldRenderer prop to do that.
 */
export function ValueAndUnitWrapper ({
    value,
    unit,
    onChange,
    unitOptions = [],
}: ValueAndUnitWrapperProps) {
    const [valueState, setValueState] = useState(value);
    const [unitState, setUnitState] = useState(unit && getValueForItem(unit));

    function fireOnChange (value, unit) {
        if (onChange) {
            onChange({value, unit});
        }
    }
    return <div
        className="value-and-unit-wrapper"
        onBlur={e => {
            if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget as Node)) {
                e.stopPropagation();
            }
        }}
    >
        <NumberFieldRenderer
            className="value-field mr-1"
            value={valueState}
            onChange={({value}) => {
                setValueState(value);
                fireOnChange(value, unitState);
            }}
        />
        {
            unitOptions.length > 1 ?
            <select
                className="unit-picker"
                value={unitState}
                onChange={(e) => {
                    const newUnit = e.currentTarget.value;
                    setUnitState(newUnit);
                    fireOnChange(valueState, newUnit);
                }}
                data-testid="unit-picker"
            >{
                unitOptions.map(unit => <option key={"o-" + unit} value={getValueForItem(unit)}>{getDisplayValueForItem(unit)}</option>)
            }</select> :
            (unitOptions.length > 0 ? getDisplayValueForItem(unitOptions[0]) : unitState)
        }
    </div>
}
