import React, { useEffect, useState } from 'react';
import { HTMLInputProps, IInputGroupProps, InputGroup} from '@blueprintjs/core';
import { useField } from 'formik';
import { FieldError } from "./FieldError";
import { Tooltip2 } from '@blueprintjs/popover2';

type InputFieldProps = IInputGroupProps & HTMLInputProps & {
    errorFieldName?: string,
    name: string,
    notifyErrors?: (name, errors) => void
};

function InputFieldWithErrorTooltip(props: InputFieldProps) {
    const { errorFieldName, notifyErrors, ...iProps } = props;
    const [hasErrors, setHasErrors] = useState(false);
    const [field, meta] = useField(iProps);

    useEffect(() => {
        if (meta && meta.touched && props.notifyErrors) {
            if(!!meta.error && hasErrors === false) {
                setHasErrors(true);
            } else if (hasErrors === true) {
                setHasErrors(false);
            }
            props.notifyErrors(field.name, meta.error);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [meta.error, meta.touched]);

    return (
        <Tooltip2
            isOpen={meta.touched && hasErrors} 
            content={<FieldError className='text-white' name={errorFieldName || field.name}/>}
            placement="bottom"
            className="w-100">
                <InputGroup id={field.name} {...field} intent={hasErrors ? 'danger' : 'none'}{...iProps} />
        </Tooltip2>
    );
}

export { InputFieldWithErrorTooltip };
