import React from "react";
import { useHistory } from "react-router";
import { STRINGS } from 'app-strings';
import { Dialog, Button, Classes, Intent } from '@blueprintjs/core';
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import { Icon, IconName, IconNames } from "@tir-ui/react-components";
import './Legal.scss';

export interface LegalProps {
    isOpen: boolean;
    title?: string;
    lastUpdated?: string;
    link?: string;
    terms: string;
    onConfirm: () => void;
}

const Legal = ({ isOpen, title, lastUpdated, link, terms, onConfirm }: LegalProps) => {
    const history = useHistory();

    return (
        <Dialog
            isOpen={isOpen}
            isCloseButtonShown={false}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            autoFocus={true}
            enforceFocus={true}
            usePortal={true}
            icon={IconNames.DOCUMENT}
            title={title ?? STRINGS.legal.title}
            aria-labelledby={title ?? STRINGS.legal.title}
        >
            <div className={Classes.DIALOG_BODY}>
                <div className="terms" dangerouslySetInnerHTML={{ __html: terms }} />
                <div className="details">
                    <span className="text-secondary">
                        {lastUpdated && `${STRINGS.legal.lastUpdated} ${lastUpdated}`}
                    </span>
                    {link &&
                        <a href={link} target="_blank" rel="noreferrer">
                            {STRINGS.legal.link}
                            <Icon icon={BPIconNames.SHARE as IconName} size={14} className="ml-2" style={{ verticalAlign: "baseline" }} />
                        </a>
                    }
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={() => history.push("/")} data-testid="declineButton">{STRINGS.legal.options.decline}</Button>
                    <Button intent={Intent.PRIMARY} onClick={onConfirm} data-testid="acceptButton">{STRINGS.legal.options.accept}</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default Legal;