import { STRINGS } from "app-strings";

export const MSALClientAuthErrorsMap = {
    popup_window_error: STRINGS.authentication.codes.popup_window_error,
    multiple_matching_tokens: STRINGS.authentication.codes.multiple_matching_tokens,
    multiple_authorities: STRINGS.authentication.codes.multiple_authorities,
    endpoints_resolution_error: STRINGS.authentication.codes.endpoints_resolution_error,
    token_renewal_error: STRINGS.authentication.codes.token_renewal_error,
    invalid_id_token: STRINGS.authentication.codes.invalid_id_token,
    invalid_state_error: STRINGS.authentication.codes.invalid_state_error,
    nonce_mismatch_error: STRINGS.authentication.codes.nonce_mismatch_error,
    login_progress_error: STRINGS.authentication.codes.login_progress_error,    
    acquiretoken_progress_error: STRINGS.authentication.codes.acquiretoken_progress_error,
    user_cancelled: STRINGS.authentication.codes.user_cancelled,
    callback_error: STRINGS.authentication.codes.callback_error,
    user_login_error: STRINGS.authentication.codes.user_login_error,
    user_non_existent: STRINGS.authentication.codes.user_non_existent,
    client_info_decoding_error: STRINGS.authentication.codes.client_info_decoding_error,
    client_info_not_populated_error: STRINGS.authentication.codes.client_info_not_populated_error,
    null_or_empty_id_token: STRINGS.authentication.codes.null_or_empty_id_token,
    id_token_parsing_error: STRINGS.authentication.codes.id_token_parsing_error,
    token_encoding_error: STRINGS.authentication.codes.token_encoding_error,
    invalid_interaction_type: STRINGS.authentication.codes.invalid_interaction_type,
    cannot_parse_cache: STRINGS.authentication.codes.cannot_parse_cache,
    block_token_requests: STRINGS.authentication.codes.block_token_requests,
};
