/** This module contains the implementation for the runbook path traversal view.  The runbook path 
 * traversal view highlights the specific path the runbook took during its execution.
 *  @module
 */
import React, { useRef, useEffect, useState } from "react";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard";
import { SIZE } from "components/enums";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { ReactFlowProvider } from 'react-flow-renderer';
import ReactFlowGraph from 'components/common/graph/react-flow/ReactFlowGraph';
import { InputType, Variant } from "components/common/graph/types/GraphTypes";
import IncidentRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/node_library.json';
import OnDemandRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/on_demand_node_library.json';
import LifecycleRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/lifecycle_node_library.json';
import SubflowRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/subflow_node_library.json';
import ExternalRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/node_library_external.json';
import { NodeLibrary, NodeLibrarySpec } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { getGraphDefFromRunbookConfig, createSubflowNodes } from 'pages/create-runbook/views/create-runbook/CreateRunbookView';
import { RunbookNode } from 'utils/services/RunbookApiService';
import { runbookService } from 'utils/runbooks/RunbookUtils';
import { RunbookIntegrationDetails } from 'pages/integrations/types/IntegrationTypes';
import { IntegrationLibraryService } from 'utils/services/IntegrationLibraryApiService';
import { useStateSafePromise } from '@tir-ui/react-components';
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { Button } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { RunbookPathTraversalNodeInfo } from "./RunbookPathTraversalNodeInfo";
import './runbook-path-traversal.scss';

export interface RunbookPathTraversalProps {
    runbook: any;
	runbookOutput: any;
}

export function RunbookPathTraversal(props: RunbookPathTraversalProps): JSX.Element {
    const [integrations, setIntegrations] = useState<RunbookIntegrationDetails[] | undefined>(undefined);
	const [dialogState, setDialogState] = useState<any>({ showDialog: false, title: "Node Info", loading: false, dialogContent: null, dialogFooter: null });
	const integrationsCache = useRef<RunbookIntegrationDetails[] | undefined>();
    const [executeSafely] = useStateSafePromise();
	useEffect(() => {
		const integrationsPromise = new Promise<RunbookIntegrationDetails[]>(async (resolve, reject) => {
			try {
				const newIntegrations = await IntegrationLibraryService.getRunbookIntegrations();
				resolve(newIntegrations as RunbookIntegrationDetails[]);    
			} catch (error) {
				reject(error);
			}
		});
		executeSafely(integrationsPromise).then(
			(integrations) => {
				integrationsCache.current = integrations;
				setIntegrations(integrations);
			}, 
			() => {
				integrationsCache.current = integrations;
				setIntegrations([]);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const [subflows, setNodeRedSubflows] = useState<Array<RunbookNode>>([]);

    const subflowNodeLibrary = new NodeLibrary(
        SubflowRunbookNodeLibrary as NodeLibrarySpec
    );

    useEffect(
		() => {
			async function fetchMyAPI() {
				let newSubflows: Array<RunbookNode> = [];
				try {
					const subflowVariant = Variant.SUBFLOW;
					const retFlows = await runbookService.getRunbooks(subflowVariant, true);
					if (retFlows) {
						for (const flow of retFlows) {
							newSubflows.push(flow);
						}
					}
				} catch (error) {
					console.log(error);
				}
				newSubflows = createSubflowNodes(
					newSubflows,
					subflowNodeLibrary,
                    integrationsCache.current
				);
				setNodeRedSubflows(newSubflows);
			}
            fetchMyAPI();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	let InitNodeLibrary: NodeLibrarySpec;
    const effectiveVariant = props.runbook.triggerType === InputType.WEBHOOK ? Variant.EXTERNAL : props.runbook.variant;
    switch (effectiveVariant) {
        case Variant.INCIDENT:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.LIFECYCLE:
            InitNodeLibrary = LifecycleRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.SUBFLOW:
            InitNodeLibrary = SubflowRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.ON_DEMAND:
            InitNodeLibrary = OnDemandRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.EXTERNAL:
            InitNodeLibrary = ExternalRunbookNodeLibrary as NodeLibrarySpec;
            break;
        default:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
    }
    
	const nodeLibrary = useRef<NodeLibrary>(
		new NodeLibrary(InitNodeLibrary as NodeLibrarySpec)
	);

    const graphDef = getGraphDefFromRunbookConfig(
        nodeLibrary.current, props.runbook, subflows, integrationsCache.current
    );

	const openTraversedNodeInfoDialog = (traversedNode) => {
		const newDialogState: any = { showDialog: true, title: "Node Info", };

		newDialogState.dialogContent = <RunbookPathTraversalNodeInfo traversedNode={traversedNode} runbookOutput={props.runbookOutput} runbookConfig={props.runbook} />;

		newDialogState.dialogFooter = <Button active={true} outlined={true} text={STRINGS.runbookOutput.okBtnText}
			onClick={() => {
				setDialogState(updateDialogState(newDialogState, false, false, []));
			}}
		/>;

		setDialogState(updateDialogState(newDialogState, true, false, []));
    };

    return <>
			<BasicDialog
				dialogState={dialogState}
				onClose={() =>
					setDialogState(updateDialogState(dialogState, false, false, []))
				}
				className="runbook-path-traversal-node-info-dialog"
			/>
			<DataLoadFacade
				loading={!integrations || !subflows.length}
				showContentsWhenLoading={true}
				className="runbook-path-traversal"
			>
				<SummaryCard
					className="pt-1 pb-1 mb-4 w-min-3-force"
					size="flex"
					height={SIZE.l}
					padding="px-3 pb-3 pt-2"
					center={false}
					hideShadow={
						INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"
					}
					hideBorder={
						INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"
					}
				>
					{integrations && subflows.length > 0 && (
						<ReactFlowProvider>
							<ReactFlowGraph
								nodeLibrary={nodeLibrary.current}
								graphDef={graphDef}
								subflows={subflows}
								static={false}
								showErrors={false}
								integrations={integrationsCache.current}
								variant={props.runbook.variant!}
								runbookPathTraversalView={true}
								nodeRunStatus={props.runbookOutput?.nodeRunStatus}
								openTraversedNodeInfoDialog={openTraversedNodeInfoDialog}
								runbookOutput={props.runbookOutput}
							/>
						</ReactFlowProvider>
					)}
				</SummaryCard>
			</DataLoadFacade>
		</>;
}
