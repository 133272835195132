/** This file defines the global data source type store.  This store keeps track of which types
 *      of data sources are currently connected to this user's tenant.
 *  @module
 */

import { loader } from 'graphql.macro';
import { Query } from 'reporting-infrastructure/data-hub';
import { dataSourceTypeOptions } from 'utils/services/DataSourceApiService';
import { client } from 'utils/services/GraphqlService';

/** the array of types that is part of this tenant. */
let types: Array<dataSourceTypeOptions> = [];
let ar11s: { id: string; name: string; hostname: string }[] = [];
/** dict for data source type retrieval */
let dst: Record<string, string> = {};

/** initializes the data source type store. */
export async function init() {
    ar11s = [];
    types = [];
    dst = {};
    // Graphql query to get the aternity configuration
    await client
        .query({
            query: new Query(
                loader(
                    './../../pages/edge-configuration/queries/datasources.graphql'
                )
            ).getGqlQuery(),
            variables: {},
            fetchPolicy: 'no-cache',
        })
        .then((results) => {
            if (results?.data?.dataSources?.length) {
                for (const config of results.data.dataSources) {
                    const sourceTypeKey: dataSourceTypeOptions = config.type as dataSourceTypeOptions;
                    const sourceTypeValue = dataSourceTypeOptions[sourceTypeKey];
                    if (dataSourceTypeOptions.ALLUVIO_AGENT !== sourceTypeValue) {
                        if (
                            Object.keys(dataSourceTypeOptions).includes(
                                sourceTypeKey
                            ) &&
                            !types.includes(sourceTypeValue)
                        ) {
                            types.push(sourceTypeValue);
                        }
                        if (
                            dataSourceTypeOptions[sourceTypeKey] ===
                            dataSourceTypeOptions.APP_RESPONSE
                        ) {
                            ar11s.push({
                                id: config.id || '',
                                name: config.name,
                                hostname: config.hostname,
                            });
                        }
                        if (sourceTypeValue) {
                            dst[config.id || 'noId'] = config.name || config.hostname;
                        }    
                    }
                }
            }
        })
        .catch(() => {
            console.error('Could not get list of data sources');
        });
}

/** returns whether or not the specified type of data source is in this tenant.
 *  @param type the type of data source to check for which is one of the dataSourceTypeOptions.
 *  @returns a boolean value which is true if the specified data source type is part of this tenant. */
export function hasDataSource(type: dataSourceTypeOptions): boolean {
    return types.includes(type);
}

/** returns whether or not the Aternity data source is in this tenant.
 *  @returns a boolean value which is true if the Aternity data source is part of this tenant. */
export function hasAternity(): boolean {
    return hasDataSource(dataSourceTypeOptions.ATERNITY);
}

/** returns whether or not the NetIm data source is in this tenant.
 *  @returns a boolean value which is true if the NetIm data source is part of this tenant. */
export function hasNetIm(): boolean {
    return hasDataSource(dataSourceTypeOptions.NET_IM);
}

/** returns whether or not the NetProfiler data source is in this tenant.
 *  @returns a boolean value which is true if the NetProfiler data source is part of this tenant. */
export function hasNetProfiler(): boolean {
    return hasDataSource(dataSourceTypeOptions.NET_PROFILER);
}

/** returns whether or not the AppResponse data source is in this tenant.
 *  @returns a boolean value which is true if the AppResponse data source is part of this tenant. */
export function hasAppResponse(): boolean {
    return hasDataSource(dataSourceTypeOptions.APP_RESPONSE);
}

/** returns the list of data source types.
 *  @returns an Array with the dataSourceTypeOptions that are in this tenant. */
export function getTypes(): dataSourceTypeOptions[] {
    return types;
}

/** returns the list of data source types.
 *  @returns an Array with the dataSourceTypeOptions that are in this tenant. */
export function getArDataSources(): { id: string; name: string }[] {
    return ar11s;
}
/** returns records to compare with explorer `datasourceType: id`
 * used in the `<IncidentSearchPage />` */
export function getDataSourceTypeSources(): Record<string, string> {
    return dst;
}
