/** This module contains the component for displaying the list of facets for a category.
 *  @module
 */
import React, { useState, useEffect } from "react";
import { AnchorButton, Button, Checkbox } from "@blueprintjs/core";
import { Facet, FacetValue, SearchRequest } from "utils/services/SearchApiService";
import { Icon } from "@tir-ui/react-components";
import { APP_ICONS } from "components/sdwan/enums";
import { STRINGS } from "app-strings";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { FacetSectionSettings, FacetSettings } from "./FacetSectionSettings";
import './FacetSection.scss';

/** a boolean value, which if true specifies that the selection options should be shown. */
const SHOW_SELECTION_OPTIONS: boolean = false;
/** a boolean value, which if true specifies that the show more options should be shown. */
const SHOW_SHOW_MORE_OPTIONS: boolean = false;

/** this interface defines the properties passed into the facet section React component. */
export interface FacetSectionProps {
    /** a string with the facet category. */
    facetCategory: string;
    /** a boolean value, if true it specifies that the facet category is artificial, meaning we created it
     *  and it did not come from search.  If this value is false or not provided then the facet came from 
     *  search. */
    isFacetCategoryArtificial?: boolean;
    /** a number with the limit for the facet category. */
    facetCategoryLimit: number;
    /** the list of facets. */
    facets: Array<Facet>;
    /** the current search request with all the filters including the facets. */
    searchRequest: SearchRequest | undefined;
    /** the search request object to use to do a search with the current time range and search string but no facets. */
    searchRequestWithNoFacets: SearchRequest | undefined;
    /** the handler for facet selection changes. */
    onFacetSelected?: (facetValue: FacetValue, category: string, selected: boolean) => void;    
    /** the handler for facet category selections. */
    onFacetCategorySelected?: (facetCategory: string, selected: boolean) => void;
    /** the handler for facet settings changes. */
    onFacetSettingsChanged: (facetCategory: string, facetSettings: FacetSettings) => void;
    /** a boolean value, which if true specifies we should sort selected facets at the top.  If false sort by count and alphabetically only. */
    sortSelectedFacetsFirst?: boolean;
}

/** a constant with the increment to use when increasing the number of facets with the show more button. */
const FACET_DISPLAY_INC: number = 5;

/** Renders the facet section view.
 *  @param props the properties passed in.
 *  @returns JSX with the facet section view component.*/
export const FacetSection = React.forwardRef((props: FacetSectionProps, ref: any): JSX.Element =>{
    const [expanded, setExpanded] = useState<boolean>(false);
    const [facetDisplayCount, setFacetDisplayCount] = useState<number>(FACET_DISPLAY_INC);
    const facetValueJsx: Array<JSX.Element> = [];

    const initDialogState = {
        showDialog: false,
        title: `Filter by ${
            STRINGS.incidentSearch.facetView.facets[props.facetCategory]
                ?.name
                ? STRINGS.incidentSearch.facetView.facets[
                      props.facetCategory
                  ]?.name
                : props.facetCategory
        }`,
        loading: false,
        dialogContent: <></>,
        dialogFooter: <></>,
    };
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    useEffect(() => {
            ref({
                expandSection: () => {
                    setExpanded(true);
                },
                collapseSection: () => {
                    setExpanded(false);
                }
            });

            // Check to see if any facets are currently selected
            if (props.facets.length) {
                for (const facet of props.facets) {
                    if (facet.selected) {
                        setExpanded(true);
                        break;
                    }
                }
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (expanded) {
        // If the section is expanded show it
        const maxFacetIndex: number = SHOW_SHOW_MORE_OPTIONS ? Math.min(facetDisplayCount, props.facets.length) : props.facets.length ;
        if (props.facets.length) {
            // Get the displayed facets and sort them selected first, then highest count, then alphabetically
            const displayedFacets = props.facets.slice(0, maxFacetIndex + 1);
            displayedFacets.sort((a: Facet, b: Facet) => {
                if (props.sortSelectedFacetsFirst && a.selected !== b.selected) {
                    return a.selected ? -1 : 1;
                } else if (a.count !== b.count) {
                    return a.count > b.count ? -1 : 1;
                } else {
                    return (a?.value || "").toString().localeCompare((b?.value || "").toString());
                }
            });
            for (let facetIndex = 0; facetIndex < displayedFacets.length; facetIndex++) {
                const facetValue = displayedFacets[facetIndex];
                const displayName = STRINGS.incidentSearch?.facetView?.facets[props.facetCategory] && STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap &&
                    facetValue.value !== null && STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap[facetValue.value.toString()]
                    ? STRINGS.incidentSearch?.facetView?.facets[props.facetCategory].valueDisplayMap[facetValue.value.toString()] : facetValue.value;
                //facetValueJsx.push(<div key={facetValue.value} className="ml-4">{facetValue.value} ({facetValue.count})</div>);
                const countSpan = <><span className="facet-label" title={String(facetValue.value)}>{displayName || STRINGS.incidentSearch.facetView.facetXcases.Unset}</span> <span className="facet-label-count ml-2">{facetValue.count > 0 ? " (" + facetValue.count + ")" : ""}</span></>;
                facetValueJsx.push(
                    <Checkbox type="checkbox" id={facetValue.value + ""} name={facetValue.value + ""} key={(facetValue.value || "").toString()}
                        className="text-nowrap facet-checkbox d-flex"
                        labelElement={countSpan}
                        //defaultChecked={facetValue.selected === true}
                        checked={facetValue.selected === true}
                        onChange={(event: any) => {
                            if (props.onFacetSelected) {
                                props.onFacetSelected(facetValue.value, props.facetCategory, event.target.checked);
                            }
                        }}
                    />
                );
            }        
        }
    }
    return <>
        <BasicDialog dialogState={dialogState} className="facet-section-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <div data-testid="FaceSection-element" key={props.facetCategory} className="my-2 facet-section-container">
            <div className="facet-section-header display-9 font-weight-normal"> 
                <span onClick={() => setExpanded(!expanded)} className="text-nowrap mr-2" style={{cursor: "pointer"}}>
                    <Icon icon={expanded ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                    <span className="ml-2">{STRINGS.incidentSearch.facetView.facets[props.facetCategory]?.name || props.facetCategory}</span>
                </span>
                {!!props.facets.length && <div>
                    <a href="/#" onClick={(e) => {
                        e.preventDefault();
                        if (props.onFacetCategorySelected) {
                            props.onFacetCategorySelected(props.facetCategory, false);
                        }
                    }} className={"display-9 px-2 font-weight-normal facet-section-icon facet-clear" + (expanded ? " expanded" : "")}>Clear</a>
                    {!props.isFacetCategoryArtificial && <a href="/#" onClick={(e) => {
                        e.preventDefault();
                        showSearchDialog(
                            dialogState, setDialogState, props.facetCategory, props.facets, props.facetCategoryLimit,
                            props.searchRequest, props.searchRequestWithNoFacets, props.onFacetSettingsChanged
                        );
                    }} className={"display-9 font-weight-normal facet-section-icon facet-view-all" + (expanded ? " expanded" : "")}>View all</a>}
                </div>}
            </div>
            <div className="facet-checkbox-list display-9">
                {facetValueJsx}
            </div>
            {SHOW_SHOW_MORE_OPTIONS && props.facets.length > facetDisplayCount && expanded && <div>
                <AnchorButton text="Show More" minimal={true} onClick={() => setFacetDisplayCount(facetDisplayCount +  FACET_DISPLAY_INC)} />
                <AnchorButton text="Show All" minimal={true} onClick={() => setFacetDisplayCount(props.facets.length)} />
            </div>}
            {SHOW_SELECTION_OPTIONS && props.facets.length > 1 && expanded && <div>
                <AnchorButton text="Select All" minimal={true} 
                    onClick={() => {
                        if (props.onFacetCategorySelected) {
                            props.onFacetCategorySelected(props.facetCategory, true);
                        }
                    }} 
                />
                <AnchorButton text="Deselect All" minimal={true} 
                    onClick={() => {
                        if (props.onFacetCategorySelected) {
                            props.onFacetCategorySelected(props.facetCategory, false);
                        }
                    }}
                />
            </div>}
        </div>
    </>;
});

/** Creates a popup that displays the section settings/search dialog.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. 
 *  @param facetCategory a String with the facet category name. 
 *  @param facets an Array with the facet list. 
 *  @param facetCategoryLimit a number with the limit on the number of facet values to retrieve for the category. 
 *  @param searchRequest the full search request with all the filters.
 *  @param searchRequestWithNoFacets the search request object to use to do a search with the current time range and search string but no facets.
 *  @param onFacetSettingsChanged a function that handles the facet category limit change. */
function showSearchDialog(
    dialogState: any, 
    setDialogState: (dialogState: any) => void,
    facetCategory: string, 
    facets: Array<Facet>, 
    facetCategoryLimit: number,
    searchRequest: SearchRequest | undefined, 
    searchRequestWithNoFacets: SearchRequest | undefined,
    onFacetSettingsChanged?: (facetCategory: string, facetSettings: FacetSettings) => void
): void {
    let outputValue: FacetSettings = {facetCategoryLimit, facetSearchString: ""};
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <FacetSectionSettings 
        facetCategory={facetCategory} 
        facets={facets} 
        facetSettings={{facetCategoryLimit, facetSearchString: ""}} 
        searchRequest={searchRequest} 
        searchRequestWithNoFacets={searchRequestWithNoFacets}
        onFacetSettingsChanged={(facetSettings: FacetSettings) => {
            outputValue = facetSettings;
        }}
        searchType={searchRequest?.type || "Incident"}
    />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                if (onFacetSettingsChanged) {
                    onFacetSettingsChanged(facetCategory, outputValue);
                }
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}
