/** This module contains the implementation for the lifecycle runbook view.  The lifecycle runbook view
 *  displays to the user the contents of a lifecycle runbook.
 *  @module
 */
import React, { useEffect } from "react";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { RunbookOutput, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { SEVERITY } from "components/enums";
import { processDataset } from "utils/runbooks/RunbookUtils";
import { translateDatasetSchema } from "pages/riverbed-advisor/views/runbook-view/RunbookView";
import classNames from "classnames";
import { ErrorDialogContent } from "pages/incident-details/views/runbook-outputs-tab/ErrorDialogContent";
import { checkForErrorsAndDebug } from "./ViewRunbookView";

/** an interface that describes the properties that can be passed in to the component.*/
export interface LifecycleRunbookViewProps {
    /** a string with the unique id of the incident.*/
    incidentId?: string;
    /** a string with the unique id of the runbook. */
    runbookId?: string;
    /** a callback that is called when the runbook output has been received which passes the runbook to the callback. */
    onRunbookReceived?: (runbook: RunbookOutput) => void;
    /** a callback that is called when the runbook data has been received which passes the data to the callback. */
    onRunbookDataReceived?: (data: Array<DataSet>) => void;
    /** CSS classes to be applied to the runbook view container node. */
    className?: string;
}
 
/** Creates the lifecycle runbook view that displays the data from a lifecycle runbook.
 *  @param props an object with the properties passed to the lifecycle runbook view.
 *  @returns JSX with the lifecycle runbook component.*/
export const LifecycleRunbookView = (props: LifecycleRunbookViewProps): JSX.Element => {
    let {loading, data, error, run} = useQuery({
        query: new Query(loader("./../../../riverbed-advisor/views/runbook-view/runbooks.graphql")),
        requiredFilters: [FILTER_NAME.incidentIds, FILTER_NAME.runbookId],
        filters: {
            [FILTER_NAME.incidentIds]: (props.incidentId ? [props.incidentId] : undefined),
            [FILTER_NAME.runbookId]: (props.runbookId ? props.runbookId : undefined)
        },
        skipGlobalFilters: true,
        timeNotRequired: true,
        lazy: true,
    });

    useEffect(() => {
        if (props.incidentId && props.runbookId) {
            run({
                filters: {
                    [FILTER_NAME.incidentIds]: [props.incidentId],
                    [FILTER_NAME.runbookId]: props.runbookId,
                },
                // Always fetch runbook data fresh because it could've changed since the last pull
                fetchPolicy: "network-only",
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentId, props.runbookId])

    let unprocessedRunbook: RunbookOutput | null = null;
    let runbook: RunbookOutput | null = null;
    let template;
    let datasets;
    if (!loading) {
        if (data && data?.runbooks?.nodes?.length > 0) {
            const runbookIndex = 0;
            unprocessedRunbook = data.runbooks.nodes[runbookIndex];
            runbook = JSON.parse(JSON.stringify(unprocessedRunbook));
            if (!runbook) {
                runbook = {id: "0", targetId: "1", name: "", severity: {value: SEVERITY.CRITICAL}, datasets: []};
            }
            runbook.targetId = "1";
            runbook.severity = {value: SEVERITY.CRITICAL};
            runbook.datasets = [];

            const dsFromQuery = data.runbooks.nodes[runbookIndex]!.datasets;

            if (typeof dsFromQuery === "string") {
                // This was from the first version of the Aternity demo, the datasets were passed as a string
                // keeping for a few days in case we need to go back.
                datasets = JSON.parse(dsFromQuery as string) as Array<DataSet>;
            } else if (typeof dsFromQuery === "object") {
                // This is the second version where the datasets are passed as an object but the schema is 
                // different than the schema in the DB.  We have two places where the runbook is viewed one
                // in test mode which gets the data directly from the runbook and is in the exact same format
                // as the DB and another when we get the data from the DB through graphql which has a format 
                // that can be supported by the graphql schema.  Since there are two formats modify the graphql
                // format to match the format we get directly from the API and leave all downstream code untouched.
                datasets = translateDatasetSchema(dsFromQuery);
            }

            template = JSON.parse(data.runbooks.nodes[runbookIndex]!.template);
            if (template && template.label) {
                runbook.name = template.label;
            } 

            if (datasets && datasets.length > 0 && template) {
                for (const dataset of datasets) {
                    const processedDataset = processDataset(dataset, template.nodes);
                    if (processedDataset) {
                        runbook.datasets.push(processedDataset);
                    }
                }
            }
        }
    }

    const {onRunbookReceived, onRunbookDataReceived} = props;
    useEffect(() => {
        if (datasets && onRunbookDataReceived) {
            onRunbookDataReceived(datasets);
        }
        if (unprocessedRunbook && onRunbookReceived) {
            onRunbookReceived(unprocessedRunbook);
        }
    }, [unprocessedRunbook, datasets, onRunbookReceived, onRunbookDataReceived]);

    const {hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug} = unprocessedRunbook ? checkForErrorsAndDebug(unprocessedRunbook) : {hasError: false, hasWarning: false, hasVariables: false, hasInput: false, hasIndicators: false, hasDebug: false};
    return <DataLoadFacade loading={loading} error={error} data={data} className={classNames("runbook-view-data-load", props.className)}>
        {(hasError || hasWarning || hasVariables || hasInput || hasIndicators || hasDebug) && unprocessedRunbook && <div className="bg-light rounded p-3 mb-3">
            <ErrorDialogContent runbook={unprocessedRunbook} />
        </div>}    
    </DataLoadFacade>;
};
