import React from 'react';
import './SinglePageLayout.scss'
import logo from 'assets/images/branding/logo-public.svg';
import { STRINGS } from 'app-strings';
import moment from 'moment';

export type LogoProps = {
    show: boolean
    defaultMessage?: boolean
    welcomeMessage?: string
}

export type SinglePageProps = {
    logo?: LogoProps,
    children: React.ReactNode,
    footer?: React.ReactNode
}

const renderLogo = (logoProps?: LogoProps) => {
    if (logoProps && logoProps.show) {
        return (
            <div className="riverbed-logo">
                <img src={logo} alt="Riverbed"/>
                {
                    renderWelcomeText(logoProps)
                }
            </div>
        );
    }
};

const renderFooter = (footer) => {

    // consumer can override the footer or just remove it by passing null
    if (typeof footer !== 'undefined') {
        return footer;
    }

    return (
        <div className="footer">
            &copy; 2020-{moment().year()} {STRINGS.copyrightText} <a href="https://www.riverbed.com/document/fpo/legal/riverbed-privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
                {STRINGS.privacyPolicy}
            </a>
        </div>
    );
};

const renderWelcomeText = (logoProps) => {
    let message = STRINGS.welcomeMessage;
    //if defaultMessage flag is false then assign the message sent by user
    if (!logoProps.defaultMessage) {
        message = logoProps.welcomeMessage;
    }
    //Show message only if it exists
    if (message) {
        return (
            <div>{message}</div>
        );
    }
};

/** Renders the single page layout.
 *  @param props the properties passed in.
 *  @returns JSX with the single page layout component.*/
export const SinglePageLayout = (props: SinglePageProps): JSX.Element => {
    const { logo, children, footer } = props;

    return (
        <div className="single-page-layout">
            <div className="cc-accent-line"/>
            { renderLogo(logo) }
            { children }
            { renderFooter(footer)}
        </div>
    )
};
