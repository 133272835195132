/** This module contains the component for creating the list of client id and secrets.
 *  @module
 */
import { Button, ButtonGroup, Intent, Menu, MenuItem, Position } from "@blueprintjs/core";
import {
    Classes,
    Popover2,
    Popover2InteractionKind,
    Tooltip2,
} from "@blueprintjs/popover2";
import {
    ErrorToaster,
    Icon,
    IconNames,
    SuccessToaster,
    Table,
    TableColumnDef,
} from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import React, { useCallback, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useQuery } from "utils/hooks";
import { Query } from "reporting-infrastructure/types/Query";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { openConfirm, openModal } from "components/common/modal";
import moment from "moment";
import { ClientAppData, ClientAppSecret } from "../types/ApiAccessTypes";
import { SDWAN_ICONS } from "components/sdwan/enums";
import { TIME_FORMAT } from "components/enums/Time";
import { ManageClientSecretsModal } from "components/modals/api-access/ManageClientSecretsModal/ManageClientSecretsModal";
import GenerateClientSecretsStepTwoModal from "components/modals/api-access/GenerateClientSecretsStepTwoModal";

interface DeleteApplicationClientMutationInput {
    id: string;
}

/** Renders the api access list view.
 *  @param props the properties passed into the component.
 *  @returns JSX with the api access list view component.*/
export function ApiAccessListView(props): JSX.Element {
    /** this constant has the environment scope and uri that the UI is being executed in */
    const { APIACCESS_ENDPOINT_URL, APIACCESS_ENDPOINT_SCOPE } = window[
        "runConfig"
    ]
        ? window["runConfig"]
        : { APIACCESS_ENDPOINT_URL: "", APIACCESS_ENDPOINT_SCOPE: "" };

    const { loading, data, error, run } = useQuery({
        name: "apiClientApplications",
        query: new Query(
            loader("./../queries/client-applications.graphql")
        ),
    });

    const refreshData = () => run({ noCache: true });
    const useRefs = () => {
        const refsByKey = useRef<Record<string, HTMLElement | null>>({});

        const setRef = (element: HTMLElement | null, key: string) => {
            refsByKey.current[key] = element;
        };

        return { refsByKey: refsByKey.current, setRef };
    };
    const { refsByKey, setRef } = useRefs();
    const modalKeys = {
        manage: "manage-client-secrets",
        info: "client-secrets-info",
    };

    const [deleteClientApplication] = useMutation<
        any,
        DeleteApplicationClientMutationInput
    >(loader("./../queries/delete-client-application-mutation.graphql"), {
        onCompleted: () => {
            SuccessToaster({
                message: STRINGS.apiAccess.toastMessages.successDeleteClient,
            });
            refreshData();
        },
        onError: (err) => {
            ErrorToaster({
                message: STRINGS.apiAccess.toastMessages.errorDeleteClient,
            });
            console.error(err?.message);
        },
    });

    const onDeleteClient = useCallback(
        (clientId: string) => {
            openConfirm({
                message: STRINGS.apiAccess.confirmClientDeleteMessage,
                onConfirm: () => {
                    return deleteClientApplication({
                        variables: {
                            id: clientId,
                        },
                    }).catch((error) => {
                        ErrorToaster({
                            message: error.message,
                        });
                    });
                },
                icon: IconNames.TRASH,
                intent: Intent.PRIMARY,
            });
        },
        [deleteClientApplication]
    );

    const getMoreMenuItems = useCallback((row) => {
        const moreMenuItems: Array<JSX.Element> = [
            <MenuItem
                disabled={false}
                text={STRINGS.apiAccess.more.manage}
                active={false}
                key={"manage"}
                onClick={() => openClientSecretsModal(row, modalKeys.manage)}
            />,
            <MenuItem
                disabled={false}
                text={STRINGS.apiAccess.more.delete}
                active={false}
                key={"revoke"}
                onClick={() => {
                    onDeleteClient(row.id);
                }}
            />,
        ];

        return moreMenuItems;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: Array<TableColumnDef> = [
        {
            id: "name",
            Header: STRINGS.apiAccess.columns.name,
            accessor: "name",
            style: { maxWidth: "350px", overflow: "hidden" },
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "d-none d-xl-table-cell",
            sortable: true,
            Cell: (row) => <span className="text-justify">{row.value}</span>,
        },
        {
            id: "expiration",
            Header: STRINGS.apiAccess.columns.expires,
            className: "display-9",
            sortable: true,
            sortFunction: (a, b) => {
                const expirationA = +getEarliestExpirationDate(a.secrets);
                const expirationB = +getEarliestExpirationDate(b.secrets);

                return expirationA === expirationB
                    ? 0
                    : expirationA > expirationB
                    ? 1
                    : -1;
            },
            formatter: (row) => {
                return (
                    <span className="d-inline-block text-justify">
                        {renderExpirationColumn(row.secrets)}
                    </span>
                );
            },
        },
        {
            id: "id",
            Header: STRINGS.apiAccess.columns.clientId,
            className: "display-9",
            formatter: (row) => {
                return (
                    <>
                        <span className="d-inline-block text-justify">
                            {row.id}
                        </span>
                        <Tooltip2
                            className={Classes.TOOLTIP2_INDICATOR + " border-0"}
                            content={STRINGS.apiAccess.copyToClipboard}
                        >
                            <CopyToClipboard text={row.id} className="ml-2">
                                <Button
                                    small
                                    icon={IconNames.DUPLICATE}
                                    onClick={() => {}}
                                />
                            </CopyToClipboard>
                        </Tooltip2>
                    </>
                );
            },
        },
        {
            id: "secrets",
            Header: STRINGS.apiAccess.columns.noOfSecrets,
            className: "display-9",
            formatter: (row) => {
                return (
                    <span className="d-inline-block text-justify">
                        {row.secrets.length}
                    </span>
                );
            },
        },
        {
            id: "menu",
            Header: "",
            accessor: "menu",
            formatter: (row) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Popover2
                            position={Position.BOTTOM_LEFT}
                            interactionKind={Popover2InteractionKind.CLICK}
                            content={<Menu>{getMoreMenuItems(row)}</Menu>}
                        >
                            <Button
                                aria-label="api-access-more-button"
                                icon={IconNames.MORE}
                                minimal
                                className="runbook-action-icon"
                                disabled={false}
                                onClick={(e) => {}}
                            />
                        </Popover2>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {renderEndpointScopeBanner()}
            <DataLoadFacade
                loading={loading}
                error={error}
                data={[]}
                showContentsWhenLoading={false}
            >
                <>
                    <ButtonGroup>
                    <Button
                        aria-label="api-access-new-button"
                        icon={IconNames.ADD}
                        onClick={() =>
                            openModal("generateClientSecretsStepOneModal", {
                                customLabels: {
                                    submit: STRINGS.apiAccess.modalButtons
                                        .submitStepOne,
                                },
                                onSuccess: () => {
                                    refreshData();
                                },
                                handleOpenSecondStep: (data) =>
                                    openClientSecretsModal(
                                        data,
                                        modalKeys.info
                                    ),
                            })
                        }
                        text={STRINGS.apiAccess.add}
                    />
                    </ButtonGroup>
                    <Table
                        id="api-access-list"
                        className="display-9 mt-4"
                        columns={columns}
                        columnDefinitionDefaults={{
                            headerClassName: "text-nowrap",
                        }}
                        data={
                            data?.clientApplications
                                ? data.clientApplications
                                : []
                        }
                        defaultPageSize={10}
                        enableSelection={false}
                        selectOnRowClick={false}
                        sortBy={[{ id: "expiration" }]}
                    />
                </>
            </DataLoadFacade>
            <GenerateClientSecretsStepTwoModal
                ref={(element: HTMLElement) => setRef(element, modalKeys.info)}
                handleErrors={handleErrors}
            />
            <ManageClientSecretsModal
                ref={(element: HTMLElement) =>
                    setRef(element, modalKeys.manage)
                }
                handleErrors={handleErrors}
            />
        </div>
    );

    /**
     * Open a given modal
     *
     * @param data Data for the modal
     * @param key Specifies if it shoud open ClientSecretsInfo modal, used when creating a new client app
     *            or opens the Manage Client Secrets modal, used when the Client App is pre-existing
     */
    function openClientSecretsModal(data: ClientAppData, key: string) {
        const clientData =
            key === modalKeys.info ? data?.createClientApplication : data;
        const modal = refsByKey[key];

        // @ts-ignore
        modal.setClientData(clientData);
        if (key === modalKeys.manage) {
            // @ts-ignore
            modal.setActions({
                triggerRefresh: refreshData,
            });
        }
        // @ts-ignore
        modal.handleOpen();
    }

    /**
     * The errors are handled inside each modal, so no need to handle them here also
     *
     * @param error
     */
    function handleErrors(error) {
        console.error(error);
    }

    /**
     * @param secrets array containing client secrets
     *
     * @returns earliest expiration date as string
     */
    function getEarliestExpirationDate(secrets: ClientAppSecret[]) {
        const earliestDate = Math.min.apply(
            null,
            secrets.map((secret) => secret.expiration)
        );

        return earliestDate;
    }

    /**
     *
     * @param secrets - the secrets array of the client app
     * @returns a span containing the earliest expiration date, and if it is expired, also a
     *          tooltip with a warning icon
     */
    function renderExpirationColumn(secrets: ClientAppSecret[]) {
        if (!secrets) {
            return STRINGS.apiAccess.expirationDateNA;
        }

        const currentDate = moment().valueOf();
        const expirationDate = getEarliestExpirationDate(secrets);
        const isSecretExpired = currentDate > expirationDate.valueOf() * 1000;
        const formattedExpirationDate = moment(expirationDate * 1000).format(
            TIME_FORMAT.DISPLAY_TIME_FORMAT
        );

        if (isSecretExpired) {
            return (
                <Tooltip2
                    className={Classes.TOOLTIP2_INDICATOR + " border-0"}
                    content={STRINGS.apiAccess.expiredSecretTooltip}
                >
                    <span className="m-1 mt-3">
                        <Icon
                            icon={SDWAN_ICONS.ALERT}
                            className="bp3-intent-danger mr-2"
                        />
                        {formattedExpirationDate}
                    </span>
                </Tooltip2>
            );
        }

        return <span>{formattedExpirationDate}</span>;
    }

    /**
     * Render the endpoint scope/uri information banner
     *
     * @returns JSX for the endpoint banner
     */
    function renderEndpointScopeBanner() {
        return (
            <div className="my-4">
                {STRINGS.apiAccess.endpointScope.partOne} <br />
                {STRINGS.apiAccess.endpointScope.partTwo}

                <span className="font-italic">
                    {APIACCESS_ENDPOINT_URL}
                    <CopyToClipboard
                        text={APIACCESS_ENDPOINT_URL }
                        className="ml-1 font-italic"
                    >
                        <Button
                            small
                            minimal
                            icon={IconNames.DUPLICATE}
                            onClick={() => {}}
                        />
                    </CopyToClipboard>
                </span>

                {STRINGS.apiAccess.endpointScope.partThree}

                <span className="font-italic">
                    {APIACCESS_ENDPOINT_SCOPE}
                    <CopyToClipboard
                        text={ APIACCESS_ENDPOINT_SCOPE }
                        className="ml-1"
                    >
                        <Button
                            small
                            minimal
                            icon={IconNames.DUPLICATE}
                            onClick={() => {}}
                        />
                    </CopyToClipboard>
                </span>
                {")"}
            </div>
        );
    }
}
