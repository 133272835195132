/** This module contains the component that displays a dialog that allows the user to configure the 
 *  trend analysis and then shows the trend analysis chart for the capacity planning demo.  There is 
 *  another version of the dialog that has no configuration and just shows the trend chart.  That dialog 
 *  is called the CapacityPlanningDialog.
 *  @module
 */
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { ButtonProps, DialogStep, Intent, MultistepDialog } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';
import StepThreshold from './StepThreshold';
import StepResults from './StepResults';
import './CapacityPlanningModal.scss';

/** Renders the capacity planning dialog React component.
 *  @param props the properties passed in.
 *  @returns JSX with the capacity planning modal component.*/
const CapacityPlanningModal = React.forwardRef((props: any, ref): JSX.Element => {
    React.useImperativeHandle(ref, () => ({
        handleOpen(editInfos) {
            setIsOpen(!isOpen);
            setEditInfos(editInfos);
        },
    }));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editInfos, setEditInfos] = useState<any>(null);
    const isWizardSubmit = useRef<boolean>(false);
    const handleClose = useCallback(() => {
        setIsOpen(false);
        setThreshold1(80);
        setThreshold2(100);
        inputs.current = {};
        setEditInfos(null);
        setHasValidInputs(true);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    //const wizardStrings = STRINGS.capacityPlanning.wizard;

    const [threshold1, setThreshold1] = useState<number>(80);
    const [threshold2, setThreshold2] = useState<number>(100);
    const inputs = useRef<any>({});

    const /*[hasValidInputs, setHasValidInputs]*/ setHasValidInputs = useState<boolean>(true)[1];

    useEffect(() => {
        /*
        if (editInfos && isOpen) {
            const { name, startOn, repeat, every, daysOfWeek } = editInfos.details;
            setSchedule({ name, startOn, repeat, every, daysOfWeek });
            setDescription(editInfos.schedule.description);
        }
        */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, editInfos]);

    const handleSubmit = async () => {
        isWizardSubmit.current = true;

        handleClose();
    }

    const finalButtonProps: Partial<ButtonProps> = {
        intent: Intent.PRIMARY,
        loading: false,
        disabled: false,
        onClick: handleSubmit
    };

    /*
    const validateInputs = (inputs) => {
        if (inputs?.variableOverrides && inputs?.variableTypes) {
            let hasInvalidBoolean = false;
            let hasInvalidIpAddress = false;
            for (const [key, value] of Object.entries(inputs.variableOverrides)) {
                if (inputs.variableTypes[key] === "boolean" && value && !["true", "false"].includes(value as string)) {
                    hasInvalidBoolean = true;
                } else if (inputs.variableTypes[key] === "ipaddr" && value && !validIP(value as string)) {
                    hasInvalidIpAddress = true;
                }
            }
            if ((hasInvalidBoolean || hasInvalidIpAddress || inputs.errors?.length)) {
                return false;
            }
            return true;
        }
        return true;
    };
    */

    return (
        <>
            <div data-testid="CapacityPlanning-modal" id="capacity-planning-modal">
                <MultistepDialog
                    className="capacity-planning-modal"
                    title={STRINGS.capacityPlanning.wizard.title}
                    autoFocus
                    canEscapeKeyClose
                    enforceFocus
                    isCloseButtonShown
                    resetOnClose
                    canOutsideClickClose={false}
                    isOpen={isOpen}
                    onClose={handleClose}
                    finalButtonProps={finalButtonProps}
                >
                    <DialogStep
                        id="select"
                        title={STRINGS.capacityPlanning.wizard.stepSelectThreshold.title}
                        panel={<StepThreshold 
                            threshold1={threshold1} setThreshold1={setThreshold1} threshold2={threshold2} setThreshold2={setThreshold2}
                            inputs={inputs} editInfos={editInfos} 
                        />}
                        nextButtonProps={{ disabled: false/*!runbookID*/ }}
                    />
                    <DialogStep
                        id="run"
                        title={STRINGS.capacityPlanning.wizard.stepResults.title}
                        panel={<StepResults 
                            threshold1={threshold1} threshold2={threshold2} 
                            inputs={inputs} editInfos={editInfos} 
                        />}
                        nextButtonProps={{ disabled: false }}
                    />
                </MultistepDialog>
            </div>
        </>
    )
});

export { CapacityPlanningModal }
