/** This module contains the component for rendering the cloud status card.
 *  @module
 */
import React from 'react';
import { Icon } from '@tir-ui/react-components';
import { SIZE } from 'components/enums';
import {
	HealthStatuses,
	HEALTH_STATUSES,
} from 'pages/health-monitor/views/HealthMonitorCardsView';
import { SummaryCard } from 'components/common/layout/summary-card/SummaryCard';
import { loader } from 'graphql.macro';
import { useQuery } from 'utils/hooks';
import { Query } from 'reporting-infrastructure/types/Query';
import {
	DURATION,
	durationToRoundedTimeRange,
} from 'utils/stores/GlobalTimeStore';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';

import { STRINGS } from 'app-strings';

/** Renders the cloud status card.
 *  @returns JSX with the cloud status card.*/
const CloudStatusCard = (): JSX.Element => {
	const { loading, data, error } = useQuery({
		name: 'Analytics',
		query: new Query(loader('./cloud-status.graphql')),
		// @ts-ignore
		queryVariables: {
			...durationToRoundedTimeRange(DURATION.DAY_1),
		},
	});

	let componentsCount: number = 0;
	let componentsOk: number = 0;
	let statusText: string;
	let statusState: string;
    let anyUnderMaintenance: boolean = false;

	if (
		!loading &&
		data &&
		'monitor' in data &&
		'cloudStatus' in data['monitor']
	) {
		(data['monitor']['cloudStatus'] as []).forEach((element) => {
			if (element['status'] === HEALTH_STATUSES.OPERATIONAL) {
				componentsOk++;
			}
			componentsCount++;
            anyUnderMaintenance = anyUnderMaintenance || (element['status'] === HEALTH_STATUSES.MAINTENANCE);
		});
	}

    if (anyUnderMaintenance) {
        statusState = HEALTH_STATUSES.MAINTENANCE;
        statusText =
            STRINGS.HEALTH_MONITORING_PAGE.healthStatus.maintenance
                .description;
    } else {
        switch (true) {
            case componentsOk === componentsCount:
                statusState = HEALTH_STATUSES.OPERATIONAL;
                statusText =
                    STRINGS.HEALTH_MONITORING_PAGE.healthStatus.operational
                        .description;
                break;
            case componentsOk === componentsCount - 1:
                statusState = HEALTH_STATUSES.DEGRADED;
                statusText =
                    STRINGS.HEALTH_MONITORING_PAGE.healthStatus.degraded
                        .description;
                break;
            case componentsOk >= 0 && componentsOk < componentsCount - 1:
                statusState = HEALTH_STATUSES.MAJOR_OUTAGE;
                statusText =
                    STRINGS.HEALTH_MONITORING_PAGE.healthStatus.majorOutage
                        .description;
                break;
            default:
                statusState = HEALTH_STATUSES.UNKNOWN;
                statusText =
                    STRINGS.HEALTH_MONITORING_PAGE.healthStatus.unknown.description;
        }
    }

	return (
		<React.Fragment>
			<DataLoadFacade
				key="cloud-status"
				loading={loading}
				error={error}
				data={data}
			>
				<SummaryCard
					title={
						<span className="text-black">
							{
								STRINGS.HEALTH_MONITORING_PAGE.cards.cloudStatus
									.title
							}
						</span>
					}
					size="flex"
					height={SIZE.m}
					className="w-min-2"
					footerContent={
						<React.Fragment>
							<a
								href={
									'https://support.riverbed.com/content/support/software/saas-platform-status.html'
								}
								className="text-decoration-underline"
								target="_blank"
								rel="noreferrer"
							>
								<Icon
									icon={HealthStatuses[statusState].icon}
									iconSize={14}
									className={
										'mr-2 align-middle tile-icon ' +
										HealthStatuses[statusState].classname
									}
								/>
								<span className="text-decoration-underline">
									{HealthStatuses[statusState].name}
								</span>
							</a>
						</React.Fragment>
					}
				>
					<div className="mt-3 align-middle">{statusText}</div>
				</SummaryCard>
			</DataLoadFacade>
		</React.Fragment>
	);
};

export { CloudStatusCard };
