/** This file defines the TimeseriesView React component.  The TimeseriesView React component wraps either a
 *  TimeChart or MultiTimeSeriesChart component and includes the query to get the data.
 *  @module */
import React from "react";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { BaseRunbookViewProps, Column, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { TimeChart, TimeChartDatum } from "components/common/time-chart/TimeChart";
import { formatKeys } from "utils/runbooks/RunbookFormatter";
import { formatValue, getComparisonParameters, getTimestamp, keysMatch } from "utils/runbooks/RunbookOutputUtils";
import { LegendPosition, LineStyle } from "components/common/chart-base/ChartToolbar";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { GroupMetricEvent } from "components/common/chart-base/ChartBase";

/** an interface that describes the properties that can be passed in to the component.*/
export interface TimeseriesViewProps extends BaseRunbookViewProps {
    // Add any properties that are specific to this view
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
}

/** Creates the timeseries chart view, which is a component that displays a chart with analytics data.
 *  @param props an object with the properties passed to the time series view.
 *  @returns JSX with the time series chart component.*/
export const TimeseriesView = (props: TimeseriesViewProps): JSX.Element => {
    let {comparisonOffset = 0, comparisonSuffix = ""} = getComparisonParameters(props.widget?.options?.comparedTo as string);
    let primaryDataset: DataSet | undefined = undefined;
    let comparisonDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            } else {
                comparisonDataset = dataset;
            }
        }
    }
    const style: LineStyle | undefined = props.widget?.options?.style ? props.widget?.options?.style as any : comparisonDataset ? LineStyle.line : undefined;
    const showLegend = props.widget?.options?.showLegend !== undefined ? props.widget.options.showLegend as boolean : true;
    const legendPosition = props.widget?.options?.legendPosition ? props.widget?.options?.legendPosition as any : LegendPosition.top;
    const selectedMetrics: Array<string> | undefined = props.widget?.options?.metrics as Array<string> | undefined;

    let { loading, data, error } = useQuery({
        query: new Query(loader("./timeseries-query.graphql")),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
		},
        skipGlobalFilters: true
    });

    const tsKeyData: Array<TimeChartDatum> = [];
    const compData: Array<TimeChartDatum> = [];
    if (primaryDataset?.metrics) {
        for (const column of primaryDataset.metrics) {
            if (selectedMetrics?.length && !selectedMetrics.includes(column.id)) {
                continue;
            }
            if (primaryDataset.datapoints) {
                data = {};
                data.datapoints = primaryDataset.datapoints;
            }
            if (!loading) {
                if (data && data?.datapoints?.length > 0 && primaryDataset.metrics) {
                    if (primaryDataset.metrics.length === 1 && data.datapoints.length > 1) {
                        for (let groupIndex = 0; groupIndex < data.datapoints.length; groupIndex++) {
                            const dataPoint = data.datapoints[groupIndex];
                            const group: string = formatKeys(primaryDataset.keys || [], dataPoint.keys) as string;
                            let ts: Array<any> = [];
                            for (const timestamp in dataPoint.data) {
                                const time = getTimestamp(timestamp);
                                if (dataPoint.data[timestamp][column.id] !== undefined) {
                                    let value = formatValue(dataPoint.data[timestamp][column.id], column);
                                    ts.push({ x: parseInt(time), y: value });    
                                }
                            }
                            tsKeyData.push({
                                groupName: group, groupId: group, 
                                metricName: primaryDataset!.metrics![0].label, metricId: primaryDataset!.metrics![0].id, 
                                unit: Unit.parseUnit(primaryDataset!.metrics![0].unit || ""), 
                                data: ts,
                                group: dataPoint.keys?.group
                            });

                            // If there is comparison data add the comparison data
                            if (dataPoint?.keys && comparisonDataset?.datapoints) {
                                for (const checkDatapoint of comparisonDataset.datapoints) {
                                    if (keysMatch(dataPoint, checkDatapoint)) {
                                        let ts: Array<any> = [];
                                        for (const timestamp in checkDatapoint.data) {
                                            const time = getTimestamp(timestamp);
                                            if (checkDatapoint.data[timestamp][column.id] !== undefined) {
                                                let value = formatValue(checkDatapoint.data[timestamp][column.id], column);
                                                ts.push({ x: parseInt(time) + comparisonOffset * 1000, y: value });
                                            }
                                        }
                                        compData.push({
                                            groupName: group, groupId: group, 
                                            metricName: primaryDataset!.metrics![0].label, metricId: primaryDataset!.metrics![0].id, 
                                            unit: Unit.parseUnit(primaryDataset!.metrics![0].unit || ""), 
                                            data: ts,
                                            group: dataPoint.keys?.group
                                        });
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        let group: string = formatKeys(primaryDataset.keys || [], data.datapoints[0].keys) as string;
                        if (primaryDataset.metrics.length === 1) {
                            group += getEnumText(primaryDataset.metrics[0]);
                        }
                        let ts: Array<any> = [];
                        for (const timestamp in data.datapoints[0].data) {
                            const time = getTimestamp(timestamp);
                            if (data.datapoints[0].data[timestamp][column.id] !== undefined) {
                                let value = formatValue(data.datapoints[0].data[timestamp][column.id], column);
                                ts.push({ 
                                    x: parseInt(time), 
                                    y: value 
                                });
                            }
                        }
                        tsKeyData.push({
                            groupName: group, groupId: group, 
                            metricName: column.label + (primaryDataset.metrics.length > 1 ? getEnumText(column) : ""), metricId: column.id, 
                            unit: Unit.parseUnit(column.unit || ""), 
                            data: ts,
                            group: data.datapoints[0].keys?.group
                        });

                        // If there is comparison data add the comparison data
                        if (data.datapoints[0].keys && comparisonDataset?.datapoints) {
                            // There should only be one key so maybe we don't have to check this, let's do it anyway, it can't hurt
                            for (const checkDatapoint of comparisonDataset.datapoints) {
                                if (keysMatch(data.datapoints[0], checkDatapoint)) {
                                    let ts: Array<any> = [];
                                    for (const timestamp in checkDatapoint.data) {
                                        const time = getTimestamp(timestamp);
                                        if (checkDatapoint.data[timestamp][column.id] !== undefined) {
                                            let value = formatValue(checkDatapoint.data[timestamp][column.id], column);
                                            ts.push({ x: parseInt(time) + comparisonOffset * 1000, y: value });
                                        }
                                    }
                                    compData.push({
                                        groupName: group, groupId: group, 
                                        metricName: column.label + (primaryDataset.metrics.length > 1 ? getEnumText(column) : ""), metricId: column.id, 
                                        unit: Unit.parseUnit(column.unit || ""), 
                                        data: ts,
                                        group: data.datapoints[0].keys?.group
                                    });
                                    break;
                                }
                            }
                        }                        
                    }
                } else {
                    tsKeyData[column.id] = [];
                }
            } else {
                tsKeyData[column.id] = [];
            }
        }
    }

    return <DataLoadFacade loading={loading || props.loading} error={error} data={data}>
        <TimeChart
            loading={false} showChartSubtitle={false} primaryData={tsKeyData} compData={compData}
            transparent={props.transparent} height={props.height}
            enableFullScreen={true} settings={{style, showLegend,  legendPosition, showMore: true, showIconsOnHover: true}}
            comparisonOffset={comparisonOffset} comparisonSuffix={comparisonSuffix}
            fullScreenTitle={props.widget?.name}
            // Hack Alert: Fix for Bugs 10501, 10231
            reflowOnResize={true} hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            onGroupMetricSelection={(event: GroupMetricEvent) => {
                if (props.onGroupsAndMetricsSelected) {
                    props.onGroupsAndMetricsSelected([...(event.groups || [])], [...(event.metrics || [])]);
                }
            }}
        />
    </DataLoadFacade>;
};

/** returns any enum metric text that should be added to a group name or metric name.
 *  @param column the Metric column to check to see if it is an enumeration.
 *  @returns a String with the text summarizing the enumeration or empty string if the column 
 *      is not an enumeration. */
function getEnumText(column: Column): string {
    let enumText = "";
    if (column.enum) {
        const enumKeys = Object.keys(column.enum);
        const enumArray: Array<string> = [];
        for (const enumKey of enumKeys) {
            enumArray.push(enumKey + " = " + column.enum[enumKey]);
        }
        enumText = " (" + enumArray.join(", ") + ")";
    }
    return enumText;
}
