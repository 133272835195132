import ELK, { ElkNode, LayoutOptions } from "elkjs/lib/elk.bundled.js";
import { Node, Edge } from "react-flow-renderer";
import { CloudIMGraphDef } from "utils/cloudim/TopologyUtils";

const elk = new ELK();

// Refer to https://eclipse.dev/elk/reference.html
export const algorithmOptions = [
    // Nicely Working
    { label: "Tree", value: "org.eclipse.elk.mrtree" },
    { label: "Layered", value: "org.eclipse.elk.layered" },
    { label: "Disco", value: "org.eclipse.elk.disco" },
    { label: "Force", value: "org.eclipse.elk.force" },
    { label: "Stress", value: "org.eclipse.elk.stress" },
    { label: "Random", value: "org.eclipse.elk.random" },
    { label: "Box", value: "org.eclipse.elk.box" },
    // Experimentals
    { label: "Radial", value: "org.eclipse.elk.radial" },
]

export const directionOptions = [
    { label: "Up", value: "UP" },
    { label: "Down", value: "DOWN" },
    { label: "Left", value: "LEFT" },
    { label: "Right", value: "RIGHT" },
]

// Refer to https://eclipse.dev/elk/reference/options.html
export const presetOptions = {
    Tree: {
        "elk.algorithm": "org.eclipse.elk.mrtree",
        "elk.direction": "DOWN",
        "elk.spacing.nodeNode": "150",
    },
    Layered: {
        "elk.algorithm": "org.eclipse.elk.layered",
        "elk.direction": "RIGHT",
        "elk.layered.spacing.edgeNodeBetweenLayers": "100",
        "elk.layered.spacing.nodeNodeBetweenLayers": "100",
        "elk.spacing.nodeNode": "150",
    },
    Stress: {
        "elk.algorithm": "org.eclipse.elk.stress",
        "elk.stress.desiredEdgeLength": "150",
        "elk.spacing.nodeNode": "150",
    },
    Box: {
        "elk.algorithm": "org.eclipse.elk.box",
        "elk.aspectRatio": "10",
        "elk.spacing.nodeNode": "60",
    }
}

export const overlapRemovalOption = {
    "elk.algorithm": "org.eclipse.elk.sporeOverlap",
}

export const defaultOptions: LayoutOptions = {
    ...presetOptions.Tree,
    ...{
        "elk.direction": "DOWN",
        "elk.spacing.nodeNode": "150",
        "elk.spacing.componentComponent": "100",
    }
};

export async function getLayoutedGraph(nodes: Partial<Node>[] & Omit<Node, "position">[], edges: Edge[], options?: LayoutOptions): Promise<CloudIMGraphDef> {
    const layoutOptions: LayoutOptions = { ...defaultOptions, ...options };
    const graph: ElkNode = {
        id: "root",
        children: nodes,
        edges: edges.map(obj => ({
            id: obj.id,
            sources: [obj.source],
            targets: [obj.target]
        })),
        layoutOptions: layoutOptions
    };

    try {
        const elkGraph = await elk.layout(graph);

        const layoutedGraph: CloudIMGraphDef = {
            nodes: nodes.map((node: any) => {
                const layoutedNode = elkGraph.children?.find((elkNode) => {
                    return elkNode.id === node.id;
                })
                node.position = { x: layoutedNode?.x ?? 0, y: layoutedNode?.y ?? 0 };
                return node;
            }),
            edges: edges
        }

        return layoutedGraph;
    } catch (error) {
        console.error(error);
        return {
            nodes: [],
            edges: []
        };
    }
};
