import { LangEN, STRINGS } from "app-strings";
import { Form, SelectField } from "components/common/form";
import { AvailableIntegration } from "pages/integrations/types/IntegrationTypes";
import React, { useEffect, useState } from "react";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";

interface Props {
    integrations: AvailableIntegration[];
    integrationId: string;
    subflowId: string;
    connectorId: string;
    handleConnectorChange: (connectorId: string, event: React.ChangeEvent<HTMLSelectElement>) => any;
}

export default function ImportRunbookConnectorSelector({
    integrations,
    integrationId,
    subflowId,
    connectorId,
    handleConnectorChange
}: Props) {
    const translations: LangEN["runbooks"]["importModal"] = STRINGS["runbooks"]["importModal"];
    const [isConnectorsListLoading, setIsConnectorsListLoading] =
        useState(true);
    const [connectorsList, setConnectorsList] = useState<
        Array<{ label: string; value: string }>
    >([]);

    useEffect(() => {
        async function getConnectorsList() {
            setIsConnectorsListLoading(true);
            const matchingIntegration = integrations?.find(
                (el) => el.id === integrationId
            );
            if (!matchingIntegration?.id) {
                return [];
            }

            const result = await IntegrationLibraryService.getConnectors(
                matchingIntegration.id
            );
            const connectorsOptions = result.map((connector) => {
                return {
                    label: connector.name,
                    value: connector.connectorId,
                };
            });
            setConnectorsList(connectorsOptions);
            setIsConnectorsListLoading(false);
        }

        getConnectorsList();
    }, [integrationId, integrations, integrations?.length]);

    if (isConnectorsListLoading) {
        return <span>{translations.loading}</span>;
    }

    return (
        <Form
            loading={isConnectorsListLoading}
            initialValues={{ connector: connectorId }}
        >
            <SelectField
                data-testid="import-runbook_connector-select"
                name="connector"
                value={connectorId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleConnectorChange(subflowId, e)}
            >
                <option key={translations.connectorSelector.noSelection} label={translations.connectorSelector.noSelection} value={undefined} />
                {connectorsList.map(connector => <option key={connector.label} value={connector.value}>{connector.label}</option>)}
                </SelectField>
        </Form>
    );
}
