import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import {
    RadioButtonField,
    RadioButtonGroup,
} from 'components/common/form/RadioButton';
import { PRODUCT_NAME_REGISTERED_WITH_AZURE } from "pages/user-account-management/UserAccountManagement";
import { STRINGS } from 'app-strings';
import './RolesSection.scss';
import { MAPPED_ROLES } from '../../pages/user-account-management/UserAccountManagement';

const setPlatformRoleFieldValidation = (label: string): yup.StringSchema => {
    return yup
        .string()
        .required(
            ` ${STRINGS.USER_ACCOUNT_MANAGEMENT.VALIDATION.selectOption}`
        );
};

const setProductRoleFieldValidation = (
    label: string,
    platformFieldKey
): yup.StringSchema => {

    if (platformFieldKey) {
        return yup.string().when([platformFieldKey], {
            is: (platformFieldKey) => {
                return platformFieldKey !== "true";
            },
            then: yup
                .string()
                .required(
                    `${STRINGS.USER_ACCOUNT_MANAGEMENT.VALIDATION.selectOption}`
                )
        });
    } else {
        return yup
            .string()
            .required(
                `${STRINGS.USER_ACCOUNT_MANAGEMENT.VALIDATION.selectOption}`
            );
    }
};

let platformRoleRenderFns: Array<Function>;
let rolesByProductRenderFns: Array<Function>;

export const initializePlatformAndProductRoleFields = (
    rolesByProduct,
    platformRoles,
    initialPlatformRole?,
    initialProductRole?
) => {
    platformRoleRenderFns = [];
    rolesByProductRenderFns = [];
    let validationSchema = {};
    let initialValues = {
        products: {
            gelato: {
                roles: []
            }
        },
        [PRODUCT_NAME_REGISTERED_WITH_AZURE]: ''
    };
    let platformFieldKey;
    if (platformRoles && platformRoles.length) {
        platformRoles.forEach((platformRole) => {
            platformFieldKey = platformRole['displayName'];
            initialValues[platformFieldKey] = initialPlatformRole || '';
            initialValues['superuser'] = initialPlatformRole || '';
            validationSchema[platformFieldKey] = setPlatformRoleFieldValidation(
                platformFieldKey
            );
            platformRoleRenderFns.push(getPlatformRoleRenderFns(platformRole));
        });
    }

    if (rolesByProduct[PRODUCT_NAME_REGISTERED_WITH_AZURE]) {
        //All available roles in gelato product
        const product = rolesByProduct[PRODUCT_NAME_REGISTERED_WITH_AZURE];

        //Check if initialProduct role exists (edit mode)
        if ((initialProductRole && initialProductRole[PRODUCT_NAME_REGISTERED_WITH_AZURE])) {

            //Set the value if it exists to select the role option when ui renders
            initialValues['products'][PRODUCT_NAME_REGISTERED_WITH_AZURE] = {
                roles: initialProductRole[PRODUCT_NAME_REGISTERED_WITH_AZURE].roles,
            };
        }

        validationSchema[PRODUCT_NAME_REGISTERED_WITH_AZURE] = setProductRoleFieldValidation(
            product['name'],
            platformFieldKey
        );

        rolesByProductRenderFns.push(
            getRolesByProductRenderFns(PRODUCT_NAME_REGISTERED_WITH_AZURE, product)
        );
    }
    return [validationSchema, initialValues];
};

const getPlatformRoleRenderFns = (platformRole) => {
    return (setFieldValue, setHasPlatformRole) => {
        return (
            <React.Fragment key={platformRole['displayName']}>
                <RadioButtonGroup
                    name={platformRole['displayName']}
                    inline={true}
                    label={STRINGS.USER_ACCOUNT_MANAGEMENT.isUserPortfolioAdmin}
                    onChange={(e) => {
                        const valueSet = e.currentTarget.value === 'true';
                        let superuser = '';
                        if (valueSet) {
                            superuser = platformRole['value'];
                        }
                        setFieldValue('superuser', superuser);
                        setHasPlatformRole(valueSet);
                    }}
                >
                    <RadioButtonField label={STRINGS.smartText.counts.yes} value="true"/>
                    <RadioButtonField label={STRINGS.smartText.counts.no} value="false"/>
                </RadioButtonGroup>
            </React.Fragment>
        );
    };
};

const getRolesByProductRenderFns = (productKey, product) => {
    const roles = product.roles;
    return (formValues, setFieldValue) => {
        return (
            <React.Fragment key={productKey}>
                <label className="required">Select a role: </label>
                <RadioButtonGroup
                    name={ productKey }
                    onChange={ (e) => {
                        const products = formValues.products;
                        formValues.products[productKey]['roles'] = [e.currentTarget.value];
                        setFieldValue('products', products);
                    } }
                    className="opt-btn-product"
                >
                    { roles.map((role) => {
                        return (
                            <RadioButtonField
                                key={ role.value }
                                label={ MAPPED_ROLES[role.value] }
                                value={ role.value }
                                checked={
                                    formValues.products[productKey].roles.length
                                    && formValues.products[productKey].roles[0] === role.value
                                }
                            />
                        );
                    }) }
                </RadioButtonGroup>
            </React.Fragment>
        );
    };
};

const renderPlatformRoles = (setFieldValue, setHasPlatformRole) => {
    return platformRoleRenderFns.map((platformRoleRenderFn) => {
        return platformRoleRenderFn(setFieldValue, setHasPlatformRole);
    });
};
const renderProductRoles = (formValues, setFieldValue, type) => {
    return rolesByProductRenderFns.map((rolesByProductRenderFn) => {
        return rolesByProductRenderFn(formValues, setFieldValue, type);
    });
};

function RolesSection(props) {
    const {setFieldValue, values} = useFormikContext();
    const [hasPlatformRole, setHasPlatformRole] = useState(
        // @ts-ignore
        values.hasOwnProperty('superuser') && (!values['superuser'] || values['superuser'] === 'true')
    );

    return (
        <div className="tir-roles-section">
            {renderPlatformRoles(setFieldValue, setHasPlatformRole)}
            {!hasPlatformRole
                ? renderProductRoles(values, setFieldValue, props.type)
                : null}
        </div>
    );
}

export { RolesSection };
