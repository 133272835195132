/** This module contains the component for displaying the search results page
 *  @module
 */
import React, { useEffect, useRef, useState } from "react";
import { STRINGS } from "app-strings";
import { Button } from '@blueprintjs/core';
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { SDWAN_ICONS } from "components/sdwan/enums/icons";
import { useQueryParams } from "utils/hooks";
import { MetaCategrories, SearchCategories, SearchItemType, SearchResultsTable } from '../../components/common/search/search-results-table/SearchResultsTable'
import { CategorySelector, CategoryItem } from "../../components/common/search/search-categories/SearchCategories";
import { useSearch } from "utils/hooks/useSearch";
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { ISO_DURATION } from "utils/stores/GlobalTimeStore";
import { ApiCallStatus, SIZE } from "components/enums";
import { TwoColumnContainer } from "components/common/layout/containers/two-column-container/TwoColumnContainer";
import { IconNames } from "@tir-ui/react-components";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { EventNames, trackEvent } from 'utils/appinsights';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** Renders the Search page.
 *  @returns JSX which shows the search results .*/
const SearchPage = (): JSX.Element | null => {
    const [activeCategroy, setActiveCategory] = useState<SearchCategories>(MetaCategrories.ALL);
    const [searchResultCount, setSearchResultsCount] = useState<number>(0);
    const { params, setQueryParam } = useQueryParams({ listenOnlyTo: ["searchText"] });

    const previousSearchTextFromParams = useRef<string>(params.searchText);
    const [searchText, setSearchText] = useState(params?.searchText ? params.searchText : "");

    const appInsightsContext = useAppInsightsContext();

    // Determines volume of data that will be fetched.
    const searchTimeDuration: ISO_DURATION = ISO_DURATION.P2H;

    const searchCategoryLookup: { [x: string]: SearchCategories } = {
        ALL: MetaCategrories.ALL,
        /* hidding per [#6657, #6658]
        SITE: SearchItemType.SITE,
        DEVICE: SearchItemType.DEVICE,
        APP: SearchItemType.APP,
        */
        RUNBOOK: SearchItemType.RUNBOOK,
        INCIDENT: SearchItemType.INCIDENT,
        DEVICE: SearchItemType.DEVICE,
        INTERFACE: SearchItemType.INTERFACE,
        APPLICATION: SearchItemType.APPLICATION,
        LOCATION: SearchItemType.LOCATION,
        IMPACTED_USER: SearchItemType.IMPACTED_USER,
        IMPACTED_APPLICATION: SearchItemType.IMPACTED_APPLICATION,
        IMPACTED_LOCATION: SearchItemType.IMPACTED_LOCATION,
        CUSTOM_PROPERTY:  SearchItemType.CUSTOM_PROPERTY
    }

    // The list categories that is passed on to Categories Component
    const searchCatInfoMap: { [x: string]: CategoryItem } = {
        ALL: {
            id: MetaCategrories.ALL,
            label: STRINGS.SEARCH.all,
            count: 0,
            showCount: true
        },
        /* hidding per [#6657, #6658]
        SITE: {
            id: SearchItemType.SITE,
            label: STRINGS.SEARCH.sites,
            count: 0,
            icon: SDWAN_ICONS.SITE,
            showCount: true
        },
        DEVICE: {
            id: SearchItemType.DEVICE,
            label: STRINGS.SEARCH.devices,
            count: 0,
            showCount: true,
            icon: SDWAN_ICONS.DEVICE
        },
        APP: {
            id: SearchItemType.APP,
            label: STRINGS.SEARCH.apps,
            count: 0,
            showCount: true,
            icon: SDWAN_ICONS.APP
        },
        */
        RUNBOOK: {
            id: SearchItemType.RUNBOOK,
            label: STRINGS.SEARCH.runbooks ,
            count: 0,
            showCount: true,
            icon: SDWAN_ICONS.RUNBOOK
        },
        INCIDENT: {
            id: SearchItemType.INCIDENT,
            label: STRINGS.SEARCH.incidents,
            count: 0,
            showCount: true,
            icon: SDWAN_ICONS.INCIDENT
        },
        DEVICE: {
            id: SearchItemType.DEVICE,
            label: STRINGS.SEARCH.devices,
            count: 0,
            showCount: true,
            icon: IconNames.DEVICES
        },
        INTERFACE: {
            id: SearchItemType.INTERFACE,
            label: STRINGS.SEARCH.interfaces,
            count: 0,
            showCount: true,
            icon: IconNames.MERGE_LINKS
        },
        APPLICATION: {
            id: SearchItemType.APPLICATION,
            label: STRINGS.SEARCH.applications,
            count: 0,
            showCount: true,
            icon: IconNames.APPLICATIONS
        },
        LOCATION: {
            id: SearchItemType.LOCATION,
            label: STRINGS.SEARCH.locations,
            count: 0,
            showCount: true,
            icon: IconNames.GLOBE
        },
        IMPACTED_USER: {
            id: SearchItemType.IMPACTED_USER,
            label: STRINGS.SEARCH.impactedUsers,
            count: 0,
            showCount: true,
            icon: IconNames.PEOPLE
        },
        IMPACTED_APPLICATION: {
            id: SearchItemType.IMPACTED_APPLICATION,
            label: STRINGS.SEARCH.impactedApplications,
            count: 0,
            showCount: true,
            icon: IconNames.APPLICATIONS
        },
        IMPACTED_LOCATION: {
            id: SearchItemType.IMPACTED_LOCATION,
            label: STRINGS.SEARCH.impactedLocations,
            count: 0,
            showCount: true,
            icon: IconNames.GLOBE
        },
        CUSTOM_PROPERTY: {
            id: SearchItemType.CUSTOM_PROPERTY,
            label: STRINGS.SEARCH.customProperties,
            count: 0,
            showCount: true,
            icon: IconNames.TAG
        }
    }
    const [searchCategories, setSearchCategories] = useState<Array<CategoryItem>>([
        searchCatInfoMap.ALL,
        /* hidding per [#6657, #6658]
        searchCatInfoMap.SITE,
        searchCatInfoMap.DEVICE,
        searchCatInfoMap.APP
        */
    ]);
    const onNewCategorySelection = (cat: string) => {
        if (searchCategoryLookup[cat]) {
            setActiveCategory(searchCategoryLookup[cat]);
        } else {
            console.warn(`Unknown search category encountered : ${cat}`);
        }
    }
    // The heavy lifting is done by this hook. It fetches data from DAL and Runbook apis collates the results and returns the data.
    const [data, search] = useSearch(searchText);
    const searchInputEl = useRef<HTMLInputElement>(null);

    const dataReturned = useRef<number>(0);

    useEffect(() => {
        setSearchResultsCount(data.data.length);
        updateSearchCatCount(data.data);
        function updateSearchCatCount(results) {
            // reset current count
            searchCategories.forEach(cat => {
                cat.count = 0;
            });
            results.forEach(item => {
                if (searchCatInfoMap[item.resultItem.type]) {
                    searchCatInfoMap[item.resultItem.type].count++;
                }
                searchCatInfoMap.ALL.count++;
            });
            setSearchCategories([
                searchCatInfoMap.ALL,
                /* hidding per [#6657, #6658]
                searchCatInfoMap.SITE,
                searchCatInfoMap.DEVICE,
                searchCatInfoMap.APP,
                */
                searchCatInfoMap.RUNBOOK,
                searchCatInfoMap.INCIDENT,
                searchCatInfoMap.DEVICE,
                searchCatInfoMap.INTERFACE,
                searchCatInfoMap.APPLICATION,
                // Hiding until search support location searches
                //searchCatInfoMap.LOCATION,
                searchCatInfoMap.IMPACTED_USER,
                searchCatInfoMap.IMPACTED_APPLICATION,
                searchCatInfoMap.IMPACTED_LOCATION,
                searchCatInfoMap.CUSTOM_PROPERTY
            ]);
            if (++dataReturned.current % 3 === 0) {
                const summary = {};
                for (const key in searchCatInfoMap) {
                    summary[key] = searchCatInfoMap[key].count;
                }
                if (appInsightsContext) {
                    const properties = {
                        name: EventNames.SEARCH_RESULT,
                        properties: {
                            searchText: params?.searchText ? params.searchText : "",
                            searchResult: summary
                        }
                    };
                    trackEvent(appInsightsContext, AuthService, properties);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, search]);

    useEffect(() => {
        setSearchText(params?.searchText ? params.searchText : "");
        search({ time: { "duration": searchTimeDuration } });
        if (appInsightsContext) {
            const properties = {
                name: EventNames.SEARCH_INVOKE,
                properties: {
                    searchText: params?.searchText ? params.searchText : ""
                }
            };
            trackEvent(appInsightsContext, AuthService, properties);
        }
        console.log("Begin Search for string : " + params.searchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    function triggerSearchOperation() {
        const text = searchInputEl?.current?.value;
        setSearchText(text ? text : "");
        // Search is triggered when the search param is updated.
        setQueryParam("searchText", text);
    }

    if (params.searchText && params.searchText !== previousSearchTextFromParams.current) {
        // The URL parameters have changed, update the search text state and exit, this will 
        // cause a re-render.
        const searchEl = document.getElementById("search-input-text") as HTMLInputElement;
        if (searchEl) {
            searchEl.value = params.searchText;
        }
        previousSearchTextFromParams.current = params.searchText;
        setSearchText(params.searchText);
        return null;
    }

    return (
        <PageWithHeader name="SearchPage" title={STRINGS.SEARCH_PAGE.title} icon={SDWAN_ICONS.SEARCH} showTimeBar={false}>
            <DataLoadFacade loading={data.state === ApiCallStatus.LOADING} error={data.error} data={data.data} showContentsWhenLoading={true} className="h-100">
                <TwoColumnContainer firstColumnSize={SIZE.s}
                    titleContent={<div className="search-header-bar">
                        <div className="d-flex p-3">
                            <input
                                aria-label="search box"
                                id="search-input-text"
                                name="searchBox"
                                type="text"
                                maxLength={150}
                                className="bp3-input bp3-fill"
                                ref={searchInputEl}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        triggerSearchOperation();
                                    }
                                }}
                                defaultValue={searchText}
                            />
                            <Button title="Search" intent="primary" onClick={triggerSearchOperation} type="button" >Search</Button>
                        </div>
                        <div className="mx-3 display-8">
                            {searchResultCount} {STRINGS.SEARCH.results}
                        </div>
                    </div>}
                >
                    <CategorySelector categories={searchCategories} selectedCategory={activeCategroy} onCategorySelection={onNewCategorySelection}></CategorySelector>
                    <SearchResultsTable searchCategory={activeCategroy} data={data ? data.data : []}></SearchResultsTable>
                </TwoColumnContainer>
            </DataLoadFacade>
        </PageWithHeader>
    );
};

export default SearchPage;
