/** This module contains the UserPrefsService that is used to persist the user settings in
 *  Azure.
 *  @module
 */
import { ApiService } from 'utils/services/ApiService'
import { THEME } from 'utils/themes/manager/constants'
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider'
import { isValidUserPreference, UserPreferences, UserPreferencesFromAPI } from './UserPrefsTypes';
import { IS_EMBEDDED } from 'components/enums/QueryParams';

/** a const with the default user prefernces. */
export const DEFAULT_USER_PREFS: UserPreferences = {
    theme: THEME.light
};
export type { UserPreferences, UserPreferencesFromAPI };
export { isValidUserPreference };

/** this class defines the UserPrefsService. */
export class UserPrefsService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        let tenantId, userId;
        try {
            const AuthService = AuthServiceProvider.getService();
            tenantId = AuthService.getTenantId();
            userId = AuthService.getUserAccount().getUserId();
        } catch (ex) {
            console.error(ex);
            throw new Error("Unable to fetch tenant and user ID from auth service");
        }
        const baseUri = `/api/affogato/preferences/tenants/${tenantId}/users/${userId}`
        super(baseUri)
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            try {
                const tenantId = ApiService.AUTH_SERVICE.getTenantId();
                const userId = ApiService.AUTH_SERVICE.getUserAccount().getUserId();
                const region = ApiService.AUTH_SERVICE.getRegion();
                return `/api/iq/${region}/preferences/tenants/${tenantId}/users/${userId}`;        
            } catch (ex) {
                console.error(ex);
                throw new Error("Unable to fetch tenant and user ID from auth service");
            }
        } else {
            return this.baseApiUri;
        }
    }

    async get<UserPreferences>(): Promise<UserPreferences> {
        if (IS_EMBEDDED) {
            return DEFAULT_USER_PREFS as UserPreferences;
        }

        return new Promise<UserPreferences>((resolve, reject) => {
            const handleUserPreferenceNotFound = (response, userPreferenceCallback) => {
                let is404 = false;
                if (response?.response?.status === 404) {
                    is404 = true;
                    console.debug("Creating user preferences for the first time for this user");

                    this.save(DEFAULT_USER_PREFS)
                        .then(response => {
                            userPreferenceCallback((response || DEFAULT_USER_PREFS) as UserPreferences);
                        })
                        .catch(exception => {
                            // Retry once again, if 409 is received
                            if (response?.response?.status === 409) {
                                this.save(DEFAULT_USER_PREFS).then(response => {
                                    userPreferenceCallback((response || DEFAULT_USER_PREFS) as UserPreferences);
                                });
                            }

                            console.error("Unable to create default user preferences for this user");
                            console.error(exception);
                            throw exception;
                        })
                        .finally(() => {
                            resolve(DEFAULT_USER_PREFS as UserPreferences);
                        });
                }
                return is404;
            }

            super
            .get<UserPreferencesFromAPI>()
            .then((response?:UserPreferencesFromAPI) => {
                if (response?.Preferences) {
                    resolve(response.Preferences as UserPreferences);
                } else {
                    if (response) {
                        if (!handleUserPreferenceNotFound(response, resolve)) {
                            console.error("Response from UserPreferences API is missing Preferences " + JSON.stringify(response))
                            throw response;
                        }
                    } else {
                        const errorMsg = "No Response received from user preferences API";
                        console.error(errorMsg);
                        throw new Error(errorMsg);
                    }
                }
            })
            .catch(exception => {
                if (!handleUserPreferenceNotFound(exception, resolve)) {
                    console.error("Unable to fetch user preferences");
                    console.error(exception);
                    reject();
                }
            });
        });
    }

    async save<UserPreferences>(prefs: UserPreferences): Promise<UserPreferences> {
        if (isValidUserPreference(prefs)) {
            const responseFromAPI = await super.post<UserPreferencesFromAPI>('', { Preferences: prefs });
            return responseFromAPI?.Preferences as UserPreferences;
        } else {
            console.error("Invalid user preference input: " + JSON.stringify(prefs));
            return Promise.reject("Invalid user preference input");
        }
    }

    async update<UserPreferences>(prefs: UserPreferences): Promise<UserPreferences> {
        if (isValidUserPreference(prefs)) {
            const responseFromAPI = await super.put<UserPreferencesFromAPI>('', { Preferences: prefs });
            return responseFromAPI?.Preferences as UserPreferences;
        } else {
            console.error("Invalid user preference input: " + JSON.stringify(prefs));
            return Promise.reject("Invalid user preference input");
        }
    }

    async clear<UserPreferences>(prefs: UserPreferences): Promise<any> {
        return await super.delete<UserPreferencesFromAPI>();
    }

}

