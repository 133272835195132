/** This module contains the component for creating new runbooks.
 *  @module
 */
import React, { useState, useRef, useEffect, useCallback, useMemo, MutableRefObject } from "react";
import { useHistory } from "react-router";
import { Prompt } from "react-router-dom";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import ReactFlowGraph from '../../../../components/common/graph/react-flow/ReactFlowGraph';
import { Edge, Elements, Node, OnLoadParams, ReactFlowProvider } from "react-flow-renderer";
import {
    DIRECTION, GraphDef, NodeDef, NodeEnvSetting, NodeProperty, NodeWiresSetting, 
    RunbookInfo, InputType, EdgeDef, Variant,
    VARIANTS_WITH_VERSIONING
} from '../../../../components/common/graph/types/GraphTypes';
import { NodeLibrary, NodeLibraryNode, NodeLibrarySpec, NodeSubflowVariableDefinition } from './NodeLibrary';
import { Button, InputGroup, TextArea, HTMLSelect, Intent, Switch, Radio, RadioGroup, Callout } from "@blueprintjs/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RunbookConfig, RunbookNode } from "utils/services/RunbookApiService";
import {
    runbookService, triggerNodes, dataOceanNodes, aggregatorNodes, canEditRunbooks, getNodeFromGraphDef, 
    isDataOceanNodeFromGraphDef, columnEditorChartNodes,isColumnEditorChartNodeFromGraphDef, 
    isMetricEditorChartNodeFromGraphDef, isMetricsEditorChartNodeFromGraphDef, 
    isAggregatorNodeFromGraphDef, isDecisionNodeFromGraphDef, transformNodes, decisionNodes, logicalNodes, 
    isSizeMetricEditorChartNodeFromGraphDef, isColorMetricEditorChartNode, skeletonNodes, subflowNodes, 
    isSubflowOutputNode, isSubflowInputNodeFromGraphDef, isSubflowOutputNodeFromGraphDef, isSubflowInputNode, isSubflowNodeFromGraphDef,
    getParentsFromGraphDef, isTriggerNodeFromGraphDef
} from 'utils/runbooks/RunbookUtils';
import { AdditionalInfoOptions, ConnectionExclusions, validateNodesFromGraphDef, ValidationResult } from "utils/runbooks/RunbookValidationUtils";
import { ToolbarAction } from "components/common/graph/GraphToolbar";
import NodeEditorPanel from "components/common/graph/NodeEditorPanel";
import LayoutEditorPanel from "components/common/graph/LayoutEditorPanel";
import GraphDataPanel from '../../../../components/common/graph/GraphDataPanel';
import GraphErrorPanel from "components/common/graph/GraphErrorPanel";
import { UniversalNode } from "components/common/graph/UniversalNode";
import { useQueryParams, useUserPreferences } from "utils/hooks";
import { STRINGS } from "app-strings";
import { getUuidV4 } from "utils/unique-ids/UniqueIds";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer";
import { DetailsPanel } from "components/reporting";
import { TabbedSubPages, Tab } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { getIconForNode } from "components/common/graph/react-flow/nodes/BaseNodeContent";
import { SDWAN_ICONS } from "components/sdwan/enums";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { IconNames, useStateSafePromise } from "@tir-ui/react-components";
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { EventNames, trackEvent } from 'utils/appinsights';
import { ReactPlugin, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { IS_CREATED_FROM_WIZARD, IS_EMBEDDED, PARAM_NAME } from "components/enums/QueryParams";
import { getURLPath } from "config";
import { getQueryParam, getURL, paramsObj } from "utils/hooks/useQueryParams";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { SIZE } from "components/enums";
import { ContextMode, ContextSource, CONTEXT_MODE, RunbookContext, VariableContextByScope, Context } from "utils/runbooks/RunbookContext.class";
import { 
    TRIGGER_OPTION_VALUE, TRIGGER_OPTION_VALUE_OLD, NODE_OPTION_VALUE_CP, NODE_OPTION_VALUE_AP, 
    SUBFLOW_INPUT_OPTION_VALUE, getDataOceanFiltersMap
} from "components/common/graph/editors/data-ocean/DataOceanFilters";
import { GenericKey } from "utils/runbooks/NodeUtil";
import { AggregateNodeUtil } from "components/common/graph/editors/aggregator/AggregatorNodeUtils";
import { 
    GLOBAL_SCOPE, INCIDENT_SCOPE, INCIDENT_SCOPE_BUILTIN_VARIABLES, PrimitiveVariableType, RUNBOOK_SCOPE_BUILTIN_VARIABLES, RUNTIME_SCOPE, 
    VariableCollection, getRuntimeBuiltinVariables 
} from "utils/runbooks/VariablesUtils";
import { VariableContext } from "utils/runbooks/VariableContext";
import { useVariables } from "utils/hooks/useVariables";
import { saveAs } from 'file-saver';
import { useCustomProperties } from "utils/hooks/useCustomProperties";
import { CustomProperty, CustomPropertyContext } from "./CustomPropertyTypes";
import SubflowRunbookNodeLibrary from "./subflow_node_library.json";
import { openConfirm } from "components/common/modal";
import RunbooksContainingSubflowTable from "../runbooks-containing-subflow/RunbooksContainingSubflowTable";
import { RunbookIntegrationDetails } from "pages/integrations/types/IntegrationTypes";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import { getIntegrationIcon } from "pages/integrations/IntegrationsLibraryPage";
import { SUBFLOW_NODE_EDIT_PROPS } from "components/common/graph/editors/subflow-input/SubflowInputNodeUtils";
import { DEFAULT_SUBFLOW_COLOR } from "components/common/graph/react-flow/nodes/subflow/SubflowNode";
import { Version } from "utils/Version.class";
import './CreateRunbookView.scss';
import { UserPreferences } from "utils/services/UserPrefsService";

// This constant specifies whether or not to show the type control
const showTypeControl: boolean = false;

// This flag controls if a node's editor blade should automatically open or not when it is selected
const editNodeOnSelection: boolean = false;

// This constant specifies whether you can save the graph if there are errors
const allowSaveWithErrors: boolean = true;

// This constant specifies whether or not to display the error and warning list in the save dialog.  If it is
// true the errors are shown in the dialog, if it is false a message is shown in the dialog saying that there
// are errors and there is a button to toggle them on and off.
const showErrorsInSaveDialog: boolean = false;

// This constant is set at runtime and specifies whether or not to include runbook development only features
const runConfig = window["runConfig"];
const INCLUDE_RUNBOOK_DEV = runConfig?.INCLUDE_RUNBOOK_DEV === undefined ? false : runConfig.INCLUDE_RUNBOOK_DEV;

// This constant defines the initial runbook that is read in to display to the user
const INIT_GRAPH_DATA: GraphDef = { nodes: [], edges: [] };

// This constant defines an initial runbook that should be used when we are embedded in Aternity
const INIT_EMBED_GRAPH_DATA: GraphDef = { 
    nodes: [
        { 
            id: getUuidV4(), name: "Webhook", type: "trigger", info: "", color: "#e7e7ae", icon: "third-party", 
            wires: {direction: "out" as DIRECTION}, x: 232, y: 160, properties: [
                {key: "triggerType", value: "webhook"},
                {key: "timeReference", value: "RUNBOOK_EXECUTION"},
                {key: "timeOffset", value: 900},
                {key: "debug", value: false}
        ]
    }], 
    edges: [] 
};

// This constant specifies the entry in the runbook list combo box for a new runbook that is not saved on the server
const NEW_RUNBOOK: RunbookInfo = {
    id: "0",
    label: STRINGS.runbookEditor.newRunbook,
    version: "1.0.0",
    origVersion: "1.0.0",
    // triggerType: InputType.APPLICATION_LOCATION,
    isFactory: false,
    disabled: true,
    runtimeVariables: {primitiveVariables: [], structuredVariables: []},
    subflowVariables: {primitiveVariables: [], structuredVariables: []}
};

/** this is the node library to use when evaluating subflows. */
const subflowNodeLibrary = new NodeLibrary(SubflowRunbookNodeLibrary as NodeLibrarySpec);

/** a reference to the auth service that has the tenant information and permissions. */
const AuthService = AuthServiceProvider.getService();

/** This interface defines the properties passed into the create runbook React component.*/
interface CreateRunbookViewProps {
    /** the nodelibrary that is currently being displayed in the runbook editor. */
    nodeLibrary: NodeLibrary;
    /** if true hide the editor, if false, display it.   When hidden all subcomponents that utilize a popover need 
     *  to have the popover hidden as well. */
    editorHidden?: boolean;
    /** the handler for the onPreview event. */
    onPreview: (runbook: RunbookConfig) => void;
    /** the handler for the onPreview event. */
    onTest: (runbook: RunbookConfig) => void;
    /** the handler for active runbook changes. */
    onActiveRunbookChanged?: (runbook: RunbookConfig) => void;
    /** the runbook to display in the editor. */
    runbookConfig?: RunbookConfig;
    /** the change handler it returns the current runbook configuration. */
    onChange?: (runbook: RunbookConfig) => void;
    /** this is true if the runbook editor is being displayed in a dialog, false otherwise. */
    inDialog?: boolean;
    /** runbook variant type */
    variant: Variant;
    /** the exclusion list for auto-connect. */
    exclusions?: ConnectionExclusions;
    /** a boolean value, true if subflows should be displayed. */
    displaySubflows?: boolean;
    /** the handler for the subflow state set event. */
    onSubflowsSet?: (subflows: RunbookNode[]) =>  void;
}

/** Renders the create runbook view.
 *  @param props the properties passed in.
 *  @returns JSX with the create runbook view component.*/
const CreateRunbookView = (props: CreateRunbookViewProps) => {
	const userPreferences = useUserPreferences({listenOnlyTo: {subflows: {isMultipleOutputsEnabled: false}, runbookImportExportMethod:{useApiImportExportRunbook: false}}});
    const useApiImportExportRunbook = Boolean(userPreferences?.runbookImportExportMethod?.useApiImportExportRunbook);
    const appInsightsContext = useAppInsightsContext();
    const history = useHistory();
    const { params, setQueryParams } = useQueryParams({ listenOnlyTo: [PARAM_NAME.rbConfigId, PARAM_NAME.rbConfigNm, PARAM_NAME.devel, PARAM_NAME.triggerType] });
    const currentRunbook = useRef<RunbookInfo>(params[PARAM_NAME.rbConfigId] && params[PARAM_NAME.rbConfigNm] ? { id: params[PARAM_NAME.rbConfigId], label: params[PARAM_NAME.rbConfigNm] } : NEW_RUNBOOK);
    const [loadRunbooks, setLoadRunbooks] = useState<boolean>(true);
    const [loadRunbook, setLoadRunbook] = useState<boolean>(false);
    const allowAutomation = useRef<boolean>((IS_EMBEDDED || IS_CREATED_FROM_WIZARD) ? true : false);
    const showAllowAutomation = true;

    // Determines whether debug information should be displayed
    const showDevelControls = (INCLUDE_RUNBOOK_DEV || params[PARAM_NAME.devel] === "true");
    const initTriggerType = params[PARAM_NAME.triggerType] || (props.variant === Variant.SUBFLOW ? InputType.SUBFLOW : undefined) || (props.variant === Variant.ON_DEMAND ? InputType.ON_DEMAND : undefined);

    const [showErrors, setShowErrors] = useState<boolean>(params[PARAM_NAME.rbConfigId] && params[PARAM_NAME.rbConfigId] !== NEW_RUNBOOK.id ? true : false);

    const reactFlowGraphComponent = useRef<any>(null);
    const nodeEditorPanelComponent = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const graphDefHistoryLocation = useRef<number>(-1);
    const graphDefHistory = useRef<Array<GraphDef>>([]);
    const [graphDef, setGraphDefState] = useState<GraphDef>(INIT_GRAPH_DATA);
    const graphDefRef = useRef<GraphDef>(graphDef);
    function setGraphDef (graphDef: GraphDef) {
        // Adding/Removing skeleton node will add the same graphDef entry, so we will skip it
        const isSame = JSON.stringify(graphDef) === JSON.stringify(graphDefRef.current);
        if (isSame) {
            return;
        }
        
        graphDefRef.current = graphDef;

        if (graphDefHistoryLocation.current < graphDefHistory.current.length - 1) {
            // We have been moving through the history, remove anything after the current index and start adding at that poing
            graphDefHistory.current.splice(graphDefHistoryLocation.current + 1, graphDefHistory.current.length - graphDefHistoryLocation.current - 1);
        }

        graphDefHistory.current.push(graphDef);
        graphDefHistoryLocation.current = graphDefHistory.current.length - 1;
        setGraphDefState(graphDef);
    };
    function resetHistory() {
        graphDefHistory.current = [];
        graphDefHistoryLocation.current = graphDefHistory.current.length - 1;
    }

    // Get the runbook that should be linked in to this view
    const [runbooks, setRunbooks] = useState<Array<RunbookInfo>>([]);
    const [subflows, setRunbookSubflows] = useState<Array<RunbookNode> | undefined>();
    const [runbookModified, setRunbookModifiedUseState] = useState<boolean>(false);
    const setRunbookModified = (modified: boolean): void => {
        setRunbookModifiedUseState(modified);
        // Save Button state depends on runbookModified: disableSave={!props.runbookModified}
        if (IS_EMBEDDED) {
            const message = modified ? 'runbookModified' : 'runbookSaveDisabled';
            AuthService.getToken(); // refresh token in embedded view
            sendNotificationToParentWindow(message, currentRunbook.current.id, currentRunbook.current?.label, params[PARAM_NAME.rbConfigNm]);
        }
    };

    const [updateNode, setUpdateNode] = useState<Node | null>(null);
    const [updateIndex, setUpdateIndex] = useState<number>(-1);
    const [executeSafely] = useStateSafePromise();
    const [doUtilsInitialized, setDOUtilsInitialized] = useState(false);

    useEffect(
        () => {
            const unloadHandler = (event) => {
                if (runbookModified) {
                    event.returnValue = STRINGS.formatString(STRINGS.runbookEditor.unsavedChangesWarning, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[props.variant]});
                }
            };
            window.addEventListener('beforeunload', unloadHandler);
            return () => {
                window.removeEventListener("beforeunload", unloadHandler);
            }
        }, 
        [runbookModified, props.variant]
    );

    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(() => {
            setDOUtilsInitialized(true);
        });
    }, [setDOUtilsInitialized, executeSafely]);
    
    const {nodeLibrary, onSubflowsSet, variant} = props;

    /** Update subflow nodes with Integration Details */
    useEffect(() => {
        const subflowPromise = variant !== Variant.SUBFLOW ? new Promise<RunbookNode[]>(async (resolve, reject) => {
            try {
                const subflowVariant = Variant.SUBFLOW;
                const retFlows: RunbookNode[] = await runbookService.getRunbooks(subflowVariant, true);
                const newSubflows = createSubflowNodes(retFlows, subflowNodeLibrary, integrationsCache.current);

                resolve(newSubflows);
            } catch (error) {
                reject(error);
            }
        }) : Promise.resolve([]);
        executeSafely(subflowPromise).then(
            (newSubflows) => {
                setRunbookSubflows(newSubflows);
                if (onSubflowsSet) {
                    onSubflowsSet(newSubflows);
                }
            }, 
            () => {
                setRunbookSubflows([]);
            }
        );
    }, [subflows?.length, nodeLibrary, onSubflowsSet, variant, executeSafely]);

    const [properties, setProperties] = useState<CustomProperty[] | undefined>(undefined);
    const customPropertiesQuery = useCustomProperties({});
    useEffect(
        () => {
            setProperties(customPropertiesQuery.data);
        },
        [customPropertiesQuery.data]
    );

    /**
     * Retrieve the integrations list
     */
    const [integrations, setIntegrations] = useState<RunbookIntegrationDetails[] | undefined>(undefined);
    const integrationsCache = useRef<RunbookIntegrationDetails[] | undefined>();
    useEffect(() => {
        const integrationsPromise = new Promise<RunbookIntegrationDetails[]>(async (resolve, reject) => {
            try {
                const newIntegrations = await IntegrationLibraryService.getRunbookIntegrationsAndConnectors();
                resolve(newIntegrations as RunbookIntegrationDetails[]);    
            } catch (error) {
                reject(error);
            }
        });
        executeSafely(integrationsPromise).then(
            (integrations) => {
                integrationsCache.current = integrations;
                setIntegrations(integrations);
            }, 
            () => {
                integrationsCache.current = integrations;
                setIntegrations([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => {
            async function retrieveRunbookList() {
                const newFlows = [NEW_RUNBOOK];
                let newActiveRunbook = NEW_RUNBOOK;
                try {
                    let retFlows;
                    const runbookVariant = props.variant === Variant.EXTERNAL ? Variant.INCIDENT : props.variant;
                    retFlows = await runbookService.getRunbooks(runbookVariant, false);
                    if (retFlows) {
                        for (const flow of retFlows) {
                            const flowInfo: RunbookInfo = { id: flow.id, label: flow.name || "Unknown", info: flow.description || "" };
                           
                            if (flow.isReady !== null && flow.isReady !== undefined) {
                                flowInfo.disabled = !flow.isReady;
                            }
                            if (flow.triggerType) {
                                flowInfo.triggerType = flow.triggerType;
                            }
                            if (flow.i18nNameKey) {
                                flowInfo.i18nNameKey = flow.i18nNameKey;
                            }
                            if (flow.i18nInfoKey) {
                                flowInfo.i18nInfoKey = flow.i18nInfoKey;
                            }

                            flowInfo.isFactory = flow.isFactory;

                            if (flow.version) {
                                flowInfo.origVersion = flow.version;
                                flowInfo.version = flow.version;
                            }

                            if (flow.seriesId) {
                                flowInfo.seriesId = flow.seriesId;
                            }

                            if (flow.otherVersions) {
                                flowInfo.otherVersions = flow.otherVersions;
                                // Push this version in
                                flowInfo.otherVersions.push(
                                    {id: flow.id, name: flow.name, description: flow.description, version: flow.version}
                                );
                            }

                            if (flow.eTag) {
                                flowInfo.eTag = flow.eTag;
                            }

                            if (flow[getVariablesKey(variant)]) {
                                flowInfo[getVariablesKey(variant)] = flow[getVariablesKey(variant)];
                            }

                            if (variant === Variant.ON_DEMAND) {
                                flowInfo.isScheduled = flow.isScheduled;
                            }
                            
                            newFlows.push(flowInfo);
                            if (flowInfo.id === currentRunbook.current.id) {
                                newActiveRunbook = flowInfo;
                            } else if (flowInfo.otherVersions?.length && flowInfo.otherVersions.find(versionInfo => versionInfo.id === currentRunbook.current.id)) {
                                const versionInfo = flowInfo.otherVersions.find(versionInfo => versionInfo.id === currentRunbook.current.id);
                                // Need to update the other versions with the current varsion
                                // I don't like this
                                flowInfo.id = versionInfo.id;
                                flowInfo.label = versionInfo.name;
                                flowInfo.info = versionInfo.description;
                                flowInfo.origVersion = versionInfo.version;
                                flowInfo.version = versionInfo.version;
                                flowInfo.eTag = "getting from other version";
                                newActiveRunbook = flowInfo;
                            } else if (params[PARAM_NAME.rbConfigId] === undefined && flowInfo.label === params[PARAM_NAME.rbConfigNm]) {
                                // URL just has name, try best effort name match and then set the current runbook
                                newActiveRunbook = flowInfo;
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
                setRunbooks(newFlows);
                //setRunbookSubflows(newSubflows);
                currentRunbook.current = newActiveRunbook;
                setLoadRunbook(true);
                setLoading(newActiveRunbook === NEW_RUNBOOK ? false : true);
                // If we do not match the current query parameters update them
                setQueryParams({ [PARAM_NAME.rbConfigId]: newActiveRunbook.id, [PARAM_NAME.rbConfigNm]: newActiveRunbook.label }, true);
            }

            if (loadRunbooks) {
                setLoadRunbooks(false);
                retrieveRunbookList();
                setLoading(true);
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadRunbooks]
    );

    useEffect(
        () => {
            async function retrieveRunbook() {
                try {
                    let runbook = await runbookService.getRunbook(currentRunbook.current.id);
                    if (runbook) {
                        const variables: VariableContextByScope = {
                            runtime: getVariables(RUNTIME_SCOPE, true),
                            incident: getVariables(INCIDENT_SCOPE, true),
                            global: getVariables(GLOBAL_SCOPE, true)
                        };   
                        resetHistory();
                        const newGraphDef = getGraphDefFromRunbookConfig(props.nodeLibrary, runbook, subflows, integrations);
                        removeWhitespaceFromGraphDef(newGraphDef);
                        updateGraphDefWithErrors(props.nodeLibrary, newGraphDef, runbook, variables, properties || [], subflows, props.variant);
                        setGraphDef(newGraphDef);
                        setShowErrors(true);
                        
                        if (runbook.eTag && currentRunbook.current?.id === runbook.id) {
                            // Update the etag in case this is an older version of the runbook, in that 
                            // case we would not have gotten the etag from the runbook list.
                            currentRunbook.current.eTag = runbook.eTag;
                        }

                        if (runbook.isReady) {
                            allowAutomation.current = true;
                        }

                        if (props.onActiveRunbookChanged) {
                            props.onActiveRunbookChanged(runbook);
                        }
                    }
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                    setLoading(false);
                }
            }
            if (doUtilsInitialized && subflows && loadRunbook) {
                setLoadRunbook(false);
                const variables: VariableContextByScope = {
                    runtime: getVariables(RUNTIME_SCOPE, true),
                    incident: getVariables(INCIDENT_SCOPE, true),
                    global: getVariables(GLOBAL_SCOPE, true)
                };                    
                if (props.runbookConfig) {
                    resetHistory();
                    currentRunbook.current = { 
                        id: props.runbookConfig.id || "", 
                        label: props.runbookConfig.name || "Unknown", 
                        info: props.runbookConfig.description || "",
                        triggerType: props.runbookConfig.triggerType,
                        disabled: !props.runbookConfig.isReady,
                        isFactory: props.runbookConfig.isFactory,
                        version: props.runbookConfig.version,
                        origVersion: props.runbookConfig.origiVersion,
                        otherVersions: props.runbookConfig.otherVersions,
                        seriesId: props.runbookConfig.seriesId,
                        eTag: props.runbookConfig.eTag,
                        [getVariablesKey(variant)]: props.runbookConfig[getVariablesKey(variant)],
                    };
                    const newGraphDef = getGraphDefFromRunbookConfig(props.nodeLibrary, props.runbookConfig!, subflows, integrations);
                    removeWhitespaceFromGraphDef(newGraphDef);
                    updateGraphDefWithErrors(props.nodeLibrary, newGraphDef, props.runbookConfig!, variables, properties || [], subflows, props.variant);
                    setGraphDef(newGraphDef);
                    setShowErrors(true);
                    
                    if (typeof props.runbookConfig.isReady === "boolean") {
                        allowAutomation.current = props.runbookConfig.isReady;
                    }

                    if (props.onActiveRunbookChanged) {
                        props.onActiveRunbookChanged(props.runbookConfig!);
                    }
                } else if (currentRunbook.current.id !== "0") {
                    retrieveRunbook();
                } else {
                    setGlobalNodes([] as Array<NodeDef>);
                    resetHistory();
                    if (currentRunbook.current.id !== "0") {
                        setGraphDef(clearNodes());
                    } else {
                        const initGraphDef = createBasicRunbook(initTriggerType);
                        updateGraphDefWithErrors(props.nodeLibrary, initGraphDef, props.runbookConfig!, variables, properties || [], subflows, props.variant);
                        setGraphDef(initGraphDef);
                        if (currentRunbook.current && initGraphDef?.nodes?.length) {
                            currentRunbook.current.triggerType = (initGraphDef?.nodes![0].properties![0].value || InputType.INTERFACE) as InputType;
                        }
                    }
                    setShowErrors(false);
                }
                setRunbookModified(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [doUtilsInitialized, subflows, loadRunbook]
    );

    // Get the runbook runbook that should be linked in to this view
    const [selectedNode, setSelectedNode] = useState<UniversalNode | undefined>(undefined);
    const [selectedNodeIDs, setSelectedNodeIDs] = useState<Array<string>>([]);
    const libraryNode: NodeLibraryNode | undefined = (selectedNode ? getLibraryNode(
        props.nodeLibrary, selectedNode.getType() || "", subflows, selectedNode.getProperties(), integrations) || undefined : undefined
    );
    const nodeName = (libraryNode && (
        STRINGS.runbookEditor?.nodeLibrary?.nodes[libraryNode.type]?.name ||
        libraryNode.subflowName ||
        libraryNode.type)) || "";

    //const globalNodes = useRef<Array<NodeDef>>([]);
    const [globalNodes, setGlobalNodes] = useState<Array<NodeDef>>([]);
    const [activeTab, setActiveTab] = useState<string>("props");

    const [multiSelect, setMultiSelect] = useState<boolean>(false);
    const [showBlade, setShowBlade] = useState<boolean>(false);
    const [showErrorBlade, setShowErrorBlade] = useState<boolean>(false);
    const [getVariables, setVariables, syncRuntimeOrSubflowVariables] = useVariables({runbookInfo: currentRunbook.current, variant: props.variant});
    const variablesContext = useMemo(() => {
        return {getVariables, setVariables, syncRuntimeOrSubflowVariables}
    }, [getVariables, setVariables, syncRuntimeOrSubflowVariables])

    // A flag ref to keep a record of when we have simulated a shift key down action and not a shift key up action yet
    const shiftDownEventDispatched = useRef(false);
    useEffect(() => {
        // If multiSelect is activated (multiSelect flag is true) and we haven't dispatched a shift down event yet
        if (multiSelect && !shiftDownEventDispatched.current) {
            const shiftDownEvent = new KeyboardEvent("keydown", {
                bubbles : true,
                cancelable : true,
                shiftKey : true,
                key: "Shift",
                code: "ShiftLeft",
            });
            // Dispatching shift key down event
            document.body.dispatchEvent(shiftDownEvent);
            shiftDownEventDispatched.current = true;
            // When user performs a mouse up (either by dragging a selection box or clicking
            // somewhere else to continue with a different action instaead), then dispatch a
            // shift up event.
            const onMouseUp = e => {
                const shiftUpEvent = new KeyboardEvent("keyup", {
                    bubbles : true,
                    cancelable : true,
                    shiftKey : true,
                    key: "Shift",
                    code: "ShiftLeft",
                });
                // Dispatching shift key up event
                document.body.dispatchEvent(shiftUpEvent);
                shiftDownEventDispatched.current = false;
                window.removeEventListener('mouseup', onMouseUp);
                setMultiSelect(false);
            }
            window.addEventListener('mouseup', onMouseUp);
        }
        // Fix the issue of the runbook graph area becoming frozen (no clicks allowed) after pressing Cmd + shift + 4 on mac
        window.addEventListener('keydown', function(event) {
            // check if Cmd + shift keys were pressed
            if (event.metaKey && event.shiftKey) {
                // triggering a shift key press event will unfreeze the runbook graph area
                const shiftUpEvent = new KeyboardEvent("keyup", {
                    bubbles : true,
                    cancelable : true,
                    shiftKey : true,
                    key: "Shift",
                    code: "ShiftLeft",
                });
                document.body.dispatchEvent(shiftUpEvent);
            }
        });
    });
    const handleToolbarAction = (action: string, value: any): void => {
        const variables: VariableContextByScope = {
            runtime: getVariables(RUNTIME_SCOPE, true),
            incident: getVariables(INCIDENT_SCOPE, true),
            global: getVariables(GLOBAL_SCOPE, true)
        };
        switch (action) {
            case ToolbarAction.CLEAR_RUNBOOK:
                setGlobalNodes([] as Array<NodeDef>);
                setGraphDef(clearNodes());
                setRunbookModified(true);
                break;
            case ToolbarAction.RELOAD_INIT_RUNBOOK:
                setGlobalNodes([] as Array<NodeDef>);
                setGraphDef(resetNodes());
                break;
            case ToolbarAction.RUNBOOK_SELECTED:
                const runbook = onRunbookSelected(value as string, runbooks);
                history.push(getURL(getURLPath("create-runbook"), { [PARAM_NAME.rbConfigId]: runbook.id, [PARAM_NAME.rbConfigNm]: runbook.label }));
                // If a browser warning about URL changing was displayed and user clicked OK, then the query param would've been updated to the new ID
                if (getQueryParam(PARAM_NAME.rbConfigId) === runbook.id) {
                    currentRunbook.current = runbook;
                    setLoadRunbook(true);
                    if (runbook.id === NEW_RUNBOOK.id) {
                        const initGraphDef = createBasicRunbook(initTriggerType);
                        if (currentRunbook.current && initGraphDef?.nodes?.length) {
                            currentRunbook.current.triggerType = (initGraphDef?.nodes![0].properties![0].value || InputType.INTERFACE) as InputType;
                        }
                        resetToGraphDef(initGraphDef);
                    }
                }
                break;
            case ToolbarAction.RESET_RUNBOOK:
                if (IS_EMBEDDED) {
                    sendNotificationToParentWindow('runbookReset', currentRunbook.current?.id, currentRunbook.current?.label)
                }
                break;
            case ToolbarAction.IMPORT_RUNBOOK:
            case ToolbarAction.EXPORT_RUNBOOK:
            case ToolbarAction.SYNC_RUNBOOK:
            case ToolbarAction.SETTINGS_RUNBOOK:
            case ToolbarAction.DELETE_RUNBOOK:
                const newDialogState = Object.assign({}, dialogState);
                newDialogState.showDialog = true;
                newDialogState.loading = false;
                newDialogState.errors = [];
                if (action === ToolbarAction.IMPORT_RUNBOOK) {
                    importContent(
                        props.nodeLibrary,
                        setGraphDef, setGlobalNodes, subflows, props.variant,
                        newDialogState, setDialogState, setRunbookModified, currentRunbook, variables, properties || [], integrations
                    );
                } else if (action === ToolbarAction.EXPORT_RUNBOOK) {
                    useApiImportExportRunbook ? 
                        exportContent(currentRunbook.current?.id, setDialogState, variant):
                        exportJSONContent(
                            graphDef, globalNodes, reactFlowGraphComponent.current, currentRunbook.current, props.variant, 
                            newDialogState, setDialogState, allowAutomation
                        )
                } else if (
                    action === ToolbarAction.SYNC_RUNBOOK || action === ToolbarAction.SETTINGS_RUNBOOK
                ) {
                    // Set the new runtime variables
                    currentRunbook.current = {...currentRunbook.current, [getVariablesKey(variant)]: getVariables(RUNTIME_SCOPE)};
                    setVariables(RUNTIME_SCOPE);

                    syncContent(
                        props.nodeLibrary,
                        graphDef, reactFlowGraphComponent.current, currentRunbook, 
                        setQueryParams, setLoadRunbooks, newDialogState, dialogStateRef, setDialogState,
                        action === ToolbarAction.SYNC_RUNBOOK, setRunbookModified, 
                        setShowErrors, appInsightsContext, showErrorBlade, setShowErrorBlade, variables, properties || [], 
                        subflows, props.variant,
                        showAllowAutomation, allowAutomation, userPreferences
                    );
                } else if (action === ToolbarAction.DELETE_RUNBOOK) {
                    deleteContent(currentRunbook, setLoadRunbooks, setQueryParams, newDialogState, setDialogState, props.variant);
                }
                break;
            case ToolbarAction.VIEW_RUNBOOK:
                props.onPreview(getRunbookConfigJson(
                    graphDef, reactFlowGraphComponent.current, currentRunbook.current, currentRunbook.current?.label || "", 
                    currentRunbook.current?.info || "", currentRunbook.current.triggerType || InputType.INTERFACE, props.variant, 
                    allowAutomation
                ));
                break;
            case ToolbarAction.TEST_RUNBOOK:
                if (!runbookModified && currentRunbook.current.id !== "0") {
                    props.onTest(getRunbookConfigJson(
                        graphDef, reactFlowGraphComponent.current, currentRunbook.current, currentRunbook.current?.label || "", 
                        currentRunbook.current?.info || "", currentRunbook.current.triggerType || InputType.INTERFACE, props.variant,
                        allowAutomation
                    ));    
                } else {
                    const newDialogState = Object.assign({}, dialogState);
                    newDialogState.showDialog = true;
                    newDialogState.loading = false;
                    newDialogState.errors = [];
                    newDialogState.title = STRINGS.runbookEditor.testDialog.title;
                    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.runbookEditor.testDialog.text}</span></div>;
                    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
                        setDialogState(updateDialogState(newDialogState, false, false, []));
                    }} text={STRINGS.runbookEditor.testDialog.btnText} />;
                    setDialogState(newDialogState);
                }
                break;
            case ToolbarAction.LAYOUT:
            case ToolbarAction.AUTO_LAYOUT:
                setRunbookModified(true);
                break;
            case ToolbarAction.ERRORS_AND_WARNINGS:
                setShowBlade(false);
                setShowErrors(true);
                setShowErrorBlade(true);
                break;
            case ToolbarAction.MULTI_SELECT:
                setMultiSelect(value);
                break;
            case ToolbarAction.UNDO:
                if (graphDefHistoryLocation.current > 0) {
                    graphDefHistoryLocation.current--
                    const newGraphDef = graphDefHistory.current[graphDefHistoryLocation.current];
                    newGraphDef.silent = false;
                    graphDefRef.current = newGraphDef;
                    generateRunbookConfigAndUpdateGraphDefWithErrors(
                        props.nodeLibrary, newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, 
                        props.variant, allowAutomation, getVariables, properties || [], subflows
                    );
                    setGraphDefState(newGraphDef);
                    setRunbookModified(true);
                    closeNodeEditor();
                }
                break;
            case ToolbarAction.REDO:
                if (graphDefHistoryLocation.current < graphDefHistory.current.length - 1) {
                    graphDefHistoryLocation.current++;
                    const newGraphDef = graphDefHistory.current[graphDefHistoryLocation.current];
                    newGraphDef.silent = false;
                    graphDefRef.current = newGraphDef;
                    generateRunbookConfigAndUpdateGraphDefWithErrors(
                        props.nodeLibrary, newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, 
                        props.variant,allowAutomation, getVariables, properties || [], subflows
                    );
                    setGraphDefState(newGraphDef);    
                    closeNodeEditor();
                }
                break;
        }
    }
    
    let initDialogState: DialogState = {showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null};
    if (!canEditRunbooks()) {
        initDialogState = {
            showDialog: true, title: STRINGS.runbookEditor.noEditTitle, loading: false, 
            dialogContent: <div className="mb-3"><span>{STRINGS.runbookEditor.noEditText}</span></div>, 
            dialogFooter: <Button active={true} outlined={true} 
                text={STRINGS.runbookEditor.noEditBtnText} onClick={() => {
                setDialogState(updateDialogState(initDialogState, false, false, []));
            }}
        />
        };
    }
    // A reference to the dialog state so we can check it during async dialog operations
    const dialogStateRef = useRef<DialogState>(initDialogState);
    // The current dialog state
    const [dialogState, setDialogStateUseState] = useState<DialogState>(initDialogState);
    // A wrapper around the useState set state function so we can record the dialogStateRef with the latest state.
    const setDialogState = (dState: DialogState): void => {
        dialogStateRef.current = dState;
        setDialogStateUseState(dState);
    };

    const nodeOperationInProgress = useRef(false);
    const originalSubflowName = useRef<string | undefined>("");
    const originalSubflowVersion = useRef<string | undefined>("");

    const closeNodeEditorPanel = useCallback(() => {
        setSelectedNode(undefined);
        setSelectedNodeIDs([]);
        setShowBlade(false);
    }, [setSelectedNode, setSelectedNodeIDs, setShowBlade]);

    const updateSelectedNodeState = useCallback((newSelectedNode, openNodeEditorBlade = false) => {
        setSelectedNode(new UniversalNode(newSelectedNode, "react-flow"));
        if (openNodeEditorBlade || (editNodeOnSelection && !multiSelect)) {
            setShowErrorBlade(false);
            setShowBlade(true);
            setActiveTab("props");
        }
        setSelectedNodeIDs([newSelectedNode.id]);
    }, [setSelectedNode, setSelectedNodeIDs, setShowBlade, multiSelect]);

    if (loading || !doUtilsInitialized || !subflows || !properties || !integrations) {
        return <DataLoadFacade loading transparent></DataLoadFacade>;
    }

    function closeNodeEditor (checkForChanges = false) {
        return new Promise(resolve => {
            if (showBlade === false) {
                resolve(true);
            } else if (checkForChanges && nodeEditorPanelComponent.current?.state?.valueChanged) {
                setDialogState({
                    showDialog: true,
                    title: STRINGS.runbookEditor.unsavedNodeChangesTitle,
                    dialogContent: STRINGS.runbookEditor.unsavedNodeChangesWarning,
                    closeable: true,
                    dialogFooter: <div className="d-flex justify-content-between flex-grow-1">
                        <Button
                            className="ml-0"
                            text={STRINGS.runbookEditor.discardAndCloseBtn}
                            intent={Intent.DANGER}
                            onClick={() => {
                                setDialogState({ ...dialogState, showDialog: false, closeable: true });
                                closeNodeEditor().then(resolve);
                            }}
                        />
                        <Button
                            icon={IconNames.SAVED}
                            text={STRINGS.runbookEditor.saveAndCloseBtn}
                            intent={Intent.SUCCESS}
                            onClick={() => {
                                nodeEditorPanelComponent.current?.saveChanges();
                                setDialogState({ ...dialogState, showDialog: false, closeable: true });
                                closeNodeEditor().then(resolve);
                            }}
                        />
                    </div>,
                } as DialogState);
            } else {
                closeNodeEditorPanel();
                resolve(true);
            }
        })
    }

    function resetToGraphDef (newGraphDef) {
        closeNodeEditor(true).then(() => {
            resetHistory();
            setGraphDef(newGraphDef);
            setRunbookModified(false);
        });
    }

    const activeEditedNodeOutputs = [...(selectedNode?.getProperty("outputs") || [])];

    const onRuntimeOrSubflowVariableEdited = (updatedVariablesList) => {
        currentRunbook.current = {...currentRunbook.current, [getVariablesKey(variant)]: updatedVariablesList};
        const newGraphDef = JSON.parse(JSON.stringify(graphDefRef.current));
        generateRunbookConfigAndUpdateGraphDefWithErrors(
            props.nodeLibrary, newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, props.variant, 
            allowAutomation, getVariables, properties || [], subflows
        );
        setGraphDefState(newGraphDef);
        setRunbookModified(true)
    };

    const showTabs = (INCLUDE_RUNBOOK_DEV || showDevelControls);
    const nodeEditorPanel = () => {
        return <VariableContext.Provider value={variablesContext}><NodeEditorPanel
            showNodeErrors={showErrors}
            ref={nodeEditor => nodeEditorPanelComponent.current = nodeEditor}
            graphDef={graphDef}
            activeRunbook={currentRunbook.current}
            selectedNode={selectedNode}
            libraryNode={libraryNode} globalNodes={globalNodes}
            nodeLibrary={props.nodeLibrary}
            variant={props.variant}
            subflows={subflows}
            onNodeEdited={async function (node) {
                // When user clicks "Save" in node editor
                await closeNodeEditor();
                setUpdateNode(node && node.vendor === "react-flow" ? node.node as Node : null);

                const newGraphDef: GraphDef = JSON.parse(JSON.stringify(graphDef));
                let updatedListOfEdges: EdgeDef[] = [];
                const editedNodeID = node.getId();
                const outputPortsCount = node.getWires()?.outputsCount || 0;
                const edgeOperationsMap = {};
                const updatedNodeOutputs = node.getProperty("outputs") || [];

                // This flag controls whether we should re-order the edges connected from output ports
                // to move along wth the port if the actions done in the node caused the order to change.
                // This currently applies to the decision branch node.
                let userWantsWiresToAutoReOrder = true;

                for (let newIndex = 0; newIndex < updatedNodeOutputs.length; newIndex++) {
                    const output = updatedNodeOutputs[newIndex];
                    const oldIndex = activeEditedNodeOutputs.findIndex(o => o.id === output.id);
                    // If this output has been moved to a new position
                    if (oldIndex >= 0 && oldIndex !== newIndex) {
                        edgeOperationsMap[oldIndex] = {
                            action: "move",
                            toIndex: String(newIndex),
                        };
                    }
                }
                // TBD: If in the future we want to provide the user an option of choosing between
                // auto-reordering wires and staying the same way, this would be the place to do it.
                // if (Object.keys(edgeOperationsMap).length > 0) {
                //     userWantsWiresToAutoReOrder = await ShowFancyDialog("Do you want wires to be re-ordered?", "Yes", "No");
                // }
                for (let oldIndex = 0; oldIndex < activeEditedNodeOutputs.length; oldIndex++) {
                    const output = activeEditedNodeOutputs[oldIndex];
                    if (oldIndex > outputPortsCount ||
                        (userWantsWiresToAutoReOrder && updatedNodeOutputs.findIndex(o => o.id === output.id) === -1)) {
                        edgeOperationsMap[oldIndex] = {
                            action: "remove",
                        };
                    }
                }
                for (const edge of newGraphDef.edges) {
                    if (edge.fromNode === editedNodeID && edge.fromPort && edgeOperationsMap[edge.fromPort]) {
                        const operation = edgeOperationsMap[edge.fromPort];
                        if (operation) {
                            if (operation.action === "remove") {
                                continue;
                            } else if (userWantsWiresToAutoReOrder && operation.action === "move") {
                                edge.fromPort = operation.toIndex;
                                updatedListOfEdges.push(edge);
                            }
                        }
                    } else {
                        updatedListOfEdges.push(edge);
                    }
                }
                newGraphDef.edges = updatedListOfEdges;
                for (const graphNode of newGraphDef.nodes) {
                    if (graphNode.id === node.getId()) {
                        graphNode.name = node.getName() || "";
                        graphNode.properties = node.getProperties();
                        graphNode.env = node.getEnvSettings();
                        const wires = node.getWires();
                        if (wires) {
                            graphNode.wires = wires;
                        }
                        break;
                    }
                }
                newGraphDef.silent = true;
                generateRunbookConfigAndUpdateGraphDefWithErrors(
                    props.nodeLibrary, newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, props.variant, 
                    allowAutomation, getVariables, properties || [], subflows
                );
                setGraphDef(newGraphDef);
                if (node && node.vendor === "config") {
                    setActiveTab("layout");
                }
                setRunbookModified(true);
                setUpdateIndex(updateIndex + 1);
                if (props.onChange) {
                    const newRunbookConfig = getRunbookConfigJson(
                        graphDef, reactFlowGraphComponent.current, currentRunbook.current, currentRunbook.current?.label || "", 
                        currentRunbook.current?.info || "", currentRunbook.current.triggerType || InputType.INTERFACE, props.variant,
                        allowAutomation
                    );
                    if (newRunbookConfig?.nodes) {
                        for (const confNode of newRunbookConfig.nodes) {
                            if (confNode.id === node.getId()) {
                                for (const property of node.getProperties()) {
                                    if (property.value !== null && property.value !== undefined) {
                                        confNode.properties[property.key] = property.value;
                                    }
                                }
                                //confNode.name = node.getName() || "";
                                //newRunbookConfig.properties = node.getProperties();
                                //newRunbookConfig.env = node.getEnvSettings();
                                //const wires = node.getWires();
                                //if (wires) {
                                //    graphNode.wires = wires;
                                //}
                                break;
                            }    
                        }    
                    }
                    props.onChange(newRunbookConfig);
                }
            }}
            onCancel={closeNodeEditor}
            onRuntimeOrSubflowVariableEdited={onRuntimeOrSubflowVariableEdited}
        /></VariableContext.Provider>
    }
    if (selectedNode?.node?.type && selectedNode.node.type === "subflow") {
        let subflowConfigurationId: string | undefined;
        const {data: selectedNodeData}: any = selectedNode.node;
        if (selectedNodeData && selectedNodeData?.properties?.length) {
            const subflowConfigurationIdProperty = selectedNodeData.properties.find(property => property.key === "configurationId");
            subflowConfigurationId = subflowConfigurationIdProperty.value;
        }
        if (subflows?.length && subflowConfigurationId) {
            for (const subflow of subflows) {
                if (subflow.id === subflowConfigurationId) {
                    originalSubflowName.current = subflow.name;
                    originalSubflowVersion.current = subflow.version;
                    break;
                }
            }
        }
    } else {
        originalSubflowName.current = "";
        originalSubflowVersion.current = "";
    }

    const bladeNodeIntegration = !!libraryNode?.integrationId && integrations.find(el => el.id === libraryNode?.integrationId);
    const bladeIcon = bladeNodeIntegration ? 
                        getIntegrationIcon(
                            bladeNodeIntegration?.branding.icons, 
                            bladeNodeIntegration?.name, 
                            "badge", 
                            bladeNodeIntegration?.branding?.secondaryColor
                        ) : 
                        getIconForNode(libraryNode?.icon) || SDWAN_ICONS.RUNBOOK

    return (<>
        <div data-testid="CreateRunbookView"></div>
        <Prompt
            when={runbookModified}
            message={location => STRINGS.formatString(STRINGS.runbookEditor.unsavedChangesWarning, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[variant]})}
        />
        <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <div className={"runbook-graph w-min-6" + (props.editorHidden ? " d-none" : "")}>
        <CustomPropertyContext.Provider value={properties || []}>
            {showBlade &&
                <DetailsPanel 
                    size={props.inDialog ? SIZE.s : SIZE.l} 
                    anchorElement={props.inDialog ? "dialog-details-pane" : undefined}
                    resizableElement={props.inDialog ? "resizable-dialog-details-panel" : undefined}
                    visible={!props.editorHidden}
                >
                    <BladeContainer
                        icon={bladeIcon}
                        title={<div className={"text-nowrap text-truncate" + (!props.inDialog ? " dinamicTruncate" : "")}>{selectedNode?.getName() || STRINGS.runbookEditor.nodeEditor.title}</div>}
                        subText={<div>
                            <div className="title-holder font-weight-600">{
                                (nodeName ? "(" + STRINGS.runbookEditor.nodeEditor.nodeType + ": " + nodeName + 
                                (originalSubflowName.current ? ") (" + STRINGS.runbookEditor.nodeEditor.subflowOriginalNameLabel + ": " + originalSubflowName.current : "") + ")" : undefined)
                            }</div>
                            {originalSubflowVersion.current && <div className="title-holder font-weight-600">
                                version {Version.parse(originalSubflowVersion.current || "").toString()}
                            </div>}
                        </div>}
                        onCloseClicked={() => closeNodeEditor(true)}
                        noContentPadding
                        showIconWithoutBg={!!bladeNodeIntegration}
                    >
                        {showTabs ?
                            <TabbedSubPages selectedTabId={activeTab} onTabChange={setActiveTab} dontUpdateURL childClassName="p-3">
                                <Tab id="props" title={STRINGS.runbookEditor.propsTabText}>
                                    {nodeEditorPanel()}
                                </Tab>
                            {INCLUDE_RUNBOOK_DEV && <Tab id="layout" title={STRINGS.runbookEditor.layoutTabText}>
                                <LayoutEditorPanel
                                    nodeLibrary={props.nodeLibrary}
                                    GraphDef={graphDef}
                                    globalNodes={globalNodes}
                                    onGlobalLayoutNodeCreated={(node: NodeDef) => {
                                        setGlobalNodes(addGlobalConfigNode(node, globalNodes));
                                    }}
                                    onGlobalLayoutNodeDeleted={(id: string) => {
                                        setGlobalNodes(deleteGlobalConfigNode(id, globalNodes));
                                    }}
                                    onGlobalLayoutNodeEdited={(id: string) => {
                                        for (const globalNode of globalNodes) {
                                            if (globalNode.id === id) {
                                                setSelectedNode(new UniversalNode(globalNode, "config"));
                                                setSelectedNodeIDs([id]);
                                                setActiveTab('props');
                                            }
                                        } 
                                    }}
                                />
                            </Tab>}
                            {showDevelControls && <Tab id="data" title={STRINGS.runbookEditor.graphDataTabText}>
                                <GraphDataPanel
                                    GraphDef={graphDef}
                                    globalNodes={globalNodes}
                                />
                            </Tab>}
                        </TabbedSubPages>
                    : <div className="p-3"> {nodeEditorPanel()} </div>}
                    </BladeContainer>
                </DetailsPanel>
            }
            {showErrorBlade && !props.editorHidden &&
                <DetailsPanel 
                    size={props.inDialog ? SIZE.s : SIZE.l} 
                    anchorElement={props.inDialog ? "dialog-details-pane" : undefined}
                    resizableElement={props.inDialog ? "resizable-dialog-details-panel" : undefined}
                >
                    <BladeContainer
                        className="error-blade"
                        //icon={getIconForNode(libraryNode?.icon) || SDWAN_ICONS.RUNBOOK}
                        title={<div className={"text-nowrap text-truncate" + (!props.inDialog ? " dinamicTruncate" : "")}>{"Errors"}</div>}
                        onCloseClicked={() => {
                            //setSelectedNode(undefined);
                            //setSelectedNodeIDs([]);
                            setShowErrorBlade(false);
                        }}
                        noContentPadding
                    >
                        <GraphErrorPanel 
                            variant={props.variant}
                            graphDef={graphDef} showNodeErrors={showErrors} onChangeShowNodeErrors={(show: boolean) => {
                                setShowErrors(show);
                            }} 
                        />
                    </BladeContainer>
                </DetailsPanel>
            }
            {/* {!showBlade && <div className="show-blade-control">
                <span className="bp3-icon-large bp3-icon-double-chevron-left mr-2" onClick={() => setShowBlade(true)}></span>
            </div>} */}
            <div className={"graph-content" + (selectedNodeIDs.length === 0 ? "" : " node-selected") + (props.inDialog ? " in-dialog" : "")}>
                <VariableContext.Provider value={variablesContext}><ReactFlowProvider><ReactFlowGraph graphDef={graphDef} 
                    onGraphComponentCreated={(newGraphComponent) => { reactFlowGraphComponent.current = newGraphComponent }}
                    onReset={() => {
                        if (graphDefHistory.current.length > 0) {
                            const newGraphDef = graphDefHistory.current[0];
                            resetToGraphDef(newGraphDef);
                        }
                    }}
                    onDeleteItems={(items: Elements, deleteElementHandler?: Function) => {
                        nodeOperationInProgress.current = true;
                        const nodeWithActiveEditorDeleted = selectedNode && Boolean(items.find(item => {
                            return item.id === selectedNode?.getId();
                        }));
                        if (nodeWithActiveEditorDeleted) {
                            closeNodeEditor();
                        }
                        const newGraphDef = onDeleteItems(items, graphDefRef.current);
                        if (deleteElementHandler) {
                            deleteElementHandler((elements: Elements) => {
                                removeDefaultProperties(items, newGraphDef, elements);
                            });
                        }
                        // Does this need to be after the trigger node is changed
                        generateRunbookConfigAndUpdateGraphDefWithErrors(
                            props.nodeLibrary, newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, props.variant, 
                            allowAutomation, getVariables, properties || [], subflows
                        );
                        setGraphDef(newGraphDef);
                        if (items && items.length > 0) {
                            for (const item of items) {
                                if (
                                    item.id !== null && item.id !== undefined && !(item as any).source && !(item as any).target &&
                                    triggerNodes.includes(item.data.type)
                                ) {
                                    // The user is deleting a trigger node, try to reset the type
                                    for (const node of newGraphDef.nodes) {
                                        if (triggerNodes.includes(node.type)) {
                                            // Find the first trigger node and update the type with it
                                            const flowInfo: RunbookInfo = JSON.parse(JSON.stringify(currentRunbook.current));
                                            // Clear the flow's trigger type when the trigger node got deleted and there are no trigger nodes left anymore
                                            flowInfo.triggerType = node.properties && node.properties.length > 0 ? node.properties[0].value : undefined;
                                            currentRunbook.current = flowInfo;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        setRunbookModified(true);
                    }}
                    onNodeAdded={(node, isCopy) => {
                        if (!isCopy) {
                            // We need to set the default properties on new nodes, not copies
                            setDefaultPropertiesOnAdd(props.nodeLibrary, node, props.variant, subflows, integrations);
                        }
                        if (skeletonNodes.includes(node.type || '')) {
                            return;
                        }

                        const newGraphDef = onReactFlowNodeAdded(node, graphDefRef.current);
                        generateRunbookConfigAndUpdateGraphDefWithErrors(
                            props.nodeLibrary, newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, props.variant, 
                            allowAutomation, getVariables, properties || [], subflows
                        );
                        setGraphDef(newGraphDef);
                        if (triggerNodes.includes(node.data.type)) {
                            const flowInfo: RunbookInfo = JSON.parse(JSON.stringify(currentRunbook.current));
                            flowInfo.triggerType = node.data.properties[0].value;
                            currentRunbook.current = flowInfo;
                        }
                        setRunbookModified(true);
                        if (props.onChange) {
                            props.onChange(getRunbookConfigJson(
                                newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, currentRunbook.current?.label || "", 
                                currentRunbook.current?.info || "", currentRunbook.current.triggerType || InputType.INTERFACE, props.variant,
                                allowAutomation
                            ));
                        }
                    }}
                    onEdgeAdded={(source, target, sourcePort, targetPort, elements: Elements | undefined) => {
                        const newGraphDef = onReactFlowEdgeAdded(source, target, sourcePort, targetPort, graphDefRef.current);
                        const targetNode = elements?.find(el => el.id === target);
                        // Ignore Skeleton Node edges
                        if (targetNode && skeletonNodes.includes(targetNode.type || '')) {
                            return;
                        }
                        if (elements) {
                            setDefaultNodePropertiesOnConnect(props.nodeLibrary, target, newGraphDef, elements, props.variant, subflows);
                        }
                        generateRunbookConfigAndUpdateGraphDefWithErrors(
                            props.nodeLibrary, newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, props.variant, 
                            allowAutomation, getVariables, properties || [], subflows
                        );
                        setGraphDef(newGraphDef);
                        setRunbookModified(true);
                        if (props.onChange) {
                            props.onChange(getRunbookConfigJson(
                                newGraphDef, reactFlowGraphComponent.current, currentRunbook.current, currentRunbook.current?.label || "", 
                                currentRunbook.current?.info || "", currentRunbook.current.triggerType || InputType.INTERFACE, props.variant,
                                allowAutomation
                            ));
                        }
                    }}
                    onNodeSelectionChanged={async function (nodes) {
                        // If user selects a single node
                        if (nodes.length === 1) {
                            const [selectedNodeFromCallback] = nodes;
                            if (selectedNode?.getId() !== selectedNodeFromCallback.id) {
                                await closeNodeEditor(true);
                                updateSelectedNodeState(selectedNodeFromCallback);
                            }
                        } else {
                            closeNodeEditor(true);
                            // If user isn't attempting multi-selection (nodes.length became zero)
                            // If showBlade was true, calling closeNodeEditorPanel would've been handled already from
                            // within closeNodeEditor method. But in this specific case, showBlade will be false and
                            // so we're calling it from here manually
                            if (nodes.length === 0 && showBlade === false) {
                                closeNodeEditorPanel();
                            }
                        }
                    }}
                    onNodeEdited={async (node) => {
                        nodeOperationInProgress.current = true;
                        await closeNodeEditor(true);
                        updateSelectedNodeState(node, true);
                    }}
                    onNodesMoved={(nodes) => {
                        setGraphDef(onReactFlowNodesMoved(nodes, graphDefRef.current));
                        setRunbookModified(true);
                    }}
                    onRuntimeOrSubflowVariableEdited={onRuntimeOrSubflowVariableEdited}
                    onIncidentVariableEdited={(updatedVariablesList) => {
                        generateRunbookConfigAndUpdateGraphDefWithErrors(
                            props.nodeLibrary, graphDef, reactFlowGraphComponent.current, currentRunbook.current, props.variant, 
                            allowAutomation, getVariables, properties || [], subflows, updatedVariablesList
                        );
                        setGraphDefState(graphDef);
                    }}
                    notifyToolbarAction={handleToolbarAction}
                    nodeLibrary={props.nodeLibrary} runbooks={runbooks} subflows={subflows} activeRunbook={currentRunbook.current} 
                    updateNode={updateNode} updateIndex={updateIndex}
                    runbookModified={runbookModified}
                    selectedNodes={selectedNodeIDs}
                    defaultExpandedCategories={currentRunbook.current.id === NEW_RUNBOOK.id ? ["triggers"] : []}
                    showErrors={showErrors}
                    undoAvailable={graphDefHistoryLocation.current > 0}
                    redoAvailable={graphDefHistoryLocation.current < graphDefHistory.current.length - 1}
                    multiSelect={multiSelect}
                    editorHidden={props.editorHidden}
                    isDevel={showDevelControls}
                    variant={props.variant}
                    exclusions={props.exclusions}
                    integrations={integrations}
                /></ReactFlowProvider></VariableContext.Provider>
            </div>
        </CustomPropertyContext.Provider>
        </div>
    </>);
};

export default CreateRunbookView;

/** returns a clear GraphDef object."
 *  @returns and empty GraphDef object.*/
function clearNodes(): GraphDef {
    const newGraphDef = JSON.parse(JSON.stringify({ nodes: [], edges: [] }));
    return newGraphDef;
}

/** returns the init GraphDef object."
 *  @returns the init GraphDef object.*/
/* istanbul ignore next */
function resetNodes(): GraphDef {
    const newGraphDef = JSON.parse(JSON.stringify(INIT_GRAPH_DATA));
    return newGraphDef;
}

/** handler for the onDeleteItems event from react-flow.
 *  @param items the items to be deleted.
 *  @param graphDef the GraphDef object.
 *  @returns the new GraphDef object.*/
function onDeleteItems(items: Elements, graphDef: GraphDef): GraphDef {
    const newGraphDef = JSON.parse(JSON.stringify(graphDef));
    if (items && items.length > 0) {
        const deletedNodes: Array<string> = [];
        for (const item of items) {
            if (item.id !== null && item.id !== undefined && !(item as any).source && !(item as any).target) {
                deletedNodes.push(item.id);
            }
        }
        for (let i = newGraphDef.nodes.length - 1; i >=0; i--) {
            const id = newGraphDef.nodes[i].id;
            if (deletedNodes.includes(id)) {
                newGraphDef.nodes.splice(i, 1);
                newGraphDef.edges = newGraphDef.edges.filter(
                    edge => edge.fromNode !== id && edge.toNode !== id
                );
                newGraphDef.silent = true;
            }
        }
        for (let i = newGraphDef.edges.length - 1; i >= 0; i--) {
            for (const item of items) {
                if (
                    newGraphDef.edges[i].fromNode === (item as any).source &&
                    newGraphDef.edges[i].toNode === (item as any).target &&
                    (!newGraphDef.edges[i].fromPort || newGraphDef.edges[i].fromPort === (item as any).sourceHandle) &&
                    (!newGraphDef.edges[i].toPort || newGraphDef.edges[i].toPort === (item as any).targetHandle)
                ) {
                    newGraphDef.edges.splice(i, 1);
                    newGraphDef.silent = true;
                    break;
                }
            }
        }

    }
    return newGraphDef;
}

/** handler for the onNodeAdded event from the react-flow ui.
 *  @param node the node that was added.
 *  @param graphDef the GraphDef object.
 *  @returns the new GraphDef object.*/
function onReactFlowNodeAdded(node: Node, graphDef: GraphDef): GraphDef {
    const newGraphDef = JSON.parse(JSON.stringify(graphDef));
    let properties = node.data.properties || [];
    properties = JSON.parse(JSON.stringify(properties));
    newGraphDef.nodes = newGraphDef.nodes.concat(
        { 
            id: node.id, name: node.data.label, type: node.data.type, info: node.data.info, 
            color: node.data.color, icon: node.data.icon, wires: node.data.wires,
            x: node.position.x, y: node.position.y, properties, editedByUser: node.data.editedByUser
        }
    );
    newGraphDef.silent = true;
    return newGraphDef;
}

/** handler for the onEdgeAdded event for the react-flow ui.
 *  @param source the id of the source node.
 *  @param target the id of the target node.
 *  @param sourcePort the string with the source port.
 *  @param targetPort string with the target port.
 *  @param graphDef the GraphDef object.
 *  @returns the new GraphDef object.*/
function onReactFlowEdgeAdded(
    source: string, target: string, sourcePort: string | null | undefined, targetPort: string | null | undefined, graphDef: GraphDef
): GraphDef {
    const newGraphDef = JSON.parse(JSON.stringify(graphDef));
    const sourceId = source;
    const targetId = target;
    if (sourceId !== null && sourceId !== undefined && targetId !== null && targetId !== undefined) {
        const edge = { fromNode: sourceId, toNode: targetId };
        if (sourcePort !== null && sourcePort !== undefined) {
            (edge as any).fromPort = sourcePort;
        }
        if (targetPort !== null && targetPort !== undefined) {
            (edge as any).toPort = targetPort;
        }
        newGraphDef.edges.push(edge);
    }
    newGraphDef.silent = true;

    return newGraphDef;
}

/** Set default properties on a given node based on the node type
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param nodeId a string with the node id.
 *  @param graphDef the GraphDef with the current graph state.
 *  @param elements the graph elements. 
 *  @param variant the runbook Variant that is being edited.
 *  @param subflows the list of subflow definitions. */
function setDefaultNodePropertiesOnConnect (
    nodeLibrary: NodeLibrary, nodeId: string, graphDef: GraphDef, elements: Elements, variant: Variant, subflows: RunbookNode[]
): void {
    const node: NodeDef | null = getNodeFromGraphDef(nodeId, graphDef);
    if (node && !node.editedByUser) {
        if (isDataOceanNodeFromGraphDef(node)) {
            DataOceanUtils.setDefaultPropertiesOnConnect(nodeLibrary, node, graphDef, elements, variant);
        } else if (isAggregatorNodeFromGraphDef(node) && node.properties) {
            AggregateNodeUtil.setDefaultPropertiesOnConnect(node, graphDef, elements);
        } else {
            const types: Array<string> = ([] as Array<string>).concat(dataOceanNodes, aggregatorNodes, transformNodes, decisionNodes, logicalNodes);
            const runbookContext = new RunbookContext(node, graphDef, DataOceanUtils.dataOceanMetaData);
            let context: Context | undefined = undefined;
            const nodeContexts = runbookContext.getNodeContexts();
            if (nodeContexts?.length && types.includes(nodeContexts[nodeContexts.length - 1].source.type) && node.properties) {
                context = nodeContexts[nodeContexts.length - 1];
            } else {
                const tContext = runbookContext.getTriggerContext();
                if (tContext) {
                    context = tContext;
                }
            }
            if (context && node.properties) {
                let keys: Array<GenericKey> = context.expandedKeys || [];
                let metrics: Array<GenericKey | string> = context.metrics || [];
                metrics = metrics.map(metric => (metric as GenericKey).id);
                if (isColumnEditorChartNodeFromGraphDef(node)) {
                    let columnsProp: any = undefined;
                    let columnsProps = node.properties.filter(prop => prop.key === "columns");
                    if (!columnsProps?.length) {
                        columnsProp = {key: "columns", value: []};
                        node?.properties?.push(columnsProp);
                    } else {
                        columnsProp = columnsProps[0];
                    }
                    columnsProp.value = [];
    
                    if (keys.length < 5 && (keys.length + metrics.length) < 10) {
                        // Show the key columns if the number of columns is not too large
                        columnsProp.value = columnsProp.value.concat(keys.map(item => item.id));
                    } else {
                        // Show the group column if either the total number of columns is large or there are a large number of key columns
                        columnsProp.value.push("group_column");
                    }

                    if (metrics.length < 10) {
                        columnsProp.value = columnsProp.value.concat(metrics);                        
                    }
    
                    let sortColumnProp: any = undefined;
                    let sortColumnProps = node.properties.filter(prop => prop.key === "sortColumn");
                    if (!sortColumnProps?.length) {
                        sortColumnProp = {key: "sortColumn", value: ""};
                        node?.properties?.push(sortColumnProp);
                    } else {
                        sortColumnProp = sortColumnProps[0];
                    }
                    sortColumnProp.value = "";
    
                    let sortOrderProp: any = undefined;
                    let sortOrderProps = node.properties.filter(prop => prop.key === "sortOrder");
                    if (!sortOrderProps?.length) {
                        sortOrderProp = {key: "sortOrder", value: ""};
                        node?.properties?.push(sortOrderProp);
                    } else {
                        sortOrderProp = sortOrderProps[0];
                    }
                    sortOrderProp.value = "";
    
                    if (columnsProp?.value?.length) {
                        sortColumnProp.value = columnsProp.value[0];
                        sortOrderProp.value = "asc";
                    }
    
                    elements.forEach(element => {
                        if (element.id === node.id && element.data.properties.length > 0) {
                            //update react graph element
                            element.data.properties.filter(prop => prop.key === "columns")[0].value = [].concat(columnsProp.value);
                            element.data.properties.filter(prop => prop.key === "sortColumn")[0].value = sortColumnProp.value;
                            element.data.properties.filter(prop => prop.key === "sortOrder")[0].value = sortOrderProp.value;
                        }
                    });
                } else if (isMetricsEditorChartNodeFromGraphDef(node)) {
                    let metricsProp: any = undefined;
                    let metricsProps = node.properties.filter(prop => prop.key === "metrics");
                    if (!metricsProps?.length) {
                        metricsProp = {key: "metrics", value: []};
                        node?.properties?.push(metricsProp);
                    } else {
                        metricsProp = metricsProps[0];
                    }
                    metricsProp.value = [];
                    metricsProp.value = metricsProp.value.concat(metrics);
                    elements.forEach(element => {
                        if (element.id === node.id && element.data.properties.length > 0) {
                            //update react graph element
                            element.data.properties.filter(prop => prop.key === "metrics")[0].value = [].concat(metricsProp.value);
                        }
                    });
                } else if (isMetricEditorChartNodeFromGraphDef(node)) {
                    let metricProp: any = undefined;
                    let metricProps = node.properties.filter(prop => prop.key === "metric");
                    if (!metricProps?.length) {
                        metricProp = {key: "metric", value: ""};
                        node?.properties?.push(metricProp);
                    } else {
                        metricProp = metricProps[0];
                    }
                    metricProp.value = "";
                    metricProp.value = metrics?.length ? metrics[0] : "";
                    elements.forEach(element => {
                        if (element.id === node.id && element.data.properties.length > 0) {
                            //update react graph element
                            element.data.properties.filter(prop => prop.key === "metric")[0].value = metricProp.value;
                        }
                    });
                } else if (isSizeMetricEditorChartNodeFromGraphDef(node)) {
                    let metricProp: any = undefined;
                    let metricProps = node.properties.filter(prop => prop.key === "sizeMetric");
                    if (!metricProps?.length) {
                        metricProp = {key: "sizeMetric", value: ""};
                        node?.properties?.push(metricProp);
                    } else {
                        metricProp = metricProps[0];
                    }
                    metricProp.value = "";
                    metricProp.value = metrics?.length ? metrics[0] : "";
                    elements.forEach(element => {
                        if (element.id === node.id && element.data.properties.length > 0) {
                            //update react graph element
                            element.data.properties.filter(prop => prop.key === "sizeMetric")[0].value = metricProp.value;
                        }
                    });
                } else if (isColorMetricEditorChartNode(node)) {
                    let metricProp: any = undefined;
                    let metricProps = node.properties.filter(prop => prop.key === "colorMetric");
                    if (!metricProps?.length) {
                        metricProp = {key: "colorMetric", value: ""};
                        node?.properties?.push(metricProp);
                    } else {
                        metricProp = metricProps[0];
                    }
                    metricProp.value = "";
                    metricProp.value = metrics?.length ? metrics[0] : "";
                    elements.forEach(element => {
                        if (element.id === node.id && element.data.properties.length > 0) {
                            //update react graph element
                            element.data.properties.filter(prop => prop.key === "colorMetric")[0].value = metricProp.value;
                        }
                    });
                }
            }

            // Handle setting up defaults for a subflow connected to a webhook trigger.
            if (isSubflowNodeFromGraphDef(node)) {
                const parents: NodeDef[] = getParentsFromGraphDef(node, graphDef);
                if (parents.length === 1 && isTriggerNodeFromGraphDef(parents[0])) {
                    let triggerType = parents[0]?.properties?.filter(prop => prop.key === "triggerType");
                    if (triggerType?.length && triggerType[0].value === InputType.WEBHOOK) {
                        let configurationId = node?.properties?.filter(prop => prop.key === "configurationId")?.[0]?.value;
                        const subflowNode = subflows.find(subflow => subflow.id === configurationId);
                        let inProp: any = undefined;
                        let inProps = node?.properties?.filter(prop => prop.key === "in");
                        if (inProps?.length) {
                            inProp = inProps[0];
                        }
                        if (
                            subflowNode && subflowNode.inputVariables?.length && 
                            subflowNode.inputVariables.find(variable => variable.type === PrimitiveVariableType.JSON) &&
                            inProp && inProp.value?.length
                        ) {
                            inProp?.value.forEach(input => {
                                const jsonInputVariable = subflowNode.inputVariables.find(
                                    variable => variable.type === PrimitiveVariableType.JSON && variable.name === input.inner
                                );
                                if (jsonInputVariable) {
                                    input.outer = "$trigger.requestBody";
                                    input.method = "trigger";    
                                }
                            });
                            elements.forEach(element => {
                                if (element.id === node.id && element.data.properties.length > 0) {
                                    //update react graph element
                                    element.data.properties.filter(prop => prop.key === "in")[0].value = [].concat(inProp.value);
                                }
                            });    
                        }
                    }
                }
            }
        }
    }
}

/** Reset default properties of a deleted connection's target node
 *  @param deletedElements the deleted Elements from the react-flow graph.
 *  @param graphDef the graphDef object with the current state of the graph.
 *  @param elements all the elements in the react-flow graph.*/
function removeDefaultProperties (deletedElements: Elements, graphDef: GraphDef, elements: Elements): void {
    const deletedIds = deletedElements.map( deletedElement => deletedElement.id );

    deletedElements.forEach( (deletedElement) => {
        // run this function only if the element is a connecting element
        if ((deletedElement as Edge).target && deletedIds.indexOf((deletedElement as Edge).target) < 0) {
            const node = getNodeFromGraphDef((deletedElement as Edge).target, graphDef);
            if (node && !node.editedByUser && isDataOceanNodeFromGraphDef(node)) {
                DataOceanUtils.clearDefaultProperties(node, elements);
            }
        }
    })
};

/** handler for the onNodesMoved event from the react-flow ui.
 *  @param nodes the array of nodes that were moved.
 *  @param graphDef the GraphDef object.
 *  @returns the new GraphDef object.*/
function onReactFlowNodesMoved(nodes: Array<Node>, graphDef: GraphDef): GraphDef {
    const newGraphDef = JSON.parse(JSON.stringify(graphDef));
    if (nodes && nodes.length > 0) {
        for (const node of nodes) {
            for (const checkNode of newGraphDef.nodes) {
                if (checkNode.id === node.id) {
                    checkNode.x = node.position.x;
                    checkNode.y = node.position.y;
                    break;
                }
            }    
        }    
    }
    newGraphDef.silent = true;
    return newGraphDef;
}

/** adds a new global config node.
 *  @param node the node to add.
 *  @param globalNodes the Array of NodeDefs with the global nodes.
 *  @returns a reference to the new global nodes state that was created.*/
/* istanbul ignore next */
function addGlobalConfigNode(node: NodeDef, globalNodes: Array<NodeDef>): Array<NodeDef> {
    const newGlobalNodes = JSON.parse(JSON.stringify(globalNodes));
    newGlobalNodes.push(node);
    return newGlobalNodes;
}

/** deletes a global configuration node.
 *  @param id the id of the global config node to delete.
 *  @param globalNodes the current array of global nodes.
 *  @returns a reference to the new global nodes state that was created.*/
/* istanbul ignore next */
function deleteGlobalConfigNode(id: string, globalNodes: Array<NodeDef>): Array<NodeDef> {
    const newGlobalNodes = JSON.parse(JSON.stringify(globalNodes));
    for (let index = 0; index < newGlobalNodes.length; index++) {
        if (newGlobalNodes[index].id === id) {
            newGlobalNodes.splice(index, 1);
            break;
        }
    }
    return newGlobalNodes;
}

/** updates the UI to display the specified runbook.
 *  @param id a String with the runbook id.
 *  @param runbooks the current list of runbooks.
 *  @returns a Flow with the active runbook.*/
function onRunbookSelected(id: string, runbooks: Array<RunbookInfo>): RunbookInfo {
    if (id) {
        for (const runbook of runbooks) {
            if (runbook.id === id) {
                return runbook;
            }
        }
    }
    return NEW_RUNBOOK;
}

/** Creates a popup that imports the nodes using the node-red format into the graph.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param setGraphDef the GraphDef object with all the nodes and edges.
 *  @param setGlobalNodes the function that is used to set the global nodes state.
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param variant the variant of runbook: incident or lifecycle.
 *  @param newDialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param setRunbookModified a reference to the function that is used to set the modified state.  We need to call
 *      this to have the graph component re-render when modifications are made. 
 *  @param updatedRunbookRef the reference to the RunbookInfo object with the latest information for the runbooks 
 *      name, description and trigger. 
 *  @param variables the map of variables by scope. 
 *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
 *  @param integrations the list of installed integrations. */
function importContent(
    nodeLibrary: NodeLibrary, setGraphDef: (graphDef: GraphDef) => void, setGlobalNodes: (nodes: Array<NodeDef>) => void, 
    subflows: Array<RunbookNode> = [], variant: Variant,
    newDialogState: DialogState, setDialogState: (dialogState: DialogState) => void, setRunbookModified: (modified: boolean) => void, 
    updatedRunbookRef: {current: RunbookInfo}, variables: VariableContextByScope, customProperties: CustomProperty[], integrations?: RunbookIntegrationDetails[]
): void {
    newDialogState.title = STRINGS.formatString(STRINGS.runbookEditor.importDialog.title, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[variant]});
    newDialogState.dialogContent = (<>
        <div className="mb-3"><span>{STRINGS.formatString(STRINGS.runbookEditor.importDialog.text, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})}</span></div>
        <input id="node-red-flow-file" type="file" />
    </>);
    newDialogState.dialogFooter = (<>
        <Button active={true} outlined={true} onClick={(evt) => {
            const fileList = (document.getElementById("node-red-flow-file") as any).files;
            if (fileList && fileList.length === 1) {
                const fileReader = new FileReader();
                /* istanbul ignore next */
                fileReader.onload = function () {
                    const text: string = fileReader.result as string;
                    if (text && text.length > 0) {
                        const newGlobalNodes = [] as Array<NodeDef>;
                        const importObject = JSON.parse(text);

                        const flowInfo: RunbookInfo = { id: updatedRunbookRef.current.id, label: "Unknown", info: "", disabled: true};
                        let newGraphDef: GraphDef;

                        if (importObject.variant !== variant && !(variant === Variant.EXTERNAL && importObject.variant === Variant.INCIDENT)) {
                            setDialogState(updateDialogState(
                                newDialogState, true, false, 
                                [
                                    STRINGS.formatString(
                                    STRINGS.runbookEditor.importDialog.wrongVariantErrorText, 
                                    {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})
                                ]
                            ));
                            return;
                        }
                        newGraphDef = getGraphDefFromRunbookConfig(importObject, subflows, [], integrations);
                        updateGraphDefWithErrors(nodeLibrary, newGraphDef, importObject, variables, customProperties, subflows, variant);
                        flowInfo.label = importObject.name || "";
                        flowInfo.info = importObject.description || "";
                        flowInfo.triggerType = importObject.triggerType;
                        // When importing a runbook we want it to be disabled by default
                        flowInfo.disabled = true;
                        flowInfo.isFactory = false;
                        delete (flowInfo as any).factoryResourceName;
                        flowInfo[getVariablesKey(variant)] = importObject[getVariablesKey(variant)];

                        if (updatedRunbookRef.current?.i18nNameKey) {
                            if (flowInfo.label === STRINGS.defaultRunbooks[updatedRunbookRef.current.i18nNameKey]) {
                                flowInfo.i18nNameKey = updatedRunbookRef.current.i18nNameKey;
                                flowInfo.label = updatedRunbookRef.current.label;
                            }    
                        }
                        if (updatedRunbookRef.current?.i18nInfoKey) {
                            if (flowInfo.info === STRINGS.defaultRunbooks[updatedRunbookRef.current.i18nInfoKey]) {
                                flowInfo.i18nInfoKey = updatedRunbookRef.current.i18nInfoKey;
                                flowInfo.info = updatedRunbookRef.current.info;
                            }    
                        }
                        updatedRunbookRef.current = flowInfo;
                        
                        setGlobalNodes(newGlobalNodes);
                        setGraphDef(newGraphDef);
                        setRunbookModified(true);
                        setDialogState(updateDialogState(newDialogState, false, false, []));
                    }
                }
                fileReader.readAsText(fileList[0]);
            }
        }} text={STRINGS.runbookEditor.importDialog.btnText} />
    </>);
    setDialogState(newDialogState);
}

/** outputs the nodes in the node-red Json format.
 *  @param flowId a String with the flowId.
 *  @param graphDef the GraphDef object with all the nodes and edges.
 *  @param reactFlowGraphComponent the react-flow GraphComponent with the graph.
 *  @returns a node-red Json object.*/
function getRunbookNodesJson(flowId: string, graphDef: GraphDef, reactFlowGraphComponent: OnLoadParams | null): Array<any> {
    const outputJson: Array<any> = [];
    for (const node of graphDef.nodes) {
        const nodeJson: any = { id: node.id, type: node.type, label: node.name, wires: [] };
        // Ignore skeleton nodes
        if (skeletonNodes.includes(node.type)) {
            continue;
        }

        // Handle the i18n keys
        if (node.i18nNameKey) {
            nodeJson.i18nNameKey = node.i18nNameKey;
        }
        if (node.i18nInfoKey) {
            nodeJson.i18nInfoKey = node.i18nInfoKey;
        }

        if (graphDef.edges && graphDef.edges.length > 0) {

            // The basic edges are stored in an array of arrays, not sure why, but that is what
            // I have seen in testing.
            const wireByPort: Record<string, Array<string>> = {};
            for (const edge of graphDef.edges) {
                if (edge.fromNode === node.id) {
                    const port = (edge.fromPort ? edge.fromPort : "0");
                    if (!wireByPort[port]) {
                        wireByPort[port] = ([] as Array<string>);
                    }
                    wireByPort[port].push(edge.toNode.toString());
                }
            }
            let maxPortValue = 0;
            for (const port in wireByPort) {
                maxPortValue = Math.max(maxPortValue, parseInt(port));
            }
            for (let wireIndex = 0; wireIndex < maxPortValue; wireIndex++) {
                nodeJson.wires.push(new Array<string>());
            }
            for (const port in wireByPort) {
                nodeJson.wires[parseInt(port)] = wireByPort[port];
            }
        }

        // Right now all this comes from the react flow node, but it is also stored in the 
        // graph node.  Why not get it there.  When the onNodeAdded callback is invoked the 
        // react flow graph does not have the node so all this is empty when run through 
        // the validation
        nodeJson.label = node.name;
        nodeJson.description = node.info;
        // In the azure function back-end all properties are stored in a properties object.
        nodeJson.properties = {x: node.x, y: node.y};

        // Add in any properties
        if (node.properties) {
            for (const prop of node.properties) {
                let propValue = prop.value;
                if (propValue !== null && propValue !== undefined) {
                    // In the azure functions, the property goes in the properties object
                    if (isDataOceanNodeFromGraphDef(node) && CONTEXT_MODE === ContextMode.CLOSEST_PARENT) {
                        // Francois asked us to convert the $closestAncestor token to the format $node:id.filter so it is clear
                        // to the RO which node is providing the filter
                        if (prop.key === "filters") {
                            propValue = JSON.parse(JSON.stringify(propValue));
                            const context = new RunbookContext(node, graphDef, DataOceanUtils.dataOceanMetaData);
                            for (const filterKey in propValue) {
                                if (propValue[filterKey] && propValue[filterKey]?.length > 0) {
                                    if (propValue[filterKey][0].startsWith(NODE_OPTION_VALUE_CP)) {
                                        // We want to convert all the $node:id.filter tokens to $closestAncestor tokens to make copy/paste
                                        // and import/export easier
                                        const filterValue = propValue[filterKey][0];
                                        const filterName = filterValue.substring(filterValue.lastIndexOf(".") + 1, filterValue.length);
                                        let sources: Array<ContextSource> =  context.getFilterNodeSources(filterName, true);
                                        if (sources && sources.length > 0) {
                                            // We have an exact match of the filters.
                                            propValue[filterKey][0] = `${ NODE_OPTION_VALUE_AP }:${ sources[sources.length - 1].id }.${ filterName }`;                                           
                                        } else {
                                            // We did not find an exact match, so now check for any compatible filter
                                            sources =  context.getFilterNodeSources(filterName, false);
                                            if (sources && sources.length > 0 && sources[sources.length - 1]?.keys) {
                                                // Take the first compatible filter
                                                propValue[filterKey][0] = `${ NODE_OPTION_VALUE_AP }:${ sources[sources.length - 1].id }.${ sources[sources.length - 1].keys[0] }`;                                           
                                            }
                                        }
                                    } else if (propValue[filterKey][0].startsWith(SUBFLOW_INPUT_OPTION_VALUE)) {
                                        const filterValue = propValue[filterKey][0];
                                        const filterName = filterValue.substring(filterValue.lastIndexOf(".") + 1, filterValue.length);
                                        for (const node of graphDef.nodes) {
                                            if (isSubflowInputNodeFromGraphDef(node)) {
                                                propValue[filterKey][0] = `${ NODE_OPTION_VALUE_AP }:${ node.id }.${ filterName }`;                                           
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (Array.isArray(propValue)) {
                        propValue.forEach((property, index) => {
                            if (property?.inner?.includes("subflow") && property?.method === "unset") {
                                delete propValue[index].method;
                            }
                        });
                    }
                    nodeJson.properties[prop.key] = propValue;
                }
            }
        }

        // Handle properties that are in runbook, but we cannot edit here, just store them
        // in the node and restore them to the object later.
        if (node.passThruProperties) {
            for (const prop of node.passThruProperties) {
                // In the azure functions, the property goes in the properties object
                nodeJson.properties[prop.key] = prop.value;
            }
        }

        // Handle subflow environment variables (this is only used in node red)
        if (node.env) {
            nodeJson.env = [];
            for (const setting of node.env) {
                nodeJson.env.push(setting);
            }
        }

        /* These properties had only been stored in the node at one point, but now they are also stored in the graph node so
           to make the validation work since the react-flow node gets updated after the onNodeAdded callback we will switch 
           to using what is in the graph def.  Keep this in case that causes a problem  
        if (reactFlowGraphComponent) {
            const elements = reactFlowGraphComponent.getElements().filter(graphNode => graphNode.id === node.id);
            if (elements && elements.length === 1) {
                const graphNode: Node = elements[0] as Node;
                nodeJson.label = graphNode.data.label;
                nodeJson.description = graphNode.data.info;
                // In the azure function back-end all properties are stored in a properties object.
                nodeJson.properties = {x: graphNode.position.x, y: graphNode.position.y};

                // Add in any properties
                if (graphNode.data && graphNode.data.properties) {
                    for (const prop of graphNode.data.properties) {
                        if (prop.value !== null && prop.value !== undefined) {
                            // In the azure functions, the property goes in the properties object
                            nodeJson.properties[prop.key] = prop.value;
                        }
                    }
                }

                // Handle properties that are in runbook, but we cannot edit here, just store them
                // in the node and restore them to the object later.
                if (graphNode.data.passThruProperties) {
                    for (const prop of graphNode.data.passThruProperties) {
                        // In the azure functions, the property goes in the properties object
                        nodeJson.properties[prop.key] = prop.value;
                    }
                }

                // Handle subflow environment variables (this is only used in node red)
                if (graphNode.data.env) {
                    nodeJson.env = [];
                    for (const setting of graphNode.data.env) {
                        nodeJson.env.push(setting);
                    }
                }
            }
        }
        */
        outputJson.push(nodeJson);
    }
    return outputJson;
}

/** outputs the nodes in the node-red runbook Json format.
 *  @param graphDef the GraphDef object with all the nodes and edges.
 *  @param reactFlowGraphComponent the react-flow GraphComponent with the graph.
 *  @param activeRunbook the RunbookInfo argument with the active runbook configuration.
 *  @param name a String with the name for the flow.
 *  @param desc a String with the description for the flow.  This goes into the runbook desc attribute.
 *  @param triggerType the input type for the flow.  The type can be "interface", "device"
 *      or "application" for alpha.
 *  @param variant the variant of runbook, incident or lifecycle.
 *  @returns a node-red Json object.*/
export function getRunbookConfigJson(
    graphDef: GraphDef, reactFlowGraphComponent: OnLoadParams | null, activeRunbook: RunbookInfo | null, 
    name: string, desc: string, triggerType: InputType, variant: Variant, allowAutomation: MutableRefObject<boolean>
): RunbookConfig {
    let runbookId: string | undefined = (activeRunbook ? activeRunbook.id : NEW_RUNBOOK.id);
    let runbookName: string = (name !== null && name !== undefined && name.trim().length > 0 ? name : NEW_RUNBOOK.label);
    if (runbookId === NEW_RUNBOOK.id) {
        // We have a new flow, make some kind of unique name and id.
        runbookId = undefined;
    }
    const nodes = getRunbookNodesJson(runbookId || "", graphDef, reactFlowGraphComponent);
    const outputJson: RunbookConfig = { 
        ...(runbookId && {id: runbookId}), name: runbookName, description: ("") +  (desc || ""), 
        variant: variant === Variant.EXTERNAL ? Variant.INCIDENT : variant || Variant.INCIDENT,
        triggerType: triggerType,
        nodes: nodes, configs: [], [getVariablesKey(variant)]: activeRunbook?.[getVariablesKey(variant)]
    };
    if (activeRunbook && activeRunbook.disabled !== null && activeRunbook.disabled !== undefined) {
        outputJson.isReady = allowAutomation.current;
    }
    if (activeRunbook) {
        outputJson.isFactory = activeRunbook.isFactory === true;
    }

    return outputJson;
}

/** returns the key to use for the runtime variables.
 *  @param variant the runbook Variant that we are extracting the variables from.
 *  @returns a String with the key for the runtime variables for the specified variant. */
function getVariablesKey(variant: Variant): string {
    const variablesKey: string = variant === Variant.SUBFLOW ? "subflowVariables" : "runtimeVariables";
    return variablesKey;
}

/** exports the currently selected runbook to the clipboard and displays it in a text area
 *      in a dialog.
 *  @param id a String with the id of the runbook to export.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. 
 *  @param variant the runbook Variant that we are extracting the variables from. */
async function exportContent(id: string, setDialogState: Function, variant: Variant): Promise<void> {
    const newDialogState: any = {
        showDialog: true,
        loading: true,
        title: STRINGS.formatString(STRINGS.runbooks.exportDialog.title, {
            variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
        }),
    };
    newDialogState.dialogContent = (
        <div className="mb-3">
            <span>
                {STRINGS.formatString(STRINGS.runbooks.exportDialog.preText, {
                    variant: STRINGS.runbooks.runbookTextForVariant[variant],
                })}
            </span>
        </div>
    );
    newDialogState.dialogFooter = undefined;
    setDialogState(newDialogState);

    try {
        const runbook = await runbookService.getRunbookWithDependenciesForExport(id);

        const newDialogState: any = {
            showDialog: true,
            title: STRINGS.formatString(STRINGS.runbooks.exportDialog.title, {
                variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
            }),
        };
        newDialogState.dialogContent = (
            <>
                <div className="mb-3">
                    <span>
                        {STRINGS.formatString(
                            STRINGS.runbooks.exportDialog.text,
                            {
                                variant: STRINGS.runbooks.runbookTextForVariant[variant],
                            }
                        )}
                    </span>
                </div>
                <textarea 
                    defaultValue={JSON.stringify(runbook, null, 4)}
                    style={{ minWidth: '470px', minHeight: '350px', resize: "both" }}
                    disabled
                />
            </>
        );
        newDialogState.dialogFooter = (
            <>
                <Button active={true} outlined={true} icon={IconNames.DOWNLOAD}
                    text={STRINGS.runbooks.exportDialog.downloadBtnText}
                    onClick={() => {
                        const runbookName: string = (runbook)?.data?.name || 'Runbook';

                        saveAs(
                            new Blob([JSON.stringify(runbook, null, 4)], {
                                type: 'text/plain;charset=utf-8',
                            }),
                            `${runbookName || 'Runbook'}.txt`
                        );
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        );
                    }}
                />
                <CopyToClipboard text={JSON.stringify(runbook)}>
                    <Button active={true} outlined={true} text={STRINGS.runbooks.exportDialog.btnText}
                        icon={IconNames.DUPLICATE}
                        onClick={() => {
                            setDialogState(
                                updateDialogState(newDialogState, false, false, [])
                            );
                        }}
                    />
                </CopyToClipboard>
            </>
        );
        setDialogState(newDialogState);
    } catch (error) {
        const afterDialogState: any = updateDialogState(
            newDialogState, true, false,
            [
                STRINGS.formatString(STRINGS.runbooks.exportDialog.errorText, {
                    variant: STRINGS.runbooks.runbookTextForVariant[variant],
                }),
            ]
        );
        setDialogState(afterDialogState);
    }
}


/** Creates a popup that exports the nodes to the clipboard in node-red format.
 *  @param graphDef the GraphDef object with all the nodes and edges.
 *  @param globalNodes the array of global nodes. 
 *  @param reactFlowGraphComponent the react-flow GraphComponent with the graph.
 *  @param activeRunbook the RunbookInfo argument with the active runbook configuration.
 *  @param variant the variant of runbook, incident or lifecycle.
 *  @param newDialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.*/
/* istanbul ignore next */
function exportJSONContent(
    graphDef: GraphDef, globalNodes: Array<NodeDef>, reactFlowGraphComponent: OnLoadParams | null, activeRunbook: RunbookInfo | null,
    variant: Variant, newDialogState: any, setDialogState, allowAutomation: MutableRefObject<boolean>
): void {
    const triggerType = (activeRunbook?.triggerType ? activeRunbook.triggerType : InputType.INTERFACE);
    const outputJson = getRunbookConfigJson(graphDef, reactFlowGraphComponent, activeRunbook, activeRunbook?.label || "", activeRunbook?.info || "", triggerType, variant, allowAutomation);

    newDialogState.title = STRINGS.formatString(STRINGS.runbookEditor.exportDialog.title, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[variant]});
    newDialogState.dialogContent = (<>
        <div className="mb-3"><span>{STRINGS.formatString(STRINGS.runbookEditor.exportDialog.text, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})}</span></div>
        <textarea defaultValue={JSON.stringify(outputJson, null, 4)} style={{width: "470px", height: "350px"}} disabled={true} />
    </>);
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
                icon={IconNames.DOWNLOAD}
                text={STRINGS.runbooks.exportDialog.downloadBtnText} 
                onClick={() => {
                    const runbookName: string = (outputJson as any)?.name || 'Runbook';     
                    saveAs(new Blob([JSON.stringify(outputJson, null, 4)], { type: "text/plain;charset=utf-8" }), `${runbookName}.txt`);
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        <CopyToClipboard text={JSON.stringify(outputJson)}>
            <Button active={true} outlined={true} 
                text={STRINGS.runbookEditor.exportDialog.btnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that syncs the nodes to the node-red server.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param graphDef the GraphDef object with all the nodes and edges.
 *  @param reactFlowGraphComponent the react-flow GraphComponent with the graph.
 *  @param currentRunbookRef the reference to the RunbookInfo argument with the active runbook configuration, including all user updates.
 *  @param setQueryParams the function to update the query parameters.
 *  @param setLoadRunbooks the reference to the id of the current runbook.  If this changes then the 
 *      page will reload.
 *  @param newDialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param dialogStateRef the reference to the current dialog state.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param saveRunbook if true save the runbook to the server, if false, just update the runbook properties
 *      and enable the save button. 
 *  @param setRunbookModified a reference to the function that is used to set the modified state.  We need to call
 *      this to have the graph component re-render when modifications are made.
 *  @param setShowErrors a reference to the function that is used to set the show errors state.
 *  @param appInsightsContext the context that is used to save app insights data. 
 *  @param showErrorBlade a boolean value if true show the error blade. 
 *  @param setShowErrorBlade a reference to the function that is used to set the show error blade stat.
 *  @param variables the map of variables by scope.
 *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param variant the variant of runbook, incident or lifecycle.
 *  @param showAllowAutomationControl .
 *  @param allowAutomation .
 *  @param userPreferences the UserPreferences object that we can use to see if subflow versioning is enabled.
 *  @returns a Promise. */
async function syncContent(
    nodeLibrary: NodeLibrary, graphDef: GraphDef, reactFlowGraphComponent: OnLoadParams | null, currentRunbookRef: {current: RunbookInfo},
    setQueryParams: (params: paramsObj, updateHistory: boolean) => void, setLoadRunbooks: (loadRunbooks: boolean) => void,
    newDialogState: DialogState, dialogStateRef: {current: DialogState}, setDialogState: (dialogState: DialogState) => void, 
    saveRunbook: boolean, setRunbookModified: (modified: boolean) => void, setShowErrors: (showErrors: boolean) => void, 
    appInsightsContext: ReactPlugin, showErrorBlade: boolean, setShowErrorBlade: (showErrorBlade: boolean) => void,
    variables: VariableContextByScope, customProperties: CustomProperty[], subflows: Array<RunbookNode> = [], variant: Variant, 
    showAllowAutomationControl: boolean, allowAutomation: MutableRefObject<boolean>, userPreferences: UserPreferences | undefined
): Promise<any> {
    const currentRunbook = currentRunbookRef.current;
    let origName = (currentRunbook?.i18nNameKey ? STRINGS.defaultRunbooks[currentRunbook.i18nNameKey] : currentRunbook?.label);
    if (!origName) {
        origName = "New Hyperion Flow";
    }
    
    const origInfo = (currentRunbook?.i18nInfoKey ? STRINGS.defaultRunbooks[currentRunbook.i18nInfoKey] : currentRunbook?.info);

    const origVersionObj = Version.parse((currentRunbookRef.current as RunbookConfig).version || "");
    const origVersion = origVersionObj.toString();
    const savedVersionObj = Version.parse((currentRunbookRef.current as RunbookConfig).origVersion || "");
    const origRadioValue: string = origVersionObj.major === savedVersionObj.major + 1 ? "major" : origVersionObj.minor === savedVersionObj.minor + 1 ? "minor" : "none";

    const triggerTypes: Array<{label: string, value: InputType}> = [
        {label: STRINGS.runbookEditor.interfaceInputType, value: InputType.INTERFACE}, 
        {label: STRINGS.runbookEditor.deviceInputType, value: InputType.DEVICE}, 
        {label: STRINGS.runbookEditor.applicationLocationInputType, value: InputType.APPLICATION_LOCATION},
        {label: STRINGS.runbookEditor.locationInputType, value: InputType.LOCATION},
        {label: STRINGS.runbookEditor.applicationInputType, value: InputType.APPLICATION}
    ];

    const triggerType = (currentRunbook?.triggerType ? currentRunbook.triggerType : InputType.INTERFACE);
/*  I think we should switch to using the menu control rather than HTMLSelect.  This
    is used for the menu control but I could not get it to work.  Will work on this 
    some more later.
    let selectedInputText = "";
    for (const option of triggerTypes) {
        if (option.value === triggerType) {
            selectedInputText = option.label;
            break;
        }
    }

    let selectedType = triggerType;
*/

    let ignoreWarnings = false;
    let errors: Array<ValidationResult> = [], warnings: Array<ValidationResult> = [];

    // report metrics to App Insights
    const reportMetrics = (eventName: string) => {
		if (appInsightsContext) {
			const properties = {
				name: eventName
			};
			trackEvent(appInsightsContext, AuthService, properties);
		}
	};

    let showErrorBladeInClosure = showErrorBlade;
    newDialogState.title = (
        STRINGS.formatString(saveRunbook ? 
            STRINGS.runbookEditor.saveDialog.syncTitle : STRINGS.runbookEditor.saveDialog.settingsTitle, 
            {variant: STRINGS.runbookEditor.runbookTextForVariantUc[variant]}
        )
    );
    function SaveDialogContent(
        props: {showErrorSection?: boolean, showErrorButton: boolean, warnings?: number, errors?: number, allowAutomation: MutableRefObject<boolean> }
    ): JSX.Element {
        const hasErrors = (props?.errors || 0) > 0;
        const isAutomationDisabled = hasErrors || IS_EMBEDDED || variant === Variant.SUBFLOW; // When embedded it should always be on
        const [versionValue, setVersionValue] = useState<string>(origRadioValue);
        const [currentVersion, setCurrentVersion] = useState<string>(origVersion);

        return (<> 
            <div className="mb-3">
                <span>{
                    STRINGS.formatString(
                        saveRunbook ? STRINGS.runbookEditor.saveDialog.syncText : STRINGS.runbookEditor.saveDialog.settingsText, 
                        {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]}
                    )
                }</span></div>
            <div className="row">
                <div className="col-md-3"><label htmlFor="runbook-flow-name">{STRINGS.runbookEditor.saveDialog.syncNameLabel}</label></div> 
                <div className="col-md-9">
                    <InputGroup id="runbook-flow-name" type="text" defaultValue={origName} />
                </div>
            </div>
            {VARIANTS_WITH_VERSIONING.includes(variant) && <div className="row mt-2">
                <div className="col-md-3"><label htmlFor="runbook-flow-version">{STRINGS.runbookEditor.saveDialog.syncVersionLabel}</label></div> 
                <div className="col-md-9">
                    <div style={{display: "inline-flex"}}>
                        <RadioGroup disabled={Boolean(!currentRunbook.seriesId)} className="pt-1" onChange={(event)=>{
                                const version = savedVersionObj;
                                let newVersion = version;
                                const newValue = (event.target as any).value;
                                switch (newValue) {
                                    case "major":
                                        newVersion = new Version(version.major + 1, 0, version.patch);
                                        break;
                                    case "minor":
                                        newVersion = new Version(version.major, version.minor + 1, version.patch);
                                        break;
                                }
                                setVersionValue(newValue);
                                setCurrentVersion(newVersion.toString())
                            }} selectedValue={versionValue} inline={true} 
                        >
                            <Radio label="none" value="none"/>
                            <Radio label="minor" value="minor"/>
                            <Radio label="major" value="major"/>
                        </RadioGroup>
                        <div className="pt-1 pl-1 pr-1" style={{backgroundColor: "#898D91"}}>
                            <span className="mr-2">v</span>
                            <span id="runbook-flow-version">{currentVersion}</span>
                        </div>
                        {/*<InputGroup id="runbook-flow-version" type="text" value={currentVersion} />*/}
                    </div>
                </div>
            </div>}
            <div className="row mt-2">
                <div className="col-md-3"><label htmlFor="runbook-flow-desc">{STRINGS.runbookEditor.saveDialog.syncDescLabel}</label></div> 
                <div className="col-md-9">
                    <TextArea id="runbook-flow-desc" defaultValue={origInfo} rows={4} className="w-100" />
                </div>
            </div>
            {showTypeControl &&             
                <div className="row mt-2">
                    <div className="col-md-3"><label>{STRINGS.runbookEditor.saveDialog.syncInputTypeLabel}</label></div> 
                    <div className="col-md-9">
                        <HTMLSelect id="runbook-flow-input-type" options={triggerTypes} defaultValue={triggerType} />
                    </div>
                </div>
            }
            {showAllowAutomationControl && variant !== Variant.SUBFLOW && variant !== Variant.ON_DEMAND &&
            <div className="row mt-4">
                <div className="col-md-2">
                    <Switch
                        id="runbook-flow-allow-automation"
                        defaultChecked={props.allowAutomation.current}
                        innerLabel={STRINGS.runbooks.offText} 
                        innerLabelChecked={STRINGS.runbooks.onText}
                        disabled={isAutomationDisabled}
                        onChange={() => {props.allowAutomation.current = !props.allowAutomation.current;}}>
                    </Switch>
                </div>
                <div className="col-md-10">
                    Allow Automation <br />
                    <span className="small">(Keep off until runbook is ready for automation)</span>
                </div>
            </div>
            }
                
            {/*false && <tr>
                <td className="p-1"><label>{STRINGS.runbookEditor.saveDialog.syncInputTypeLabel}</label></td>
                <td><Popover2 minimal position={Position.BOTTOM} content={<Menu style={{width: "300px"}} >
                    {triggerTypes.map(option => {
                        return <MenuItem text={option.label} active={option.value === triggerType} onClick={() => {selectedType = option.value; selectedInputText = option.label;}}/>
                    })}
                </Menu>}>
                    <Button rightIcon={IconNames.CHEVRON_DOWN} text={selectedInputText} className="text-nowrap" style={{width: "300px"}} />
                </Popover2></td>
                </tr>*/}
            {variant === Variant.ON_DEMAND && !!currentRunbook.isScheduled && <div className="mt-4 saving-scheduled-runbook">
                <Callout intent={Intent.PRIMARY}>
                    <strong>{STRINGS.runbookEditor.saveDialog.savingScheduledRunbook}</strong>
                </Callout>
            </div>}
            {!showErrorsInSaveDialog && props.showErrorSection && <div className="mb-4 mt-4">
                <div>{
                    props.errors && props.warnings ? STRINGS.formatString(STRINGS.runbookEditor.saveDialog.syncErrorAndWarningText, props.errors, props.warnings, STRINGS.runbookEditor.runbookTextForVariant[variant]) : 
                    props.errors ? 
                        STRINGS.formatString(STRINGS.runbookEditor.saveDialog.syncErrorText, props.errors, STRINGS.runbookEditor.runbookTextForVariant[variant]) : 
                        STRINGS.formatString(STRINGS.runbookEditor.saveDialog.syncWarningText, props.warnings, STRINGS.runbookEditor.runbookTextForVariant[variant])
                }</div>
                {props.showErrorButton && <Button minimal style={{paddingLeft: "0"}} text={
                        <u className="display-8">{
                            STRINGS.formatString(showErrorBladeInClosure ? 
                                STRINGS.runbookEditor.saveDialog.syncHideErrors : 
                                STRINGS.runbookEditor.saveDialog.syncShowErrors, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})
                        }</u>
                    } 
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                        showErrorBladeInClosure = !showErrorBladeInClosure;
                        (e.target as HTMLElement).textContent = STRINGS.formatString(showErrorBladeInClosure ? 
                            STRINGS.runbookEditor.saveDialog.syncHideErrors : 
                            STRINGS.runbookEditor.saveDialog.syncShowErrors, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]});
                        setShowErrorBlade(showErrorBladeInClosure);
                        setShowErrors(showErrorBladeInClosure);
                        setDialogState(updateDialogState(newDialogState, true, false, []));
                }}/>}
            </div>}
        </>);
    };
    newDialogState.dialogContent = <SaveDialogContent showErrorButton={false} allowAutomation={allowAutomation} />;

    function SaveDialogFooter(props: {showErrorButton: boolean, disabled?: boolean, forceEtag?: boolean}) {
        return <>
            {props.showErrorButton && <Button active={true} outlined={true}
                text={STRINGS.runbookEditor.saveDialog.syncShowErrorsBtnText}
                disabled={Boolean(props.disabled)}
                onClick={async (evt) => {
                    setShowErrorBlade(true);
                    setShowErrors(true);
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />}

            <Button active={true} outlined={true} disabled={Boolean(props.disabled)}
                text={saveRunbook ? props.forceEtag ? 
                    STRINGS.runbookEditor.saveDialog.syncWithEtagBtnText : 
                    STRINGS.runbookEditor.saveDialog.syncBtnText : STRINGS.runbookEditor.saveDialog.settingsBtnText}
                onClick={async () => {
                    const isReady = (document.getElementById("runbook-flow-allow-automation") as HTMLInputElement)?.checked || false;
                    const name = (document.getElementById("runbook-flow-name") as HTMLInputElement).value;
                    const desc = (document.getElementById("runbook-flow-desc") as HTMLInputElement).value;
                   // const version = Version.parse((document.getElementById("runbook-flow-version") as HTMLInputElement)?.value || "");
                    const version = Version.parse((document.getElementById("runbook-flow-version") as HTMLSpanElement)?.innerText || "");
                    const versionStr: string | undefined = version.isGreaterThan(new Version(0, 0, 0)) ? version.toString() : undefined;

                    let triggerType = (currentRunbook?.triggerType ? currentRunbook.triggerType : InputType.INTERFACE);
                    if (showTypeControl) {
                        triggerType = (document.getElementById("runbook-flow-input-type") as HTMLInputElement).value as InputType;
                    }
                    
                    if (currentRunbook) {
                        const flowInfo: RunbookInfo = { 
                            id: currentRunbook.id, label: name || "Unknown", info: desc || "", triggerType, 
                            [getVariablesKey(variant)]: currentRunbook[getVariablesKey(variant)], isReady: isReady,
                            eTag: currentRunbook.eTag 
                        };
                        if (currentRunbook.disabled !== null && currentRunbook.disabled !== undefined) {
                            flowInfo.disabled = currentRunbook.disabled;
                        }
                        
                        if (currentRunbook?.i18nNameKey) {
                            if (name === STRINGS.defaultRunbooks[currentRunbook.i18nNameKey]) {
                                flowInfo.i18nNameKey = currentRunbook.i18nNameKey;
                                flowInfo.label = currentRunbook.label;
                            }    
                        }
                        if (currentRunbook?.i18nInfoKey) {
                            if (desc === STRINGS.defaultRunbooks[currentRunbook.i18nInfoKey]) {
                                flowInfo.i18nInfoKey = currentRunbook.i18nInfoKey;
                                flowInfo.info = currentRunbook.info;
                            }    
                        }
                        if (currentRunbook[getVariablesKey(variant)]) {
                            // flowInfo[getVariablesKey(variant)] = getVariables(RUNTIME_SCOPE);
                            flowInfo[getVariablesKey(variant)] = currentRunbook[getVariablesKey(variant)];
                        }
                        flowInfo.isFactory = currentRunbook.isFactory;

                        flowInfo.seriesId = currentRunbook.seriesId;
                        flowInfo.origVersion = currentRunbook.origVersion;
                        flowInfo.otherVersions = currentRunbook.otherVersions;
                        if (version.isGreaterThan(new Version(0, 0, 0))) {
                            flowInfo.version = version.toString();
                        }

                        currentRunbookRef.current = flowInfo;
                        reportMetrics(EventNames.RUNBOOK_UPDATE);
                    }

                    if (saveRunbook) {
                        let expiredEtag = false;
                        setDialogState(updateDialogState(newDialogState, true, true, []));

                        try {
                            let updatedEtag: string | undefined = undefined;
                            if (props.forceEtag && currentRunbook?.id) {
                                const runbook = await runbookService.getRunbook(currentRunbook.id);
                                updatedEtag = runbook.eTag;
                            }

                            if (!props.forceEtag && variant === Variant.SUBFLOW) {
                                const runbooksThatUseTheSubflow = await getRunbooksThatUseCurrentSubflow();
                                const isInUse = runbooksThatUseTheSubflow.length > 0;
                                if (isInUse) {
                                    let message = <div>
                                        <p>{STRINGS.runbookEditor.saveDialog.confirmationModal.title}</p>
                                        <RunbooksContainingSubflowTable runbooks={runbooksThatUseTheSubflow} />
                                    </div>;
                                    openConfirm({
                                        message: message,
                                        onConfirm: async () => {
                                            expiredEtag = await handleSave(name, desc, versionStr, triggerType, updatedEtag, expiredEtag);
                                            if (errors?.length > 0 || warnings?.length > 0) {
                                                if (errors?.length === 0) {
                                                    ignoreWarnings = true;
                                                }
                                                const errorList = errors.map(item => item.nodeName ? item.nodeName + ": " + item.text : item.text);
                                                const warningList = warnings.map(item => item.nodeName ? item.nodeName + ": " + item.text : item.text);
                                                newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} forceEtag={expiredEtag} />;
                                                setDialogState(updateDialogState(newDialogState, true, false, errorList, warningList));
                                                return;                                
                                            }
                                            setDialogState(updateDialogState(newDialogState, false, false, []));
                                        },
                                        onCancel: async () => {
                                            setDialogState(updateDialogState(newDialogState, false, false, []));
                                        },
                                        intent: Intent.PRIMARY,
                                    });
                                    return;
                                } else {
                                    expiredEtag = await handleSave(name, desc, versionStr, triggerType, updatedEtag, expiredEtag);
                                }
                            } else {
                                expiredEtag = await handleSave(name, desc, versionStr, triggerType, updatedEtag, expiredEtag);
                            }
                        } catch (error) {
                            errors = [STRINGS.formatString(STRINGS.runbookEditor.errors.generalSaveError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})];
                            reportMetrics(EventNames.RUNBOOK_VALIDATION_ERROR);
                        }
                        if (errors?.length > 0 || warnings?.length > 0) {
                            if (errors?.length === 0) {
                                ignoreWarnings = true;
                            }
                            const errorList = errors.map(item => item.nodeName ? item.nodeName + ": " + item.text : item.text);
                            const warningList = warnings.map(item => item.nodeName ? item.nodeName + ": " + item.text : item.text);
                            newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} forceEtag={expiredEtag} />;
                            setDialogState(updateDialogState(newDialogState, true, false, errorList, warningList));
                            return;                                
                        }
                    } else if (currentRunbook) {
                        setRunbookModified(true);
                    }
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;

        /**
         * Retrieve the runbooks that use the subflow
         * 
         * @returns List of runbooks
         */
        async function getRunbooksThatUseCurrentSubflow() {
            const incidentRunbooks = await runbookService.getRunbooks(Variant.INCIDENT);
            const webhookRunbooks = []; //await runbookService.getRunbooks(Variant.EXTERNAL);
            const lifecycleRunbooks = await runbookService.getRunbooks(Variant.LIFECYCLE);
            const onDemandRunbooks = await runbookService.getRunbooks(Variant.ON_DEMAND);

            return [...incidentRunbooks, ...lifecycleRunbooks, ...onDemandRunbooks, ...webhookRunbooks].filter(runbook => 
                runbook.nodes.some((node) => isSubflowNodeFromGraphDef(node) && node?.properties?.configurationId === currentRunbook.id)
            );
        }

        async function handleSave(name: string, desc: string, version: string | undefined, triggerType: InputType, updatedEtag: string | undefined, expiredEtag: boolean) {
            const result = await syncToServer(
                nodeLibrary, graphDef, reactFlowGraphComponent, currentRunbookRef.current, name, desc, version, triggerType,
                setQueryParams, setLoadRunbooks, setRunbookModified, ignoreWarnings, updatedEtag, variables,
                customProperties, subflows, variant, allowAutomation
            );
            warnings = result.warnings;
            errors = result.errors;
            expiredEtag = result.expiredEtag === true;
            return expiredEtag;
        }
    };
    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={saveRunbook} />;
    newDialogState.loading = saveRunbook;
    setDialogState(newDialogState);

    if (saveRunbook) {
        try {
            const result = await validateRunbook(
                nodeLibrary, getRunbookConfigJson(graphDef, reactFlowGraphComponent, currentRunbook, origName, origInfo, triggerType, variant, allowAutomation), 
                graphDef, variables, customProperties, subflows, false, variant, allowAutomation
            );
            warnings = result.warnings;
            errors = result.errors;
        } catch (error) {
            console.error(error);
            errors = [{text: STRINGS.formatString(STRINGS.runbookEditor.errors.generalValidationError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})}];
        }
        if (dialogStateRef.current.showDialog) {
            if (errors?.length > 0 || warnings?.length > 0) {
                if (errors?.length === 0) {
                    ignoreWarnings = true;
                }

                if (errors?.length > 0 && currentRunbook && !currentRunbook.disabled) {
                    // If the runbook is determined to be invalid make sure it is not active
                    warnings.push({text: "The runbook has an error.  You can save the runbook but it will be disabled."});
                }

                if (showErrorsInSaveDialog) {
                    const errorList = errors.map(item => item.nodeName ? item.nodeName + ": " + item.text : item.text);
                    const warningList = warnings.map(item => item.nodeName ? item.nodeName + ": " + item.text : item.text);
                    setDialogState(updateDialogState(newDialogState, true, false, errorList, warningList));                         
                } else {
                    newDialogState = updateDialogState(newDialogState, true, false, [], []);
                    newDialogState.dialogContent = <SaveDialogContent showErrorSection={true} showErrorButton={false} errors={errors.length} warnings={warnings.length} allowAutomation={allowAutomation} />;
                    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={true} />;
                    setDialogState(newDialogState);
                }
            } else {
                // No errors enable the dialog
                newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} />;
                setDialogState(updateDialogState(newDialogState, true, false, [], []));                         
            }
            if (showErrorsInSaveDialog) {
                setShowErrors(true);
            }
        }
    }
}

/** Creates a popup that asks the user if they want to delete the runbook from the node-red server.
 *  @param currentRunbookRef the reference to the RunbookInfo argument with the active runbook configuration.
 *  @param setLoadRunbooks the function that is used to set the state variable that if true causes the runbook list to reload.
 *  @param setQueryParams the runbook that should be used to load an initial graph.
 *  @param newDialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param variant the runbook Variant that is being edited. */
function deleteContent(
    currentRunbookRef: {current: RunbookInfo}, setLoadRunbooks: (loadRunbooks: boolean) => void,
    setQueryParams: (params: paramsObj, updateHistory: boolean) => void, newDialogState: DialogState, 
    setDialogState: (dialogState: DialogState) => void, variant: Variant
): void {
    newDialogState.title = STRINGS.formatString(STRINGS.runbookEditor.deleteDialog.title, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[variant]});
    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.formatString(STRINGS.runbookEditor.deleteDialog.text, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})}</span></div>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
        setDialogState(updateDialogState(newDialogState, true, true, []));
        let errors: Array<string> = [];
        try {
            errors = await deleteRunbook(currentRunbookRef, setLoadRunbooks, setQueryParams, variant);
        } catch (error) {
            errors = [STRINGS.formatString(STRINGS.runbookEditor.errors.generalDeleteError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})];
        }
        setDialogState(updateDialogState(newDialogState, errors?.length > 0, false, errors || []));
    }} text={STRINGS.runbookEditor.deleteDialog.btnText} />;
    setDialogState(newDialogState);
}

/** Delete the runbook from the node-red server.
 *  @param currentRunbookRef the reference to the RunbookInfo argument with the active runbook configuration.
 *  @param setLoadRunbooks the function that is used to set the state variable that if true causes the runbook list to reload.
 *  @param setQueryParams the runbook that should be used to load an initial graph.
 *  @param variant the runbook Variant that is being edited.
 *  @returns an array of errors or an empty array if there are no errors.*/
async function deleteRunbook(
    currentRunbookRef: {current: RunbookInfo}, setLoadRunbooks: (loadRunbooks: boolean) => void,
    setQueryParams: (params: paramsObj, updateHistory: boolean) => void, variant: Variant
): Promise<Array<string>> {
    const currentRunbook = currentRunbookRef.current;
    try {
        if (currentRunbook && currentRunbook.id !== NEW_RUNBOOK.id) {
            const response = await runbookService.deleteRunbook(currentRunbook.id);
            if (response === "") {
                // Reset the graph
                currentRunbookRef.current = NEW_RUNBOOK;
                setLoadRunbooks(true);
                setQueryParams({ [PARAM_NAME.rbConfigId]: NEW_RUNBOOK.id, [PARAM_NAME.rbConfigNm]: NEW_RUNBOOK.label }, true);
                return Promise.resolve([]);
            } else {
                return Promise.resolve([STRINGS.formatString(STRINGS.runbookEditor.errors.generalDeleteError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})]);
            }
        } else {
            return Promise.resolve([STRINGS.formatString(STRINGS.runbookEditor.errors.newRunbookDeleteError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})]);
        }
    } catch (error) {
        console.error(error);
        return Promise.resolve([STRINGS.formatString(STRINGS.runbookEditor.errors.generalDeleteError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})]);
    }
}

/** Creates a popup that syncs the nodes to the node-red server.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param graphDef the GraphDef object with all the nodes and edges.
 *  @param reactFlowGraphComponent the react-flow GraphComponent with the graph.
 *  @param currentRunbook the RunbookInfo argument with the active runbook configuration.
 *  @param name a String with the name for the flow.
 *  @param desc a String with the description for the flow.
 *  @param version
 *  @param triggerType the input type for the flow.  The type can be "interface", "device"
 *      or "application" for alpha.
 *  @param setQueryParams the runbook that should be used to load an initial graph.
 *  @param setLoadRunbooks the function that is used to set the state variable that if true causes the runbook list to reload.
 *  @param setRunbookModified a reference to the function that is used to set the modified state.
 *  @param ignoreWarnings if true allow the user to save despite there being warnings.  If it is false do not
 *      allow the user to save if there are warnings.
 *  @param forceEtag a string with the etag to force.  This is for the case where the etag has expired and we
 *      want to save anyway.
 *  @param variables the map of variables by scope.
 *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param variant the variant of runbook, incident or lifecycle.
 *  @param allowAutomation .
 *  @returns a Promise with an object that contains the arrary of warnings and errors.*/
async function syncToServer(
    nodeLibrary: NodeLibrary, graphDef: GraphDef, reactFlowGraphComponent: OnLoadParams | null, currentRunbook: RunbookInfo | null, 
    name: string, desc: string, version: string | undefined, triggerType: InputType, setQueryParams: (params: paramsObj, updateHistory: boolean) => void, 
    setLoadRunbooks: (loadRunbooks: boolean) => void, setRunbookModified: (modified: boolean) => void, ignoreWarnings: boolean = false,
    forceEtag: string | undefined, variables: VariableContextByScope, customProperties: CustomProperty[], 
    subflows: Array<RunbookNode> = [], variant: Variant, allowAutomation: MutableRefObject<boolean>
): Promise<{warnings: Array<ValidationResult>, errors: Array<ValidationResult>, expiredEtag?: boolean}> {
    let outputJson: RunbookConfig = getRunbookConfigJson(graphDef, reactFlowGraphComponent, currentRunbook, name, desc, triggerType, variant, allowAutomation);
    
    let isUpdate: boolean = Boolean(currentRunbook && currentRunbook.id !== NEW_RUNBOOK.id);
    if (
        VARIANTS_WITH_VERSIONING.includes(variant) &&
        version && currentRunbook?.seriesId && Version.parse(version).isGreaterThan(new Version(0, 0, 0)) && 
        Version.parse(version).isGreaterThan(Version.parse(currentRunbook?.origVersion || ""))
    ) {
        const newVersion: Version = Version.parse(version);
        const oldVersion: Version = Version.parse(currentRunbook?.origVersion || "");
        if (newVersion.major === oldVersion.major && currentRunbook.id !== NEW_RUNBOOK.id) {
            // This is an update
            isUpdate = true;
        } else {
            // This is a post we have a majore
            isUpdate = false;
            delete outputJson.id;
        }
        outputJson.version = version;
        outputJson.seriesId = currentRunbook?.seriesId;

        // We might want to detect some edge cases, the user loads an old version and then updates to an already existing version.
        // The user downgrades the version, this should not be allowed.
    }
    
    // Validate the runbook
    let errors: Array<ValidationResult> = [], warnings: Array<ValidationResult> = [];
    try {
        const result = await validateRunbook(nodeLibrary, outputJson, graphDef, variables, customProperties, subflows, true, variant, allowAutomation);
        warnings = result.warnings;
        errors = result.errors;
    } catch (error) {
        console.error(error);
        errors = [{text: STRINGS.formatString(STRINGS.runbookEditor.errors.generalValidationError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})}];
    }

    // If there are any errors report them back and exit.
    if (!allowSaveWithErrors && (errors?.length > 0 || (!ignoreWarnings && warnings.length > 0))) {
        return Promise.resolve({warnings, errors});
    }

    outputJson.isValidated = errors.length === 0;

    /* disable by request (Bug 14649)
    if (!outputJson.isValidated && (currentRunbook && ('isReady' in currentRunbook) && currentRunbook.isReady)) {
        outputJson.isReady = false;
    }
    */

    if (currentRunbook?.i18nNameKey) {
        if (name === STRINGS.defaultRunbooks[currentRunbook.i18nNameKey]) {
            outputJson.i18nNameKey = currentRunbook.i18nNameKey;
            outputJson.name = currentRunbook.label;
        }    
    }
    if (currentRunbook?.i18nInfoKey) {
        if (desc === STRINGS.defaultRunbooks[currentRunbook.i18nInfoKey]) {
            outputJson.i18nInfoKey = currentRunbook.i18nInfoKey;
            outputJson.description = currentRunbook.info;
        }    
    }

    updateNodePositions(outputJson);

    try {
        let response;
        if (isUpdate) {
            response = await runbookService.updateRunbook(currentRunbook!.id, outputJson, forceEtag || currentRunbook!.eTag);
        } else {
            response = await runbookService.saveRunbook(outputJson)
        }
        if (typeof response === "object" && response.id) {
            // this should be the id, use it to re-render the graph and toolbar
            setRunbookModified(false);
            if (currentRunbook) {
                currentRunbook.id = response.id;
                currentRunbook.eTag = response.eTag;
                currentRunbook.seriesId = response.seriesId;
                currentRunbook.version = response.version;
                currentRunbook.origVersion = response.version;
            }
            
            if (IS_EMBEDDED && window.parent) {
                sendNotificationToParentWindow('runbookSaved', response.id, currentRunbook?.label)
            }

            // If the line below is uncommented the editor will not re-render the runbook
            //setLoadRunbooks(true);
            setQueryParams({ [PARAM_NAME.rbConfigId]: response.id, [PARAM_NAME.rbConfigNm]: name }, true);
        }
        return Promise.resolve({warnings: [], errors: []});
    } catch (error) {
        console.error(error);
        
        const errors: {text: string}[] = [];
        let expiredEtag = false;
        const status = (error as any)?.response?.status || 0;
        if (status === 412) {
            // The etag has expired because someone modified the configuration
            errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.etagSaveError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})});
            expiredEtag = true;
        } else {
            const data = (error as any)?.response?.data;
            const msg = data?.message || "";
            if (status === 400 && msg.includes("etag value is specified and is valid")) {
                // The etag is not understood by the back-end
                errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.etagSaveError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})});
                expiredEtag = true;
            } else {
                // We have all other errors
                let codeInfos: {code: string, params: {[x: string]: string}}[] = STRINGS.runbookEditor.errors.codes[data?.code] 
                    ? [{code: data.code, params: (data?.innererror || {name})}]
                    : [];

                // For the RBO the details array sometimes has more specific error information, so check for a known code there
                for (const details of data?.details || []) {
                    if (STRINGS.runbookEditor.errors.codes[details.code]) {
                        codeInfos.push({code: details.code, params: (details.innererror || {})});
                    }
                }

                for (const codeInfo of codeInfos) {
                    errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.codes[codeInfo.code], codeInfo.params)});
                }
                if (errors.length === 0) {
                    errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.generalSaveError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})});
                }
            }
        }
        return Promise.resolve({warnings: [], errors, expiredEtag});
    }
}

/** validates the runbook content and returns a string with the error information or null if there is no error.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param runbook the RunbookConfig object with the current contents of the runbook.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @param variables the map of variables by scope.
 *  @param customProperties the array of CustomProperty objects with the custom properties for 
 *       all the entity types. 
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param checkUniqueName a boolean value, if true we should check to make sure the name is unique, if false
 *      skip the uniqueness check.
 *  @param variant the runbook variant, incident or lifecycle.
 *  @param allowAutomation - allow automation ref
 *  @returns a Promise with an object that contains the arrary of warnings and errors.*/
async function validateRunbook(
    nodeLibrary: NodeLibrary, runbook: RunbookConfig, graphDef:GraphDef, variables: VariableContextByScope,
    customProperties: CustomProperty[], subflows: Array<RunbookNode> = [],
    checkUniqueName: boolean = true, variant: Variant, allowAutomation: MutableRefObject<boolean>
): Promise<{warnings: Array<ValidationResult>, errors: Array<ValidationResult>}> {
    const {errors, warnings} = validateNodesFromGraphDef(nodeLibrary, graphDef, variables, customProperties, subflows, variant);

    if (checkUniqueName) {
        try {
            const runbooks = await runbookService.getRunbooks(variant === Variant.EXTERNAL ? Variant.INCIDENT : variant);
            for (const savedRunbook of runbooks) {
                if (runbook.id !== savedRunbook.id) {
                    /* Allow this for now
                    if (!savedRunbook.isFactory && runbook.triggerType === savedRunbook.triggerType) {
                        // We are only allowed one user defined runbook of each type, so check to see that there 
                        // are no other runbooks of this type
                        errors.push(STRINGS.formatString(STRINGS.runbookEditor.errors.runbookTriggerTypeError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]}));
                    }
                    */
                    if (savedRunbook.name === runbook.name && (!runbook.seriesId || runbook.seriesId !== savedRunbook.seriesId)) {
                        // We should not allow duplicate names
                        errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.runbookDuplicateNameError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})});
                    }
                }
            }
        } catch (error) {
            errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.runbooksValidationQueryError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})});
        }    
    }

    // We should not allow the automation if there are errors in the runbook
    if (errors.length > 0 && IS_CREATED_FROM_WIZARD && !IS_EMBEDDED) {
        allowAutomation.current = false;
    }

    return Promise.resolve({warnings, errors});
}

/** first generates the runbook config object based on the current runbook graph and then updates the 
 *      nodes within the graph def object with the error flag. 
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @param reactFlowGraphComponent the react-flow GraphComponent with the graph.
 *  @param currentRunbook the RunbookInfo object with the latest information for the runbooks name, 
 *      description and trigger. 
 *  @param variant the variant of runbook, incident or lifecycle.
 *  @param allowAutomation - allow automation ref
 *  @param getVariables the function that is used to get the current set of variables for a scope. 
 *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param incidentVariablesUpdated . */
function generateRunbookConfigAndUpdateGraphDefWithErrors(
    nodeLibrary: NodeLibrary, graphDef: GraphDef, reactFlowGraphComponent: OnLoadParams | null, currentRunbook: RunbookInfo | null, 
    variant: Variant, allowAutomation: MutableRefObject<boolean>, getVariables: (scope, isBuiltIn) => VariableCollection, 
    customProperties: CustomProperty[], subflows: Array<RunbookNode> = [], incidentVariablesUpdated?
): void {
    const triggerType = (currentRunbook?.triggerType ? currentRunbook.triggerType : InputType.INTERFACE);
    const variables: VariableContextByScope = {
        runtime: getRuntimeOrSubflowVariablesFromRunbookConfig(currentRunbook as RunbookConfig, variant),
        incident: getIncidentVariables(getVariables, incidentVariablesUpdated || null, triggerType),
        global: getVariables(GLOBAL_SCOPE, true)
    };
    const runbook = getRunbookConfigJson(graphDef, reactFlowGraphComponent, currentRunbook, "", "", triggerType, variant, allowAutomation);
    updateGraphDefWithErrors(nodeLibrary, graphDef, runbook, variables, customProperties, subflows, variant);
}

/** generates the runtime variables object.
 *  @param runbook the RunbookConfig with the runbook whose variable scope is requested.
 *  @returns an object with the runtime variables. */
function getRuntimeOrSubflowVariablesFromRunbookConfig(runbook: RunbookConfig, variant: Variant) {
    return {
            primitiveVariables: [
            ...(runbook?.[getVariablesKey(variant)]?.primitiveVariables || []),
            ...(RUNBOOK_SCOPE_BUILTIN_VARIABLES),
            ...(getRuntimeBuiltinVariables(runbook.triggerType))
            ],
            structuredVariables: [
            ...(runbook?.[getVariablesKey(variant)]?.structuredVariables || []),
            ]
    };
}

/** generates the incident variables object.
 *  @param getVariables the function that is used to get the current set of variables for a scope.
 *  @param updatedIncidentVariables the updated incident variables without the builtin ones.
 *  @param triggerType the input type for the flow.  The type can be "interface", "device"
 *  @returns an object with the incident variables. */
function getIncidentVariables(getVariables, incidentVariablesUpdated, triggerType) {
    if (incidentVariablesUpdated) {
        if (triggerType === InputType.WEBHOOK) {
            return { primitiveVariables: [], structuredVariables: []};
        } else {
            return {
                primitiveVariables: [...incidentVariablesUpdated.primitiveVariables, ...INCIDENT_SCOPE_BUILTIN_VARIABLES],
                structuredVariables: [...incidentVariablesUpdated.structuredVariables]
            };
        }
    } else {
        return getVariables(INCIDENT_SCOPE, true);
    }
}

/** updates the nodes within the graph def object with the error flag. 
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param runbook the RunbookConfig object with the current contents of the runbook.
 *  @param graphDef the GraphDef object with the current graph definition. 
 *  @param variables the map of variables by scope. 
 *  @param customProperties the array of CustomProperty objects with the custom properties for 
 *       all the entity types. 
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param variant the variant, incident or lifecycle. */
export function updateGraphDefWithErrors(
    nodeLibrary: NodeLibrary, graphDef: GraphDef, runbook: RunbookConfig, variables: VariableContextByScope,
    customProperties: CustomProperty[], subflows: Array<RunbookNode> = [], variant: Variant
): void {
    try {
        const {errors, warnings} = validateNodesFromGraphDef(nodeLibrary, graphDef, variables, customProperties, subflows, variant);
        
        // Check for warnings and errors across all nodes
        delete graphDef.warnings;
        if (warnings?.length > 0) {
            for (const warning of warnings) {
                if (!warning.nodeId) {
                    if (!graphDef.warnings) {
                        graphDef.warnings = [];
                    }
                    graphDef.warnings.push(warning.text);
                }
            }
        }
        delete graphDef.errors;
        if (errors?.length > 0) {
            for (const error of errors) {
                if (!error.nodeId) {
                    if (!graphDef.errors) {
                        graphDef.errors = [];
                    }
                    graphDef.errors.push(error.text);
                }
            }
        }    
        
        // Check for errors tied to a particular node
        if (graphDef.nodes?.length > 0) {
            for (const node of graphDef.nodes) {
                delete node.warnings;
                if (warnings?.length > 0) {
                    for (const warning of warnings) {
                        if (warning.nodeId && warning.nodeId === node.id) {
                            if (!node.warnings) {
                                node.warnings = [];
                            }
                            node.warnings.push(warning.text);
                        }
                    }
                }
                delete node.errors;
                if (errors?.length > 0) {
                    for (const error of errors) {
                        if (error.nodeId && error.nodeId === node.id) {
                            if (error.additionalInfo !== undefined && error.additionalInfo === AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR) {
                                graphDef.additionalInformation = AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR;
                            }
                            if (!node.errors) {
                                node.errors = [];
                            }
                            node.errors.push(error.text);
                        }
                    }
                }
            }
        }
    } catch (error) {
        delete graphDef.warnings;
        graphDef.errors = [];
        if (graphDef.nodes?.length > 0) {
            for (const node of graphDef.nodes) {
                delete node.warnings;
                delete node.errors;
            }
        }
        graphDef.errors.push(STRINGS.formatString(STRINGS.runbookEditor.errors.generalValidationError, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]}));
    }
}

/** returns the GraphDef object that corresponds to the specified runbook.
 *  @param library a reference to the NodeLibrary.
 *  @param runbook the runbook.
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param integrations the list of installed integrations.
 *  @returns the GraphDef object for the flow.*/
export function getGraphDefFromRunbookConfig(library: NodeLibrary, runbook: RunbookConfig, subflows: Array<RunbookNode> =[], integrations?: RunbookIntegrationDetails[]): GraphDef {
    const newGraphDef: GraphDef = { nodes: [], edges: [] };
    if (runbook && runbook.nodes) {
        const dataOceanFiltersMap = getDataOceanFiltersMap(runbook.variant || Variant.INCIDENT);
        for (const node of runbook.nodes) {
            const graphNode: NodeDef = { id: node.id, type: node.type, name: node.label || "", info: node.description || "", editedByUser: true};
            
            // Check for i18n for the name
            if (node.i18nNameKey) {
                graphNode.i18nNameKey = node.i18nNameKey;
            }
            if (node.i18nInfoKey) {
                graphNode.i18nInfoKey = node.i18nInfoKey;
            }
            if (skeletonNodes.includes(node.type)) {
                continue;
            }
            
            const nodePropObject = node.properties;

            // Create a temporary properties object to determine the subtype
            const subTypeProps: Array<NodeProperty> = [];
            for (const key in nodePropObject) {
                subTypeProps.push({ key: key, value: nodePropObject[key] });
            }

            const libraryNode = getLibraryNode(library, node.type, subflows,  subTypeProps, integrations);

            // Declare properties that we know about and will keep track of
            const knownProperties: Array<string> = ["id", "type", "label", "description", "wires", "i18nNameKey", "i18nInfoKey", "editedByUser"];

            // These are interesting, we probably need to load in Z and make use of it
            knownProperties.push("x");
            knownProperties.push("y");
            knownProperties.push("z");

            if (nodePropObject.x && nodePropObject.y) {
                graphNode.x = nodePropObject.x;
                graphNode.y = nodePropObject.y;    
            }

            if (libraryNode) {
                if (libraryNode.color) {
                    graphNode.color = libraryNode.color;
                }
                if (libraryNode.icon) {
                    graphNode.icon = libraryNode.icon;
                }
                if (libraryNode.wires) {
                    graphNode.wires = libraryNode.wires;
                    if (isDecisionNodeFromGraphDef(graphNode)) {
                        graphNode.wires.outputsCount = nodePropObject?.outputs?.length || 0;
                    }
                }

                if (libraryNode.properties) {
                    for (const prop of libraryNode.properties) {
                        knownProperties.push(prop.name);
                        if (nodePropObject[prop.name] !== null && nodePropObject[prop.name] !== undefined) {
                            if (!graphNode.properties) {
                                graphNode.properties = ([] as Array<NodeProperty>);
                            }
                            let propValue = nodePropObject[prop.name];
                            if (prop.name && prop.name === "filters") {
                                // All new runbooks will have $trigger, but old runbooks might have $runbook.  Convert $runbook
                                // to $trigger
                                propValue = JSON.parse(JSON.stringify(propValue));
                                for (const filterKey in propValue) {
                                    if (propValue[filterKey] && propValue[filterKey]?.length > 0 && typeof propValue[filterKey][0] === "string") {
                                        if (propValue[filterKey][0] === TRIGGER_OPTION_VALUE_OLD) {
                                            propValue[filterKey][0] = TRIGGER_OPTION_VALUE;
                                        } else if (propValue[filterKey][0].startsWith(NODE_OPTION_VALUE_AP) && CONTEXT_MODE === ContextMode.CLOSEST_PARENT) {
                                            // We want to convert all the $node:id.filter tokens to $closestAncestor tokens to make copy/paste
                                            // and import/export easier
                                            const filterValue = propValue[filterKey][0];
                                            let filterName = filterValue.substring(filterValue.lastIndexOf(".") + 1, filterValue.length);
                                            let nodeId = filterValue.substring(filterValue.lastIndexOf(":") + 1, filterValue.lastIndexOf("."));
                                            for (const doKey in dataOceanFiltersMap) {
                                                if (dataOceanFiltersMap[doKey].key === filterKey) {
                                                    // When we were saving the runbook we took the filter expression of the form:
                                                    // "serverLocation": [
                                                    //     "$node:fb410ece-ddc8-483a-9b7d-44134472f396.server_location"
                                                    // ]
                                                    // and we took the server_location and if it came from a compatible filters
                                                    // we switched it to, for example, client_location.  Now we want to switch it 
                                                    // back so we look for serverLocation in the meta data and find the do key for 
                                                    // serverLocation and restore that do key.  We might want to consider moving this
                                                    // into the DoFilters React component.
                                                    filterName = doKey;
                                                    break;
                                                } 
                                            }
                                            let isSubflowInput: boolean = false;
                                            for (const checkNode of runbook.nodes) {
                                                if (isSubflowInputNode(checkNode) && checkNode.id === nodeId) {
                                                    isSubflowInput = true;
                                                    break;
                                                }
                                            }
                                            if (isSubflowInput) {
                                                propValue[filterKey][0] = `${ SUBFLOW_INPUT_OPTION_VALUE }.${ filterName }`;                                           
                                            } else {
                                                propValue[filterKey][0] = `${ NODE_OPTION_VALUE_CP }.${ filterName }`;                                           
                                            }
                                        }
                                    }
                                }
                            }
                            graphNode.properties.push({ key: prop.name, value: propValue });
                        }
                    }
                }
            }

            // Handle the environment settings on subflows.
            knownProperties.push("env");
            if (node.env) {
                graphNode.env = ([] as Array<NodeEnvSetting>);
                for (const setting of node.env) {
                    graphNode.env.push(setting as NodeEnvSetting);
                }
            }

            // Handle properties that are in runbook, but we cannot edit here, just store them
            // in the node and restore them to the object later.
            for (const nodePropName in nodePropObject) {
                if (!knownProperties.includes(nodePropName)) {
                    if (!graphNode.passThruProperties) {
                        graphNode.passThruProperties = ([] as Array<NodeProperty>);
                    }
                    graphNode.passThruProperties.push({ key: nodePropName, value: nodePropObject[nodePropName] });
                }
            }

            newGraphDef.nodes.push(graphNode);
            if (node.wires) {
                for (let port = 0; port < node.wires.length; port++) {
                    if (typeof node.wires[port] === "string") {
                        // We have a string
                        const edge = { fromNode: node.id, toNode: node.wires[port] };
                        if (libraryNode?.wires?.supportsMultipleOutputs) {
                            // Subflows support multiple ports on the source side, so add the port
                            (edge as any).fromPort = port.toString();
                        }
                        newGraphDef.edges.push(edge);
                    } else if (Array.isArray(node.wires[port])) {
                        // The wire is an array
                        for (const toId of (node.wires[port] as Array<string>)) {
                            const edge = { fromNode: node.id, toNode: toId }
                            if (libraryNode?.wires?.supportsMultipleOutputs) {
                                // Subflows support multiple ports on the source side, so add the port
                                (edge as any).fromPort = port.toString();
                            }
                            newGraphDef.edges.push(edge);
                        }
                    } else {
                        // The wire is an object, if have seen this with subflows
                    }
                }
            }
        }
    }
    return newGraphDef;
}

/** creates the subflow node for the node library. 
 *  @param runbooks the list of runbooks that are subflows.
 *  @param subflowNodeLibrary the NodeLibrary.
 *  @param integrations the array of RunbookIntegrationDetails that is used to get the subflow color and svg for integrations.
 *  @returns the list of RunbookNodes with the subflows.  Each element in the array is a subflow. */
export function createSubflowNodes(
    runbooks: RunbookConfig[], subflowNodeLibrary: NodeLibrary, integrations?: RunbookIntegrationDetails[]
): RunbookNode[] {
    // if you change this you need to go to checkConnectionToSubflow in the RunbookValidationUtils and make a change
    const ONE_INPUT_PER_KEY: boolean = false;
    const subflows: RunbookNode[] = [];

    for (const runbook of runbooks) {
        const subflowVariables = runbook.subflowVariables || {primitiveVariables: [], structuredVariables: []};

        let numOutputs: number = 0;
        if (runbook.nodes?.length) {
            for (const node of runbook.nodes) {
                if (isSubflowOutputNode(node)) {
                    const handle: number = node.properties?.index;
                    if (!Number.isNaN(handle) && handle >= 0) {
                        numOutputs = Math.max(handle + 1, numOutputs);
                    }
                }
            }    
        }
        const subflow: RunbookNode = {
            id: runbook.id || "",
            type: "subflow",
            name: runbook.name,
            info: runbook.description || "",
            category: "",
            in: [],
            out: Array(numOutputs),
            meta: {
                module: "ModuleName"
            },
            color: DEFAULT_SUBFLOW_COLOR,
            inputLabels: [],
            outputLabels: Array(numOutputs),
            // Should this be isFactory
            builtIn: runbook.isFactory,
            icon: "SUBFLOW",
            version: runbook.version,
            seriesId: runbook.seriesId,
            otherVersions: runbook.otherVersions
        };

        const subflowGraphDef = getGraphDefFromRunbookConfig(subflowNodeLibrary, runbook, [], integrations);
        if (subflowGraphDef?.nodes?.length) {
            for (const node of subflowGraphDef.nodes) {
                if (isSubflowInputNodeFromGraphDef(node)) {
                    let synthKeysProps: NodeProperty[] | undefined = node.properties?.filter(prop => prop.key === "synthKeys");
                    if (synthKeysProps?.length) {
                        const doKeys: string[] = [];
                        for (const synthKey of synthKeysProps[0].value) {
                            if (synthKey.dataOceanId) {
                                if (ONE_INPUT_PER_KEY) {
                                    subflow.in!.push(
                                        {x: 40, y: 50, wires: [
                                            {
                                                id: "1", type: {
                                                    id: synthKey.dataOceanId,
                                                    label: synthKey.label,
                                                    type: "data_ocean",
                                                    unit: "none"
                                                }
                                            }
                                        ]}
                                    );
                                    subflow.inputLabels!.push(node.name || "");    
                                } else {
                                    doKeys.push(synthKey.dataOceanId);
                                }                       
                            }
                        }
                        if (!ONE_INPUT_PER_KEY) {
                            subflow.in!.push(
                                {x: 40, y: 50, wires: [
                                    {
                                        id: "1", context: {
                                            keys: doKeys,
                                            type: "data_ocean",
                                        }
                                    }
                                ]}
                            );
                            subflow.inputLabels!.push(node.name || "");    
                        }
                    }
                    let inputVariables: NodeProperty[] | undefined = node.properties?.filter(prop => prop.key === "inputVariables");
                    if (inputVariables?.length) {
                        const variables: NodeSubflowVariableDefinition[] = [];
                        for (const inputVariable of inputVariables[0].value) {
                            for (const primitiveVariable of subflowVariables.primitiveVariables) {
                                if (primitiveVariable.name === inputVariable) {
                                    variables.push({name: inputVariable, type: primitiveVariable.type, unit: primitiveVariable.unit});
                                }
                            }
                            for (const structuredVariable of subflowVariables.structuredVariables) {
                                if (structuredVariable.name === inputVariable) {
                                    variables.push({name: inputVariable, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries});
                                }
                            }
                        }
                        /* This was for debugging purposes
                        variables.push({name: "subflow.StrVar", type: "string"});
                        variables.push({name: "subflow.MyFloat", type: "float", unit: "bps"});
                        variables.push({name: "subflow.MyAuthProfile", type: "auth_profile", unit: "none"});
                        variables.push({name: "subflow.MyEdge", type: "alluvio_edge", unit: "none"});
                        variables.push({name: "subflow.MyCustomSum", type: "custom", unit: "none", isTimeseries: false});
                        variables.push({name: "subflow.MyCustomTime", type: "custom", unit: "none", isTimeseries: true});
                        variables.push({name: "subflow.MyInterfaceSum", type: "network_interface", unit: "none", isTimeseries: false});
                        */
                        subflow.inputVariables = variables;
                    }
                    let outputVariables: NodeProperty[] | undefined = node.properties?.filter(prop => prop.key === "outputVariables");
                    if (outputVariables?.length) {
                        const variables: NodeSubflowVariableDefinition[] = [];
                        for (const outputVariable of outputVariables[0].value) {
                            for (const primitiveVariable of subflowVariables.primitiveVariables) {
                                if (primitiveVariable.name === outputVariable) {
                                    variables.push({name: outputVariable, type: primitiveVariable.type, unit: primitiveVariable.unit});
                                }
                            }
                            for (const structuredVariable of subflowVariables.structuredVariables) {
                                if (structuredVariable.name === outputVariable) {
                                    variables.push({name: outputVariable, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries});
                                }
                            }
                        }
                        /* This was for debugging purposes
                        variables.push({name: "subflow.OutStr", type: "string", unit: "none"});
                        */
                        subflow.outputVariables = variables;
                    }

                    let integrationIds: NodeProperty[] | undefined = node.properties?.filter(prop => prop.key === SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS);
                    if (integrationIds && Object.values(integrationIds)?.length) {
                        subflow.integrationId = Object.values(integrationIds[0].value)[0];
                        const connectorIntegrationDetails: RunbookIntegrationDetails | undefined = (integrations || []).find(integration => integration.id === subflow.integrationId);
                        if (connectorIntegrationDetails) {
                            subflow.color = connectorIntegrationDetails?.branding?.primaryColor || DEFAULT_SUBFLOW_COLOR;
                            subflow.icon = getIntegrationIcon(connectorIntegrationDetails?.branding?.icons, connectorIntegrationDetails.name, "avatar", connectorIntegrationDetails?.branding?.secondaryColor);
                            subflow.integrationId = connectorIntegrationDetails?.id;
                        }
                    }
                } else if (isSubflowOutputNodeFromGraphDef(node)) {
                    const runbookContext = new RunbookContext(node, subflowGraphDef, DataOceanUtils.dataOceanMetaData);
                    let outputIndexProps: NodeProperty[] | undefined = node.properties?.filter(prop => prop.key === "index");
                    if (outputIndexProps?.length) {
                        const handle = parseInt(outputIndexProps[0].value);
                        if (!Number.isNaN(handle) && handle >= 0 && handle < numOutputs) {
                            // Get the context
                            const contexts: Context[] = runbookContext.getNodeContexts();
                            let context: Context | undefined = contexts?.length ? contexts[contexts.length - 1] : undefined;
                            if (!context) {
                                context = runbookContext.getTriggerContext();
                            }
                            if (context) {
                                subflow.out![handle] = {x: 40, y: 50, wires: [
                                    { id: "1", context: context }
                                ]};
                                subflow.outputLabels![handle] = node.name || "";            
                            }
                        }
                    }
                }
            }    
        } 

        subflows.push(subflow);
    }

    return subflows;
}

/** returns the library node for the specified type.
 *  @param library a reference to the NodeLibrary.
 *  @param type a String with the type whole library node is requested.
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param nodeProperties the Array of NodeProperty objects with the properties for the node.
 *  @param integrations the list of installed integrations.
 *  @returns the NodeLibraryNode or null if no library node is found. */
export function getLibraryNode(
    library: NodeLibrary, type: string, subflows: Array<RunbookNode> = [], nodeProperties?: Array<NodeProperty>,
    integrations?: RunbookIntegrationDetails[]
): NodeLibraryNode | null {
    const nodeFromLibrary = library.getNodeUsingProperties(type, nodeProperties);
    if (nodeFromLibrary) {
        return nodeFromLibrary;
    }

    let subflowId = "";
    // Removing subflow:
    //if (type.includes(":")) {
    //    subflowId = type.substring(type.indexOf(":") + 1);
    //}
    if (subflowNodes.includes(type)) {
        for (const property of (nodeProperties || [])) {
            if (property.key === "configurationId") {
                subflowId = property.value;
                break;
            }
        }
    }
    for (const subflow of subflows) {
        if (subflow.id === subflowId) {
            let wires = {
                direction: "none", in: subflow.in, inputLabels: subflow.inputLabels,
                out: subflow.out, outputLabels: subflow.outputLabels, supportsMultipleOutputs: true
            } as NodeWiresSetting;
            if ((subflow?.in?.length || 0) > 0 && (subflow?.out?.length || 0) > 0) {
                wires.direction = DIRECTION.BOTH;
            } else if ((subflow?.in?.length || 0) > 0) {
                wires.direction = DIRECTION.IN;
            } else if ((subflow?.out?.length || 0) > 0) {
                wires.direction = DIRECTION.OUT;
            }

            let integrationInfo: any = undefined;
            let integrationIds: NodeProperty[] | undefined = nodeProperties?.filter(prop => prop.key === SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS);
            if (integrationIds && Object.values(integrationIds)?.length) {
                subflow.integrationId = Object.values(integrationIds[0].value)[0];
                const connectorIntegrationDetails: RunbookIntegrationDetails | undefined = (integrations || []).find(integration => integration.id === subflow.integrationId);
                if (connectorIntegrationDetails) {
                    integrationInfo = {
                        id: subflow.integrationId, 
                        icon: connectorIntegrationDetails?.branding?.icons?.find(icon => icon.type === "avatar")?.svg,
                        name: connectorIntegrationDetails?.name || "",
                        primaryColor: connectorIntegrationDetails?.branding?.primaryColor || DEFAULT_SUBFLOW_COLOR,
                        secondaryColor: connectorIntegrationDetails?.branding?.secondaryColor
                    };
                }
            }

            const finalNode = {
                subflowName: "crb"+subflow.name || "subflow", subflowId: subflowId, subflowBuiltIn: subflow.builtIn || false, 
                type: type, "color": subflow.color || DEFAULT_SUBFLOW_COLOR, uiAttrs: {showDebug: true}, wires, env: subflow.env, 
                subflowVariables: {input: subflow.inputVariables || [], output: subflow.outputVariables || []},
                integrationId: subflow.integrationId || "",
                integrationInfo: integrationInfo,
                properties: [
                    // The description is now a default property
                    // { name: "info", label: "Description", type: "textarea", default: "" }
                    // This was not there
                    {name: "debug", type: "boolean", label: "debug", default: false},
                    {name: "configurationId", label: "configurationId", type: "hidden", default: subflow.id},
                    {name: "in", label: "in", type: "hidden", default: (subflow.inputVariables || []).map(variable => {return {inner: variable.name, outer: ""}})},
                    {name: "out", label: "out", type: "hidden", default: (subflow.outputVariables || []).map(variable => {return {inner: variable.name, outer: ""}})}
                ],
                subflowDescription: subflow.info
            };

            return finalNode;
        }
    }

    //if (type.startsWith("subflow:")) {
    if (subflowNodes.includes(type)) {
        // If we didn't locate the subflow return a generic subflow library node.
        let wires: NodeWiresSetting = { direction: DIRECTION.BOTH, supportsMultipleOutputs: true };
        return {
            subflowName: "subflow", type: type, "color": "#fffff", wires, properties: [
                // The description is now a default property 
                //{ name: "info", label: "Description", type: "textarea", default: "" }
            ]
        };
    }
    return null;
}

/** creates a basic graph with a comment instructing the user what to do.
 *  @param initTriggerType a String with the initial trigger type to be added to the runbook.
 *  @returns the GraphDef object with the basic graph that should be displayed to the user when they create
 *      a new runbook. */
export function createBasicRunbook(initTriggerType: string | undefined): GraphDef {
    const newGraphDef: GraphDef = !IS_EMBEDDED && !initTriggerType ? {nodes: [], edges: []} : INIT_EMBED_GRAPH_DATA;
    if (initTriggerType) {
        switch (initTriggerType) {
            case InputType.DEVICE:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.NetworkDevice.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.INTERFACE:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.NetworkInterface.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.APPLICATION_LOCATION:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.Application.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.LOCATION:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.Location.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.APPLICATION:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.ApplicationOnly.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.WEBHOOK:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.Webhook.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.IMPACT_ANALYSIS_READY:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.RunbookCompleted.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.INCIDENT_INDICATORS_UPDATED:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.IndicatorsUpdated.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.INCIDENT_NOTE_ADDED:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.NoteAdded.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.INCIDENT_NOTE_UPDATED:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.NoteUpdated.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.INCIDENT_NOTE_DELETED:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.NoteDeleted.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.INCIDENT_ONGOING_CHANGED:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.OngoingStateChanged.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.INCIDENT_STATUS_CHANGED:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.trigger.subTypes.StatusChanged.name;
                newGraphDef.nodes![0].properties![0].value = initTriggerType;
                break;
            case InputType.SUBFLOW:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.subflow_input.name;
                newGraphDef.nodes![0].type = "subflow_input";
                newGraphDef.nodes![0].icon = "log-in";
                newGraphDef.nodes![0].properties = [
                    {key: "triggerType", value: initTriggerType},
                    {key: "outputDataFormat", value: "summary"},
                    {key: "debug", value: false},    
                    {key: "synthKeys", value: []},
                    {key: "inputVariables", value: []},
                    {key: "outputVariables", value: []},
                    {key: "integrationIds", value: {}},
                    {key: "staticInputValuesLists", value: {}}
                ];
                break;
            case InputType.ON_DEMAND:
                newGraphDef.nodes![0].name = STRINGS.runbookEditor.nodeLibrary.nodes.on_demand_input.name;
                newGraphDef.nodes![0].type = "on_demand_input";
                newGraphDef.nodes![0].icon = "log-in";
                newGraphDef.nodes![0].properties = [
                    {key: "triggerType", value: initTriggerType},
                    {key: "outputDataFormat", value: "summary"},
                    {key: "debug", value: false},    
                    {key: "synthKeys", value: []},
                    {key: "inputVariables", value: []},
                    {key: "outputVariables", value: []}
                ];
                break;
        }
    }
    return newGraphDef;
}

/** Setting default properties on the node when it is added.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param node the react-flow node that is being added.
 *  @param variant the runbook Variant that is being edited. 
 *  @param subflows the list of subflow definitions. 
 *  @param integrations the list of installed integrations. */
function setDefaultPropertiesOnAdd (
    nodeLibrary: NodeLibrary, node: Node, variant: Variant, subflows: RunbookNode[],
    integrations: RunbookIntegrationDetails[]
): void {
    if (dataOceanNodes.includes(node.data.type)) {
        DataOceanUtils.setDefaultPropertiesOnAdd(nodeLibrary, node, variant);
    } else if (subflowNodes.includes(node.data.type)) {
        let configurationId = node?.data?.properties?.filter(prop => prop.key === "configurationId")?.[0]?.value;
        const subflowNode = subflows.find(subflow => subflow.id === configurationId);
        let inProp: any = undefined;
        let inProps = node?.data?.properties?.filter(prop => prop.key === "in");
        if (inProps?.length) {
            inProp = inProps[0];
        }
        if (
            subflowNode && subflowNode.inputVariables?.length && subflowNode.integrationId &&
            subflowNode.inputVariables.find(variable => variable.type === PrimitiveVariableType.CONNECTOR) &&
            inProp && inProp.value?.length
        ) {
            const connectorIntegrationDetails: RunbookIntegrationDetails | undefined = (integrations || []).find(
                integration => integration.id === subflowNode.integrationId
            );
            if (connectorIntegrationDetails && connectorIntegrationDetails.connectors?.length === 1) {
                inProp?.value.forEach(input => {
                    const connectorInputVariable = subflowNode.inputVariables.find(
                        variable => variable.type === PrimitiveVariableType.CONNECTOR && variable.name === input.inner
                    );
                    if (connectorInputVariable && connectorIntegrationDetails.connectors?.length === 1) {
                        input.outer = connectorIntegrationDetails.connectors[0].connectorId;
                        input.method = "connector";    
                    }
                });    
            }
        }
    } else if (columnEditorChartNodes.includes(node.data.type)) {      
        let columnsProps = node.data.properties.filter(prop => prop.key === "columns");
        let columnsProp: any = undefined;
        if (!columnsProps?.length) {
            columnsProp = {key: "columns", value: []};
            node.data.properties.push(columnsProp);
        } else {
            columnsProp = columnsProps[0];
        }
        if (!columnsProp.value.includes("group_column")) {
            columnsProp.value.push("group_column");
        }
    }
}

/** In runbook template's graphdef, if there is any whitespace to the top or left, this
 *      method will remove that whitespace by offsetting all the nodes with a min whitespace of 50.
 *      Fixes Bug 9396. Will also fix a case where user drags the graph over to a negative XY
 *      co-ordinate and saves the template.  This only happens on read to fix any old runbooks that
 *      have a weird offset.
 *  @param graphDef the GraphDef object with all the nodes in the graph. */
export function removeWhitespaceFromGraphDef(graphDef: GraphDef): void {
    const MIN_OFFSET = 50, MAX_OFFSET = 500;
    if (graphDef?.nodes?.length) {
        let minX = Number.MAX_SAFE_INTEGER;
        let minY = Number.MAX_SAFE_INTEGER;    
        for (const node of graphDef.nodes) {
            if (node.x !== undefined && node.x !== null) {
                minX = Math.min(node.x, minX);
            }
            if (node.y !== undefined && node.y !== null) {
                minY = Math.min(node.y, minY);
            }
        }

        // Check to see if anything is to the left of MIN_OFFSET or to the right of MAX_OFFSET
        if (minX !== Number.MAX_SAFE_INTEGER && (minX < MIN_OFFSET || minX > MAX_OFFSET)) {
            // We want to shift all nodes such that no node is to the left of 50
            for (const node of graphDef.nodes) {
                if (node.x !== undefined) {
                    node.x = node.x + (MIN_OFFSET - minX);            
                }
            }
        }

        // Check to see if anything is above MIN_OFFSET or below MAX_OFFSET.
        if (minY !== Number.MAX_SAFE_INTEGER && (minY < MIN_OFFSET || minY > MAX_OFFSET)) {
            // We want to shift all nodes such that no node is to the above 50
            for (const node of graphDef.nodes) {
                if (node.y !== undefined) {
                    node.y = node.y + (MIN_OFFSET - minY);            
                }
            }
        }
    }
}

/** This function updates the node positions when saving the runbook.  Why would we need to do this?
 *      If the user has panned the graph editor and then drops a node in the upper left corner of the 
 *      graph they might think that this node will be at the origin of the coordinate system, but it 
 *      is not because of the panning, this node might have a very negative x or y value or possibly 
 *      a very high positive value, depending on which way they panned, which will cause it to go off 
 *      the screen when the graph is reloaded.  This function calculates the minX and minY node position 
 *      and then shifts all nodes so that the left most node is 50 pixels to the right of the left edge 
 *      and all nodes are at least 50 pixels below the top edge.  It only does the shift if the minX and
 *      minY values are outside of some tolerance.
 *  @param runbook the RunbookConfig object.*/
function updateNodePositions(runbook: RunbookConfig): void {
    const MIN_OFFSET = 50, MAX_OFFSET = 500;
    if (runbook?.nodes?.length) {
        let minX = Number.MAX_SAFE_INTEGER;
        let minY = Number.MAX_SAFE_INTEGER;    
        for (const node of runbook.nodes) {
            const nodeProperties = node.properties;
            if (nodeProperties.x !== undefined && nodeProperties.x !== null) {
                minX = Math.min(nodeProperties.x, minX);
            }
            if (nodeProperties.y !== undefined && nodeProperties.y !== null) {
                minY = Math.min(nodeProperties.y, minY);
            }
        }

        // Check to see if anything is to the left of MIN_OFFSET or to the right of MAX_OFFSET
        if (minX !== Number.MAX_SAFE_INTEGER && (minX < MIN_OFFSET || minX > MAX_OFFSET)) {
            // We want to shift all nodes such that no node is to the left of 50
            for (const node of runbook.nodes) {
                const nodeProperties = node.properties;
                if (nodeProperties.x !== undefined) {
                    nodeProperties.x = nodeProperties.x + (MIN_OFFSET - minX);            
                }
            }
        }

        // Check to see if anything is above MIN_OFFSET or below MAX_OFFSET.
        if (minY !== Number.MAX_SAFE_INTEGER && (minY < MIN_OFFSET || minY > MAX_OFFSET)) {
            // We want to shift all nodes such that no node is to the above 50
            for (const node of runbook.nodes) {
                const nodeProperties = node.properties;
                if (nodeProperties.y !== undefined) {
                    nodeProperties.y = nodeProperties.y + (MIN_OFFSET - minY);
                }            
            }
        }
    }
}
 
/**
 * If the UI is embedded, send a notification to the parent window when runbook is modified/saved
 * 
 * @param {string} message
 * @param {string} runbookId
 * @param {string} runbookName
 * @param {string=} previousRunbookName
 */
function sendNotificationToParentWindow(message: string, runbookId: string, runbookName: string | undefined, previousRunbookName?: string | undefined) {
    if (!window?.parent) {
        return;
    }

    /* 
       This will return the url of the site in which the iframe is embedded
       We need to take into account that Firefox does not have ancestorOrigins
    */
    const parentOrigin = window.location?.ancestorOrigins?.length ? window.location.ancestorOrigins[0] : document.referrer;

    window.parent.postMessage(previousRunbookName ? {
        message: message,
        id:  runbookId,
        name: runbookName || '',
        previousName: previousRunbookName,
    }: {
        message: message,
        id:  runbookId,
        name: runbookName || '',
    }, parentOrigin);
}
