import React from 'react';
import { FormGroup, Checkbox } from '@blueprintjs/core';
import { ErrorMessage, useField } from 'formik';

function CheckboxField({label, required, helperText, className, ...props}: any) {

    const [field, meta] = useField(props);
    //this component might need more work, checkbox always end up being complicated

    return (
        <FormGroup labelFor={field.name} helperText={helperText} className={className}>
            <Checkbox id={field.name} checked={meta.value} {...field} {...props} label={label}/>
            <ErrorMessage name={field.name}/>
        </FormGroup>
    );
}

export { CheckboxField };
