import React from "react";
import { Button, ButtonGroup } from "@blueprintjs/core";
import "./TimePeriodViewLast.scss";
import { STRINGS } from "app-strings";
import {
    TIME_OPTION,
    TIME_OPTION_VALUES,
} from "components/common/time-range-selector/TimeRangeSelector";
import { DURATION } from "utils/hooks";
import { TIME_RANGE } from "utils/stores/GlobalTimeStore";
export interface TimePeriodOptions {
    showTimePeriodOptions?: Array<TIME_OPTION>;
    selectedOption?: TIME_OPTION;
    durationSelectionHandler: (
        selecteTimeOption: TIME_OPTION,
        timeRange?: TIME_RANGE
    ) => void;
}

export const DURATION_SHOW_SCREEN_SIZE = {
    [DURATION?.DAY_7]: "xl",
    [DURATION?.DAY_1]: "lg",
    [DURATION?.HOUR_6]: "lg",
    [DURATION?.HOUR_1]: "lg",
    [DURATION?.MIN_30]: "lg",
    [DURATION?.MIN_15]: "lg",
}

export function TimePeriodViewLast(props: TimePeriodOptions) {

    function allowedTimePeriod(showTimePeriodOptions) {
        let time_period_windows = { ...DURATION };
        const showTimePeriodList = Array.from(new Set(showTimePeriodOptions));
        for (let key in time_period_windows) {
            if (!showTimePeriodList.includes(key)) {
                delete time_period_windows[key];
            }
        }
        return time_period_windows;
    }

    const timePeriodData = allowedTimePeriod(props.showTimePeriodOptions);

    const renderButtons = Object.keys(timePeriodData).map((key, el) => (
        <Button
            role="viewLastOptionButton"
            key={key}
            aria-label={"timePeriodViewLast-option-" + key}
            minimal={true}
            small
            active={props.selectedOption === key}
            className={`time-period-button mr-1 text-center rounded d-none d-${DURATION_SHOW_SCREEN_SIZE[key] || "xl"}-inline`}
            onClick={(e) => {
                props.durationSelectionHandler(TIME_OPTION_VALUES[key]);
                e.stopPropagation();
            }}
            text={STRINGS.TIME_RANGE_SELECTOR[key].shortText}
        />
    ));

    return (
        <div className="time-period-selector">
            <div>
                <span aria-label="timePeriodViewLast-label" className="d-none d-xl-inline">
                    {STRINGS.TIME_RANGE_SELECTOR.view_last}:
                </span>
                <ButtonGroup>{renderButtons}</ButtonGroup>
            </div>
        </div>
    );
}
