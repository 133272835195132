/** This module defines a React component that displays a set of links in a search result.
 *  @module
 */
import React from "react";
import { useHistory } from "react-router";
import { getURL } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import { getViewSiteInMapURL, SiteOnMap } from "reporting-infrastructure/utils/commonUtils";
import { IconNames } from "@blueprintjs/icons";
import { Icon, IconName, PopoverPosition } from "@blueprintjs/core";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { STRINGS } from "app-strings";

/** an enum with the valid link types. */
export enum LinkType {
    /** link to a page within the react app. */
    PAGE        = "PAGE",
    /** link to a location outside the react app. */
    LOCATION    = "LOCATION"
}

/** this interface defines all the information necessary to define a link. */
export interface LinkInfo {
    /** a string with the path for the link. */
    path: string | SiteOnMap;
    /** a String with the label that is to be displayed for the link. */
    label: string;
    /** the LinkType that defines what type of link this is and tells you how to interpret the path. */
    type: LinkType;
    /** any params passed into the link. */
    params?: { [x: string]: any }
}

/** the properties passed into the React component. */
export interface LinksGroupProps {
    /** an array with the links that are to be displayed in the search result. */
    links: Array<LinkInfo>
}

/** Renders a set of links in a search result.
 *  @param data the properties passed in.
 *  @returns JSX with the search result links react component.*/
export function LinksGroup({ links }: LinksGroupProps) {
    const noop = "#"
    const history = useHistory();
    const hostName = window.location.origin;
    const linksElements = links.map((link, index) => {
        let hrefString = noop;
        const params = link.params ? link.params : {};
        const style = index === 0 ? "" : "ml-4";
        if (link.type === LinkType.PAGE) {
            const pathString = getURL(getURLPath(link.path), params, { replaceQueryParams: true });
            hrefString = `${hostName}${pathString}`;
            return <div className={style} key={index}>
                <a href={hrefString}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        history.push(pathString, params);
                    }}>
                    {link.label}
                </a>
                <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0"} content={STRINGS.SEARCH.linkTooltip} position={PopoverPosition.RIGHT} transitionDuration={50}>
                    <Icon icon={IconNames.SHARE as IconName} size={14} className="ml-2" style={{color: "#106ba3", cursor: "pointer", verticalAlign: "baseline"}} 
                        onClick={() => {
                            window.open(pathString, "_blank");
                        }} 
                    />
                </Tooltip2>
            </div>
        } else if (link.type === LinkType.LOCATION) {
            const pathString = getViewSiteInMapURL(link.path as SiteOnMap);
            hrefString = `${hostName}${pathString}`;
            return <div className={style} key={index} onClick={(e) => {
                if (pathString) {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push(pathString);
                }
            }}>
                <a href={hrefString} onClick={(e) => { e.preventDefault() }}> {link.label}</a>
            </div>
        } else {
            console.warn(`Unknown link Type found ${link.type}`);
            return undefined;
        }
    }).filter((item)=>item!==undefined);
    return <div className="d-flex mt-4 justify-content-start">
        {linksElements}
    </div>
}
