import { STRINGS } from "app-strings";

/** an enum which defines all the valid platform types. */
export enum PROVIDER_TYPES {
    AWS = "AWS",
    AZURE = "AZURE",
    ONPREM = "ONPREM",
};

export enum CLOUD_PROVIDER_TYPE {
    AWS = "aws",
    AZURE = "azure",
}

/** a constant which maps the platform type enum value to a label. */
export const PROVIDER_TYPES_TO_LABEL_MAP: Record<string, string> = {
    [PROVIDER_TYPES.AWS]: STRINGS.cloudim.geoMap.provider.aws,
    [PROVIDER_TYPES.AZURE]: STRINGS.cloudim.geoMap.provider.azure,
    [PROVIDER_TYPES.ONPREM]: STRINGS.cloudim.geoMap.provider.onprem,
}

export const CLOUD_PROVIDER_TYPE_TO_LABEL: Record<string, string> = {
    [CLOUD_PROVIDER_TYPE.AWS]: STRINGS.cloudim.geoMap.provider.aws,
    [CLOUD_PROVIDER_TYPE.AZURE]: STRINGS.cloudim.geoMap.provider.azure,
}