import React from 'react';
import { Form as FormikForm, Formik, FormikProps, FormikValues } from 'formik'
import classNames from 'classnames';
import * as yup from 'yup';
import { LoadingOverlay, SuccessToaster } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';

import './Form.scss'

export type TIRUIFormType = {
    name?: string,
    onSuccessMessage?: string,
    onSubmit?: Function,
    onReset?: Function,
    className?: string,
    initialValues: object,
    loading: boolean,
    validationSchema?: yup.ObjectSchema
    children: React.ReactNode | Function
};

const renderChildren = (children: React.ReactNode | Function, formik: FormikProps<FormikValues>) => {
    if(typeof children === 'function') {
        return children(formik);
    } else {
        return children;
    }
}

export const Form = (props: TIRUIFormType) => {
    const handleSubmit = (values, formikOptions) => {
        if(props.onSubmit) {
            props.onSubmit(values, formikOptions).then(
                () => {
                    formikOptions.setSubmitting(false);
                    (props.onSuccessMessage) ? SuccessToaster({message:props.onSuccessMessage}) : SuccessToaster({message: STRINGS.successfulSubmit});
                },
                () => {
                    formikOptions.setSubmitting(false);
                }
            )
        }
    }
    const handleReset = () => {
        if(props.onReset) {
            props.onReset()
        }
    }
    const classes = classNames(
        'tir-ui-form',
        props.className
    )

    return (
        <Formik
            enableReinitialize={true}
            initialValues={ props.initialValues }
            onSubmit={ handleSubmit }
            onReset={ handleReset }
            loading={ props.loading }
            validationSchema={ props.validationSchema }
        >
            { (formik) => (
                <React.Fragment>
                    <LoadingOverlay visible={formik.isSubmitting || props.loading} />
                    <FormikForm className={ classes }>
                        { renderChildren(props.children, formik) }
                    </FormikForm>
                </React.Fragment>
            ) }
        </Formik>
    )
}
