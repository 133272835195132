/** This module contains the component for rendering the applications card in the impact summary.
 *  The applications card displays the count and list of impacted applications.
 *  @module
 */
import React from 'react';
import { SIZE } from 'components/enums';
import { IconNames } from '@tir-ui/react-components';
import { SummaryCard, SummaryCardProps } from 'components/common/layout/summary-card/SummaryCard';
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { STRINGS } from 'app-strings';

/** the properties passed into the React component. */
interface ServicesCardProps extends SummaryCardProps {
    /** a number with the count of applications. */
	count?: number;
    /** the string array with the list of applications. */
	data?: string[];
    /** the handler to use for details requests. */
    onDetails?: (icon: string, title: string, subTitle: string, data: string[]) => void;
    /** a boolean value, if true show the footer, if false do not, the default is true. */
    showFooter?: boolean;
}

/** Renders the applications card in the impact summary.
 *  @param props the properties passed in.
 *  @returns JSX with the applications card component.*/
const ApplicationsCard = ({count = 0, data = [], showFooter = true, ...props}: ServicesCardProps): JSX.Element => {
	const apps = [...data].sort();

	const handleOnClick = () => {
        if (props.onDetails) {
            props.onDetails(IconNames.APPLICATIONS, STRINGS.incidents.impactSummaryView.applications, STRINGS.incidents.impactSummaryView.impactedApplications, data);
        }
    };

	return (
		<SummaryCard
			title={
				<span className="text-black">
					{STRINGS.incidents.cards.applications.title}
				</span>
			}
			icon={IconNames.APPLICATIONS}
			size="flex"
			height={SIZE.m}
			className="w-min-3-force"
            hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            hideBorder={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
			footerContent={<>
				{false && <span className="opacity-7 display-10">{STRINGS.showingMockData}</span>}
				{(showFooter && apps.length > 0) && <div className="display-9 float-right text-primary clickable" onClick={handleOnClick}>{STRINGS.viewAll}</div> }
			</>}
		>
			<div className="overflow-hidden h-100 justify-content-center ">
				<div className="d-inline-block display-6 font-weight-900 text-black ">
					{/* {count} Using length as of now because query data is mocked and so there can be a mismatch of count and list of apps */}
					{apps.length}
				</div>
				<div className="display-9 pl-2 pt-2 text-truncate">{apps.join(", ")}</div>
			</div>
		</SummaryCard>
	);
};

export { ApplicationsCard };
