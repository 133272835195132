import React from 'react'
import { Icon, IconName, IconNames } from '@tir-ui/react-components'
import { ApolloError } from '@apollo/client/errors'
import { Intent } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';

export interface DataTileProps {
    data?: string | number,
    className?: string,
    title?: string,
    unit?: string,
    icon?: IconName,
    error?: ApolloError
}

export function DataTile(props: DataTileProps) {
    return (
        <>
            <div className={`data-tile d-flex flex-row justify-content-center ${props.className ? props.className : ''}`}>
                {props.icon && <Icon icon={props.icon} iconSize={25} className="p-2 d-flex align-items-center tile-icon" />}
                <div className="text-center d-flex flex-column justify-content-center">
                    <div className="d-flex flex-row align-items-baseline justify-content-center">
                        <h3 className="m-0 font-size-large font-size-md-x-large font-size-lg-xx-large" aria-label="data-point">{props.data}</h3>
                        {props.unit && <span className="font-size-x-small font-size-md-small font-weight-bold" aria-label="data-unit">{props.unit}</span>}
                    </div>
                    {props.title && <div id="datatile-title" className="font-size-x-small font-size-md-medium" aria-label='title'>{props.title}</div>}
                </div>
            </div>
            {!!props.error?.graphQLErrors?.length && <div className="font-size-x-small font-size-md-medium">
                <Icon icon={IconNames.ERROR} className="mr-2" intent={Intent.DANGER} />{STRINGS.ERRORS.contactSupport}<br />
                {props.error.graphQLErrors[0].extensions.errorId ? (<><span className="font-size-x-small">{STRINGS.ERRORS.LABELS.errorId} {props.error.graphQLErrors[0].extensions.errorId}</span><br /></>) : ''}
                {(props.error.graphQLErrors[0].extensions.sessionId || props.error.graphQLErrors[0].extensions.gql_session) ? (<><span className="font-size-x-small">{STRINGS.ERRORS.LABELS.sessionId} {props.error.graphQLErrors[0].extensions.sessionId || props.error.graphQLErrors[0].extensions.gql_session}</span></>) : ''}
            </div>
            }
        </>
    );
}
