/** This module contains constants and utilities that are used in the runbook output widgets
 *  @module
 */

import { STRINGS } from "app-strings";
import { Column, DataPoint } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";

/** returns a string with the timestamp.  Desso has used multiple timestamp formats during
 *      the Aternity demo so we need to convert between the formats.
 *  @param timestamp the timestamp returned by the data ocean.
 *  @returns the timestamp in milliseconds.*/
export function getTimestamp(timestamp: string): string {
    if (timestamp.includes(".") || timestamp.length < 13) {
        // We have either seconds or seconds.nanoseconds
        if (timestamp.includes(".")) {
            timestamp = timestamp.substring(0, timestamp.indexOf("."));
        }
        // Convert to milliseconds, we should pull in the nanoseconds here, but for the 
        // demo this is okay.
        timestamp = timestamp + "000";
    } else {
        // We have nanoseconds, strip off the extra digits and use milliseconds
        timestamp = timestamp.substring(0, timestamp.length - 6);
    }
    return timestamp;
}

/** formats the value based on its type.
 *  @param value the value returned by the back-end.  When coming from DAL this will be a string, 
 *      but when we do testing with node red this can be a number.
 *  @param columnDef the definition of the column.
 *  @returns the formated value for the column. */
export function formatValue(value: string | number | null, columnDef: Column): string | number | null {
    // This have value.length > 0
    if (value !== null && value !== undefined && (columnDef.type === "integer" || columnDef.type === "float")) {
        try {
            if (typeof value === "string") {
                // We have a string
                if (value === "") {
                    // Empty string will return 0
                    return null;
                }
                value = Number(value);
                if (Number.isNaN(value)) {
                    return null;
                }
                }
        } catch (error) {
            console.log("card data was not a number");
        }
    }
    return value;
}

/** calculates the percent change between two values.
 *  @param value the current value.
 *  @param compValue the comparison value.
 *  @returns the percent change or undefined if it cannot be calculated. */
export function calculatePercentChange(value: string | number, compValue: string | number): number | undefined {
    if (typeof value === "string") {
        if (value === "") {
            return undefined;
        }
        value = Number(value);
    }
    if (Number.isNaN(value) || typeof value !== "number") {
        return undefined;
    }

    if (typeof compValue === "string") {
        if (compValue === "") {
            return undefined;
        }
        compValue = Number(compValue);
    }
    if (Number.isNaN(compValue) || typeof value !== "number") {
        return undefined;
    }

    if (compValue !== 0) {
        return 100.0 * ((value - compValue) / compValue);
    } else if (value !== 0) {
        // We have number / 0 which is infinity
        return value > 0 ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    } else if (compValue === 0 && value === 0) {
        // We have 0 / 0, that is undefined, but that really is no change
        return 0;
    }

    return undefined;
}

/** checks to see if the keys of one DataPoint match the keys of another DataPoint.
 *  @param datapoint the first DataPoint to check.
 *  @param checkDatapoint the second DataPoint to check.
 *  @returns a boolean value, true if the keys for the two datapoints match, false otherwise. */
export function keysMatch(datapoint: DataPoint, checkDatapoint: DataPoint): boolean {
    if (datapoint?.keys && checkDatapoint?.keys) {
        // We are checking all keys, right now, in the future we might want to only check primary keys
        let keysMatch: boolean = true;
        let hasAtLeastOneKeyMatch: boolean = false;
        for (const primaryKey in datapoint.keys) {
            const keyValue = datapoint.keys[primaryKey];
            const checkKeyValue = checkDatapoint.keys[primaryKey];
            if ((keyValue === null || keyValue === undefined) && (checkKeyValue === null || checkKeyValue === undefined)) {
                // Do not check null keys
                continue;
            }
            if (!checkKeyValue || (typeof checkKeyValue !== "object" && keyValue !== checkKeyValue)) {
                keysMatch = false;
                break;
            }
            hasAtLeastOneKeyMatch = true;
        }
        if ((keysMatch && hasAtLeastOneKeyMatch) || (Object.keys(datapoint.keys).length === 0 && Object.keys(checkDatapoint.keys).length === 0)) {
            return true;
        }
    }
    return false;
}

/** returns the comparison offset and comparison suffix as an object. 
 *  @param comparedTo the comparedTo parameter in the data ocean node properties.
 *  @returns the comparison offset and comparison suffix as an object. */
export function getComparisonParameters(comparedTo: string | undefined): {comparisonOffset: number, comparisonSuffix: string} {
    if (comparedTo) {
        switch (comparedTo) {
            case "yesterday":
                return {
                    comparisonOffset: 24 * 60 * 60,
                    comparisonSuffix: STRINGS.runbookEditor.nodeLibrary.propertyLabels.comparisonOptionYesterday
                };
            case "last_week":
                return {
                    comparisonOffset: 7 * 24 * 60 * 60,
                    comparisonSuffix: STRINGS.runbookEditor.nodeLibrary.propertyLabels.comparisonOptionLastWeek
                };
            case "4_weeks_ago":
                return {
                    comparisonOffset: 4 * 7 * 24 * 60 * 60,
                    comparisonSuffix: STRINGS.runbookEditor.nodeLibrary.propertyLabels.comparisonOptionLastMonth    
                };
        }
    }
    return {comparisonOffset: 0, comparisonSuffix: ""};
}
