import { SEVERITY, SEVERITY_INDEX } from 'components/enums';
import React from 'react';
import { Feature, Position } from 'geojson';

export interface linkSupportedOptions {
    severity?: SEVERITY
    selected?: boolean
    hovered?: boolean
    width?: number
    renderCount?: number
}
export interface linkProps {
    id: string
    coordinates: Position[]
    properties?: linkSupportedOptions
}
// This component acts as a carrier for mapbox format feature's config. Rendering of
// this item in UI will be performed by mapbox API. Each feature will be converted to
// an appropriate UI item by mapbox on canvas using the paint config that's passed by
// the wrapping layer.
function MapFeature (props: Feature) {
    return <></>;
}
function Link (props: linkProps) {
    const linkProperties = {
        id: props.id,
        severity: props.properties?.severity || SEVERITY.UNKNOWN,
        severityIndex: SEVERITY_INDEX[props.properties?.severity || SEVERITY.UNKNOWN],
        selected: props.properties?.selected || false,
        hovered: props.properties?.hovered || false,
        width: props.properties?.width
    };
    return <MapFeature
        type="Feature"
        id={props.id}
        properties={linkProperties}
        geometry={{
            type: "LineString",
            coordinates: props.coordinates
        }}
    />;
}
export { Link };