/** This file defines the prediction summary react component.  The prediction summary view React component 
 *  displays a prototype component for the EmpoweredX demo that displays the results of predictive AI.
 *  @module */
import React, { useState, useLayoutEffect } from "react";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { IconNames } from "@tir-ui/react-components";
import { STRINGS, HELP } from "app-strings";
import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard";
import { SIZE } from "components/enums";
import AutoUpdateControl, { AutoUpdateState, TimeType } from "components/common/auto-update/AutoUpdateControl";
import { FILTER_NAME } from "utils/hooks";
import { getURL } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import { useHistory } from "react-router";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { CapacityPlanningTableView } from "./views/capacity-planning-table/CapacityPlanningTableView";
import { PerformanceTableView } from "./views/performance-table/PerformanceTableView";

const AuthService  = AuthServiceProvider.getService();

const initAutoUpdate: AutoUpdateState = {
    enabled: true,
    interval: 1,
    lastUpdate: 60* Math.floor(new Date().getTime() / (60 * 1000)),
    sequenceNumber: 0
};
export const AutoUpdateContext = React.createContext(initAutoUpdate);

/** Renders the prediction summary dashboard page.
 *  @param props the properties passed in.
 *  @returns JSX with the prediction summary page component.*/
export default function PredictionSummaryPage(props): JSX.Element {
    const history = useHistory();
    const [paywall, setPaywall] = useState<string>('ACTIVE');
    const [autoUpdate, setAutoUpdate] = useState<any>(initAutoUpdate);

    useLayoutEffect(() => {
        if (paywall && autoUpdate) {
            setPaywall(AuthService.getSubscription() === false ? 'ACTIVE': 'INACTIVE');
            if (AuthService.getSubscription()) {
                const newAutoUpdate = autoUpdate;
                newAutoUpdate.enabled = false;
                setAutoUpdate(newAutoUpdate);
            }
        }
    }, [paywall, autoUpdate]);

    const handleIncidentSelection = (id: string | undefined | null) => {
        history.push(getURL(getURLPath("incident"), {
            f: {
                [FILTER_NAME.incidentId]: id
            }
        }, { replaceQueryParams: true }))
    };

    return (<>
        <PageWithHeader
            name="PredictionSummaryPage"
            showGlobalFilters={false}
            title={STRINGS.predictionSummaryDashboard.title}
            icon={IconNames.GLOBE_NETWORK} showTimeBar={false}
            leftAlignedControls={undefined}
            rightAlignedControls={<AutoUpdateControl autoUpdate={autoUpdate} timeType={TimeType.DURATION}
                showPlayPauseButton={true} onAutoUpdateChanged={(autoUpdate) => {
                    setAutoUpdate(autoUpdate);
                }}
                showRefreshButton={!autoUpdate.enabled}
                className="d-none d-md-block"
            />}
            helpInfo={HELP.networkSummary}
        >
            <AutoUpdateContext.Provider value={autoUpdate}>
                <CardsHolder className="mx-3 mt-3">
                    <SummaryCard size="flex" height={SIZE.l} title={STRINGS.predictionSummaryDashboard.capacityPlanningTable.title} titlePadding={false} isSummary
                        className="w-min-4-force capacity-planning-list-card" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                        <CapacityPlanningTableView onClickEvent={(id) => {handleIncidentSelection(id)}} />
                    </SummaryCard>
                </CardsHolder>
                <CardsHolder className="mx-3 mt-3">
                    <SummaryCard size="flex" height={SIZE.l} title={STRINGS.predictionSummaryDashboard.performanceTable.title} titlePadding={false} isSummary
                        className="w-min-4-force performance-list-card" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                        <PerformanceTableView onClickEvent={(id) => {handleIncidentSelection(id)}} />
                    </SummaryCard>
                </CardsHolder>
            </AutoUpdateContext.Provider>
        </PageWithHeader>
    </>);
};
