/** This module contains a component that represents a condition key.  A condition is 
 *  an expression like "key operator value", so this component represents the key in 
 *  that expression.
 *  @module
 */
import React, { useEffect, useMemo } from "react";
import {Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Icon } from "@tir-ui/react-components";
import { getDisplayValueForItem, getMatchingItemForValue, getIconForItem, getValueForItem, keyItem, CONDITION_TREE_ICONS } from "../ConditionUtils";
import { isEqual, groupBy, sortBy } from 'lodash';
import { WrapFieldInDisplayMode } from "../../WrapFieldInDisplayMode";

/** the type definition for condition key on change handlers. */
type onChangeHandler = (newConditionKey: keyItem) => void;

/** this interface defines the properties passed into the ConditionKey React component. */
export interface ConditionKeyProps {
    /** a string with the classes that should be applied to this component. */
    className?: string;
    /** Pass as true to make this field non-editable */
    readOnly?: boolean;
    /** This prop can be used to provide an optional category that will be displayed in the left half of the pillbox */
    category?: string | React.ReactNode;
    /** Actual value. This can either be the full keyItem or just the value string */
    value?: keyItem;
    /** List of options to display in dropdown. If this is not provided, a simple textbox will be used */
    options?: Array<keyItem>;
    /** onChange callback when user changes the condition key either in the dropdown or the textbox as applicable */
    onChange?: onChangeHandler;
    /** A custom formatter that can be used for controlling how the category content will be rendered */
    categoryFormatter?: (category: string | React.ReactNode) => string | React.ReactNode;
    /** A custom formatter that can be used for controlling how the displayed category key will be rendered */
    keyDisplayFormatter?: (props: { value?: keyItem }) => string | React.ReactNode;
    /** A custom renderer that can be used to completely replace the key's dropdown/textbox control with whatever you'd like */
    customRenderer?: (props: { value?: keyItem, options?: Array<keyItem>, onChange?: onChangeHandler }) => string |React.ReactNode;
}

/** Renders the condition key.  A condition is an expression like "key operator value", so this component represents 
 *      the key in that expression.
 *  @param props the properties object passed in.
 *  @returns the JSX with the condition key React component. */
export function ConditionKey ({
    className,
    readOnly = false,
    category,
    value,
    options = [],
    onChange,
    categoryFormatter,
    keyDisplayFormatter,
    customRenderer,
}: ConditionKeyProps) {
    // If value and options were provided and no custom renderer was provided, find the matching selected option
    const selectedOption = useMemo(() => {
        if (!customRenderer && value && options.length > 0) {
            return getMatchingItemForValue(options, value);
        }
    }, [options, value, customRenderer]);

    // When mounting this component, if a value was provided but provided value was a simple string value which
    // matched a complex option object, then fire an onChange with the complex object. There could be logic
    // that's based on the complex object in one of it's parent components.
    useEffect(() => {
        if (value && selectedOption && !isEqual(value, selectedOption) && onChange) {
            onChange(selectedOption);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let keyPickerJSX;
    if (customRenderer) {
        keyPickerJSX = customRenderer({ value, options, onChange });
    } else {
        const hasOptions = options?.length > 0;
        const displayValue = keyDisplayFormatter ? keyDisplayFormatter({ value: selectedOption || value}) :
            (hasOptions ? (selectedOption ? getDisplayValueForItem(selectedOption) : "") : (value && getDisplayValueForItem(value)));
        const displayIcon = selectedOption ? getIconForItem(selectedOption) : (value && getIconForItem(value));
        const displayContent = <div className="display-holder">
            {displayIcon && <Icon icon={displayIcon} iconSize={11} className="icon"/>}
            {displayValue}
        </div>;
        const canBeGrouped = hasOptions && typeof options[0] !== 'string' && options[0]?.display;

        let sortedOptions: Array<any> = [];

        // Group Items only if required
        if (canBeGrouped) {
            const groupedOptions = groupBy(options, ((el) => {
                if (!el?.display) {
                    return el;
                }
        
                return el.display.includes('builtin') ? 'builtIn' : 'custom';
            }));
        
            sortedOptions = [...sortBy(groupedOptions.custom, (el) => el.display.toLowerCase()), ...sortBy(groupedOptions.builtIn, (el) => el.display.toLowerCase())];
        }
        else {
            sortedOptions = options.sort();
        }   

        if (readOnly) {
            keyPickerJSX = displayContent;
        } else if (hasOptions) {
            keyPickerJSX = <Popover2
                lazy
                defaultIsOpen={!displayValue}
                autoFocus={false}
                enforceFocus={false}
                content={<Menu className="h-max-4 overflow-auto">{
                    sortedOptions.map(item => {
                        const iconName = getIconForItem(item);
                        return <MenuItem
                            key={"option-" + getValueForItem(item)}
                            text={getDisplayValueForItem(item)}
                            className={typeof item === "string" ? undefined: item.className}
                            disabled={typeof item === "string" ? undefined: Boolean(item.disabled)}
                            active={selectedOption === item}
                            icon={iconName ? <Icon icon={iconName}/> : undefined}
                            onClick={() => {
                                if (onChange) {
                                    onChange(item);
                                } else {
                                    console.warn("No onChange handler provided for ConditionKey control!. This control will always work in a controlled state and so value and onChange params should be provided to make it usable.")
                                }
                            }}
                        />;
                    })
                }</Menu>}
            >
                <span className="display-holder">
                    {displayContent}
                    {hasOptions && <Icon icon={CONDITION_TREE_ICONS.DROPDOWN} iconSize={10} className="dropdown-icon"/>}
                </span>
            </Popover2>;
        } else {
            keyPickerJSX = <WrapFieldInDisplayMode
                // initEditMode={!value}
                value={value}
                onChange={onChange}
                displayValueFormatter={keyDisplayFormatter ? ({ value }) => keyDisplayFormatter({ value }) : undefined}
            />
        }
    }
    return <div className={"condition-key" + (className ? " " + className : "")}>
        { category && <div className="category add-padding">{categoryFormatter ? categoryFormatter(category) : category}</div> }
        { keyPickerJSX && <div className="key add-padding">{keyPickerJSX}</div> }
    </div>
}
