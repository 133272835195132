import LocalizedStrings from 'react-localization';
import { en } from './en';
//import it from './it'; // add more language is we ever translate things
import { stringsJSON, helpJSON, helpEmbeddedJSON } from '@rvbd/npm-hyperion-docs';
import merge from 'lodash/merge';
import { IS_EMBEDDED } from 'components/enums/QueryParams';

const STRINGS = new LocalizedStrings({
    en: merge(en, stringsJSON.en),
    //it: it
});

const HELP = new LocalizedStrings({
    en: IS_EMBEDDED ? helpEmbeddedJSON : helpJSON,
});

// Type to be used for auto-completing strings in translations
type LangEN = Required<Partial<typeof en>>;

export type {LangEN};
export {STRINGS, HELP};
