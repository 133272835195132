import * as yup from 'yup';
import ipRegex from 'ip-regex';
import cidrRegex from 'cidr-regex';
import { STRINGS } from "app-strings";

export const validIP = (value: string): boolean => {
    return ipRegex({ exact: true }).test(value);
};

export const validCIDR = (value: string): boolean => {
    return cidrRegex({ exact: true }).test(value);
};

export const validIPorCIDR = (value: string): boolean => {
    return validIP(value) || validCIDR(value);
};

export const validDomain = (value: string): boolean => {
    const isValidDomain = require('is-valid-domain');
    return isValidDomain(value);
};

export const validateDomain = (
    label: string,
    errorMessage: string
): yup.StringSchema => {
    const message = `${errorMessage} ${label}`;
    return yup.string().test('validDomain', message, validDomain).required();
};

export const validateEmail = (label: string): yup.StringSchema => {
    return yup.string().email().label(label).required();
};

export const validateStringNoSpaces = (
    label: string,
    errorMessage: string
): yup.StringSchema => {
    return yup.string().matches(/^\S*$/, errorMessage).label(label).required();
};

export const validateIP = (
    label: string,
    errorMessage: string
): yup.StringSchema => {
    const message = `${errorMessage} ${label}`;
    return yup.string().test('validIP', message, validIP).required();
};

export const validateCIDR = (
    label: string,
    errorMessage: string
): yup.StringSchema => {
    const message = `${errorMessage} ${label}`;
    return yup.string().test('validCIDR', message, validCIDR).required();
};

export const validateIPorCIDR = (
    label: string,
    errorMessage: string
): yup.StringSchema => {
    const message = `${errorMessage} ${label}`;
    return yup
        .string()
        .test('validIPorCidr', message, validIPorCIDR)
        .required();
};

export const validateRiverbedEmailField = (values) => {
    let errors = {};
    //custom check for riverbed emails not working in alias form
    if (values['user_email'].match(/.+\..+@riverbed.com$/) !== null) {
        errors['user_email'] = STRINGS.SIGNUP.EMAIL.riverbedError
    }

    return errors;
};

export const validateJSON = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}