/** This module contains the SearchApiService that can be used to query the cognitive search
 *      service.
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';
import { SearchItem, SearchRequest, SearchResult } from './SearchApiService';

// The URL for the API server.
export const SEARCH_URL = '/api/affogato/correlation/2.0/';

/** this class defines the SearchApiService. */
class SearchCorrelationApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        const baseUri = SEARCH_URL;
        super(baseUri)
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/correlation/2.0/`;    
        } else {
            return this.baseApiUri;
        }
    }

    /** runs a search query using a post with the search request.
     *  @param searchRequest the object with the search request.
     *  @returns a Promise which resolves to the returned SearchResult. */
    search(searchRequest: SearchRequest): Promise<SearchResult<SearchItem>> {
        return new Promise((resolve, reject) => {
            const request: SearchRequest = Object.assign({}, searchRequest);

            // We have an array of fields so create the selected statement for the user.
            if (Array.isArray(request.select)) {
                request.select = request.select.join(", ");
            }

            // We have an array of fields so create the searchFields statement for the user.
            if (Array.isArray(request?.searchFields)) {
                request.searchFields = request.searchFields.join(", ");
            }

            // We have an order by object so create the order by statement for the user.
            if (typeof request?.orderby === "object") {
                request.orderby = `${request.orderby.field} ${request.orderby.order}`;
                //alert(request.orderby);
            }

            // We have a field so create the field statement for the user.
            if (Array.isArray(request.filter)) {
                request.filter = "search.in(type, '" + request.filter.join(",") + "', ',')";
                //alert(request.filter);
            }

            const newRequest = {
                type: searchRequest.type,
                top: request.top,
                skip: request.skip,
                count: request.count,
                searchFields: searchRequest.searchFields,
                search: request.search,
                filter:  request.filter,
                orderby: request.orderby,
                facets: request.facets
            };

            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.post<SearchResult<SearchItem>>(`tenants/${tenantId}/search`, newRequest).then((response: SearchResult<SearchItem>) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }
}

const SearchCorrelationService = new SearchCorrelationApiService();
export { SearchCorrelationService };
