/** This module creates a new node for the react-flow graph that represents the input, output and default node.
 *  @module
 */
import React, { useRef } from 'react';
import { Handle, Position, NodeProps, Connection, Edge } from 'react-flow-renderer';
import { BaseNodeContent } from '../BaseNodeContent';
import './DefaultNode.css';

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params);

/** Renders the default node component.
 *  @param props the properties passed in.
 *  @returns JSX with the default node component.*/
export default function DefaultNode ({
    // Position of target handles (i.e. Inputs)
    targetPosition = Position.Left,
    // Position of source handles (i.e. Outputs)
    sourcePosition = Position.Right,
    ...props
}: NodeProps): JSX.Element {
    const isConnectable = props.isConnectable;

    // Create the input ports
    const inputPorts: Array<JSX.Element> = [];
    if (props.type === "output" || props.type === "default") {
        // Only one for now
        inputPorts.push(
            <Handle
                type="target"
                key={"input-handle-node-" + props.id}
                position={targetPosition}
                onConnect={onConnect}
            />
        );
    }

    // Create the output ports
    const className = "source-plus-handle";
    const outputPorts: Array<JSX.Element> = [];
    if (props.type === "input" || props.type === "default") {
        outputPorts.push(
            <Handle
                type="source"
                key={"output-handle-node-" + props.id}
                position={sourcePosition}
                isConnectable={isConnectable}
                className={className} 
                onMouseUp={() => nodeRef.current.showMenu(0)}
            />
        );
    }

    const nodeRef = useRef<any>();

    return (<>
        <BaseNodeContent ref={nodeRef} sourcePosition={sourcePosition} targetPosition={targetPosition} {...props}
            numOutputHandles={outputPorts.length}
            inputHandles={inputPorts} 
            outputHandles={outputPorts}
        />
    </>);
};
