/** This module contains the implementation for the on demand runbooks blade.  The on demand runbook
 *      blade displays the runbook output in a blade for the on demand runbooks faceted search page.
 *  @module
 */
import React, { useState } from 'react'
import { STRINGS } from 'app-strings';
import { IconTitle } from 'components/common/icon-title/IconTitle';
import { ViewCollection } from 'components/common/layout/view-collection/ViewCollection';
import { SIZE } from 'components/enums';
import { SDWAN_ICONS } from 'components/sdwan/enums';
import { RunbookOutput } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { RunbookView } from 'pages/riverbed-advisor/views/runbook-view/RunbookView';
import { showTitleIcons } from 'utils/runbooks/RunbookUtils';
import { EntryType, PriorityReasonsView } from 'pages/incident-details/views/priority-reason/PriorityReasonsView';

/** the properties passed into the React component. */
interface OnDemandRunbooksBladeProps {
    /** a boolean value, true if the data is loading, false otherwise. */
    loading: boolean;
    /** the data returned by the query. */
    data: any;
    /** any errors returned by the query. */
    error: any;
    /** the handler for the blade closed event. */
    onBladeClosed?: () => void;
}

/** Creates the on demand runbook blade, which is a component that displays the runbook output 
 *      in the blade in the on demand runbooks faceted search.
 *  @param props an object with the properties passed to the on demand runbook blade.
 *  @returns JSX with the on demand runbook blade component.*/
const OnDemandRunbooksBlade = (props: OnDemandRunbooksBladeProps) => {
    const runbookId = props.data?.runbookId;
    const [runbookData, setRunbookData] = useState<any | undefined>(undefined);
    const [runbookOutput, setRunbookOutput] = useState<RunbookOutput | undefined>(runbookData?.runbookOutput);

    return (
        <div className='w-full'>
            <div className="mb-4">
                <div className="d-flex flex-wrap mt-2" >
                    {/* <IncidentImpactSummaryView key={"incident-impact-" + id} incidentId={id} showTitleIcons={showTitleIcons} showFooter={false} /> */}
                    <PriorityReasonsView 
                        type={EntryType.INSIGHT}
                        showTitleIcons={showTitleIcons} showFooter={false} runbookOutput={runbookOutput}
                    />
                </div>
                <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined}
                    title={STRINGS.incidents.runbookTitle} size={SIZE.m} className="mb-2 font-weight-500"
                />
                <ViewCollection activeView="runbook">
                    <div key="runbook" className="runbook-output-tab">
                        <RunbookView
                            runbookId={runbookId}
                            onRunbookDataReceived={(data) => {
                                if (!runbookData) {
                                    setRunbookData(data);
                                }
                            }}
                            onRunbookReceived={(runbook) => {
                                if (!runbookOutput) {
                                    setRunbookOutput(runbook);
                                }
                            }}
                        />
                    </div>
                </ViewCollection>
            </div>
        </div>
    )
}

export default OnDemandRunbooksBlade
