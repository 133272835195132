/** This module contains a react component that displays the error and warning content for the runbook output
 *  error dialog.
 *  @module
 */
import React, { useCallback, useReducer } from "react";
import { Intent, Tree } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { PARAM_NAME } from "components/enums/QueryParams";
import { parseTimeFromDAL, useQueryParams } from "utils/hooks";
import { 
    Column, ErrorInfo, DataPoint, GraphqlData, GraphqlDataValue, RunbookOutput, VariableResults, 
    DataResult, StructuredVariableValue
} from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { clone } from "lodash";
import { processDataset } from "utils/runbooks/RunbookUtils";
//import DOMPurify from 'dompurify';
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { TIME_FORMAT } from "components/enums";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { addJsonArrayToTree, addJsonObjectToTree } from "../primary-indicator/JsonViewer";
import './ErrorDialogContent.css';

/** a boolean value, if true just take the raw JSON for a structured variable and display it as is, if false,
 *  output the formatted tree by descending the hierarchy and displaying the information. */
const showStructuredVariablesAsRawJson = true;

/** a boolean value, if true show the widget summary, if false do not. */
const showWidgetSummary: boolean = false;

/** a boolean value, if true show the widget errors in the tree. */
const showPerWidgetDetailsInTree: boolean = true;

/** if true purify the content and sanitize it, if false display errors as is. */
//const purifyContent = false;

/** a list of do attributes to show when the debug URL parameter is transmitted (&debug=true). */
const doDebugAttributes: Array<string> = ["errorCode", "SessionId", "ErrorId"];

/** if true always show the data ocean debug information, even if the URL does not have "debug=true". */
const alwaysShowDoDebugAttributes = true;

/** defines the NodePath type. */
type NodePath = number[];

/** defines the TreeAction type. */
type TreeAction = { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
                | { type: "DESELECT_ALL" };

/** executes the specified visit function for the specified set of nodes and their
 *      children.
 *  @param nodes the nodes for which the visit function is to be run.
 *  @param path the path.
 *  @param visitFn the visit function.*/
function forEachNode(nodes: any[] | undefined, visitFn: (node: any) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        visitFn(node);
        forEachNode(node.childNodes, visitFn);
    }
}

/** executes the specified callback for the tree node specified by the path.
 *  @param nodes the nodes in the tree.
 *  @param path the path.
 *  @param callback the callback function.*/
function forNodeAtPath(nodes: any[], path: NodePath, callback: (node: any) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

/** the reducer function.
 *  @param state the current state of the tree.
 *  @param action the current action.
 *  @returns the new state of the tree after taking the specified action.*/
function treeReducer(state: any[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL": {
            const newState = clone(state);
            forEachNode(newState, node => (node.isSelected = false));
            return newState;
        }
        case "SET_IS_EXPANDED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState;
        }
    }
}

/** This interface defines the properties passed into the error dialog content component.*/
export interface ErrorDialogContentProps {
    /** the data set that contains the DataSetInfo object that gives the following information: the actual times, data sources, filters, errors, etc. */
    runbook?: RunbookOutput;
}

/** Renders the error dialog content.
 *  @param props the properties passed in.
 *  @returns JSX with the error dialog content React component.*/
export const ErrorDialogContent = (props: ErrorDialogContentProps): JSX.Element => {
    const {runbook} = props;

    let { params } = useQueryParams({ listenOnlyTo: [ PARAM_NAME.debug ] });
    const showDebugInfo = params[PARAM_NAME.debug] === "true";
    if (showDebugInfo) {
        console.log("We will support debug mode in the future and show the stack traces");
    }

    // Setup the tree.
    const INITIAL_STATE: Array<any> = [];

    let widgetErrors = 0;
    let widgetWarnings = 0;
    if (runbook?.datasets || runbook?.errors?.length) {
        const datasets = runbook.datasets;
        const template = runbook.template ? JSON.parse(runbook.template) : undefined;
    
        let generalErrors: Array<ErrorInfo> = [];
        const generalWarnings: Array<ErrorInfo> = [];

        const widgetErrorsAndWarnings: Record<string, {warnings: Array<ErrorInfo>, errors: Array<ErrorInfo>}> = {};

        if (datasets?.length && template) {
            // Analyze the errors and warnings and categorize them as widget level errors or general errors
            for (const dataset of datasets) {
                const processedDataset = processDataset(dataset, template.nodes);
                if (processedDataset) {
                    widgetErrors += processedDataset?.info?.error ? 1 : 0;
                    widgetWarnings += processedDataset?.info?.warning ? 1 : 0;
                    if (processedDataset.widgets?.length && (processedDataset?.info?.error || processedDataset?.info?.warning)) {
                        for (const widget of processedDataset.widgets) {
                            let widgetName = widget?.name && widget.name.trim() !== "" ? widget.name : STRINGS.runbookOutput.errorDialog.unknownWidgetText;
                            let widgetErrors = widgetErrorsAndWarnings[widgetName];
                            if (!widgetErrors) {
                                widgetErrors = {warnings: [], errors: []};
                                widgetErrorsAndWarnings[widgetName] = widgetErrors;
                            }
                            if (processedDataset?.info?.error) {
                                widgetErrors.errors.push(processedDataset.info.error);
                            }
                            if (processedDataset?.info?.warning) {
                                widgetErrors.warnings.push(processedDataset.info.warning);
                            }
                        }
                    }
                } else {
                    // This dataset could not be processed, quite possibly because it is a dataset early
                    // in the branch with an error that is no associated with any widget.  Check for an 
                    // error and extract it so it can be displayed to the user.
                    if (dataset?.info?.error) {
                        generalErrors.push(dataset.info.error);
                    }
                    if (dataset?.info?.warning) {
                        generalWarnings.push(dataset.info.warning);
                    }
                }
            }
        }

        if (runbook?.errors?.length) {
            generalErrors = generalErrors.concat(runbook.errors);
        }

        if (generalErrors.length) {
            addGeneralErrorOrWarningNodes(generalErrors, INITIAL_STATE, true, showDebugInfo);
        }

        if (generalWarnings.length) {
            addGeneralErrorOrWarningNodes(generalWarnings, INITIAL_STATE, false, showDebugInfo);
        }

        if (showPerWidgetDetailsInTree) {
            const widgetNames = Object.keys(widgetErrorsAndWarnings);
            widgetNames.sort();
            const widgetsParentNode: any = {
                id: "ErrorsAndWarningsForWidgets", key: "ErrorsAndWarningsForWidgets", 
                depth: 0, path: [INITIAL_STATE.length], label: STRINGS.runbookOutput.errorDialog.widgetErrorsAndWarningsLabel, 
                isExpanded: true, childNodes: []
            }
            INITIAL_STATE.push(widgetsParentNode);
            let children: Array<any> = [];
            for (let index = 0; index < widgetNames.length; index++) {
                const widgetName = widgetNames[index];
                const widgetErrors = widgetErrorsAndWarnings[widgetName];
                const widgetNode = {
                    id: "ErrorsAndWarningsFor" + widgetName, key: "ErrorsAndWarningsFor" + widgetName, 
                    depth: 0, path: [INITIAL_STATE.length], label: widgetName, 
                    isExpanded: false, childNodes: []
                }
                addWidgetErrorsOrWarningsNodes(widgetErrors, widgetNode, widgetName, showDebugInfo);
                children.push(widgetNode);
            }
            widgetsParentNode.childNodes = children;
        }
    }

    if (
        runbook && runbook.variableResults && 
        (runbook.variableResults.primitiveVariables?.length || runbook.variableResults.structuredVariables?.length)
    ) {
        addVariableNodes(runbook, INITIAL_STATE);
    }

    if (runbook && runbook.triggerInputs) {
        addRunbookInputNodes(runbook, INITIAL_STATE);
    }

    if (runbook && runbook.indicators?.length) {
        addRunbookIndicatorNodes(runbook, INITIAL_STATE);
    }

    if (runbook && runbook.notes?.length) {
        addRunbookNoteNodes(runbook, INITIAL_STATE);
    }

    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    return <>
        <div style={{minHeight: "200px", maxHeight: "400px", overflowY: "auto"}}>
            <div className="error-dialog-content display-8">
                <Tree contents={nodes} onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} />
            </div>
        </div>
        {showWidgetSummary && (widgetErrors || widgetWarnings) && <span>
            {widgetErrors && widgetWarnings ? 
                STRINGS.formatString(STRINGS.runbookOutput.errorDialog.widgetErrorAndWarningText, widgetErrors, widgetWarnings) : 
                widgetErrors ? 
                    STRINGS.formatString(STRINGS.runbookOutput.errorDialog.widgetErrorText, widgetErrors) : 
                    STRINGS.formatString(STRINGS.runbookOutput.errorDialog.widgetWarningText, widgetWarnings) 
            }
        </span>}
    </>;
}

/** adds the runbook input into the error dialog.
 *  @param runbook the RunbookOutput object with the runbook contents.
 *  @param root the root of the tree displayed in the dialog. */
function addRunbookInputNodes(runbook: RunbookOutput, root:Array<any>): void {
    if (runbook.triggerInputs) {
        // Here we use the JsonViewer to format the tree
        const inputNode = {
            id: "RunbookInput", key: "RunbookInput", depth: 0, path: [root.length], 
            label: STRINGS.runbookOutput.errorDialog.inputsLabel, 
            isExpanded: true, childNodes: []
        };

        // We are going to modify the object so make a copy
        const runbookInput = JSON.parse(JSON.stringify(runbook.triggerInputs));

        // There are several objects that DAL treats as strings, expand the objects.
        if (runbookInput) {
            convertFromDalToObject(runbookInput);
        }
    
        root.push(inputNode);            
        addJsonObjectToTree(runbookInput as Record<string, any>, inputNode);
    }
}

/** adds the runbook indicators into the error dialog.
 *  @param runbook the RunbookOutput object with the runbook contents.
 *  @param root the root of the tree displayed in the dialog. */
function addRunbookIndicatorNodes(runbook: RunbookOutput, root:Array<any>): void {
    if (runbook.indicators?.length) {
        // Here we use the JsonViewer to format the tree
        const inputNode = {
            id: "RunbookIndicators", key: "RunbookIndicators", depth: 0, path: [root.length], 
            label: STRINGS.runbookOutput.errorDialog.indicatorsLabel, 
            isExpanded: true, childNodes: []
        };

        root.push(inputNode);            
        addJsonArrayToTree(runbook.indicators, inputNode);
    }
}

/** adds the runbook notes into the error dialog.
 *  @param runbook the RunbookOutput object with the runbook contents.
 *  @param root the root of the tree displayed in the dialog. */
function addRunbookNoteNodes(runbook: RunbookOutput, root:Array<any>): void {
    if (runbook.notes?.length) {
        // Here we use the JsonViewer to format the tree
        const inputNode = {
            id: "RunbookNotes", key: "RunbookNotes", depth: 0, path: [root.length], 
            label: STRINGS.runbookOutput.errorDialog.notesLabel, 
            isExpanded: true, childNodes: []
        };
    
        const notes = JSON.parse(JSON.stringify(runbook.notes));
        for (const note of notes) {
            delete note.__typename;
            delete note.id;
            note.timestamp = formatToLocalTimestamp(parseTimeFromDAL(note.timestamp), TIME_FORMAT.DISPLAY_TIME_FORMAT);
        }
    
        root.push(inputNode);            
        addJsonArrayToTree(notes, inputNode);
    }
}

/** converts the JSON returned by DAL to a format that is more consistent with the original object.
 *  @param json the JSON that is to be converted. */
function convertFromDalToObject(json: any): void {
    if (json) {
        if (typeof json === "object") {
            delete json.__typename;
            for (const key in json) {
                if (Array.isArray(json[key])) {
                    const newObj = {};
                    for (const fieldDef of json[key]) {
                        newObj[fieldDef.field] = fieldDef.values;
                    }
                    json[key] = newObj;
                } else if (key === "requestBody") {
                    // Request body is a stringified JSON object
                    try {
                        json[key] = JSON.parse(json[key]);
                    } catch (error) {
                        // do nothing if this occurs
                    }
                }
            }
        }
    }
}

/** adds the variable nodes to the dialog.
 *  @param runbook the RunbookOutput object with the runbook contents.
 *  @param root the root of the tree displayed in the dialog. */
function addVariableNodes(runbook: RunbookOutput, root: Array<any>): void {
    const scopes: string[] = ["runtime", "incident", "global"];
    for (const scope of scopes) {
        addVariableNodesForScope(scope, runbook, root);
    }
}

/** adds all the variable nodes for the specified scope.
 *  @param scope a String with the scope of the variable to be displayed: runtime, incident or global.
 *  @param runbook the RunbookOutput object with the runbook contents.
 *  @param root the root of the tree in the dialog. */
function addVariableNodesForScope(scope: string, runbook: RunbookOutput, root: Array<any>): void {
    const children: Array<any> = [];
    const variableMap = getVariableMap(scope, runbook);
    if (variableMap.startingVariables) {
        children.push(addStartingOrEndingNode(scope, variableMap.startingVariables, true, 0, [root.length], children.length, scope));
    }
    if (variableMap.endingVariables) {
        children.push(addStartingOrEndingNode(scope, variableMap.endingVariables, false, 0, [root.length], children.length, scope));
    }
    if (children.length) {
        root.push({
            id: scope + "Variables", key: scope + "Variables", depth: 0, path: [root.length], 
            label: STRINGS.runbookOutput.errorDialog.variables.scopes[scope].name, 
            isExpanded: true, childNodes: children
        });            
    }
}

/** returns a parent node for the starting or ending branch of the tree.
 *  @param scope a String with the scope of the variable to be displayed: runtime, incident or global.
 *  @param startingOrEndingVariables the Object with the data to display.
 *  @param starting a boolean value, true if we want to display the starting value, false otherwise.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a node with the parent node for the starting or ending branch of the tree. */
function addStartingOrEndingNode(
    scope: string, startingOrEndingVariables: any, starting: boolean, depth: number, parentPath: number[], 
    index: number, baseId: string
): any {
    const id = baseId + (starting ? "Entering" : "Leaving");
    const children: Array<any> = [];
    if (startingOrEndingVariables.custom) {
        children.push(addBuiltInOrCustomNode(scope, startingOrEndingVariables.custom, false, (depth + 1), [...parentPath, index], children.length, id));
    }
    if (startingOrEndingVariables.builtIn) {
        children.push(addBuiltInOrCustomNode(scope, startingOrEndingVariables.builtIn, true, (depth + 1), [...parentPath, index], children.length, id));
    }
    return {
        id: id, key: id, depth: (depth + 1), path: [...parentPath, index], 
        className: "info-warning-error-label", 
        label: STRINGS.runbookOutput.errorDialog.variables[starting ? "enteringLabel" : "leavingLabel"],
        childNodes: children
    };
}

/** returns a parent node for the built-in or custom branch of the tree.
 *  @param scope a String with the scope of the variable to be displayed: runtime, incident or global.
 *  @param builtInOrCustomVariables the Object with the data to display.
 *  @param builtIn a boolean value, true if we want to display the built-in value, false otherwise.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a node with the parent node for the built-in or custom branch of the tree. */
function addBuiltInOrCustomNode(
    scope: string, builtInOrCustomVariables: any, builtIn: boolean, depth: number, parentPath: number[], 
    index: number, baseId: string
): any {
    const id = baseId + (builtIn ? "BuiltIn" : "Custom");
    const children: Array<any> = [];
    for (let variableIndex = 0; variableIndex < builtInOrCustomVariables.length; variableIndex++) {
        if (builtInOrCustomVariables[variableIndex].type === "simple") {
            children.push(addPrimitiveVariableValue(builtInOrCustomVariables[variableIndex], (depth + 1), [...parentPath, index], children.length, id));
        } else {
            children.push(addStructuredVariableValue(builtInOrCustomVariables[variableIndex], (depth + 1), [...parentPath, index], children.length, id));
        }
    }
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index], 
        className: "info-warning-error-label", 
        label: STRINGS.runbookOutput.errorDialog.variables[builtIn ? "builtinLabel" : "customLabel"],
        childNodes: children
    };
}

/** returns a leaf node with the value of a primitive variable.
 *  @param variableValue the Object with the primitive variable value.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a leaf node with the value of a primitive variable. */
function addPrimitiveVariableValue(
    variableValue: any, depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + variableValue.name;
    const component = <div className="d-flex icon-and-label-div">
        <span >{variableValue.name + ": " + variableValue.value}</span>
    </div>;
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index],
        className: "info-warning-error-label", 
        label: component
    };
}

/** returns a set of nodes that display a structured variable.
 *  @param variableValue the Object with the structured variable value.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a set of nodes that display a structured variable. */
function addStructuredVariableValue(
    variableValue: {name: string, value: StructuredVariableValue}, depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + variableValue.name;
    if (!showStructuredVariablesAsRawJson) {
        // Here we format the tree ourselves.
        const children: Array<any> = [];
        if (variableValue.value.info.keys) {
            children.push(addKeyOrMetricNode(variableValue.value.info.keys, true, (depth + 1), [...parentPath, index], children.length, id));
        }
        if (variableValue.value.info.metrics) {
            children.push(addKeyOrMetricNode(variableValue.value.info.metrics, false, (depth + 1), [...parentPath, index], children.length, id));
        }
        if (variableValue.value) {
            children.push(addStructuredValuesNode(variableValue.value.data, variableValue.value.info.keys || [], variableValue.value.info.metrics || [], (depth + 1), [...parentPath, index], children.length, id));
        }
        return {
            id: id, key: id, depth: depth + 1, path: [...parentPath, index],
            className: "info-warning-error-label", 
            label: variableValue.name,
            childNodes: children
        };
    } else {
        // Here we use the JsonViewer to format the tree
        const varNameNode =  {
            id: id, key: id, depth: depth + 1, path: [...parentPath, index],
            className: "info-warning-error-label", 
            label: variableValue.name,
            childNodes: []
        };
        addJsonObjectToTree(variableValue.value as Record<string, any>, varNameNode);
        return varNameNode
    }
}

/** returns a parent node that displays the title Keys or Metrics and has the list of key or metric 
 *      defns as children.
 *  @param keysOrMetrics an array with the key or metric definitions.
 *  @param isKey a boolean value, true if we want to display a key, false if we want to display a metric.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a parent node that displays the title Keys or Metrics and has the list of key or metric 
 *      defns as children. */
function addKeyOrMetricNode(
    keysOrMetrics: Array<Column>, isKey: boolean, depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + (isKey ? "Key" : "Metric");
    const children: Array<any> = [];
    for (let keyOrMetricIndex = 0; keyOrMetricIndex < keysOrMetrics.length; keyOrMetricIndex++) {
        children.push(addKeyOrMetricDefn(keysOrMetrics[keyOrMetricIndex], (depth + 1), [...parentPath, index], children.length, id));
    }
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index],
        className: "info-warning-error-label", 
        label: isKey ? "Keys" : "Metrics",
        childNodes: children
    };
}

/** returns a parent node that displays the name of a key or metric definition and has its defn as children.
 *  @param keyOrMetricDefn an array with the key or metric definitions.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns returns a parent node that displays the name of a key or metric definition and has its defn as children. */
function addKeyOrMetricDefn(
    keyOrMetricDefn: Column, depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + keyOrMetricDefn.label;
    const children: Array<any> = [];
    if (keyOrMetricDefn.type) {
        children.push(
            {
                id: id + "Type", key: id + "Type", depth: depth + 2, path: [...parentPath, index, children.length],
                className: "info-warning-error-label", 
                label: "Type: " + keyOrMetricDefn.type
            }
        )
    }
    if (keyOrMetricDefn.unit && keyOrMetricDefn.unit !== "none" && keyOrMetricDefn.unit !== "") {
        children.push(
            {
                id: id + "Unit", key: id + "Unit", depth: depth + 2, path: [...parentPath, index, children.length],
                className: "info-warning-error-label", 
                label: "Unit: " + keyOrMetricDefn.unit
            }
        )
    }
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index],
        className: "info-warning-error-label", 
        label: keyOrMetricDefn.label,
        childNodes: children
    };
}

/** returns a parent node that displays the values string as the label and has the structured value as the children.
 *  @param values an array with the key or metric definitions.
 *  @param keyDefs an Array of Column objects with the key definitions.
 *  @param metricDefs an Array of Column objects with the metric definitions.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a parent node that displays the values string as the label and has the structured value as the children. */
function addStructuredValuesNode(
    values: DataResult[], keyDefs: Column[], metricDefs: Column[], 
    depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + "Value";
    const children: Array<any> = [];
    for (let valueIndex = 0; valueIndex < values.length; valueIndex++) {
        children.push(addStructuredValueNode(values[valueIndex], keyDefs, metricDefs, depth, parentPath, children.length, baseId));
    }
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index],
        className: "info-warning-error-label", 
        label: "Values",
        childNodes: children
    };
}

/** returns a parent node that displays the array index of the datapoint and the data as the children.
 *  @param value a DataPoint with either summary or ts data.
 *  @param keyDefs an Array of Column objects with the key definitions.
 *  @param metricDefs an Array of Column objects with the metric definitions.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a parent node that displays the array index of the datapoint and the data as the children. */
function addStructuredValueNode(
    value: DataPoint, keyDefs: Column[], metricDefs: Column[], 
    depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + index;
    const children: Array<any> = [];
    if (value.keys) {
        for (const keyValue of value.keys as Array<GraphqlDataValue>) {
            children.push(addStructuredKeyOrMetricValue(keyValue, keyDefs, depth, parentPath, children.length, baseId));
        }
    }
    if (value.data) {
        for (const datum of value.data as GraphqlData[]) {
            const timestamp = datum.timestamp;
            const metrics = datum.metrics;
            if (timestamp) {
                children.push(addStructuredTimeValue(datum, metricDefs, depth, parentPath, index, baseId));
            } else {
                if (metrics) {
                    for (const metricValue of datum.metrics) {
                        children.push(addStructuredKeyOrMetricValue(metricValue, metricDefs, depth, parentPath, index, baseId));
                    }
                }
            }
        }
    }
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index],
        className: "info-warning-error-label", 
        label: "[" + index + "]",
        childNodes: children
    };
}

/** returns a parent node that displays the timestamp as a parent and the metric values as the children.
 *  @param timeValue a GraphqlData object with the timestamp and metric values.
 *  @param defs an Array of Column objects with the metric definitions.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a parent node that displays the timestamp as a parent and the metric values as the children. */
function addStructuredTimeValue(
    timeValue: GraphqlData, defs: Column[], depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + timeValue.timestamp;
    const children: Array<any> = [];
    if (timeValue.metrics) {
        for (const metricValue of timeValue.metrics) {
            children.push(addStructuredKeyOrMetricValue(metricValue, defs, depth, parentPath, children.length, baseId));
        }
    }
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index],
        className: "info-warning-error-label", 
        label: formatToLocalTimestamp(parseTimeFromDAL(timeValue.timestamp), TIME_FORMAT.DISPLAY_TIME_FORMAT),
        childNodes: children
    };
}

/** returns a leaf node with the value of a structured variable key or metric.
 *  @param keyValue the GraphqlDataValue with the key or metric value.
 *  @param defs the array of Columns with the column definitions.
 *  @param depth the parent tree depth.
 *  @param parentPath the parent path.
 *  @param index the index of this child element.
 *  @param baseId a String with the parent id so we can create a unique child id.
 *  @returns a leaf node with the value of a structured variable key or metric. */
function addStructuredKeyOrMetricValue(
    keyValue: GraphqlDataValue, defs: Column[], depth: number, parentPath: number[], index: number, baseId: string
): any {
    const id = baseId + keyValue.id;
    const unit = getUnitFromDef(keyValue.id, defs);
    return {
        id: id, key: id, depth: depth + 1, path: [...parentPath, index],
        className: "info-warning-error-label", 
        label: keyValue.id + ": " + keyValue.value + (unit.length > 0 ? " (" + unit + ")" : ""),
    };
}

/** returns a string with the unit for the column definition.
 *  @param id a String with the column id whose unit is to be returned.
 *  @param defs an Array of Columns with all the column definitions.
 *  @returns a String with the unit for the specified column id. */
function getUnitFromDef(id: string, defs: Column[] | undefined): string {
    let unit = "";
    if (defs?.length) {
        for (const keyOrMetricDef of defs) {
            if (keyOrMetricDef.id === id) {
                unit = keyOrMetricDef.unit ? Unit.parseUnit(keyOrMetricDef.unit).getDisplayName() : "";
                break;
            }
        }
    }                
    return unit;
}

/** returns a map with all the variable definitions.
 *  @param scope a String with the scope of the variable to be displayed: runtime, incident or global.
 *  @param runbook the RunbookOutput object with the runbook contents.
 *  @returns a map with all the variable definitions. */
function getVariableMap(scope: string, runbook: RunbookOutput): any {
    const variableMap: any = {};
    for (const primitiveVariable of (runbook.variableResults as VariableResults).primitiveVariables!) {
        if (!primitiveVariable.name.includes(scope)) {
            continue;
        }
        if (primitiveVariable?.startValue?.length) {
            let startingVariables: any = variableMap.startingVariables;
            if (!startingVariables) {
                startingVariables = {};
                variableMap.startingVariables = startingVariables;
            }
            const builtIn = primitiveVariable.name.includes("builtin");
            let builtInOrCustomVariables: any = startingVariables[builtIn ? "builtIn" : "custom"];
            if (!builtInOrCustomVariables) {
                builtInOrCustomVariables = [];
                startingVariables[builtIn ? "builtIn" : "custom"] = builtInOrCustomVariables;
            }
            const unit = primitiveVariable.unit ? Unit.parseUnit(primitiveVariable.unit).getDisplayName() : "";
            builtInOrCustomVariables.push({type: "simple", name: primitiveVariable.name, value: primitiveVariable?.startValue + (unit.length > 0 ? " " + unit : "")})
        }
        if (primitiveVariable?.value?.length) {
            let endingVariables: any = variableMap.endingVariables;
            if (!endingVariables) {
                endingVariables = {};
                variableMap.endingVariables = endingVariables;
            }
            const builtIn = primitiveVariable.name.includes("builtin");
            let builtInOrCustomVariables: any = endingVariables[builtIn ? "builtIn" : "custom"];
            if (!builtInOrCustomVariables) {
                builtInOrCustomVariables = [];
                endingVariables[builtIn ? "builtIn" : "custom"] = builtInOrCustomVariables;
            }
            const unit = primitiveVariable.unit ? Unit.parseUnit(primitiveVariable.unit).getDisplayName() : "";
            builtInOrCustomVariables.push({type: "simple", name: primitiveVariable.name, value: primitiveVariable?.value + (unit.length > 0 ? " " + unit : "")})
        }
    }
    for (const structuredVariable of (runbook.variableResults as VariableResults).structuredVariables!) {
        if (!structuredVariable.name.includes(scope)) {
            continue;
        }
        let endingVariables: any = variableMap.endingVariables;
        if (!endingVariables) {
            endingVariables = {};
            variableMap.endingVariables = endingVariables;
        }
        const builtIn = structuredVariable.name.includes("builtin");
        let builtInOrCustomVariables: any = endingVariables[builtIn ? "builtIn" : "custom"];
        if (!builtInOrCustomVariables) {
            builtInOrCustomVariables = [];
            endingVariables[builtIn ? "builtIn" : "custom"] = builtInOrCustomVariables;
        }
        let value = structuredVariable.value;
        if (typeof structuredVariable.value === "string") {
            try {
                value = JSON.parse(structuredVariable.value);
            } catch (error) {
                console.error("Could not parse structured variable value for: " + structuredVariable.name);
            }
        }
        builtInOrCustomVariables.push({type: "complex", name: structuredVariable.name, value});
    }
    return variableMap;
}

/** adds the general error or warning nodes to the tree.
 *  @param errorsOrWarnings the Array of ErrorInfo objects with the errors or warnings.
 *  @param root the root of the tree.
 *  @param isError a boolean value, true if the Array of ErrorInfo objects are erroes and false if they are warnings.
 *  @param showDebugInfo a boolean value, if true show the session id from DO, if false do not show it. */
function addGeneralErrorOrWarningNodes(errorsOrWarnings: Array<ErrorInfo>, root: Array<any>, isError: boolean, showDebugInfo: boolean): void {
    let children: Array<any> = [];
    for (let index = 0; index < errorsOrWarnings.length; index++) {
        const errorOrWarning = errorsOrWarnings[index];
        const details: Record<string, string> = getErrorDetails(errorOrWarning);
        const code: string = errorOrWarning.code && STRINGS.runbookOutput.errorsAndWarnings[errorOrWarning.code] ? 
            errorOrWarning.code : (isError ? "GENERAL_ERROR" :  "GENERAL_WARNING");
        if ((code === "GENERAL_ERROR" || code === "GENERAL_WARNING") && errorOrWarning.code) {
            details.errorCode = errorOrWarning.code;
        }

        let errorText = STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details);
        if ((showDebugInfo || alwaysShowDoDebugAttributes) && doDebugAttributes?.length) {
            // If debug is true then collect up any attributes that the DO team has said will help with 
            // debugging and show them to the user
            const attrs: Array<string> = [];
            for (const attribute of doDebugAttributes) {
                if (details[attribute]) {
                    attrs.push(attribute + "=" + details[attribute]);
                }
            }
            if (attrs.length) {
                errorText += " (" + attrs.join(", ") + ")";
            }
        }
        //if (purifyContent) {
            // If purify content is turned on then sanitize the error string.  This causes a problem for 
            // URLs in the error by removing the target attribute.
            //errorText = DOMPurify.sanitize(errorText);
        //}
        const errorComp = <div className="d-flex icon-and-label-div">
            {isError && <Icon icon={IconNames.ERROR} className="mr-2" intent={Intent.DANGER}/>}
            {!isError && <Icon icon={IconNames.WARNING_SIGN} className="mr-2" intent={Intent.WARNING}/>}
            <span dangerouslySetInnerHTML={{__html: errorText}} />
        </div>;
        const childIdOrKey =  isError ? "error-" + index : "warning-" + index;
        
        children.push({
            id: childIdOrKey, key: childIdOrKey, depth: 1, path: [root.length, children.length], 
            className: "info-warning-error-label", 
            label: errorComp
        });
    }
    const idOrKey = isError ? "GeneralErrors" : "GeneralWarnings";
    root.push({
        id: idOrKey, key: idOrKey, label: STRINGS.runbookOutput.errorDialog[isError ? "generalErrorsLabel" : "generalWarningsLabel"], 
        isExpanded: true, childNodes: children
    });        
}

/** adds all errors and warnings for a specific widget. 
 *  @param errorsAndWarnings the object with the errors and warnings for the widget.
 *  @param parentNode the parent node of the nodes being added. 
 *  @param widgetName the name of the widget. 
 *  @param showDebugInfo a boolean value, if true show the session id from DO, if false do not show it. */
function addWidgetErrorsOrWarningsNodes(
    errorsAndWarnings: {warnings: Array<ErrorInfo>, errors: Array<ErrorInfo>}, parentNode: any, widgetName: string, showDebugInfo: boolean
): void {
    let children: Array<any> = [];
    if (errorsAndWarnings?.errors?.length) {
        const errorsNode = {
            id: "widget-errors" + widgetName, key: "widget-errors" + widgetName, depth: parentNode.depth + 1, path: parentNode.path.concat(children.length),
            label: STRINGS.runbookOutput.errorDialog.widgetErrorsLabel, 
            isExpanded: true, childNodes: []
        }
        addWidgetErrorOrWarningNodes(errorsAndWarnings.errors, errorsNode, true, showDebugInfo);
        children.push(errorsNode);        
    }
    if (errorsAndWarnings?.warnings?.length) {
        const warningsNode = {
            id: "widget-warnings" + widgetName, key: "widget-warnings" + widgetName, depth: parentNode.depth + 1, path: parentNode.path.concat(children.length),
            label: STRINGS.runbookOutput.errorDialog.widgetWarningsLabel, 
            isExpanded: true, childNodes: []
        }
        addWidgetErrorOrWarningNodes(errorsAndWarnings.warnings, warningsNode, false, showDebugInfo);
        children.push(warningsNode);        
    }
    parentNode.childNodes = children;
}

/** adds the widget error or warning nodes to the tree.
 *  @param errorsOrWarnings the Array of ErrorInfo objects with the errors or warnings.
 *  @param parentNode the parent node of the nodes being added. 
 *  @param isError a boolean value, true if the Array of ErrorInfo objects are erroes and false if they are warnings. 
 *  @param showDebugInfo a boolean value, if true show the session id from DO, if false do not show it. */
 function addWidgetErrorOrWarningNodes(errorsOrWarnings: Array<ErrorInfo>, parentNode: any, isError: boolean, showDebugInfo: boolean): void {
    let children: Array<any> = [];
    for (let index = 0; index < errorsOrWarnings.length; index++) {
        const errorOrWarning = errorsOrWarnings[index];
        const details: Record<string, string> = getErrorDetails(errorOrWarning);
        const code: string = errorOrWarning.code && STRINGS.runbookOutput.errorsAndWarnings[errorOrWarning.code] ? 
            errorOrWarning.code : (isError ? "GENERAL_ERROR" :  "GENERAL_WARNING");
        if ((code === "GENERAL_ERROR" || code === "GENERAL_WARNING") && errorOrWarning.code) {
            details.errorCode = errorOrWarning.code;
        }
            
        let errorText = STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details);
        if ((showDebugInfo || alwaysShowDoDebugAttributes) && doDebugAttributes?.length) {
            // If debug is true then collect up any attributes that the DO team has said will help with 
            // debugging and show them to the user
            const attrs: Array<string> = [];
            for (const attribute of doDebugAttributes) {
                if (details[attribute]) {
                    attrs.push(attribute + "=" + details[attribute]);
                }
            }
            if (attrs.length) {
                errorText += " (" + attrs.join(", ") + ")";
            }
        }
        //if (purifyContent) {
            // If purify content is turned on then sanitize the error string.  This causes a problem for 
            // URLs in the error by removing the target attribute.
            //errorText = DOMPurify.sanitize(errorText);
        //}
        const errorComp = <div className="d-flex icon-and-label-div">
            {isError && <Icon icon={IconNames.ERROR} className="mr-2" intent={Intent.DANGER}/>}
            {!isError && <Icon icon={IconNames.WARNING_SIGN} className="mr-2" intent={Intent.WARNING}/>}
            <span dangerouslySetInnerHTML={{__html: errorText}} />
        </div>;
        const childIdOrKey =  isError ? "error-" + index : "warning-" + index;

        children.push({
            id: childIdOrKey, key: childIdOrKey, depth: parentNode.depth + 1, path: parentNode.path.concat([children.length]), 
            className: "info-warning-error-label", 
            label: errorComp
        });
    }
    parentNode.childNodes = children;
}

/** returns the k/v pairs with the error details. 
 *  @param error the ErrorInfo object with the error information.
 *  @returns a map of the error details values to their keys.  The keys are referred to in the 
 *      error messages in the STRINGS file. */
function getErrorDetails(error: ErrorInfo): Record<string, string> {
    const details: Record<string, string> = {};
    if (error?.innerError?.properties?.length) {
        for (const prop of error.innerError.properties) {
            // Replace any "." in the key with an __.  The localization API does not support
            // nested objects.
            details[prop.key.replace(/\./g, "__")] = prop.value;
        }
    }
    return details;
}
 