import React, { useState } from 'react';
import { Button, FormGroup, HTMLInputProps, IInputGroupProps, InputGroup, Intent } from '@blueprintjs/core';
import { useField } from 'formik';
import { FieldError } from "./FieldError";
import { STRINGS } from 'app-strings';
import { Tooltip2 } from '@blueprintjs/popover2';

export type InputFieldProps = IInputGroupProps & HTMLInputProps & {
    label?: string
    helperText?: string
    errorFieldName?: string
    name: string
    showPasswordOption?: boolean
};
/**
 * TODO not a fan of the way multiple label+fields would align in the standard blueprint implementantion
 * (unless i am doing something wrong)
 * TODO an aligned version similar to what I did for PropertyList components
 */

function InputField(props: InputFieldProps) {
    const { label, required, helperText, errorFieldName, showPasswordOption, ...iProps } = props;
    const [field, meta] = useField(iProps);
    const [showPass, setShowPass] = useState(false);

    const lockButton = (
        <Tooltip2 content={`${showPass ? STRINGS.previewPassword.hide : STRINGS.previewPassword.show} ${STRINGS.previewPassword.password}`} disabled={props.disabled}>
            <Button
                disabled={props.disabled}
                icon={showPass ? "eye-open" : "eye-off"}
                intent={Intent.WARNING}
                minimal={true}
                onClick={() => setShowPass(!showPass)}
            />
        </Tooltip2>
    );

    return (
        <FormGroup label={label} labelFor={field.name} labelInfo={required ? '* ' : ''} helperText={helperText}>
            <InputGroup id={field.name} {...field} intent={meta.touched && meta.error ? 'danger' : 'none'}{...iProps}
                type={showPasswordOption ? showPass ? "text" : "password" : props.type}
                rightElement={(showPasswordOption && props.type === 'password') ? lockButton : props?.rightElement} />
            {(meta.touched && meta.error) && <FieldError name={errorFieldName || field.name} />}
        </FormGroup>
    );
}

export { InputField };
