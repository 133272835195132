/** This module defines the top navigation predictive AI notification React component.  The predictive AI notification 
 *      is displayed in the top navigation bar at the top of the page.  The notification widget will display if and only
 *      if there are A predictions to display.
 *  @module */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Tag } from '@blueprintjs/core';
import { Icon, IconNames } from '@tir-ui/react-components';
import { getURLPath } from 'config';
import { PRIORITY, PRIORITY_CLASS, PRIORITY_COLORS, PRIORITY_INDEX } from 'components/enums';
import { getURL, useQueryParams } from 'utils/hooks/useQueryParams';
import { parseTimeFromDAL } from 'utils/hooks';
import { formatToLocalTimestamp } from 'reporting-infrastructure/utils/formatters';
import { STRINGS } from 'app-strings';
import { DURATION, durationToTimeRange } from 'utils/stores/GlobalTimeStore';
import { PredictiveAiNotificationCardInfo, PredictiveAiNotificationsBlade } from './PredictiveAiNotificationsBlade';
import PredictionData from './PredictionData.json';
import "./PredictiveAiNotification.scss";

/** this interface defines the predition info object which has the details of all the predictions. */
interface PredictionInfoObject {
    /** a PRIORITY object with the highest priority of all the predictions. */
    worstPriority: PRIORITY;
    /** the count with the number of predictions. */
    count: number;
    /** an array with all the notifications. */
    notifications: PredictiveAiNotificationCardInfo[];
}

/** an integer with the max number of items to show. */
export const MAX_ITEMS_TO_SHOW: number = 20;

/** this interface defines the properties passed into the PredictiveAiNotication React component. */
export interface PredictiveAiNotificationProps {
    /** a handler for new notification received events. */
    onNewNotificationReceived?: (priority: string) => void;
}

/** Renders the top predictive AI notifcation component.
 *  @param props the properties passed in.
 *  @returns JSX with the predictive AI notification component.*/
export function PredictiveAiNotification (props: PredictiveAiNotificationProps): JSX.Element {
    const { params, setQueryParam } = useQueryParams({ listenOnlyTo: ["showAdvisorBlade"] });
    const freshAlert = useRef(Boolean(params.showAdvisorBlade) === false);
    const lastPageNotificationsWasFetchedFor = useRef("");
    const lastFetchEndTime = useRef<number>();

    // Create the query to retrieve the predictions
    const [predictionListQuery, setPredictionListQuery] = useState<{loading: boolean, error: any, run: (queryVariables) => void, data: any}>(
        {loading: false, error: undefined, run: (queryVariables) => {}, data: undefined}) /*useQuery({
        name: "PredictionsListForNotification",
        query: new Query(loader("./incident-list-for-notification.graphql")),
        consumedFilters: [],
        timeNotRequired: true,
        lazy: true
    })*/;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (lastPageNotificationsWasFetchedFor.current !== window.location.pathname) {
            let timeRangeToFetchPredictionsFor = durationToTimeRange(DURATION.DAY_2);
            const lastQueryTime = localStorage.getItem("lastNewPredictionsFetch");
            if (lastQueryTime !== null) {
                const lastQueryTimeNum = Number(lastQueryTime);
                if (lastFetchEndTime.current && lastFetchEndTime.current > lastQueryTimeNum) {
                    timeRangeToFetchPredictionsFor.startTime = lastFetchEndTime.current;
                // Use start time as the last user fetch time from local storage only if it was more recent than
                // the allowed range of predictions to fetch (set to 6 hours right now)
                } else if (lastQueryTimeNum > timeRangeToFetchPredictionsFor.startTime) {
                    timeRangeToFetchPredictionsFor.startTime = Number(lastQueryTime);
                }
            } else if (lastFetchEndTime.current) {
                timeRangeToFetchPredictionsFor.startTime = lastFetchEndTime.current;
            }
            lastPageNotificationsWasFetchedFor.current = window.location.pathname;
            lastFetchEndTime.current = timeRangeToFetchPredictionsFor.endTime;
            predictionListQuery.run({
                queryVariables: {
                    "startTime": timeRangeToFetchPredictionsFor.startTime,
                    "endTime": timeRangeToFetchPredictionsFor.endTime
                }
            });
            const newPredictionListQuery = JSON.parse(JSON.stringify(predictionListQuery));
            newPredictionListQuery.run = (queryVariables) => {};
            newPredictionListQuery.data = PredictionData;
            setPredictionListQuery(newPredictionListQuery);
        }
    });

    // Store the last time till which predictions were fetched when user OPENS the blade
    if (lastFetchEndTime.current && params.showAdvisorBlade) {
        localStorage.setItem("lastNewPredictionsFetch", String(lastFetchEndTime.current));
    }
    const collatedPredictionsInfo = useRef<PredictionInfoObject>();

    useEffect(() => {
        const newPredictionInfo = processPredictionInfo(predictionListQuery.data?.predictions?.nodes);
        let predictionAppended = false;
        if (!collatedPredictionsInfo.current) {
            collatedPredictionsInfo.current = newPredictionInfo;
        } else if (newPredictionInfo.count) {
            predictionAppended = true;
            collatedPredictionsInfo.current = combinePredictionInfo([{
                ...newPredictionInfo,
                notifications: newPredictionInfo.notifications.map(notification => ({...notification, new: true}))
            }, collatedPredictionsInfo.current]);
        }
        const newPredictionReceived = Boolean(collatedPredictionsInfo.current?.count);
        if (newPredictionReceived && props?.onNewNotificationReceived) {
            if (predictionAppended) {
                // Set freshAlert flag to true so that it updates notification button to show the expanded version
                if (Boolean(params.showAdvisorBlade) === false) {
                    freshAlert.current = true;
                }
                // Also call 'onNewNotificationReceived' so that navigation bar can show the full-size bar blink
                props.onNewNotificationReceived(String(collatedPredictionsInfo.current?.worstPriority || PRIORITY.UNKNOWN));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [predictionListQuery.data]);
    
    const predictionNotificationData = collatedPredictionsInfo.current;
    const predictionNotificationCount = predictionNotificationData?.count || 0;
    const worstPredictionPriority = predictionNotificationData?.worstPriority || PRIORITY.UNKNOWN;
    const worstPredictionPriorityBgClass = PRIORITY_CLASS[worstPredictionPriority]?.bg || PRIORITY_CLASS[PRIORITY.UNKNOWN].bg;

    const [notificationScrollIndex, setNotificationScrollIndex] = useState(0);
    const notificationScrollIndexRef = useRef(notificationScrollIndex);
    notificationScrollIndexRef.current = notificationScrollIndex;
    const notificationScrollTimer = useRef<any>(null);
    if (!notificationScrollTimer.current && predictionNotificationCount > 1) {
        notificationScrollTimer.current = setInterval(() => {
            setNotificationScrollIndex(notificationScrollIndexRef.current + 1 >= predictionNotificationCount ? 0 : notificationScrollIndexRef.current + 1);
        }, 3000);
    }
    if (notificationScrollTimer.current && (predictionNotificationCount <= 1 || !freshAlert.current)) {
        clearInterval(notificationScrollTimer.current);
        notificationScrollTimer.current = null;
    }

    function markAllNotificationsAsOld () {
        if (collatedPredictionsInfo.current) {
            collatedPredictionsInfo.current = {
                ...collatedPredictionsInfo.current,
                notifications: collatedPredictionsInfo.current.notifications.map(notification => ({...notification, new: false}))
            };
        }
    }

    const [, setLastUpdate] = useState(new Date());
    function clearAllNotifications () {
        if (collatedPredictionsInfo.current) {
            collatedPredictionsInfo.current = {
                worstPriority: PRIORITY.UNKNOWN,
                count: 0,
                notifications: []
            };
            // Using a state change to trigger re-render of this component
            setLastUpdate(new Date());
        }
    }

    return <>
            {(predictionNotificationCount > 0) &&
                <Button minimal={true} icon={<Icon icon={IconNames.AI_BIS_1} iconSize={20}/>} active={Boolean(params.showAdvisorBlade)}
                    className={"text-white position-relative align-self-stretch" + (freshAlert.current ? " " + worstPredictionPriorityBgClass + " pulse-double" : "")}
                    onClick={() => {
                        freshAlert.current = false;
                        const bladeOpen = Boolean(params.showAdvisorBlade);
                        setQueryParam("showAdvisorBlade", (bladeOpen ? "" : "true"));
                        if (bladeOpen) {
                            markAllNotificationsAsOld();
                        }
                    }}>
                    <span className={"counts d-inline-block display-10 text-center investigation-count text-black" + (freshAlert.current ? " bg-muted" : " " + worstPredictionPriorityBgClass)}>{predictionNotificationCount > MAX_ITEMS_TO_SHOW ? MAX_ITEMS_TO_SHOW + "+" : predictionNotificationCount}</span>
                    {freshAlert.current && 
                        <div className="ml-2 w-max-3 notification-marquee d-none d-lg-block">
                            <div className="notification-marquee-content pr-1" style={{top: "-" + (notificationScrollIndexRef.current * 20) + "px"}}>
                                {predictionNotificationData?.notifications.map((prediction, index) => {
                                    return <span key={"notification-" + prediction.id + "-" + index} className={notificationScrollIndexRef.current !== index ? "w-max-1" : ""}>{"Forecast Thresholds Exceeded"/*prediction.title*/}</span>;
                                })}
                            </div>
                        </div>
                    }
                </Button>
            }
            <PredictiveAiNotificationsBlade
                priority={worstPredictionPriority}
                notifications={predictionNotificationData?.notifications}
                loading={predictionListQuery.loading}
                onCloseClicked={markAllNotificationsAsOld}
                onClearNotificationsClicked={clearAllNotifications}
            />
        </>;
}

/** Go through a list of predictions and map into prediction cards */
function processPredictionInfo (predictions:any = []): PredictionInfoObject {
    let output: PredictionInfoObject = {
        worstPriority: PRIORITY.LOW,
        count: 0,
        notifications: []
    };
    if (predictions.length > 0) {
        let priorities:any[] = [];
        for (const prediction of predictions) {
                const predictionPriority = prediction.priority;
                const [firstTrigger] = prediction.triggers || [];
                const allIndicators = prediction.triggers?.reduce((output, trigger) => {
                    return [...output, ...(trigger.indicators ? trigger.indicators : [{kind: "too_high", metric: "sum_traffic_total_bytes"}])];
                }, []);
                const anomalyMessage = getNaturalLanguageMessageForIndicators(allIndicators);
                output.notifications.push({
                    id: prediction.id,
                    // title: prediction.description + " (" + formatDurationToString(Number(prediction.duration)) + ")",
                    title: prediction.description,
                    message: <div>
                        <div className="mt-2">
                            { anomalyMessage ? anomalyMessage + " " + STRINGS.predictions.anomalyAt + " " : "" }
                            {formatToLocalTimestamp(parseTimeFromDAL(prediction.earliestDetection))}
                        </div>
                        <div className="pt-3">
                            { firstTrigger?.entity?.name && <Tag
                                minimal={true}
                                className="mr-1 mb-1 py-1 align-middle text-white"
                                style={{ backgroundColor: PRIORITY_COLORS[predictionPriority] || ""}}
                                // icon={firstTrigger.entity?.kind === "network_interface" ? <Icon icon={SDWAN_ICONS.INTERFACE}/> : undefined}
                                icon={firstTrigger.entity?.kind === "network_interface" ? <Icon icon={IconNames.LAN}/> : undefined}
                                >{firstTrigger.entity.name}</Tag>
                            }
                        </div>
                    </div>,
                    priority: predictionPriority,
                    data: {
                        id: prediction.id,
                        ...prediction
                    },
                    link: getURL(getURLPath("incident"), { incidentId: prediction.id }, { replaceQueryParams: true })
                });
                priorities.push({ priority: predictionPriority, count: 1 });
        }
        output.worstPriority = getWorstPriority(priorities) || PRIORITY.UNKNOWN;
        output.count = output.notifications.length;
    }
    return output;
}

export function getWorstPriority (priorities: Array<any>, onlyIfHigh: boolean = false): PRIORITY | undefined {
    let worst = PRIORITY.UNKNOWN;
    if (priorities) {
        for (const priorityRow of priorities) {
            const pIndex = PRIORITY_INDEX[priorityRow.priority];
            if (priorityRow.count > 0 && pIndex > PRIORITY_INDEX[worst]) {
                worst = priorityRow.priority;
            }
            if (worst === PRIORITY.CRITICAL) {
                // Can't get any higher so stop
                break;
            }
        }
    }
    if ((worst === PRIORITY.UNKNOWN || worst === PRIORITY.LOW || worst === PRIORITY.MODERATE) && onlyIfHigh) {
        return undefined;
    } else {
        return worst;
    }
}

function combinePredictionInfo(infoObjects) {
    const combinedInfoObj = infoObjects.reduce((combined, infoObj) => {
        if (combined) {
            combined.worstPriority = getWorstPriority([
                { priority: combined.worstPriority, count: 1 },
                { priority: infoObj.worstPriority, count: 1 },
            ]);
            combined.count += infoObj.count;
            combined.notifications = [...combined.notifications, ...infoObj.notifications];
            return combined;
        } else {
            return Object.assign(infoObj);
        }
    }, null);
    return combinedInfoObj;
}


export function getNaturalLanguageMessageForIndicators (allIndicators:any = []) {
    const uniqueIndicators = {};
    if (allIndicators) {
        for (const anomaly of allIndicators) {
            const anomalyKey = anomaly.metric + "_" + anomaly.kind;
            uniqueIndicators[anomalyKey] = (uniqueIndicators[anomalyKey] || 0) + 1;
        }    
    }
    let anomalyMessage = "";
    let uniqueIndicatorCount = Object.keys(uniqueIndicators).length;
    for (const anomalyRawKey in uniqueIndicators) {
        uniqueIndicatorCount--;
        if (anomalyMessage !== "") {
            anomalyMessage += (uniqueIndicatorCount === 0 ? (" " + STRINGS.and) : ", ") + " ";
        }
        const anomalyKey = anomalyRawKey.toLowerCase();
        if (STRINGS.predictions.anomalyStrings[anomalyKey] === undefined) {
            console.warn("Missing localized string for " + anomalyKey);
            anomalyMessage += anomalyKey.replace(/__/g, ": ").replace(/_/g, " ");
        } else {
            anomalyMessage += STRINGS.predictions.anomalyStrings[anomalyKey];
        }
    }
    return anomalyMessage;
}

