/** This file defines the output data element for the transform node editor.  An output data element
 *  allows the user to edit one key (property) or metric.  A list of output data elements is in an 
 *  output data block.
 *  @module */
import React, { useState, useRef, useEffect } from "react";
import { IRef } from '@blueprintjs/core/dist/core.bundle';
import { Button, HTMLSelect, IconName, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { STRINGS } from "app-strings";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { Icon } from "@tir-ui/react-components";
import { KeysMetadata, TransformNodeUtils } from "./TransformNodeUtils";
import { UpdateEventType } from "./TransformNodeEditor";
import { Suggest } from "@blueprintjs/select";
import { setNativeValue } from "reporting-infrastructure/utils/commonUtils";
import { MultiSelectInput } from "components/common/multiselect/MultiSelectInput";
import { SHOW_JSON_DO } from "components/enums/QueryParams";
import './TransformNodeEditor.scss';

/** this interface defines the key or metric definition in this output element UI. */
export interface OutputDataElementDefinition {
    /** the id of the element. */
    id: string;
    /** the initial type that should be displayed in the UI. */
    type?: string;
    /** the initial unit that should be displayed in the UI. */
    unit?: string;
    /** a String with the label for the element. */
    label: string;
    /** a String with the data ocean id if this is one of the standard keys or metrics, otherwise undefined. */
    dataOceanId?: string;
}

/** this interface defines the key definitions used in the suggested list and displayed as the built-in properties. */
export interface KeyDefinition {
    /** a String with the id of the key. */
    id: string;
    /** a String with the label for the key. */
    label: string;
    /** a String with the type of the key. */
    type: string;
    /** a String with the unit of the key. */
    unit: string;
    /** a boolean value, true if this is a primary key and is thus required for a filter to be created from it. */
    required?: boolean;
}

/** an enum with the output value type. */
export enum OutputDataValueType {
    /** the enumberated value for an integer. */
    INTEGER = "integer",
    /** the enumberated value for a string. */
    STRING = "string",
    /** the enumberated value for a float. */
    DECIMAL = "float",
    /** the enumberated value for a IP Address. */
    IPADDRESS = "ipaddr",
    /** the enumberated value for a boolean. */
    BOOLEAN = "boolean",
    /** the enumberated value for a JSON object. */
    JSON = "json",
}

/** this interface defines the properties passed into the OutputDataElement react component. */
export interface OutputDataElementProps {
    onRemoveOutputDataElementClicked?: (id: string) => void,
    /** the onChange handler that is called whenever the keys and metrics change. */
    onChange?,
    /** the initial definition that is used for this element. */
    outputDataElementDefinition: OutputDataElementDefinition,
    /** the list of suggestions for either the keys (properties) or metrics. */
    suggestionsList: Object,
    /** a string with the name of the section. */
    sectionName?: string,
    /** boolean set to true if definition method is set to loading from variable. */
    loadFromVar: boolean,
    /** boolean set to true if current element is first in the list, used to display the label above inputs. */
    isFirstItem: boolean,
    /** boolean set to true if inside a subflow node editor panel */
    isSubflow?: boolean,
    /** boolean set to true if inside an on-demand runbook node editor panel */
    isOnDemandNodeEditor?: boolean,
    /** boolean set to true if inside the on-demand runbook run dialog */
    isOnDemandRunDialog?: boolean
}

export interface AutocompleteElement {
    id: string;
    label: string;
    type: string;
    unit: string;
}

/** Component for editing the one key or one metric.
 *  @returns a JSX component with the output data element editor. */
export function OutputDataElement(
    {
        onRemoveOutputDataElementClicked,
        onChange,
        outputDataElementDefinition,
        suggestionsList,
        sectionName,
        loadFromVar,
        isFirstItem,
        isSubflow,
        isOnDemandNodeEditor,
        isOnDemandRunDialog
    }: OutputDataElementProps
): JSX.Element {
    const suggestionsKeys = Object.keys(suggestionsList as Object).filter(
        key => SHOW_JSON_DO || !["json", "requestBody", "requestQueryParameters", "requestHeaders"].includes(key)
    );
    const [unitOptions, setUnitOptions] = useState(Unit.BASE_UNITS.filter((unit) => unit !== "pct"));
    const [valueTypeOptions, setValueTypeOptions] = useState(getDefaultOutputDataValueTypeOptions);
    const [builtInPropsSectionExpanded, setBuiltInPropsSectionExpanded] = useState(false);
    const [keys, setKeys] = useState(new Array<KeyDefinition>());
    const [suggestedItems, setSuggestedItems] = useState(new Array<AutocompleteElement>());
    const [runbookSelectedItem, setRunbookSelectedItem] = useState<any>();

    let suggestInput = useRef<IRef<HTMLInputElement> | undefined>();

    useEffect(() => {
        const suggestedItems: Array<AutocompleteElement> = [];
        if (isOnDemandRunDialog) {
            suggestedItems.push({id: "any", label: "Any", type: "", unit: ""});
        }
        if (isOnDemandNodeEditor) {
            suggestedItems.push({id: "", label: "None", type: "", unit: ""});
        }
        for (const item of suggestionsKeys) {
            suggestedItems.push({id: item, label: suggestionsList[item]?.label, type: suggestionsList[item]?.type, unit: suggestionsList[item]?.unit});
        }
        setSuggestedItems(suggestedItems);
        if (outputDataElementDefinition && outputDataElementDefinition.label) {
            setRunbookSelectedItem({
                id: outputDataElementDefinition?.dataOceanId,
                label: outputDataElementDefinition?.label,
                type: outputDataElementDefinition?.type,
                unit: outputDataElementDefinition?.unit
            });
            if (outputDataElementDefinition.dataOceanId && !isBuiltin.current) {
                getBuiltInProperties(outputDataElementDefinition?.dataOceanId);
                isBuiltin.current = true;
                setUnitOptions(getUnitTypeOptions(outputDataElementDefinition?.dataOceanId));
                setValueTypeOptions(getValueTypeOptions(outputDataElementDefinition?.dataOceanId));
            }
        }

        if (isSubflow) {
            setUnitOptions(['N/A']);
            setValueTypeOptions(['Object']);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getUnitTypeOptions(itemId: string) {
        var unitsList;
        if (itemId && suggestionsKeys.includes(itemId)) {
            const unit = new Unit(suggestionsList?.[itemId].unit);
            if (unit.unit !== "" && unit.isScalable()) {
                unitsList = unit.getScaledUnitOptions();
            } else {
                unitsList = [unit.unit ? unit.unit : 'N/A'];
            }
        } else {
            unitsList = Unit.BASE_UNITS.filter((unit) => unit !== "pct");
        }
        return unitsList;
    }

    function getValueTypeOptions(itemId: string) {
        var valueType;
        if (itemId && suggestionsKeys.includes(itemId)) {
            valueType = suggestionsList?.[itemId].type;
            valueType = valueType ? valueType.charAt(0).toUpperCase() + valueType.slice(1) : 'N/A';
        } else return getDefaultOutputDataValueTypeOptions();
        return [valueType];
    }

    function getDefaultOutputDataValueTypeOptions() {
        return Object.keys(OutputDataValueType).map(k => OutputDataValueType[k as any]);
    }

    function getBuiltInProperties(itemId: string) {
        let availableKeys = TransformNodeUtils.getCompleteExpandedKeys(suggestionsList as KeysMetadata, itemId ? itemId : '');
        if (availableKeys) {
            setKeys(availableKeys);
        }
        return availableKeys;
    }

    function getTooltipContent(key: KeyDefinition) {
        var tooltipContent = "";
        if (key.label) {
            tooltipContent += "<b>" + key.label + "</b>";
        }
        if (key.required === true) {
            tooltipContent += "<br>" + STRINGS.runbookEditor.nodeEditor.tooltips.requiredForIntegration;
        }
        if (key.id) {
            tooltipContent += "<br>" + STRINGS.runbookEditor.nodeEditor.tooltips.id + key.id;
        }
        if (key.type) {
            tooltipContent += "<br>" + STRINGS.runbookEditor.nodeEditor.tooltips.type + key.type.charAt(0).toUpperCase() + key.type.slice(1);
        }
        if (key.unit) {
            tooltipContent += "<br>" + STRINGS.runbookEditor.nodeEditor.tooltips.unit + key.unit.charAt(0).toUpperCase() + key.unit.slice(1);
        }     
        return tooltipContent;
    }

    const isBuiltin = useRef<boolean>(false);

    const AutocompleteSuggest = Suggest.ofType<AutocompleteElement>();

    const handleItemSelection = (item: AutocompleteElement ) => {
        isBuiltin.current = true;
        getBuiltInProperties(item.id);
        setUnitOptions(getUnitTypeOptions(item.id));
        setValueTypeOptions(getValueTypeOptions(item.id));
        onChange({
            uniqueId: outputDataElementDefinition.id,
            id: item.id,
            type: suggestionsKeys.includes(item.id) && suggestionsList[item.id]?.label === item.label ? UpdateEventType.DEFAULT_SELECTED : UpdateEventType.NAME_CHANGED,
            value: item.label,
            isDoId: suggestionsKeys.includes(item.id) && suggestionsList[item.id]?.label === item.label
        });

        setRunbookSelectedItem({...item});
        if (suggestInput.current) {
            setNativeValue(suggestInput.current, JSON.stringify(item.label));
        }
    }

    const propsCreateNewItem = {...(!isSubflow && {
        createNewItemFromQuery: (query) => {return {id: query, label: query, unit: '', type: ""}},
        createNewItemRenderer: (query, active, handleClick) => {
            return <div key={"ac-" + query} onClick={handleClick} className={"pt-2 pb-2 d-flex justify-content-between align-items-center ac-suggest-row" + (active ? " selected": "")}>
                <div className="ml-2 left-controls d-flex flex-wrap align-items-center">{query}</div>
            </div>;
        }})
    }

    let autocompleteInputPlaceholder = "";
    if (isOnDemandNodeEditor) {
        autocompleteInputPlaceholder = STRINGS.runbookInvocations.entityTypeInputPlaceholder;
    }
    if (isOnDemandRunDialog) {
        autocompleteInputPlaceholder = STRINGS.runbookInvocations.entityTypeAny;
    }

    return <div>
        <div className="d-flex flex-row p-2 pb-0">
            <div className={"d-flex flex-column pr-3" + (isOnDemandNodeEditor ? " w-100 on-demand-input-data-properties" : "")}>
            <label className={isFirstItem ? "" : "d-none"}>{isOnDemandNodeEditor ? STRINGS.runbookEditor.nodeEditor.outputDataTypeField : STRINGS.runbookEditor.nodeEditor.outputDataNameField}</label>
            {
                <>
                    <AutocompleteSuggest 
                        className="output-data-autocomplete-suggest"
                        inputProps={{
                            placeholder: autocompleteInputPlaceholder,
                            className: "w-min-2",
                            inputRef: suggestInput,
                            name: "input-suggest",
                        }}
                        disabled={loadFromVar}
                        itemsEqual={areItemsEqual}
                        defaultSelectedItem={outputDataElementDefinition as AutocompleteElement}
                        resetOnSelect={true}
                        closeOnSelect={true}
                        resetOnClose={true}
                        popoverProps={{ minimal: true, popoverClassName: "h-max-6 overflow-auto position-absolute" }}
                        items={suggestedItems ? suggestedItems : []}
                        itemRenderer={renderAutocompleteItem}
                        itemPredicate={(query, item) => {
                            const queryLowerCase = query?.toLowerCase() || "";
                            return item.label.toLowerCase().includes(queryLowerCase) ? true : false;
                        }}
                        fill={false}
                        inputValueRenderer={item => item.label}
                        {...propsCreateNewItem}
                        onItemSelect={handleItemSelection}
                        noResults={<MenuItem disabled={true} text={STRINGS.globalFilters.empty} />}
                    />
                    {(!runbookSelectedItem|| !runbookSelectedItem?.label) && !isSubflow &&
                        <span className="text-danger align-top small">{STRINGS.runbookEditor.errors.transformNode.outputDataPropertyMissingName}</span>}
                </>
            }
            </div>
            <div className={"flex-column pr-3" + (isOnDemandNodeEditor ? " d-none" : " d-flex")}>
                <label className={isFirstItem ? "" : "d-none"}>{STRINGS.runbookEditor.nodeEditor.outputDataTypeField}</label>
                <HTMLSelect className="selector-min-width"
                    aria-label="valueType-select"
                    name="valueType"
                    fill={false}
                    disabled={loadFromVar}
                    defaultValue={outputDataElementDefinition?.type || valueTypeOptions[0]}
                    options={valueTypeOptions}
                    onChange={e => {
                        onChange({
                            uniqueId: outputDataElementDefinition.id,
                            id: runbookSelectedItem?.id,
                            type: UpdateEventType.VALUE_TYPE_CHANGED,
                            value: e.currentTarget.value
                        });
                    }}
                >
                </HTMLSelect>
            </div>
            <div className={"flex-column pr-3" + (isOnDemandNodeEditor ? " d-none" : " d-flex")}>
                <label className={isFirstItem ? "" : "d-none"}>{STRINGS.runbookEditor.nodeEditor.outputDataUnitField}</label>
                <HTMLSelect className="selector-min-width"
                    aria-label="unit-select"
                    name="unit"
                    disabled={loadFromVar}
                    fill={false}
                    defaultValue={outputDataElementDefinition?.unit}
                    options={unitOptions}
                    onChange={e => {
                        onChange({
                            uniqueId: outputDataElementDefinition.id,
                            id: runbookSelectedItem?.id,
                            type: UpdateEventType.UNIT_CHANGED,
                            value: e.currentTarget.value
                        });
                    }}
                >
                </HTMLSelect>
            </div>
            <div className={"flex-column pr-3" + (isFirstItem ? " mt-4" : "") + (isOnDemandNodeEditor ? " d-none" : " d-flex")}>
                <Button
                    minimal
                    disabled={loadFromVar}
                    title={STRINGS.runbookEditor.nodeEditor.tooltips.removeOutput}
                    icon={IconNames.CROSS as IconName}
                    onClick={() => {
                        if (onRemoveOutputDataElementClicked) {
                            setNativeValue(suggestInput.current, null);
                            onRemoveOutputDataElementClicked(outputDataElementDefinition?.id);
                        }
                    }}
                />
            </div>
        </div>
        {
            sectionName && keys && runbookSelectedItem && suggestionsKeys?.includes(runbookSelectedItem?.id) && <div className="p-2">
                <div
                    className="heading d-inline-block clickable display-9 font-weight-500"
                    onClick={() => setBuiltInPropsSectionExpanded(!builtInPropsSectionExpanded)}
                >
                    <Icon icon={builtInPropsSectionExpanded ? IconNames.CARET_DOWN: IconNames.CARET_RIGHT}/>
                    {STRINGS.runbookEditor.nodeEditor.builtInProperties}
                </div>
                {
                    builtInPropsSectionExpanded && <div className="pt-2" data-testid="builtin-props-list"><MultiSelectInput
                        key={"built-in-props-section"}
                        sortable
                        items={ keys.map(key => {
                            return { display: key.label, value: key.id }
                        }) }
                        selectedItems={ keys.map(key => {
                            return { display: key.label, value: key.id, tooltip: getTooltipContent(key)}
                        }) }
                        placeholder={ "" }
                        disabled={true}
                    /></div>
                }
            </div>
        }
    </div>
}


const renderAutocompleteItem = (item: AutocompleteElement, { handleClick, modifiers, query }): JSX.Element => {
    return  (
        <MenuItem
            active={modifiers.active}
            key={"ac-" + item.id}
            onClick={handleClick}
            text={<span>{item.label}</span>}
        />
    );
};

function areItemsEqual(itemA: AutocompleteElement, itemB: AutocompleteElement) {
    // compare labels of two items
    return itemA.label.toLowerCase() === itemB.label.toLowerCase();
}
