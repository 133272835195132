/** This file defines the liquid template toolbar React component.  The liquid template
 *  toolbar component provides a set of controls for adding variables to the liquid template.
 *  @module */
import React, { useState, useEffect } from "react";
import { AnchorButton, HTMLSelect } from "@blueprintjs/core";
import { HELP, STRINGS } from "app-strings";
import { IconNames } from '@tir-ui/react-components';
import { GenericKey } from "utils/runbooks/NodeUtil";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp";
import { TriggerKey } from "./TransformNodeEditor";
import { InputType, Variant } from "../../types/GraphTypes";
import './LiquidTemplateToolbar.scss';

/** This interface defines the properties that are passed into the liquid template toolbar. */
export interface LiquidTemplateToolbarProps {
    /** the runbook variant that being edited. */
    variant: Variant;
    /** the runtime, incident, and/or global variables. */
    variables: any;
    /** the list of expanded keys for the trigger. */
    triggerExpandedKeys: TriggerKey[];
    /** the list of metrics for the trigger. */
    triggerMetrics?: GenericKey[];
    /** the list of expanded keys for the parent. */
    parentExpandedKeys: GenericKey[];
    /** the list of metrics for the parent. */
    parentMetrics?: GenericKey[];
    /** the handler for the liquid template variables selection. */
    handleVariableSelection: (variable: string) => void;
}

/** Component for editing the properties of a liquid template toolbar.
 *  @param props the properties passed into the React component.
 *  @returns a JSX component with the liquid template toolbar. */
export const LiquidTemplateToolbar = (props: LiquidTemplateToolbarProps): JSX.Element => {
    const [type, setType] = useState<string>(props.variant !== Variant.SUBFLOW ? "trigger" : "parent");
    const [options, setOptions] = useState<{label: string, value: string}[]>(
        props.variant !== Variant.SUBFLOW ?
            [STRINGS.liquidTemplateEditor.toolbar.selectTrigger].concat((props.triggerExpandedKeys || []).map(key => {
                if (key.triggerType === InputType.WEBHOOK) {
                    return {label: key.label, value: `trigger["${key.id}"]`};
                } else {
                    const parentId: string = key.liquid_key ?  key.liquid_key : key.id.substring(0, key.id.indexOf(".")) + "s";
                    return {label: key.label, value: `trigger["${parentId}"]["data"][0].keys["${key.id}"]`};
                }
            /* Not supported on back-end right now    
            })).concat((props.triggerMetrics || []).map(metric => {
                const parentId: string = "metric"; // metric.id.substring(0, metric.id.indexOf("."));
                return {label: metric.label, value: `trigger["${parentId}s"]["data"][0].data["${metric.id}"]`};
            */
            }))
        :
            [{label: STRINGS.liquidTemplateEditor.toolbar.selectParent, value: ""}].concat((props.parentExpandedKeys || []).map(key => {
                return {label: key.label, value: `node_input.output["data"][0].keys["${key.id}"]`};
            }).concat((props.parentMetrics || []).map(metric => {
                return {label: metric.label, value: `node_input.output["data"][0].data["${metric.id}"]`};
            })))
    );
    const [option, setOption] = useState<string | undefined>(
        props.triggerExpandedKeys?.length 
            ? `trigger["${props.triggerExpandedKeys[0].id.substring(0, props.triggerExpandedKeys[0].id.indexOf("."))}s"]["data"][0].keys["${props.triggerExpandedKeys[0].id}"]` 
            : undefined
    );

    useEffect(
        () => {
            if (type === "trigger") {
                const newOptions: {label: string, value: string}[] = [{label:STRINGS.liquidTemplateEditor.toolbar.selectTrigger, value: ""}];
                newOptions.push(...(props.triggerExpandedKeys || []).map(key => {
                    if (key.triggerType === InputType.WEBHOOK) {
                        return {label: key.label, value: `trigger["${key.id}"]`};
                    } else {
                        const parentId: string = key.liquid_key ? key.liquid_key : key.id.substring(0, key.id.indexOf(".")) + "s";
                        return {label: key.label, value: `trigger["${parentId}"]["data"][0].keys["${key.id}"]`};
                    }
                }/*).concat((props.triggerMetrics || []).map(metric => {
                    const parentId: string = "metric"; //metric.id.substring(0, metric.id.indexOf("."));
                    return {label: metric.label, value: `trigger["${parentId}s"]["data"][0].data["${metric.id}"]`};
                })*/));
                setOptions(newOptions);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.triggerExpandedKeys, props.triggerMetrics]
    );

    useEffect(
        () => {
            if (type === "parent") {
                const newOptions: {label: string, value: string}[] = [{label: STRINGS.liquidTemplateEditor.toolbar.selectParent, value: ""}];
                newOptions.push(...(props.parentExpandedKeys || []).map(key => {
                    return {label: key.label, value: `node_input.output["data"][0].keys["${key.id}"]`};
                }).concat((props.parentMetrics || []).map(metric => {
                    return {label: metric.label, value: `node_input.output["data"][0].data["${metric.id}"]`};
                })));
                setOptions(newOptions);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.parentExpandedKeys, props.parentMetrics]
    );

    return <div className="align-items-center mt-2 position-relative">
        <span className="display-8 mr-1">Insert</span>
        <WrapInTooltip 
            tooltip={
                type === "trigger" 
                    ? STRINGS.liquidTemplateEditor.toolbar.selectTriggerTooltip 
                    : type === "parent" ? STRINGS.liquidTemplateEditor.toolbar.selectParentTooltip : STRINGS.liquidTemplateEditor.toolbar.selectVariableTooltip
            }
        >
            <HTMLSelect id="var-type-selection" name="var-type-selection"
                disabled={false}
                value={option}
                onChange={(event) => {
                    setOption(event.target.value);
                }}
                options={options} className="editor-input-standard"
            />
        </WrapInTooltip>
        <span className="display-8 ml-2 mr-1">from</span>
        <WrapInTooltip tooltip={STRINGS.liquidTemplateEditor.toolbar.selectTypeTooltip}>
            <HTMLSelect id="var-type-selection" name="var-type-selection"
                disabled={false}
                value={type}
                onChange={(event) => {
                    let newOptions: {label: string, value: string}[] = [];
                    switch (event.target.value) {
                        case "trigger":
                            newOptions = [{label: STRINGS.liquidTemplateEditor.toolbar.selectTrigger, value: ""}];
                            newOptions.push(...(props.triggerExpandedKeys || []).map(key => {
                                if (key.triggerType === InputType.WEBHOOK) {
                                    return {label: key.label, value: `trigger["${key.id}"]`};
                                } else {
                                    const parentId: string = key.liquid_key ? key.liquid_key : key.id.substring(0, key.id.indexOf(".")) + "s";
                                    return {label: key.label, value: `trigger["${parentId}"]["data"][0].keys["${key.id}"]`};
                                }
                            }/*).concat((props.triggerMetrics || []).map(metric => {
                                const parentId: string = "metric"; //metric.id.substring(0, metric.id.indexOf("."));
                                return {label: metric.label, value: `trigger["${parentId}s"]["data"][0].data["${metric.id}"]`};
                            })*/));
                            break;
                        case "parent":
                            newOptions = [{label: STRINGS.liquidTemplateEditor.toolbar.selectParent, value: ""}];
                            newOptions.push(...(props.parentExpandedKeys || []).map(key => {
                                return {label: key.label, value: `node_input.output["data"][0].keys["${key.id}"]`};
                            }).concat((props.parentMetrics || []).map(metric => {
                                return {label: metric.label, value: `node_input.output["data"][0].data["${metric.id}"]`};
                            })));
                            break;
                        case "variable":
                            newOptions = [{label: STRINGS.liquidTemplateEditor.toolbar.selectVariable, value: ""}];
                            props.variables.forEach((variable) => {
                                newOptions.push({label: variable.name, value: variable.name});
                            });
                            break;
                    }
                    setOptions(newOptions);
                    setOption(undefined);
                    setType(event.target.value);
                }}
                options={
                    (props.variant !== Variant.SUBFLOW 
                        ? [{label: "Trigger", value: "trigger"}] 
                        : []
                    ).concat([{label: "Parent", value: "parent"}, {label: "Variable", value: "variable"}])} 
                className="editor-input-standard"
            />
        </WrapInTooltip>
        <WrapInTooltip tooltip={STRINGS.liquidTemplateEditor.toolbar.addTooltip}>
            <InlineHelp helpMapping={HELP.templateValidator?.template}>
                <AnchorButton
                    className="ml-4"
                    id="variables-icon"
                    icon={IconNames.ADD}
                    onClick={() => {
                        if (option) {
                            let varText = "";
                            switch (type) {
                                case "trigger":
                                    varText = `{{${option}}}`;
                                    varText = varText.replaceAll('\\', '');
                                    break;
                                case "parent":
                                    varText = `{{${option}}}`;
                                    varText = varText.replaceAll('\\', '');
                                    break;
                                case "variable":
                                    varText = `{{variables["${option}"]}}`;
                                    varText = varText.replaceAll('\\', '');
                                    break;
                            }
                            props.handleVariableSelection(varText);    
                        }
                    }}
                />
            </InlineHelp>
        </WrapInTooltip>
    </div>;
};
