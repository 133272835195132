/** This file defines the SearchItemHeader React component.  The SearchItemHeader react 
 *  component displays the search header which displays an Icon that represents the 
 *  search item type and the title of the search item.
 *  @module */
import React from "react";
import { SDWAN_ICONS } from "components/sdwan/enums/icons";
import { SearchItemType } from "../search-results-table/SearchResultsTable";
import { Severity } from "types";
import { IconTitle } from "components/common/icon-title/IconTitle";

/** this interface defines the properties passed into the SearchItemHeader component. */
export interface SearchItemHeaderProps {
    /** the SearchItemType with the type of search item that is being displayed in the header. */
    type?: SearchItemType;
    /** the Severity object with the information on the severity/priority of the search result.  
     *  If absent no severity information is shown. */
    severity?: Severity;
    /** a String with the title of the search result that is to be displayed in the header.  This is
     *  typically the name of the item. */
    title: string;
}

/** Creates the the search item header component which displays the header in a search result.
 *  @param props an object with the properties passed to the search item header component.
 *  @returns JSX with the search item header component.*/
export function SearchItemHeader({ title, type, severity }: SearchItemHeaderProps) {
    return <div className="d-flex">
        <div>
            {type &&
                <IconTitle icon={SDWAN_ICONS[type]} status={severity?.value} />
            }
        </div>
        <h6 className="ml-1">
            {title}
        </h6>
    </div>
}
