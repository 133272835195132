/** This module contains the component for rendering the health monitoring section.
 * It consist of cloud status, Edges, metric streaming, indicator generation,
 * incident generation and runbook execution cards.
 *  @module
 */
import React from 'react';
import { IconNames } from '@tir-ui/react-components';
import { CardsHolder } from 'components/common/layout/cards-holder/CardsHolder';
import { AnalyticsCard } from 'pages/health-monitor/cards/AnalyticsCard';
import { CloudStatusCard } from 'pages/health-monitor/cards/CloudStatusCard';
import { AlluvioEdgesCard } from 'pages/health-monitor/cards/AlluvioEdgesCard';
import { DataIngestCard } from 'pages/health-monitor/cards/DataIngestCard';
import { IncidentGenerationCard } from 'pages/health-monitor/cards/IncidentGenerationCard';
import { RunbooksCard } from 'pages/health-monitor/cards/RunbooksCard';

import { STRINGS } from 'app-strings';

export enum HEALTH_STATUSES {
	OPERATIONAL = 'OPERATIONAL',
	DEGRADED = 'DEGRADED_PERFORMANCE',
	PARTIAL_OUTAGE = 'PARTIAL_OUTAGE',
	MAJOR_OUTAGE = 'MAJOR_OUTAGE',
	MAINTENANCE = 'UNDER_MAINTENANCE',
	UNKNOWN = 'UNKNOWN',
}

export const HealthStatuses = {
	OPERATIONAL: {
		name: STRINGS.HEALTH_MONITORING_PAGE.healthStatus.operational.label,
		icon: IconNames.TICK,
		classname: 'text-success',
	},
	DEGRADED_PERFORMANCE: {
		name: STRINGS.HEALTH_MONITORING_PAGE.healthStatus.degraded.label,
		icon: IconNames.WARNING_SIGN,
		classname: 'text-warning',
	},
	PARTIAL_OUTAGE: {
		name: STRINGS.HEALTH_MONITORING_PAGE.healthStatus.partialOutage.label,
		icon: IconNames.ISSUE,
		classname: 'text-warning',
	},
	MAJOR_OUTAGE: {
		name: STRINGS.HEALTH_MONITORING_PAGE.healthStatus.majorOutage.label,
		icon: IconNames.ISSUE,
		classname: 'text-danger',
	},
	UNDER_MAINTENANCE: {
		name: STRINGS.HEALTH_MONITORING_PAGE.healthStatus.maintenance.label,
		icon: IconNames.STOPWATCH,
		classname: 'text-info',
	},
	UNKNOWN: {
		name: STRINGS.HEALTH_MONITORING_PAGE.healthStatus.unknown.label,
		icon: IconNames.DISABLE,
		classname: 'text-secondary',
	},
};

/** Renders the health monitoring top view.
 *  @param props the properties passed in.
 *  @returns JSX with the health monitoring top component.*/
const HealthMonitorCardsView = (): JSX.Element => {
	return (
		<React.Fragment>
			<CardsHolder
				className="pb-3"
				title={STRINGS.HEALTH_MONITORING_PAGE.overallStatus}
			>
				<CloudStatusCard />
				<AlluvioEdgesCard />
				<DataIngestCard />
				<AnalyticsCard />
				<IncidentGenerationCard />
				<RunbooksCard />
			</CardsHolder>
		</React.Fragment>
	);
};

export { HealthMonitorCardsView };
