/** This module contains the class that should be the base class for all Hyperion services.
 *  @module
 */
import axios from "axios";
import { IS_EMBEDDED } from "components/enums/QueryParams";
import { ApiService } from "./ApiService";

/** a constant with the base URI for all services. */
let embeddedUri = "";


function setAxiosInterceptor(accountId) {
    axios.interceptors.request.use(
        (config) => {
            config.headers["accountId"] = accountId;

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

const isRunbookViewRoute = window.location.pathname.includes('runbook-details');
const hasEmbeddedUri =  IS_EMBEDDED && isRunbookViewRoute;

if (hasEmbeddedUri) {
    const accountId = sessionStorage.getItem("accountId");

    if (accountId) {
        try {
            setAxiosInterceptor(accountId);
        } catch (error) {
            console.error(error);
        }
    }
}

const { BASE_API_URI } = hasEmbeddedUri ? embeddedUri : window["runConfig"];

/** a constant with the tenant management service. */
const TenantManagementService = new ApiService(BASE_API_URI);

export { TenantManagementService };
