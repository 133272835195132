import { Button } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import React from "react";

export function ContentOutdatedIndicator ({
    onReloadClicked
}: {
    /** Callback for when Reload button is clicked. If this param was not provided,
    only the change detected message will be displayed and the reload button will not be displayed */
    onReloadClicked?: () => void,
}) {
    return <span className="content-outdated-indicator">
        <span className="throb-slow">{STRINGS.autoUpdate.changeDetected}</span>
        {
            onReloadClicked && <Button
                onClick={() => {
                    onReloadClicked();
                }}
                text={STRINGS.autoUpdate.updateBtnText}
                icon={IconNames.REFRESH}
                minimal
                className="text-primary"
            />
        }
    </span>;
}