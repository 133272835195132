import { INCIDENT_STATUS_INDEX, PRIORITY_INDEX, SEVERITY, SEVERITY_INDEX } from "components/enums";
import { PARAM_NAME } from "components/enums/QueryParams";
import { getURLPath } from "config";
import { Severity } from "types";
import { getURL } from "utils/hooks/useQueryParams";
import { clearQueryParams } from 'utils/hooks/useQueryParams';

/** A util method to go through an array of severities and return the worst severity state */
export function getWorstStatus (items?: Array<Severity>) {
    let worstState = SEVERITY.UNKNOWN;
    if (items) {
        for (const severity of items) {
            const severityValue = severity.value || SEVERITY.UNKNOWN;
            const severityIndex = SEVERITY_INDEX[severityValue];
            if (severityIndex !== undefined && severityIndex > SEVERITY_INDEX[String(worstState)]) {
                worstState = severityValue;
            }
        }
    }
    return worstState;
}

/** A util method that can be used when sorting an array of records based on severity.
 * The records in the input array is expected to be of the format { severity: { value: <Severity>, ...}, ...}
 */
export function sortBasedOnSeverity (a, b) {
    const healthA = SEVERITY_INDEX[a?.severity?.value] || SEVERITY_INDEX.UNKNOWN;
    const healthB = SEVERITY_INDEX[b?.severity?.value] || SEVERITY_INDEX.UNKNOWN;
    return (healthA > healthB ? 1 : (healthB > healthA ? -1 : 0));
}

/** A util method that can be used when sorting an array of records based on priority.
 * The records in the input array is expected to be of the format { priority: <Priority>, ...}
 */
export function sortBasedOnPriority (a, b) {
    const priorityA = PRIORITY_INDEX[a?.priority?.toUpperCase()] || PRIORITY_INDEX.UNKNOWN;
    const priorityB = PRIORITY_INDEX[b?.priority?.toUpperCase()] || PRIORITY_INDEX.UNKNOWN;
    return (priorityA > priorityB ? 1 : (priorityB > priorityA ? -1 : 0));
}

/** A util method that can be used when sorting an array of records based on incident status.
 * The records in the input array is expected to be of the format { status: <IncidentStatus>, ...}
 */
export function sortBasedOnIncidentStatus (a, b) {
    const statusA = INCIDENT_STATUS_INDEX[a?.status?.toUpperCase()] || 0;
    const statusB = INCIDENT_STATUS_INDEX[b?.status?.toUpperCase()] || 0;
    return (statusA > statusB ? 1 : (statusB > statusA ? -1 : 0));
}

/** A util method that can be used when sorting an array of records based on a column
 * which has data of time format.
 */
 export function sortColumnWithTimeData (a, b, columnId) {
    if (b[columnId] && a[columnId]) {
        const difference = b[columnId].valueOf() - a[columnId].valueOf();
        if (difference > 0) {
            return -1;
        } else if (difference < 0) {
            return 1;
        } else {
            return 0;
        }
    } else if (b[columnId]) {
        return -1;
    } else if (a[columnId]) {
        return 1;
    } else {
        return 0;
    }
}
export interface SiteOnMap {
    id?: string;
    latitude?: number;
    longitude?: number;
}

export function getViewSiteInMapURL ({ id = undefined , latitude = undefined, longitude = undefined }: SiteOnMap = {}) {
    let targetURL = "";
    if (id && latitude && longitude) {
        targetURL = getURL(getURLPath("map"), {
            zoom: 5,
            b: "site",
            s: id,
            lat: latitude,
            lng: longitude,
        })
    }
    return targetURL;
};

export function encodeURIParams(value : object | string) {
    encodeURI(typeof value === "object" ? JSON.stringify(value) : (value === undefined ? "" : String(value)));
}

/** Generates a unique id by concatinating runbookid and widget id
 *  @param runbookId This identifies a specific runbook execution. Primarly used for sharing link to a specific runbook
 *  @param widgetId Identifies the specific widget in the runbook execution result
 */
export function computeWidgetContainerId(runbookId: string | undefined, widgetId: string | undefined) {
    return `${runbookId}-${widgetId}`;
}

/** Find html element by its id 
 *  @param id HTML element id. 
 */
export function findElement(id: string) {
    return window.document.getElementById(id);
}

/** Brings the html element in view.
 *  @param id HTML element id. 
 */
export function scrollToElement(id: string) {
    setTimeout(() => {
        let targetElement = findElement(id);
        if (targetElement) {
            targetElement.scrollIntoView({ block: "start", inline: "start", behavior: "smooth" });
            //Clear Query params if target element was found to prevent repetitive scrolling.
            clearQueryParams([PARAM_NAME.pageLocationId])
        }
    }, 100)
}

/** This method sets the native value and fires an event for the change.
 * This is so that we bubble a change event for this custom control which is
 * not one of the native input controls. We use this method to simulate a
 * change event happening which will bubble up and can be listened to. */
 export function setNativeValue (element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter?.call(element, value);
    } else {
        valueSetter?.call(element, value);
    }
    var event = new Event('input', { bubbles: true });
    element.dispatchEvent(event);
}
