/** This module contains the hook that is used to return and maintain the list of global filters.
 *  @module
 */
import { useEffect, useRef, useState } from 'react';
import { getAllFilters, setAllFilters, addChangeCallback, removeChangeCallback,
    FILTERS_OBJECT, SUPPORTED_FILTER_VALUE_TYPES } from '../stores/GlobalFiltersStore';
import { FILTER_NAME } from 'components/sdwan/enums';
import { isEqual } from 'lodash';
import { useSupportedFilters } from './useSupportedFilters';

/** this interface defines the properties that can be passed into the useGlobalFilters hook. */
export interface useGlobalFiltersProps {
    // If provided, this hook will cause a re-render of consuming component only when the value of one of these filters change
    listenOnlyTo?: FILTER_NAME[],
    // If provided, the output filters object will only include the filters in the exclusive list.
    exclusiveList?: FILTER_NAME[],
    // If provided, the output filters object will exclude these filters even if a value was present
    excludeList?: FILTER_NAME[]
}

/** this interface defines the return object from the useGlobalFilters hook. */
export interface useGlobalFiltersReturn {
    /** the current set of global filters. */
    filters: FILTERS_OBJECT;
    /** Sets the value for a single filter. Pass value as undefined to clear it.
     *  @param key a FILTER_NAME with the key for the filter in the filter object.
     *  @param value a SUPPORTED_FILTER_VALUE_TYPES object with the value for the key.
     *  @returns a FILTERS_OBJECT with the current set of filters. */
    setFilter: (key: FILTER_NAME, value?: SUPPORTED_FILTER_VALUE_TYPES) => FILTERS_OBJECT;
    /** Sets the values for multiple filters at the same time by passing an object of filter keys and values.
     *  @param partialFiltersObject a FILTERS_OBJECT with a subset of the global filters keys.  These filters
     *      will override the filters currently set as the global filters and will leave all filter keys that are
     *      not in this object as they are currently set.
     *  @returns the FILTERS_OBJECT with the current set of filters. */
    setMultipleFilters: (partialFiltersObj: FILTERS_OBJECT) => FILTERS_OBJECT;
    /** sets the current set of global filters.  This function is passed directly from the global filters store.
     *  @param newFilters the FILTERS_OBJECT with the new set of global filters.
     *  @returns the new filters object. */
    setAllFilters: (newFilters: FILTERS_OBJECT) => FILTERS_OBJECT;
    /** Clears a single filter.
     *  @param key the FILTER_NAME with the key of the filter to clear.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    clearFilter: (key: FILTER_NAME) => FILTERS_OBJECT;
    /** Pass an array of filter names to clear more than one filter at a time.
     *  @param keys the array of FILTER_NAMEs with all the keys that are to be cleared.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    clearMultipleFilters: (keys: FILTER_NAME[]) => FILTERS_OBJECT;
    /** Clears all filters from global store.
     *  @returns the FILTERS_OBJECT with the current set of filters. */
    clearAllFilters: () => FILTERS_OBJECT;
    /** Add one or more values to a filter while retaining any existing values.
     *  @param key the FILTER_NAME with the key that is to be added.
     *  @param value the SUPPORTED_FILTER_VALUE_TYPES object with the value of the key.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    addFilterValue: (key: FILTER_NAME, value: SUPPORTED_FILTER_VALUE_TYPES) => FILTERS_OBJECT;
    /** removes the specified filter value.
     *  @param key the FILTER_NAME with the key of the filter values that are to be removed.
     *  @param value the SUPPORTED_FILTER_VALUE_TYPES object with the values to be removed.  If this 
     *      value contains all the values currently set, then the filter for this key will be cleared, 
     *      otherwise only the values in the value object will be cleared and the rest will remain.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    removeFilterValue: (key: FILTER_NAME, value: SUPPORTED_FILTER_VALUE_TYPES) => FILTERS_OBJECT;
}

/** this function defines a hookd for keeping track of the global filters.
 *  @param props the properties object passed into the hook.
 *  @returns a useGlobalFiltersReturn object with the filters and utility functions. */
function useGlobalFilters (props?: useGlobalFiltersProps): useGlobalFiltersReturn {
    const [filters, setFilterFromStore] = useState<FILTERS_OBJECT>(getFilteredFilters("output"));
    const interestedFilters = useRef<FILTERS_OBJECT>(getFilteredFilters("listeners"));

    // using supported filters so that useGlobalFilters hook will re-render if supported filters change
    useSupportedFilters();

    useEffect(() => {
        // As long as this hook is mounted and active, listen to changes happening in
        // the global filters singleton store
        addChangeCallback(syncFiltersWithStore);
        return () => {
            // Unsubscribe the callback when unmounting
            removeChangeCallback(syncFiltersWithStore);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** returns the filters that are of the specified type.
     *  @param filterType a String with the type (either "listeners" or "output").
     *  @returns the FILTERS_OBJECT with the filters of the specified type. */
    function getFilteredFilters (filterType: "listeners" | "output") : FILTERS_OBJECT{
        const allFilters = getAllFilters();
        const exclusiveFilterList = props?.exclusiveList ? props.exclusiveList : Object.keys(allFilters);
        const excludeListMap = props?.excludeList ? props?.excludeList.reduce((map, filter) => { map[filter] = true; return map; }, {}) : undefined;
        const outputFilterList = excludeListMap ? exclusiveFilterList.filter(filter => !excludeListMap[filter]) : exclusiveFilterList;
        const listenerFilterList = props?.listenOnlyTo || outputFilterList;
        const extractionList = filterType === "listeners" ? listenerFilterList : outputFilterList;
        if (!props || !(props.listenOnlyTo || props.exclusiveList || props.excludeList)) {
            return allFilters;
        } else {
            let interestedFilters: FILTERS_OBJECT = {};
            for (const filterName of extractionList) {
                if (allFilters[filterName]) {
                    interestedFilters[filterName] = allFilters[filterName];
                }
            }
            return interestedFilters;
        }
    }

    /** syncs the filters with the GlobalFiltersStore. */
    function syncFiltersWithStore() {
        // Whenever a filter change happens, fetch a latest copy of the object and update
        // local state with it so that the consumer of this hook will get re-rendered
        const newInterestedFilters = getFilteredFilters("listeners");
        if (!isEqual(interestedFilters.current, newInterestedFilters)) {
            interestedFilters.current = newInterestedFilters;
            setFilterFromStore(getFilteredFilters("output"));
        }
    }

    /** Sets the value for a single filter. Pass value as undefined to clear it.
     *  @param key a FILTER_NAME with the key for the filter in the filter object.
     *  @param value a SUPPORTED_FILTER_VALUE_TYPES object with the value for the key.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    function setFilter(key: FILTER_NAME, value?: SUPPORTED_FILTER_VALUE_TYPES): FILTERS_OBJECT {
        let filtersCopy = Object.assign({}, getAllFilters());
        if (value === undefined || value === "") {
            delete filtersCopy[key];
        } else {
            filtersCopy[key] = value;
        }
        return setAllFilters(filtersCopy);
    }

    /** Add one or more values to a filter while retaining any existing values.
     *  @param key the FILTER_NAME with the key that is to be added.
     *  @param value the SUPPORTED_FILTER_VALUE_TYPES object with the value of the key.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    function addFilterValue (key: FILTER_NAME, value: SUPPORTED_FILTER_VALUE_TYPES): FILTERS_OBJECT {
        let newFilterValue;
        const allFilters = getAllFilters();
        const existingFilterValue = allFilters[key];
        if (existingFilterValue) {
            newFilterValue = Array.isArray(existingFilterValue) ? [...existingFilterValue] : [existingFilterValue];
            if (Array.isArray(value)) {
                for (const eachValue of value) {
                    if (!newFilterValue.includes(eachValue)) {
                        newFilterValue.push(eachValue);
                    }
                }
            } else {
                if (!newFilterValue.includes(value)) {
                    newFilterValue.push(value);
                }
            }
        } else {
            newFilterValue = value;
        }
        return setFilter(key, newFilterValue);
    }

    /** Clears a single filter.
     *  @param key the FILTER_NAME with the key of the filter to clear.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    function clearFilter (key: FILTER_NAME): FILTERS_OBJECT {
        return setFilter(key);
    }

    /** removes the specified filter value.
     *  @param key the FILTER_NAME with the key of the filter values that are to be removed.
     *  @param value the SUPPORTED_FILTER_VALUE_TYPES object with the values to be removed.  If this 
     *      value contains all the values currently set, then the filter for this key will be cleared, 
     *      otherwise only the values in the value object will be cleared and the rest will remain.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    function removeFilterValue (key: FILTER_NAME, value: SUPPORTED_FILTER_VALUE_TYPES): FILTERS_OBJECT {
        let valueToRemoveMap = {};
        let currentFilters = getAllFilters();
        const existingFilterValue = currentFilters[key];
        let response = currentFilters;
        if (existingFilterValue) {
            if (Array.isArray(value)) {
                for (const i of value) {
                    valueToRemoveMap[String(i)] = true;
                }
            } else {
                valueToRemoveMap[String(value)] = true;
            }
            let newValuesAfterRemoval:any = [];
            if (Array.isArray(existingFilterValue)) {
                for (value of existingFilterValue) {
                    if (!valueToRemoveMap[String(value)]) {
                        newValuesAfterRemoval.push(value);
                    }
                }
                if (newValuesAfterRemoval.length === 0) {
                    response = clearFilter(key);
                } else if (newValuesAfterRemoval.length === 1) {
                    response = setFilter(key, newValuesAfterRemoval[0]);
                } else if (newValuesAfterRemoval.length > 1) {
                    response = setFilter(key, newValuesAfterRemoval);
                }
            } else if (valueToRemoveMap[String(existingFilterValue)]) {
                response = clearFilter(key);
            }
        }
        return response;
    }

    /** Sets the values for multiple filters at the same time by passing an object of filter keys and values.
     *  @param partialFiltersObject a FILTERS_OBJECT with a subset of the global filters keys.  These filters
     *      will override the filters currently set as the global filters and will leave all filter keys that are
     *      not in this object as they are currently set.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    function setMultipleFilters(partialFiltersObj: FILTERS_OBJECT): FILTERS_OBJECT {
        let filtersCopy = Object.assign({}, getAllFilters(), partialFiltersObj);
        for (const filterName in partialFiltersObj) {
            if (partialFiltersObj[filterName] === undefined || partialFiltersObj[filterName] === "") {
                delete filtersCopy[filterName];
            }
        }
        return setAllFilters(filtersCopy);
    }

    /** Pass an array of filter names to clear more than one filter at a time.
     *  @param keys the array of FILTER_NAMEs with all the keys that are to be cleared.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    function clearMultipleFilters(keys: FILTER_NAME[]): FILTERS_OBJECT {
        let filtersCopy = Object.assign({}, getAllFilters());
        for (const filterName of keys) {
            delete filtersCopy[filterName];
        }
        return setAllFilters(filtersCopy);
    }

    /** Clears all filters from global store.
     *  @returns the FILTERS_OBJECT with the resultant set of filters. */
    function clearAllFilters(): FILTERS_OBJECT {
        return setAllFilters({});
    }

    return {
        filters,
        setFilter,
        setMultipleFilters,
        setAllFilters,
        clearFilter,
        clearMultipleFilters,
        clearAllFilters,
        addFilterValue,
        removeFilterValue
    };
}

export { useGlobalFilters, FILTER_NAME };
export type { FILTERS_OBJECT, SUPPORTED_FILTER_VALUE_TYPES };
