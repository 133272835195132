/**
 * This lists all the possible Event types that can exist.
 */

export enum GlobalEventTypes {
    TimeChange = "TIME_CHANGE",
}

export enum WidgetEventTypes {
    MetricChange = "METRIC_CHANGE",
    AppChange ="APP_CHANGE",
    SiteChange ="SITE_CHANGE",
    OverlayChange = "OVERLAY_CHANGE",
    ShowHideWidget="SHOW_HIDE_WIDGET",
    UnderlayChange = "UNDERLAY_CHANGE",
    DeviceChange = "DEVICE_CHANGE"
}
