import React, { ReactNode } from 'react';
import { CustomIconNames, StatusLED, StatusLEDProps } from 'components/common/status-led/StatusLED';
import { RoundedLinkButton } from 'components/common/rounded-link-button/RoundedLinkButton';
import { SIZE } from 'components/enums';

export interface BladeItemBriefProps extends StatusLEDProps {
    title?: string | ReactNode;
    count?: string | number;
    actionButtonLabel?: string;
    showActionButton?: boolean;
    onActionButtonClicked?: () => void;
    children?: ReactNode;
    noContentPadding?: boolean;
    className?: string;
}

function BladeItemBrief ({ title, count, icon = CustomIconNames.roundedBar, actionButtonLabel, showActionButton = false, onActionButtonClicked, noContentPadding, className, ...props}: BladeItemBriefProps) {
    let titleText;
    if (count !== undefined || title !== undefined || showActionButton) {
        titleText = <div className="d-inline-flex align-items-center"><span>{title}</span>
            { count !== undefined && <span className="ml-1">({count})</span> }
            { showActionButton && <RoundedLinkButton className="ml-1" size={SIZE.xs}
                onClick={() => { if (onActionButtonClicked) onActionButtonClicked(); }} text={actionButtonLabel} /> }
        </div>;
    }

    return <div className={"blade-item-brief" + (noContentPadding ? "" : " my-4") + (className ? " " + className : "")}>
        <StatusLED className="display-8 font-weight-600" icon={icon}
            label={titleText}
            {...props}
        />
        { props.children &&
            <div className="display-9 text-muted mt-1 ml-2">
                {props.children}
            </div>
        }
    </div>;
}

export { BladeItemBrief };
