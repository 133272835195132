/** This module contains a component that represents a condition operation.  A condition is 
 *  an expression like "key operator value", so this component represents the operator in 
 *  that expression.
 *  @module
 */
import {Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Icon } from "@tir-ui/react-components";
import React, { useEffect, useMemo } from "react";
import { operationItem, getDisplayValueForItem, getMatchingItemForValue, getIconForItem, getValueForItem, CONDITION_TREE_ICONS } from "../ConditionUtils";
import { isEqual } from 'lodash';
import { WrapFieldInDisplayMode } from "../../WrapFieldInDisplayMode";

/** this interface defines the properties passed into the ConditionOperation React component. */
export interface ConditionOperationProps {
    /** a string with the classes that should be applied to this component. */
    className?: string;
    /** Pass as true to make this field non-editable */
    readOnly?: boolean;
    /** Actual current value. This can either be the full keyItem or just the value string */
    value?: operationItem;
    /** List of options to display in dropdown. If this is not provided, a simple textbox will be used */
    options?: Array<operationItem>;
    /** onChange callback when user changes the condition operation either in the dropdown or the textbox as applicable */
    onChange?: (newOperation: operationItem) => void;
    /** A custom formatter that can be used for controlling how the displayed operation will be rendered */
    displayValueFormatter?: (props: { value?: operationItem }) => string | React.ReactNode;
    /** A custom renderer that can be used to completely replace the key's dropdown/textbox control with whatever you'd like */
    customRenderer?: (props) => React.ReactNode;
}

/** Renders the condition operation.  A condition is an expression like "key operator value", so this component represents 
 *      the operator in that expression.
 *  @param props the properties object passed in.
 *  @returns the JSX with the condition operation React component. */
export function ConditionOperation ({
    className,
    readOnly = false,
    value,
    options = [],
    onChange,
    displayValueFormatter,
    customRenderer,
}: ConditionOperationProps) {

    // If value and options were provided and no custom renderer was provided, find the matching selected option
    const selectedOption = useMemo(() => {
        if (!customRenderer && value && options.length > 0) {
            return getMatchingItemForValue(options, value);
        }
    }, [options, value, customRenderer]);

    // When mounting this component, if a value was provided but provided value was a simple string value which
    // matched a complex option object, then fire an onChange with the complex object. There could be logic
    // that's based on the complex object in one of it's parent components.
    useEffect(() => {
        if (value !== undefined && selectedOption && !isEqual(value, selectedOption) && onChange) {
            onChange(selectedOption);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classNames = "condition-operation add-padding" + (className ? " " + className : "");
    let outputJSX;

    if (customRenderer) {
        outputJSX = <div className={classNames}>
            {customRenderer({ value, options })}
        </div>;
    } else {
        const displayValue = displayValueFormatter ? displayValueFormatter({ value: selectedOption || value }) :
            (selectedOption ? getDisplayValueForItem(selectedOption) : (value && getDisplayValueForItem(value)));
        const displayIcon = selectedOption ? getIconForItem(selectedOption) : (value && getIconForItem(value));
        const hasOptions = options?.length > 0;
        const displayContent = <>
            {displayIcon && <Icon icon={displayIcon} iconSize={11} className="icon"/>}
            {displayValue}
        </>;

        if (readOnly) {
            outputJSX = <div className={classNames}>
                {displayContent}
            </div>;
        } else if (hasOptions) {
            outputJSX = <Popover2
                className={classNames}
                lazy
                autoFocus={false}
                enforceFocus={false}
                content={<Menu className="h-max-4 overflow-auto">{
                    options.map(item => {
                        const iconName = getIconForItem(item);
                        const text = getDisplayValueForItem(item);
                        const hint = (typeof item !== "string" && item.hint) ? <span className="display-9 ml-1">{item.hint}</span> : ""
                        return <MenuItem
                            key={"option-" + getValueForItem(item)}
                            text={hint ? <div className="d-flex justify-content-between align-items-center">{text}{hint}</div> : text}
                            className={typeof item === "string" ? undefined: item.className}
                            disabled={typeof item === "string" ? undefined: Boolean(item.disabled)}
                            active={selectedOption === item}
                            icon={iconName ? <Icon icon={iconName}/> : undefined}
                            onClick={() => {
                                if (onChange) {
                                    onChange(item);
                                } else {
                                    console.warn("No onChange handler provided for ConditionOperation control!. This control will always work in a controlled state and so value and onChange params should be provided to make it usable.")
                                }
                            }}
                        />;
                    })
                }</Menu>}
            >
                <span className="display-holder clickable">
                    {displayContent}
                    {hasOptions && <Icon icon={CONDITION_TREE_ICONS.DROPDOWN} iconSize={10} className="dropdown-icon"/>}
                </span>
            </Popover2>;
        } else {
            outputJSX = <div className={classNames}>
                <WrapFieldInDisplayMode
                    value={value}
                    onChange={onChange}
                    displayValueFormatter={displayValueFormatter ? ({ value }) => displayValueFormatter({ value }) : undefined}
                />
            </div>;
        }
    }
    return outputJSX;
}
