/** This file defines the BarView React component.  The BarView React component wraps a 
 *  BarChart component and includes the query to get the data.  The BarChart React component renders a 
 *  a basic bar chart with n-groups and one metric and alse renders a stacked bar chart with
 *  n-groups and m-metrics where the m-metrics must all be convertible to the same unit.
 *  @module */
import React from "react";
import { BarChart, BarData } from "components/common/bar-chart/BarChart";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { Column, BaseRunbookViewProps, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { formatKeys } from "utils/runbooks/RunbookFormatter";
import { formatValue, getComparisonParameters, keysMatch } from "utils/runbooks/RunbookOutputUtils";
import { BarOrientation, LegendPosition } from "components/common/chart-base/ChartToolbar";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
import { GroupMetricEvent } from "components/common/chart-base/ChartBase";

/** an interface that describes the properties that can be passed in to the component.*/
export interface BarViewProps extends BaseRunbookViewProps {
    // Add any properties that are specific to this view
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
}

/** Creates the bar chart view, which is a component that displays one bar chart with analytics data.
 *  @param props an object with the properties passed to the bar chart view.
 *  @returns JSX with the bar chart component.*/
export const BarView = (props: BarViewProps): JSX.Element => {
    let {comparisonSuffix = ""} = getComparisonParameters(props.widget?.options?.comparedTo as string);
    let primaryDataset: DataSet | undefined = undefined;
    let comparisonDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            } else {
                comparisonDataset = dataset;
            }
        }
    }

    let {loading, data, error} = useQuery({
        query: new Query(loader("../table-view/summary-data-query.graphql")),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
		},
        skipGlobalFilters: true
    });

    const orientation = props.widget?.options?.orientation === "vertical" ? BarOrientation.vertical : BarOrientation.horizontal;
    const showLegend = props.widget?.options?.showLegend !== undefined ? props.widget.options.showLegend as boolean : true;
    const legendPosition = props.widget?.options?.legendPosition ? props.widget?.options?.legendPosition as any : LegendPosition.top;
    const selectedMetrics: Array<string> | undefined = props.widget?.options?.metrics as Array<string> | undefined;

    let barData: Array<BarData> = [];
    let metrics: Array<string> = [];
    let metricIds: Array<string> = [];
    let units: Array<Unit> = [];
    if (primaryDataset?.datapoints) {
        data = {};
        data.datapoints = primaryDataset.datapoints;
    }
    if (!loading) {
        if (data && data.datapoints && primaryDataset?.keys && primaryDataset?.metrics) {
            const columnDefs: Array<Column> = primaryDataset.metrics;
            for (const columnDef of columnDefs) {
                if (selectedMetrics?.length && !selectedMetrics.includes(columnDef.id)) {
                    continue;
                }
                metrics.push(columnDef.label);
                metricIds.push(columnDef.id);
                units.push(Unit.parseUnit(columnDef.unit || ""));
            }

            for (const datapoint of data.datapoints) {
                const name = formatKeys(primaryDataset.keys, datapoint.keys) as string;
                const bar: BarData = {label: name, values: [], compValues: [], group: datapoint.keys?.group};
                for (const columnDef of columnDefs) {
                    if (selectedMetrics?.length && !selectedMetrics.includes(columnDef.id)) {
                        continue;
                    }
                    let tv: any = formatValue(datapoint.data[columnDef.id], columnDef);
                    bar.values.push(tv);
                    
                    // If there is comparison data add the comparison data
                    if (datapoint.keys && comparisonDataset?.datapoints) {
                        // There should only be one key so maybe we don't have to check this, let's do it anyway, it can't hurt
                        for (const checkDatapoint of comparisonDataset.datapoints) {
                            if (keysMatch(datapoint, checkDatapoint)) {
                                let compValue: any = formatValue(checkDatapoint.data[columnDef.id], columnDef);
                                bar.compValues!.push(compValue);
                                break;
                            }
                        }
                    }                        
                }
                
                barData.push(bar);
            }
        }
    }

    return <DataLoadFacade loading={loading || props.loading} error={error} data={data}>
        <BarChart barData={barData} metrics={metrics} metricIds={metricIds} units={units} transparent={props.transparent} 
            height={props.height} enableFullScreen={true} 
            settings={{
                showLegend, legendPosition, stacked: (props.widget?.options?.stackBars !== undefined ? props.widget.options.stackBars as boolean : false),
                orientation, showBarLabels: (props.widget?.options?.showBarLabels !== undefined ? props.widget.options.showBarLabels as boolean : true),
                showMore: true, showIconsOnHover: true
            }}
            comparisonSuffix={comparisonSuffix}
            fullScreenTitle={props.widget?.name} hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            onGroupMetricSelection={(event: GroupMetricEvent) => {
                if (props.onGroupsAndMetricsSelected) {
                    props.onGroupsAndMetricsSelected([...(event.groups || [])], [...(event.metrics || [])]);
                }
            }}
        />
    </DataLoadFacade>;
};
