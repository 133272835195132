/** This module creates a new node for the react-flow graph that represents a subflow node.
 *  @module
 */
 import React, { CSSProperties, useRef } from 'react';
import { Handle, Position, NodeProps, Connection, Edge } from 'react-flow-renderer';
import { STRINGS } from "app-strings";
import { APP_ICONS } from 'components/sdwan/enums';
import { BaseNodeContent } from '../BaseNodeContent';
import { defaultDirection } from '../../ReactFlowGraph';
import './SubflowNode.css';

// The base style for all the handles
const baseHandleStyle: CSSProperties = { background: '#b1b1b7' };

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params);

export const DEFAULT_SUBFLOW_COLOR = "#DDAA99";

/** Renders the subflow node component.
 *  @param props the properties passed in.
 *  @returns JSX with the sublow node component.*/
export default function SubflowNode({
    // Position of handle as a target node from a different source
    targetPosition = Position.Left,
    // Position of handle as a source node
    sourcePosition = Position.Right,
    ...props
}: NodeProps): JSX.Element {
    const data = props.data;
    const isConnectable = props.isConnectable;

    // Create the input ports
    const inputPorts: Array<JSX.Element> = [];
    if (props.data?.wires?.in) {
        const numPorts = props.data.wires.in.length;
        const increment = Math.floor(100 / numPorts);
        const cssPos = (defaultDirection === "LR" ? "top" : "left");
        const position = (defaultDirection === "LR" ? Position.Left : Position.Top);
        for (let index = 0; index < props.data.wires.in.length; index++) {
            const handleStyle = {...baseHandleStyle, [cssPos]: (Math.floor(increment / 2) + index * increment) + '%'
            };
            inputPorts.push(
                <Handle key={"input-handle-node-" + props.id + "-" + index} type="target" position={position} 
                    id={index.toString()} style={handleStyle} onConnect={onConnect} 
                />
            );
        }
    }

    // Create the output ports
    const className = "source-plus-handle";
    const outputPorts: Array<JSX.Element> = [];
    if (props.data?.wires?.out) {
        const numPorts = props.data.wires.out.length;
        const increment = Math.floor(100 / numPorts);
        const cssPos = (defaultDirection === "LR" ? "top" : "left");
        const position = (defaultDirection === "LR" ? Position.Right : Position.Bottom);
        for (let index = 0; index < numPorts; index++) {
            const handleStyle = {
                //...baseHandleStyle, 
                [cssPos]: (Math.floor(increment / 2) + index * increment) + '%'
            };
            outputPorts.push(
                <Handle key={"output-handle-node-" + props.id + "-" + index} type="source" position={position} 
                    id={index.toString()} style={handleStyle} isConnectable={isConnectable} 
                    className={className} 
                    onMouseUp={() => nodeRef.current.showMenu(index)}
                />
            );
        }
    }

    const name = (data.label ? data.label : STRINGS.runbookEditor.graph.unknownSubflow);

    const nodeRef = useRef<any>();

    return (<>
        <BaseNodeContent ref={nodeRef} sourcePosition={sourcePosition} targetPosition={targetPosition} {...props} defaultIcon={APP_ICONS.SUBFLOW} name={name}
            numOutputHandles={outputPorts.length}
            inputHandles={inputPorts} 
            outputHandles={outputPorts}
        />
    </>);
};
