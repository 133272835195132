/** This module contains the component for displaying a list of metrics/keys with appplicable 
 *  aggregate functions
 *  @module
 */
import React from 'react';
import { HTMLSelect } from '@blueprintjs/core';
import { Switch } from "@blueprintjs/core";
import { STRINGS } from 'app-strings';
import './AggregateFieldsControl.scss'

/** Interface that you could extend if in the future we want to use the AggregateControlComponent for other usescases
 *  Extend this interface and add addditional Attributes and pass it as a generic to AggregateControlComponent. */
export interface FunctionTemplate {
    id: string,
    label: string
}
/**Interface that describes an original Metric/Key*/
export interface OriginalField {
    id: string,
    /** Original field label */
    label: string,
    /** Is the field a Key or Metric */
    isKey: boolean,
    [k: string]: any
}
/**Interface that describes an AggregateField */
export interface AggregateField<T extends FunctionTemplate> {
    /** unique id */
    id: string,
    /** Labelfor the field that will be displayed */
    label: string,
    /** Selected aggregate function*/
    aggregatorFunction: string,
    /** List of all aggregate function applicable for a field */
    aggregatorFunctionOptions: T[],
    /** Original field that is being aggregated. Original label is also displayed*/
    originalField: OriginalField,
    /** Should the field be included/Excluded */
    included: boolean,
    /** User has edited the field */
    userModified: boolean,
    fieldType: string
}
/** Enum that describes the types of output events triggered by this component */
export enum UpdateEventType {
    SELECTION_CHANGED = "SELECTION_CHANGED",
    AGG_FUNCTION_CHANGED = "AGG_FUNCTION_CHANGED",
    NAME_CHANGED = "NAME_CHANGED"
}

/** Describe UpdateEvent, triggered when user edits one of the field*/
export interface UpdateEvent {
    id: string | number;
    type: UpdateEventType;
    value: any;
}
/** This interface defines the properties passed into the AggreateFiledsControl React component.*/
export interface AggregateFieldControlsProps<T extends FunctionTemplate> {
    /** Array of fields that need to aggregated. */
    aggregateFields: Array<AggregateField<T>> | undefined,
    /** Disabled/Enable all the fields */
    disabled?: boolean,
    /** Callback funtion that is triggered when a fields edited.*/
    onChange: Function,
};

/** Component for displaying a list of metrics/keys with appplicable aggregate functions.
 *  @param aggregateFields - Array of fields on which aggregate funcitons can be applied.
 *  @param onChange - callback function invoked when the a field is edited.
 *  @param disabled- callback function invoked when the expression is modified
 *  @return  JSX component. */
export function AggregateFieldsControl<T extends FunctionTemplate>({
    aggregateFields, onChange, disabled = true
}: AggregateFieldControlsProps<T>) {

    function getAggregatorFunctionOptions(field: AggregateField<T>) {
        return field.aggregatorFunctionOptions.map((item: FunctionTemplate) => {
            return { value: item.id, label: item.label };
        })
    }
    function generateControls() {
        let controls = aggregateFields?.map((field, index) => {
            return <div key={index} aria-label="aggregator field row" className="grid-container mt-1 mb-1">
                <Switch
                    className="mb-0 align-self-center"
                    aria-label="toggle button enable disable"
                    checked={field.included}
                    disabled={false}
                    onChange={(e) => {
                        onChange({
                            id: field.id,
                            type: UpdateEventType.SELECTION_CHANGED,
                            value: e.currentTarget.checked
                        })
                    }}
                />
                <HTMLSelect aria-label="aggregator function" name="aggFunc" className="flex-grow-2 exp-attr" options={getAggregatorFunctionOptions(field)} value={field.aggregatorFunction} onChange={(e) => {
                    onChange({
                        id: field.id,
                        type: UpdateEventType.AGG_FUNCTION_CHANGED,
                        value: e.currentTarget.value
                    })
                }} />
                <div className='mr-1 display-9 align-self-center'> {field.originalField.label} </div>
                <div className='mr-1 display-9 align-self-center'> {field?.label} </div>
            </div>
        });
        return controls;
    }
    const unconnectedNodeContent = <div className='display-8 d-flex justify-content-center pt-3'> {STRINGS.runbookEditor.nodeEditor.unconnectedNodeMessage2}</div>
    const fieldsContent = disabled ? unconnectedNodeContent : generateControls();
    return <div className="d-flex flex-column aggregate-control compare-control p-2 ">
        <div className='grid-container display-9 mb-3'>
            <div className='grid-item grid-item-1'> INCLUDE</div>
            <div className='grid-item grid-item-2'> OPERATION</div>
            <div className='grid-item grid-item-3'> ORIGINAL METRIC</div>
            <div className='grid-item grid-item-4'> AGGREGATED METRIC</div>
        </div>
        {fieldsContent}
    </div>;
}
