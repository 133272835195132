import { Button, IButtonProps } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { Outline, SIZE } from "components/enums";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./RoundedLinkButton.scss";
export interface RoundedLinkButtonProps extends IButtonProps {
    href?: string
    openInNewTab?: boolean
    showPivotButton?: boolean
    onPivotClicked?: () => void
    size?: SIZE.xs | SIZE.s | SIZE.m,
    className?: string,
    outline?: Outline
}

export function RoundedLinkButton ({
    className,
    outline = Outline.SHOW,
    size = SIZE.m,
    // You can either provide onClick or href. When href is provided, onClick will be ignored
    // openInNewTab, showPivotButton and onPivotClicked will apply only when href is provided
    href,
    openInNewTab = false,
    showPivotButton = false,
    onPivotClicked,
    ...props
}:RoundedLinkButtonProps) {
    const [hovered, setHovered] = useState(false);
    // If href was provided, use link from react-router-dom so that react router does the component switching without a page reload.
    if (href) {
        const linkElement = <Link
            to={href} className={"rounded-link-button size-" + size + (outline === Outline.SHOW ? " bp3-button bp3-outlined" : "") + (className ? " " + className : "")}
            onClick={() => { if (onPivotClicked) { onPivotClicked(); }}}
            target={openInNewTab ? "_blank" : undefined}
        >
            {props.text}
            {openInNewTab && <Icon icon={IconNames.SHARE} iconSize={10} className="align-top ml-1 mr-0"/>}
        </Link>;
        if (showPivotButton) {
            return <div>
                {linkElement}
                <Button icon={<Icon icon={IconNames.SHARE}/>} minimal={true} small={true} className="pivot-button"
                    onClick={e => { if (onPivotClicked) { onPivotClicked(); }}}/>
            </div>;
        } else {
            return linkElement;
        }
    } else {
        return <Button className={(className ? className + " " : "") + "rounded-link-button size-" + size} minimal={true}
            outlined={outline === Outline.SHOW ? true: (outline === Outline.SHOW_ON_HOVER ? hovered: false)} onMouseEnter={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)}
            {...props}
        />;
    }
};
