/** This module defines a React component that displays the impacted location search result.
 *  @module
 */
import React from "react";
import { SearchItem, SearchResultsItemProps } from "../search-results-item/SearchResultsItem";
import { LinkInfo, LinkType } from "../../search-links-group/LinksGroup";
import { STRINGS } from "app-strings";
import { useUserPreferences } from "utils/hooks";
import { SearchStandardItem } from "../search-standard-item/SearchStandardItem";
import { HIDDEN_STATES, INCIDENT_STATUS, PRIORITY } from "components/enums";
import { DEFAULT_SEARCH_PREF, SearchPreference } from "utils/services/UserPrefsTypes";
import { 
    getFacetValue, getImpactedLocationFacetForEngine, getPriorityFacetForEngine, getStatusFacetForEngine 
} from "pages/incident-search/IncidentSearchUtils";
import { FACET_FIELDS } from "utils/services/SearchApiService";

/** Renders the search result for the impacted location.
 *  @param data the properties passed in.
 *  @returns JSX with the impacted location search result react component.*/
export function SearchImpactedLocationItem({ data }: SearchResultsItemProps) {
    const userPreferences = useUserPreferences({
        listenOnlyTo: {search: {}}
    });
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};

    let modifiedData: SearchItem = data;
    try {
        modifiedData = JSON.parse(JSON.stringify(data));
        const impactedLocation: any = data.impactedLocation;
        if (impactedLocation) {
            modifiedData.formattedData.description = STRINGS.formatString(STRINGS.SEARCH.descImpactedLocation, {impactedLocation: impactedLocation.name});
        }    
    } catch (error) {
        console.error(error);
    } 

    const links: Array<LinkInfo> = [
        {
            path: "incident-search",
            label: STRINGS.SEARCH.viewIncidents,
            type: LinkType.PAGE,
            params: {
                searchType: "incident",
                facets: {
                    [getImpactedLocationFacetForEngine(searchPreferences.srchEngine)]: [data.formattedData.name],
                    [getPriorityFacetForEngine(searchPreferences.srchEngine)]: [
                        getFacetValue(PRIORITY.CRITICAL, searchPreferences.srchEngine), getFacetValue(PRIORITY.HIGH, searchPreferences.srchEngine), 
                        getFacetValue(PRIORITY.MODERATE, searchPreferences.srchEngine), getFacetValue(PRIORITY.LOW, searchPreferences.srchEngine)
                    ],
                    [getStatusFacetForEngine(searchPreferences.srchEngine)]: [
                        INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING, INCIDENT_STATUS.ON_HOLD, INCIDENT_STATUS.DISMISSED, INCIDENT_STATUS.CLOSED
                    ].filter(status => !HIDDEN_STATES.includes(status)).map(state => getFacetValue(state, searchPreferences.srchEngine))
                }
            }
        },
        {
            path: "incident-search",
            label: STRINGS.SEARCH.details,
            type: LinkType.PAGE,
            params: {
                searchType: "location",
                facets: {
                    [FACET_FIELDS.NAME]: [data.formattedData.title]
                },
                inspectorBladeOpen: true
            }
        }
    ];
    return <SearchStandardItem data={modifiedData} links={links} showCharts={false} showHealth={false} />;
}
