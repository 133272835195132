import React from 'react';

export interface ViewCollectionProps {
    activeView?: string
    defaultToFirstView?: boolean
    children: JSX.Element | JSX.Element[]
}

function ViewCollection ({activeView, defaultToFirstView = true, children}: ViewCollectionProps) {
    const activeViewKey = activeView;
    const activeViewIndex = (activeViewKey === undefined || activeViewKey === null || activeViewKey === "" )?
        (defaultToFirstView ? 0 : -1) :
        (isNaN(Number(activeViewKey)) ? (defaultToFirstView ? 0 : -1) : Number(activeViewKey));
    let activeViewComponent;
    React.Children.forEach(children, (child, index) => {
        if ((defaultToFirstView && index === 0) || (activeViewKey !== undefined && child?.key === activeViewKey) || index === activeViewIndex) {
            activeViewComponent = child;
        }
    });
    return activeViewComponent || <></>;
}

export { ViewCollection };
