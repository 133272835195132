/** This file defines the CorrelationChart React component.  The CorrelationChart React component renders a
 *  a basic correlation chart with n-groups and one metric.
 *  @module */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Classes, Dialog } from "@blueprintjs/core";
import { scaleMetric } from "reporting-infrastructure/utils/formatters";
import {
    CHART_SERIES_COLORS,
    CHART_COLORS,
    TIME_FORMAT,
} from "components/enums";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter";
import { HighchartsData } from "components/reporting/utils/Types";
import { cloneDeep, merge } from "lodash";
import { parseTimeFromDAL, useGlobalTime } from "utils/hooks";
import Highcharts, {
    AlignValue,
    OptionsLayoutValue,
    VerticalAlignValue,
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import OfflineExporting from "highcharts/modules/offline-exporting";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { BaseChartProps, GroupMetricSource } from "../chart-base/ChartBase";
import {
    ChartType,
    LegendPosition,
    CorrelationChartSettings,
    showSettingsDialog,
} from "../chart-base/ChartToolbar";
import { STRINGS } from "app-strings";
import { BASE_CHART_OPTIONS } from "components/reporting/charts/defaults/HighchartDefaults";
import {
    BasicDialog,
    updateDialogState,
} from "components/common/basic-dialog/BasicDialog";
import "components/common/chart-base/ChartBase.css";

// This is needed to enable the highcharts no data functionality
NoDataToDisplay(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

/** This interface defines the properties passed into the time series chart React component.*/
export interface CorrelationChartProps extends BaseChartProps {
    /** a boolean with the loading status. */
    loading?: boolean;
    /** The primary data which should have the same keys as the groupsKeyData object.*/
    primaryData?: Array<CorrelationChartDatum>;
    /** The comparison data which should have the same keys as the groupsKeyData object.*/
    compData?: Array<CorrelationChartDatum>;
    /** a boolean value, if true show the chart title.  If the value is false do not show the title.  If this property
     *  is undefined it is assumed to be true. */
    showChartSubtitle?: boolean;
    /** the CorrelationChartSetings object with the basic settings for the chart such as the style and legend position. */
    settings?: CorrelationChartSettings;
    /** the offset of the comparison data in seconds. */
    comparisonOffset?: number;
    /** the suffix to use in the legend when displaying comparison data. */
    comparisonSuffix?: string;
    /** A flag to force a reflow after a window resize happens */
    reflowOnResize?: boolean;
}

/** this interface defines the data structure for holding one line on the correlation chart. */
export interface CorrelationChartDatum {
    /** the name of the group, if any. */
    groupName?: string;
    /** the id of the group, if any. */
    groupId?: string;
    /** the name of the x-axis metric, if any. */
    xMetricName?: string;
    /** the id of the x-axis metric if any. */
    xMetricId?: string;
    /** the units for the x-axis data. */
    xUnit?: Unit;
    /** the name of the y-axis metric, if any. */
    yMetricName?: string;
    /** the id of the y-axis metric if any. */
    yMetricId?: string;
    /** the units for the y-axis data. */
    yUnit?: Unit;
    /** the time series data. */
    data?: HighchartsData;
    /** the data that is passed when there is a selection. */
    group?: any;
}

/** Renders the correlation chart React component.
 *  @param props the properties passed in.
 *  @returns JSX with the correlation chart component.*/
export function CorrelationChart(props: CorrelationChartProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const [settings, setSettings] = useState<CorrelationChartSettings>(
        props.settings || {},
    );
    const [dialogState, setDialogState] = useState({
        showDialog: false,
        loading: false,
        title: "",
        dialogContent: null,
        dialogFooter: null,
    });
    const handleSettingsOpen = useCallback(() => {
        showSettingsDialog(
            settings,
            ChartType.correlation,
            setDialogState,
            (action: ToolbarAction, value: any) => {
                if (action === ToolbarAction.SETTINGS_CHANGED) {
                    setSettings(value);
                }
            },
        );
    }, [settings]);

    const { absoluteTime } = useGlobalTime();
    const DEFAULT_SERIES_TEMPLATE = {
        type: "scatter",
        name: "",
        visible: true,
        showInLegend: true,
        color: "",
        data: [],
    };

    let xMetric: string | undefined;
    let xUnit: Unit | undefined;
    let yMetric: string | undefined;
    let yUnit: Unit | undefined;

    let seriesData: Array<any> = [];
    if (props.primaryData?.length) {
        let colorIndex = 0;
        const seriesColors = props.seriesColors
            ? props.seriesColors
            : CHART_SERIES_COLORS;
        for (const datum of props.primaryData) {
            const chartColor = seriesColors[colorIndex]
                ? seriesColors[colorIndex]
                : seriesColors[0];
            let tsData: HighchartsData = cloneDeep(datum.data || []);

            if (!xMetric) {
                xMetric = datum.xMetricName;
                xUnit = datum.xUnit;
                yMetric = datum.yMetricName;
                yUnit = datum.yUnit;
            }

            let seriesTempData = cloneDeep(DEFAULT_SERIES_TEMPLATE);
            seriesTempData.name = datum.groupName;
            seriesTempData.color = chartColor;
            seriesTempData.data = tsData;
            seriesTempData.xMetric = datum.xMetricName;
            seriesTempData.xUnit = datum.xUnit;
            seriesTempData.yMetric = datum.yMetricName;
            seriesTempData.yUnit = datum.yUnit;
            seriesTempData.groupData = datum.group;
            seriesTempData.metricData = [datum.xMetricId, datum.yMetricId];
            if (props.onGroupMetricSelection) {
                seriesTempData.events = {
                    click: (event) => {
                        const selected = !event.point.selected;
                        event.point.select(selected, false);
                        props.onGroupMetricSelection!({
                            source: GroupMetricSource.SERIES,
                            selected,
                            groups: [event.point.series.userOptions.groupData],
                            metrics:
                                event.point.series.userOptions.metricData || [],
                        });
                    },
                    legendItemClick: (event) => {
                        const selected = !event.target.selected;
                        event.target.select(selected, false);
                        props.onGroupMetricSelection!({
                            source: GroupMetricSource.LEGEND,
                            selected,
                            groups: [event.target.userOptions.groupData],
                            metrics: event.target.userOptions.metricData,
                        });
                    },
                };
            }
            seriesData.push(seriesTempData);
            colorIndex++;

            if (props.compData?.length) {
                for (const compDatum of props.compData) {
                    if (datum.groupName === compDatum.groupName) {
                        let tsData: HighchartsData = cloneDeep(compDatum.data);

                        let compColor = new Highcharts.Color(
                            seriesTempData.color,
                        )
                            .setOpacity(0.4)
                            .get();
                        let compSeries = cloneDeep(seriesTempData);
                        compSeries.name =
                            datum.groupName + " - " + props.comparisonSuffix ||
                            "";
                        compSeries.color = compColor;
                        compSeries.data = tsData;
                        compSeries.xMetric = compDatum.xMetricName;
                        compSeries.xUnit = compDatum.xUnit;
                        compSeries.yMetric = compDatum.yMetricName;
                        compSeries.yUnit = compDatum.yUnit;
                        compSeries.comparisonOffset = props.comparisonOffset;
                        compSeries.comparisonSuffix = props.comparisonSuffix;
                        compSeries.groupData = compDatum.group;
                        compSeries.metricData = [
                            compDatum.xMetricId,
                            compDatum.yMetricId,
                        ];
                        seriesData.push(compSeries);
                    }
                }
            }
        }
    }
    let seriesOptions = seriesData;

    const showSubtitle =
        props.showChartSubtitle !== null &&
        props.showChartSubtitle !== undefined
            ? props.showChartSubtitle
            : true;
    const chartRef = useRef<HighchartsReact.RefObject>(null);

    // For cases where highcharts was having trouble resizing the time series chart,
    // the reflowOnResize prop can be used to force a reflow half a second after the
    // initial resize occurred. Try to use this only as a last resort if it was not
    // possible to fix the behavior with other layout changes.
    useEffect(() => {
        function onResize() {
            setTimeout(() => {
                if (chartRef.current) {
                    chartRef.current.chart.reflow();
                }
            }, 500);
        }
        if (props.reflowOnResize) {
            window.addEventListener("resize", onResize);
            return () => {
                window.removeEventListener("resize", onResize);
            };
        }
    }, [props.reflowOnResize]);

    const getChart = (popup: boolean = false) => {
        return (
            <div
                aria-label="correlationMetrics card"
                className={
                    popup
                        ? Classes.DIALOG_BODY
                        : "flex correlationChart" +
                          (props.transparent ? "" : " bg-light") +
                          (props.transparent || props.hideShadow
                              ? ""
                              : " shadow")
                }
            >
                <HighchartsReact
                    // Needed to set immutable to true to get the setSettings state change to refresh all settings.
                    // For some reason the step attribute did not clear without making this immutable.
                    highcharts={Highcharts}
                    immutable={true}
                    options={getChartOptions(
                        seriesOptions,
                        absoluteTime,
                        showSubtitle,
                        xMetric || "",
                        xUnit,
                        yMetric || "",
                        yUnit,
                        settings,
                        props.options,
                        handleOpen,
                        handleSettingsOpen,
                        props.fullScreenTitle,
                    )}
                    containerProps={{
                        style: {
                            width: props.width ? props.width : "100%",
                            height: popup
                                ? 0.9 * window.innerHeight - 40 + "px"
                                : props.height
                                  ? props.height
                                  : "100%",
                            padding: popup ? "10px" : "",
                        },
                    }}
                    ref={chartRef}
                />
            </div>
        );
    };

    return (
        <>
            <BasicDialog
                dialogState={dialogState}
                onClose={() =>
                    setDialogState(
                        updateDialogState(dialogState, false, false, []),
                    )
                }
            />
            <Dialog
                title={props.fullScreenTitle ? props.fullScreenTitle : ""}
                isOpen={isOpen}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                onClose={handleClose}
                style={{
                    width: 0.75 * window.innerWidth,
                    height: 0.9 * window.innerHeight,
                }}
            >
                {getChart(true)}
            </Dialog>
            {getChart(false)}
        </>
    );
}

/** returns the time chart options for the specified series.  This function merges the default time
 *      chart options with the options specific to this chart.
 *  @param seriesData the data series to put in the time chart.
 *  @param absoluteTime the time to use in the chart.
 *  @param showSubtitle specifies whether or not to show the subtitle.
 *  @param xMetric a String with the label for the x-axis Metric.
 *  @param xUnit a Unit object with the units for the x-axis Metric.
 *  @param yMetric a String with the label for the y-axis Metric.
 *  @param yUnit a Unit object with the units for the y-axis Metric.
 *  @param settings the CorrelationChartSettings object with some of the settings for the chart like the style and legend position.
 *  @param options additional options that should be merged into the chart options.
 *  @returns the chart options for the specified series.*/
function getChartOptions(
    seriesData,
    absoluteTime,
    showSubtitle: boolean,
    xMetric: string,
    xUnit: Unit | undefined,
    yMetric: string,
    yUnit: Unit | undefined,
    settings: CorrelationChartSettings,
    options: Highcharts.Options | undefined,
    handleOpen: () => void,
    handleSettingsOpen: () => void,
    chartTitle: string = "",
): Highcharts.Options {
    const { showLegend = true, legendPosition = LegendPosition.top } = settings;

    let legendLayout = "horizontal";
    let legendAlign = "left";
    let legendVerticalAlign = "top";
    switch (legendPosition) {
        case LegendPosition.top:
            // Defaults are set for top, nothing to do
            break;
        case LegendPosition.bottom:
            legendVerticalAlign = "bottom";
            break;
        case LegendPosition.left:
            legendLayout = "vertical";
            break;
        case LegendPosition.right:
            legendLayout = "vertical";
            legendAlign = "right";
            break;
    }

    let optionsCopy: Highcharts.Options = Highcharts.merge(BASE_CHART_OPTIONS, {
        chart: {
            height: null,
            width: null,
            type: "scatter",
            reflow: true,
            backgroundColor: "transparent",
            alignTicks: false,
            spacingRight: 8,
            spacingLeft: 0,
            events: {},
        },
        exporting: {
            enabled: true,
            filename: `${chartTitle ? chartTitle : "correlationchart"}-riverbed`,
            buttons: {
                contextButton: {
                    menuItems: [
                        {
                            text: STRINGS.chartToolbar.toggleFullScreen,
                            onclick: () => {
                                handleOpen();
                            },
                        },
                        {
                            text: STRINGS.chartToolbar.settingsMenuItem,
                            onclick: () => {
                                handleSettingsOpen();
                            },
                        },
                        "downloadCSV",
                        "downloadPNG",
                    ],
                },
            },
            chartOptions: {
                chart: {
                    backgroundColor: "#fff",
                },
            },
        },
        subtitle: {
            style: {
                fontWeight: "bold",
            },
        },
        legend: {
            enabled: showLegend,
            //lineHeight: 8,
            symbolRadius: 0,
            layout: legendLayout as OptionsLayoutValue,
            align: legendAlign as AlignValue,
            verticalAlign: legendVerticalAlign as VerticalAlignValue,
            floating: false,
            x: 0, //85
            y: 0,
            symbolHeight: 12,
            symbolWidth: 12,
            labelFormatter: function (this: any) {
                if (this && this.name) {
                    return this.name;
                }
            },
            itemStyle: {
                color: CHART_COLORS.LEGEND_DEFAULT,
            },
            itemHoverStyle: {
                color: CHART_COLORS.LEGEND_DEFAULT,
            },
        },
        xAxis: {
            type: "linear",
            visible: true,
            crosshair: {
                snap: true,
                width: 1,
            },
            title: {
                text: getAxisLabel(xMetric, xUnit),
            },
            labels: {
                overflow: "justify",
                formatter: function (this: any) {
                    return scaleMetric(this.value, new Unit()).formatted;
                },
            },
        },
        yAxis: {
            type: "linear",
            startOnTick: false,
            endOnTick: false,
            visible: true,
            title: {
                text: getAxisLabel(yMetric, yUnit),
            },
            labels: {
                overflow: "justify",
                formatter: function (this: any) {
                    return scaleMetric(this.value, new Unit()).formatted;
                },
            },
        },
        title: {
            text: "",
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            scatter: {
                allowPointSelect: true,
            },
            series: {
                color: "#88C",
                marker: {
                    enabled: true,
                    symbol: "square",
                    radius: 2,
                    // This was used to get the time chart to show square markers in the legend, but it
                    // messed up the tooltips
                    // enabled: undefined,
                    // symbol: 'square',
                    // radius: 12,
                    // enabledThreshold: 1000
                },
                //disable legend click
                events: {
                    legendItemClick: function (event) {
                        return undefined;
                    },
                },
                //fillOpacity: 0.2,
                //lineWidth: 2,
                // Two days of 1 minute data
                turboThreshold: 2 * 1440,
            },
        },
        series: [
            {
                type: "scatter",
                data: [],
                animation: {
                    duration: 500,
                },
                borderRadius: 4,
                borderColor: "transparent",
                maxPointWidth: 10,
            },
        ],
        tooltip: {
            enabled: true,
            backgroundColor: "white",
            shared: true,
            split: false,
            useHTML: true,
            formatter: function (this: any) {
                const compSuffix = this.series.options.comparisonSuffix;
                let compText = "";
                if (compSuffix) {
                    compText =
                        "<br /><b><span>" + compSuffix + "</span></b> : ";
                }

                const xUnit = this.series.options.xUnit
                    ? this.series.options.xUnit
                    : new Unit();
                const xMetric = this.series.options.xMetric
                    ? this.series.options.xMetric
                    : new Unit();
                const yUnit = this.series.options.yUnit
                    ? this.series.options.yUnit
                    : new Unit();
                const yMetric = this.series.options.yMetric
                    ? this.series.options.yMetric
                    : new Unit();
                const symbol = "&#9632;";
                let toolTip =
                    '<div><span style="font-size:16px;color:' +
                    this.point.color +
                    '">' +
                    symbol +
                    "</span>" +
                    compText +
                    "<b><span> " +
                    xMetric +
                    "</span></b> : <b>" +
                    scaleMetric(this.point.x, xUnit).formatted +
                    "</b> vs " +
                    "<b><span> " +
                    yMetric +
                    "</span></b> : <b>" +
                    scaleMetric(this.point.y, yUnit).formatted +
                    "</b></div>";
                return toolTip;
            },
        },
        time: {
            useUTC: false,
        },
        //Display when chartData:{data:[]}
        lang: {
            noData: STRINGS.no_data_to_display,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "13px",
            },
        },
    });

    merge(optionsCopy, {
        series: seriesData,
    });
    if (showSubtitle) {
        const parsedStartTime = parseTimeFromDAL(absoluteTime.startTime);
        const parsedEndTime = parseTimeFromDAL(absoluteTime.endTime);
        // If both start and end time are valid
        if (parsedStartTime && parsedEndTime) {
            optionsCopy.subtitle!.text =
                formatToLocalTimestamp(
                    parsedStartTime,
                    TIME_FORMAT.DISPLAY_24HOUR_FORMAT,
                ) +
                " - " +
                formatToLocalTimestamp(
                    parsedEndTime,
                    TIME_FORMAT.DISPLAY_24HOUR_FORMAT,
                );
        }
    }
    if (options) {
        merge(optionsCopy, options);
    }
    return optionsCopy;
}

/** returns the series name depending on whether this chart is showing multiple groups, multiple metrics or both
 *  @param datum the CorrelationChartDatum with the time series data.
 *  @param isMultiGroup a boolean, true if there are more than one group on the chart.
 *  @param isMultiMetric a boolean, true if there is more than one metric on the chart.
 *  @param seriesCount the number of series on the axis, if it is multi-metric but only one series, continue to show
 *      the metric name.
 *  @returns a String with the name for the series. */
function getAxisLabel(metricName: string, unit?: Unit): string {
    const axisUnit = unit?.clone();
    if (axisUnit && axisUnit.getDisplayName() !== "ms") {
        axisUnit.prefix = "";
    }
    return (
        metricName +
        " " +
        (axisUnit && axisUnit.getDisplayName() !== ""
            ? "(" + axisUnit.getDisplayName() + ")"
            : "")
    );
}
