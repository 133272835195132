import { IconNames } from "@tir-ui/react-components";
import { ConditionOperationProps } from "./condition-operation/ConditionOperation";
import { ConditionValueProps } from "./condition-value/ConditionValue";
import { isEqual } from 'lodash';
import { ConditionTree } from "../ConditionTreeBuilder";
import { ConditionBlockValue, ConditionRowOrBlockValue, ConditionRowValue } from "../condition-block/ConditionBlock";

export const CONDITION_TREE_ICONS = {
    DROPDOWN: IconNames.CARET_DOWN,
    NEST_BLOCK: IconNames.KEY_ENTER,
    DENEST_BLOCK: IconNames.SMALL_CROSS,
    DELETE: IconNames.CROSS,
    ADD: IconNames.PLUS,
}
export interface selectComplexItem {
    display: string;
    value: string;
    icon?: string;
    disabled?: boolean;
    clasName?: string;
    [key: string]: any;
};

interface keyComplexItem extends selectComplexItem {
    operationNotReqd?: boolean;
    valueNotReqd?: boolean;
    operationProps?: ConditionOperationProps;
    valueProps?: ConditionValueProps;
}
interface operationComplexItem extends selectComplexItem {
    valueNotReqd?: boolean;
    valueProps?: ConditionValueProps;
}

export type selectItem = selectComplexItem | string;
export type keyItem = keyComplexItem | string;
export type operationItem = operationComplexItem | string;

/** A method to extract and return the appropriate display value */
export function getDisplayValueForItem (item: selectItem): string {
    if (typeof item === "string") {
        return item;
    } else {
        return item.display;
    }
}

/** A method to extract and return just the value from a selectItem  */
export function getValueForItem (item: selectItem): string {
    if (typeof item === "string") {
        return item;
    } else {
        return item.value;
    }
}

/** A method to extract and return the icon property from a selectItem if
 * it is of the complex type and the icon property exists */
export function getIconForItem (item: selectItem): string | undefined {
    if (typeof item === "string") {
        return undefined;
    } else {
        return item.icon;
    }
}

/** This method normalizes a selectItem which could be a string or a selectComplexItem
 * and returns a selectComplexItem in both cases. */
export function normalizeItem (item: selectItem): selectComplexItem {
    if (typeof item === "string") {
        return {
            display: item,
            value: item,
        };
    } else {
        return item;
    }
}

/** An easy method to find and return the matching item from a list of items
 * by comparing it's value to a value provided as input */
export function getMatchingItemForValue (items: Array<selectItem>, value: selectItem): selectItem | undefined {
    const valueToCompare = getValueForItem(value);
    return items.find(item => {
        return getValueForItem(item) === valueToCompare;
    });
}

/** A simple method for generating a random ID string */
export function generateRandomID (): string {
    // return new Date().getTime();
    return String(Math.floor(1000 + Math.random() * 9000));
}

/** Takes in a list of items, and adds random IDs to those items that
 * don't have an `id` property provided already */
 export function applyIdsToItems (items: Array<ConditionRowOrBlockValue>) {
    return items.map((item, index) => {
        if (item.id === undefined) {
            return {
                ...item,
                id: String(index) + generateRandomID(),
            };
        } else {
            return item;
        }
    });
}

/** A method that compares to given condition objects and returns a boolean
 * that indicates if they can be considered equal or not. */
export function areConditionsEqual (conditionA: ConditionRowValue, conditionB: ConditionRowValue): boolean {
    let equal = true;
    if (
        conditionA.id !== conditionB.id ||
        conditionA.category !== conditionB.category ||
        getValueForItem(conditionA.conditionKey || "") !== getValueForItem(conditionB.conditionKey || "") ||
        getValueForItem(conditionA.operation || "") !== getValueForItem(conditionB.operation || "") ||
        !isEqual(conditionA.value, conditionB.value)
    ) {
        equal = false;
    }
    return equal;
}

/** A method that compares to given condition block value objects and returns
 * a boolean that indicates if they can be considered equal or not. This method
 * goes deep into the condition tree structure and does a thorough comparison */
export function areConditionBlocksEqual (itemA: ConditionRowOrBlockValue | ConditionBlockValue["conditions"], itemB: ConditionRowOrBlockValue | ConditionBlockValue["conditions"]): boolean {
    let equal = true;
    if (Array.isArray(itemA) && Array.isArray(itemB)) {
        if (itemA.length !== itemB.length) {
            equal = false;
        } else {
            for (let i = 0; i < itemA.length; i++) {
                if (!areConditionBlocksEqual(itemA[i] as ConditionRowOrBlockValue, itemB[i] as ConditionRowOrBlockValue)) {
                    equal = false;
                    break;
                }
            }
        }
    } else if ((Array.isArray(itemA) && !Array.isArray(itemB)) || (Array.isArray(itemB) && !Array.isArray(itemA))) {
        equal = false;
    } else {
        // If both items are blocks, compare their top-level props and also go deeper and compare every
        // sub-item under the conditions list for both blocks
        if ((itemA as any).conditions !== undefined && (itemB as any).conditions !== undefined) {
            const blockItemA = itemA as ConditionBlockValue;
            const blockItemB = itemB as ConditionBlockValue;
            if (blockItemA.id !== blockItemB.id || blockItemA.blockType !== blockItemB.blockType) {
                equal = false;
            } else if (!areConditionBlocksEqual(blockItemA.conditions, blockItemB.conditions)) {
                equal = false;
            }
        // If one of the items is a block and the other one is a condition
        } else if (
            ((itemA as any).conditions !== undefined && (itemB as any).conditions === undefined) ||
            ((itemB as any).conditions !== undefined && (itemA as any).conditions === undefined)
        ) {
            equal = false;
        // If both items are condition row items, then compare them.
        } else if (!areConditionsEqual(itemA as ConditionRowValue, itemB as ConditionRowValue)) {
            equal = false;
        }
    }
    return equal;
}

/** A sugar method that walks the condition tree and finds out if there are more than one 
 * condition rows. Not condition blocks but individual condition rows. */
export function conditionTreeHasMoreThanOneCondition (conditionTree: ConditionTree): boolean {
    let hasMoreThanOneCondition = false;
    if (conditionTree) {
        if (conditionTree.conditions.length > 1) {
            hasMoreThanOneCondition = true;
        } else if (conditionTree.conditions.length === 1) {
            const firstConditionItem = conditionTree.conditions[0];
            if (isConditionBlock(firstConditionItem)) {
                hasMoreThanOneCondition = conditionTreeHasMoreThanOneCondition(firstConditionItem as ConditionTree);
            }
        }
    }
    return hasMoreThanOneCondition;
}

/** A method that checks if given input, which could be a condition row or
 * condition block, is of a condition block type */
export function isConditionBlock (conditionItem: ConditionRowOrBlockValue) {
    return (conditionItem as any).blockType !== undefined;
}

/** A sugar method that can be used if needed to strip the conditionTree of
 * all properties that are meant to support the inner workings of condition tree builder.
 * The output will be a simple tree that's indicative of the condition structure */
export function getPropFreeConditionTree (input: ConditionTree | ConditionRowOrBlockValue | ConditionRowOrBlockValue[]) {
    if (Array.isArray(input)) {
        return input.map(condition => {
            return getPropFreeConditionTree(condition);
        });
    } else {
        const { conditionProps, conditionBlockProps, conditions, ...props } = input as any;
        return {
            ...props,
            ...(conditions ? { conditions : getPropFreeConditionTree(conditions) } : {}),
        };
    }
}

/** A method to walk the condition tree and find an item in the tree by matching it's ID
 * and return both the item and it's depth in the tree */
export function findItemInTree (tree: ConditionTree | ConditionRowOrBlockValue, itemId: string, currentDepth: number = 0) {
    if (tree.id === itemId) {
        return {
            depth: currentDepth,
            item: tree,
        };
    } else if (isConditionBlock(tree)) {
        for (const condition of (tree as ConditionBlockValue).conditions) {
            const itemFound = findItemInTree(condition, itemId, currentDepth + 1);
            if (itemFound) {
                return itemFound;
            }
        }
    }
}