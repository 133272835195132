/** This module contains the enums and constants for the indicator types as well as 
 *  several useful utility functions involving indicator types.
 *  @module
 */
import { Intent } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";

/** an enum which defines all the valid indicator types */
export enum INDICATOR_TYPE {
    TOO_LOW                     = "TOO_LOW",
    TOO_HIGH                    = "TOO_HIGH",
    ABNORMALLY_LOW              = "ABNORMALLY_LOW",
    ABNORMALLY_HIGH             = "ABNORMALLY_HIGH",
    FAULT_DOWN                  = "FAULT_DOWN",
    FAULT_DEGRADED              = "FAULT_DEGRADED",
    FAULT_CHANGE                = "FAULT_CHANGE",
    DYNAMIC_THRESHOLD_TOO_LOW   = "DYNAMIC_THRESHOLD_TOO_LOW",
    DYNAMIC_THRESHOLD_TOO_HIGH  = "DYNAMIC_THRESHOLD_TOO_HIGH",
    /** the enumerated value for the not increasing indicator, this indicator detects if the uptime goes to 0 in an interval. */
    NOT_INCREASING              = "NOT_INCREASING",
}

/** a constant which maps the indicator enum value to a label. */
export const INDICATOR_TO_LABEL_MAP: Record<string, string> = {
    [INDICATOR_TYPE.TOO_LOW]:                       STRINGS.incidents.indicatorTypes.too_low,
    [INDICATOR_TYPE.TOO_HIGH]:                      STRINGS.incidents.indicatorTypes.too_high,
    [INDICATOR_TYPE.ABNORMALLY_LOW]:                STRINGS.incidents.indicatorTypes.abnormally_low,
    [INDICATOR_TYPE.ABNORMALLY_HIGH]:               STRINGS.incidents.indicatorTypes.abnormally_high,
    [INDICATOR_TYPE.FAULT_DOWN]:                    STRINGS.incidents.indicatorTypes.fault_down,
    [INDICATOR_TYPE.FAULT_DEGRADED]:                STRINGS.incidents.indicatorTypes.fault_degraded,
    [INDICATOR_TYPE.FAULT_CHANGE]:                  STRINGS.incidents.indicatorTypes.fault_change,
    [INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_LOW]:     STRINGS.incidents.indicatorTypes.dynamic_threshold_too_low,
    [INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_HIGH]:    STRINGS.incidents.indicatorTypes.dynamic_threshold_too_high,
    [INDICATOR_TYPE.NOT_INCREASING]:                STRINGS.incidents.indicatorTypes.not_increasing,
}

/** a constant which maps the indicator enum value to a terse label. */
export const INDICATOR_TO_TERSE_LABEL_MAP: Record<string, string> = {
    [INDICATOR_TYPE.TOO_LOW]:                       STRINGS.incidents.indicatorTypes.too_low_terse,
    [INDICATOR_TYPE.TOO_HIGH]:                      STRINGS.incidents.indicatorTypes.too_high_terse,
    [INDICATOR_TYPE.ABNORMALLY_LOW]:                STRINGS.incidents.indicatorTypes.abnormally_low_terse,
    [INDICATOR_TYPE.ABNORMALLY_HIGH]:               STRINGS.incidents.indicatorTypes.abnormally_high_terse,
    [INDICATOR_TYPE.FAULT_DOWN]:                    STRINGS.incidents.indicatorTypes.fault_down_terse,
    [INDICATOR_TYPE.FAULT_DEGRADED]:                STRINGS.incidents.indicatorTypes.fault_degraded_terse,
    [INDICATOR_TYPE.FAULT_CHANGE]:                  STRINGS.incidents.indicatorTypes.fault_change_terse,
    [INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_LOW]:     STRINGS.incidents.indicatorTypes.dynamic_threshold_too_low_terse,
    [INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_HIGH]:    STRINGS.incidents.indicatorTypes.dynamic_threshold_too_high_terse,
    [INDICATOR_TYPE.NOT_INCREASING]:                STRINGS.incidents.indicatorTypes.not_increasing_terse,
}

/** a constant which maps the indicator enum value to an icon. */
export const INDICATOR_TO_ICON_MAP: Record<string, { icon: string, className?: string, intent?: Intent, bgColor?: string }> = {
    [INDICATOR_TYPE.TOO_LOW]:                       { icon: IconNames.TRENDING_DOWN, intent: Intent.DANGER, bgColor: "#fcda5d" },
    [INDICATOR_TYPE.TOO_HIGH]:                      { icon: IconNames.TRENDING_UP, intent: Intent.DANGER, bgColor: "#c74141" },
    [INDICATOR_TYPE.ABNORMALLY_LOW]:                { icon: IconNames.TRENDING_DOWN, intent: Intent.WARNING, bgColor: "#c74141" },
    [INDICATOR_TYPE.ABNORMALLY_HIGH]:               { icon: IconNames.TRENDING_UP, intent: Intent.WARNING, bgColor: "#c74141" },
    [INDICATOR_TYPE.FAULT_DOWN]:                    { icon: IconNames.FLAME, intent: Intent.DANGER, bgColor: "#c74141" },
    [INDICATOR_TYPE.FAULT_DEGRADED]:                { icon: IconNames.WARNING_SIGN, intent: Intent.WARNING, bgColor: "#c74141" },
    [INDICATOR_TYPE.FAULT_CHANGE]:                  { icon: IconNames.WARNING_SIGN, intent: Intent.WARNING, bgColor: "#c74141" },
    [INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_LOW]:     { icon: IconNames.TRENDING_DOWN, intent: Intent.DANGER, bgColor: "#fcda5d" },
    [INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_HIGH]:    { icon: IconNames.TRENDING_UP, intent: Intent.DANGER, bgColor: "#c74141" },
    [INDICATOR_TYPE.NOT_INCREASING]:                { icon: IconNames.FLAME, intent: Intent.DANGER, bgColor: "#c74141" },
}

/** returns true if the indicator type supports baselines, false otherwise.
 *  @param type the indicator type (kind) to check.
 *  @returns a boolean value, true if the indicator type is a baseline type. */
export function isBaselineIndicatorType(type: INDICATOR_TYPE | undefined): boolean {
    switch (type) {
        case INDICATOR_TYPE.ABNORMALLY_HIGH:
        case INDICATOR_TYPE.ABNORMALLY_LOW:
        case INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_HIGH:
        case INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_LOW:
            return true;
        default:
            return false;
    }
}

/** returns true if the indicator type involves high or low thresholds/baselines, false otherwise.
 *  @param type the indicator type (kind) to check.
 *  @returns a boolean value, true if the indicator type is a high or low threshold/baseline type. */
export function isLowOrHighIndicatorType(type: INDICATOR_TYPE | undefined): boolean {
    switch (type) {
        case INDICATOR_TYPE.ABNORMALLY_HIGH:
        case INDICATOR_TYPE.ABNORMALLY_LOW:
        case INDICATOR_TYPE.TOO_HIGH:
        case INDICATOR_TYPE.TOO_LOW:
        case INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_HIGH:
        case INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_LOW:
            return true;
        default:
            return false;
    }
}

/** returns true if the indicator type involves low thresholds/baselines, false otherwise.
 *  @param type the indicator type (kind) to check.
 *  @returns a boolean value, true if the indicator type is a low threshold/baseline type. */
export function isLowIndicatorType(type: INDICATOR_TYPE | undefined): boolean {
    switch (type) {
        case INDICATOR_TYPE.ABNORMALLY_LOW:
        case INDICATOR_TYPE.TOO_LOW:
        case INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_LOW:
            return true;
        default:
            return false;
    }
}

/** returns true if the indicator type involves high thresholds/baselines, false otherwise.
 *  @param type the indicator type (kind) to check.
 *  @returns a boolean value, true if the indicator type is a high threshold/baseline type. */
export function isHighIndicatorType(type: INDICATOR_TYPE | undefined): boolean {
    switch (type) {
        case INDICATOR_TYPE.ABNORMALLY_HIGH:
        case INDICATOR_TYPE.TOO_HIGH:
        case INDICATOR_TYPE.DYNAMIC_THRESHOLD_TOO_HIGH:
            return true;
        default:
            return false;
    }
}
