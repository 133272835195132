/** This module contains the component for the view runbook page.  The view runbook page displays
 *      runbooks generated from test runs and fake data.
 *  @module
 */

import React from "react";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { SDWAN_ICONS } from "components/sdwan/enums/icons";
import ViewRunbookView from "./views/view-runbook/ViewRunbookView";
import { PARAM_NAME } from "components/enums/QueryParams";
import { useQueryParams } from "utils/hooks";
 
/** Renders the view runbook page.
 *  @param props the properties passed in.
 *  @returns JSX with the view runbook page component.*/
const ViewRunbookPage = (props): JSX.Element => { 
    let { params } = useQueryParams({ 
        listenOnlyTo: [
            PARAM_NAME.rbConfigId, PARAM_NAME.rbConfigNm
        ] 
    });

    /********************************************************************************************************************************************************/
    /* This was copied from create runbook                                                                                                                  */
    /********************************************************************************************************************************************************/
    /*
    const variant: string = getQueryParam(PARAM_NAME.variant);
    let initNodeLibrary: NodeLibrary;
    switch (variant) {
        case Variant.INCIDENT:
            initNodeLibrary = new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec);
            break;
        case Variant.LIFECYCLE:
            initNodeLibrary = new NodeLibrary(LifecycleRunbookNodeLibrary as NodeLibrarySpec);
            break;
        case Variant.SUBFLOW:
            initNodeLibrary = new NodeLibrary(SubflowRunbookNodeLibrary as NodeLibrarySpec);
            break;
        default:
            initNodeLibrary = new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec);
    }

    const nodeLibrary = useRef<NodeLibrary>(initNodeLibrary);
    */

    return (
        <PageWithHeader name="ViewRunbookPage" title={params[PARAM_NAME.rbConfigNm] /*runbookConfig && runbookConfig.name ? runbookConfig.name : ""*/} 
            icon={SDWAN_ICONS.RUNBOOK} addPadding={false} showTimeBar={false}
        >
            <ViewRunbookView subflows={[]}/>
        </PageWithHeader>
    );
};
 
export default ViewRunbookPage;
 