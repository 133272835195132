/** This module contains a factory for creating widgets.  The widgets are used in runbooks, NPM Plus,
 *  and Dashboards.
 *  @module
 */

import React from "react";
import { DataSet, SeverityScore } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { INTERACTION_TYPE, WIDGET_TYPE, Widget } from "./Widget.type";
import { PieView } from "components/common/vis-framework/widget/pie-view/PieView";
import { BarView } from "components/common/vis-framework/widget/bar-view/BarView";
import { TableView } from "components/common/vis-framework/widget/table-view/TableView";
import { TimeseriesView } from "components/common/vis-framework/widget/timeseries-view/TimeseriesView";
import { CardsView } from "components/common/vis-framework/widget/cards-view/CardsView";
import { GaugesView } from "components/common/vis-framework/widget/gauges-view/GaugesView";
import { DebugView } from "components/common/vis-framework/widget/debug-view/DebugView";
import { BubbleView } from "components/common/vis-framework/widget/bubble-view/BubbleView";
import { CorrelationView } from "components/common/vis-framework/widget/correlation-view/CorrelationView";
import { ConnectionGraphView } from "components/common/vis-framework/widget/connection-graph-view/ConnectionGraphView";
import { GeoMapView } from "components/common/vis-framework/widget/geomap-view/GeoMapView";

/** The height of the widgets */
const WIDGET_HEIGHT: string | undefined = "320px";

/** creates a new widget component of the specified type.
 *  @param widgetType the WIDGET_TYPE to create.
 *  @param widget the widget specification.
 *  @param datasets the datasets to pass to the widget.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns returns a React component with the widget. */
export function createWidget(
    widgetType: WIDGET_TYPE, widget: Widget, datasets: DataSet[], loading?: boolean,
    notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void,
    setTableLimitHandler?: any, tableLimit?: number, showControls?: boolean,
): JSX.Element {
    const showWidgetToolbar: boolean = false;
    const runbookId = undefined;

    let widgetComponent: JSX.Element = <div>Unknown Visual Component</div>;
    switch (widgetType) {
        case WIDGET_TYPE.TABLE:
            widgetComponent = createTable(
                "", widget, datasets, runbookId, showWidgetToolbar, loading,
                notifyInteraction, setTableLimitHandler, tableLimit, showControls,
            );
            break;
        case WIDGET_TYPE.CONNECTION_GRAPH:
            widgetComponent = createConnectionGraph("", widget, datasets, runbookId, showWidgetToolbar, loading);
            break;
        case WIDGET_TYPE.CARDS: {
            /*
            const datasets: Array<DataSet> = new Array(4).fill(undefined);
            for (let index = 0; index < widgetDatasets.length; index++) {
                if (widgetDatasets[index].type ===  DATA_TYPE.SUMMARY) {
                    datasets[widgetDatasets[index].isComparison ? 1 : 0] = widgetDatasets[index];
                } else if (widgetDatasets[index].type ===  DATA_TYPE.TIMESERIES) {
                    datasets[widgetDatasets[index].isComparison ? 3 : 2] = widgetDatasets[index];
                }
            }
            */
            widgetComponent = createCards("", widget, datasets, runbookId, showWidgetToolbar, loading);
            break;
        }
        case WIDGET_TYPE.TIMESERIES:
            widgetComponent = createTimeseries("", widget, datasets, runbookId, showWidgetToolbar, loading, notifyInteraction);
            break;
        case WIDGET_TYPE.BAR:
            widgetComponent = createBar("", widget, datasets, runbookId, showWidgetToolbar, loading, notifyInteraction);
            break;
        case WIDGET_TYPE.PIE:
            widgetComponent = createPie("", widget, datasets, runbookId, showWidgetToolbar, loading, notifyInteraction);
            break;
        case WIDGET_TYPE.BUBBLE:
            widgetComponent = createBubble("", widget, datasets, runbookId, showWidgetToolbar, loading, notifyInteraction);
            break;
        case WIDGET_TYPE.CORRELATION:
            widgetComponent = createCorrelation("", widget, datasets, runbookId, showWidgetToolbar, loading, notifyInteraction);
            break;
        case WIDGET_TYPE.GAUGES:
            widgetComponent = createGauges("", widget, datasets, runbookId, showWidgetToolbar, loading);
            break;
        case WIDGET_TYPE.DEBUG:
            //if (widget?.options?.showInOutput) {
            widgetComponent = createDebug("", widget, datasets, runbookId, showWidgetToolbar, loading);
            //}
            break;
        case WIDGET_TYPE.GEO_MAP:
            widgetComponent = createGeoMap("", widget, datasets, runbookId, showWidgetToolbar, loading, notifyInteraction);
            break;
    }
    return widgetComponent;
}

/** Create a table component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns JSX with the table component.*/
function createTable(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined, notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void,
    setTableLimitHandler?: React.Dispatch<React.SetStateAction<number>>, tableLimit?: number, showControls?: boolean,
): JSX.Element {
    let options = widget.options || {};
    //let key = "table";
    let triggerColumnId: string | undefined = undefined;
    let triggerSeverity: SeverityScore | undefined = undefined;
    if (datasets?.length) {
        //key = datasets[0].id;
        triggerColumnId = datasets[0].trigger?.id;
        triggerSeverity = datasets[0].severity;
    }
    const component = (
        <div className="WidgetFactory-Table-container">
            <TableView
                incidentId={incidentId}
                runbookId={runbookId}
                datasets={datasets}
                key={widget.id /*key*/}
                triggerColumnId={triggerColumnId}
                triggerSeverity={triggerSeverity}
                columns={options.columns ? options.columns : []}
                widget={widget}
                sortBy={[
                    {
                        id: options.sortColumn
                            ? String(options.sortColumn)
                            : "",
                        desc: options.sortOrder
                            ? options.sortOrder === "desc"
                                ? true
                                : false
                            : false,
                    },
                ]}
                showSelections={true}
                loading={loading}
                onGroupsAndMetricsSelected={(
                    groups: any[],
                    metrics: string[],
                ) => {
                    if (notifyInteraction) {
                        notifyInteraction(INTERACTION_TYPE.GROUP, groups);
                    }
                }}
                showControls={showControls}
                recreateTableOnEachRender={true}
                setTableLimitHandler={setTableLimitHandler}
                tableLimit={tableLimit}
            />
        </div>
    );
    return component;
}

/** Create a connection graph component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @returns JSX with the connection graph component.*/
function createConnectionGraph(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined
): JSX.Element {
    //let options = widget.options || {}; 
    const component = <ConnectionGraphView
        incidentId={incidentId} datasets={datasets} key={widget.id + Math.random()/*datasets[0].id*/}
        widget={widget} height={WIDGET_HEIGHT} loading={loading}
    />;
    return component;
}

/** Create a bar component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns JSX with the bar component.*/
function createBar(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined, notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void
): JSX.Element {
    //let options = widget.options || {}; 
    const component = <BarView
        incidentId={incidentId} datasets={datasets} key={widget.id + Math.random()/*datasets[0].id*/}
        widget={widget} height={WIDGET_HEIGHT} loading={loading} onGroupsAndMetricsSelected={(groups: any[], metrics: string[]) => {
            if (notifyInteraction) {
                notifyInteraction(INTERACTION_TYPE.GROUP, groups);
            }
        }}
    />;
    return component;
}

/** Create a pie chart component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns JSX with the bar component.*/
function createPie(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined, notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void
): JSX.Element {
    //let options = widget.options || {};
    const component = <PieView
        incidentId={incidentId} datasets={datasets} key={widget.id + Math.random()/*datasets[0].id*/}
        widget={widget} height={WIDGET_HEIGHT} loading={loading} onGroupsAndMetricsSelected={(groups: any[], metrics: string[]) => {
            if (notifyInteraction) {
                notifyInteraction(INTERACTION_TYPE.GROUP, groups);
            }
        }}
    />;
    return component;
}

/** Create a bubble chart component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns JSX with the bubble chart component.*/
function createBubble(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined, notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void
): JSX.Element {
    //let options = widget.options || {};
    const component = <BubbleView
        incidentId={incidentId} datasets={datasets} key={widget.id + Math.random()/*datasets[0].id*/}
        widget={widget} height={WIDGET_HEIGHT} loading={loading} onGroupsAndMetricsSelected={(groups: any[], metrics: string[]) => {
            if (notifyInteraction) {
                notifyInteraction(INTERACTION_TYPE.GROUP, groups);
            }
        }}
    />;
    return component;
}

/** Create a cards component.  The cards component can display both summary data (big numbers) and 
 *  time series data (sparklines).
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @returns JSX with the cards component.*/
function createCards(
    incidentId: string, widget: Widget,
    datasets: Array<DataSet>, runbookId: string | undefined, showToolbar: boolean, loading: boolean | undefined
): JSX.Element {
    //let options = widget.options || {};
    const component = <CardsView incidentId={incidentId} key={widget.id + Math.random()/*datasets[0].id*/}
        datasets={datasets} widget={widget} height={WIDGET_HEIGHT} loading={loading}
    />;
    return component;
}

/** Create a gauges component.  The gauges component will display the summary data as a set of gauges, one
 *  row of gauges for each row of data.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @returns JSX with the cards component.*/
function createGauges(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined
): JSX.Element {
    //let options = widget.options || {}; 
    const component = <GaugesView incidentId={incidentId} key={widget.id + Math.random()/*datasets[0].id*/}
        datasets={datasets} widget={widget} loading={loading}
    />;
    return component;
}

/** Create a time series component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook. 
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns JSX with the time series component.*/
function createTimeseries(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined, notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void
): JSX.Element {
    //let options = widget.options || {}; 
    const component = <TimeseriesView
        incidentId={incidentId} datasets={datasets}
        key={widget.id/*datasets[0].id*/} height={WIDGET_HEIGHT} widget={widget}
        loading={loading} onGroupsAndMetricsSelected={(groups: any[], metrics: string[]) => {
            if (notifyInteraction) {
                notifyInteraction(INTERACTION_TYPE.GROUP, groups);
            }
        }}
    />;
    return component;
}

/** Create a time series component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook. 
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns JSX with the time series component.*/
function createCorrelation(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading?: boolean | undefined, notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void
): JSX.Element {
    //let options = widget.options || {};
    const component = <CorrelationView
        incidentId={incidentId} datasets={datasets}
        key={widget.id/*datasets[0].id*/} height={WIDGET_HEIGHT} widget={widget}
        loading={loading} onGroupsAndMetricsSelected={(groups: any[], metrics: string[]) => {
            if (notifyInteraction) {
                notifyInteraction(INTERACTION_TYPE.GROUP, groups);
            }
        }}
    />;
    return component;
}

/** Create a debug view component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @returns JSX with the debug view component.*/
function createDebug(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined
): JSX.Element {
    //let options = widget.options || {}; 
    const component = <DebugView
        datasets={datasets} key={widget.id + Math.random()/*datasets[0].id*/} widget={widget} height={WIDGET_HEIGHT}
        loading={loading}
    />;
    return component;
}

/** Create a geomap component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @param loading a boolean value, true if the data for the widget is loading.
 *  @param notifyInteraction the handler for interaction events.
 *  @returns JSX with the geomap component.*/
function createGeoMap(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined,
    showToolbar: boolean, loading: boolean | undefined, notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void
): JSX.Element {
    let options = widget.options || {};
    //let key = "table";
    let triggerColumnId: string | undefined = undefined;
    let triggerSeverity: SeverityScore | undefined = undefined;
    if (datasets?.length) {
        //key = datasets[0].id;
        triggerColumnId = datasets[0].trigger?.id;
        triggerSeverity = datasets[0].severity;
    }
    const component = <GeoMapView
        incidentId={incidentId} runbookId={runbookId} datasets={datasets} key={widget.id/*key*/}
        triggerColumnId={triggerColumnId} triggerSeverity={triggerSeverity}
        columns={options.columns ? options.columns : []} widget={widget}
        sortBy={[{
            id: options.sortColumn ? String(options.sortColumn) : '',
            desc: options.sortOrder ? (options.sortOrder === 'desc' ? true : false) : false
        }]}
        showSelections={true}
        loading={loading} onGroupsAndMetricsSelected={(groups: any[], metrics: string[]) => {
            if (notifyInteraction) {
                notifyInteraction(INTERACTION_TYPE.GROUP, groups);
            }
        }}
    />;
    return component;
}
