import React, {
	useRef,
	useCallback,
	useState,
	useEffect,
	useContext,
} from 'react';
import {
	Button,
	Menu,
	MenuItem,
	Position,
	Intent,
	Switch,
	ButtonGroup,
} from '@blueprintjs/core';
import _ from 'lodash';
import { Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip';
import {
	Table,
	Icon,
	IconNames,
	TableColumnDef,
	SuccessToaster,
	ErrorToaster,
} from '@tir-ui/react-components';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import {
	DataSourceService,
	gatewayTypeOptions,
	vmImageTypes,
} from 'utils/services/DataSourceApiService';
import { loader } from 'graphql.macro';
import { useQuery } from 'utils/hooks';
import { Query } from 'reporting-infrastructure/types/Query';
import { useMutation } from '@apollo/client';
import {
	DURATION,
	durationToRoundedTimeRange,
} from 'utils/stores/GlobalTimeStore';
import { AutoUpdateContext } from '../EdgeConfigPage';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { openConfirm } from 'components/common/modal';
import { DATASOURCE_STATUS_PROPS } from 'pages/edge-configuration/views/DataSourceStatus';
import { AddDataSourceModal } from 'pages/edge-configuration/modals/AddDataSourceModal';
import { AddEdgeGatewayModal } from 'pages/edge-configuration/modals/AddEdgeGatewayModal';
import { SetDataSourceSettingsModal } from '../modals/SetDataSourceSettingsModal';
import {
	DataSourceView,
	PreferredDataSource,
} from 'pages/edge-configuration/views/DataSourceView';
import { EdgeGatewayStatus } from './EdgeGatewayStatus';

import { STRINGS } from 'app-strings';

import './EdgeGatewaysView.scss';

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** the needed data to render each row */
type TabularRowEdges = {
	id: string;
	name: string;
	type: string;
	connected: boolean;
	ignoreInvalidCertificates: boolean;
	dataSources: EdgeDataSource[];
};

/** the holisitic data returned for each row */
type EdgeDataSource = {
	id: string;
	name: string;
	type: string;
	state: boolean;
	status: string;
	hostname: string;
	metricVersion: string;
	productVersion: string;
	lastReceivedData: string;
	info: any;
};

/** the initial struct that is fed into helper functions `getDSStatuses()` or
 * `getDisplayData()` */
type InitialEdgeData = {
	edges: TabularRowEdges[];
};

/** data used to generate the contents of each row */
type EdgeDisplayData = {
	name: string;
	type: string;
	data_sources: JSX.Element[] | [];
	ignoreInvalidCertificates: JSX.Element;
	status: JSX.Element;
	more: JSX.Element;
	subComponent: JSX.Element | string;
};

type TabularSwitchStateProps = {
	isChecked: boolean;
	editFunction: Function;
};

type TabularMenuCellProps = {
	id: string;
	name: string;
	type: string;
	dataSources: EdgeDataSource[];
};

export interface SetDataSourcesInput {
	input: {
		id: string;
		eTag: string;
		name: string;
		hostname: string;
		type: string;
		port: number;
		protocol: string;
		metricStreamingEnabled: boolean;
		queriesEnabled: boolean;
		authentication?: {
			username?: string;
			password?: string;
		};
		iot: {
			deviceId: string;
			hostname: string;
			hubHostname: string;
		};
	};
}

export interface CreateDataSourcesInput {
	input: {
		name: string;
		hostname: string;
		type: string;
		port: number;
		protocol: string;
		metricStreamingEnabled: boolean;
		queriesEnabled: boolean;
		authentication: {
			username: string;
			password: string;
		};
		iot: {
			deviceId: string;
			hostname: string;
			hubHostname: string;
		};
	};
}

/** Column definitions strictly for Edge Gateways view */
const getColumns: Array<TableColumnDef> = [
	{
		id: 'name',
		Header: STRINGS.DATA_SOURCES.edgeGateways.columns.gatewayName,
		accessor: 'name',
	},
	{
		id: 'type',
		Header: STRINGS.DATA_SOURCES.edgeGateways.columns.gatewayType,
		accessor: 'type',
	},
	{
		id: 'status',
		Header: STRINGS.DATA_SOURCES.edgeGateways.columns.status,
		accessor: 'status',
	},
	{
		id: 'data_sources',
		Header: STRINGS.DATA_SOURCES.edgeGateways.columns.dataSources,
		accessor: 'data_sources',
	},
	{
		id: 'ignoreInvalidCertificates',
		Header: STRINGS.DATA_SOURCES.edgeGateways.columns
			.performCertificateValidation,
		accessor: 'ignoreInvalidCertificates',
	},
	{
		id: 'more',
		Header: '',
		accessor: 'more',
	},
];

/** table cell component used in the `ignoreInvalidCertificates` column */
export const TabularSwitchState = ({
	isChecked,
	editFunction,
}: TabularSwitchStateProps) => {
	/** Directly stating the boolean prop is reversed, this is done to have the
	 * `<Switch />` values make sense. If you're ignoring the invalid
	 * certificate, you aren't performing a certificate validation (toggled `off`) */
	const flipIsCheck = !isChecked;

	/** toggle `perform certificate validation` between on/off */
	const toggleCertificateValidationHandler = () => {
		editFunction();
	};

	return (
		<Switch
			checked={flipIsCheck}
			innerLabelChecked="on"
			innerLabel="off"
			onChange={toggleCertificateValidationHandler}
		/>
	);
};

/** Renders the  edge gateways summary view.
 *  @param props the properties passed in.
 *  @returns JSX with the edge gateways summary view.*/
const EdgeGatewaysView = (): JSX.Element => {
	const tableId = 'GatewaysTbl';

	const [delay, setDelay] = useState<boolean>(false);

	const autoUpdate = useContext(AutoUpdateContext);

	const { loading, data, error, run } = useQuery({
		name: 'EdgeConfig',
		query: new Query(loader('./../queries/edge-config.graphql')),
		queryVariables: {
			...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
		},
	});

	const dataSourceQuery = useQuery({
		query: new Query(loader('./../queries/datasource.graphql')),
		name: 'dataSource',
		lazy: true,
	});

	const dataSourceSettingsQuery = useQuery({
		name: 'dataSourceSettings',
		query: new Query(loader('./../queries/datasource-settings.graphql')),
	});

	const [setDataSource] = useMutation<any, SetDataSourcesInput>(
		loader('./../queries/set-datasource-mutation.graphql'),
		{
			onCompleted: (data) => {
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.success,
				});
			},
			onError: (err) => {
				ErrorToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.error,
				});
				console.error(err?.message);
			},
		}
	);

	const [deleteDataSource] = useMutation<any, SetDataSourcesInput>(
		loader('./../queries/delete-datasource-mutation.graphql'),
		{
			onCompleted: (data) => {
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.success,
				});
			},
			onError: (err) => {
				ErrorToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.error,
				});
				console.error(err?.message);
			},
		}
	);

	const preferredDataSource =
		dataSourceSettingsQuery &&
		!dataSourceSettingsQuery.loading &&
		dataSourceSettingsQuery.data?.dataSourceSettings
			?.applicationLocationPreferredDataSourceType
			? dataSourceSettingsQuery.data.dataSourceSettings
					.applicationLocationPreferredDataSourceType
			: PreferredDataSource.APP_RESPONSE;

	const expandedRows: string[] = [];

	const lastSequenceNumber = useRef(0);
	useEffect(
		() => {
			if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
				lastSequenceNumber.current = autoUpdate.sequenceNumber;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[autoUpdate.sequenceNumber]
	);

	const dataRefresh = useCallback(() => {
		run({
			noCache: true,
			queryVariables: {
				...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
			},
		});
		setDelay(false);
	}, [run]);

	const handleEdgeGatewayDelete = useCallback(
		(id: string, name: string) => {
			openConfirm({
				message: STRINGS.formatString(
					STRINGS.DATA_SOURCES.edgeGateways.messages.deleteConfirmMsg,
					<b>{name}</b>
				),
				onConfirm: () => {
					setDelay(true);
					return DataSourceService.deleteEdgeGateway(id).then(
						() => {
							setTimeout(() => {
								dataRefresh();
							}, 3000);
						},
						(error) => {
							ErrorToaster({
								message: error.message,
							});
							setDelay(false);
						}
					);
				},
				intent: Intent.PRIMARY,
				icon: IconNames.TRASH,
			});
		},
		[dataRefresh]
	);

	const editCertificateValidation: (
		id: string,
		isChecked: boolean
	) => Promise<void> = useCallback(
		async (id, isChecked) => {
			const payload = {
				entity: {
					attributes: { ignore_invalid_certificates: !isChecked },
				},
			};
			try {
				const edgeGateway = await DataSourceService.getEdgeGateway(id);
				await DataSourceService.updateEdgeGateway(
					id,
					_.merge(edgeGateway, payload)
				);
				SuccessToaster({
					message: "Success: Toggled 'Certificate Validation'",
				});
				setTimeout(() => {
					dataRefresh();
				}, 250);
			} catch (error) {
				ErrorToaster({
					message: "Unable to toggle 'Certificate Validation'",
				});
			}
		},
		[dataRefresh]
	);

	const handleDataSourceDelete = useCallback(
		(id: string, name: string) => {
			openConfirm({
				message: STRINGS.formatString(
					STRINGS.DATA_SOURCES.dataSourceView.messages
						.deleteConfirmMsg,
					<b>{name}</b>
				),
				onConfirm: () => {
					setDelay(true);
					return deleteDataSource({
						variables: {
							input: {
								id: id,
							},
						},
					}).then(
						() => {
							setTimeout(() => {
								dataRefresh();
							}, 3000);
						},
						(error) => {
							ErrorToaster({
								message: error.message,
							});
							setDelay(false);
						}
					);
				},
				intent: Intent.PRIMARY,
				icon: IconNames.TRASH,
			});
		},
		[dataRefresh, deleteDataSource]
	);

	const handleScriptDownload = (id: string, type: string) => {
		if (type === 'Ova') {
			DataSourceService.downloadOvaFile(id).then(
				() => {},
				(error) => {
					ErrorToaster({
						message: error.message,
					});
				}
			);
		} else {
			DataSourceService.downloadInitScript(id).then(
				() => {},
				(error) => {
					ErrorToaster({
						message: error.message,
					});
				}
			);
		}
	};

	const handleVmDownload = (type: string): void => {
		openConfirm({
			message: (
				<span>
					<b>
						{
							STRINGS.DATA_SOURCES.dataSourceView.messages
								.downloadVmImageExternalWarning
						}
					</b>{' '}
					{
						STRINGS.DATA_SOURCES.dataSourceView.messages
							.downloadVmImageMsg
					}{' '}
					({new URL(vmImageTypes[type]).hostname})
				</span>
			),
			onConfirm: () => {
				// const tenantId = AuthService.getTenantId();
				const anchor = document.createElement('a');
				anchor.setAttribute('target', '_blank');
				anchor.setAttribute('href', `${vmImageTypes[type]}`);
				anchor.setAttribute('download', `${type}`);
				anchor.click();
				anchor.remove();
			},
			intent: Intent.WARNING,
			icon: IconNames.WARNING_SIGN,
		});
	};

	/** generates the `JSX.Element` for a rows cell under the `data_sources`
	 * column */
	const getDSStatuses = (data: EdgeDataSource[]) => {
		const dsStatuses = {};
		data &&
			data.forEach((item) => {
				dsStatuses[item.info.status] =
					(dsStatuses[item.info.status] || 0) + 1;
			});

		return Object.keys(dsStatuses)
			.sort((a, b) => {
				return dsStatuses[a] - dsStatuses[b];
			})
			.map((status) => {
				return (
					<span
						style={{
							marginRight: '15px',
						}}
						key={status}
					>
						{
							<WrapInTooltip
								tooltip={DATASOURCE_STATUS_PROPS[status].label}
							>
								<span
									style={{
										verticalAlign: 'middle',
										marginRight: '5px',
									}}
								>
									{dsStatuses[status]}
								</span>
								<Icon
									icon={DATASOURCE_STATUS_PROPS[status].icon}
									intent={
										DATASOURCE_STATUS_PROPS[status].intent
									}
									className={
										DATASOURCE_STATUS_PROPS[status]
											.className
									}
								/>
							</WrapInTooltip>
						}
					</span>
				);
			});
	};

	/** used to create the nested items when a menu is toggled to open */
	const TabularMenuCell = ({
		id,
		name,
		type,
		dataSources,
	}: TabularMenuCellProps) => {
		return (
			<div
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<Popover2
					position={Position.BOTTOM_RIGHT}
					interactionKind={Popover2InteractionKind.CLICK}
					content={
						<Menu>
							{getMoreMenuItems(
								id,
								name,
								type,
								dataSources.length
							)}
						</Menu>
					}
				>
					<Button
						aria-label="edge-gateways-more-button"
						icon={IconNames.MORE}
						minimal
						className="edge-gateways-action-icon"
						disabled={false}
					/>
				</Popover2>
			</div>
		);
	};

	/** generates the data used for the `<Table />` */
	const getDisplayData = (data: InitialEdgeData): any => {
		let displayData: Array<EdgeDisplayData | []> = [];
		if (data && data?.edges.length > 0) {
			data.edges.map((item: TabularRowEdges) => {
				return displayData.push({
					name: item['name'],
					type: gatewayTypeOptions[item['type']],
					status: (
						<EdgeGatewayStatus connectedEdge={item.connected} />
					),
					ignoreInvalidCertificates: (
						<TabularSwitchState
							isChecked={item.ignoreInvalidCertificates}
							editFunction={() =>
								editCertificateValidation(
									item.id,
									item.ignoreInvalidCertificates
								)
							}
						/>
					),
					data_sources: getDSStatuses(item['dataSources']),
					more: (
						<TabularMenuCell
							id={item.id}
							name={item.name}
							type={item.type}
							dataSources={item.dataSources}
						/>
					),
					subComponent:
						(item['dataSources'] as []).length !== 0 ? (
							<DataSourceView
								data={item['dataSources']}
								preferredDataSource={preferredDataSource}
								reconfigureDataSource={
									handleDataSourceReconfigure
								}
								editDataSource={handleDataSourceEdit}
								setDataSourceSettings={
									handleSetDataSourceSettings
								}
								deleteDataSource={handleDataSourceDelete}
							/>
						) : (
							''
						),
				});
			});
		}

		return displayData;
	};

	const getMoreMenuItems = (
		id: string,
		name: string,
		type: string,
		dsCount: number
	): Array<JSX.Element> => {
		return [
			<MenuItem
				disabled={false}
				text={
					STRINGS.DATA_SOURCES.edgeGateways.menuItems.editEdgeGateway
				}
				active={false}
				key={'edit_edge_gateway'}
				onClick={() => handleEdgeGatewayEdit(id)}
			/>,
			<MenuItem
				disabled={false}
				text={
					STRINGS.DATA_SOURCES.edgeGateways.menuItems.createDataSource
				}
				active={false}
				key={'connect_data_source'}
				onClick={() => handleDataSourceOpen(id)}
			/>,
			<MenuItem
				disabled={!type}
				text={
					STRINGS.DATA_SOURCES.edgeGateways.menuItems.downloadVmImage
				}
				active={false}
				key={'vm_download'}
				onClick={() => handleVmDownload(type)}
			/>,
			<MenuItem
				disabled={!type}
				text={
					type !== 'Ova'
						? STRINGS.DATA_SOURCES.edgeGateways.menuItems
								.downloadCloudInitScript
						: STRINGS.DATA_SOURCES.edgeGateways.menuItems
								.downloadIso
				}
				active={false}
				key={'script_download'}
				onClick={() => handleScriptDownload(id, type)}
			/>,
			<MenuItem
				disabled={
					dsCount !== 0 || !AuthService.userHasWriteAccess('gelato')
				}
				text={STRINGS.DATA_SOURCES.edgeGateways.menuItems.delete}
				active={false}
				key={'delete'}
				onClick={() => handleEdgeGatewayDelete(id, name)}
			/>,
		];
	};

	const addEdgeGatewayModal = useRef();
	const handleEdgeGatewayEdit = (id: string) => {
		if (addEdgeGatewayModal.current) {
			// @ts-ignore
			addEdgeGatewayModal.current.setEdit(true);
			// @ts-ignore
			addEdgeGatewayModal.current.setGatewayId(id);
			// @ts-ignore
			addEdgeGatewayModal.current.handleOpen();
		}
	};

	const handleEdgeGatewayOpen = () => {
		if (addEdgeGatewayModal.current) {
			// @ts-ignore
			addEdgeGatewayModal.current.setEdit(false);
			// @ts-ignore
			addEdgeGatewayModal.current.handleOpen();
		}
	};

	const addDataSourceModal = useRef();
	const handleDataSourceOpen = (id: string) => {
		if (addDataSourceModal.current) {
			// @ts-ignore
			addDataSourceModal.current.setEdit(false);
			// @ts-ignore
			addDataSourceModal.current.setGatewayId(id);
			// @ts-ignore
			addDataSourceModal.current.handleOpen();
		}
	};

	const handleDataSourceEdit = (gatewayId: string, dataSourceId: string) => {
		if (addDataSourceModal.current) {
			// @ts-ignore
			addDataSourceModal.current.setEdit(true);
			// @ts-ignore
			addDataSourceModal.current.setGatewayId(gatewayId);
			// @ts-ignore
			addDataSourceModal.current.setDataSourceId(dataSourceId);
			// @ts-ignore
			addDataSourceModal.current.handleOpen();
		}
	};

	const setDataSourceSettingsModal = useRef();
	const handleSetDataSourceSettings = (preferredDataSource: string) => {
		if (setDataSourceSettingsModal.current) {
			// @ts-ignore
			setDataSourceSettingsModal.current.handleOpen();
			// @ts-ignore
			setDataSourceSettingsModal.current.setPreferredDataSource(
				preferredDataSource
			);
		}
	};

	const handleDataSourceReconfigure = (id: string) => {
		setDelay(true);
		return dataSourceQuery
			.run({
				queryVariables: { id: String(id) },
				noCache: true,
			})
			.then(
				(response) => {
					const dataSource = response.dataSource;
					setDataSource({
						variables: {
							input: {
								id: dataSource.id,
								eTag: dataSource.eTag,
								type: dataSource.type,
								name: dataSource.name,
								hostname: dataSource.hostname,
								port: dataSource.port,
								protocol: dataSource.protocol,
								metricStreamingEnabled:
									dataSource.metricStreamingEnabled,
								queriesEnabled: dataSource.queriesEnabled,
								iot: {
									deviceId: dataSource.iot.deviceId,
									hostname: dataSource.iot.hostname,
									hubHostname: dataSource.iot.hubHostname,
								},
							},
						},
					});
				},
				(error) => {
					ErrorToaster({
						message: error.message,
					});
				}
			)
			.finally(() => {
				setDelay(false);
			});
	};

	const tableData = getDisplayData(data);
	const currentExpandedRows = useRef<Array<string> | undefined>(undefined);
	if (currentExpandedRows.current === undefined && expandedRows.length > 0) {
		// The reference has not been initialized
		currentExpandedRows.current = expandedRows;
	}

	return (
		<React.Fragment>
			<DataLoadFacade
				key="edges"
				loading={loading || delay}
				error={error}
				data={data}
			>
				<div aria-label="gateways list">
					<ButtonGroup className="mt-3">
						<Button
							data-testid={'add-edge-gateway-button'}
							// minimal
							icon={IconNames.ADD}
							// className="user-btn"
							onClick={() => {
								handleEdgeGatewayOpen();
							}}
						>
							{
								STRINGS.DATA_SOURCES.addEdgeGatewayDialog.title
									.add
							}
						</Button>
					</ButtonGroup>
					<Table
						id={tableId}
						columns={getColumns}
						noDataText={STRINGS.noDataAvailable}
						data={tableData}
						sortBy={[{ id: 'name' }]}
						enablePagination={false}
						expandOnlyOneRow
						expandedRows={currentExpandedRows.current}
						onExpansionChange={(expandedRows: any) => {
							if (tableData && tableData.length > 0) {
								currentExpandedRows.current = expandedRows.map(
									(row) => row.id
								);
							}
						}}
					/>
				</div>
			</DataLoadFacade>
			<AddEdgeGatewayModal
				ref={addEdgeGatewayModal}
				dataRefresh={dataRefresh}
				setDelay={() => {
					setDelay(true);
				}}
			/>
			<AddDataSourceModal
				ref={addDataSourceModal}
				dataRefresh={dataRefresh}
				setDelay={() => {
					setDelay(true);
				}}
				submitToApi={true}
			/>
			<SetDataSourceSettingsModal ref={setDataSourceSettingsModal} />
		</React.Fragment>
	);
};

export { EdgeGatewaysView };
