import React from 'react';
import * as yup from 'yup';
import { Callout, Intent } from '@blueprintjs/core';
import { AuthService } from '@tir-ui/azure-auth';
import { ErrorToaster, LoadingOverlay, useDataLoading } from '@tir-ui/react-components';
import { TenantManagementService } from 'utils/services/TenantManagementService';
import { FormModal } from 'components/common/modal';
import { initialTextBoxValue, TextBoxWithDropdown } from 'components/common/form/TextBoxWithDropdown';
import { validateRiverbedEmailField } from "utils/validators/Validators";
import {
    initializePlatformAndProductRoleFields,
    RolesSection,
} from './RolesSection';
import { STRINGS } from 'app-strings';

const getDomains = () => {
    const tenantId = AuthService.getTenantId();
    return TenantManagementService.get(`common/tenant/${tenantId}/domains`);
}

export const InviteUserModal = (props) => {

    const { rolesByProduct, platformRoles } = props;
    const [domains, loading] = useDataLoading<any>(getDomains, undefined);
    const [
        roleValidationSchema,
        initialRoleValues,
    ] = initializePlatformAndProductRoleFields(rolesByProduct, platformRoles);

    const validationSchema = yup.object().shape({
        user_email: yup
            .string()
            .email(STRINGS.SIGNUP.EMAIL.domainError)
            .label(STRINGS.SIGNUP.EMAIL.label)
            .required(),
        ...roleValidationSchema,
    });

    const initialTextValue = initialTextBoxValue('user_email')

    const formConfig = {
        initialValues: {
            user_email: '',
            ...initialTextValue,
            ...initialRoleValues,
        },
        validationSchema: validationSchema,
        validate: validateRiverbedEmailField,
        onSubmit: (values: any) => {
            if (!values.superuser && !Object.keys(values.products).length) {
                throw new Error(STRINGS.USER_ACCOUNT_MANAGEMENT.VALIDATION.minimumProductSelection);
            }

            const payloadData = { user_email: values['user_email'] };
            if (values.superuser) {
                payloadData['superuser'] = values.superuser;
            } else {
                payloadData['products'] = values.products;
            }
            const tenantId = AuthService.getTenantId();
            return new Promise ((resolve, reject) => {
                TenantManagementService.post(
                    `common/tenant/${tenantId}/user`,
                    payloadData
                ).then(response => {
                    resolve(response);
                }, error => {
                    ErrorToaster({
                        message: error.response?.data?.message
                    });
                    reject(error);
                });
            });
        },
    };

    return (
        <FormModal
            title={STRINGS.USER_ACCOUNT_MANAGEMENT.INVITE_USER.title}
            formikProps={formConfig}
            {...props}
        >
            <Callout intent={Intent.PRIMARY}>
                {STRINGS.USER_ACCOUNT_MANAGEMENT.INVITE_USER.info}
            </Callout>

            <br />

            {
                loading ?
                    <LoadingOverlay visible={true}/>
                :
                    <React.Fragment>
                        <TextBoxWithDropdown
                            label={STRINGS.SIGNUP.EMAIL.label}
                            placeholder={STRINGS.SIGNUP.EMAIL.placeholder}
                            name={"user_email"}
                            required={true}
                            menuItems={domains.items.map(domain => `@${domain.domain_name}`)}
                        />
                        <br/>
                        {
                            <RolesSection />
                        }
                    </React.Fragment>
            }
        </FormModal>
    );
};
