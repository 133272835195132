/** This module contains the component for displaying the page header.
 *  @module
 */
import React, { ReactNode } from 'react';
import { trackPageView } from 'utils/appinsights';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { PageDetailPaneRenderer } from 'components/common/page/PageDetailPaneRenderer';
import { PageHeader, PageHeaderProps } from 'components/sdwan/layout/page-header/PageHeader';
import { Container } from 'react-bootstrap';
import { STRINGS } from 'app-strings';
import "./PageWithHeader.scss"
import { IS_EMBEDDED } from 'components/enums/QueryParams';

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** this interface defines the propertes passed into the PageHeader component. */
interface PageWithHeaderProps extends PageHeaderProps {
    /** the name of the page. */
    name: string;
    /** the child components to be displayed on the page. */
    children?: ReactNode;
    /** a boolean value that specifies whether or not padding should be added between the page header and content. */
    addPadding?: boolean;
    /** the optional class name for the page header. */
    className?: string;
    /** specifies whether to display the subheader. */
    showHeader?: boolean;
}

/** Renders the page with header React component.
 *  @param {string} name - title of the page
 *  @param {JSX.Element} children - react components to pass in
 *  @param {boolean} addPadding - specific spacing between page header and
 *  content
 *  @param {string} className - used to aid in additional css styles
 *  @param {boolean} showHeader - toggle for subheader
 *  @param {object} headerProps - baked in propers from the `<PageHeader />`
 *  @returns JSX with the view page with header React component.
 **/
function PageWithHeader({
    name,
    children,
    className,
    addPadding,
    showHeader = true,
    ...headerProps
}: PageWithHeaderProps): JSX.Element {
    // report metrics to App Insights
    const appInsightsContext = useAppInsightsContext();
    if (appInsightsContext) {
        const properties = {
            name: name
        };
        trackPageView(appInsightsContext, AuthService, properties);
    }
    
    if (IS_EMBEDDED) {
        showHeader = false;
    }

    let pageTitle = STRINGS.appTitle;
    if (headerProps.title && typeof headerProps.title === "string") {
        pageTitle = headerProps.title + " - " + pageTitle;
    }
    if (document.title !== pageTitle) {
        document.title = pageTitle;
    }
    return <Container fluid className="p-0 h-100 d-flex flex-column page-with-header">
        { showHeader &&
            <PageHeader {...headerProps}/>
        }
        <div className={"page-content container-fluid flex-grow-1 overflow-auto" + (className ? " " + className : "") + (addPadding ? " add-padding" : "" )}>
            {children}
        </div>
        <PageDetailPaneRenderer/>
    </Container>;
}

export { PageWithHeader };
