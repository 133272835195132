/** This module contains the component for displaying a control that allows you to select a filter from a list
 *  of items.
 *  @module
 */
import React, { useEffect, useRef } from "react";
import { Button, Checkbox } from "@blueprintjs/core";
import { ColumnFilterControlProps, IconNames, LinkButton } from "@tir-ui/react-components";
import { Popover2 } from "@blueprintjs/popover2";

// TBD: THIS COMPONENT SHOULD BE MOVED INTO tir-ui-react-components AND REPLACE
// THE MultiSelectFilterControl COMPONENT IN src/components/tables/TableBuiltinColumnFilters.tsx
// This component was recently updated by cshelton to format the displayed options
// using the provided formatter instead of showing raw values.

/** Renders the multi-select filter control component.
 *  @param props the properties passed into the component.
 *  @returns JSX with the multi-select filter control component.*/
export function MultiSelectFilterControl(props:ColumnFilterControlProps): JSX.Element {
    const {
        children,
        column: {
            Cell,
            filterValue = [],
            setFilter,
            accessor,
        },
        flatRows,
        options,
        clearFilterControlLabel = "Clear",
    } = props;
    const itemsRef = useRef<Array<string|{ value: string, display: string | React.ReactNode}>>([]);
    useEffect(() => {
        if (itemsRef.current.length === 0) {
            if (options) {
                itemsRef.current = options;
            } else if (flatRows.length > 0 && itemsRef.current.length === 0) {
                const uniqueRowsMap:{ [index: string]: { value: any, row: any }} = {};
                for (const row of flatRows) {
                    const value = accessor(row.values);
                    if (uniqueRowsMap[value] === undefined) {
                        uniqueRowsMap[value] = { row, value };
                    }
                }
                itemsRef.current = Object.values(uniqueRowsMap).map(record => {
                    return {
                        value: record.value,
                        display: Cell({ value: record.value, row: record.row }),
                    }
                });
            }
        }
    }, [flatRows, options, accessor, Cell]);
    const currentActiveItems = Array.isArray(filterValue) ? filterValue.reduce((output, value) => {
        output[value] = true;
        return output;
    }, {}) : {};
    const noSelections = Object.keys(currentActiveItems).length === 0;
    return <>{children} <span onClick={e => { e.stopPropagation(); }}><Popover2
        content={<div>
            <div style={{ maxHeight: "300px", overflow: "auto", padding: "10px", paddingBottom: "0" }}>
                {itemsRef.current.map(item => {
                    const value = typeof item === "string" ? item : item.value;
                    const label = typeof item === "string" ? item : item.display;
                    return <div key={value}><label><Checkbox
                        key={"multi-item-filter-" + item}
                        className="d-inline"
                        checked={currentActiveItems[value] || false}
                        onChange={e => {
                            if (e.currentTarget.checked) {
                                currentActiveItems[value] = true;
                            } else {
                                delete currentActiveItems[value];
                            }
                            setFilter(Object.keys(currentActiveItems));
                        }}
                    />{label}</label></div>;
                })}
            </div>
            {!noSelections && <LinkButton onClick={() => setFilter([])} style={{ padding: "10px", paddingTop: "0" }}>{clearFilterControlLabel}</LinkButton>}
        </div>}
        interactionKind="click"
    ><Button
        className={"filter-icon" + (noSelections ? " show-on-hover no-filter" : "")}
        icon={noSelections ? IconNames.FILTER : IconNames.FILTER_KEEP}
        minimal
        small
    /></Popover2>
    </span></>;
};
