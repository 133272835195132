import React from "react";
import { Icon } from "@tir-ui/react-components";
import { APP_ICONS } from "components/sdwan/enums";
import { STRINGS } from "app-strings";

import "./RiverbedAdvisorContainer.scss";

type RiverbedAdvisorContainerProps = {
    children?: React.ReactNode;
};

const RiverbedAdvisorContainer = ({
    children,
}: RiverbedAdvisorContainerProps) => {
    return (
        <div className="riverbed-advisor-container card data-card bg-muted h-100 shadow">
            <div className="header border-bottom d-flex justify-content-between m-3 pb-2">
                <span className="display-7 font-weight-500 text-black">
                    {STRINGS.riverbedAdvisor.title}
                    <sup className="display-9 font-weight-bold">
                        {STRINGS.riverbedAdvisor.trademark}
                    </sup>
                </span>
                <Icon icon={APP_ICONS.AI} />
            </div>
            <div className="content pb-3 pt-1 d-flex flex-column align-items-center">
                {children}
            </div>
        </div>
    );
};

export { RiverbedAdvisorContainer };
