/** This module contains the component for rendering the custom properties blade.
 *  @module
 */
import React, { useEffect, useState } from "react";
import {
    Alignment, Button, Divider, InputGroup, Intent, Label, Switch, TextArea
} from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { getURLPath } from "config";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { generateRandomID } from "components/common/condition-tree-builder/condition/ConditionUtils";
import { getURL } from "utils/hooks/useQueryParams";
import { Form } from "components/common/form";
import { STRINGS } from "app-strings";
import { CustomPropertyType } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { useQuery } from '../../../../utils/hooks';
import { Query } from 'reporting-infrastructure/data-hub';
import { loader } from 'graphql.macro';
import { CUSTOM_PROPERTY_PERMISSION } from "../../../incident-search/IncidentSearchPage";
import "./CustomPropertyBlade.scss";

export enum ValidTypes {
    APPLICATION = "APPLICATION",
    LOCATION = "LOCATION",
    NETWORK_DEVICE = "NETWORK_DEVICE",
    NETWORK_INTERFACE = "NETWORK_INTERFACE",
}

type ValidCustomPropertyAssociations = {
    type: ValidTypes;
}

type CustomPropertyValue = {
    id: string;
    name: string;
    action?: string;
}

type User = {
    id: string;
    name: string;
    email: string;
}

export type CustomPropertyBladeData = {
    lastUpdatedAt: string;
    values?: CustomPropertyValue[];
    user: User;
    id: string;
    name: string;
    description: string;
    validTypes: ValidCustomPropertyAssociations[];
    type: CustomPropertyType
}

/**
 * Extracts and returns an array of names from the values property of the given
 * data object in the `<CustomPropertyBlade />`
 * @param {CustomPropertyBladeData} data - The CustomPropertyBladeData object containing values from which names are to be extracted.
 * @returns {string[]} An array of names extracted from the values property. Returns an empty array if values are undefined or empty.
 * 
 * @example
 * const customPropertyData = {
 *     lastUpdatedAt: "2021-01-01",
 *     values: [
 *         { id: "1", name: "Value1" },
 *         { id: "2", name: "Value2" }
 *     ],
 *     id: "prop1",
 *     name: "PropertyName",
 *     description: "Property Description",
 *     validTypes: [{ type: ValidTypes.APPLICATION }]
 * };
 * 
 * // Usage
 * const names = getArrayNamesFromValues(customPropertyData);
 * // Output: ["Value1", "Value2"]
 */
export const getArrayNamesFromValues = (data: CustomPropertyBladeData): string[] | [] => {
    if (data && data?.values && data?.values.length > 0) {
        return data?.values.map(value => value.name);
    }
    return [];
};

/** the properties passed into the React component. */
interface CustomPropertyBladeProps {
    loading: boolean;
    data: CustomPropertyBladeData;
    error: any;
    /** the handler for the blade closed event. */
    onBladeClosed?: () => void;
    /** the handler for the data save event. */
    setCustomProperty: () => void;
    /**  allowMultiType {boolean} flag */
    allowMultiType: boolean;
}

/** Renders the custom property blade.
 *  @param props the properties passed in.
 *  @returns JSX with the impact summary component.*/
const CustomPropertyBlade = (props: CustomPropertyBladeProps): JSX.Element => {
    const [customProperties, setCustomProperties] = useState<any>(null);
    const [showNameAndDescriptionField, setShowNameAndDescriptionField] = useState<any>(true);
    const [showAddValue, setShowAddValue] = useState<any>(true);
    const [allowEditOfExistingValues, setAllowEditOfExistingValues] = useState<any>(true);
    const [allowDeletingExistingValues, setAllowDeletingExistingValues] = useState<any>(true);

    const customPropertiesQuery = useQuery({
		name: 'CustomProperties',
		query: new Query(loader('../../../../utils/hooks/custom-properties.graphql')),
	});

    useEffect(() => {
        setCustomProperties(customPropertiesQuery.data);
    }, [customPropertiesQuery.data]);

    useEffect(() => {
        if (customProperties && props?.data?.id) {
            const customProperty = customProperties?.customProperties?.page?.find(item => item.id === props.data.id);
            let permissionKey: any = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_UPDATE_PROPERTY)?.[0];
            setShowNameAndDescriptionField(customProperty?.permissions?.includes(permissionKey));
            permissionKey = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_CREATE_PROPERTY_VALUE)?.[0];
            setShowAddValue(customProperty?.permissions?.includes(permissionKey));
            permissionKey = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_UPDATE_PROPERTY_VALUE)?.[0];
            setAllowEditOfExistingValues(customProperty?.permissions?.includes(permissionKey));
            permissionKey = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_DELETE_PROPERTY_VALUE)?.[0];
            setAllowDeletingExistingValues(customProperty?.permissions?.includes(permissionKey));
        }
    }, [props?.data?.id, customProperties]);

    const { loading, data, error, onBladeClosed, setCustomProperty, allowMultiType } = props;

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
 
    const [locations, setLocations] = useState<boolean>(false);
    const [applications, setApplications] = useState<boolean>(false);
    const [devices, setDevices] = useState<boolean>(false);
    const [interfaces, setInterfaces] = useState<boolean>(false);

    const [values, setValues] = useState<Array<CustomPropertyValue | any>>([]);
    const [valuesToDelete, setValuesToDelete] = useState<Array<string>>([]);
    const [uniqueValueError, setUniqueValueError] = useState<boolean>(false);
    
    useEffect(() => {
        setName(data.name);
        setDescription(data.description);
        setLocations(
            data.hasOwnProperty("validTypes") &&
                data.validTypes.filter(
                    (item) => item.type === ValidTypes.LOCATION
                ).length > 0
        );
        setApplications(
            data.hasOwnProperty("validTypes") &&
                data.validTypes.filter(
                    (item) => item.type === ValidTypes.APPLICATION
                ).length > 0
        );
        setDevices(
            data.hasOwnProperty("validTypes") &&
                data.validTypes.filter(
                    (item) => item.type === ValidTypes.NETWORK_DEVICE
                ).length > 0
        );
        setInterfaces(
            data.hasOwnProperty("validTypes") &&
                data.validTypes.filter(
                    (item) => item.type === ValidTypes.NETWORK_INTERFACE
                ).length > 0
        );
        setValues(data?.values ?? []);
        return () => {};
    }, [data]);

    const handleValueDelete = (id: string) => {
        setValues(
            values.filter((value) => {
                return value.id !== id;
            })
        );

        if (!valuesToDelete.includes(id) && !id.includes("add-")) {
            setValuesToDelete([...valuesToDelete, id]);
        }
    };

    const handleSetLocations = (value: boolean) => {
        setLocations(value); 
        if (!allowMultiType) {
            setApplications(false);
            setDevices(false);
            setInterfaces(false);

        }
    }; 

    const handleSetApplications = (value: boolean) => {
        setApplications(value); 
        if (!allowMultiType) {
            setLocations(false);
            setDevices(false);
            setInterfaces(false);
        }
    };

    const handleSetDevices = (value: boolean) => {
        setDevices(value); 
        if (!allowMultiType) {
            setLocations(false);
            setApplications(false);
            setInterfaces(false);
        }
    };

    const handleSetInterfaces = (value: boolean) => {
        setInterfaces(value); 
        if (!allowMultiType) {
            setLocations(false);
            setApplications(false);
            setDevices(false);

        }
    };

    const handleValueAdd = () => {
        const id = "add-" + generateRandomID();
        setValues([...values, { id: id, name: "", action: "add" }]);
    };

    const handleValueUpdate = (id: string, name: string) => {
        const uniqueValue = values
            .map((value) => {
                if (value.name === name.trim()) {
                    return false;
                } else {
                    return true;
                }
            })
            .includes(false);
        setUniqueValueError(uniqueValue);
        setValues(
            values.map((value) => {
                if (value.id === id) {
                    return Object.assign({}, value, {
                        id: id,
                        name: name,
                        action: "edit",
                    });
                } else {
                    return Object.assign({}, value, value);
                }
            })
        );
    };

    const handleSubmit = (values, { setSubmitting }) => {
        let validTypes: Array<object> = [];
        applications && validTypes.push({ type: ValidTypes.APPLICATION });
        locations && validTypes.push({ type: ValidTypes.LOCATION });
        devices && validTypes.push({ type: ValidTypes.NETWORK_DEVICE });
        interfaces && validTypes.push({ type: ValidTypes.NETWORK_INTERFACE });

        const valuesToCreate = values.values
            .map((value) => {
                if (value.id.includes("add-") && value.action === "edit") {
                    return { name: value.name };
                } else return null;
            })
            .filter((value) => value !== null);

        const valuesToSet = values.values
            .map((value) => {
                if (value.action === "edit" && !value.id.includes("add-")) {
                    return { id: value.id, name: value.name };
                } else return null;
            })
            .filter((value) => value !== null);

        const payload = {
            variables: {
                id: data.id,
                customProperty: {
                    name: values.name && values.name,
                    description: values.description && values.description,
                    validTypes: validTypes,
                },
                valuesToCreate: valuesToCreate,
                valuesToSet: valuesToSet,
                valuesToDelete: valuesToDelete,
            },
        };

        try {
            // @ts-ignore
            setCustomProperty(payload).finally(() => {
                setSubmitting(false);
            });
        } catch (error) {
            setSubmitting(false);
            return Promise.reject("Error updating custom property.");
        }
    };

    return (
        <div className="tir-custom-property">
            {error ? (
                <Label>{error}</Label>
            ) : (
                <Form
                    initialValues={{
                        name: name,
                        description: description,
                        locations: locations,
                        applications: applications,
                        devices: devices,
                        interfaces: interfaces,
                        values: values,
                    }}
                    onSubmit={handleSubmit}
                    loading={loading}
                >
                    {showNameAndDescriptionField && <><div className="row">
                        <div className="col-md-3 mb-1">
                            <Label>
                                {
                                    STRINGS.CUSTOM_PROPERTIES_PAGE
                                        .customPropertyBlade.name
                                }
                            </Label>
                        </div>
                        <div className="col-md-9">
                            <InputGroup
                                id={"custom-prop-name"}
                                data-testid={"custom-prop-name"}
                                disabled={isSystemCustomProperty(data)}
                                name={"name"}
                                fill={true}
                                required={true}
                                defaultValue={data.name}
                                onChange={(event) => {
                                    setName(event.currentTarget.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <Label>
                                {
                                    STRINGS.CUSTOM_PROPERTIES_PAGE
                                        .customPropertyBlade.description
                                }
                            </Label>
                        </div>
                        <div className="col-md-9 mb-2">
                            <TextArea
                                id="custom-prop-description"
                                data-testid="custom-prop-description"
                                disabled={isSystemCustomProperty(data)}
                                name={"description"}
                                defaultValue={data.description}
                                onChange={(event) => {
                                    setDescription(event.currentTarget.value);
                                }}
                                fill={true}
                                style={{
                                    height: "50px",
                                }}
                            />
                        </div>
                    </div></>}
                    <div className="row">
                        <div className="col-md-3">
                            <Label>
                                {
                                    STRINGS.CUSTOM_PROPERTIES_PAGE
                                        .customPropertyBlade.applicableTo.label
                                }
                            </Label>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-4">
                                    <Label>
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade
                                                .applicableTo.locations
                                        }
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        id="custom-properties-locations"
                                        name="locations"
                                        alignIndicator={Alignment.RIGHT}
                                        inline={true}
                                        disabled={isSystemCustomProperty(data)}
                                        onChange={(event) => {
                                            handleSetLocations(
                                                event.currentTarget.checked
                                            );
                                        }}
                                        checked={locations}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <a
                                        href={getURL(
                                            getURLPath("explorer"),
                                            {
                                                searchType: "location",
                                                groupedFacets: {
                                                    [data.name]:
                                                        getArrayNamesFromValues(data),
                                                },
                                            },
                                            { replaceQueryParams: true }
                                        )}
                                        className="text-decoration-underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade.goLabel
                                        }
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Label>
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade
                                                .applicableTo.applications
                                        }
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        id="custom-properties-applications"
                                        name="applications"
                                        alignIndicator={Alignment.RIGHT}
                                        inline={true}
                                        disabled={isSystemCustomProperty(data)}
                                        onChange={(event) => {
                                            handleSetApplications(
                                                event.currentTarget.checked
                                            );
                                        }}
                                        checked={applications}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <a
                                        href={getURL(
                                            getURLPath("explorer"),
                                            {
                                                searchType: "application",
                                                groupedFacets: {
                                                    [data.name]:
                                                        getArrayNamesFromValues(data),
                                                },
                                            },
                                            { replaceQueryParams: true }
                                        )}
                                        className="text-decoration-underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade.goLabel
                                        }
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Label>
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade
                                                .applicableTo.devices
                                        }
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        id="custom-properties-devices"
                                        name={"devices"}
                                        alignIndicator={Alignment.RIGHT}
                                        inline={true}
                                        disabled={isSystemCustomProperty(data)}
                                        onChange={(event) => {
                                            handleSetDevices(
                                                event.currentTarget.checked
                                            );
                                        }}
                                        checked={devices}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <a
                                        href={getURL(
                                            getURLPath("explorer"),
                                            {
                                                searchType: "device",
                                                groupedFacets: {
                                                    [data.name]:
                                                        getArrayNamesFromValues(data),
                                                },
                                            },
                                            { replaceQueryParams: true }
                                        )}
                                        className="text-decoration-underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade.goLabel
                                        }
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Label>
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade
                                                .applicableTo.interfaces
                                        }
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        id="custom-properties-interfaces"
                                        name={"interfaces"}
                                        alignIndicator={Alignment.RIGHT}
                                        inline={true}
                                        disabled={isSystemCustomProperty(data)}
                                        onChange={(event) => {
                                            handleSetInterfaces(
                                                event.currentTarget.checked
                                            );
                                        }}
                                        checked={interfaces}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <a
                                        href={getURL(
                                            getURLPath("explorer"),
                                            {
                                                searchType: "interface",
                                                groupedFacets: {
                                                    [data.name]:
                                                        getArrayNamesFromValues(data),
                                                },
                                            },
                                            { replaceQueryParams: true }
                                        )}
                                        className="text-decoration-underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {
                                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                                .customPropertyBlade.goLabel
                                        }
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="text-center font-weight-bold display-8 mt-3">
                        <Label>
                            {
                                STRINGS.CUSTOM_PROPERTIES_PAGE
                                    .customPropertyBlade.values.title
                            }
                        </Label>
                    </div>
                    <div className="mt-2 mb-2">
                        {values &&
                            values.length > 0 &&
                            values.map((value, index) => {
                                return (
                                    <div
                                        key={value.id + "-" + index}
                                        className="row ml-2"
                                    >
                                        <>
                                            <InputGroup
                                                id={value.id}
                                                data-testid={
                                                    "custom-prop-value"
                                                }
                                                disabled={isSystemCustomProperty(data) || !allowEditOfExistingValues}
                                                className="col-md-11"
                                                name={"value"}
                                                fill={false}
                                                required={true}
                                                placeholder={
                                                    STRINGS
                                                        .CUSTOM_PROPERTIES_PAGE
                                                        .customPropertyBlade
                                                        .valuePlaceholder
                                                }
                                                defaultValue={value.name}
                                                onChange={(event) => {
                                                    handleValueUpdate(
                                                        value.id,
                                                        event.currentTarget
                                                            .value
                                                    );
                                                }}
                                            />
                                            <Label className="col-md-1">
                                                <span className="d-flex-inline align-items-center justify-content-between">
                                                    <WrapInTooltip
                                                        tooltip={
                                                            STRINGS
                                                                .CUSTOM_PROPERTIES_PAGE
                                                                .customPropertyBlade
                                                                .buttons.delete
                                                        }
                                                    >
                                                        <Button
                                                            disabled={isSystemCustomProperty(data) || !allowDeletingExistingValues}
                                                            onClick={() => {
                                                                handleValueDelete(
                                                                    value.id
                                                                );
                                                            }}
                                                            minimal
                                                            icon={
                                                                IconNames.TRASH
                                                            }
                                                        />
                                                    </WrapInTooltip>
                                                </span>
                                            </Label>
                                        </>
                                    </div>
                                );
                            })}
                        {uniqueValueError && (
                            <div>
                                <Label className="text-danger ml-1">
                                    {
                                        STRINGS.CUSTOM_PROPERTIES_PAGE
                                            .customPropertyBlade.messages
                                            .uniqueValue
                                    }
                                </Label>
                            </div>
                        )}
                    </div>
                    {showAddValue && <Button
                        aria-label="add-value-element"
                        className="btn-dotted p-2 mb-4"
                        icon={IconNames.ADD}
                        fill
                        disabled={isSystemCustomProperty(data)}
                        text={
                            STRINGS.CUSTOM_PROPERTIES_PAGE
                                .customPropertyBlade.values.addValue
                        }
                        onClick={() => handleValueAdd()}
                    />}
                    <div>
                        <Button
                            className="mr-2"
                            aria-label="cancel"
                            type="button"
                            outlined
                            onClick={() => onBladeClosed && onBladeClosed()}
                        >
                            {
                                STRINGS.CUSTOM_PROPERTIES_PAGE
                                    .customPropertyBlade.buttons.closeBtnText
                            }
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            aria-label="submit"
                            disabled={isSystemCustomProperty(data)}
                            type="submit"
                        >
                            {
                                STRINGS.CUSTOM_PROPERTIES_PAGE
                                    .customPropertyBlade.buttons.submitBtnText
                            }
                        </Button>
                    </div>
                </Form>
            )}
        </div>
    );
};

/** this function returns whether or not a custom property is a system custom property.
 *  @param searchResult the custom property search result.
 *  @returns a boolean value, which is true if the property is a system property, false otherwise. */
function isSystemCustomProperty(searchResult: any): boolean {
    return searchResult && (/*searchResult.name === "Rollup" ||*/ searchResult.type === CustomPropertyType.System);
}

export { CustomPropertyBlade };
