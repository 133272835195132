import React, { useState, useCallback } from 'react';


//we do this to have a way to open modals anywhere in the application, this is much simpler than having a service or data store just for this
type openModalFunctionType = (name: (string | undefined), props?: object) => void;
let openModal: openModalFunctionType = () => {
    // notify developer in case of misuse, since the real function gets initialized by the component instance
    console.error('To use openModal functionality a ModalRenderer component has to be part of the layout');
};

function ModalRenderer (props: { modals: { [index: string]: any } }) {

    const [modal, setModal] = useState<string | undefined>(undefined);
    const [modalProps, setModalProps] = useState<object | undefined>(undefined); // todo put here modal props type

    openModal = useCallback((name: string | undefined, props?: object) => {
        setModal(name);
        if (name) {
            const onClose = (data) => {
                setModal(undefined);
                if(props && props['onClose']) {
                    props['onClose'](data);
                }
            };
            setModalProps(Object.assign({}, props, {onClose: onClose}));
        } else {
            setModalProps(undefined);
        }
    }, [setModal, setModalProps]);

    if (modal && modalProps) {  //you have to pass at least some props to render the modal
        const Modal = props.modals[modal];
        if (Modal) {
            return (
                <Modal {...modalProps}/>
            );
        } else {
            console.error(`Modal "${modal}" is not defined.`);
        }

    }

    return null;
}

export { ModalRenderer, openModal }

