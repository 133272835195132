import { createContext } from "react";
import { VariableCollection } from "./VariablesUtils";

export const VariableContext = createContext(
    {
        getVariables: (scope, builtIn?): VariableCollection => {return {primitiveVariables: [], structuredVariables: []}}, 
        setVariables: (scope, data?) => {}, 
        syncRuntimeOrSubflowVariables: (scope, data?) => {}
    }
);
