import React from 'react';
import classnames from 'classnames';
import { Modal } from '@tir-ui/react-components';
import { Form, Formik } from 'formik';
import { Classes } from '@blueprintjs/core';
import "../form/Form.scss";

/**
 * This is a combination wrapper for a modal that has a form inside,
 * it takes care of all the boilerplate required to have a form inside a modal
 * so there is no need to repeat the logic in every modal
 * @param formikProps
 * @param children
 * @param modalProps
 */
//TODO change to the new standard way of declaring props type we are using
function FormModal({formikProps, children, ...modalProps}: any) { //todo how to validate types correctly

    let formikApi: any;

    function handleModalSubmit() {
        /**
         * https://github.com/jaredpalmer/formik/issues/1580
         * Contrary to what the documentation says, submitForm does not reject the promise with a failed form,
         * until that gets fixed, handling it manually
         *
         * 2.0.7 changelog claims this was fixed, but I tried 2.0.8 and does not seem the case, so the code stays like this for now
         */
        //return submitForm();
        return new Promise((resolve, reject) => {
            return formikApi.submitForm()
                .then(formikApi.validateForm)
                .then((errors: {}) => {
                    //validation errors will be handled here
                    const isValid = Object.keys(errors).length === 0;

                    if (isValid) {
                        resolve();
                    } else {
                        reject({}); //we don't need any extra error, we rely on the field errors
                    }
                })
                .catch((error: any) => {
                    //this instead handle errors from the api
                    // if we know how to read the error, we could assign here a specific error to a specific field
                    // instead of showing the generic error
                    reject(error.message);
                })
                .finally(() => {
                    //resetting submission state has to be done manually in formik
                    formikApi.setSubmitting(false);
                });
        });

    }

    return (
        <Modal onSubmit={handleModalSubmit} {...modalProps}>
            <div className={classnames('form-modal-content', {[Classes.SKELETON]: modalProps.loading})} >
                {modalProps.loading ? null : //todo would be nice to display a skeleton version of the form, but it's a bit complicated with initialization, leaving it as future finesse
                    <Formik {...formikProps}>
                        {
                            (props) => {
                                // bring references outside to be able to call these from the parent
                                formikApi = props;
                                return (
                                    <Form>
                                        {children}
                                    </Form>
                                );
                            }
                        }
                    </Formik>
                }
            </div>
        </Modal>
    );

}

export { FormModal };



