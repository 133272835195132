/** This module contains the component that displays a chart that displays the 
 *  training and prediction data for the trend analysis.
 *  @module
 */

import React, { useState, useEffect } from "react";
import { Switch } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { TimeseriesTileForDemo } from "components/reporting/tiles/timeseries-tile/TimeseriesTileForDemo";
import { formatToLocalTimestamp, scaleMetric } from "reporting-infrastructure/utils/formatters";
import { TIME_FORMAT } from "components/enums";
import { getLabelForMetric } from "utils/stores/GlobalUnitsStore";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import CapacityPlanningData from './CapacityPlanning.json';
import BryansData from './BryansData2.json';
import { Icon, IconNames } from "@tir-ui/react-components";

/** the color for the threshold line. */
const THRESHOLD_LINE_COLOR = "red";
/** the opacity for the threshold line. */
const THRESHOLD_LINE_OPACITY = 0.5;

/** this interface defines the properties that are passed in to the AnalysisChart React component. */
export interface AnalysisChartProps {
    /** a number with the height of the chart. */
    height: number;
    /** a number with the timeout for the fake query to get the data in milliseconds. */
    timeout: number;
    /** a number with the first threshold. */
    threshold1: number;
    /** a number with the second threshold. */
    threshold2: number;
}

/** Renders the analysis chart React component.
 *  @param props the properties passed in.
 *  @returns JSX with the incident table actions component.*/
const AnalysisChart = (props: AnalysisChartProps) => {
    const [showPrediction, setShowPrediction] = useState<boolean>(true);

    const example: string = "example3";
    
    let [timeRange, setTimeRange] = useState<any>();
    let [indicatorData, setIndicatorData] = useState<any>();
    let [seriesData, setSeriesData] = useState<any>();;
    const yAxisUnit = Unit.parseUnit(CapacityPlanningData[example].indicatorData.unit);
    const plotBands = CapacityPlanningData[example].indicatorData.plotBands;

    useEffect(() => {
        const getData = () => {
            const newIndicatorData: any = CapacityPlanningData[example].indicatorData;
            const newSeriesData = JSON.parse(JSON.stringify(CapacityPlanningData[example].seriesData));

            /**/
            const filteredData = BryansData.data.filter((datum, index) => {
                return (showPrediction && (index < 500 || index % 50 === 0)) || (!showPrediction && (index < 500 || (index < 4000 && index % 50 === 0)));
            });

            newIndicatorData.samples = [];
            filteredData.forEach((datum, index) => {
                const time = parseInt(datum.ts);
                newIndicatorData.samples.push({x: time, y: datum.actual_value});
            });
            newSeriesData[1].data = [];
            filteredData.forEach((datum, index) => {
                const time = parseInt(datum.ts);
                newSeriesData[1].data.push({x: time, low: datum.acceptable_low_value, high: datum.acceptable_high_value});    
            });
            /**/

            let startTime = Number.MAX_SAFE_INTEGER;
            let endTime = 0;
            newIndicatorData.samples.forEach((datum) => {
                const time = datum.x;
                if (time < startTime) {
                    startTime = time;
                }
                if (time > endTime) {
                    endTime = time;
                }    
            });
            //let timeRange =  CapacityPlanningData[example].timeRange; //{"startTime": 1633714200000, "endTime": 1633722300000};
            let timeRange = {startTime, endTime};

            newSeriesData.push({
                type: "line",
                color: THRESHOLD_LINE_COLOR,
                dashStyle: "Dash",
                opacity: THRESHOLD_LINE_OPACITY,
                data: [{
                    x: timeRange.startTime,
                    y: props.threshold1, //newIndicatorData.threshold,
                }, {
                    x: timeRange.endTime,
                    y: props.threshold1, //newIndicatorData.threshold,
                }],
                enableMouseTracking: false,
            } as any);

            newSeriesData.push({
                type: "line",
                color: THRESHOLD_LINE_COLOR,
                dashStyle: "Dash",
                opacity: THRESHOLD_LINE_OPACITY,
                data: [{
                    x: timeRange.startTime,
                    y: props.threshold2, //newIndicatorData.threshold,
                }, {
                    x: timeRange.endTime,
                    y: props.threshold2, //newIndicatorData.threshold,
                }],
                enableMouseTracking: false,
            } as any);

            setTimeRange(timeRange);
            setIndicatorData(newIndicatorData);
            setSeriesData(newSeriesData);
        };
        if (props.timeout === 0) {
            getData();
        } else {
            setTimeout(() => {
                getData();
            }, props.timeout);    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPrediction]);

    function formatTooltipData(this: any) {
        let toolTip = formatToLocalTimestamp(new Date(this.x), TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT);
        const symbol = '&#9632;';
        const color = true/*isBaseline*/ ? this.points[0].color : this.point.color;
        const opacity = true/*isBaseline*/ ? this.points[0].series.options.opacity : this.point.series.options.opacity;
        const actualValue =
                '<div><span style="font-size:16px;color:' +
                color + ';opacity:' + opacity +
                '">' +
                symbol +
                '</span>' +
                '<b><span> ' +
                STRINGS.incidents.columns.actual +
                '</span></b> : <b>' +
                scaleMetric(this.y, Unit.parseUnit(indicatorData?.unit || "")).formatted +
                '</b></div>';
        toolTip += actualValue;

        if (true/*isBaseline*/ && this.points[1]) {
            const expectedRange =
                    '<div><span style="font-size:16px;color:' +
                    this.points[1].series.options.fillColor + ';opacity:' + this.points[1].series.options.opacity +
                    '">' +
                    symbol +
                    '</span>' +
                    '<b><span> ' +
                    STRINGS.incidents.timeseriesChart.expectedRange +
                    '</span></b> : <b>' +
                    scaleMetric(this.points[1].point.options.low, Unit.parseUnit(indicatorData?.unit || "")).formatted + '&ndash;' +
                    scaleMetric(this.points[1].point.options.high, Unit.parseUnit(indicatorData?.unit || "")).formatted +
                    '</b></div>';
            toolTip += expectedRange;
        } else if (indicatorData?.threshold) {
            const expectedRange =
                    '<div>' +
                        '<span style="font-size:16px;color:' + THRESHOLD_LINE_COLOR + ';opacity:' + THRESHOLD_LINE_OPACITY + '">' + symbol + '</span>' +
                        '<b><span> ' + STRINGS.incidents.timeseriesChart.threshold + '</span></b> : ' +
                        '<b>' + scaleMetric(indicatorData.threshold, Unit.parseUnit(indicatorData?.unit || "")).formatted + '</b>' +
                    '</div>';
            toolTip += expectedRange;
        }
        return toolTip;
    }

    return (
		<>
            <div className="d-flex justify-content-between align-items-center mb-4 ml-4">
                <div className="d-flex align-items-center">
                    <Icon icon={IconNames.LAN} iconSize={32} className="mr-4" />
                    <div className="d-flex flex-column align-items-center mr-4">
                        <div className="font-weight-bold mb-2">GigabitEthernet 1/0/1</div>
                        <div className="mb-2">on</div>
                        <div className="font-weight-bold">BOS4500WANRtr2</div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <Icon icon={IconNames.TIME} iconSize={32} className="mr-4" />
                    <div className="d-flex flex-column align-items-center">
                        <div>6 months</div>
                        <div>
                            <Switch
                                data-testid="show-only-training-data"
                                checked={showPrediction}
                                disabled={false}
                                inline={true}
                                label="Show Prediction"
                                onChange={(e) => {
                                    const checked = e.currentTarget.checked;
                                    setShowPrediction(checked);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DataLoadFacade showContentsWhenLoading={true} loading={indicatorData === undefined}>
                {timeRange &&<TimeseriesTileForDemo
                    chartData={indicatorData?.samples}
                    // Apply a max Y height of 100 when the indicator being displayed is a percentage type and the max seen value is between 10 and 100
                    yMax={
                        (indicatorData?.unit === "%" && indicatorData.maxValue < 100 && indicatorData.maxValue > 10) ? 100 : undefined
                    }
                    yMin={
                        (indicatorData?.unit === "%" ) ? 0 : undefined
                    }
                    //className="flex-grow-1"
                    className="h-min-3"
                    config={{
                        chart: {
                            height: props.height
                        },
                        xAxis: {
                            visible: true,
                            type: "datetime",
                            min: timeRange.startTime,
                            max: timeRange.endTime,
                            labels: {
                                formatter: function (this:any) {
                                    return formatToLocalTimestamp(this.value, TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT);
                                }
                            },
                            crosshair: {
                                snap: true,
                                width: 1,
                            },
                            plotBands: plotBands
                        },
                        yAxis: {
                            visible: true, //isBaseline,
                            type: "number",
                            title: {
                                text: getLabelForMetric(indicatorData?.metric || "") + " " + 
                                    (yAxisUnit && yAxisUnit.getDisplayName() !== '' ? '(' + yAxisUnit.getDisplayName() + ')' : ''),
                            },
                            labels: {
                                formatter: function (this: any) {
                                    return scaleMetric(this.value, new Unit()).formatted;
                                }
                            }
                        },
                        tooltip: {
                            useHTML: true,
                            shared: true/*isBaseline*/,
                            split: false,
                            formatter: formatTooltipData
                            /*(isBaseline || isThreshold) ? formatTooltipData : function (this:any) {
                                return scaleMetric(this.y, Unit.parseUnit(indicatorData?.unit || "")).formatted
                            }*/
                        },
                        series: seriesData,
                        annotations: [
                            /*
                            {
                                draggable: true,
                                labels: {
                                    point: {
                                        x: 1635820200000,
                                        y: 0,
                                        xAxis: 0, yAxis: 0
                                    },
                                    x: 60, y: -30,
                                    //distance: 5,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    backgroundColor: '#03a9f4',
                                    text: "Training",
                                    shape: 'callout',
                                    //borderWidth: 0,
                                    borderColor: '#03a9f4',
                                    justify: true,
                                    allowOverlap: true,
                                    style: {
                                        textAlign: 'center',
                                        color: 'white',
                                        fontSize: '14px'
                                    },
                                }
                            },
                            {
                                draggable: true,
                                labels: {
                                    point: {
                                        x: 1638264900000,
                                        y: 0,
                                        xAxis: 0, yAxis: 0
                                    },
                                    x: 90, y: -30,
                                    //distance: 5,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    backgroundColor: '#03a9f4',
                                    text: "At Threshold 1",
                                    shape: 'callout',
                                    //borderWidth: 0,
                                    borderColor: '#03a9f4',
                                    justify: true,
                                    allowOverlap: true,
                                    style: {
                                        textAlign: 'center',
                                        color: 'white',
                                        fontSize: '14px'
                                    },
                                }
                            },
                            {
                                draggable: true,
                                labels: {
                                    point: {
                                        x: 1646004900000,
                                        y: 0,
                                        xAxis: 0, yAxis: 0
                                    },
                                    x: 90, y: -30,
                                    //distance: 5,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    backgroundColor: '#03a9f4',
                                    text: "At Threshold 2",
                                    shape: 'callout',
                                    //borderWidth: 0,
                                    borderColor: '#03a9f4',
                                    justify: true,
                                    allowOverlap: true,
                                    style: {
                                        textAlign: 'center',
                                        color: 'white',
                                        fontSize: '14px'
                                    },
                                }
                            }
                                */
                        ]
                    }}
                />}
                {/*<Callout intent='primary'>
                    <p>Based on the forecasted analysis of <b style={{fontSize: "16px"}}>1 month</b>, this interface will likely meet or exceed the <b style={{fontSize: "16px"}}>90%</b>
                    threshold in <b style={{fontSize: "16px"}}>1 days</b> time if trends continue.</p>
                    <p>In <b style={{fontSize: "16px"}}>2 days</b> time this interface will likely
                    meet or exceed the <b style={{fontSize: "16px"}}>100%</b> threshold if trends continue.</p>
                    <p>If you would like to investigate the top talkers, <u>click here</u>.</p>
                </Callout>*/}
                <div className="d-flex align-items-center mt-4 ml-4 px-3 pt-3 pb-2" style={{backgroundColor: '#137cbd26', borderRadius: '12px'}}>
                    <Icon icon={IconNames.AI_BIS_1} iconSize={32} className="mr-4" />
                    <div>
                        <p>Based on the forecasted analysis of <b style={{fontSize: "16px"}}>6 months</b>, this interface will likely meet or exceed the <b style={{fontSize: "16px"}}>80%</b> threshold in <b style={{fontSize: "16px"}}>29 days</b> time if trends continue.</p>
                        <p>In <b style={{fontSize: "16px"}}>115 days</b> time this interface will likely meet or exceed the <b style={{fontSize: "16px"}}>100%</b> threshold if trends continue.</p>
                        <p>If you would like to investigate the top talkers, <u>click here</u>.</p>
                    </div>
                </div>
            </DataLoadFacade>
		</>
	);
};

export default AnalysisChart;
