import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Callout, Intent } from '@blueprintjs/core';
import { AuthService } from '@tir-ui/azure-auth';
import { ErrorToaster, LoadingOverlay, useDataLoading } from '@tir-ui/react-components';
import { FormModal } from 'components/common/modal';
import { TagListField } from 'components/common/form';
import { TenantManagementService } from 'utils/services/TenantManagementService';
import { validateDomain } from 'utils/validators/Validators';
import { STRINGS } from 'app-strings';

const fetchDomains = () => {
    const tenantId = AuthService.getTenantId();
    return TenantManagementService.get(`common/tenant/${tenantId}/domains`);
};

export const EditDomainsModal = (props: any) => {
    const [data, loading] = useDataLoading<any>(fetchDomains, []);
    const [domains, setDomains] = useState<any>();

    useEffect(() => {
        const domains =
            data && data.hasOwnProperty('items')
                ? data.items.map((domain) => domain.domain_name)
                : [];
        setDomains(domains);
    }, [data]);

    const validationSchema = yup.object().shape({
        domains: yup
            .array()
            .of(
                validateDomain(
                    '',
                    STRINGS.EDIT_DOMAINS.validation.invalidDomain
                )
            )
            .required(STRINGS.EDIT_DOMAINS.validation.valueRequired),
    });

    const handleSubmitResponse = (values, responseData) => {
        let message: any = undefined;
        if ( responseData &&
            ('failed_deletions' in responseData ||
            'failed_additions' in responseData) &&
            (responseData.failed_deletions.length > 0 ||
            responseData.failed_additions.length > 0)
        ) {
            setDomains(
                values.domains
                    .concat(responseData.failed_deletions)
                    .filter(
                        (item) => !responseData.failed_additions.includes(item)
                    )
            );
            message =
                STRINGS.EDIT_DOMAINS.errors.updateFailure +
                ': ' +
                [
                    responseData.failed_additions.length > 0
                        ? responseData.failed_additions
                        : null,
                    responseData.failed_deletions.length > 0
                        ? responseData.failed_deletions
                        : null,
                ]
                    .filter(Boolean)
                    .join(',');
        }
        return message;
    };

    const formConfig = {
        initialValues: {
            domains: domains,
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            const tenantId = AuthService.getTenantId();
            return new Promise((resolve, reject) => {
                TenantManagementService.put(
                    `common/tenant/${tenantId}/domains`,
                    values
                ).then(
                    (response) => {
                        const message: string = handleSubmitResponse(values, response);
                        message
                            ? reject({ message: message })
                            : resolve(response);
                    },
                    (error) => {
                        const message: string = handleSubmitResponse(values, error.response.data);
                        ErrorToaster({
                            message: message
                        });
                        reject({
                            message: message,
                        });
                    }
                );
            });
        },
    };

    return (
        <FormModal
            title={STRINGS.EDIT_DOMAINS.title}
            formikProps={formConfig}
            {...props}
        >
            <div className="tir-domains">
                <Callout intent={Intent.NONE}>
                    {STRINGS.EDIT_DOMAINS.helpText}
                </Callout>
                <br />
                {loading ? (
                    <LoadingOverlay visible={true}></LoadingOverlay>
                ) : (
                    <TagListField
                        name="domains"
                        label={STRINGS.EDIT_DOMAINS.labelText}
                        className="domains-field"
                        inline={false}
                        fill={true}
                        placeholder={STRINGS.EDIT_DOMAINS.placeholderText}
                        tagProps={{
                            intent: Intent.PRIMARY,
                            large: false,
                            minimal: false,
                        }}
                        values={domains}
                    />
                )}
                <Callout intent={Intent.PRIMARY}>
                    {STRINGS.EDIT_DOMAINS.warningText}
                </Callout>
            </div>
        </FormModal>
    );
};
