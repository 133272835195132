import React, { ReactNode } from 'react';
import { Button } from '@blueprintjs/core';
import { SIZE } from 'components/enums/Sizes';
import { IconTitle, IconTitleProps } from 'components/common/icon-title/IconTitle';
import { IconNames } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { Link } from 'react-router-dom';

export interface BladeContainerProps extends IconTitleProps {
    /** A flag to control if back button should be displayed in the blade or not. Defaults to false */
    showBackButton?: boolean;
    /** Callback handler for clicking the back button in blade */
    onBackClicked?: (mouseEvent) => void;
    /** A flag to control if close button should be displayed in the blade or not. Defaults to true */
    showCloseButton?: boolean;
    /** Callback handler for clicking the close button in blade */
    onCloseClicked?: (mouseEvent) => void;
    /** A flag to control if a link to drill down into details should be displayed. Defaults to false */
    showDetailsLink?: boolean;
    /** The target URL to which this link should take the user to. It's a passthrough to the 'to' property of <Link/> */
    detailsLink?: string;
    /** Label override to be used for the link. Defaults to "View Details" */
    detailsLinkLabel?: string;
    /** Callback handler for clicking the link. You may choose to not provide a link and instead respond to the click handler */
    onDetailsLinkClicked?: Function;
    /** Title/Content to be displayed in the header */
    headerContent?: string | ReactNode;
    /** Content to be displayed in the footer*/
    footerContent?: string | ReactNode;
    /** When passed as true, it will not add any padding to the contents displayed in the lower contents section. Defaults to false */
    noContentPadding?: Boolean;
    /** Contents to be displayed in the blade */
    children?: ReactNode;
    /** Style class to be applied to the outer container element of the blade */
    className?: string;
}

function BladeContainer({
    showBackButton = false,
    onBackClicked,
    showCloseButton = true,
    onCloseClicked,
    showDetailsLink = false,
    detailsLink,
    detailsLinkLabel = STRINGS.viewDetails,
    onDetailsLinkClicked,
    headerContent,
    footerContent,
    noContentPadding = false,
    className,
    ...props
}: BladeContainerProps) {
    // If headerContent was plain string, wrap it in a div with required margin and formatting CSS classes
    const headerJSX = headerContent && <div className={"my-2" + typeof headerContent === "string" ? " display-8" : ""}>{headerContent}</div>;
    return <div className={"blade-container d-flex flex-column h-100" + (className ? " " + className : "")}>
        <div className="p-3 w-min-3">
            {
                (props.icon || props.title || showBackButton || showCloseButton) &&
                <div className="d-flex flex-row justify-content-between">
                    <div className={props.centeredTitle ? 'text-center' : ''}>
                        {
                            showBackButton &&
                            <Button className="back-button" role="navigate back" icon={IconNames.ARROW_LEFT} minimal={true}
                                onClick={(e) => onBackClicked && onBackClicked(e)} />
                        }
                    </div>
                    <div style={{flexGrow: 1}} className='titleContainer'>
                        {props.title && <IconTitle size={SIZE.m} bold={true} {...props} />}
                    </div>
                    {
                        showCloseButton && 
                        <span>
                            <Button className="close-button" role="close blade" icon={IconNames.CROSS} minimal={true}
                                onClick={(e) => onCloseClicked && onCloseClicked(e)} />
                        </span>
                    }
                </div>
            }
            {headerJSX}
            {showDetailsLink && <Link
                to={detailsLink || ""}
                onClick={() => { if (onDetailsLinkClicked) { onDetailsLinkClicked(); } }}
                className="mt-2 mb-1 show-details-button d-inline-block float-right"
            >{detailsLinkLabel}</Link>
            }
        </div>
        {/* Review overflow here */}
        <div className={(noContentPadding ? "" : "py-2 px-4") + " border-top overflow-x-hidden h-100 blade-container-content"}>
            {props.children}
        </div>
        {footerContent &&
            <div className="border-top p-3">
                {footerContent}
            </div>
        }

    </div>;
}

export { BladeContainer };
