/** This module contains the component for the graph definitions view
 *  @module
 */
import React from 'react';
import { STRINGS } from 'app-strings';
import { HTMLSelect, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { Form } from 'components/common/form';
import * as yup from 'yup';
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { ElementForStructuredVariable, PrimitiveVariableType } from 'utils/runbooks/VariablesUtils';
import { InputFieldWithErrorTooltip } from 'components/common/form/InputFieldWithErrorTooltip';

import './VariableDefinitionView.scss';

type StructuredFieldProps = {
    index,
    variable: ElementForStructuredVariable,
    isProperty?: boolean,
    builtin: boolean,
    handleDelete,
    handleVariableChange,
    errorsListener,
}

export default function StructuredVariableField({index, variable, isProperty = false, errorsListener, handleVariableChange, handleDelete, builtin, ...props}: StructuredFieldProps) {
    
    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.name)
            .required()
            .matches(/^([a-zA-Z0-9]|-)*$/, 'No space or special characters supported for variable names'),
        type: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.type)
            .required(),
        unit: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.unit)
            .oneOf(Unit.VAR_UNITS),
        defaultValue: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.initValue)
	});

    return (<Form
        initialValues={{
            label: variable.label,
            type: variable.type,
            unit: Unit.VAR_UNITS.slice(-1)[0],
            defaultValue: ''
        }}
        loading={false}
        validationSchema={validationSchema}
        >
            <div className='properties-grid pb-2'>
                <span>
                    {/* Grid alignment */}
                </span>
                <span>
                    <InputFieldWithErrorTooltip
                        name="label"
                        placeholder={STRINGS.runbookEditor.variableDefinitions.variableTable.name}
                        value={variable.label || ""}
                        fill={true}
                        onChange={e => handleVariableChange(e, isProperty, index)}
                        notifyErrors={(name, errors) => errorsListener(index, errors)}
                        disabled={builtin}>
                    </InputFieldWithErrorTooltip>
                </span>
                <span>
                    <HTMLSelect 
                        fill={true} 
                        name="type"
                        disabled={builtin}
                        options={
                            Object.keys(PrimitiveVariableType).map((key) => {
                                return {label: STRINGS.runbookEditor.variableDefinitions.primitiveVariable.types[key], value: PrimitiveVariableType[key]}
                            })}
                            value={variable.type}
                            onChange={e => handleVariableChange(e, isProperty, index)}
                    />
                </span>
                <span>
                    <HTMLSelect 
                        disabled={builtin || (variable.type !== PrimitiveVariableType.INTEGER && variable.type !== PrimitiveVariableType.FLOAT)}
                        fill={true} 
                        name="unit"
                        options={Unit.VAR_UNITS}
                        value={variable.unit || Unit.VAR_UNITS[Unit.VAR_UNITS.length - 1]}
                        onChange={e => handleVariableChange(e, isProperty, index)}/>
                </span>
                <span>
                    <InputFieldWithErrorTooltip 
                        placeholder={STRINGS.runbookEditor.variableDefinitions.variableTable.initValue}
                        name="defaultValue"
                        disabled={true}/>
                </span>
                <span className='d-flex align-items-center justify-content-center'>
                    <Icon onClick={() => {
                        if (!builtin) {
                            handleDelete(index, isProperty);
                        }
                    }}
                        intent={builtin ? Intent.DANGER : Intent.NONE}
                        icon={IconNames.CROSS}/>
                </span>
            </div>
    </Form>)
}