/** This file defines the a bunch of utilities for creating map data.
 *  @module */

export const siteListQuerySites: any[] = [
    {
        id: "100",
        name: "Paris",
        severity: {value: "CRITICAL", score: 90},
        location: {
            latitude: "48.856613",
            longitude: "2.352222"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "Paris"
            }, {
                id: "USSDCOXUNFL01D02",
                cityName: "Versailles"
            }
        ],
    }, {
        id: "101",
        name: "New York",
        severity: {value: "CRITICAL", score: 90},
        location: {
            latitude: "40.712776",
            longitude: "-74.005974"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "New York"
            }
        ]
    }, {
        id: "102",
        name: "San Jose",
        severity: {value: "DEGRADED", score: 90},
        location: {
            latitude: "37.338207",
            longitude: "-121.886330"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "San Jose"
            }
        ]
    }, {
        id: "103",
        name: "Austin",
        severity: {value: "DEGRADED", score: 90},
        location: {
            latitude: "30.267153",
            longitude: "-97.743057"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "Austin"
            }
        ]
    }, {
        id: "104",
        name: "Boston",
        severity: {value: "NORMAL", score: 90},
        location: {
            latitude: "42.360081",
            longitude: "-71.058884"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "Boston"
            }
        ]
    }, {
        id: "105",
        name: "Chicago",
        severity: {value: "NORMAL", score: 90},
        location: {
            latitude: "41.8781",
            longitude: "-87.6298"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "Boston"
            }
        ]
    }, {
        id: "106",
        name: "Barcelona",
        severity: {value: "NORMAL", score: 90},
        location: {
            latitude: "41.3874",
            longitude: "2.1686"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "Barcelona"
            }
        ]
    }, {
        id: "107",
        name: "London",
        severity: {value: "CRITICAL", score: 90},
        location: {
            latitude: "51.5072",
            longitude: "-0.1276"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "LONDON"
            }
        ]
    }, {
        id: "108",
        name: "Rome",
        severity: {value: "NORMAL", score: 90},
        location: {
            latitude: "41.9028",
            longitude: "12.4964"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "ROME"
            }
        ]
    }, {
        id: "109",
        name: "San Francisco",
        severity: {value: "NORMAL", score: 90},
        location: {
            latitude: "37.7749",
            longitude: "-122.4194"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "San Francisco"
            }
        ]
    }, {
        id: "110",
        name: "Vancouver",
        severity: {value: "NORMAL", score: 90},
        location: {
            latitude: "49.2827",
            longitude: "-123.1207"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "Vancouver"
            }
        ]
    }, {
        id: "111",
        name: "Montreal",
        severity: {value: "NORMAL", score: 90},
        location: {
            latitude: "45.5019",
            longitude: "-73.5674"
        },
        throughput: 4115.672484466372,
        packets: 19.6322945408849,
        latency: 10.11231884057971,
        jitter: 0.043478260869565216,
        packetLoss: 1.2742028985507248,
        hosts: [
            {
                id: "USSDCOXUNFL01D01",
                cityName: "Montreal"
            }
        ]
    }          
];

// These overlays drive the links in the map
export const overlaysQueryPerSiteOverlays = {
    "100": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "100", name: "Paris"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "100", name: "Paris"}
            },
            remote: {
                site: {id: "106", name: "Barcelona"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "100", name: "Paris"}
            },
            remote: {
                site: {id: "107", name: "London"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "100", name: "Paris"}
            },
            remote: {
                site: {id: "108", name: "Rome"}
            }
        }
    ],
    "101": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "100", name: "Paris"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "102", name: "San Jose"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "103", name: "Austin"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "104", name: "Boston"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "105", name: "Chicago"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "109", name: "San Francisco"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "110", name: "Vancouver"}
            }
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "101", name: "New York"}
            },
            remote: {
                site: {id: "111", name: "Montreal"}
            }
        }
    ],
    "102": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "102", name: "San Jose"}
            },
            remote: {
                site: {id: "101", name: "New York"}
            }
        }
    ],
    "103": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "103", name: "Austin"}
            },
            remote: {
                site: {id: "101", name: "New York"}
            }
        }
    ],
    "104": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "104", name: "Boston"}
            },
            remote: {
                site: {id: "101", name: "New York"}
            }
        }
    ],
    "105": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "105", name: "Chicago"}
            },
            remote: {
                site: {id: "101", name: "New York"}
            }
        }
    ],
    "106": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "106", name: "Barcelona"}
            },
            remote: {
                site: {id: "100", name: "Paris"}
            }
        }
    ],
    "107": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "107", name: "London"}
            },
            remote: {
                site: {id: "100", name: "Paris"}
            }
        }
    ],
    "108": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "NORMAL", score: 3.1818181818181817},
            local: {
                site: {id: "108", name: "Rome"}
            },
            remote: {
                site: {id: "100", name: "Paris"}
            }
        }
    ],
    "109": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "109", name: "San Francisco"}
            },
            remote: {
                site: {id: "101", name: "New York"}
            }
        }
    ],
    "110": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "110", name: "Vancouver"}
            },
            remote: {
                site: {id: "101", name: "New York"}
            }
        }
    ],
    "111": [
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 3.1818181818181817},
            local: {
                site: {id: "111", name: "Montreal"}
            },
            remote: {
                site: {id: "101", name: "New York"}
            }
        }
    ],
};

export const siteDetailsQueryPerSiteSiteDetails = {
    "100": [        
        {id: "100", name: "Paris", severity: {value: "CRITICAL", score: 3.5}, importance: "STANDARD"}
    ],
    "101": [        
        {id: "101", name: "New York", severity: {value: "CRITICAL", score: 3.5}, importance: "STANDARD"}
    ],
    "102": [        
        {id: "102", name: "San Jose", severity: {value: "DEGRADED", score: 3.5}, importance: "STANDARD"}
    ],
    "103": [        
        {id: "103", name: "Austin", severity: {value: "DEGRADED", score: 3.5}, importance: "STANDARD"}
    ],
    "104": [        
        {id: "104", name: "Boston", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ],
    "105": [        
        {id: "105", name: "Chicago", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ],
    "106": [        
        {id: "106", name: "Barcelona", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ],
    "107": [        
        {id: "107", name: "London", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ],
    "108": [        
        {id: "108", name: "Rome", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ],
    "109": [        
        {id: "109", name: "San Francisco", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ],
    "110": [        
        {id: "110", name: "Vancouver", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ],
    "111": [        
        {id: "111", name: "Montreal", severity: {value: "NORMAL", score: 3.5}, importance: "STANDARD"}
    ]
};

// These overlays show up in the details, but do not drive the links
export const siteDetailsQueryPerSiteSiteOverlays = {
    "100": [        
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "100", name: "Paris"}}
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "106", name: "Barcelone"}}
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "107", name: "London"}}
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "108", name: "Rome"}}
        }
    ],
    "101": [        
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "100", name: "Paris"}}
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "102", name: "San Jose"}}
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "102", name: "Austin"}}
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "102", name: "Boston"}}
        }, {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "102", name: "Chicago"}}
        }
    ],
    "102": [        
    ],
    "103": [        
    ],
    "104": [        
    ],
    "105": [        
    ],
    "106": [        
    ],
    "107": [        
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "100", name: "Paris"}}
        }
    ],
    "108": [        
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "100", name: "Paris"}}
        }
    ],
    "109": [        
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "101", name: "New York"}}
        }
    ],
    "110": [        
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "101", name: "New York"}}
        }
    ],
    "111": [        
        {
            id: "2.1.1.25-custom1-2.1.1.1-custom1-ipsec",
            severity: {value: "CRITICAL", score: 5.678},
            throughput: 1.23456,
            remote: {site: {id: "101", name: "New York"}}
        }
    ]
};

export const siteDetailsQueryPerSiteSiteHosts = {
    "100": [        
        {id: "USSDCODETMI01D01", severity: {value: "NORMAL", score: 1.23}}
    ],
    "101": [        
        {id: "USSDCODETMI01D01", severity: {value: "DEGRADED", score: 1.23}},
        {id: "USSDCODETMI01D02", severity: {value: "NORMAL", score: 1.23}}
    ],
    "102": [        
        {id: "USSDCODETMI01D01", severity: {value: "DEGRADED", score: 1.23}}
    ],
    "103": [        
        {id: "USSDCODETMI01D01", severity: {value: "CRITICAL", score: 1.23}}
    ],
    "104": [        
        {id: "USSDCODETMI01D01", severity: {value: "DEGRADED", score: 1.23}}
    ],
    "105": [        
        {id: "USSDCODETMI01D01", severity: {value: "DEGRADED", score: 1.23}}
    ],
    "106": [        
        {id: "USSDCODETMI01D01", severity: {value: "DEGRADED", score: 1.23}}
    ],
    "107": [        
        {id: "USSDCODETMI01D01", severity: {value: "NORMAL", score: 1.23}}
    ],
    "108": [        
        {id: "USSDCODETMI01D01", severity: {value: "CRITICAL", score: 1.23}}
    ],
    "109": [        
        {id: "USSDCODETMI01D01", severity: {value: "CRITICAL", score: 1.23}}
    ],
    "110": [        
        {id: "USSDCODETMI01D01", severity: {value: "CRITICAL", score: 1.23}}
    ],
    "111": [        
        {id: "USSDCODETMI01D01", severity: {value: "CRITICAL", score: 1.23}}
    ]
};

export const siteDetailsQueryPerSiteSiteApps = {
    "100": [        
        {id: "exchange", severity: {value: "DEGRADED", score: 2.54}},
    ],
    "101": [
        {id: "tcp", severity: {value: "CRITICAL", score: 2.54}},
        {id: "udp", severity: {value: "CRITICAL", score: 2.54}}
    ],
    "102": [        
        {id: "exchange", severity: {value: "DEGRADED", score: 2.54}},
    ],
    "103": [        
        {id: "outlook", severity: {value: "DEGRADED", score: 2.54}},
    ],
    "104": [        
        {id: "exchange", severity: {value: "DEGRADED", score: 2.54}},
        {id: "outlook", severity: {value: "DEGRADED", score: 2.54}},
        {id: "tcp", severity: {value: "DEGRADED", score: 2.54}},
    ],
    "105": [        
        {id: "exchange", severity: {value: "DEGRADED", score: 2.54}},
    ],
    "106": [        
        {id: "udp", severity: {value: "DEGRADED", score: 2.54}},
    ],
    "107": [        
        {id: "udp", severity: {value: "NORMAL", score: 2.54}},
    ],
    "108": [        
        {id: "udp", severity: {value: "CRITICAL", score: 2.54}},
    ],
    "109": [        
        {id: "udp", severity: {value: "CRITICAL", score: 2.54}},
    ],
    "110": [        
        {id: "udp", severity: {value: "CRITICAL", score: 2.54}},
    ],
    "111": [        
        {id: "udp", severity: {value: "CRITICAL", score: 2.54}},
    ]
};
