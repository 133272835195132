import { parse } from "csv-parse/lib/sync";

const VALUES_KEY = "values";
const VALID_TYPES_KEY = "validTypes";
const CSV_CUSTOM_PROPS_HEADERS = [
    "name",
    "description",
    VALID_TYPES_KEY,
    VALUES_KEY,
];

const CSVParserService = {
    /**
     * Export existing custom data to CSV format
     * Each row will have the format:
     * name,description,values separated with |
     *
     * @param data {object}
     * @param allowMultiType {boolean} Control if exporting more than one applicableTo field should be allowed
     *
     * @returns {string}
     */
    convertToCSV: function (data: object, allowMultiType: boolean) {
        if (!data || !Object.values(data).length) {
            return "";
        }

        const headers = CSV_CUSTOM_PROPS_HEADERS;
        const json = Object.values(data);
        let csvText = "";

        for (const line of json) {
            csvText +=
                headers
                    .map((key) => {
                        // Handle Values
                        if (key === VALUES_KEY) {
                            const propKey = "name";
                            const propValues = line[key];

                            return propValues.length > 0
                                ? propValues
                                      .map((value: any) => value[propKey])
                                      .join("|")
                                : "";
                        }

                        if (key === VALID_TYPES_KEY) {
                            const propKey = "type";
                            const propValues = line[key];

                            return propValues.length > 0
                                ? allowMultiType
                                    ? propValues
                                          .map((value: any) => value[propKey])
                                          .join("|")
                                    : propValues[0][propKey]
                                : "";
                        }

                        // Escape comma characters in property names
                        if (line[key].includes(",")) {
                            line[key] = `"${line[key]}"`;
                        }

                        return line[key];
                    })
                    .join(",") + "\n";
        }

        return csvText;
    },

    /**
     * Convert CSV format to usable Javascript Object
     *
     * @param data {string}
     *
     * @returns {object}
     */
    convertFromCSV: function (data) {
        const delimiter = ",";
        if (!data || !data.includes(delimiter)) {
            return [];
        }

        try {
            const records = parse(data, {
                relax_column_count: true,
                skip_empty_lines: true,
                columns: CSV_CUSTOM_PROPS_HEADERS,
                trim: true,
            });

            return records;
        } catch (error) {
            return error;
        }
    },
    /**
     *
     * @param properties a list of custom properties
     * @param allowMultiType {boolean} Control if exporting more than one applicableTo field should be allowed
     */
    exportToCSV: function (properties: any, allowMultiType: boolean) {
        const FILE_NAME = "custom-properties";
        const csvContent = this.convertToCSV(properties, allowMultiType);
        const blob = new Blob([csvContent], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `${FILE_NAME}.csv`;
        link.href = url;
        // Skip download in cypress tests
        // @ts-expect-error
        if (!window?.Cypress) {
            link.click();
        }
        link.remove();
    },
};

export default CSVParserService;
