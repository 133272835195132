import React from "react";
import { SEVERITY, STATUS_COLORS } from "components/enums";
import { StackedPercentageBar, StackItem } from "../stacked-percentage-bar/StackedPercentageBar";

export type HealthPercentageBarProps = {
    normal?: number | undefined;
    degraded?: number | undefined;
    critical?: number | undefined;
    unknown?: number | undefined;
    onItemClick?:(e: SEVERITY) => void;
};

export function HealthPercentageBar({ normal, degraded, critical, unknown, onItemClick }: HealthPercentageBarProps) {
    function handleOnClick(status: SEVERITY) {
        if(onItemClick) {
            onItemClick(status);
        }
    }
    const stackedItems: Array<StackItem> = [];
    if (normal !== undefined) {
        stackedItems.push({
            value: normal,
            color: STATUS_COLORS.NORMAL,
            label: "normal",
            id: SEVERITY.NORMAL
        })
    }
    if (degraded !== undefined) {
        stackedItems.push({
            value: degraded,
            color: STATUS_COLORS.DEGRADED,
            label: "degraded",
            id: SEVERITY.DEGRADED
        });
    }
    if (critical !== undefined) {
        stackedItems.push({
            value: critical,
            color: STATUS_COLORS.CRITICAL,
            label: "critical",
            id: SEVERITY.CRITICAL
        });
    }
    if (unknown !== undefined) {
        stackedItems.push({
            value: unknown,
            color: STATUS_COLORS.UNKNOWN,
            label: "unknown",
            id: SEVERITY.UNKNOWN
        });
    }
    return (
        <StackedPercentageBar stackItems={stackedItems} onClick={handleOnClick}/>
    )
}
