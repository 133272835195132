/** This file defines the subflow editor React component.  The subflow editor allows you 
 *  to edit the configuration of a subflow.
 *  @module */
import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { HTMLSelect, InputGroup, PopoverPosition } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { NodeSubflowVariableDefinition } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { GenericKey } from "utils/runbooks/NodeUtil";
import { Context, RunbookContext, VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { VariableContext } from "utils/runbooks/VariableContext";
import { GLOBAL_SCOPE, INCIDENT_SCOPE, PrimitiveVariableType, RUNTIME_SCOPE, StructuredVariableType } from "utils/runbooks/VariablesUtils";
import { ProfileInterface } from "utils/services/ThirdPartyIntegrationApiService";
import { useQueryParams } from "utils/hooks";
import { Variant } from "../../types/GraphTypes";
import { useStateSafePromise } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import { InstalledIntegrationConnector } from "pages/integrations/types/IntegrationTypes";
import { Classes, Tooltip2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { Icon } from "@tir-ui/react-components";

/** this interface defines the properties that are used to store the variable mapping between the inner (subflow)
 *  variable and outer (runbook) variable. */
export interface VariableMapping {
    /** the inner variable, which is always the subflow variable. */
    inner: string;
    /** the outer variable which is always the runbook variable. */
    outer: string;
    /** the method that specifies static, runtime or incident */
    method?: Method;
}

/** this interface defines the properties that are passed into the SubflowVariableMappingEditor React component. */
export interface SubflowVariableMappingEditorProps {
    /** the current context of the whole runbook.  We will need the trigger context here. */
    runbookContext?: RunbookContext;
    /** a boolean value, if true this is the first row. */
    isFirstRow?: boolean;
    /** a boolean value, if true this is the last row. */
    isLastRow?: boolean;
    /** a boolean value, if true this is the inbound mappings editor, if false it is the outbound mappings editor. */
    inbound: boolean;
    /** the definition of the subflow variable. */
    subflowVariable: NodeSubflowVariableDefinition;
    /** the current VariableMapping. */
    initValue: VariableMapping;
    /** the array of ProfileInterface objects with the list of auth profiles. */
    authProfiles?: ProfileInterface[];
    /** the list of Edge devices. */
    edges ?: any[];
    /** the handler for mapping changes. */
    onVariableMappingSet: (subflowVariable: string, mappedVariable: string, method: Method) => void;
    /** an array containing the names of the runtime variables which will be created automatically when selecting dropdown options */
    runtimeVariablesToBeCreated?: string[];
    /** the optional integration id. */
    integrationId?: string;
    /** an object containing the lists of static values for subflow runtime variables. */
    staticInputValuesLists?: any;
    /** a boolean which is true if at least one inbound variable has a description. */
    hasInboundDescription?: boolean;
    /** a boolean which is true if at least one outbound variable has a description. */
    hasOutboundDescription?: boolean;
    /** an array containing descriptions for context values defined inside the subflow input node editor. */
    inputOrOutputValuesDescriptions?: any;
    /** runtime variables changes handler */
    onRuntimeOrSubflowVariableEdited?: (updatedVariablesList) => void;
    /** a boolean value, if true the variable name dropdown will have the automatically created value, if false the variable name dropdown will have the initial value. */
    assignedAutomaticallyCreatedRuntimeValue?: boolean;
    /** a function which handles the assignedAutomaticallyCreatedRuntimeValue state value */
    setAssignedAutomaticallyCreatedRuntimeValue?: React.Dispatch<React.SetStateAction<boolean>>;
}

/** this enum defines all the valid values for the method. */
export enum Method {
    /** the enumerated value for the static method. */
    "STATIC"    = "static",
    /** the enumerated value for the connector method. */
    "CONNECTOR" = "connector",
    /** the enumerated value for the runtime method. */
    "RUNTIME"   = "runtime",
    /** the enumerated value for the incident method. */
    "INCIDENT"  = "incident",
    /** the enumerated value for the trigger method. */
    "TRIGGER"   = "trigger",
    /** the enumerated value for the unset method. */
    "UNSET"     = "unset"
};

/** this enum defines all the valid labels for the method. */
export enum MethodLabel {
    /** the enumerated label for the static method. */
    "static"    = "static",
    /** the enumerated label for the connector method. */
    "connector" = "connector",
    /** the enumerated label for the runtime method. */
    "runtime"   = "runtime var",
    /** the enumerated label for the incident method. */
    "incident"  = "incident var",
    /** the enumerated label for the trigger method. */
    "trigger"   = "trigger",
    /** the enumerated label for the unset method. */
    "unset"     = "not set"
};

const JSON_KEYS_WITH_TRIGGER_OPTIONS: string[] = ["requestBody", "requestHeaders", "requestQueryParameters"];
const LIFECYCLE_AND_WEBHOOK_KEYS_TRIGGER_MAP: Record<string, string> = {
    "requestBody": "json",
    "requestHeaders": "json",
    "requestQueryParameters": "json",
    "requestPath": "string",
    // Runbook completed
    //"createdAt": "timestamp",
    "runbookStatus": "string",
    // Note Added
    "content": "string",
    "createdAt": "timestamp",
    // Note updated
    //"content": "string",
    //"createdAt": "timestamp",
    // Indicators Updated
    "indicators": "integer",
    // Damien also wants an indicators json
    // Status Changed
    "oldStatus": "string",
    "status": "string",
    "lastUpdatedAt": "timestamp",
    // Ongoing state changed
    "endTime": "timestamp"
};

/** Component for editing the variable mapping of a subflow node.
 *  @param props the properties passed into the React component.
 *  @returns a JSX component with the subflow node variable mapping editor. */
export const SubflowVariableMappingEditor = React.forwardRef((props: SubflowVariableMappingEditorProps, ref: any): JSX.Element => {
    const { params } = useQueryParams({ listenOnlyTo: ["variant"] });
    const variable = props.subflowVariable;
    const name = variable.name.startsWith("subflow.") ? variable.name.substring(8, variable.name.length) : variable.name;
    const primitiveType = Object.keys(PrimitiveVariableType).filter((key) => PrimitiveVariableType[key] === variable.type)[0];
    const structuredType = Object.keys(StructuredVariableType).filter((key) => StructuredVariableType[key] === variable.type)[0];
    const type = primitiveType ? PrimitiveVariableType[primitiveType] : StructuredVariableType[structuredType];
    const typeLabel = primitiveType 
        ? STRINGS.runbookEditor.variableDefinitions.primitiveVariable.types[primitiveType] 
        : STRINGS.runbookEditor.variableDefinitions.structuredVariable.types[structuredType];

    const [method, setMethod] = useState<Method>(props.initValue.method || (props.inbound ? (type !== PrimitiveVariableType.CONNECTOR ? Method.STATIC : Method.CONNECTOR) : Method.RUNTIME));
    const [connectors, setConnectors] = useState<InstalledIntegrationConnector[] | undefined>(undefined);
    const [runtimeSelectValue, setRuntimeSelectValue] = useState(method === Method.RUNTIME && props.initValue.outer ? props.initValue.outer : "");
 
    const { getVariables, syncRuntimeOrSubflowVariables } = useContext(VariableContext);
    const variables: VariableContextByScope = {
        runtime: getVariables(RUNTIME_SCOPE, true),
        incident: getVariables(INCIDENT_SCOPE, true),
        global: getVariables(GLOBAL_SCOPE, true)
    };

    const resources = STRINGS.runbookEditor.nodeEditor.subflowVarMapping[props.inbound ? "inbound" : "outbound"];

    let triggerKeys: string[] = [];
    let triggerExpandedKeys: GenericKey[] = [];
    if ([Method.RUNTIME, Method.TRIGGER].includes(method) && props.runbookContext) {
        const triggerContext: Context | undefined = props.runbookContext.getTriggerContext();
        if (triggerContext) {
            triggerKeys = triggerContext.keys || [];
            triggerExpandedKeys = triggerContext.expandedKeys || [];
        }
    }

    const availableMethods = useRef(Object.keys(Method).filter(key => (props.inbound || !["STATIC", "TRIGGER"].includes(key))));

    useEffect(() => {
        /* In the runbook editor, we should disable a variable in the select drop-down if the variable is already assigned to an output variable. */
        disableOptionsInVariableSelects('.subflow-output [data-testid="set-variable-runtime-select"]');
        disableOptionsInVariableSelects('.subflow-output [data-testid="set-variable-incident-select"]');

        if (method === Method.STATIC && !availableMethods.current.find(key => Method[key] === Method.STATIC)) {
            if (availableMethods.current.find(key => Method[key] === Method.RUNTIME)) {
                setMethod(Method.RUNTIME);
            } else if (availableMethods.current.find(key => Method[key] === Method.INCIDENT)) {
                setMethod(Method.INCIDENT);
            }
        }
    }, [props.inbound, method]);

    useEffect(() => {
        /* In the Input tab set the default selected method to Unset where applicable. */
        if (
            props.inbound && 
            props.initValue.outer === "" && 
            availableMethods.current.find(key => Method[key] === Method.UNSET) &&
            ![PrimitiveVariableType.CONNECTOR, PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.ALLUVIO_EDGE].includes(type)
        ) {
            setMethod(Method.UNSET);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [executeSafely] = useStateSafePromise();
    useEffect(() => {
        const connectorsPromise = new Promise<InstalledIntegrationConnector[]>(async (resolve, reject) => {
            try {
                if (props.integrationId) {
                    const newConnectors = await IntegrationLibraryService.getConnectors(props.integrationId);
                    const enabledNewConnectors = newConnectors.filter((connector => connector?.isEnabled));

                    resolve(enabledNewConnectors as InstalledIntegrationConnector[]);    
                } else {
                    resolve([]);
                }
            } catch (error) {
                reject(error);
            }
        });
        executeSafely(connectorsPromise).then(
            (connectors) => {
                setConnectors(connectors);
            }, 
            () => {
                setConnectors([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.assignedAutomaticallyCreatedRuntimeValue && !runtimeSelectValue) {
            const assignedVariableName = variable.name.replace('subflow.','runtime.');
            setRuntimeSelectValue(assignedVariableName);
            props.onVariableMappingSet(variable.name, assignedVariableName, method);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.assignedAutomaticallyCreatedRuntimeValue]);

    const showTriggerOptionInRuntime = useRef<boolean>(false);
    showTriggerOptionInRuntime.current = useMemo(() => {
        return props.initValue.outer.startsWith("$trigger") && method === Method.RUNTIME;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props?.authProfiles) {
        // sort the authentication profiles alphabetically by name
        props.authProfiles.sort((a, b) => a.name.localeCompare(b.name));
    }
    
    // Wait for api call to complete before rendering the editor
    if (!connectors) {
        return <tr><td colSpan={2}><DataLoadFacade loading/></td></tr>;
    }

    const variableDescriptionExits = 
        (props.inbound && props.hasInboundDescription) || (!props.inbound && props.hasOutboundDescription);

    const variableDescription = 
        props.inputOrOutputValuesDescriptions?.find(
            (item: { valueName: string, description: string }) => item.valueName === variable.name
        )?.description.trim();

    return <>
        {(props.isFirstRow || (props.isFirstRow && props.isLastRow === props.isFirstRow)) && 
        <div className="d-flex flex-row subflow-row-min-width pb-2 pt-2 border-bottom">
            <div className="d-flex flex-column pr-1 col-md-3">
                <label className="font-size-md-small font-weight-500 mb-2">{resources.name}</label>
            </div>
            <div className={"d-flex flex-column pr-2" + (variableDescriptionExits ? " col-md-2": " col-md-3")}>
                <label className="font-size-md-small font-weight-500 mb-2">{resources.type}</label>
            </div>
            <div className="d-flex flex-column pr-1 col-md-1">
                <label className="font-size-md-small font-weight-500 mb-2">{resources.unit}</label>
            </div>
            <div className="d-flex flex-column pr-1 col-md-2">
                <label className="font-size-md-small font-weight-500 mb-2">{resources.method}</label>
            </div>
            <div className="d-flex flex-column col-md-3">
            <label className="font-size-md-small font-weight-500 mb-2">{resources.set}</label>
            </div>
            {variableDescriptionExits && <div className="col-md-1"></div>}
        </div>}
        <div className={"d-flex flex-row align-items-md-center subflow-row-min-width pb-2 pt-2" + (props.isLastRow ? " mb-2": "")}>            
            <div className="d-flex flex-column pr-1 col-md-3">
                <span data-testid="variable-name" className="font-size-md-small pt-2">{name}</span>
            </div>
            <div className={"d-flex flex-column pr-2" + (variableDescriptionExits ? " col-md-2": " col-md-3")}>
                <span data-testid="variable-type" className="font-size-md-small pt-2">{typeLabel}</span>
            </div>
            <div className="d-flex flex-column pr-1 col-md-1">
                <span data-testid="variable-unit" className="font-size-md-small pt-2">{variable.unit && variable.unit !== "none" && variable.unit !== "" ? variable.unit : "N/A"}</span>
            </div>
            <div className="d-flex flex-column pr-1 col-md-2">
                <HTMLSelect
                    data-testid="method-select"
                    className="selector-min-width method"
                    fill={true}
                    options={Object.keys(Method).filter(
                        key => (
                            (
                                [PrimitiveVariableType.CONNECTOR].includes(type) &&
                                key === "CONNECTOR"
                            ) ||
                            (
                                [PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.ALLUVIO_EDGE].includes(type) && 
                                (key === "STATIC" || (params.variant === Variant.ON_DEMAND && key === "RUNTIME"))
                            ) || 
                            (
                                (![PrimitiveVariableType.CONNECTOR, PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.ALLUVIO_EDGE].includes(type) && 
                                key !== "CONNECTOR") &&
                                (props.inbound || (key !== "CONNECTOR" && key !== "STATIC" && key !== "TRIGGER" && key !== "UNSET")) && 
                                ((params.variant === Variant.ON_DEMAND && key !== "INCIDENT") || params.variant !== Variant.ON_DEMAND)
                            )
                        )
                    ).map(key => {return {label: MethodLabel[key?.toLowerCase()] ? MethodLabel[key?.toLowerCase()] : key?.toLowerCase(), value: Method[key]}})}
                    value={method}
                    onChange={
                        (event) => {
                            setMethod(event.currentTarget.value as Method);
                            if (event.currentTarget.value === Method.UNSET) {
                                props.onVariableMappingSet(variable.name, "", Method.UNSET);
                            }
                        }
                    }
                />
            </div>
            <div className="d-flex flex-column col-md-3">
                {method === Method.STATIC && type === PrimitiveVariableType.AUTH_PROFILE && <HTMLSelect
                    data-testid="set-variable-auth-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectAuthenticationProfileText, value: ""}].concat(
                            props?.authProfiles?.map((profile) => {return {label: profile.name || "", value: profile.id || ""};}) || []
                        )
                    }
                    defaultValue={method === Method.STATIC && props.initValue.outer ?  props.initValue.outer : ""}
                    onChange={
                        (event) => {
                            props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                        }
                    }
                />}
                {method === Method.STATIC && type === PrimitiveVariableType.ALLUVIO_EDGE && <HTMLSelect
                    data-testid="set-variable-edge-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectAlluvioEdgeText, value: ""}].concat(
                            props?.edges?.map((edge) => {return {label: edge.name || "", value: edge.id || ""};}) || []
                        )
                    }
                    defaultValue={method === Method.STATIC && props.initValue.outer ?  props.initValue.outer : ""}
                    onChange={
                        (event) => {
                            props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                        }
                    }
                />}
                {(method === Method.STATIC || method === Method.UNSET) && 
                    ![PrimitiveVariableType.CONNECTOR, PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.ALLUVIO_EDGE].includes(type) && Boolean(!props.staticInputValuesLists?.[variable.name]?.length) &&
                    <InputGroup
                    data-testid="set-variable-text-input"
                    fill={true}
                    placeholder={method === Method.STATIC ? STRINGS.runbookEditor.nodeEditor.subflowVarMapping.staticInputPlaceHolder : ""}
                    defaultValue={method === Method.STATIC && props.initValue.outer ? props.initValue.outer : ""}
                    onChange={
                        (event) => {
                            if (typeLabel === "Integer") {
                                const currentTargetValue = event.currentTarget.value;
                                event.currentTarget.value = currentTargetValue.replace(/\D/g,'');
                            }
                            props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                        }
                    }
                    disabled={method === Method.UNSET}
                />}
                {(method === Method.STATIC || method === Method.UNSET) && 
                    ![PrimitiveVariableType.CONNECTOR, PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.ALLUVIO_EDGE].includes(type) && Boolean(props.staticInputValuesLists?.[variable.name]?.length) &&
                    <HTMLSelect
                        data-testid="set-variable-string-select"
                        className="selector-min-width"
                        fill={true}
                        options={
                            [{label: "Select", value: ""}].concat(
                                props.staticInputValuesLists[variable.name].map((option) => {return {label: option.label, value: option.value || option.label};}) || []
                            )
                        }
                        defaultValue={props.initValue.outer ? props.initValue.outer : ""}
                        onChange={
                            (event) => {
                                props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                            }
                        }
                        disabled={method === Method.UNSET}
                    />
                }
                {method === Method.CONNECTOR && type === PrimitiveVariableType.CONNECTOR && <HTMLSelect
                    data-testid="set-variable-connector-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectConnectorText, value: ""}].concat(
                            (connectors.filter(connector => !props.integrationId || connector.integrationId === props.integrationId) || []).map((connector) => {
                                return {label: connector.name, value: connector.connectorId};
                            })
                        )
                    }
                    defaultValue={method === Method.CONNECTOR && props.initValue.outer ?  props.initValue.outer : ""}
                    onChange={
                        (event) => {
                            props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                        }
                    }
                />}
                {method === Method.RUNTIME && <HTMLSelect
                    data-testid="set-variable-runtime-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectRuntimeVariableText, value: ""}].concat(
                        variables.runtime[primitiveType ? "primitiveVariables" : "structuredVariables"].filter(
                            (variable) => (!props.inbound && variable.type === type && !variable.name.includes("builtin")) || (props.inbound && variable.type === type && !props.runtimeVariablesToBeCreated?.includes(variable.name))
                        ).map(
                            (variable) => {
                                return {label: variable.name, value: variable.name};
                            }
                        )).concat(
                            !props.inbound && props.runtimeVariablesToBeCreated?.length ? props.runtimeVariablesToBeCreated.filter(
                                (label) => label === variable.name.replace('subflow.','runtime.') && !getVariables(RUNTIME_SCOPE).primitiveVariables.find(runtimeVariable => runtimeVariable.name === label)
                            ).map(label => {
                                return {label: `${label} (created on select)`, value: label};
                            }) : []
                        ).concat(
                            triggerKeys.map((triggerKey) => {
                                return (triggerKey === variable.type || RunbookContext.areFiltersCompatible(triggerKey, variable.type)) && props.inbound &&
                                showTriggerOptionInRuntime.current
                                    ? {label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.triggerKeys[triggerKey], value: "$trigger" + (JSON_KEYS_WITH_TRIGGER_OPTIONS.includes(triggerKey) ? "." + triggerKey : "")}
                                    : undefined as any;
                            }).filter((obj) => obj !== undefined) || []
                        ).concat(
                            triggerExpandedKeys.map((triggerExpandedKey) => {
                                return (LIFECYCLE_AND_WEBHOOK_KEYS_TRIGGER_MAP[triggerExpandedKey.id] === variable.type) && props.inbound &&
                                showTriggerOptionInRuntime.current
                                    ? {label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.triggerKeys[triggerExpandedKey.id], value: "$trigger." + triggerExpandedKey.id}
                                    : undefined as any;
                            }).filter((obj) => obj !== undefined) || []
                        )
                    }
                    value={runtimeSelectValue}
                    onChange={
                        (event) => {
                            props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                            setRuntimeSelectValue(event.currentTarget.value);
                            if (props.setAssignedAutomaticallyCreatedRuntimeValue) {
                                props.setAssignedAutomaticallyCreatedRuntimeValue(false);
                            }
                            if (!props.inbound && props.onRuntimeOrSubflowVariableEdited) {
                                const runtimeVariables = getVariables(RUNTIME_SCOPE);
                                const newRuntimeVariableName = variable.name.replace('subflow.','runtime.');
                                const newRuntimeVariableType = variable.type;
                                if (!runtimeVariables.primitiveVariables.find(variable => variable.name === newRuntimeVariableName && variable.type === newRuntimeVariableType) && Object.values(PrimitiveVariableType).includes(variable.type as PrimitiveVariableType)) {
                                    runtimeVariables.primitiveVariables.push({
                                        name: newRuntimeVariableName, type: variable.type as PrimitiveVariableType, isReadOnly: false
                                    });
                                    syncRuntimeOrSubflowVariables(runtimeVariables);
                                    props.onRuntimeOrSubflowVariableEdited(runtimeVariables);
                                }
                            }
                        }
                    }
                    onFocus={() => {
                        if (!props.inbound) {
                            /* In the runbook editor, we should disable a variable in the select drop-down if the variable is already assigned to an output variable. */
                            disableOptionsInVariableSelects('.subflow-output [data-testid="set-variable-runtime-select"]');
                        }
                    }}
                />}
                {method === Method.INCIDENT &&<HTMLSelect
                    data-testid="set-variable-incident-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectIncidentVariableText, value: ""}].concat(
                        variables.incident[primitiveType ? "primitiveVariables" : "structuredVariables"].filter(
                            (variable) => (!props.inbound && variable.type === type && !variable.name.includes("builtin")) || (props.inbound && variable.type === type)
                        ).map(
                            (variable) => {return {label: variable.name, value: variable.name};}
                        ))
                    }
                    defaultValue={method === Method.INCIDENT && props.initValue.outer ? props.initValue.outer : ""}
                    onChange={
                        (event) => {
                            if (!props.inbound) {
                                /* In the runbook editor, we should disable a variable in the select drop-down if the variable is already assigned to an output variable. */
                                disableOptionsInVariableSelects('.subflow-output [data-testid="set-variable-incident-select"]');
                            }
                            props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                        }
                    }
                />}
                {method === Method.TRIGGER && <HTMLSelect
                    data-testid="set-trigger-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectTriggerText, value: ""}].concat(
                            triggerKeys.map((triggerKey) => {
                                return (triggerKey === variable.type || RunbookContext.areFiltersCompatible(triggerKey, variable.type)) && props.inbound 
                                    ? {label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.triggerKeys[triggerKey], value: "$trigger" + (JSON_KEYS_WITH_TRIGGER_OPTIONS.includes(triggerKey) ? "." + triggerKey : "")}
                                    : undefined as any;
                            }).filter((obj) => obj !== undefined) || []
                        ).concat(
                            triggerExpandedKeys.map((triggerExpandedKey) => {
                                return (LIFECYCLE_AND_WEBHOOK_KEYS_TRIGGER_MAP[triggerExpandedKey.id] === variable.type) && props.inbound 
                                    ? {label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.triggerKeys[triggerExpandedKey.id], value: "$trigger." + triggerExpandedKey.id}
                                    : undefined as any;
                            }).filter((obj) => obj !== undefined) || []
                        )
                    }
                    defaultValue={method === Method.TRIGGER && props.initValue.outer ?  props.initValue.outer : ""}
                    onChange={
                        (event) => {
                            if (!props.inbound) {
                                /* In the runbook editor, we should disable a variable in the select drop-down if the variable is already assigned to an output variable. */
                                disableOptionsInVariableSelects('.subflow-output [data-testid="set-variable-runtime-select"]');
                            }
                            props.onVariableMappingSet(variable.name, event.currentTarget.value, method);
                        }
                    }
                />}
            </div>
            {variableDescriptionExits && <div className="col-md-1">
                {variableDescription && <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0 ml-2"} intent="primary" 
                    content={
                        <div style={{maxWidth: "200px"}}>{variableDescription}</div>
                    } interactionKind="hover" position={PopoverPosition.RIGHT}>
                    <Icon className="text-secondary" icon={IconNames.HELP} />
                </Tooltip2>}
            </div>}
        </div>
    </>;
});

/** 
 *  Disable options (with the same value as the value in the current select element) in other select elements
 *  @param selector the select css selector value */
function disableOptionsInVariableSelects(selector: string): void {
    const variableSelects = document.querySelectorAll(selector);
    if (variableSelects.length) {
        /* First enable all options in all select elements */
        variableSelects.forEach(select => {
            const options = select.querySelectorAll("option");
            options.forEach(option => { 
                option.disabled = false; 
            })
        });
        /* Then disable options (with the same value as the value in the current select) in other selects */
        variableSelects.forEach((select, indexOuter) => {
            const selectValue = (select as HTMLInputElement).value;
            variableSelects.forEach((select, indexInner) => {
                if (indexInner !== indexOuter) {
                    const options = select.querySelectorAll("option");
                    options.forEach(option => {
                        if (option.innerText === selectValue) {
                            option.disabled = true;
                        }
                    });
                }
            });
        });
    }
}
