/** This module creates a new node for the react-flow graph that represents a logical node.
 *  @module
 */
import React, { CSSProperties, useRef } from 'react';
import { Handle, Position, NodeProps, Connection, Edge } from 'react-flow-renderer';
import { BaseNodeContent } from '../BaseNodeContent';
import './DecisionNode.css';

// The base style for all the handles
const baseHandleStyle: CSSProperties = { background: '#b1b1b7' };

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params);

/** Renders the switch node component.
 *  @param props the properties passed in.
 *  @returns JSX with the switch node component.*/
export default function DecisionNode({
    // Position of target handles (i.e. Inputs)
    targetPosition = Position.Left,
    // Position of source handles (i.e. Outputs)
    sourcePosition = Position.Right,
    ...props
}: NodeProps): JSX.Element {
    const isConnectable = props.isConnectable;
    const cssTargetPos = (targetPosition === Position.Left ? "top" : "left");
    const cssSourcePos = (sourcePosition === Position.Right ? "top" : "left");
    // Create the input ports
    const inputPorts: Array<JSX.Element> = [];
    const numInputPorts = 1;
    const inputPortIncrement = Math.floor(100 / numInputPorts);
    for (let index = 0; index < numInputPorts; index++) {
        const handleStyle = {
            ...baseHandleStyle,
            [cssTargetPos]: (Math.floor(inputPortIncrement / 2) + index * inputPortIncrement) + '%'
        };
        inputPorts.push(
            <Handle
                type="target"
                key={"input-handle-node-" + props.id + "-" + index + "-" + targetPosition}
                position={targetPosition} 
                id={index.toString()}
                style={handleStyle}
                onConnect={onConnect}
            />
        );
    }

    // Create the output ports
    const className = "source-plus-handle";
    const outputPorts: Array<JSX.Element> = [];
    const outputs = props.data?.properties?.find(prop => prop.key === "outputs")?.value;
    const numOutputPorts = outputs?.length || 1;
    const outputPortIncrement = Math.floor(100 / numOutputPorts);
    for (let index = 0; index < numOutputPorts; index++) {
        const handleStyle = {
            //...baseHandleStyle, 
            [cssSourcePos]: (Math.floor(outputPortIncrement / 2) + index * outputPortIncrement) + '%'
        };
        outputPorts.push(<React.Fragment key={"output-node-" + props.id + "-" + index}>
            <Handle
                type="source"
                key={"output-handle-node-" + props.id + "-" + index + "-" + sourcePosition}
                position={sourcePosition}
                id={index.toString()}
                style={handleStyle}
                isConnectable={isConnectable}
                className={className} 
                onMouseUp={() => nodeRef.current.showMenu(index)}
            />
        </React.Fragment>);
    }

    const nodeRef = useRef<any>();

    return (<>
        <BaseNodeContent ref={nodeRef} sourcePosition={sourcePosition} targetPosition={targetPosition} {...props} portCount={numOutputPorts}
            numOutputHandles={outputPorts.length}
            inputHandles={inputPorts} 
            outputHandles={outputPorts}
        />
    </>);
};
