/** This module contains all the types for the custom properties in the runbook editor.
 *  @module
 */

import React from "react";

/** this interface defines the CustomPropertyValue object.  This object stores the value of 
 *      a custom property including the value's name and id.*/
export interface CustomPropertyValue {
    /** a string with the id of the CustomPropertyValue.  This is in the form of a uuid. */
    id: string;
    /** a string with the name of the CustomPropertyValue. */
    name: string;
}

/** this interface defines the CustomProperty object.  This object stores the name, id and values for a
 *  custom property. */
export interface CustomProperty {
    /** a string with the id of the CustomProperty.  This is in the form of a uuid. */
    id: string;
    /** a string with the name of the CustomProperty. */
    name: string;
    /** a string array with the entity types that the custom property is enabled on.  These match the 
     *  data ocean names for the entity, such as network_interface, network_device, application and location. */
    types: string[];
    /** the type of custom property, user or system. */
    type?: CustomPropertyType;
    /** an array with the CustomPropertyValues. */
    values: CustomPropertyValue[];
    /** an array with the custom property permissions. */
    permissions?: string[];
}

/** this enum defines the valid values for the custom property type. */
export enum CustomPropertyType {
    /** the user custom property type. */
    User        = "USER",
    /** the system custom property type. */
    System      = "SYSTEM"
}

/** the context for the custom properties in the runbook. */
export const CustomPropertyContext = React.createContext<CustomProperty[]>([]);
