/** This file defines the cloudim detail view react component.
 *  @module */
import React from "react";
import { STRINGS } from 'app-strings';
import { SIZE } from 'components/enums';
import { Icon, IconNames } from "@tir-ui/react-components";
import { DetailsPanel } from "components/reporting";
import { Region } from "pages/cloudim/views/cloudim-geomap/CloudIMGeoMapView";
import { SummaryCard } from 'components/common/layout/summary-card/SummaryCard';

import { CloudIMSummaryView } from "./summary/CloudIMSummaryView";
import { CloudIMTopologyView } from "./topology/CloudIMTopologyView";
import "./CloudIMDetailsView.scss";

export interface CloudIMDetailsViewProps {
    regionDetail: Region;
    tokens?: Array<string>;
    onBladeClosed?: () => void;
}

const CloudIMDetailsView = ({ regionDetail, tokens, onBladeClosed }: CloudIMDetailsViewProps): JSX.Element => {
    /** IMPORTANT: There is a property "xy" in regionDetail, we want to remove this for our detail view.
     * If this is not removed, it will rerender a few components a second time after it has already rendered.
     * The reason for the rerender is due to the result of the "isEqual" from lodash when comparing objects.
     * When it comes to comparing the property "xy" is found in the ref but is not found in the object we're passing through, which is weird.
    */
    delete regionDetail["xy"];
    
    return <DetailsPanel
        title={<span>
            <Icon className="align-middle mx-1" icon={IconNames.MAP_MARKER} />
            <div className="d-inline-block font-size-large">{regionDetail.displayName}</div>
        </span>}
        className="cloudim-detail-blade"
        size={SIZE.l}
        overlay={false}
        onCloseClicked={() => onBladeClosed && onBladeClosed()}
    >
        <CloudIMSummaryView className="cloudim-summary" regionDetail={regionDetail} />
        <SummaryCard className="w-100 cloudim-topology" title={STRINGS.cloudim.topology.cardTitle} alignTitle="left" titleFontWeight="font-weight-bold" titlePadding={false}>
            <CloudIMTopologyView regionDetail={regionDetail} tokens={tokens} />
        </SummaryCard>
    </DetailsPanel>
};

export { CloudIMDetailsView };
