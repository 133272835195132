import React, { useState, useEffect } from 'react';
import { FormGroup, ITagInputProps, TagInput } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import { FieldError } from './FieldError';

export type TagListType = ITagInputProps & {
    name: string;
    label: string;
    inline?: boolean;
    required?: boolean;
    helperText?: string;
};

function TagListField(props : TagListType) {
    const { label, required, helperText, values, name, onChange, inline, ...otherProps } = props;

    const [field, meta] = useField(name);

    const { setFieldValue } = useFormikContext();

    const [tags, setTags] = useState<React.ReactNode[] | string[]>(values as any);

    useEffect(() => {
        setTags(values as any);
    }, [values]);

    const handleOnChange = (values: React.ReactNode[]) => {
        const newValues = values.filter((v, i, a) => a.indexOf(v) === i);
        setTags(newValues);
        setFieldValue(name, newValues);
        if (onChange) {
            onChange(newValues);
        }
    };

    const handleOnKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    return (
        <FormGroup
            label={label}
            labelFor={field.name}
            labelInfo={required ? '* ' : ''}
            inline={inline || false}
            helperText={helperText}
        >
            <TagInput
                {...field}
                intent={meta.touched && meta.error ? 'danger' : 'none'}
                values={tags}
                addOnBlur={true}
                {...otherProps}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
            />
            <FieldError name={field.name} />
        </FormGroup>
    );
}

export { TagListField };
