
// This object provides defaults for various thresholds. The file currently has arbitrary values.
// NOTE: This should ideally come form DAL service and should be part of their schema. DAL should get this
// info from Analatics.
export const MetricThresholds = {
    Overlay: {
        Throughput: {
            value: 60,
            unit: "mbps",
        },
        Performance: {
            // DAL uses 40 and 70 for degraded and critical severity. Performance is (100 - severity).
            // Hence using 60 for indicating performance drop anywhere below 'degraded' state
            value: 60,
        },
        Utilization: {
            value: 95
        },
        Jitter: {
            value: 3,
            unit: "ms"
        },
        Latency: {
            value: 10,
            unit: "ms"
        },
        PacketLoss: {
            value: 10
        },
        Severity: {
            Critical: {
                value: 70,
            },
            Degraded: {
                value: 50,
            }
        }
    },
    Underlay: {
        Performance: {
            value: 60,
        },
        Throughput: {
            value: 60,
            unit: "mbps",
        },
        Utilization: {
            value: 95
        },
        Jitter: {
            value: 3,
            unit: "ms"
        },
        Latency: {
            value: 10,
            unit: "ms"
        },
        PacketLoss: {
            value: 10
        },
        Severity: {
            Critical: {
                value: 70,
            },
            Degraded: {
                value: 50,
            }
        }
    },
    Apps: {
        Performance: {
            value: 60,
        },
        Severity: {
            Critical: {
                value: 70,
            },
            Degraded: {
                value: 50,
            }
        }
    }
}