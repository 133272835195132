/** This module contains the default condition editors.
 *  @module
 */
import { HTMLSelect } from "@blueprintjs/core";
import React from "react";

/** the default field render for conditions.  It uses a basic input to edit the field value.
 *  @param param0
 *  @returns the React component with the default field renderer. */
export function DefaultFieldRenderer ({ value, onChange, className = "" }) {
    return <input
        className={className}
        onChange={e => onChange({ value: e.currentTarget.value })}
        defaultValue={value}
        autoFocus
    />;
}

/** the default field render for conditions.  It uses a basic input to edit the field value.
 *  @param param0
 *  @returns the React component with the default field renderer. */
export function DefaultMultiFieldRenderer ({ value, onChange, className = "" }) {
    return <input
        className={className}
        onChange={e => {
            let value: any = e.currentTarget.value;
            // string to array, trim whitespace, ignore empty value
            value = value?.split(',').map((i: string) => i.trim()).filter((i: string) => i);
            onChange({ value });
        }}
        defaultValue={Array.isArray(value) ? value.join(",") : value}
        autoFocus
    />;
}

/** the numeric field renderer for conditions.  It uses an input and a regex to validate that the 
 *  input value is a number.
 *  @param param0
 *  @returns the React component with the number field renderer. */
export function NumberFieldRenderer ({ value, onChange, className = "" }) {
    return <input
        className={className}
        onChange={e => onChange({ value: e.currentTarget.value.replace(/[^0-9.]/g, '') })}
        defaultValue={value}
        autoFocus
        style={{ width: "50px", textAlign: "center" }}
        onKeyPress={e => {
            if (!/[0-9.]/.test(e.key)) {
                e.preventDefault();
            }
        }}
    />;
}

/** this component displays the value but does not allow the user to edit it.
 *  @param param0
 *  @returns the React component with the no edit field renderer. */
export function NoEditFieldRenderer ({ value, onChange, className = "", label }) {
    return <span
        className={className}
        style={{ width: "50px", textAlign: "center" }}
    >{label || value}</span>;
}

/** the option field renderer for conditions.  It uses an HTML select to allow the user to edit the field
 *  by selecting from a list of options.
 *  @param param0
 *  @returns the React component with the option field renderer. */
export function OptionFieldRenderer ({ value, onChange, className = "", options = [{value: "Unknown", label: "Unknown"}] }) {
    const hasValue = options.find((option) => option.value === value) !== undefined;
    return <HTMLSelect 
        className={className}
        onChange={(event) => onChange({ value: event.currentTarget.value})}
        defaultValue={hasValue || !options?.length ? value : options[0].value}
        options={options}
    />;
}
