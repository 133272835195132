import React from 'react';
import { getRoutes } from 'config';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader';
import { OneColumnContainer } from 'components/common/layout/containers/one-column-container/OneColumnContainer';
import { HealthMonitorCardsView } from './views/HealthMonitorCardsView';
import { HealthMonitorTabsView } from './views/HealthMonitorTabsView';
import { HELP, STRINGS } from 'app-strings';

const HealthMonitorPage = (): JSX.Element => {
	return (
		<PageWithHeader
			name={HealthMonitorPage.name}
			addPadding={true}
			showTimeBar={false}
			showGlobalFilters={false}
			{...getRoutes()['health-monitor']}
			title={STRINGS.HEALTH_MONITORING_PAGE.pageTitle}
			helpInfo={HELP.monitoringHealth}
		>
			<OneColumnContainer key="cards">
				<HealthMonitorCardsView />
			</OneColumnContainer>
			<OneColumnContainer key="tabs">
				<HealthMonitorTabsView />
			</OneColumnContainer>
		</PageWithHeader>
	);
};

export { HealthMonitorPage };
