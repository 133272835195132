import React from 'react';
import {
    FormGroup,
    Classes,
    IRadioGroupProps,
    IRadioProps
} from '@blueprintjs/core';
import classNames from "classnames";
import { Field } from "formik";
import { FieldError } from "./FieldError";

export type RadioButtonType = IRadioProps & {
    helperText?: string
}

export const RadioButtonField = ({ label, helperText, inline, ...buttonProps }: RadioButtonType) => {

    const labelClasses = classNames(
        Classes.RADIO,
        Classes.CONTROL,
        Classes.ALIGN_LEFT,
        {
            [Classes.DISABLED]: buttonProps.disabled
        },
        {
            [Classes.INLINE]: inline
        },
        buttonProps.className
    )

    const helperClasses = classNames(
        Classes.TEXT_SMALL,
        Classes.TEXT_MUTED,
        {
            [Classes.TEXT_DISABLED]: buttonProps.disabled
        },
        "ml-1"
    )
    return (
        <label className={ labelClasses }>
            <Field type="radio" { ...buttonProps } />
            <span className={ Classes.CONTROL_INDICATOR }>
            </span>
            <span>
                { label }
            </span>
            {
                //For now going with this pattern but we can change it later to have it open in a tooltip or popover
                helperText ?
                    <span className={ helperClasses }>({ helperText })</span>
                    :
                    null
            }
        </label>
    );
};

const renderChildren = (children, props: RadioButtonType) => {
    return React.Children.map(children, (child: React.ReactElement<RadioButtonType>) => {
        //For now we only render the children only if they are of type RadioButtonField
        // @ts-ignore - compile error on .type but it does exist
        if(child.type && child.type.name === RadioButtonField.name) {
            const { name, disabled, inline } = props;
            // get basic properties from the parent and pass it to the clone (RadioButtonField)
            // We are cloning because we need to extract properties from child and create the component again.
            // @idea: BlueprintJS
            const onChange = (e) => {
                if (props.onChange) {
                    props.onChange(e);
                }
            }
            return React.cloneElement(child, {
                name: name,
                disabled: disabled,
                onClick: onChange,
                ...child.props,
                inline: inline,
            })
        }
    });
}
//Component for grouping radio buttons. Omitting name and onChange and making 'name' mandatory
export const RadioButtonGroup = (props: { name: string, children: React.ReactNode, onChange?} & Omit<IRadioGroupProps, 'name' | 'onChange'>) => {
    const { label, children, ...otherProps } = props;
    const classes = classNames(
        'tir-ui-radio-btn',
        props.className
    )

    return (
        <div className={ classes }>
            <FormGroup
                label={label}
            >
                {
                    renderChildren(children, otherProps)
                }
                {
                    props.inline ? <br/> : null
                }
                <FieldError name={ props.name } />
            </FormGroup>
        </div>
    )
};
