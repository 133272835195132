import { gql, DocumentNode } from "@apollo/client";

export class Query {
    gqlQuery: DocumentNode;

    // TODO Handle invalid gql string passed in. Property `gqlQuery` needs to be initialized (Typescript constraint)
    constructor(query: string | DocumentNode) {
        if (typeof query === "string") {
            this.gqlQuery = gql(query);
        } else {
            this.gqlQuery = query;
        }
    }

    getGqlQuery(): DocumentNode {
        return this.gqlQuery;
    }
}
