/** This file defines the SparklineTile React component.  The SparklineTile React component 
 *  displays a time series data in a sparkline.  The sparkline just has one time series 
 *  data set with no axes.
 *  @module */
import React, { useRef } from "react";
import { Icon, IconName } from "@tir-ui/react-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { CHART_COLORS } from "components/enums";
import { HighchartsData, timeSeriesTooltipFormatter, timeSeriesTooltipFormatterParams } from "components/reporting/utils/Types";
import { BASE_CHART_OPTIONS } from "components/reporting/charts/defaults/HighchartDefaults";
import "./SparklineTile.scss";

export type sparklineTileChartData = HighchartsData;

/** This interface defines the properties passed into the SparklineTile component.*/
export interface SparklineTileProps {
    /** an object with additional highcharts otions that are merged into the default highcharts configuration. */
    config?: object;
    /** a string with the chart title. */
    title?: string | number;
    /** optional data that can be displayed above the chart. */
    data?: string | number;
    /** a String with the units to display for the optional data displayed above the chart. */
    unit?: string;
    /** the highcharts time series data that are to be displayed on the chart. */
    chartData?: sparklineTileChartData;
    /** the comparison data that coincides with the chartData object and is in the format of a highcharts series data. */
    chartCompData?: sparklineTileChartData;
    /** a number with the comparison data time offset in seconds. */
    comparisonOffset?: number;
    /** the suffix that should be appended to the name of the dataset in the legend. */
    comparisonSuffix?: string;
    /** an array of numbers with the thresholds to be displayed on the chart. */
    thresholds?: Array<number | sparklineTileChartData>;
    /** a String with the class name. */
    className?: string;
    /** the name of the icon to display above the chart. */
    icon?: IconName;
    /** a string with the data to display to the right of the icon. */
    iconData?: string | number;
    /** a boolean value, if true show the marker. */
    leadingMarker?: boolean;
    /** a number with the minimum y-value to be displayed. */
    yMin?: number;
    /** a number with the maximum y-value to be displayed. */
    yMax?: number;
    /** a function that replaces the tooltip formatter for the chart. */
    tooltipFormatter?: timeSeriesTooltipFormatter;
    /** a boolean value, if true enable full screen mode. */
    enableFullScreen?: boolean;
}

const DEFAULT_OPTIONS = Highcharts.merge(BASE_CHART_OPTIONS, {
    chart: {
        height: null,
        width: null,
        type: "spline",
        reflow: true,
        backgroundColor: "transparent",
        events: {}
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        visible: false
    },
    yAxis: {
        visible: false,
    },
    title: {
        text: "",
    },
    plotOptions: {
        series: {
            color: "#88C",
            marker: {
                enabled: false
            }
        }
    },
    series: [
        {
            data: [],
            animation: {
                duration: 500,
            },
            borderRadius: 4,
            borderColor: "transparent",
            maxPointWidth: 10,
        },
    ],
    tooltip: {
        enabled: true,
        backgroundColor: "white",
        shared: true
    },
    time:{
        useUTC: false
    },
});

/** Renders the SparaklineTile React component.
 *  @param cardProps the properties passed in.
 *  @returns JSX with the SparaklineTile React component.*/
export function SparklineTile(props: SparklineTileProps) {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    function getChartOptions() {
        let options = cloneDeep(DEFAULT_OPTIONS);
        if (props.leadingMarker === undefined || props.leadingMarker === true) {
            options.chart.events.load = function (this:any) {
                const [series] = this.series;
                if (series.data.length > 1) {
                    //set marker on last point
                    series.data[series.data.length - 1].update({ marker: { enabled: true } });
                }
            };
        }

        options.tooltip.formatter = function (this: timeSeriesTooltipFormatterParams) {
            if (props.tooltipFormatter) {
                return props.tooltipFormatter(this);
            } else {
                return this.y;
            }
        };

        //merge chart config
        if (props.config) {
            options = merge(options, props.config);
        }
        if (props.yMin !== undefined) {
            options.yAxis.min = props.yMin;
        }
        if (props.yMax !== undefined) {
            options.yAxis.max = props.yMax;
        }
        //merge data
        let tsData: HighchartsData = cloneDeep(props.chartData);
        markIsolatedPoints(tsData);
        options.series[0].data = tsData;

        if (props.chartCompData) {
            tsData = cloneDeep(props.chartCompData);
            markIsolatedPoints(tsData);
            options.series.push({
                color: new Highcharts.Color("#88C").setOpacity(0.4).get(),
                animation: {
                    duration: 500,
                },
                borderRadius: 4,
                borderColor: "transparent",
                maxPointWidth: 10,
                data: tsData
            });
        }

        if (props.thresholds && props.chartData?.length) {
            for (const threshold of props.thresholds) {
                options.series.push({
                    type: "line",
                    dashStyle: "Dash",
                    color: CHART_COLORS.THRESHOLD_DEFAULT,
                    lineWidth: 1,
                    data: Array.isArray(threshold) ? threshold : props.chartData.map(d => {
                        if (d && d["x"] !== undefined) {
                            return {
                               x: d["x"],
                               y: threshold
                            };
                        } else {
                            return threshold;
                        }
                    })
                });
            }
        }
        return options;
    }

    return (
        <div
            className={`chart-tile sparkline-tile d-flex flex-row justify-content-center ${
                props.className ? props.className : ""
            }`}
        >
            { (props.icon || props.iconData || props.title) && 
                <div className="p-2 d-flex align-items-center flex-column justify-content-center">
                    {(props.icon || props.iconData !== undefined) && (
                        <span className="text-nowrap">
                            {props.icon && <Icon icon={props.icon} iconSize={25} className="tile-icon"/>}
                            {props.iconData !== undefined && (
                                <span
                                    className="display-6 p-1"
                                    aria-label="icon-data"
                                >
                                    {props.iconData}
                                </span>
                            )}
                        </span>
                    )}
                    {props.title && (
                        <div
                            className="font-size-x-small font-size-lg-small font-weight-bold"
                            aria-label="title"
                        >
                            {props.title}
                        </div>
                    )}
                </div>
            }
            <div className="text-center d-flex flex-column justify-content-center flex-grow-1">
                {props.data !== undefined && (
                    <h6 className="m-0">
                        <span aria-label="sparkline-data">{props.data}</span>
                        {props.unit !== undefined && (
                            <span
                                className="font-size-x-small font-size-lg-small font-weight-bold p-1"
                                aria-label="unit"
                            >
                                {props.unit}
                            </span>
                        )}
                    </h6>
                )}
                {props.chartData && (
                    <div className="position-relative flex-grow-1 card-chart-holder">
                        <div className="position-absolute w-100 h-100">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={getChartOptions()}
                                containerProps={{
                                    style: { width: "100%", height: "100%" },
                                }}
                                ref={chartRef}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

/** takes all the points that are not part of a line segment and adds a marker to those points so 
 *      can be displayed.
 *  @param points the array of point to search for isolated points that are not part of any line segments. */
function markIsolatedPoints(points: Array<any>): void {
    for (let index = 0; index < points.length; index++) {
        if (index === 0 && points.length > 1 && points[1].y === null) {
            points[index].marker = {enabled: true, radius: 2};
        } else if (index === (points.length - 1) && points.length > 1 && points[points.length - 2].y === null) {
            points[index].marker = {enabled: true, radius: 2};
        } else if (index > 0 && index < (points.length - 1) && points[index - 1].y === null && points[index + 1].y === null) {
            points[index].marker = {enabled: true, radius: 2};
        }
    }
}
