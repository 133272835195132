/** This file defines the global unit store.  This store is a singleton that 
 *  caches the units for all the React components.  The store is accessed by 
 *  the useUnits hook and should not be accessed directly.
 *  @module 
 */
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { DataOceanMetadata, DataOceanMetric } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type';

/** stores the units once in the closure.*/
let units: Record<string, Record<string, Unit>> = {};
/** the list of listeners for changes in the store.*/
let callbacks: Array<(event: {newVal: Record<string, Record<string, Unit>>, prevVal: Record<string, Record<string, Unit>>}) => void> = [];
/** the data ocean metadata that is used to look up metrics and units. */
let dataOceanMetadata: DataOceanMetadata;

/** initializes the units store. */
export async function init() {
// I wanted to call this from Main.tsx but it was causing unit tests to fail.  I have added
// the setDataOceanMetadata function and called that from Main.tsx instead.     
//    dataOceanMetadata = DataOceanUtils.dataOceanMetaData;
//    if (!dataOceanMetadata) {
//        dataOceanMetadata = await DataOceanUtils.init();
//    }
}

/** sets the DO metadata.
 *  @param doMetaData the DataOceanMetaData which contains the object types, metrics and keys for the data ocean. */
export function setDataOceanMetadata(doMetaData: DataOceanMetadata): void {
    dataOceanMetadata = doMetaData;
}

/** returns the units for all types.
 *  @returns the units for all types.*/
function getUnits(): Record<string, Record<string, Unit>> {
    return units;
}

/** sets the units for all types.
 *  @param units the units for all types.*/
function setUnits(newUnits: Record<string, Record<string, Unit>>): void {
    if (newUnits && Object.keys(newUnits).length > 0) {
        for (const key in newUnits) {
            units[key] = newUnits[key];
        }
        triggerSync();
    }
}

/** returns the dictionary of units for the specified type.
 *  @param type a String with the type, for example "Site", "Application", "Interface" ...
 *  @returns the dictionary of units.*/
function getUnitsForType(type: string): Record<string, Unit> {
    return units[type] || {};
}

/** sets the dictionary of units for the specified type.
 *  @param type a String with the type, for example "Site", "Application", "Interface" ...
 *  @param newUnits the dictionary of units that should be cached.*/
function setUnitsForType(type: string, newUnits: Record<string, Unit>): void {
    if (type) {
        if (newUnits && Object.keys(newUnits).length > 0) {
            units[type] = newUnits;
        } else {
            // No units provided, clear the item from the cache
            delete units[type];
        }
        triggerSync();
    }
}

/** triggers all the places where this store is used to sync. */
function triggerSync() {
    for (const callback of callbacks) {
        callback({ newVal: units, prevVal: units });
    }
}

/** add a change callback.
 *  @param callback The callback function.*/
function addChangeCallback(callback: (event: {newVal: Record<string, Record<string, Unit>>, prevVal: Record<string, Record<string, Unit>>}) => void) {
    callbacks.push(callback);
}

/** remove the change callback.
 *  @param callback The callback function.*/
function removeChangeCallback(callback?: (event: {newVal: Record<string, Record<string, Unit>>, prevVal: Record<string, Record<string, Unit>>}) => void) {
    callbacks = callbacks.filter(c => c !== callback);
}

/** returns the unit for a metric id, using the data ocean metadata.
 *  @param metricId a String with the id of the metric.
 *  @returns a String with the unit to use for the metric. */
function getUnitForMetric(metricId: string): string {
    if (dataOceanMetadata?.metrics && dataOceanMetadata.metrics[metricId]) {
        return dataOceanMetadata.metrics[metricId].unit || "";
    }
    return "";
}

/** returns the label for a metric id, using the data ocean metadata.
 *  @param metricId a String with the id of the metric.
 *  @returns a String with the label to use for the metric. */
function getLabelForMetric(metricId: string): string {
    if (dataOceanMetadata?.metrics && dataOceanMetadata.metrics[metricId]) {
        return dataOceanMetadata.metrics[metricId].label || "";
    }
    return "";
}

/** returns the label for a metric id, using the data ocean metadata.
 *  @param metricId a String with the id of the metric.
 *  @returns a String with the label to use for the metric. */
function getMetricDef(metricId: string): DataOceanMetric | undefined {
    if (dataOceanMetadata?.metrics && dataOceanMetadata.metrics[metricId]) {
        return dataOceanMetadata.metrics[metricId];
    }
    return undefined;
}

export { 
    getUnits, setUnits, getUnitsForType, setUnitsForType, addChangeCallback, removeChangeCallback, getUnitForMetric, 
    getLabelForMetric, getMetricDef 
};
