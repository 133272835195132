/** This file defines the various priority enums and constants that are used in many
 *  of the hyperion incident and runbook views.
 *  @module */
import { STRINGS } from "app-strings"
import { itemType } from "../common/select/SelectInput";


/** an enum which defines all the valid priority values. */
export enum PRIORITY {
    UNKNOWN     = "UNKNOWN",
    LOW         = "LOW",
    MODERATE    = "MODERATE",
    HIGH        = "HIGH",
    CRITICAL    = "CRITICAL"
}

/** A map of index values for priorities to be used when sorting based on priority. */
export const PRIORITY_INDEX = {
    [PRIORITY.UNKNOWN]:     0,
    [PRIORITY.LOW]:         1,
    [PRIORITY.MODERATE]:    2,
    [PRIORITY.HIGH]:        3,
    [PRIORITY.CRITICAL]:    4,
}

/** a constant which maps the priority enum value to a label. */
export const PRIORITY_TO_LABEL_MAP: Record<string, string> = {
    [PRIORITY.LOW]:         STRINGS.incidentPriorities.low,
    [PRIORITY.MODERATE]:    STRINGS.incidentPriorities.moderate,
    [PRIORITY.HIGH]:        STRINGS.incidentPriorities.high,
    [PRIORITY.CRITICAL]:    STRINGS.incidentPriorities.critical,
}

/** a constant which defines the priority colors. */
export const COLORS: Record<string, string> = {
    [PRIORITY.UNKNOWN]:     "#cbd5e0",
    [PRIORITY.LOW]:         "#cbd5e0",
    [PRIORITY.MODERATE]:    "#fcda5d",
    [PRIORITY.HIGH]:        "#f28349",
    [PRIORITY.CRITICAL]:    "#c74141"
};

/** a constant which defines the class specification for each of the priority values. */
export const CSS_CLASS: Record<string, Record<string, string>> = {
    [PRIORITY.UNKNOWN]:     { text: "text-priority-unknown", bg: "bg-priority-unknown", border: "border-priority-unknown"},
    [PRIORITY.LOW]:         { text: "text-priority-low", bg: "bg-priority-low", border: "border-priority-low" },
    [PRIORITY.MODERATE]:    { text: "text-priority-moderate", bg: "bg-priority-moderate", border: "border-priority-moderate"},
    [PRIORITY.HIGH]:        { text: "text-priority-high", bg: "bg-priority-high", border: "border-priority-high" },
    [PRIORITY.CRITICAL]:    { text: "text-priority-critical", bg: "bg-priority-critical", border: "border-priority-critical" }
};

/** returns a list of label value pair of priorities
 *  @returns an array with the priority items. */
export const getPriorityItemsListForDropdown = (): Array<itemType> => {
    const priorityItemsList = new Array<itemType>();

    Object.keys(PRIORITY).forEach( p => {
        if (p !== PRIORITY.UNKNOWN) {
            const label = PRIORITY_TO_LABEL_MAP[PRIORITY[p]] || "";
            const priorityClasses = CSS_CLASS[PRIORITY[p]];
            priorityItemsList.push({
                value: p,
                label: label,
                className: priorityClasses ? `${priorityClasses.bg} ${priorityClasses.border}` : ""
            });    
        }
    })
    return priorityItemsList;
};

/** returns whether or not the specified object is a priority value.
 *  @param priority the value to test.
 *  @returns a boolean value, true if the specified priority is in the priority
 *      enum, false otherwise. */
 export function isPriority(priority: any): boolean {
    const priorityValues: Array<string> = Object.values(PRIORITY);
    return priorityValues.includes(priority);
}

