/** This module defines the predictive AI notification blade React component.  The predictive AI notification 
 *      blade is displayed when the user clicks on the notification in the top nav bar.
 *  @module */
import React, { ReactNode, useRef } from 'react';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { BladeContainer } from 'components/common/layout/containers/blade-container/BladeContainer';
import { DetailsPanel } from 'components/reporting';
import { PRIORITY, SIZE } from 'components/enums';
import { BladeItemBrief } from 'components/common/layout/blade-item-brief/BladeItemBrief';
import { getURL, useQueryParams } from 'utils/hooks/useQueryParams';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { Link } from 'react-router-dom';
import { getURLPath } from 'config';
import { RoundedLinkButton } from 'components/common/rounded-link-button/RoundedLinkButton';
import CapacityPlanningDialog from 'pages/incident-search/modals/capacity-planning/CapacityPlanningDialog';

/** an integer with the max number of predictions to show in the blade. */
const MAX_ITEMS_TO_SHOW: number = 10;

/** this interface defines the predition cart object which has the details of a prediction displayed in a card. */
export interface PredictiveAiNotificationCardInfo {
    /** a String with the id of the prediction. */
    id: string;
    /** a String with the title of the prediction. */
    title?: string;
    /** a String with the message for the prediction. */
    message?: string|ReactNode;
    /** an optional boolean value, true if the predition is new. */
    new?: boolean;
    /** a PRIORITY object with the priority of the prediction. */
    priority?: PRIORITY;
    /** a String with the URL of the link that a prediction should go to. */
    link?: string;
    /** any data to display. */
    data?: any;
};

/** this interface defines the properties passed into the PredictiveAiNotificationsBlade React component. */
export interface PredictiveAiNotificationsBladeProps {
    /** a boolean value, true if it is loading. */
    loading?: boolean;
    /** a PRIORITY object with the priority to display in the blade. */
    priority?: PRIORITY;
    /** the array of prediction notifications that should be displayed in the blade. */
    notifications?: PredictiveAiNotificationCardInfo[];
    /** the handler for blade closed notifications. */
    onCloseClicked?: () => void;
    /** the handler for blade cleared notifications. */
    onClearNotificationsClicked?: () => void;
}

/** Renders the top predictive AI notifcation blade component.
 *  @param props the properties passed in.
 *  @returns JSX with the predictive AI notification blade component.*/
export function PredictiveAiNotificationsBlade ({
    notifications = [], loading = false, priority = PRIORITY.UNKNOWN, onCloseClicked, onClearNotificationsClicked
}: PredictiveAiNotificationsBladeProps): JSX.Element {
    const { params, setQueryParam } = useQueryParams({ listenOnlyTo: ["showAdvisorBlade"] });
    //const history = useHistory();
    const newNotificationsCount = notifications && notifications.length > 0 ? notifications.filter(notification => Boolean(notification.new)).length : 0;
    const hrefForViewAll = getURL(
        getURLPath("ai-prediction-dashboard"),
        {
            /*
            [FILTERS_QUERY_PARAM_KEY]: {
                [FILTER_NAME.priority]: Object.values(PRIORITY),
                [FILTER_NAME.incidentStatus]: Object.values(INCIDENT_STATUS),
            }
                */
        },
        { replaceQueryParams: true }
    );

    // Capacity Planning Modal
    const capacityPlanningModal = useRef();
    const openCapacityPlanningModal = (editInfos = null) => {
        if (capacityPlanningModal.current) {
            // @ts-ignore
            capacityPlanningModal.current.handleOpen(editInfos);
        }
    }
    
    return params.showAdvisorBlade === "true" ? <DetailsPanel>
        <BladeContainer
            title={STRINGS.predictions.bladeTitle + " (" + (notifications.length >= MAX_ITEMS_TO_SHOW ? MAX_ITEMS_TO_SHOW + "+" : notifications.length) + ")"}
            subText={newNotificationsCount > 0 && newNotificationsCount < notifications.length ? newNotificationsCount + " new since last visit" : ""}
            // icon={APP_ICONS.AI}
            icon={IconNames.AI_BIS_1}
            // status={priority}
            isPriority={false}
            noContentPadding={true}
            onCloseClicked={() => {
                setQueryParam("showAdvisorBlade", "");
                if (onCloseClicked) {
                    onCloseClicked();
                }
            }}
            headerContent={
                <div className="d-flex justify-content-between pt-2 px-2 align-items-end">
                    <Link to={hrefForViewAll}>{STRINGS.predictions.viewAll}</Link>
                    {
                        onClearNotificationsClicked &&
                        <RoundedLinkButton onClick={onClearNotificationsClicked} text={STRINGS.predictions.clearNotifications} size={SIZE.xs}/>
                    }
                </div>
            }
        ><DataLoadFacade loading={loading} data={notifications} error={undefined}>{
            notifications.length === 0 ?
            <div className="p-2 mt-5 text-center text-secondary">{STRINGS.predictions.noPredictions}</div> :
            notifications.map((runbook, index) => {
                return index < MAX_ITEMS_TO_SHOW ? <div key={runbook.id + "-" + index} className={"border-bottom pl-3 d-flex" + (runbook.new ? "" : " opacity-8")}>
                    <div className="flex-grow-1">
                        <BladeItemBrief status={PRIORITY[runbook.priority || PRIORITY.UNKNOWN] || PRIORITY.UNKNOWN}
                            isPriority={true}
                            title={
                                runbook.new ?
                                <>{runbook.title}<span className="display-10 bg-success text-white align-top ml-1" style={{ padding: "0 2px"}}>New</span></> :
                                runbook.title
                            }
                            noContentPadding={true} className="mb-2 mt-4 special-ai">
                            {runbook.message}
                        </BladeItemBrief>
                    </div>
                    { /*runbook.link && <Button role="pivot" className="rounded-0 border-left flex-grow-0" icon={IconNames.CHEVRON_RIGHT} minimal={true} onClick={() => history.push(String(runbook.link))}/>*/}
                    <Button 
                        role="pivot" className="predictive-item rounded-0 border-left flex-grow-0" icon={IconNames.CHEVRON_RIGHT} minimal={true} 
                        onClick={() => {
                            openCapacityPlanningModal();
                        }}
                    />
                </div> : undefined;
            })
        }
        {
            notifications.length > MAX_ITEMS_TO_SHOW  &&
                <div className="text-center p-3">
                    {/* Commenting this out for now because we're limiting the query to MAX_ITEMS_TO_SHOW + 1 */}
                    {/* {newNotificationsCount - MAX_ITEMS_TO_SHOW} {STRINGS.more}<br/> */}
                    <Link to={hrefForViewAll}>{STRINGS.predictions.viewAll}</Link>
                </div>
        }
        </DataLoadFacade></BladeContainer>
        <CapacityPlanningDialog ref={capacityPlanningModal} threshold1={80} threshold2={100} title="Ke e esto!?" refreshSearch={() => {}} />
    </DetailsPanel> :
    <></>;
}
