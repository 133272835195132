import React, { useState } from "react";
import {
    ErrorToaster,
    Icon,
    IconNames,
    Modal,
    SuccessToaster,
} from "@tir-ui/react-components";
import cx from "classnames";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { FetchResult, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import moment from "moment";
import { STRINGS } from "app-strings";
import { TIME_FORMAT } from "components/enums";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    ClientApp,
    ClientAppSecret,
    ClientAppSecretData,
} from "pages/api-access/types/ApiAccessTypes";
import { openModal } from "components/common/modal";
import "./ManageClientSecretsModal.scss";
import { SDWAN_ICONS } from "components/sdwan/enums";

interface DeleteClientApplicationSecretMutationInput {
    appId: string;
    secretId: string;
}

type ClientAppSecretWithDeletionState = ClientAppSecret & {
    isMarkedForDeletion: boolean;
};

type ModalCustomActions = {
    triggerRefresh?: () => void;
};

export const ManageClientSecretsModal = React.forwardRef((_props: any, ref) => {
    const defaultClientData: ClientApp = {
        id: "",
        name: "",
        secrets: [],
    };
    const [modalCustomActions, setModalCustomActions] =
        useState<ModalCustomActions>({});
    const [clientData, setClientData] = useState<ClientApp>(defaultClientData);
    const [newSecrets, setNewSecrets] = useState<
        ClientAppSecretWithDeletionState[]
    >([
        {
            description: "",
            expiration: 0,
            secretId: "",
            isMarkedForDeletion: false,
        },
    ]);

    const secretsMarkedForDeletion = newSecrets.filter(
        (secret) => secret.isMarkedForDeletion
    );
    const hasSecretsMarkedForDeletion = secretsMarkedForDeletion.length > 0;
    const hasSecretsAdded = newSecrets.some(
        (secret) => secret?.secretText && secret.secretText !== ""
    );
    const [isOpen, setIsOpen] = useState(false);

    React.useImperativeHandle(ref, () => ({
        setClientData(data: ClientApp) {
            if (data) {
                setClientData(data);
                setNewSecrets(
                    data.secrets.map((secret) => {
                        return { ...secret, isMarkedForDeletion: false };
                    })
                );
            }
        },
        handleOpen() {
            setIsOpen(!isOpen);
        },
        setActions(actions) {
            setModalCustomActions(actions);
        },
    }));

    const [deleteClientApplicationSecret] =
        useMutation<DeleteClientApplicationSecretMutationInput>(
            loader(
                "./../queries/delete-client-application-secret-mutation.graphql"
            ),
            {
                onCompleted: () => {
                    SuccessToaster({
                        message:
                            STRINGS.apiAccess.toastMessages.successDeleteSecret,
                    });
                },
                onError: (_err) => {
                    ErrorToaster({
                        message:
                            STRINGS.apiAccess.toastMessages.errorDeleteSecret,
                    });
                },
            }
        );

    if (!isOpen) {
        return <></>;
    }

    return (
        <Modal
            title={STRINGS.apiAccess.modalTitles.manageSecrets}
            customLabels={{
                submit: STRINGS.apiAccess.modalButtons.submitStepTwo,
            }}
            hideCancel={true}
            buttons={[
                {
                    label: STRINGS.apiAccess.modalButtons.addAnotherSecret,
                    action: () => handleAddAnotherSecret(),
                },
            ]}
            onSubmit={() => handleDeleteSecrets(secretsMarkedForDeletion)}
            onClose={() => handleCloseModal()}
            onSuccess={() => handleSuccess()}
            usePortal={false}
        >
            <div className="container">
                <div className="row">
                    <span className="col-sm-3">
                        {STRINGS.apiAccess.labels.clientName}
                    </span>
                    <span className="col font-weight-bold">
                        {clientData?.name}
                    </span>
                </div>
                <div className="row">
                    <span className="col-sm-3">
                        {STRINGS.apiAccess.labels.clientId}
                    </span>
                    <span className="col font-weight-bold">
                        {clientData?.id}

                        <Tooltip2
                            className={Classes.TOOLTIP2_INDICATOR + " border-0"}
                            content={STRINGS.apiAccess.copyToClipboard}
                        >
                            <CopyToClipboard
                                text={clientData?.id}
                                className="ml-2"
                            >
                                <Button
                                    small
                                    minimal
                                    icon={IconNames.DUPLICATE}
                                    onClick={() => {}}
                                />
                            </CopyToClipboard>
                        </Tooltip2>
                    </span>
                </div>
                {renderSecrets(newSecrets)}
            </div>

            {hasSecretsAdded && (
                <Callout intent={Intent.PRIMARY} className="mb-2">
                    <div className="row">
                        <span className="col-md-2 font-weight-bold">
                            {
                                STRINGS.apiAccess.manageClientSecretsModal
                                    .infoMessageForNewSecretTitle
                            }
                        </span>
                        <span className="col-md-10">
                            {
                                STRINGS.apiAccess.manageClientSecretsModal
                                    .infoMessageForNewSecret
                            }
                        </span>
                    </div>
                </Callout>
            )}

            {hasSecretsMarkedForDeletion && (
                <Callout intent={Intent.PRIMARY} icon={<></>}>
                    <span>
                        {
                            STRINGS.apiAccess.manageClientSecretsModal
                                .infoMessageForDeletion
                        }
                    </span>
                </Callout>
            )}
        </Modal>
    );

    /**
     * Shows the secret description
     *
     * @param description - secret description
     * @param isSecretExpired - boolean indicating if secret is expired
     *
     * @returns either an html span with an icon and red expiration date, if secret is expired, else
     * it returns a string
     */
    function renderDescription(description: string, isSecretExpired: boolean) {
        if (isSecretExpired) {
            return (
                <Tooltip2
                    className={Classes.TOOLTIP2_INDICATOR + " border-0"}
                    content={STRINGS.apiAccess.expiredSecretTooltip}
                >
                    <span className="m-1 mt-3">
                        <Icon
                            icon={SDWAN_ICONS.ALERT}
                            className="bp3-intent-danger mr-2"
                        />
                        {description}
                    </span>
                </Tooltip2>
            );
        }

        return description;
    }

    /**
     * Render the secret text/hint
     *
     * @param secret - client app secret
     * @returns a copy to clipboard button if the secret has secretText, else
     *          a string if only the hint is available
     */
    function renderSecretText(secret: ClientAppSecretWithDeletionState) {
        const maskedText = "**********************"; 
        const secretText = secret.hint ? (
            `${secret.hint}${maskedText}`
        ) : (
            <>
                {secret.secretText}
                <CopyToClipboard text={secret.secretText} className="ml-2">
                    <Button
                        minimal
                        small
                        icon={IconNames.DUPLICATE}
                        onClick={() => {}}
                    />
                </CopyToClipboard>
            </>
        );

        return secretText;
    }
    /**
     * Shows a table containing details about the secrets
     *
     * @param secrets - the list of secrets for the client
     *
     * @returns HTML Table that renders the secrets list
     */
    function renderSecrets(secrets: ClientAppSecretWithDeletionState[]) {
        return (
            <table className="table my-4">
                <thead>
                    <tr>
                        <th scope="col">
                            {
                                STRINGS.apiAccess.addClientSecretModal.columns
                                    .description
                            }
                        </th>
                        <th scope="col">
                            {
                                STRINGS.apiAccess.addClientSecretModal.columns
                                    .secret
                            }
                        </th>
                        <th scope="col">
                            {
                                STRINGS.apiAccess.addClientSecretModal.columns
                                    .expiration
                            }
                        </th>
                        <th scope="col"></th>
                    </tr>
                </thead>

                <tbody>
                    {secrets.map((secret, index) => {
                        const isExpired = +secret.expiration < moment().unix();
                        // Disabled if it is only one secret left
                        const isDeleteDisabled =
                            !secret.isMarkedForDeletion &&
                            secretsMarkedForDeletion.length ===
                                secrets.length - 1;

                        return (
                            <tr key={secret.secretId || index}>
                                <td className={cx("secret", {
                                    "secret--marked-for-deletion":
                                        secret.isMarkedForDeletion,
                                    "secret--expired": isExpired,
                                })}>
                                    {renderDescription(
                                        secret.description,
                                        isExpired
                                    )}
                                </td>
                                <td className={cx("secret", {
                                    "secret--marked-for-deletion":
                                        secret.isMarkedForDeletion,
                                    "secret--expired": isExpired,
                                })}>
                                    {renderSecretText(secret)}
                                </td>
                                <td className={"expiration-date "
                                    + cx("secret", {
                                    "secret--marked-for-deletion":
                                        secret.isMarkedForDeletion,
                                    "secret--expired": isExpired,
                                })}>
                                    {moment
                                        .unix(secret.expiration)
                                        .format(
                                            TIME_FORMAT.DISPLAY_TIME_FORMAT
                                        )}
                                </td>
                                <td>
                                    <Button
                                        small
                                        aria-label="client-secret-state-button"
                                        disabled={isDeleteDisabled}
                                        icon={
                                            secret.isMarkedForDeletion
                                                ? IconNames.UNDO
                                                : IconNames.TRASH
                                        }
                                        onClick={() => {
                                            if (!newSecrets) {
                                                return;
                                            }

                                            setNewSecrets(
                                                newSecrets.map((newSecret) => {
                                                    if (
                                                        newSecret.secretId ===
                                                        secret.secretId
                                                    ) {
                                                        return {
                                                            ...secret,
                                                            isMarkedForDeletion:
                                                                !secret.isMarkedForDeletion,
                                                        };
                                                    }

                                                    return newSecret;
                                                })
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    /**
     * Handles closing of the modal
     */
    function handleCloseModal() {
        setIsOpen(false);
        setNewSecrets([]);
        setClientData(defaultClientData);
    }

    /**
     * Handles the deletion of given client secrets
     *
     * @param secrets - an array with secrets marked for deletion
     *
     * @returns {Promise}
     */
    function handleDeleteSecrets(secrets: ClientAppSecretWithDeletionState[]) {
        const promises: Promise<
            FetchResult<
                DeleteClientApplicationSecretMutationInput,
                Record<string, any>,
                Record<string, any>
            >
        >[] = [];

        secrets.forEach((secret) => {
            promises.push(handleDeleteSecret(clientData.id, secret.secretId));
        });

        // The errors will be handled in each individual promise
        return Promise.all(promises).finally(() => {
            setIsOpen(false);
        });
    }
    /**
     * Handles the deletion of a client secret
     *
     * @param {string} clientId
     * @param {string} secretId
     *
     * @returns Promise
     */
    function handleDeleteSecret(clientId: string, secretId: string) {
        return deleteClientApplicationSecret({
            variables: {
                appId: clientId,
                secretId: secretId,
            },
        });
    }

    /**
     * Open a new modal for adding another secret
     */
    function handleAddAnotherSecret() {
        openModal("addClientSecretModal", {
            clientId: clientData?.id,
            clientName: clientData?.name,
            handleClientSecretCreated: (data: ClientAppSecretData) => {
                if (!data?.createClientApplicationSecret) {
                    return;
                }

                setNewSecrets([
                    ...newSecrets,
                    {
                        ...data.createClientApplicationSecret,
                        isMarkedForDeletion: false,
                    },
                ]);
            },
        });
    }

    function handleSuccess() {
        if (hasSecretsAdded || hasSecretsMarkedForDeletion) {
            modalCustomActions?.triggerRefresh &&
                modalCustomActions.triggerRefresh();
        }
    }
});
