import React from "react";
import moment, { unitOfTime } from "moment";
import { formatToLocalTimestamp } from "..";
import { TIME_FORMAT } from "components/enums";
import { formatDurationToString } from "../GeneralFormatter";
import { formatTimeToUserFriendlyString } from "../date-formatter/DateFormatter";

// TODO: add tests for `getElapsedTimeInfo()`, had a hard time mocking with jest
/**
 * Calculate the elapsed time info as a formatted string or number
 * @param {string|Date} time - initial time or date to calculate elapsed time
 * from
 * @param {unitOfTime.Diff|"standard"|"short"} format - `standard` returns "2
 * days, 15 hours ago", while `short` returns "2d 15h"
 * @param {string|Date} compareToTime - time or date to which initial time
 * should be compared with. If not provided, defaults to current time.
 * @returns {string|number} Formatted elapsed time
 */
export const getElapsedTimeInfo = (
    time: string | Date,
    format: unitOfTime.Diff | "standard" | "short",
    compareToTime?: string | Date,
): string | number => {
    /** time difference between compareToTime and current*/
    const diffDuration = moment.duration(
        moment
            .utc(compareToTime ? compareToTime : moment())
            .diff(moment.utc(time)),
    );

    let coreElapsedTimeInfo: string | number;

    const dayAmount = Math.floor(diffDuration.asDays());
    const isDayPlural = "day".concat(
        Math.floor(diffDuration.asDays()) > 1 ? "s" : "",
    );
    const hourAmount = diffDuration.hours();
    const isHourPlural = "hour".concat(diffDuration.hours() > 1 ? "s" : "");

    if (format === "standard") {
        if (dayAmount >= 1 && hourAmount > 0) {
            coreElapsedTimeInfo = `${dayAmount} ${isDayPlural} and ${hourAmount} ${isHourPlural}`;
        } else if (dayAmount >= 1) {
            coreElapsedTimeInfo = `${dayAmount} ${isDayPlural}`;
        } else {
            coreElapsedTimeInfo = moment
                .utc(time)
                .from(moment.utc(compareToTime), true);
        }
    } else if (format === "short") {
        coreElapsedTimeInfo =
            formatDurationToString(
                moment
                    .utc(time)
                    .diff(
                        moment.utc(compareToTime ? compareToTime : moment()),
                        "s",
                    ),
                true,
            ) || "<1s";
    } else {
        coreElapsedTimeInfo = moment
            .utc(time)
            .diff(moment.utc(compareToTime ? compareToTime : moment()), format);
    }

    return coreElapsedTimeInfo;
};

export interface ElapsedTimeFormatterProps {
    /** Time in JavaScript Date format that is to be formatted */
    time?: Date;
    /** Time to compare the provided time with. If not provided, it will be compared to current time */
    compareToTime?: Date;
    showRange?: boolean;
    /** Prefix to add before formatted output. e.g. passing "Running for" will return output as "Running for 2 minutes" */
    prefix?: String;
    /** Suffix to add after formatted output. e.g. passing "ago" will return output as "2 hours ago" */
    suffix?: String;
    /** A flag to control if the actual time information should also be displayed below */
    showOriginal?: boolean;
    /** Passing this flag as true will make original time take the primary spot and show
     * elapsed time information as a sub-text. This will work only when showOriginal flag is true. */
    showOriginalFirst?: boolean;
    /** Prefix to add before the formatted original time. e.g. passing "Started on" will display "Started on Apr 1st, 1:05 PM" */
    prefixForOriginal?: String;
    /** Format to display the elapsed time in */
    format?: unitOfTime.Diff | "standard" | "short";
    /** Time format to be used for displaying the original time information in (showOriginal flag must be true for this) */
    originalTimeFormat?: string;
    /** Additional sub-text to be displayed in the bottom */
    subText?: React.ReactNode;
}

export function ElapsedTimeFormatter({
    time,
    compareToTime,
    showRange = false,
    prefix,
    prefixForOriginal,
    suffix,
    showOriginal = false,
    showOriginalFirst = false,
    format = "standard",
    originalTimeFormat = TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT,
    subText,
}: ElapsedTimeFormatterProps) {
    if (time) {
        const coreElapsedTimeInfo = getElapsedTimeInfo(
            time,
            format,
            compareToTime,
        );

        const elapsedTimeInfo =
            (prefix ? prefix + " " : "") +
            coreElapsedTimeInfo +
            (suffix ? " " + suffix : "");
        const originalTimeInfo = showOriginal
            ? (prefixForOriginal ? prefixForOriginal + " " : "") +
              (showRange && compareToTime
                  ? formatTimeToUserFriendlyString(
                        {
                            startTime: compareToTime.getTime(),
                            endTime: time.getTime(),
                        },
                        originalTimeFormat,
                    )
                  : formatToLocalTimestamp(time, originalTimeFormat))
            : undefined;

        return (
            <div className="d-inline-block how-long-ago">
                <div data-testid="elapsed-time-info">
                    {showOriginalFirst ? originalTimeInfo : elapsedTimeInfo}
                </div>
                {(showOriginal || subText) && (
                    <div className="display-9 opacity-7 actual-value">
                        {showOriginalFirst ? elapsedTimeInfo : originalTimeInfo}
                        {subText && showOriginal ? " " : ""}
                        {subText}
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
}
