/** This file defines the cloudim summary view react component.
 *  @module */
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { STRINGS } from 'app-strings';
import { Tree, TreeNodeInfo } from "@blueprintjs/core";
import { clone, isEqual } from "lodash";
import { Region } from "pages/cloudim/views/cloudim-geomap/CloudIMGeoMapView";
import { PROVIDER_TYPES_TO_LABEL_MAP } from "components/enums";

import "./CloudIMSummaryView.scss";

/** defines the NodePath type. */
type NodePath = number[];

/** defines the TreeAction type. */
type TreeAction = { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "INITIALIZE"; payload: Array<any> }
    | { type: "RESET"; payload: TreeNodeInfo<{}>[] };

/** executes the specified callback for the tree node specified by the path.
 *  @param nodes the nodes in the tree.
 *  @param path the path.
 *  @param callback the callback function.*/
function forNodeAtPath(nodes: any[], path: NodePath, callback: (node: any) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

/** the reducer function.
 *  @param state the current state of the tree.
 *  @param action the current action.
 *  @returns the new state of the tree after taking the specified action.*/
function treeReducer(state: any[], action: TreeAction) {
    switch (action.type) {
        case "INITIALIZE": {
            const newState = clone(action.payload);
            return newState;
        }
        case "SET_IS_EXPANDED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState;
        }
        case "RESET": {
            return action.payload;
        }
    }
}

function createTree(details: Region) {
    let INITIAL_STATE = [
        {
            id: STRINGS.cloudim.geoMap.details.assets,
            label: <span><b>{STRINGS.cloudim.geoMap.details.assets}</b>{details.assets}</span>,
            childNodes: [] as Array<any>,
        }
    ]

    Object.keys(details.resources).map(resourceTypeKey => (
        INITIAL_STATE[0].childNodes.push({
            id: resourceTypeKey,
            label: <span><b>{resourceTypeKey + ": "}</b>{details.resources[resourceTypeKey]}</span>,
        })
    ));

    return INITIAL_STATE;
}

export interface CloudIMSummaryViewProps {
    regionDetail: Region;
    /** Style class to be applied to the outer container element */
    className?: string;
}

const CloudIMSummaryView = ({ regionDetail, className }: CloudIMSummaryViewProps): JSX.Element => {
    // Setup the tree.
    const INITIAL_STATE: Array<any> = createTree(regionDetail);
    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const lastRegionDetail = useRef<any>(regionDetail);
    useEffect(
        () => {
            if (!isEqual(lastRegionDetail.current, regionDetail)) {
                lastRegionDetail.current = regionDetail;
                dispatch({
                    payload: INITIAL_STATE,
                    type: "RESET",
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [regionDetail]
    );

    return <div className={"detailsFields" + (className ? " " + className : "")}>
        <div className="info">
            <span><b>{STRINGS.cloudim.geoMap.details.provider}</b>{PROVIDER_TYPES_TO_LABEL_MAP[regionDetail.platform]}</span>
            <span><b>{STRINGS.cloudim.geoMap.details.code}</b>{regionDetail.name}</span>
            <span><b>{STRINGS.cloudim.geoMap.details.location}</b>{regionDetail.location}</span>
        </div>
        <div>
            <Tree contents={nodes} onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} />
        </div>
    </div>
};

export { CloudIMSummaryView };
