/** This module contains the implementation for the Card component.  A Card displays data in 
 *  a rectangle with rounded edges and a background color that is different than the default, 
 *  for exmple white when in light mode.
 *  @module
 */
import React, { ReactNode } from 'react';
import { IconNames } from '@tir-ui/react-components';
import { Button } from '@blueprintjs/core';
import { SIZE } from 'components/enums';
import './Card.scss';

/** this interface defines the properties that are passed into the component. */
export interface CardProps {
    className?: string,
    ariaLabel?:string,
    closeable?: boolean,
    onClose?: () => void,
    children?: ReactNode,
    onClick?: Function,
    /** an optional parameter which specifies the height of the card. */
    height?: SIZE.xs | SIZE.s | SIZE.m | SIZE.l,
    /** a boolean value, if true hide the shadow and border, if false keep it.  By default it is false. */
    hideShadow?: boolean,
    /** a boolean value, if true hide the border, if false keep it.  By default it is false. */
    hideBorder?: boolean,
}

/** Creates the Card component, which is a component that displays a card in a flex 
 *  container.
 *  @param props an object with the properties passed to the Card component.
 *  @returns JSX with the Card component.*/
export function Card (props: CardProps): JSX.Element {
    const getWrappedChildren = (children) => {
        const countOfChildren = React.Children.count(children);
        if (countOfChildren) {
            if (children.length > 1) {
                return <div className="card-body d-flex flex-wrap justify-content-center">{children}</div>;
            } else {
                return React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, {
                        className: (child.props.className ? child.props.className + " " : "") + "card-body"
                    });
                })
            }
        }
    };
    return (
        <div aria-label={props.ariaLabel? `${props.ariaLabel}` : "" } className={"data-card card" + (props.hideShadow ? " hide-shadow" : "") + (props.hideBorder ? " hide-border" : "") + (props.className? " " + props.className : "") + (props.height? " card-height-" + props.height : "") + (props.onClick? " clickable" : "")} onClick={() => props.onClick && props.onClick()}>
            { props.closeable &&
                <Button aria-label="Close the card" icon={IconNames.CROSS} minimal={true} className="card-close-button" large
                    onClick={ props.onClose? props.onClose : undefined }
                />
            }
            { getWrappedChildren(props.children) }
        </div>
    );
}
