import React, { useEffect, useRef, useState } from "react";
import { LangEN, STRINGS } from "app-strings";
import { ErrorToaster, LoadingOverlay, Modal, SuccessToaster, Table, useStateSafePromise } from "@tir-ui/react-components";
import { Button, Callout, Intent, SpinnerSize, Tag } from "@blueprintjs/core";
import DOMPurify from "dompurify";
import { uniq } from 'lodash';
import { useHistory } from "react-router-dom";
import { getURL } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import { PARAM_NAME } from "components/enums/QueryParams";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { NodeLibrary, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { runbookService, subflowNodes } from "utils/runbooks/RunbookUtils";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import IncidentRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library.json";
import LifecycleRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/lifecycle_node_library.json";
import SubflowRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/subflow_node_library.json";
import ExternalRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library_external.json";
import OnDemandRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/on_demand_node_library.json";
import { Variant } from "components/common/graph/types/GraphTypes";
import { AvailableIntegration } from "pages/integrations/types/IntegrationTypes";
import { RunbookDependency, RunbookDependencyStatus } from "utils/runbooks/RunbookTypes";
import { DEFAULT_TAB_URL_STATE_KEY } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import ImportRunbookConnectorSelector from "./ImportRunbookConnectorSelector";
import { RunbookService } from "utils/services/RunbookApiService";
import { Method } from "components/common/graph/editors/subflow/SubflowVariableMappingEditor";
import './ImportRunbookModal.scss';

export type ImportRunbookModalActions = {
    handleOpen: () => void;
    setVariant: (variant: Variant) => void;
};

interface Props {
    ref: React.MutableRefObject<any>,
    refreshData: () => void;
}

type ImportProcessState = 'compatible' | 'warning' | 'error';

export const ImportRunbookModal = React.forwardRef((props: Props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const translations: LangEN = STRINGS;
    const [dialogState, setDialogState] = useState({
        showDialog: false,
        title: "My Dialog",
        loading: false,
        dialogContent: <></>,
        dialogFooter: <></>,
    });
    const [dialogClassName, setDialogClassName] = useState("");
    const [importProcessState, setImportProcessState] = useState<ImportProcessState>('compatible');
    const [variant, setVariant] = useState<Variant>(Variant.INCIDENT);
    const [dependencies, setDependencies] = useState<RunbookDependency[]>([]);
    const [dependencyConnectorMap, setDependencyConnectorMap] = useState({});
    const [runbookForExport, setRunbookForExport] = useState<any>({});
    const [errors, setErrors] = useState<Array<any>>([]);
    const integrationsCache = useRef<AvailableIntegration[] | undefined>();
    const [integrations, setIntegrations] = useState<AvailableIntegration[] | undefined>(undefined);
    const [isRunbookFileImported, setIsRunbookFileImported] = useState(false);
    const [executeSafely] = useStateSafePromise();
    const history = useHistory();

    // Get Integrations List
    useEffect(() => {
        const integrationsPromise = new Promise<AvailableIntegration[]>(
            async (resolve, reject) => {
                try {
                    const newIntegrations =
                        await IntegrationLibraryService.getAvailableIntegrations();
                    resolve(newIntegrations);
                } catch (error) {
                    reject(error);
                }
            }
        );
        executeSafely(integrationsPromise).then(
            (integrations) => {
                integrationsCache.current = integrations;
                setIntegrations(integrations);
            },
            () => {
                integrationsCache.current = integrations;
                setIntegrations([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let InitNodeLibrary: NodeLibrarySpec;
    switch (variant) {
        case Variant.INCIDENT:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.LIFECYCLE:
            InitNodeLibrary = LifecycleRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.SUBFLOW:
            InitNodeLibrary = SubflowRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.ON_DEMAND:
            InitNodeLibrary = OnDemandRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.EXTERNAL:
            InitNodeLibrary = ExternalRunbookNodeLibrary as NodeLibrarySpec;
            break;
        default:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
    }
    const nodeLibrary = useRef<NodeLibrary>(
        new NodeLibrary(InitNodeLibrary as NodeLibrarySpec)
    );

    /**
     * Actions to control the Connector Modal from outside
     */
    React.useImperativeHandle(ref, () => ({
        handleOpen() {
            setIsOpen(!isOpen);
        },
        setVariant(variant: Variant) {
            setVariant(variant);
        },
    }));

    /**
     * Render the Modal Content
     *
     * @returns {JSX.Element}
     */
    function renderModalContent() {
        return (
            <div className="import-runbook-modal">
                <>
                    {!isRunbookFileImported && (
                        <>
                            <div className="mb-3">
                                <span>
                                    {STRINGS.formatString(
                                        translations.runbooks.importDialog.text,
                                        { variant: translations.runbooks.runbookTextForVariant[variant]}
                                    )}
                                </span>
                            </div>
                            <input id="runbook-file" type="file" data-testid="input_uploadFile" />
                        </>
                    )}

                    {isRunbookFileImported && (
                        <>
                            {renderCalloutMessage(dependencies)}
                            {renderIntegrationsTable(dependencies)}
                            {renderSubflowsTable(dependencies)}
                        </>
                    )}

                    {errors?.length > 0 && (
                        <Callout
                            className="callout-message mt-2"
                            intent={Intent.DANGER}
                        >
                            {errors.map((error, index) => (
                                <p key={index}>{error}</p>
                            ))}
                        </Callout>
                    )}
                </>
            </div>
        );
    }

    /**
     * Render the table with the subflow nodes that the runbook depends on
     * 
     * @param dependencies - the list of runbook dependencies
     * 
     * @returns {JSX.Element}
     */
    function renderIntegrationsTable(dependencies: RunbookDependency[]) {
        function getRowControl(
            status: RunbookDependencyStatus,
            searchTerm: string
        ) {
            switch (status) {
                case "unknown":
                    return <></>;
                case "invalid":
                    return <></>;
                case "missing":
                    return <></>;
                case "compatible":
                    return <></>;
                case "manualInterventionRequired":
                    return <></>;
                case "updateRecommended":
                    return (
                        <>
                            <Button
                                className="ml-2"
                                intent={Intent.WARNING}
                                onClick={() => {
                                    history.push(
                                        getURL(getURLPath("integrations"), {
                                            [PARAM_NAME.searchText]: searchTerm,
                                            [DEFAULT_TAB_URL_STATE_KEY]: 'installed'
                                        })
                                    );
                                }}
                            >
                                { translations.runbooks.importModal.integrationStatus.updateRecommended.btn }
                            </Button>
                        </>
                    );
                case "updateRequired":
                    return (
                        <>
                            <Button
                                className="ml-2"
                                intent={Intent.DANGER}
                                onClick={() => {
                                    history.push(getURL(getURLPath("integrations"), {
                                        [PARAM_NAME.searchText]: searchTerm,
                                        [DEFAULT_TAB_URL_STATE_KEY]: 'installed'
                                    }));
                                }}
                            >
                                { translations.runbooks.importModal.integrationStatus.updateRequired.btn }
                            </Button>
                        </>
                    );

                case "installationRequired":
                    return (
                        <>
                            <Button
                                className="ml-2"
                                intent={Intent.DANGER}
                                onClick={() => {
                                    history.push(
                                        getURL(getURLPath("integrations"), {
                                            [PARAM_NAME.searchText]: searchTerm,
                                            [DEFAULT_TAB_URL_STATE_KEY]: 'available'
                                        })
                                    );
                                }}
                            >
                                { translations.runbooks.importModal.integrationStatus.installationRequired.btn }
                            </Button>
                        </>
                    );
            }
        }

        const STATUS_NEXT_STEP_MAP = {
            unknown: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.unknown.text
            ),
            invalid: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.invalid.text
            ),
            missing: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.missing.text
            ),
            compatible: <></>,
            manualInterventionRequired: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus
                    .manualInterventionRequired.text
            ),
            updateRecommended: renderStatusTag(
                Intent.WARNING,
                translations.runbooks.importModal.integrationStatus.updateRecommended
                    .text
            ),
            updateRequired: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.updateRequired.text
            ),
            installationRequired: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.installationRequired
                    .text
            ),
        };

        const columns = [
            {
                id: "name",
                Header: "Integration Name",
                accessor: "name",
                sortable: false,
                showFilter: false,
            },
            {
                id: "description",
                Header: "Description",
                accessor: "description",
                sortable: false,
                showFilter: false,
            },
            {
                id: "nextStep",
                Header: "Next Step",
                sortable: false,
                showFilter: false,
            },
            {
                id: "action",
                Header: "",
                sortable: false,
                showFilter: false,
            },
        ];

        const integrationsUsedByRunbook = dependencies
            .filter((el) => el.status !== "compatible")
            .map((dependency) => {
                const integrationDetails = integrations?.find(
                    (el) => el.id === dependency.sourcePackageId
                );

                // The integration is missing the subflow
                if (!integrationDetails) {
                    return {
                        name: dependency.name,
                        description: dependency.nodeLabel,
                        nextStep: STATUS_NEXT_STEP_MAP[dependency.status],
                        action: getRowControl(dependency.status, dependency.name                        )
                    };
                }

                return {
                    name: integrationDetails?.name || dependency.name,
                    description: integrationDetails?.description.short,
                    nextStep: STATUS_NEXT_STEP_MAP[dependency.status],
                    action: getRowControl(
                        dependency.status,
                        integrationDetails?.name || ''
                    ),
                };
            });

        function renderStatusTag(intent, label) {
            return (
                <Tag minimal intent={intent}>
                    {label}
                </Tag>
            );
        }

        if (!integrationsUsedByRunbook || integrationsUsedByRunbook.length === 0) {
            return <></>;
        }

        return (
            <Table
                className="mt-2"
                columns={columns}
                data={integrationsUsedByRunbook as Array<any>}
                interactive={false}
            />
        );
    }

    /**
     * Render the table with the subflow nodes that the runbook depends on
     * 
     * @param dependencies - the list of runbook dependencies
     * 
     * @returns {JSX.Element}
     */
    function renderSubflowsTable(dependencies: any) {
        const columns = [
            {
                id: "name",
                Header: "Subflow Name",
                accessor: "name",
                sortable: false,
                showFilter: false,
            },
            {
                id: "nodeLabel",
                Header: "Node Name",
                accessor: "nodeLabel",
                sortable: false,
                showFilter: false,
            },
            {
                id: "connector",
                Header: "Connector Name / Authentication Profile",
                formatter: (subflow) => {
                    if (!integrations) {
                        return <></>
                    }

                    const selectedConnector = dependencyConnectorMap[subflow.id];

                    function handleConnectorChange(connectorId: string, ev: any) {
                        setDependencyConnectorMap(prevState => ({
                            ...prevState, 
                            [connectorId]: ev?.currentTarget?.value
                        }));
                    }

                    return <ImportRunbookConnectorSelector
                        integrations={integrations}
                        integrationId={subflow?.sourcePackageId}
                        subflowId={subflow.id}
                        connectorId={selectedConnector}
                        handleConnectorChange={handleConnectorChange}
                    />
                }
            }
        ];

        const hasErrors = dependencies.find(el => ['installationRequired', 'updateRequired', 'invalid', 'unknown', 'missing'].includes(el.status));

        // If there is an error we don't show the subflow list
        if (hasErrors) {
            return <></>
        }

        return (
            <>
                <p className="mt-5">
                    {translations.runbooks.importModal.subflowConfigMessage}
                </p>
                <Table
                    columns={columns}
                    data={dependencies}
                    interactive={false}
                />
            </>
        );
    }

    /**
     * Render the messages on top of the modal which inform the user about the import state
     * 
     * @param dependencies - the list of runbook dependencies
     * 
     * @returns {JSX.Element}
     */
    function renderCalloutMessage(dependencies: RunbookDependency[]) {
        if (dependencies?.length === 0) {
            return <></>;
        }

        // We don't have to show duplicate messages
        const statuses = uniq(dependencies.map(el => el.status));
        const hasWarningsOrErrors = !!(statuses.find(el => el !== 'compatible'));
        const STATUS_INTENT_MAP = {
            unknown: Intent.WARNING,
            invalid: Intent.DANGER,
            missing: Intent.DANGER,
            compatible: Intent.SUCCESS,
            manualInterventionRequired: Intent.WARNING,
            updateRecommended: Intent.WARNING,
            updateRequired: Intent.DANGER,
            installationRequired: Intent.DANGER,
        };

        return (
            <div className="d-flex flex-column" style={{ gap: "8px" }}>
                {statuses.map((status, index) => {
                    // Hide compatible callout message if there are errors or warnings
                    if (hasWarningsOrErrors && status === 'compatible') {
                        return <></>;
                    }

                    return (
                        <Callout
                            key={index}
                            intent={STATUS_INTENT_MAP[status]}
                            className="py-4"
                            icon={null}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        translations.runbooks.importModal
                                            .calloutMessages[status]
                                    ),
                                }}
                            ></div>
                        </Callout>
                    );
                })}
            </div>
        );
    }

    /**
     * Handles closing of the modal
     */
    function handleCloseModal() {
        setErrors([]);
        setIsRunbookFileImported(false);
        setDependencies([]);
        setDependencyConnectorMap({});
        setIsOpen(false);
        setImportProcessState('compatible');
    }

    /**
     * Check if the proceed with import button should be disabled
     * 
     * @returns {Boolean}
     */
    function isProceedWithImportDisabled() {
        const hasUnsetConnectors = Object.values(dependencyConnectorMap).some(el => typeof el === 'undefined');
        
        return hasUnsetConnectors;
    }

    /**
     * Get an array containing the buttons that are going to be shown in the Modal Footer
     *
     * @returns {JSX.Element}
     */
    function getModalButtons() {
        const buttons: Array<any> = [];

        if (importProcessState === 'compatible') {
            buttons.push({
                action: isRunbookFileImported ? () => processImport(runbookForExport) : handleFileImport,
                label: isRunbookFileImported ? translations.runbooks.importModal.buttons.importBtn : translations.runbooks.importModal.buttons.readFileBtn,
                intent: Intent.PRIMARY,
                disabled: isRunbookFileImported && isProceedWithImportDisabled()
            })
        }

        if (importProcessState === 'warning') {
            buttons.push({
                action: isRunbookFileImported ? () => processImport(runbookForExport) : handleFileImport,
                label: isRunbookFileImported ? translations.runbooks.importModal.buttons.importAnywayBtn : translations.runbooks.importModal.buttons.readFileBtn,
                intent: Intent.PRIMARY,
                disabled: isRunbookFileImported && isProceedWithImportDisabled()
            })
        }

        return buttons;
    }

    /**
     * Handle the import of the file
     *
     * @returns
     */
    async function handleFileImport() {
        const fileList = (document.getElementById("runbook-file") as any).files;
        setErrors([]);
        setDependencies([])
        setRunbookForExport({});
        setIsRunbookFileImported(false);

        if (fileList && fileList?.length === 1) {
            const fileReader = new FileReader();
            fileReader.onload = async function () {
                const text: string = fileReader.result as string;
                if (text && text.length > 0) {
                    try {
                        const parsedText = JSON.parse(text);
                        const runbookExportFormat = parsedText?.data || parsedText;
                        setRunbookForExport(runbookExportFormat);

                        // Check if the format is correct
                        if (!runbookExportFormat?.runbook || !runbookExportFormat?.dependencies) {
                            errors.push(translations.runbooks.importDialog.wrongExportFormat);
                            setErrors([
                                translations.runbooks.importDialog.wrongExportFormat
                            ])

                            return;
                        }

                        // Check the node types, do not allow someone to copy in a node type from another runbook
                        if (runbookExportFormat?.runbook?.nodes?.length) {
                            const nodeTypes: string[] =
                                nodeLibrary?.current?.getNodeTypes();
                            if (variant !== Variant.SUBFLOW) {
                                nodeTypes.push(...subflowNodes);
                            }
                            const unsupportedTypes: string[] = [];
                            for (const node of runbookExportFormat.runbook
                                .nodes) {
                                if (!nodeTypes.includes(node.type)) {
                                    if (!unsupportedTypes.includes(node.type)) {
                                        unsupportedTypes.push(node.type);
                                    }
                                }
                            }
                            if (unsupportedTypes?.length) {
                                errors.push(
                                    STRINGS.formatString(
                                        translations.runbooks.importDialog.invalidTypeErrorText,
                                        {
                                            variant: translations.runbooks.runbookTextForVariant[variant],
                                            types: unsupportedTypes.join(", "),
                                        }
                                    )
                                );
                            }
                        }

                        const importedRunbookVariant = runbookExportFormat?.runbook?.variant?.toLowerCase();
                        const currentVariantPage = (variant === Variant.EXTERNAL ? Variant.INCIDENT : variant).toLowerCase();
                        const hasMatchingVariant = importedRunbookVariant === currentVariantPage;

                        if (errors.length === 0 && hasMatchingVariant) {
                            const result =
                                await runbookService.checkImportRunbookDependencies(
                                    runbookExportFormat
                                );
                            
                            const dependenciesList = result?.dependencies as RunbookDependency[];

                            // If there are no dependencies, we can proceed with the import
                            if (dependenciesList.length === 0) {
                                processImport(runbookExportFormat);

                                return;
                            }
                            
                            setDependencies(dependenciesList);
                            const updatedDependencyConnectorMap = {...dependencyConnectorMap};
                            dependenciesList.forEach(el => {
                                updatedDependencyConnectorMap[el.id] = undefined;
                            })
                            setDependencyConnectorMap({...updatedDependencyConnectorMap});
                            setProcessStatus(result);
                            setIsRunbookFileImported(true);
                        } else if (errors.length === 0) {
                            setErrors([
                                STRINGS.formatString(
                                    translations.runbooks.importDialog.wrongVariantErrorText,
                                    {
                                        variant: translations.runbooks.runbookTextForVariant[variant],
                                    }
                                ),
                            ]);
                        }
                    } catch (error) {
                        setErrors(
                            getErrorsFromResponse(
                                error,
                                STRINGS.formatString(
                                    translations.runbooks.importDialog.errorText,
                                    {
                                        variant: translations.runbooks.runbookTextForVariant[variant],
                                    }
                                ) +
                                "<br/>" +
                                error
                            )
                        );
                        console.error(error);
                    }
                }
            };
            fileReader.readAsText(fileList[0]);
        }
    }

    /**
     * Set the process state (in error / with warnings / compatible) for the import
     * 
     * @param result - the import/export object returned by the endpoint
     * 
     * @returns void
     */
    function setProcessStatus(result: any) {
        // Check for errors
        const dependenciesWithErrors = result?.dependencies.filter(el => ['installationRequired', 'updateRequired', 'invalid', 'missing', 'unknown'].includes(el.status));
        if (dependenciesWithErrors.length > 0) {
            setImportProcessState('error');

            return;
        }

        // Check for warnings
        const dependenciesWithWarnings = result?.dependencies.filter(el => ['upgradeRecommended', 'manualInterventionRequired'].includes(el.status));
        if (dependenciesWithWarnings.length > 0) {
            setImportProcessState('warning');
            return
        }

        setImportProcessState('compatible');
    }

    /**
     * Perform the runbook import
     * 
     * @param {object} runbook - the JSON payload for the import/export object
     */
    async function processImport(runbook) {
        const payload = getImportDataWithConnectorSelection(runbook);

        try {
            await RunbookService.importRunbook(payload);
            SuccessToaster({ message: translations.runbooks.importModal.toastMessage.success })    
            props?.refreshData();
        } catch (error) {
            console.error(error);
            ErrorToaster({ message: translations.runbooks.importModal.toastMessage.error })
        } finally {
            handleCloseModal();
        }
    }

    /** extract any error
     *  @param error the error object from the catch.
     *  @param defaultError the detault error to return if there is no known error code in the response.
     *  @returns a String array with any errors parsed from the response object, or the default error message. */
    function getErrorsFromResponse(error: any, defaultError): string[] {
        let errors: Array<string> = [];
        const errorMsg = error?.response?.data?.code
            ? STRINGS.formatString(
                translations.runbookEditor.errors.codes[
                    error?.response?.data?.code
                ],
                error?.response?.data?.innererror || {}
            )
            : defaultError;
        errors.push(errorMsg);
        return errors;
    }

    /**
     * Parse JSON and add user connector selection to the payload
     * 
     * @param runbookForExport 
     * 
     * @returns {JSON}
     */
    function getImportDataWithConnectorSelection(runbookForExport: any) {
        const payload = {...runbookForExport};
        
        if (!payload?.runbook?.nodes || dependencies?.length === 0) {
            return payload
        }

        dependencies.forEach((dependency) => {
            const nodes = payload.runbook.nodes;
            const node = nodes.find(el => el.id === dependency.id);
            const connectorProperties = node?.properties?.in?.find(el => el.method === Method.CONNECTOR);

            if (connectorProperties) {
                connectorProperties.outer = dependencyConnectorMap[dependency.id];
            }
        })

        return payload;
    }

    const modalButtons = getModalButtons();

    if (!isOpen) {
        return <></>;
    }

    /** Render the MODAL */
    return (
        <Modal
            title={translations.runbooks.importModal.title}
            buttons={modalButtons}
            onSubmit={() => { }}
            onClose={() => handleCloseModal()}
            usePortal={false}
            hideSubmit={true}
        >
            {renderModalContent()}
            <BasicDialog
                portalClassName="error-dialog"
                className={dialogClassName}
                dialogState={dialogState}
                onClose={() => {
                    setDialogState(
                        updateDialogState(dialogState, false, false, [])
                    );
                    setDialogClassName("");
                }}
            />
            <LoadingOverlay
                visible={false}
                spinnerSize={SpinnerSize.SMALL}
                loadingText={"Loading"}
            />
        </Modal>
    );
});
