import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Classes, Divider, Intent, Label, Menu, MenuItem, Radio, RadioGroup } from '@blueprintjs/core';
import { Form } from 'components/common/form';
import { STRINGS } from 'app-strings';
import { Icon, IconNames } from '@tir-ui/react-components';
import { Popover2 } from '@blueprintjs/popover2';
import './AddAuthenticationModal.scss';

export interface IEndpointTypePanelProps {
    alluvioEdgeData?: any;
    endpointDetails?: any;
    onChangeEndpointType: (event) => void;
    onAddPrivateEndpoint: (event) => void;
    onDeletePrivateEndpoint: (event) => void;
}

export enum EndpointTypeOptions {
    'public' = 'public',
    'private' = "private"
}

const EndpointTypePanel: React.FC<IEndpointTypePanelProps> = (props) => {
    const [edgeDeviceOptions, setEdgeDeviceOptions] = useState(props?.alluvioEdgeData?.edges || []);

    useEffect(() => {
        setEdgeDeviceOptions(props?.alluvioEdgeData?.edges.filter(item => !props.endpointDetails.edgeDeviceIds.includes(item.id)));
    }, [props?.alluvioEdgeData?.edges, props.endpointDetails.edgeDeviceIds]);

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p><b>{STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.title}</b></p>
            <Divider />
            <br />
            <Form
                initialValues={
                    {
                        endpoint_type: props.endpointDetails.endpointType,
                        private_endpoints: props.endpointDetails.edgeDeviceIds
                    }
                }
                loading={false}
            >
                <p>{STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.fields.sendQueriesTo}</p>
                <RadioGroup
                    name="endpoint_type"
                    onChange={props.onChangeEndpointType}
                    selectedValue={props.endpointDetails.endpointType}
                    inline={true}
                    className="pl-4 align-self-center"
                >
                    <Radio
                        label={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.fields.publicEndpoint}
                        value={EndpointTypeOptions.public}
                        className="mb-0 mr-2"
                    />
                    <Radio
                        label={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.fields.privateEndpoint}
                        value={EndpointTypeOptions.private}
                        className="mb-0 pt-2 position-relative d-block"
                    />
                </RadioGroup>
                <div className="ml-4 mt-4">
                    <div className="row ml-4">
                        <Label className="font-weight-bold col-md-11">
                            {STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.fields.alluvioEdge}
                        </Label>
                        <Label className="col-md-1">
                        </Label>
                    </div>
                    {props.endpointDetails.edgeDeviceIds.map((privateEndpoint) => {
                        return <div key={privateEndpoint} className="row ml-4">
                            <Label className="col-md-11 mb-1">
                                {props?.alluvioEdgeData?.edges?.find(item => item.id === privateEndpoint)?.name || <em>{STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.deletedAlluvioEdgeName}</em>}
                            </Label>
                            <Label className="col-md-1 mb-1">
                                <span className='d-flex align-items-center justify-content-center'>
                                    <Icon onClick={() => { props.onDeletePrivateEndpoint(privateEndpoint) }}
                                        intent={Intent.NONE}
                                        icon={IconNames.CROSS} />
                                </span>
                            </Label>
                        </div>
                    })}
                    <div className="p-2 ml-4 mb-4 mr-2 mt-2 add-alluvio-edge-control">
                        <Popover2
                            fill={true}
                            usePortal={false}
                            disabled={props.endpointDetails.endpointType !== EndpointTypeOptions.private}
                            content={<Menu>
                                {edgeDeviceOptions
                                    ? edgeDeviceOptions?.map((key) => { return <MenuItem key={key?.id} text={key?.name} onClick={() => props.onAddPrivateEndpoint(key?.id)} /> })
                                    : <MenuItem text={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.noAlluvioEdge} disabled></MenuItem>
                                }
                            </Menu>}
                            placement="left-start"
                        >
                            <Button
                                data-testid="add-alluvio-edge"
                                icon={IconNames.ADD}
                                minimal
                                disabled={props.endpointDetails.endpointType !== EndpointTypeOptions.private}
                                text={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.addAlluvioEdge}
                            />
                        </Popover2>
                    </div>
                </div>
            </Form>
            {/* uncomment this once the manage trusted certificates functionality will be implemented */}
            {/* <p style={{ position: "absolute", "bottom": 60 }}>
                {STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.note}
                <a className="p-1 text-nowrap" href={getURLPath('edge-configuration')} target='_blank' rel="noreferrer">{STRINGS.thirdPartyIntegrations.addAuthProfile.panels.endpointType.pageRefName} <Icon icon={APP_ICONS.LINK} /></a>
            </p> */}
        </div>
    );
};

export { EndpointTypePanel };
