import React, { useState } from "react";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { HELP, STRINGS } from "app-strings";
import AutoUpdateControl, {
    AutoUpdateState,
    TimeType,
} from "components/common/auto-update/AutoUpdateControl";
import { CloudIMGeoMapView } from "./views/cloudim-geomap/CloudIMGeoMapView";
import { FeatureFlag } from "components/common/feature-flag/FeatureFlag";
import CloudIMLegal from "./views/cloudim-legal";

const initAutoUpdate: AutoUpdateState = {
    enabled: true,
    interval: 5,
    lastUpdate: 60 * Math.floor(new Date().getTime() / (60 * 1000)),
    sequenceNumber: 0,
};
export const AutoUpdateContext = React.createContext(initAutoUpdate);

const CloudIMPage = (): JSX.Element => {
    const [autoUpdate, setAutoUpdate] = useState<any>(initAutoUpdate);

    return (
        <FeatureFlag flagName="cloudim">
            <CloudIMLegal />
            <PageWithHeader
                name={CloudIMPage.name}
                title={STRINGS.cloudim.pageTitle}
                showGlobalFilters={false}
                className="overflow-hidden"
                addPadding={false}
                showTimeBar={false}
                rightAlignedControls={
                    <AutoUpdateControl
                        autoUpdate={autoUpdate}
                        timeType={TimeType.DURATION}
                        showPlayPauseButton={true}
                        onAutoUpdateChanged={(autoUpdate) => {
                            setAutoUpdate(autoUpdate);
                        }}
                        showRefreshButton={!autoUpdate.enabled}
                        className="d-none d-md-block"
                    />
                }
                helpInfo={HELP.theaterView}
            >
                <AutoUpdateContext.Provider value={autoUpdate}>
                    <CloudIMGeoMapView />
                </AutoUpdateContext.Provider>
            </PageWithHeader>
        </FeatureFlag>
    );
};

export default CloudIMPage;
