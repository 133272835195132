/** This module contains the component for displaying a blade with the runbook output.
 *  @module
 */
import React from "react";
import { STRINGS } from "app-strings";
import { IconTitle } from "components/common/icon-title/IconTitle";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer";
import { PRIORITY, SIZE } from "components/enums";
import { PARAM_NAME, INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";
//import { TriggerListView } from "components/hyperion/views/triggers-list/TriggerListView";
import { Card, DetailsPanel } from "components/reporting";
import { SDWAN_ICONS } from "components/sdwan/enums";
import { getURLPath } from "config";
import { IncidentImpactSummaryView } from "pages/incident-details/views/impact-summary/IncidentImpactSummaryView";
import { PriorityLEDFormatter } from "reporting-infrastructure/utils/formatters/priority-led-formatter/PriorityLEDFormatter";
import { getURL, useQueryParams } from "utils/hooks/useQueryParams";
import { RunbookView } from "pages/riverbed-advisor/views/runbook-view/RunbookView";
import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder";
import { UsersCard } from "pages/incident-details/views/impact-summary/cards/UsersCard";
import { SitesCard } from "pages/incident-details/views/impact-summary/cards/SitesCard";
import { ApplicationsCard } from "pages/incident-details/views/impact-summary/cards/ApplicationsCard";
import { EntryType, PriorityReasonsView } from "pages/incident-details/views/priority-reason/PriorityReasonsView";
import { RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { ViewCollection } from "components/common/layout/view-collection/ViewCollection";
import { showTitleIcons } from "utils/runbooks/RunbookUtils";
import "./RunbookBriefBlade.scss"

/** This interface defines the properties passed into the runbook brief blade React component.*/
interface RunbookBriefBladeProps {
    /** a string with id of the incident. */
    incidentId?: string;
    /** a string with id of the runbook. */
    runbookId?: string;
    /** the runbook output. */
    runbookOutput?: RunbookOutput;
    /** a string with description of the runbook output (not the config). */
    description?: string;
    /** the list of impacted users stored in the runbook output. */
    impactedUsers?: Array<string>;
    /** the list of impacted locations stored in the runbook output. */
    impactedLocations?: Array<string>;
    /** the list of impacted applications stored in the runbook output. */
    impactedApplications?: Array<string>;
    /** the handler for the blade closed event. */
    onBladeClosed?: () => void;
}

/** Renders the runbook brief blade view.
 *  @param props the properties passed into the component.
 *  @returns JSX with the runbook brief blade view component.*/
export function RunbookBriefBlade (props: RunbookBriefBladeProps): JSX.Element {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.runbookId, PARAM_NAME.embed, PARAM_NAME.isRunbookOnDemand] });
    const isRunbookOnDemand = !!params[PARAM_NAME.isRunbookOnDemand];

    return <DetailsPanel floating size={SIZE.xl}>
        <BladeContainer className="runbook-brief-blade-container"
            title={<div className="d-inline-flex align-items-center">
                <PriorityLEDFormatter priority={props.runbookOutput?.priority || PRIORITY.UNKNOWN} showStatusAsBackground/>
                <span className="ml-2">{props.description}</span>
            </div>}
            onCloseClicked={() => {
                if (props.onBladeClosed) {
                    props.onBladeClosed();
                }
            }}
            showDetailsLink={false}
            detailsLink={getURL(
                getURLPath("incident"), 
                { [PARAM_NAME.incidentId]: props.incidentId}, 
                { replaceQueryParams: true }
            )}
            detailsLinkLabel={STRINGS.incidents.moreMenu.view}
        >
            {false && <IncidentImpactSummaryView key={"incident-impact-" + props.incidentId} showTitleIcons={showTitleIcons} incidentId={props.incidentId} className="mt-2"/>}
            <div className="d-flex flex-wrap" >
                <div className={"incident-impact-summary"}>
                    <IconTitle icon={showTitleIcons ? SDWAN_ICONS.ALERT : undefined} title={STRINGS.incidents.impactSummaryView.title} size={SIZE.m} className="mb-2 font-weight-500"/>
                    <CardsHolder className="pb-3 w-max-12">
                        <UsersCard count={props.impactedUsers?.length || 0} data={props?.impactedUsers || []} showFooter={false} />
                        <SitesCard count={props.impactedLocations?.length || 0} data={props?.impactedLocations || []} showFooter={false} />
                        <ApplicationsCard count={props.impactedApplications?.length || 0} data={props?.impactedApplications || []} showFooter={false} />
                    </CardsHolder>
                </div>
                {INCIDENT_DETAILS_STYLE !== "table" && <PriorityReasonsView 
                    type={isRunbookOnDemand ? EntryType.INSIGHT : EntryType.PRIORITY}
                    showTitleIcons={showTitleIcons} runbookOutput={props.runbookOutput} showFooter={false} 
                />}
            </div>
            {INCIDENT_DETAILS_STYLE !== "table" && <div className="mb-4">
                <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} 
                    title={STRINGS.incidents.runbookTitle} size={SIZE.m} className="mb-2 font-weight-500" 
                />
                {INCIDENT_DETAILS_STYLE !== "noTableOneCardForEachWidget" && <Card>
                    <ViewCollection activeView="runbook">
                        <div key="runbook" className="runbook-output-tab">
                            <RunbookView 
                                incidentId={props.incidentId}
                                runbookId={props.runbookId}
                                //onRunbookTemplateAvailable={onRunbookTemplateAvailable}
                                //onRunbookReceived={onRunbookOutputAvailable}
                                className="px-4 pb-4 runbook-output-tab-runbook-bg"
                            />
                        </div>
                    </ViewCollection>
                </Card>}
                {INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" &&
                    <ViewCollection activeView="runbook">
                        <div key="runbook" className="runbook-output-tab">
                            <RunbookView 
                                incidentId={props.incidentId}
                                runbookId={props.runbookId}
                                //onRunbookTemplateAvailable={onRunbookTemplateAvailable}
                                //onRunbookReceived={onRunbookOutputAvailable}
                                className="pb-4 runbook-output-tab-runbook-bg"
                            />
                        </div>
                    </ViewCollection>
                }
            </div>}
            {INCIDENT_DETAILS_STYLE === "table" && <>
                <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} title={STRINGS.incidents.incident_sources} size={SIZE.m} className="font-weight-bold"/>
                {/*false && <TriggerListView 
                    filters={{ [FILTER_NAME.incidentId]: props.incidentId }} expandable 
                    autoExpandFirstTrigger={false} enableRerunRunbook={false}
                />*/}
                <RunbookView 
                    incidentId={props.incidentId}
                    runbookId={props.runbookId}
                    //onRunbookTemplateAvailable={onRunbookTemplateAvailable}
                    //onRunbookReceived={onRunbookOutputAvailable}
                    className="px-4 pb-4 runbook-output-tab-runbook-bg"
                />
            </>}
        </BladeContainer>
    </DetailsPanel>
}
