import React from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { loader } from 'graphql.macro';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import {
	Button,
	Callout,
	Icon,
	Intent,
	Label,
	Switch,
} from '@blueprintjs/core';
import {
	ErrorToaster,
	IconNames,
	SuccessToaster,
} from '@tir-ui/react-components';
import { DATASOURCE_STATUS } from './DataSourceStatus';
import { openConfirm, openModal } from 'components/common/modal';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';

import { STRINGS } from 'app-strings';

interface SetAternityDataSourcesInput {
	input: {
		id?: string;
		metricStreamingEnabled: boolean;
		queriesEnabled: boolean;
		hostname: string;
		authentication: {
			username?: string;
		}
		account?: {
			id?: number;
			name?: string;
		};
	};
}

export default function AtternitySaaSView() {
	const apolloClient = useApolloClient();

	const { loading, data, error } = useQuery(loader('./../queries/aternity-datasource.graphql'), {
		pollInterval: 1 * 60 * 1000,
	});
	const dataSource = data?.aternityDataSource || {};

	const [setAternityDataSource] = useMutation<any, SetAternityDataSourcesInput>(
		loader('./../queries/set-aternity-datasource-mutation.graphql'),
		{
			onCompleted: (data) => {
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.atternityConfig.queryResponse
							.success,
				});
			},
			onError: (err) => {
				ErrorToaster({
					message:
						STRINGS.DATA_SOURCES.atternityConfig.queryResponse
							.error,
				});
				console.error(err?.message);
			},
		}
	);

	const [resetAternityDataSource] = useMutation<any, any>(
		loader('./../queries/reset-aternity-datasource-mutation.graphql'),
		{
			onCompleted: (data) => {
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.atternityConfig.queryResponse
							.success,
				});
			},
			onError: (err) => {
				ErrorToaster({
					message:
						STRINGS.DATA_SOURCES.atternityConfig.queryResponse
							.error,
				});
				console.error(err?.message);
			},
		}
	);

	const openSetConfigModal = (dataSource?) => {
		openModal('edgeConfigModal', dataSource);
	}

	return (
		<DataLoadFacade
			loading={loading}
			data={data}
			error={error}
			key="categories"
			showContentsWhenLoading={true}
			className={'basic'}
		>
			<div className="atternity-config bg-white my-3 p-3 h-min-5">
				{!!dataSource.authentication && !dataSource.authentication.username ? (
					<div className="row">
						<Label className="mr-3" style={{ lineHeight: '2' }}>
							{
								STRINGS.DATA_SOURCES.atternityConfig.labels
									.noConfig
							}
						</Label>
						<div className="col-1">
							<Button
								intent="primary"
								onClick={() => openSetConfigModal()}
							>
								{
									STRINGS.DATA_SOURCES.atternityConfig.ctas
										.noConfig
								}
							</Button>
						</div>
					</div>
				) : (
					<div className="container-fluid">
						<div className="row">
							<Label className="mr-3">
								{
									STRINGS.DATA_SOURCES.atternityConfig.labels
										.hostname
								}
							</Label>
							<span className="col-auto" data-testid="hostname">
								{dataSource.hostname}{' '}
								{!!dataSource.authentication && !!dataSource.authentication.username && (
									<span>({dataSource.authentication.username} / *****)</span>
								)}
							</span>
							<span className="col-1">
								<Button
									intent="primary"
									data-testid="hostname-cta"
									onClick={() =>
										openSetConfigModal(dataSource)
									}
								>
									{
										STRINGS.DATA_SOURCES.atternityConfig
											.ctas.hostname
									}
								</Button>
							</span>
						</div>
						{!!dataSource.account && (
							<div className="row">
								<Label className="mr-3">
									{
										STRINGS.DATA_SOURCES.atternityConfig
											.labels.username
									}
								</Label>
								<span
									className="col-auto"
									data-testid="username"
								>
									{dataSource?.account?.name} (ID{' '}
									{dataSource?.account?.id})
								</span>
							</div>
						)}
						<div className="row">
							<Label className="mr-3">
								{
									STRINGS.DATA_SOURCES.atternityConfig.labels
										.enableDataSource
								}
							</Label>
							<Switch
								className="d-inline ml-1"
								labelElement={dataSource.queriesEnabled ? 'On' : 'Off'}
								checked={dataSource.queriesEnabled}
								onChange={async (e) => {
									await setAternityDataSource({
										variables: {
											input: {
												metricStreamingEnabled: dataSource.metricStreamingEnabled,
												queriesEnabled: !dataSource.queriesEnabled,
												hostname: dataSource.hostname,
												authentication: { username:  dataSource.authentication?.username || ''},
											},
										},
									});
									await apolloClient.refetchQueries({
										include: 'active',
									});
								}}
							/>
						</div>
						<div className="row">
							<Label className="mr-3">
								{
									STRINGS.DATA_SOURCES.atternityConfig.labels
										.enableDataIngest
								}
							</Label>
							<Switch
								className="d-inline ml-1"
								labelElement={dataSource.metricStreamingEnabled ? 'On' : 'Off'}
								checked={dataSource.metricStreamingEnabled}
								onChange={async (e) => {
									await setAternityDataSource({
										variables: {
											input: {
												metricStreamingEnabled: !dataSource.metricStreamingEnabled,
												queriesEnabled: dataSource.queriesEnabled,
												hostname: dataSource.hostname,
												authentication: { username:  dataSource.authentication?.username || ''},
											},
										},
									});
									await apolloClient.refetchQueries({
										include: 'active',
									});
								}}
							/>
						</div>
						<div className="row">
							<Label className="mr-3">
								{
									STRINGS.DATA_SOURCES.atternityConfig.labels
										.status
								}
							</Label>
							{dataSource?.info?.status ===
								DATASOURCE_STATUS.OK.toUpperCase() ? (
								<div className="col-auto">
									<Icon
										intent="success"
										icon={IconNames.TICK_CIRCLE}
									/>
								</div>
							) : dataSource?.info?.status ===
							  DATASOURCE_STATUS.DISABLED.toUpperCase() ? (
								<div className="col-auto">
									<Icon
										intent={Intent.DANGER}
										icon={IconNames.DISABLE}
									/>
								</div>
							) : (
								<div className="col-8 d-inline">
									<Icon
										intent="danger"
										icon={IconNames.ERROR}
									/>
									{dataSource?.info?.status ===
										'DISABLED' && (
										<span className="ml-2 display-9">
											{
												STRINGS.DATA_SOURCES
													.atternityConfig.status
													.disabled
											}
										</span>
									)}
									{dataSource?.info?.status === 'FAILED' &&
										dataSource?.info?.error?.details.map(
											(error, i) => (
												<Callout
													key={i}
													intent={Intent.WARNING}
													icon={null}
													className="my-3"
												>
													<div
														dangerouslySetInnerHTML={{
															__html: DOMPurify.sanitize(
																STRINGS.formatString(
																	STRINGS
																		.runbookOutput
																		.errorsAndWarnings[
																		!error.code ||
																		!Object.keys(
																			STRINGS
																				.runbookOutput
																				.errorsAndWarnings
																		).includes(
																			error.code
																		)
																			? 'GENERAL_ERROR'
																			: error?.code
																	],
																	Object.fromEntries(
																		dataSource?.info?.error?.innerError?.properties.map(
																			(
																				x
																			) => [
																				x.key,
																				x.value,
																			]
																		)
																	)
																)
															),
														}}
													/>
												</Callout>
											)
										)}
								</div>
							)}
						</div>
						<div className="row">
							<Label className="mr-3">
								{
									STRINGS.DATA_SOURCES.atternityConfig.labels
										.lastReceived
								}
							</Label>
							<div className="col-auto">
								<div>
									{moment
										.utc(dataSource.lastIngestedEndTime * 1000)
										.local()
										.format('lll')}
								</div>
								<div className="display-9">
									(
									{moment
										.utc(dataSource.lastIngestedEndTime * 1000)
										.local()
										.fromNow()}
									)
								</div>
							</div>
						</div>
					</div>
				)}
				{!!dataSource?.authentication && dataSource?.authentication.username && (
					<Button
                        outlined
						className="mt-5"
						icon={IconNames.TRASH}
						onClick={() =>
							openConfirm({
								message:
									STRINGS.DATA_SOURCES.atternityConfig
										.confirmModal.label,
								onConfirm: async () => {
									try {
										await resetAternityDataSource();
										await apolloClient.refetchQueries({
											include: 'active',
										});
									} catch (error) {
										console.log(error);
									}
								},
								intent: Intent.PRIMARY,
								icon: IconNames.TRASH,
							})
						}
					>
						{STRINGS.DATA_SOURCES.atternityConfig.ctas.reset}
					</Button>
				)}
			</div>
		</DataLoadFacade>
	);
}
