/** This module contains an editor for the graph properties.
 *  @module
 */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import DOMPurify from 'dompurify';
import {
	Button,
	Checkbox,
	HTMLSelect,
	InputGroup,
	NumericInput,
	TextArea,
} from '@blueprintjs/core';
import {
	IconNames,
	LoadingOverlay,
	useStateSafePromise,
} from '@tir-ui/react-components';
import {
	NodeLibrary,
	NodeLibraryNode,
} from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { GraphDef, NodeDef, RunbookInfo, Variant, VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS, VARIANTS_WITH_RUNTIME_BUILTIN_VARS } from '../../types/GraphTypes';
import { DataOceanUtils } from '../data-ocean/DataOceanUtils';
import { useVariables } from 'utils/hooks/useVariables';
import { TextEditor } from 'components/common/texteditor/TextEditor';
import { KeyOption, TableNodeUtils } from '../table/TableNodeUtils';
import { getNodeFromGraphDef, subflowOutputNodes } from 'utils/runbooks/RunbookUtils';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, PrimitiveVariableType, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { MultiSelectInput } from 'components/common/multiselect/MultiSelectInput';
import { RunbookContextSummary } from '../RunbookContextSummary';
import { SHOW_CONTEXT } from 'components/enums/QueryParams';
import { DataOceanMetadata } from '../data-ocean/DataOceanMetadata.type';
import { UniversalNode } from '../../UniversalNode';
import { STRINGS } from 'app-strings';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { useUserPreferences } from 'utils/hooks';
import { RunbookNode } from 'utils/services/RunbookApiService';

/** This interface defines the properties passed into the node editor React component.*/
export interface SimpleNodeEditorProps {
	/** the selected node. */
	selectedNode?: UniversalNode;
	/** the libaray node that describes the editable properites. */
	libraryNode?: NodeLibraryNode;
	/** the array of global nodes. */
	globalNodes: Array<NodeDef>;
	/** Currently active runbook info */
	activeRunbook: RunbookInfo;
	/** information about all nodes and their parent child relationships*/
	graphDef: GraphDef;
	/** a reference to the node library. */
	nodeLibrary: NodeLibrary;
    /** the variant of runbook that is currently being edited. */
    variant: Variant;
    /** the array of RunbookNode objects with the list of subflows. */
    subflows: RunbookNode[];
	/** a reference to the setState function in NodeEditorPanel */
	handleChange?: () => void;
	/** runtime variables changes handler */
    onRuntimeOrSubflowVariableEdited?: (updatedVariablesList) => void;
	/* a function which changes the state of the save and close button based on the existence of duplicate decision node output labels */
	saveAndCloseBtnDisable?: (boolean) => void;
}

export const SimpleNodeEditor = React.forwardRef(
	(props: SimpleNodeEditorProps, ref): JSX.Element => {
		const [properties, setProperties] = useState<any>({});
		const [envProperties, setEnvProperties] = useState<any>({});
		const [objMetricMetaData, setObjMetricMetaData] =
			useState<DataOceanMetadata>();
		const [editRawJson, setEditRawJson] = useState<boolean>(false);
		const [getVariables] = useVariables({
			runbookInfo: props.activeRunbook,
		});
		const { handleChange } = props;

		const [executeSafely] = useStateSafePromise();
		const [loading, setLoading] = useState(true);

        const customProperties = useContext(CustomPropertyContext);
		const userPreferences = useUserPreferences({listenOnlyTo: {
			subflows: {isMultipleOutputsEnabled: true},
		}});
		
		const fetchData = useCallback(() => {
			return executeSafely(DataOceanUtils.init()).then(
				(response: any) => {
					setObjMetricMetaData(response);
					setLoading(false);
				},
				(error) => {
					console.error(error);
				}
			);
		}, [executeSafely]);

		useEffect(() => {
			// Fetch Meta data on load.
			fetchData();
		}, [fetchData]);

		useEffect(() => {
			const properties = {};
			const envProperties = {};
			if (props.libraryNode) {
				if (props.libraryNode.properties) {
					for (const prop of props.libraryNode.properties) {
						const propLibraryValue =
							prop.default !== null && prop.default !== undefined
								? prop.default
								: undefined;
						let propValue = props?.selectedNode?.getProperty(
							prop.name
						);
						propValue =
							propValue !== null && propValue !== undefined
								? propValue
								: propLibraryValue;
						if (prop.type === 'json') {
							propValue = JSON.stringify(propValue);
						}
						properties[prop.name] = propValue;
					}
					setProperties(properties);
				}
				if (props.libraryNode.env) {
					for (const envSetting of props.libraryNode.env) {
						const propLibraryValue =
							envSetting.value !== null &&
							envSetting.value !== undefined
								? envSetting.value
								: undefined;
						let propValue = props?.selectedNode?.getEnv(
							envSetting.name
						);
						propValue =
							propValue !== null && propValue !== undefined
								? propValue
								: propLibraryValue;
						envProperties[envSetting.name] = propValue;
					}
					setEnvProperties(envProperties);
				}
			}
		}, [props.libraryNode, props.selectedNode]);

		/* set an updateNode function on the reference of this component which will be invoked by the parent
		 * component: NodeEditorPanel
		 */
		// @ts-ignore
		ref.current = {
			updateNode: () => {
				if (props.libraryNode && props.libraryNode.properties) {
					for (const prop of props.libraryNode.properties) {
						if (props.selectedNode) {
							if (properties.hasOwnProperty(prop.name)) {
								let propValue = properties[prop.name];
								if (prop.type === 'json') {
									try {
										propValue = JSON.parse(propValue);
									} catch (error) {
										propValue = {};
									}
								} else if (prop.type === 'texteditor') {
									// RTE adds the line brake symbol at the end even if value is empty
									propValue =
										propValue &&
										propValue.match(/\S/g)[0] !== '​'
											? DOMPurify.sanitize(propValue)
											: null;
								} else if (prop.type === 'hidden') {
									// Do not modifiy the value of a hidden property.  There is no reason to reset the
									// value, if we do nothing it will remain set to whatever value it had before the node
									// was edited.
									continue;
								}
								props.selectedNode.setProperty(
									prop.name,
									propValue
								);
							} else {
								// Do we need to do this, Ajay was doing this
								//this.props.selectedNode.removeProperty(prop.name);
							}
						}
					}
				}
			},
			validate: () => {
				const errorMessages = new Array<string>();
				if (props.libraryNode && props.libraryNode.properties) {
					for (const prop of props.libraryNode.properties) {
						if (prop.type === 'metric' && !properties[prop.name]) {
							errorMessages.push(
								STRINGS.runbookEditor.errors.noMetricError
							);
						}
					}
				}
				return errorMessages;
			},
		};

		const getFormElements = () => {
			const formElements: Array<JSX.Element> = [];

			if (objMetricMetaData) {
				if (editRawJson) {
					// For development purposes we have choosen to allow the user to edit the raw JSON.  Add a text area
					// to the form that exposes the raw JSON.  This should not be used for a production node, but is very
					// useful for early integrations with the back-end.
					formElements.push(
						<tr key={'textarea_key'}>
							<td className="p-1" colSpan={2}>
								<textarea
									autoFocus
									//ref={outputsExpressionElement}
									defaultValue={JSON.stringify(
										properties,
										undefined,
										4
									)}
									style={{
										width: '100%',
										height: '600px',
										fontFamily: 'monospace',
										fontSize: 'small',
										borderColor: '#999',
									}}
									className="bg-white text-black"
									// onChange={handleOutputsListChange}
									onBlur={(e) => {
										try {
											const text = e.currentTarget.value;
											setProperties(JSON.parse(text));
										} catch (error) {
											console.error(error);
										}
									}}
								/>
							</td>
						</tr>
					);
				} else {
					if (props.libraryNode?.uiAttrs?.allowRawJsonEdit) {
						formElements.push(
							<tr key={'button_key'}>
								<td className="p-1" colSpan={2}>
									<div className="text-right mb-2">
										<Button
											text={
												STRINGS.runbookEditor.nodeEditor
													.editRawJson
											}
											className="edit-raw-json-btn"
											icon={IconNames.EDIT}
											onClick={() => setEditRawJson(true)}
											minimal
										/>
									</div>
								</td>
							</tr>
						);
					}

					if (props.libraryNode && props.libraryNode.properties) {
						const isIndexHidden = subflowOutputNodes.includes(props.libraryNode.type) && !userPreferences.subflows?.isMultipleOutputsEnabled;

                        const variableList = getVariables(RUNTIME_SCOPE, VARIANTS_WITH_RUNTIME_BUILTIN_VARS.includes(props.variant)).primitiveVariables.concat(
                            VARIANTS_WITH_INCIDENT_VARS.includes(props.variant) ? getVariables(INCIDENT_SCOPE, true).primitiveVariables : []
                        ).concat(
                            VARIANTS_WITH_GLOBAL_VARS.includes(props.variant) ? getVariables(GLOBAL_SCOPE, true).primitiveVariables : []
                        ).filter(variable => ![PrimitiveVariableType.JSON].includes(variable.type))

                        for (const prop of props.libraryNode.properties) {
							const propLibraryValue =
								prop.default !== null &&
								prop.default !== undefined
									? prop.default
									: undefined;
							let propValue = props?.selectedNode?.getProperty(
								prop.name
							);
							propValue =
								propValue !== null && propValue !== undefined
									? propValue
									: propLibraryValue;

							// Hide multiple subflow outputs property
							if (isIndexHidden && prop.name === 'index') {
								continue;
							}

							if (propValue !== null && propValue !== undefined) {
								const propLabel = STRINGS.runbookEditor
									.nodeLibrary.propertyLabels[prop.label]
									? STRINGS.runbookEditor.nodeLibrary
											.propertyLabels[prop.label]
									: prop.label;
								switch (prop.type) {
									case 'boolean':
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1" colSpan={2}>
													<Checkbox
                                                        className='mb-0'
														type="checkbox"
														id={prop.name}
														name={prop.name}
														label={propLabel}
														defaultChecked={
															properties[
																prop.name
															]
														}
														onChange={(
															event: any
														) =>
															(properties[
																prop.name
															] =
																event.target.checked)
														}
													/>
												</td>
											</tr>
										);
										break;
									case 'text':
									case 'password':
									case 'email':
									case 'tel':
									case 'url':
									case 'json':
										if (prop.type === 'json') {
											propValue =
												JSON.stringify(propValue);
										}
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1" colSpan={2}>
                                                    <label className="mb-0 pb-1">
                                                        {propLabel}
                                                    </label>
													<InputGroup
														type={prop.type}
														id={prop.name}
														name={prop.name}
														className="editor-input-standard"
														defaultValue={
															properties[
																prop.name
															]
														}
														onChange={(event) =>
															(properties[
																prop.name
															] =
																event.target.value)
														}
													/>
												</td>
											</tr>
										);
										break;
                                    case 'integer':
                                    case 'number':
                                    case 'float':
                                        formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1" colSpan={2}>
                                                    <label className='mb-0 pb-1'>{propLabel}</label>
													<NumericInput
														type={prop.type}
														id={prop.name}
														name={prop.name}
														className="editor-input-standard"
														defaultValue={properties[prop.name]}
														onValueChange={(valueAsNumber: number, valueAsString: string) => {
															(properties[prop.name] = valueAsNumber)
															if (handleChange) {    
																handleChange();
															}	
														}}
													/>
												</td>
											</tr>
										);
                                        break;
                                    case 'textarea':
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1" colSpan={2}>
                                                    <label className='mb-0 pb-1'>{propLabel}:</label>
													<TextArea
														id={prop.name}
														name={prop.name}
														defaultValue={
															properties[
																prop.name
															]
														}
														onChange={(event) =>
															(properties[
																prop.name
															] =
																event.target.value)
														}
														fill={true}
														className="editor-input-textarea"
														style={{
															height: '200px',
														}}
													/>
												</td>
											</tr>
										);
										break;
									case 'texteditor':
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1" colSpan={2}>
                                                    <label className='mb-0 pb-1'>{propLabel}</label><br />
													<TextEditor
														initialValue={
															properties[
																prop.name
															]
														}
														onChange={(value) =>
															(properties[
																prop.name
															] = value)
														}
														placeholder={
															STRINGS.TextEditor
																.notesPlaceholder
														}
														includeVariables={true}
														variables={variableList}
													/>
												</td>
											</tr>
										);
										break;
									case 'select': {
										const options: Array<{
											label: string;
											value: string;
										}> = [];
										if (prop.options) {
											for (const option of prop.options) {
												const optionLabel = STRINGS
													.runbookEditor.nodeLibrary
													.propertyLabels[
													option.label
												]
													? STRINGS.runbookEditor
															.nodeLibrary
															.propertyLabels[
															option.label
													  ]
													: option.label;
												options.push({
													label: optionLabel,
													value: option.value,
												});
											}
										}
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1">
                                                    <label>{propLabel} </label>
													<HTMLSelect
														id={prop.name}
														name={prop.name}
														defaultValue={
															properties[
																prop.name
															]
														}
														onChange={(event) =>
															(properties[
																prop.name
															] =
																event.target.value)
														}
														options={options}
														className="editor-input-standard ml-3 mt-1"
													/>
												</td>
											</tr>
										);
										break;
									}
									case 'metric':
									case 'optionalMetric': {
										const metrics: KeyOption[] =
											TableNodeUtils.getMetrics(
												objMetricMetaData,
                                                customProperties,
												getNodeFromGraphDef(
													props.selectedNode?.node
														?.id || '',
													props.graphDef
												),
												props.graphDef
											);
										const options = [
											{
												value: '',
												label: STRINGS.runbookEditor
													.nodeEditor
													.selectMetricOption,
											} as KeyOption,
										].concat(metrics);
										if (prop.type === 'optionalMetric') {
											options.push({
												value: 'none',
												label: STRINGS.runbookEditor
													.nodeEditor.noMetricOption,
											} as KeyOption);
										}
										const hasValue =
											options.find((option) => {
												return (
													option.value ===
													properties[prop.name]
												);
											}) !== undefined;
										if (!hasValue) {
											properties[prop.name] = '';
										}
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1">
                                                    <label>{propLabel}</label>
													<HTMLSelect
														id={prop.name}
														name={prop.name}
														defaultValue={
															properties[
																prop.name
															]
														}
														onChange={(event) =>
															(properties[
																prop.name
															] =
																event.target
																	.value ===
																	'' ||
																event.target
																	.value ===
																	'none'
																	? undefined
																	: event
																			.target
																			.value)
														}
														options={options}
														className="editor-input-standard ml-3 mt-1"
													/>
												</td>
											</tr>
										);
										break;
									}
									case 'metrics': {
										const metrics =
											TableNodeUtils.getMetrics(
												objMetricMetaData,
                                                customProperties,
												getNodeFromGraphDef(
													props.selectedNode?.node
														?.id || '',
													props.graphDef
												),
												props.graphDef
											);

										const selectedMetrics: Array<any> = [];

										if (propValue?.length) {
											for (const metric of metrics) {
												if (
													properties[
														prop.name
													].includes(metric.value)
												) {
													selectedMetrics.push({
														display: metric.label,
														value: metric.value,
													});
												}
											}
										}

										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1" colSpan={2}>
                                                    <label className='mb-0 pb-1'>{propLabel}</label>
													<MultiSelectInput
														sortable
														disabled={false}
														items={metrics.map(
															(metric) => {
																return {
																	display:
																		metric.label,
																	value: metric.value,
																};
															}
														)}
														selectedItems={
															selectedMetrics
														}
														onChange={(
															updatedValues
														) => {
															const newMetrics =
																updatedValues.map(
																	(metric) =>
																		metric.value
																);
															properties[
																prop.name
															] = newMetrics;
														}}
														placeholder={
															STRINGS
																.runbookEditor
																.nodeLibrary
																.nodes.table
																.tableColumnsPlaceholder
														}
													/>
												</td>
											</tr>
										);
										break;
									}
									case 'group': {
										// This one is special it requires the list of ui_group configuration nodes.
										const options: Array<{
											label: string;
											value: string;
										}> = [];
										for (const globalNode of props.globalNodes) {
											if (
												globalNode.type === 'ui_group'
											) {
												let tab = null;
												if (globalNode.properties) {
													for (const property of globalNode.properties) {
														if (
															property.key ===
															'tab'
														) {
															tab =
																property.value;
															break;
														}
													}
												}
												for (const tabGlobalNode of props.globalNodes) {
													if (
														tabGlobalNode.type ===
															'ui_tab' &&
														tab &&
														tab === tabGlobalNode.id
													) {
														options.push({
															value: globalNode.id,
															label:
																tabGlobalNode.name +
																' - ' +
																globalNode.name,
														});
														break;
													}
												}
											}
										}
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1">
													<label>{propLabel}:</label>
												</td>
												<td className="p-1">
													<HTMLSelect
														id={prop.name}
														name={prop.name}
														defaultValue={propValue}
														options={options}
														className="editor-input-standard"
													/>
												</td>
											</tr>
										);
										break;
									}
									case 'hidden': {
										// Hidden fields cannot be edited, just pass their properties back when the form is applied.
										break;
									}
									default:
										formElements.push(
											<tr key={prop.type + prop.name}>
												<td className="p-1">
													{propLabel || prop.name}:
												</td>
												<td className="p-1">
													{typeof propValue ===
													'string'
														? propValue.toString
														: JSON.stringify(
																propValue,
																undefined,
																4
														  )}
												</td>
											</tr>
										);
								}
							}
						}
					}
					if (props.libraryNode && props.libraryNode.env) {
						for (const envSetting of props.libraryNode.env) {
							const propLibraryValue =
								envSetting.value !== null &&
								envSetting.value !== undefined
									? envSetting.value
									: undefined;
							let propValue = props?.selectedNode?.getEnv(
								envSetting.name
							);
							propValue =
								propValue !== null && propValue !== undefined
									? propValue
									: propLibraryValue;
							if (propValue !== null && propValue !== undefined) {
								switch (envSetting.type) {
									case 'str':
									case 'num':
									case 'json':
									case 'bin':
									case 'env':
										formElements.push(
											<tr
												key={
													envSetting.type +
													envSetting.name
												}
											>

												<td className="p-1" colSpan={2}>
                                                    <label className='mb-0 pb-1'>
														{envSetting.name}
													</label>
													<InputGroup
														type="text"
														id={envSetting.name}
														name={envSetting.name}
														key={
															envSetting.type +
															envSetting.name
														}
														className="editor-input-standard"
														defaultValue={
															envProperties[
																envSetting.name
															]
														}
														onChange={(event) =>
															(properties[
																envSetting.name
															] =
																event.target.value)
														}
													/>
												</td>
											</tr>
										);
										break;
									case 'bool':
										const options: Array<{
											label: string;
											value: string;
										}> = [];
										options.push({
											label: 'true',
											value: 'true',
										});
										options.push({
											label: 'false',
											value: 'false',
										});
										formElements.push(
											<tr
												key={
													envSetting.type +
													envSetting.name
												}
											>
												<td className="p-1">
													<label>
														{envSetting.name}:
													</label>
												</td>
												<td className="p-1">
													<HTMLSelect
														id={envSetting.name}
														name={envSetting.name}
														className="editor-input-standard"
														options={options}
														defaultValue={
															envProperties[
																envSetting.name
															]
														}
														onChange={(event) =>
															(envProperties[
																envSetting.name
															] =
																event.target.value)
														}
													></HTMLSelect>
												</td>
											</tr>
										);
										break;
									default:
										formElements.push(
											<tr
												key={
													envSetting.type +
													envSetting.name
												}
											>
												<td className="p-1">
													{envSetting.name}:
												</td>
												<td className="p-1">
													{propValue.toString()}
												</td>
											</tr>
										);
								}
							}
						}
					}
				}
			}

			if (SHOW_CONTEXT) {
				formElements.push(
					<RunbookContextSummary
						key="runbook-context"
						currentProperties={properties}
						node={
							props.graphDef.nodes.find(
								(node) =>
									node.id === props.selectedNode?.getId()
							)!
						}
						graphDef={props.graphDef}
                        showOutputExample={true} showInputExample={true}
					/>
				);
			}

			return formElements;
		};

		if (loading) {
			return (
				<tr>
					<td>
						<LoadingOverlay visible={true} />
					</td>
				</tr>
			);
		} else {
			return <>{getFormElements()}</>;
		}
	}
);
