export enum NoteType{
    STANDARD = "STANDARD",
    PINNED   = "PINNED"
};

export enum ACTIVITY_TYPE {
    INCIDENT_CREATED                 = "INCIDENT_CREATED",
    INCIDENT_NOTE_ADDED              = "INCIDENT_NOTE_ADDED",
    INCIDENT_NOTE_UPDATED            = "INCIDENT_NOTE_UPDATED",
    INCIDENT_NOTE_DELETED            = "INCIDENT_NOTE_DELETED",
    INCIDENT_PRIORITY_CHANGED        = "INCIDENT_PRIORITY_CHANGED",
    INCIDENT_STATUS_CHANGED          = "INCIDENT_STATUS_CHANGED",
    RUNBOOK_STATUS_CHANGED           = "RUNBOOK_STATUS_CHANGED",
    LIFECYCLE_NOTE_ADDED             = "LIFECYCLE_NOTE_ADDED",
    LIFECYCLE_RUNBOOK_STATUS_CHANGED = "LIFECYCLE_RUNBOOK_STATUS_CHANGED",
    DEBUG                            = "DEBUG"
}