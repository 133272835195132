import React, { ReactNode, useEffect, useState } from "react";
import { Icon, IconName, Button, Menu, MenuItem, Position } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";
import { EventNames, trackEvent } from "utils/appinsights";
import { BasicDialog, updateDialogState, DialogState } from "../basic-dialog/BasicDialog";
import { WrapInTooltip } from "../wrap-in-tooltip/WrapInTooltip";
import { Popover2 } from "@blueprintjs/popover2";
import { clearQueryParam, useQueryParams } from "utils/hooks/useQueryParams";
import "./IconVideo.scss";

/** This interface defines the properties passed into the icon title React component.*/
export interface IconTitleProps {
    /** the icon that is to be displayed as video icon. */
    icon?: IconName | ReactNode;
    /** an object with the videoData. */
    videoData: Record<string, string>;
    /** an optional className that can be used to further refine the css for this component. */
    className?: string;
    /** an optional boolean to toggle between Menu and Icon. */
    asList?: boolean;
}

// Function to wrap common content with a component
function WrapperComponent({ condition, wrapper, children }) {
    return condition ? wrapper(children) : children;
}

export function IconVideo({ icon = IconNames.LEARNING, asList = false, ...props }) {
    const { params } = useQueryParams({ listenOnlyTo: ["video"] });

    // Auth services
    const appInsightsContext = useAppInsightsContext();
    const AuthService = AuthServiceProvider.getService();

    // Modal variable & init
    const initDialogState = { showDialog: false, title: '', loading: false, dialogContent: null, dialogFooter: null };
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    // Basic URI to create video paths for all tenants
    const { HELP_URI } = window['runConfig'] ? window['runConfig'] : { HELP_URI: '' };

    // Generic function to open modal and track event
    const openVideoModal = (videoName, videoTitle) => {
        setDialogState({
            showDialog: true,
            title: videoTitle,
            dialogContent:
                <video controls autoPlay width="640">
                    <source src={`${HELP_URI}/IQ/Content/Resources/Videos/${videoName}.mp4`} type="video/mp4" />
                </video>,
            closeable: true
        } as DialogState);

        // report video event metrics to App Insights
        if (appInsightsContext) {
            const properties = {
                name: EventNames.HELP_VIDEO_INVOKED,
                properties: {
                    topic: videoName || "Unknown"
                }
            };
            trackEvent(appInsightsContext, AuthService, properties);
        }
    }

    const videoMenuItems: Array<JSX.Element> = [];
    for (const videoName in props.videoData) {
        videoMenuItems.push(
            <MenuItem
                key={videoName}
                text={props.videoData[videoName]}
                onClick={() => openVideoModal(videoName, props.videoData[videoName])}
            />
        );
    }

    // This will trigger the video modal if there is a video query param in the URL,
    // NOTE: This will not test if the video params are correct, or if the video file exists.
    useEffect(() => {
        if (params.video) {
            const videoParam = params.video;
            if (videoParam && asList) {
                const [videoFileName, videoTitle] = (videoParam).split(":");            
                openVideoModal(JSON.parse(videoFileName), JSON.parse(videoTitle));
            }    
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.video]);

    return <>
        <BasicDialog dialogState={dialogState} className="icon-video-dialog" onClose={() => {
            setDialogState(updateDialogState(dialogState, false, false, []));
            clearQueryParam("video", true);
        }} />
        <WrapperComponent condition={asList} wrapper={children => (
            <Popover2 minimal 
                position={Position.BOTTOM_RIGHT}
                content={<Menu>{videoMenuItems}</Menu>}>
                {children}
            </Popover2>)
        }>
            <WrapInTooltip tooltip={asList ? 'Video Tutorials' : Object.values(props.videoData)[0]} orientation="rhs">
                {!asList && 
                <span className="ribbon" onClick={() => openVideoModal(Object.keys(props.videoData)[0], Object.values(props.videoData)[0])}>
                    <Icon icon={IconNames.LEARNING} />
                </span>
                }
                <Button minimal aria-label={'Video Tutorial'}
                    icon={<Icon icon={icon} />}
                    className={props.className}
                    onClick={() => !asList && openVideoModal(Object.keys(props.videoData)[0], Object.values(props.videoData)[0])}
                />
            </WrapInTooltip>
        </WrapperComponent>
    </>
}
