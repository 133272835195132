export const HttpNodeStatusTemplate = `{%  comment %}
# Enter Liquid template with {{variables}} to specify
# conditions on HTTP status code or HTTP payload body
# for returning:
# - "ready" when the async query has completed and the final
#   response is received.
# - "error" to break out when an invalid response is received.
# - any other text to keep retrying.
#
# Template below is simply an example and should be
# modified based on the expected HTTP response
# returned in the 'data' variable where data.body
# contains the payload and data.statusCode contains the
# HTTP status code.
{% endcomment %}

{% if data.statusCode == 200 and data.body contains "COMPLETED" %}
ready
{% elsif data.statusCode == 200 and data.body contains "INPROGRESS" %}
just-return-something-like-continue-polling-or-anything-else
{% else %}
error
{% endif %}`;