/** This module contains utilities for editing set complex variable nodes and validating set complex variable nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "components/common/graph/types/GraphTypes";
import { NodeUtils } from "utils/runbooks/NodeUtil";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { getProperties } from "utils/runbooks/RunbookUtils";
import { INCIDENT_SCOPE, RUNTIME_SCOPE, SUBFLOW_SCOPE } from "utils/runbooks/VariablesUtils";


/** an enum with all of the valid set complex variable node properties. */
export enum SET_COMPLEX_VARIABLE_NODE_EDIT_PROPS {
    /** the variables property. */
    VARIABLE = "variable",
    /** the debug property */
    DEBUG = 'debug',
}

/** Utility class for set complex variable node,*/
export class SetComplexVariableNodeUtils extends NodeUtils {
    /** the error messages for the set complex variable node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.variablesNode;

    /** Check if set complex variable node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on all the nodes. 
     *  @param variables the map of variables by scope.
     *  @returns  is node valid. */
    static isNodeValid(nodeId: string | undefined | null, graphDef: GraphDef, variables?: VariableContextByScope): boolean {
        if (!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        this.validateNode(nodeId, errors, graphDef, variables)
        return errors.length === 0;
    }

    /** Check if a set complex variable node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes. 
     *  @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables);
        this.validateNodePropertiesFromGraphDef(curNode, getProperties(curNode), errors, curNode?.name, variables);
    }

    /** Validates the property values for a given set complex variable node
     *  @param selectedNode the UniversalNode that wraps the react-flow node.
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @param variables the map of variables by scope. 
     *  @returns an array of strings with all the errors that were found. */
    static validateNodePropertiesFromGraphDef(
        selectedNode: NodeDef | undefined, currentProperties: Record<string, any>, errors: Array<string>, label: string,
        variables?: VariableContextByScope
    ): Array<string> {
        if (!currentProperties?.variable || (currentProperties?.variable && !currentProperties?.variable.name)) {
            errors.push(SetComplexVariableNodeUtils.errMsgs.variableNameMissing);
        }
        if (currentProperties?.variable?.name) {
            const structuredVariables: string[] = NodeUtils.getStructuredVariablesList(variables);
            if (!structuredVariables.includes(currentProperties.variable.name)) {
                if (currentProperties.variable.name.includes(RUNTIME_SCOPE)) {
                    errors.push(SetComplexVariableNodeUtils.errMsgs.incompatibleRuntimeVariable);
                } else if (currentProperties.variable.name.includes(INCIDENT_SCOPE)) {
                    errors.push(SetComplexVariableNodeUtils.errMsgs.incompatibleIncidentVariable);
                } else if (currentProperties.variable.name.includes(SUBFLOW_SCOPE)) {
                    errors.push(SetComplexVariableNodeUtils.errMsgs.incompatibleSubflowVariable);
                }
            }
        }
        return errors;
    }

    /** Validates the property values for a given set complex variable node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        currentProperties: Record<string, any>, errors: Array<string>
    ): Array<string> {
        if (!currentProperties?.variable || (currentProperties?.variable && !currentProperties?.variable.name)) {
            errors.push(SetComplexVariableNodeUtils.errMsgs.variableNameMissing);
        }
        return errors;
    }

}
