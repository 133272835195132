import React from "react";
import { STRINGS } from 'app-strings';
import "./MissingRole.scss";

export const defaultText =
    STRINGS.missingRoles.message;

type UserDoesNotHavePermissionProps = {
    /** paragraph text that tells user call-to-action */
    text?: string;
};

/** Rejection Message for non-read users */
export const MissingRole = ({
    text = defaultText,
}: UserDoesNotHavePermissionProps) => {
    return (
        <section className="MissingRole-section">
            <p>{text}</p>
        </section>
    );
};
