/** This module defines a React component that displays the list of search categories.
 *  @module
 */
import React from "react";
import { Menu, MenuItem } from '@blueprintjs/core';
import { Icon } from "@tir-ui/react-components";

/** this interace defines a search category item. */
export interface CategoryItem {
    /** unique id */
    id: string;
    /** Text that will be displayed on the category button */
    label: String;
    /** Text that will be displayed on the category button `(#count)` format. */
    count: number;
    /** show/hide count info */
    showCount?: boolean
    /** Icon that will be displayed on the category button */
    icon?: string| undefined;
}

/** This interface defines the properties passed into the category selector component. */
export interface CategorySelectorProps {
    /** list of CategoryItems to be displayed */
    categories: Array<CategoryItem>;
    /** current selected category. This item will be highlighted */
    selectedCategory: string;
    /** callback function that will be invoked when on new seleciton */
    onCategorySelection: (e: string) => void;
}

/** Categories selecton component. Displays categories as a list of buttons.
 *  @param props an object with the properties passed to the CategorySelector Component.
 *  @returns JSX SearchCategories component.*/
export function CategorySelector({categories, selectedCategory, onCategorySelection}: CategorySelectorProps) {
    const menuItems = categories.map((item: CategoryItem) => {
        const text = (item.showCount === true) ? `${item.label} (${item.count})` : item.label;
        return <MenuItem
            key={item.id}
            active={item.id === selectedCategory}
            text={text}
            icon={<Icon icon={item.icon}/>}
            onClick={(event) => {
                event.preventDefault();
                onCategorySelection(item.id);
            }}
        />
    })
    return <Menu className="bg-transparent w-100" large={true}>
        {
            menuItems
        }
    </Menu>
}
