import { Checkbox, Radio, RadioGroup } from "@blueprintjs/core";
import React, { useRef, useState } from "react";
import { BLOCK_TYPE_PROPS, ConditionBlockType } from "./ConditionBlock";

export interface ConditionBlockTypePickerProps {
    value?: ConditionBlockType;
    onChange?: (blockType) => void;
    options?: ConditionBlockType[];
}

export function ConditionBlockTypePicker ({
    value,
    onChange,
    options = [ConditionBlockType.AND, ConditionBlockType.OR, ConditionBlockType.NOT_AND, ConditionBlockType.NOT_OR],
}: ConditionBlockTypePickerProps) {
    const [operation, setOperation] = useState(value === ConditionBlockType.OR || value === ConditionBlockType.NOT_OR ? "or" : "and");
    const [negationEnabled, setNegationEnabled] = useState(value === ConditionBlockType.NOT_AND || value === ConditionBlockType.NOT_OR);

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    function fireOnChangeEvent (operation, negation) {
        if (onChangeRef.current) {
            let finalOperation: ConditionBlockType;
            if (operation === "or") {
                if (negation) {
                    finalOperation = ConditionBlockType.NOT_OR;
                } else {
                    finalOperation = ConditionBlockType.OR;
                }
            } else {
                if (negation) {
                    finalOperation = ConditionBlockType.NOT_AND;
                } else {
                    finalOperation = ConditionBlockType.AND;
                }
            }
            onChangeRef.current(finalOperation);
        }
    }

    function onOperationChanged (newOperation) {
        if (newOperation !== operation) {
            setOperation(newOperation);
            fireOnChangeEvent(newOperation, negationEnabled);
        }
    }
    function onNegationChanged (checked) {
        if (checked !== negationEnabled) {
            setNegationEnabled(checked);
            fireOnChangeEvent(operation, checked);
        }
    }

    return <div className="contents">
        {
            options.includes(ConditionBlockType.AND) && options.includes(ConditionBlockType.OR) && <div className="operation-menu">
                <RadioGroup name="operations" onChange={e => onOperationChanged(e.currentTarget.value)} selectedValue={operation}>
                    <Radio label={BLOCK_TYPE_PROPS[ConditionBlockType.AND].label} value="and"/>
                    <Radio label={BLOCK_TYPE_PROPS[ConditionBlockType.OR].label} value="or"/>
                </RadioGroup>
            </div>
        }
        {
            (options.includes(ConditionBlockType.NOT_AND) || options.includes(ConditionBlockType.NOT_OR)) &&
            <Checkbox
                onChange={e => onNegationChanged(e.currentTarget.checked)}
                className="negation-checkbox"
                label={BLOCK_TYPE_PROPS.NOT.label}
                checked={negationEnabled}
            />
        }
    </div>;
}