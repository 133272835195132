import React, { useState, useEffect } from "react";
import { Icon, IconNames } from "@tir-ui/react-components";
import { APP_ICONS } from "components/sdwan/enums";
import { InputGroup, Button } from "@blueprintjs/core";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { startCase, toLower } from 'lodash';
import { STRINGS } from "app-strings";
import "./EntityList.scss";

/** this interface defines the properties passed into the EntityList React component. */
export interface EntityListProps {
    /** a String with the object type whose entities are to be displayed. */
    objType: string;
}

const EntityList = (props: EntityListProps): JSX.Element => {
    const [pinnedCategoryOpen, setPinnedCategoryOpen] = useState<boolean>(true);
    const [listsCategoryOpen, setListsCategoryOpen] = useState<boolean>(true);
    const [pinnedFilterValue, setPinnedFilterValue] = useState<string>('');
    const [listsFilterValue, setListsFilterValue] = useState<string>('');
    const [entities, setEntities] = useState<Array<string> | undefined>([]);
    const [pinned, setPinned] = useState<Array<string> | undefined>([]);

    const capitalizeEachEntityNameWord = (entityName: string): string => {
        const newEntityName = startCase(toLower(entityName.replace(/_/g,' ')));
        const newEntityNameWords = newEntityName.split(' ');
        newEntityNameWords.forEach((word: string, index: number) => {
            if (word.length < 4) {
                newEntityNameWords[index] = word.toUpperCase();
            }
        });
        return newEntityNameWords.join(' ');
    };

    useEffect(() => {
        const group_by = DataOceanUtils.dataOceanMetaData.obj_types[props.objType].group_by;
        if (listsFilterValue) {
            setEntities(group_by?.filter(entityName => toLower(capitalizeEachEntityNameWord(entityName)).includes(toLower(listsFilterValue))));
        } else {
            setEntities(group_by);
        }

        const pinnedEntityNames = [];
        if (pinnedFilterValue) {
            setPinned(pinnedEntityNames.filter(pinnedName => toLower(pinnedName).includes(toLower(pinnedFilterValue))));
        } else {
            setPinned(pinnedEntityNames);
        }

    }, [listsFilterValue, pinnedFilterValue, props.objType]);

    const handleListsFilterChange = (event) => {
        setListsFilterValue(event.target.value);
    };

    const handleListsFilterClear = () => {
        const inputField = document.getElementById('listsFilterInput') as HTMLInputElement;
        if (inputField) {
            inputField.value = '';
            setListsFilterValue('');
        }
    }

    const listsFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={handleListsFilterClear}
        />
    );

    const handlePinnedFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPinnedFilterValue(event.currentTarget.value);
    };

    const handlePinnedFilterClear = () => {
        const inputField = document.getElementById('pinnedFilterInput') as HTMLInputElement;
        if (inputField) {
            inputField.value = '';
            setPinnedFilterValue('');
        }
    }

    const pinnedFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={handlePinnedFilterClear}
        />
    );

    const drag = (event: any, groupBy: string) => {
        event.dataTransfer.setData("groupBy", groupBy);
    }

    return (<div className="entity-list p-2 pt-3 bg-light">
        <div className="entity-list-header display-7 pb-3">{STRINGS.navigator.entityListWidget.title}</div>
        <div className="entity-list-category mb-2 mt-4">
            <div className="entity-list-category-name">
                <Icon icon={pinnedCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="pl-1 font-weight-bold" onClick={() => setPinnedCategoryOpen(!pinnedCategoryOpen)} style={{ cursor: "pointer" }}>
                    {STRINGS.navigator.entityListWidget.pinnedListTitle}
                </span>
                <button className="pin-entity"><Icon icon={APP_ICONS.ADD} /></button>
            </div>
            {pinnedCategoryOpen && 
                <div className="entity-list-items font-weight-normal pt-2">
                    <InputGroup
                        id={"pinnedFilterInput"}
                        leftIcon="search"
                        rightElement={pinnedFilterClearIcon}
                        onChange={handlePinnedFilterChange}
                        placeholder={STRINGS.incidents.impactSummaryView.search}
                        value={pinnedFilterValue}
                        className="mb-3 mt-2"
                    />
                    {pinned?.length ? pinned.map((pinnedName, index) => (
                        <div className="entity-list-item" draggable onDragStart={(event) => { drag(event, pinnedName); }} key={index}>
                            <Icon icon={APP_ICONS.DRAG_HANDLE_VERTICAL} />
                            <span>{pinnedName}</span>
                        </div>)) : (
                            <div className="entity-list-item display-9">{STRINGS.navigator.entityListWidget.noPinnedItemsFound}</div>
                        )}
                </div>
            }
        </div>
        <div className="entity-list-category">
            <div onClick={() => setListsCategoryOpen(!listsCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={listsCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="pl-1 font-weight-bold">{STRINGS.navigator.entityListWidget.listsListTitle}</span>
            </div>
            {listsCategoryOpen && 
                <div className="entity-list-items font-weight-normal pt-2">
                    <InputGroup
                        id={"listsFilterInput"}
                        leftIcon="search"
                        rightElement={listsFilterClearIcon}
                        onChange={handleListsFilterChange}
                        placeholder={STRINGS.incidents.impactSummaryView.search}
                        value={listsFilterValue}
                        className="mb-3 mt-2"
                    />
                    {entities?.length ? entities.map((entityName, index) => (
                        <div className="entity-list-item" draggable onDragStart={(event) => { drag(event, entityName); }} key={index}>
                            <Icon icon={APP_ICONS.DRAG_HANDLE_VERTICAL} />
                            <span>{capitalizeEachEntityNameWord(entityName)}</span>
                        </div>)) : (
                            <div className="entity-list-item display-9">{STRINGS.navigator.entityListWidget.noListsFound}</div>
                        )}
                </div>
            }
        </div>
    </div>);
}

export { EntityList };
