import React from "react";
import { Intent } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { STRINGS } from "app-strings";

/** defines the datasource status enum.*/
enum EDGE_GATEWAY_STATUS {
    UP = "UP",
    DOWN = "DOWN",
    UNKNOWN = "UNKNOWN",
}

const EDGE_GATEWAY_STATUS_PROPS: Record<
    string,
    {
        label: string;
        icon?: string;
        intent?: Intent;
        className?: string;
    }
> = {
    [EDGE_GATEWAY_STATUS.UP]: {
        label: STRINGS.DATA_SOURCES.edgeGateways.status.up,
        icon: IconNames.TICK_CIRCLE,
        intent: Intent.SUCCESS,
    },
    [EDGE_GATEWAY_STATUS.DOWN]: {
        label: STRINGS.DATA_SOURCES.edgeGateways.status.down,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
    },
    [EDGE_GATEWAY_STATUS.UNKNOWN]: {
        label: STRINGS.DATA_SOURCES.edgeGateways.status.unknown,
        icon: IconNames.ERROR,
        intent: Intent.WARNING,
    },
};

type EdgeGatewayStatusProps = {
    connectedEdge: boolean;
};

const EdgeGatewayStatus = ({ connectedEdge }: EdgeGatewayStatusProps) => {
    const status = connectedEdge
        ? EDGE_GATEWAY_STATUS.UP
        : EDGE_GATEWAY_STATUS.DOWN;

    return (
        <React.Fragment>
            <WrapInTooltip tooltip={EDGE_GATEWAY_STATUS_PROPS[status].label}>
                <Icon
                    icon={EDGE_GATEWAY_STATUS_PROPS[status].icon}
                    intent={EDGE_GATEWAY_STATUS_PROPS[status].intent}
                    className={EDGE_GATEWAY_STATUS_PROPS[status].className}
                />
            </WrapInTooltip>
        </React.Fragment>
    );
};

export { EdgeGatewayStatus };
