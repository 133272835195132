import React, { useEffect, useRef, useState } from "react";
import {
    ErrorToaster,
    IconNames,
    LoadingOverlay,
    Modal,
    useStateSafePromise,
} from "@tir-ui/react-components";
import {
    Button,
    Card,
    Icon,
    IconName,
    Intent,
    SpinnerSize,
    Switch,
    Tab,
} from "@blueprintjs/core";
import { LangEN, STRINGS } from "app-strings";
import { uniqBy } from "lodash";
import { TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { getIntegrationIcon } from "../IntegrationsLibraryPage";
import { AddAuthenticationModal } from "./AddAuthenticationModal";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";

import {
    IntegrationProgressDialog,
    IntegrationProgressDialogProps,
} from "./IntegrationProgressDialog";
import {
    InstalledIntegration,
    InstalledIntegrationConnector,
    InstalledIntegrationStatus,
    IntegrationConnectorConfig,
    IntegrationConnectorPayload,
    AvailableIntegrationDetails,
    IntegrationProcess,
    INTEGRATION_PROCESS_VARIANT,
    IntegrationConnector,
} from "../types/IntegrationTypes";
import { openConfirm } from "components/common/modal";
import {
    BasicDialog,
    updateDialogState,
} from "components/common/basic-dialog/BasicDialog";
import { formatUnixToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { TIME_FORMAT } from "components/enums";

import { getIntegrationBadge, getIntegrationReferences } from "../IntegrationLibraryUtils";
import { Tooltip2 } from "@blueprintjs/popover2";
import { Version } from "utils/Version.class";
import { ThirdPartyIntegrationService } from "utils/services/ThirdPartyIntegrationApiService";

import "./IntegrationConfigurationModal.scss";

export type IntegrationConfigModalTabs = "description" | "configuration";

export const CONNECTOR_ENABLED_BY_DEFAULT = true;

export const IntegrationConfigurationModal = React.forwardRef(
    (props: any, ref) => {
        const [isOpen, setIsOpen] = useState(false);
        const [hasChanges, setHasChanges] = useState(false);
        const [integration, setIntegration] =
            useState<AvailableIntegrationDetails>();
        const [referencesLoading, setReferencesLoading] = useState(false);
        const [connectorsLoading, setConnectorsLoading] = useState(false);
        const [connectorConfig, setConnectorConfig] =
            useState<IntegrationConnectorConfig>();
        const [executeSafely] = useStateSafePromise();
        const [connectors, setConnectors] = useState<any>([]);
        const [activeTab, setActiveTab] =
            useState<IntegrationConfigModalTabs>("description");
        const connectorProfileModal = useRef();
        const minConnectors = connectorConfig?.minimumAmount || 0;
        const [isInstalled, setIsInstalled] = useState(false);
        const [isUpgradable, setIsUpgradable] = useState(false);
        const [upgradableToVersion, setUpgradableToVersion] = useState<string>('');
        const [isActionInProgress, setIsActionInProgress] = useState(false);

        const progressModalRef = useRef(null);
        const integrationsPageTranslations: LangEN["INTEGRATIONS_PAGE"] =
            STRINGS.INTEGRATIONS_PAGE;
        const [dialogState, setDialogState] = useState({
            showDialog: false,
            title: "My Dialog",
            loading: false,
            dialogContent: <></>,
            dialogFooter: <></>,
        });
        const [dialogClassName, setDialogClassName] = useState("");

        /** Load the connectors data */
        useEffect(() => {
            const fetchConnectors = async (id: string) => {
                try {
                    if (activeTab === "configuration" && integration?.id) {
                        setConnectorsLoading(true);
                        const result: any = await executeSafely(
                            IntegrationLibraryService.getConnectors(id)
                        );

                        setConnectors(result);

                        return result;
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setConnectorsLoading(false);
                }
            };

            // We need to add the Connectors only if the installation is installed
            if (
                activeTab === "configuration" &&
                integration?.id &&
                isInstalled
            ) {
                fetchConnectors(integration!.id);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activeTab]);
        /**
         * Actions to control the Connector Modal from outside
         */
        React.useImperativeHandle(ref, () => ({
            handleOpen() {
                setIsOpen(!isOpen);
            },
            setIntegrationDetails(data: AvailableIntegrationDetails) {
                if (data) {
                    setIntegration({ ...data });
                    setConnectorConfig({ ...data?.connector });
                    setHasChanges(false);
                }
            },
            setIsInstalled(isInstalled: boolean) {
                setIsInstalled(isInstalled);
            },
            setIsUpgradable(isUpgradable: boolean) {
                setIsUpgradable(isUpgradable);
            },
            setUpgradableToVersion(version: string) {
                setUpgradableToVersion(version);
            },
            setPreselectedTab(tab: IntegrationConfigModalTabs) {
                setActiveTab(tab);
            }
        }));

        /**
         * Open the Integration Config/Install Modal
         */
        async function openProgressModal(process: IntegrationProcess, newVersion?: string) {
            const progressModal = progressModalRef?.current;

            if (isActionInProgress) {
                return;
            }

            // @ts-ignore
            progressModal?.toggleOpen();

            switch (process) {
                case INTEGRATION_PROCESS_VARIANT.install:
                    // @ts-ignore
                    progressModal?.triggerInstall();
                    break;
                case INTEGRATION_PROCESS_VARIANT.uninstall:
                    // @ts-ignore
                    progressModal?.triggerUninstall();
                    break;
                case INTEGRATION_PROCESS_VARIANT.update:
                    // @ts-ignore
                    progressModal?.triggerUpdate();
                    break;
                case INTEGRATION_PROCESS_VARIANT.upgrade:
                    // @ts-ignore
                    progressModal?.triggerUpgrade(newVersion);
                    break;

                default:
                    console.error('Unknown integration process');
            }

            setIsActionInProgress(true);
        }

        /**
         *
         * @param isEdit - if the modal is in edit mode / or create a new connector
         * @param connector - the connector information
         * @param index - the index of the connector for update on UI
         */
        const handleOpenConnectorProfileModal = async (
            isEdit: boolean,
            isIntegrationInstalled: boolean = false,
            connector?:
                | InstalledIntegrationConnector
                | IntegrationConnectorPayload,
            index?: number,
            isClone?: boolean,
        ) => {
            if (!connectorProfileModal.current || !integration) {
                console.error("An integration is required.");

                return;
            }

            // @ts-ignore
            connectorProfileModal.current.setIntegrationId(integration.id);

            if (isEdit) {
                const installedConnector =
                    connector as InstalledIntegrationConnector;
                // We need to get the connector AuthProfileId and properties on demand
                const connectorDetails = (isIntegrationInstalled && installedConnector.connectorId)
                    ? await IntegrationLibraryService.getConnectorDetails(
                        integration.id,
                        installedConnector.connectorId
                    )
                    : { ...connector, authenticationProfileId: null };

                if (isInstalled) {
                    // Installed Connector Edit
                    // @ts-ignore
                    // Send the profileId so the AuthProfile modal knows that it needs to retrieve info from BE
                    connectorProfileModal.current.setConnector(
                        connectorDetails,
                        connectorDetails.authenticationProfileId
                    );
                } else {
                    // Created Connector Edit (not yet installed)
                    // @ts-ignore
                    connectorProfileModal.current.setConnector(connector);
                }
                // @ts-ignore
                connectorProfileModal.current.setConnectorIndex(index);
            }

            // @ts-ignore
            connectorProfileModal.current.setEdit(isEdit, true);
            // @ts-ignore
            connectorProfileModal.current.setIsClone(isClone);

            // @ts-ignore
            connectorProfileModal.current.handleOpen();
        };

        const brandingIcon = getIntegrationIcon(
            integration?.branding?.icons,
            integration?.name || "Integration",
            "small"
        );

        /**
         * Render the Modal Content
         *
         * @returns {JSX.Element}
         */
        function renderModalContent() {
            const hasLinks = (integration?.links || []).length > 0;

            return (
                <div className="container integrations-library__modal">
                    <header className="row">
                        <div className="pr-2 border-right d-flex align-items-center justify-content-center">
                            <div className="integrations-library__card-logo integrations-library__card-logo--small">
                                {brandingIcon}
                            </div>
                        </div>
                        <div className="col ml-2 mt-auto">
                            <h2>{integration?.name}</h2>
                            <p>{integration?.description?.short}</p>

                            <div className="integrations-library__card-badges integrations-library__card-badges--relative">
                                {integration?.isBeta && getIntegrationBadge('beta')}
                                {isUpgradable && getIntegrationBadge('upgradeAvailable', upgradableToVersion)}
                                {isInstalled
                                    ? getIntegrationBadge('installed')
                                    : getIntegrationBadge('available')
                                }
                            </div>
                        </div>
                    </header>
                    <TabbedSubPages
                        childClassName="pl-0"
                        selectedTabId={activeTab}
                        onTabChange={setActiveTab}
                        renderActiveTabPanelOnly={true}
                        dontUpdateURL={false}
                    >
                        <Tab id="description" title={"Description"}>
                            <div className="mt-4">
                                <p style={{ whiteSpace: "pre-line" }}>
                                    {integration?.description?.long}
                                </p>
                                <h3 className="mt-5 font-size-large font-weight-bold">
                                    Links
                                </h3>
                                <ul className="list-group integration-library__modal-links">
                                    {hasLinks &&
                                        integration?.links.map(
                                            (link, index) => {
                                                return (
                                                    <li
                                                        key={index}
                                                        className="list-group-item"
                                                        style={{
                                                            border: 0,
                                                            padding: "4px 0",
                                                        }}
                                                    >
                                                        <a
                                                            href={link?.value}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {link?.name}
                                                            <Icon
                                                                icon={
                                                                    IconNames.SHARE as IconName
                                                                }
                                                                size={14}
                                                                className="ml-2"
                                                                style={{
                                                                    color: "#106ba3",
                                                                    cursor: "pointer",
                                                                    verticalAlign:
                                                                        "baseline",
                                                                }}
                                                            />
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                            </div>
                        </Tab>
                        <Tab id="configuration" title={"Configuration"}>
                            {minConnectors > 1 &&
                                connectors.length < minConnectors && (
                                    <p
                                        style={{ color: "red" }}
                                        className="mt-2 font-italic text-center font-size-small mx-auto w-full"
                                    >
                                        {STRINGS.formatString(
                                            integrationsPageTranslations
                                                .integrationDetailsModal
                                                .minConnectorsRequired,
                                            minConnectors
                                        )}
                                    </p>
                                )}
                            <div className="mt-4">
                                {renderConnectorsList(
                                    connectors.filter(el => !(el as IntegrationConnector).isClone),
                                    connectorConfig,
                                    isInstalled
                                )}
                            </div>
                        </Tab>
                    </TabbedSubPages>

                    <AddAuthenticationModal
                        title="integration-connector-modal"
                        ref={connectorProfileModal}
                        refreshData={() => {
                            console.debug("refresh Data");
                        }}
                        profileNameList={connectors.map(el => el?.name)}
                        handleErrors={(error) => {
                            console.error(error);
                        }}
                        handleAddConnector={(
                            payload,
                            index: number,
                            isEdit: boolean
                        ) => {
                            /* istanbul ignore next */
                            if (isEdit) {
                                setConnectors([
                                    ...connectors.map((connector, i) => {
                                        if (i === index) {
                                            return payload;
                                        }

                                        return connector;
                                    }),
                                ]);
                                setHasChanges(true);
                            } else {
                                setConnectors([...connectors, payload]);
                                setHasChanges(true);
                            }
                        }}
                        connectorConfig={connectorConfig}
                    />
                </div>
            );
        }

        /**
         * Render a connector in the list
         */
        function renderConnector(
            connector:
                | InstalledIntegrationConnector
                | IntegrationConnectorPayload,
            index: number,
            isIntegrationInstalled: boolean
        ) {
            // Don't show cloned connectors
            if ((connector as IntegrationConnector)?.isClone) {
                return <></>
            }

            return (
                <li
                    key={connector?.name}
                    className="list-group-item d-flex my-2 rounded"
                >
                    <span>
                        {connector?.name}
                        {isIntegrationInstalled && (connector as InstalledIntegrationConnector)?.isDeprecated && (
                            <Tooltip2
                                content={integrationsPageTranslations.integrationDetailsModal.connectorIsDeprecated}
                                position="bottom"
                                interactionKind="hover"
                            >
                                <Icon
                                    className="ml-2 vertical-align"
                                    icon={IconNames.WARNING_SIGN}
                                    intent={Intent.DANGER}
                                />
                            </Tooltip2>
                        )}
                    </span>
                    <div className="ml-auto d-flex" style={{ gap: "8px" }}>
                        <Switch
                            className="mb-0 align-self-center"
                            aria-label={`toggle-${connector.name}`}
                            checked={connector.isEnabled}
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                                setHasChanges(true);
                                setConnectors(
                                    connectors.map((item) => {
                                        if (item.name === connector.name) {
                                            return {
                                                ...connector,
                                                isEnabled: !connector.isEnabled,
                                            };
                                        }

                                        return item;
                                    })
                                );
                            }}
                        />
                        <Button
                            title={integrationsPageTranslations.integrationDetailsModal.controls.edit}
                            minimal
                            style={{ cursor: "pointer" }}
                            icon={IconNames.COG}
                            onClick={() => {
                                if (!connectorProfileModal?.current) {
                                    return;
                                }
                                handleOpenConnectorProfileModal(
                                    true,
                                    isIntegrationInstalled,
                                    connector,
                                    index,
                                    (connector as IntegrationConnector)?.isClone
                                );
                            }}
                        />
                        <Button
                            minimal
                            title={integrationsPageTranslations.integrationDetailsModal.controls.duplicate}
                            icon={IconNames.DUPLICATE}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDuplicateConnector(connector.name, connector?.connectorId, isIntegrationInstalled)}
                        />
                        <Button
                            minimal
                            icon={IconNames.TRASH}
                            title={integrationsPageTranslations.integrationDetailsModal.controls.delete}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                connector?.connectorId ?
                                    onDeleteInstalledConnector(connector.name) :
                                    handleDeleteConnector(connector.name)
                            }
                        />
                    </div>
                </li>
            );
        }

        /**
         * Renders the list of connectors
         *
         * @param connectors
         *
         * @returns {JSX.Element}
         */
        function renderConnectorsList(
            connectors: Array<IntegrationConnectorPayload>,
            connectorConfig: IntegrationConnectorConfig | undefined,
            isIntegrationInstalled: boolean
        ) {
            if (!connectorConfig) {
                return <></>;
            }

            const hasConnectors = connectors?.length > 0;
            const maxConnectors = connectorConfig.maximumAmount;

            if (maxConnectors === 0) {
                return (
                    <p
                        style={{ color: "red" }}
                        className="mt-2 font-italic text-center font-size-small mx-auto w-full"
                    >
                        {
                            integrationsPageTranslations.integrationDetailsModal
                                .noConnectorRequired
                        }
                    </p>
                );
            }

            const showConnectorRequiredMessage =
                !hasConnectors && minConnectors > 0;

            return (
                <div>
                    <LoadingOverlay visible={connectorsLoading || isActionInProgress} />
                    {connectors.length < maxConnectors && (
                        <Button
                            aria-label={
                                integrationsPageTranslations.integrationDetailsModal
                                    .buttons.addConnector
                            }
                            intent={Intent.PRIMARY}
                            onClick={() =>
                                handleOpenConnectorProfileModal(false, false)
                            }
                            icon={IconNames.PLUS}
                            text={
                                integrationsPageTranslations.integrationDetailsModal
                                    .buttons.addConnector
                            }
                        />
                    )}
                    {!connectorsLoading && showConnectorRequiredMessage && (
                        <p
                            style={{ color: "red" }}
                            className="mt-2 font-italic text-center font-size-small mx-auto w-full"
                        >
                            {
                                integrationsPageTranslations.integrationDetailsModal
                                    .connectorRequired
                            }
                        </p>
                    )}

                    {!connectorsLoading && connectors.length === 0 && (
                        <p className="mt-2 font-italic text-center font-size-small mx-auto w-full">
                            {
                                integrationsPageTranslations.integrationDetailsModal
                                    .noConnectors
                            }
                        </p>
                    )}

                    <div>
                        <ul className="list-group mt-2 integrations-library__connectors-list">
                            {connectors.map((connector, index) =>
                                renderConnector(
                                    connector,
                                    index,
                                    isIntegrationInstalled
                                )
                            )}
                        </ul>
                    </div>
                </div>
            );
        }

        /**
         * Handles closing of the modal
         */
        function handleCloseModal() {
            setActiveTab("description"); // reset active tab
            setIsOpen(false);
            setConnectors([]);
            setIsActionInProgress(false);
        }

        /**
         * Check if the installation has an install/uninstall in progress
         *
         * @param INTEGRATION_PROCESS_VARIANT - the type of the process that we do the check for
         * @param integrationId
         * @param integrationVersion
         *
         * @returns {boolean}
         */
        // istanbul ignore next - this is going to be hard to test, as we need to alter the fixture for the GET installedIntegrations in mid-flight
        async function checkIfProcessInProgress(
            type: IntegrationProcess,
            integrationId,
            integrationVersion
        ) {
            try {
                const processMap = {
                    [INTEGRATION_PROCESS_VARIANT.install]: InstalledIntegrationStatus.InstallationInProgress,
                    [INTEGRATION_PROCESS_VARIANT.update]: InstalledIntegrationStatus.UpdateInstallationInProgress,
                    [INTEGRATION_PROCESS_VARIANT.uninstall]: InstalledIntegrationStatus.UninstallInProgress,
                    [INTEGRATION_PROCESS_VARIANT.upgrade]: InstalledIntegrationStatus.UpgradeInstallationInProgress
                }

                // Fetch Installed Integrations
                const processStatus = Object.hasOwn(processMap, type) ? processMap[type] : processMap[0];

                const installedIntegrations =
                    (await IntegrationLibraryService.getInstalledIntegrations()) as Array<InstalledIntegration>;
                const hasActiveProcess = installedIntegrations.find(
                    (integration) =>
                        integration.integrationId === integrationId &&
                        integration.installedVersion === integrationVersion &&
                        integration.status === processStatus
                );

                // Has an Install/Uninstall/Update in progress
                if (hasActiveProcess) {
                    return true;
                }

                return false;
            } catch (error) {
                console.error(error);
            }
        }

        /**
         * Check if the integration resources are in use
         */
        async function onDeleteIntegration() {
            try {
                setReferencesLoading(true);
                const affectedResources = await getIntegrationReferences(
                    integration?.id
                );
                const uniqueResources = uniqBy(affectedResources, "id");
                setReferencesLoading(false);

                const confirmMessage =
                    uniqueResources?.length > 0 ? (
                        <>
                            <p className="font-size-sm">
                                {`${STRINGS.formatString(
                                    uniqueResources.length === 1
                                        ? integrationsPageTranslations
                                            .integrationDetailsModal
                                            .uninstallIntegration
                                            .dialogReferencingResource
                                        : integrationsPageTranslations
                                            .integrationDetailsModal
                                            .uninstallIntegration
                                            .dialogReferencingResources,
                                    uniqueResources?.length
                                )} \n
                            ${integrationsPageTranslations.integrationDetailsModal
                                        .uninstallIntegration.dialogConfirm
                                    }`}
                            </p>
                            <div className="mt-2 integration-library__modal-referenced-resources">
                                {uniqueResources.map((runbook) =>
                                    renderRunbook(runbook)
                                )}
                            </div>
                        </>
                    ) : (
                        `${integrationsPageTranslations.integrationDetailsModal.uninstallIntegration.dialogNoReferencingResources} \n  
                    ${integrationsPageTranslations.integrationDetailsModal.uninstallIntegration.dialogConfirm}`
                    );

                openConfirm({
                    message: confirmMessage,
                    confirmButtonText:
                        integrationsPageTranslations.integrationDetailsModal
                            .uninstallIntegration.dialogConfirmBtn,
                    onConfirm: () => handleUninstallIntegration(),
                    icon: IconNames.TRASH,
                    intent: Intent.PRIMARY
                });
            } catch (error) {
                console.error(error);
            }
        }

        /**
         * Check if the integration connector is in use
         */
        async function onDeleteInstalledConnector(
            connectorName: string
        ) {
            try {
                if (!integration?.id) {
                    return;
                }

                setReferencesLoading(true);
                const affectedResources = await getIntegrationReferences(
                    integration?.id
                );
                const uniqueResources = uniqBy(affectedResources, "id");

                setReferencesLoading(false);

                const confirmMessage =
                    uniqueResources?.length > 0 ? (
                        <>
                            <p className="font-size-sm">
                                {`${STRINGS.formatString(
                                    uniqueResources.length === 1
                                        ? integrationsPageTranslations
                                            .affectedResources
                                            .dialogReferencingResource
                                        : STRINGS.formatString(integrationsPageTranslations
                                            .affectedResources
                                            .dialogReferencingResources,
                                            uniqueResources?.length
                                        )
                                )} \n
                            ${integrationsPageTranslations.affectedResources
                                        .deleteConnectorConfirm
                                    }`}
                            </p>
                            <div className="mt-2 integration-library__modal-referenced-resources">
                                {uniqueResources.map((runbook) =>
                                    renderRunbook(runbook)
                                )}
                            </div>
                        </>
                    ) : (
                        `${integrationsPageTranslations.affectedResources.dialogNoReferencingResources} \n  
                    ${integrationsPageTranslations.affectedResources.deleteConnectorConfirm}`
                    );

                openConfirm({
                    message: confirmMessage,
                    confirmButtonText:
                        integrationsPageTranslations.affectedResources
                            .deleteConnectorConfirmBtn,
                    onConfirm: () => handleDeleteConnector(connectorName),
                    icon: IconNames.TRASH,
                    intent: Intent.PRIMARY,
                });
            } catch (error) {
                console.error(error);
            }
        }

        /**
         * Create a copy of the selected connector
         * 
         * @param connectorName 
         * @param connectorId
         */
        async function handleDuplicateConnector(connectorName: string, connectorId: undefined | string, isIntegrationInstalled: boolean) {
            const connectorToBeCloned = connectors.find(el => el.name === connectorName);

            if (!connectorToBeCloned) {
                return;
            }

            setConnectorsLoading(true);
            const clonedConnectorName = STRINGS.formatString(integrationsPageTranslations.integrationDetailsModal.duplicatePrefix, `${connectorName}`);
            let clonedConnector: IntegrationConnector | {} = {};

            // Installed connector
            if (connectorId && integration?.id) {
                // Get connector details from the BE
                const connectorDetails = await IntegrationLibraryService.getConnectorDetails(integration?.id, connectorId);

                clonedConnector = {
                    ...connectorDetails,
                    name: clonedConnectorName,
                    connectorId: undefined,
                    authenticationProfileId: undefined,
                    undefined,
                    isClone: true
                };

                // Add auth profile data to the cloned connector
                if (connectorDetails?.authenticationProfileId) {
                    const connectorProfileDetails = await ThirdPartyIntegrationService.getAuthProfile(connectorDetails?.authenticationProfileId);

                    const {id, ...authProfileDetails } = connectorProfileDetails;

                    clonedConnector = {
                        ...clonedConnector,
                        authenticationProfile: {
                            ...authProfileDetails,
                            name: clonedConnectorName
                        }
                    }
                }
            } else {
                // NOT Installed Connector
                clonedConnector = {
                    ...connectorToBeCloned,
                    connectorId: undefined,
                    name: clonedConnectorName,
                    isClone: true,
                }

                // Add auth profile data to the cloned connector
                if (connectorToBeCloned?.authenticationProfile) {
                    clonedConnector = {
                        ...clonedConnector,
                        authenticationProfile: {
                            ...connectorToBeCloned?.authenticationProfile,
                            name: clonedConnectorName
                        }
                    }
                }
            }

            setConnectors([clonedConnector, ...connectors]);
            setConnectorsLoading(false);
            handleOpenConnectorProfileModal(
                true,
                isIntegrationInstalled,
                clonedConnector as IntegrationConnectorPayload,
                0,
                true
            );
        }

        /**
         * Delete the connector
         *
         * @param {string} connectorName
         */
        async function handleDeleteConnector(connectorName) {
            try {
                // Deleting a connector on the BE will be done when the Update Integration call is made.
                setHasChanges(true);
                setConnectors(
                    connectors.filter((el) => el.name !== connectorName)
                );
            } catch (error) {
                console.error(error);
                ErrorToaster({
                    message:
                        integrationsPageTranslations.integrationDetailsModal.errors
                            .failedToDeleteConnector,
                });
            }
        }

        /**
         * Handle the upgrade of an installed integration to a newer version
         */
        async function handleUpgradeIntegration(newVersion: string) {
            if (integration?.id) {
                const upgradeAlreadyInProgress =
                    await checkIfProcessInProgress(
                        INTEGRATION_PROCESS_VARIANT.upgrade,
                        integration.id,
                        integration.version
                    );

                if (upgradeAlreadyInProgress) {
                    ErrorToaster({
                        message:
                            integrationsPageTranslations.integrationDetailsModal.errors.upgradeAlreadyInProgress,
                    });
                } else {
                    openProgressModal(INTEGRATION_PROCESS_VARIANT.upgrade, newVersion);
                }
            }
        }

        /**
         * Handle the uninstall of an integration
         */
        async function handleUninstallIntegration() {
            if (integration?.id) {
                const uninstallAlreadyInProgress =
                    await checkIfProcessInProgress(
                        INTEGRATION_PROCESS_VARIANT.uninstall,
                        integration.id,
                        integration.version
                    );

                if (uninstallAlreadyInProgress) {
                    ErrorToaster({
                        message:
                            integrationsPageTranslations.integrationDetailsModal.errors
                                .uninstallAlreadyInProgress,
                    });
                } else {
                    openProgressModal(INTEGRATION_PROCESS_VARIANT.uninstall);
                }
            }
        }

        function renderRunbook(runbook) {
            const runbookCard = (
                <Card className="w-100">
                    <h5 className="font-weight-bold">
                        <a
                            href={`create-runbook?fid=${runbook.id}&fname=${runbook.name}&variant=${runbook.variant}`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {runbook.name}
                        </a>
                    </h5>
                    <p className="font-italic font-weight-light">
                        {runbook.description}
                    </p>
                    <p className="font-italic font-weight-normal">
                        {STRINGS.formatString(
                            STRINGS.thirdPartyIntegrations.referencingRunbooks
                                .runbookUpdate,
                            {
                                user: runbook.lastUpdatedUser,
                                time: formatUnixToLocalTimestamp(
                                    runbook.lastUpdatedTime,
                                    TIME_FORMAT.DISPLAY_DATE_ONLY_FORMAT
                                ),
                            }
                        )}
                    </p>
                </Card>
            );

            return runbookCard;
        }

        /**
         * Get an array containing the buttons that are going to be shown in the Modal Footer
         *
         * @returns {JSX.Element}
         */
        function getModalButtons() {
            const buttons: Array<any> = [
                {
                    label: integrationsPageTranslations.integrationDetailsModal.buttons
                        .cancel,
                    action: handleCloseModal,
                },
            ];

            if (isInstalled) {
                buttons.push({
                    label: integrationsPageTranslations.integrationDetailsModal.buttons
                        .uninstall,
                    action: onDeleteIntegration,
                    intent: Intent.DANGER,
                });
            }

            if (isUpgradable && upgradableToVersion.length) {
                const oldVersion = Version.parse(integration?.version || "");
                const newVersion = Version.parse(upgradableToVersion || "");
                const isMajorUpdate = oldVersion.major !== newVersion.major;

                buttons.push({
                    label: integrationsPageTranslations.integrationDetailsModal.buttons.upgrade,
                    action: () => {
                        openConfirm({
                            className: 'integrations-library__upgrade-confirm',
                            message: isMajorUpdate ?
                                integrationsPageTranslations.integrationDetailsModal.upgradeIntegration.confirmMessage.major :
                                integrationsPageTranslations.integrationDetailsModal.upgradeIntegration.confirmMessage.minor,
                            confirmButtonText: integrationsPageTranslations.integrationDetailsModal.upgradeIntegration.confirmButton,
                            onConfirm: () => { handleUpgradeIntegration(upgradableToVersion); },
                            intent: Intent.PRIMARY,
                        });
                    },
                    intent: Intent.PRIMARY,
                });
            }

            buttons.push({
                label: isInstalled
                    ? integrationsPageTranslations.integrationDetailsModal.buttons.apply
                    : integrationsPageTranslations.integrationDetailsModal.buttons.install,
                action: async () => {
                    if (connectors.length < minConnectors) {
                        setActiveTab("configuration");

                        return;
                    }

                    if (!integration) {
                        return;
                    }

                    // Edit An Integration
                    if (isInstalled) {
                        const updateAlreadyInProgress =
                            await checkIfProcessInProgress(
                                INTEGRATION_PROCESS_VARIANT.update,
                                integration.id,
                                integration.version
                            );

                        if (updateAlreadyInProgress) {
                            ErrorToaster({
                                message:
                                    integrationsPageTranslations.integrationDetailsModal
                                        .errors.updateAlreadyInProgress,
                            });
                        } else {
                            openProgressModal(INTEGRATION_PROCESS_VARIANT.update);
                        }
                    } else {
                        // Install an integration
                        const installAlreadyInProgress =
                            await checkIfProcessInProgress(
                                INTEGRATION_PROCESS_VARIANT.install,
                                integration.id,
                                integration.version
                            );

                        if (installAlreadyInProgress) {
                            ErrorToaster({
                                message:
                                    integrationsPageTranslations.integrationDetailsModal
                                        .errors.installAlreadyInProgress,
                            });
                        } else {
                            openProgressModal(INTEGRATION_PROCESS_VARIANT.install);
                        }
                    }
                },
                intent: Intent.SUCCESS,
                disabled: (!hasChanges && isInstalled) || isActionInProgress,
            });

            return buttons;
        }

        const modalButtons = getModalButtons();

        if (!integration || !connectorConfig || !isOpen) {
            return <></>;
        }

        // Props for the IntegrationProgressDialog component
        const progressModalProps: IntegrationProgressDialogProps = {
            integration: integration,
            payload: {
                connectors: [...connectors.filter(el => !el.isClone)],
            },
            handleProcessFinish: props.onProcessFinished,
            closeConfigModal: () => handleCloseModal(),
        };

        /** Render the MODAL */
        return (
            <Modal
                title={""}
                hideCancel={true}
                buttons={modalButtons}
                onSubmit={() => { }}
                onClose={() => handleCloseModal()}
                usePortal={false}
                hideSubmit={true}
            >
                <LoadingOverlay
                    visible={referencesLoading}
                    spinnerSize={SpinnerSize.SMALL}
                    loadingText={
                        integrationsPageTranslations.integrationDetailsModal
                            .uninstallIntegration.checkInProgress
                    }
                />
                {renderModalContent()}
                <BasicDialog
                    portalClassName="error-dialog"
                    className={dialogClassName}
                    dialogState={dialogState}
                    onClose={() => {
                        setDialogState(
                            updateDialogState(dialogState, false, false, [])
                        );
                        setDialogClassName("");
                    }}
                />
                <IntegrationProgressDialog
                    ref={progressModalRef}
                    {...progressModalProps}
                />
            </Modal>
        );
    }
);
