import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Classes, Divider, Label } from '@blueprintjs/core';
import { Form, InputField, TextAreaField } from 'components/common/form';
import { STRINGS } from 'app-strings';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconNames } from '@tir-ui/react-components';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { TriggerTypes } from '../views/MappingConfigurationView';
import { ConditionBlockType } from 'components/common/condition-tree-builder/condition-block/ConditionBlock';
import { Ops } from 'components/common/graph/editors/decision/DecisionNodeUtil';
import { TRIGGER_PREFIX } from 'components/hyperion/views/decision-branch/DecisionBranchUtils';

export interface IFinalPanelProps {
    editMode: boolean;
    automationNameValue: string;
    automationDescriptionValue: string;
    automationOrderValue: number;
    maxOrderValue: number;
    conditionTree: any;
    triggerType: string;
    onAutomationNameValueChange: (
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    onAutomationDescriptionValueChange: (
        event: React.FormEvent<HTMLTextAreaElement>
    ) => void;
    onAutomationOrderValueChange: (
        event: React.FormEvent<HTMLInputElement>
    ) => void;
}

const FinalPanel: React.FC<IFinalPanelProps> = ({
    editMode,
    automationNameValue,
    automationDescriptionValue,
    automationOrderValue,
    maxOrderValue,
    conditionTree,
    triggerType,
    onAutomationNameValueChange,
    onAutomationDescriptionValueChange,
    onAutomationOrderValueChange,
}) => {
    const AuthService = AuthServiceProvider.getService();
    const tenantId = AuthService.getTenantId();
    const region = AuthService.getRegion();
    const automationVersion = '1.0';
    const baseURL = `${window.location.origin}/api/iq/${region}/automation/${automationVersion}/tenants/${tenantId}/webhooks`;

    /*
    const paramsFromConditions = extractParamsFromConditionTree(conditionTree);
    const webhookURL =
        baseURL +
        (paramsFromConditions.length > 0
            ? `?${extractParamsFromConditionTree(conditionTree)}`
            : '');
    */
    const paramsFromConditions: Record<string, string[]> = {};
    extractDeduplicatedParamsFromConditionTree(conditionTree, paramsFromConditions);
    let webhookURL: string = '';
    for (const key in paramsFromConditions) {
        const values: string[] = paramsFromConditions[key];
        for (const value of values) {
            webhookURL += (webhookURL.length ? "&" : baseURL + "?") + key + "=" + value;
        }
    }

    const [validOrderValue, setValidOrderValue] = useState<boolean>(true);
    const handleAutomationOrderValueChange = (
        event: React.FormEvent<HTMLInputElement>
    ) => {
        if (
            Number(event.currentTarget.value) >= 1 &&
            Number(event.currentTarget.value) <= maxOrderValue
        ) {
            setValidOrderValue(true);
        } else {
            setValidOrderValue(false);
        }
        onAutomationOrderValueChange(event);
    };

    return (
        <div
            className={classNames(
                Classes.DIALOG_BODY,
                'mapping-config-final-panel',
                'p-1'
            )}
        >
            <p>
                <b>
                    {STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels
                        .final.subTitle + ':'}
                </b>
            </p>
            <Divider />
            <br />
            <Form
                initialValues={{
                    mapping_name: automationNameValue,
                    mapping_description: automationDescriptionValue,
                    mapping_order: automationOrderValue,
                }}
                loading={false}
            >
                <InputField
                    name="mapping_name"
                    type="text"
                    required={true}
                    className="input-field"
                    label={
                        STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal
                            .panels.final.fields.automationName.label + ':'
                    }
                    onChange={onAutomationNameValueChange}
                    disabled={false}
                    value={automationNameValue}
                />
                <TextAreaField
                    name="mapping_description"
                    rows={4}
                    required={true}
                    className="w-100"
                    label={
                        STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal
                            .panels.final.fields.automationDescription.label +
                        ':'
                    }
                    onChange={onAutomationDescriptionValueChange}
                    disabled={false}
                    value={automationDescriptionValue}
                />
                <Label>
                    {
                        STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal
                            .panels.final.fields.automationOrder.description
                    }
                </Label>
                <InputField
                    name="mapping_order"
                    type="number"
                    min={1}
                    max={maxOrderValue}
                    className="w-25"
                    required={true}
                    fill={true}
                    label={
                        STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal
                            .panels.final.fields.automationOrder.label + ':'
                    }
                    onChange={handleAutomationOrderValueChange}
                    disabled={editMode}
                    value={automationOrderValue.toString()}
                />
                {!validOrderValue && (
                    <div className="text-danger display-9">
                        {STRINGS.formatString(
                            STRINGS.MAPPING_CONFIGURATION_PAGE.orderMappingModal
                                .validationErrorMsg,
                            maxOrderValue
                        )}
                    </div>
                )}

                {triggerType === TriggerTypes.EXTERNAL && renderWebhookURL()}
            </Form>
        </div>
    );

    /** Render Webhook URL section
     *  @returns JSX . */
    function renderWebhookURL() {
        return (
            <div className="pt-3">
                <div className="mt-1">Example of a webhook URL:</div>
                <div className="d-flex mt-2 font-italic">
                    <span> {webhookURL}</span>
                    <span>
                        <CopyToClipboard text={webhookURL} className="ml-2">
                            <Button
                                small
                                minimal
                                icon={IconNames.DUPLICATE}
                                onClick={() => {}}
                            />
                        </CopyToClipboard>
                    </span>
                </div>
            </div>
        );
    }
};

/** Will create an url params string, from the given condition tree.
 *  @param tree {conditionTree}
 *  @returns string .*/
function extractDeduplicatedParamsFromConditionTree(tree: any, params: Record<string, string[]>): void {
    if (tree?.key && tree?.value) {
        const operation = (tree.op || '').toLowerCase();
        if (operation) {
            const startIndex = tree?.key && tree.key?.indexOf(TRIGGER_PREFIX) !== -1 ? tree.key.lastIndexOf(".") + 1 : 0;
            const key = tree?.key ? tree.key.substring(startIndex, tree.key.length) : undefined;
            const value = tree?.value;
            if (!params[key]) {
                params[key] = [];
            }
            if (Array.isArray(value)) {
                for (const item of value) {
                    if (!params[key].includes(item)) {
                        params[key].push(item);
                    }
                }
            } else if (!params[key].includes(value)) {
                params[key].push(value);
            }
            return;
        }
    }

    if (tree?.operation === ConditionBlockType.AND || tree?.operation === ConditionBlockType.OR) {
        tree?.conditions.forEach((el) => {
            extractDeduplicatedParamsFromConditionTree(el, params);
        });
    }
}

/** Will create an url params string, from the given condition tree.
 *  @param tree {conditionTree}
 *  @returns string .*/
function extractParamsFromConditionTree(tree: any): string {
    if (tree?.key && tree?.value) {
        const operationsForParams = [Ops.EQ, Ops['EQ-ANY'], Ops.CONTAINS];
        const operation = (tree.op || '').toLowerCase();

        if (true || operationsForParams.includes(Ops[operation.toUpperCase()])) {
            const startIndex = tree?.key && tree.key?.indexOf(TRIGGER_PREFIX) !== -1 ? tree.key.lastIndexOf(".") + 1 : 0;
            const key = tree?.key ? tree.key.substring(startIndex, tree.key.length) : undefined;
            const value = tree?.value;
            /*
            if (Ops[operation.toUpperCase()] === Ops['EQ-ANY']) {
                return `${key}=[${value}]`;
            } else {
                return `${key}=${value}`;
            }
            */
            return `${key}=${value}`;
        } else {
            return '';
        }
    }

    if (tree?.operation === ConditionBlockType.AND) {
        const components = tree?.conditions.map((el) => {
            return extractParamsFromConditionTree(el);
        });

        if (components.length) {
            return components.join('&');
        }
    }

    if (tree?.operation === ConditionBlockType.OR) {
        // Any of the first two conditions is an AND, we use the first condition from the AND, and the one from top level
        // If there is no AND condition, we use the first condition
        const firstTwoConditions = tree?.conditions.slice(0, 2);
        const andCondition = firstTwoConditions.find(
            (el) => el?.operation === ConditionBlockType.AND
        );
        const components = andCondition
            ? extractParamsFromConditionTree(andCondition)
            : extractParamsFromConditionTree(firstTwoConditions[0]);

        if (Array.isArray(components) && components.length > 0) {
            return components.join('&');
        } else if (typeof components === 'string') {
            return components;
        }
    }

    return '';
}

export { FinalPanel, extractParamsFromConditionTree, extractDeduplicatedParamsFromConditionTree };
