import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
//import * as Sentry from '@sentry/browser';

import App from './components/app/App';

// this tells the theme to avoid showing the ugly focus ring when not using keyboard to navigate
import { FocusStyleManager } from "@blueprintjs/core";
FocusStyleManager.onlyShowFocusOnTabs();

// sentry integration
// const isLocalHost = window.location.host.split('.')[0] === 'localhost:3000';
// if(!isLocalHost) {
//     const { ENV } = window['runConfig'];
//     Sentry.init({dsn: "https://8fdf5b2f221d4f2b81997ff6752a87c3@o288610.ingest.sentry.io/5271934", environment:ENV});
// }

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

