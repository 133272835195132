/** This module contains the component for rendering the users card in the impact summary.
 *  The users card displays the count and list of impacted users.
 *  @module
 */
import React from 'react';
import { SIZE } from 'components/enums';
import { IconNames } from '@tir-ui/react-components';
import { SummaryCard, SummaryCardProps } from 'components/common/layout/summary-card/SummaryCard';
import { STRINGS } from 'app-strings';
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";

const BEM_USERS_CARD_LENGTH = "UsersCard-length";
const BEM_USERS_CARD_FOOTER = "UsersCard-footer";

/** used to filter arrays of nonsensical and repeating dots, ex: `...` */
const dotMatcher = /(^|\s)\.{1,}(\s|$)/;

/** the properties passed into the React component. */
interface UsersCardProps extends SummaryCardProps {
    /** a number with the count of users. */
	count?: number;
    /** the string array with the list of users. */
	data?: string[];
    /** the handler to use for details requests. */
    onDetails?: (icon: string, title: string, subTitle: string, data: string[]) => void;
    /** a boolean value, if true show the footer, if false do not, the default is true. */
    showFooter?: boolean;
}

/** Renders the users card in the impact summary
 *  @param {number} count
 *  @param {Array} data
 *  @param {boolean} showFooter
 *  @param props - react properties passed in
 *  @returns JSX with the users card component.*/
const UsersCard = ({count = 0, data = [], showFooter = true, ...props}: UsersCardProps): JSX.Element => {
    const users = [...data]
        // filter out falsy values and subsequent dots
        .filter((datum) => datum && !dotMatcher.test(datum))
        .sort();

	const handleOnClick = () => {
        if (props.onDetails) {
            props.onDetails(
                IconNames.PEOPLE, STRINGS.incidents.impactSummaryView.users, STRINGS.incidents.impactSummaryView.impactedUsers, data
            );
        }
	};

	return (
		<SummaryCard
			title={
				<span className="text-black">
					{STRINGS.incidents.cards.users.title}
				</span>
			}
			icon={IconNames.PEOPLE}
			size="flex"
			height={SIZE.m}
			className="w-min-3-force"
            hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            hideBorder={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
			footerContent={<>
				{false && <span className="opacity-7 display-10">{STRINGS.showingMockData}</span>}
				{(showFooter && users.length > 0) ? 
                    <div
                        data-testid={BEM_USERS_CARD_FOOTER}
                        className="display-9 float-right text-primary clickable"
                        onClick={handleOnClick}
                    >
                        {STRINGS.viewAll}
                    </div>
                    : null
                }
			</>}
		>
			<div className="overflow-hidden h-100 justify-content-center">
                <div
                    data-testid={BEM_USERS_CARD_LENGTH}
                    className="d-inline-block display-6 font-weight-900 text-black"
                >
                    {/* {count} Using length as of now because query data is mocked and so there can be a mismatch of count and list of users */}
                    {users.length}
                </div>
				<div
					className="display-9 pl-2 pt-2 text-truncate"
					onClick={handleOnClick}
				>
					{users.join(", ")}
				</div>
			</div>
		</SummaryCard>
	);
};

export { UsersCard, BEM_USERS_CARD_LENGTH, BEM_USERS_CARD_FOOTER };
