import React, { Ref } from "react";
import { FormGroup } from "@blueprintjs/core";
import { useField } from "formik";
import { FieldError } from "./FieldError";

export type FileUploadFieldProps = {
    fileRef: Ref<HTMLInputElement>;
    label?: string;
    required?: boolean;
    helperText?: string;
    name: string;
    errorFieldName?: string;
    accepts?: string;
};

function FileUploadField({
    fileRef,
    label,
    required = false,
    helperText,
    accepts,
    errorFieldName,
    ...props
}: FileUploadFieldProps) {
    const [field] = useField(props);

    return (
        <FormGroup
            label={label}
            labelFor={field.name}
            helperText={helperText}
            labelInfo={required ? "* " : ""}
        >
            <input
                aria-label={label}
                type="file"
                accept={accepts}
                ref={fileRef}
                {...field}
                value={undefined}
            />
            <br />
            <FieldError name={errorFieldName || field.name} />
        </FormGroup>
    );
}

export { FileUploadField };
