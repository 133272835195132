/** This module defines the priority filter React component.  The priority filter displays a set of buttons
 *      with the priorities that the user can filter on.
 *  @module */
import React, { useEffect } from 'react';
import { Icon, IconNames } from '@tir-ui/react-components';
import { Button } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';
import { PRIORITY, PRIORITY_COLORS, PRIORITY_INDEX, PRIORITY_TO_LABEL_MAP } from 'components/enums';
import { FILTER_NAME, useGlobalFilters } from 'utils/hooks';
import { FILTER_IN_USER_PREFS_KEY } from 'utils/services/UserPrefsTypes';
import { getFiltersFromUserPreference, saveFiltersToUserPreference } from 'utils/stores/UserPreferencesUtils';

/** the default filter values that should be displayed if there are no preferences. */
const DEFAULT_PRIORITY_FILTER_VALUES = [PRIORITY.CRITICAL, PRIORITY.HIGH];

/** This interface defines the properties passed into the priority filter React component.*/
export interface PriorityFilterProps {
    /** Additional CSS classes to apply on outermost DOM elem of this component */
    className?: string;
    /** Boolean to show the priority label next to filter */
    showLabel?: boolean;
    /** If this flag is true and global filters doens't have a value for priority
     * when this component is mounted, then it will be automatically set to the
     * default priority filter values */
    applyDefaults?: boolean;
    /** Optional list of priorities to be displayed */
    allowList?: PRIORITY[];
    /** Optional list of priorities to be hidden */
    blockList?: PRIORITY[];
    /** A key that can be used to decide which set of user preferences keys this
     * control's state should be stored under. Do not pass this value if you don't
     * want it to be stored. */
    userPrefsKey?: FILTER_IN_USER_PREFS_KEY;
}

/** Renders the priority filter view.
 *  @param props the properties passed in.
 *  @returns JSX with the priority filter component.*/
export function PriorityFilter ({
    className,
    showLabel = true,
    applyDefaults = true,
    allowList = Object.keys(PRIORITY).filter(p => p !== PRIORITY.UNKNOWN).map(p => PRIORITY[p]),
    blockList = [],
    userPrefsKey,
}: PriorityFilterProps): JSX.Element {
    const { filters, setFilter } = useGlobalFilters({
        listenOnlyTo: [FILTER_NAME.priority]
    });
    const currentPriorities:any = filters.priority ? (typeof filters.priority === "string" ? [filters.priority] : filters.priority) : [];
    // Build a map of the current priority filter values for easy access
    const currentPrioritesMap = currentPriorities.reduce((output, priority) => {
        output[priority] = true;
        return output;
    }, {});

    useEffect(() => {
        if (!filters[FILTER_NAME.priority] && userPrefsKey) {
            // Get filters stored in user preferences
            getFiltersFromUserPreference(userPrefsKey).then(filtersFromUserPrefs => {
                // If filters in user preferences had a priority filter
                const priorityValue = filtersFromUserPrefs[FILTER_NAME.priority];
                if (priorityValue) {
                    setFilter(FILTER_NAME.priority, priorityValue);
                // If user preferences did not have a saved priority filter value and applyDefaults flag was true
                } else if (applyDefaults) {
                    setFilter(FILTER_NAME.priority, DEFAULT_PRIORITY_FILTER_VALUES);
                }
            }).catch(() => {
                // If user preferences is not available and applyDefaults flag was true
                if (applyDefaults) {
                    setFilter(FILTER_NAME.priority, DEFAULT_PRIORITY_FILTER_VALUES);
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function togglePriority (priority: PRIORITY) {
        let updatedPrioritiesMap = Object.assign({}, currentPrioritesMap);
        if (currentPrioritesMap[priority]) {
            delete updatedPrioritiesMap[priority];
        } else {
            updatedPrioritiesMap[priority] = true;
        }
        if (Object.keys(updatedPrioritiesMap).length > 0) {
            const updatedFilters = setFilter(FILTER_NAME.priority, Object.keys(updatedPrioritiesMap));
            if (userPrefsKey) {
                // Save the updated filter to user preferences as the user has manually modified it
                saveFiltersToUserPreference(updatedFilters, undefined, userPrefsKey);
            }
        }
    }
    const blockListMap = blockList.reduce((output, priority) => {
        output[priority] = true;
        return output;
    }, {});

    return <div className={"priority-filters d-inline-flex flex-wrap" + (className ? " " + className : "")}>
        {showLabel && <span className="mb-1 mr-2 text-uppercase d-none d-sm-flex align-items-center">{STRINGS.incidentPriorities.label}:</span>}
        {
            allowList
                .filter(priority => !blockListMap[priority])
                .sort((a, b) => {
                    return PRIORITY_INDEX[b] - PRIORITY_INDEX[a];
                })
                .map(p => {
                    const priority = PRIORITY[p];
                    return priority ? <Button
                        key={"filter-priority-" + priority}
                        small
                        className="mr-2 mb-2 toggleable"
                        data-testid={"btn-" + priority}
                        active={Boolean(currentPrioritesMap[priority])}
                        onClick={e => togglePriority(priority)}
                        icon={<Icon
                            icon={IconNames.SYMBOL_SQUARE}
                            color={PRIORITY_COLORS[priority] || PRIORITY_COLORS.UNKNOWN}
                        />}>{PRIORITY_TO_LABEL_MAP[priority] || priority}</Button> :
                        undefined;
                })
        }
    </div>;
}
