/** This module contains the main ui React Component which is invoked in App.tsx
 *  @module
 */
import React, { useEffect, useState } from "react";
import { MainLayout } from "../layouts/main-layout/MainLayout";
import { LoadingOverlay } from "@tir-ui/react-components";
import { getRoutes } from "config";
import { SideMenu } from "components/app/layouts/side-menu/SideMenu";
//import { ConfigurationService } from 'services/ConfigurationService';
import { ErrorPage, ErrorProps } from "pages-unrouted/error/ErrorPage";
import { ThemeManager, ThemeContext, THEME } from "utils/themes";
import { init as globalTimeStoreInit } from "utils/stores/GlobalTimeStore";
import { init as globalEventMappingsStoreInit } from "utils/stores/GlobalEventMappingsStore";
//import { init as globalUnitsStoreInit } from "utils/stores/GlobalUnitsStore";
import { retrieveTenantFeatureFlags } from "utils/stores/FeatureFlagStore";
import { setDataOceanMetadata } from "utils/stores/GlobalUnitsStore";
import { init as globalDataSourceTypeStoreInit } from "utils/stores/GlobalDataSourceTypeStore";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";

// Task 6822: Disable Launch Darkly For Now
// import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
// import { useLDClient } from 'launchdarkly-react-client-sdk';

// const AuthService  = AuthServiceProvider.getService();

interface Props {
    shouldSkipGlobalDataStores?: boolean; 
}
/** Renders the main UI component under Tiramisu.  All post-authentication initializations are handled here
 *  @param props the properties passed in.
 *  @returns JSX with the main UI component under the Tiramisu components.*/
function Main({ shouldSkipGlobalDataStores = false } : Props): JSX.Element {
    // Task 6822: Disable Launch Darkly For Now
    // const ldClient = useLDClient();
    const ROUTES = getRoutes();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ErrorProps>();
    const [theme, setTheme] = useState<THEME>(THEME.light);

    useEffect(() => {
        //able to handle multiple parallel initializations in the future without code rewrite
        const initPromises: Promise<object | void>[] = [
            ThemeManager.initialize(),
        ];

        //initialize the configuration service
        //initPromises.push(ConfigurationService.init());

        // Initialize the global time store after the main component is mounted
        initPromises.push(globalTimeStoreInit());

        if (!shouldSkipGlobalDataStores) {
            // initialize feature flag retrieval
            initPromises.push(retrieveTenantFeatureFlags())

            // Initialize the global data source types store after the main component is mounted
            initPromises.push(globalDataSourceTypeStoreInit());

            // Initialize the global event mappings store after the main component is mounted
            initPromises.push(globalEventMappingsStoreInit());

            // Initialize the units store
            //initPromises.push(globalUnitsStoreInit());
            // I wanted to do it like above but a bunch of unit tests failed because the units store
            // referenced the DataOceanUtils, so do it this way now.
            initPromises.push(new Promise((resolve, reject) => {
                DataOceanUtils.init().then((data) => {
                    setDataOceanMetadata(data);
                    resolve(data);
                });
            }));
        }

        //verify all went well
        Promise.all(initPromises).then(
            () => {
                setTheme(ThemeManager.getTheme());
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(error);
            }
        );
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Task 6822: Disable Launch Darkly For Now
    // useEffect(() => {
    //     ldClient?.identify({
    //         key: AuthService.getUserAccount().getUsername().split('@')[0],
    //         name: AuthService.getUserAccount().getName(),
    //         email: AuthService.getUserAccount().getUsername()
    //     });
    // }, [ldClient]);

    if (loading) {
        return <LoadingOverlay visible={true} />;
    }

    if (error) {
        return <ErrorPage error={error} />;
    }

    function setNewTheme(theme: THEME) {
        ThemeManager.setTheme(theme);
        setTheme(ThemeManager.getTheme());
    };

    const routes = Object.assign({}, ROUTES);

    return (
        <ThemeContext.Provider value={{
            theme: theme,
            setTheme: setNewTheme
        }}>
                <MainLayout
                    routes={routes}
                    defaultRoute={ROUTES["impact-dashboard"]}
                    sideMenu={<SideMenu/>}
                />
        </ThemeContext.Provider>
    );
}

export { Main };
