/** This module contains the GraphqlService that is used to connect to DAL and run the 
 *  GraphQL queries
 *  @module
 */
import { DocumentNode, print } from 'graphql';
import { addTypenameToDocument } from '@apollo/client/utilities';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, createHttpLink, from } from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { setContext } from "@apollo/client/link/context";
import { sha256 } from 'crypto-hash';
import hashedQueries from '../../graphql/extracted_queries.json';
import { GRAPHQL_API_ENDPOINT } from './GraphqlService';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { ApiService } from './ApiService';

const authService = AuthServiceProvider.getService();
const httpLink = createHttpLink({
    uri: () => {
        if (ApiService.USE_REGION) {
            const region = authService.getRegion();
            return `/api/iq/${region}/dal/2.0/graphql`;    
        } else {
            return GRAPHQL_API_ENDPOINT;
        }
    }
});
const accountId:any = sessionStorage.getItem("accountId");
const authMiddleware = setContext((operation) => {
    const tenantId = authService.getTenantId();
    return {
        headers: {
            "client-name": "Affogato [web]",
            "client-version": "1.0.0",
            "accountId": accountId || 'accountId',
            "x-tenant-id": tenantId
        },
    };
    }
);


// Use this link chain for the automatic persisted queries
const linkChain = createPersistedQueryLink({ generateHash: generateHash }).concat(from([authMiddleware, httpLink]));

const embeddedClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: linkChain,
    cache: new InMemoryCache({
        typePolicies: {
            GenericValue: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            GenericIdValueOfString: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            GenericNameValueOfDouble: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            Dataset: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            PriorityReason: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            Query: {
                fields: {
                    summary: {
                        merge(existing, incoming) {
                            // Without this we get a warning about an issue merging the summary object.  The summary
                            // object has no id and should not be cached.  Adding the merge function is equivalent to what 
                            // happens if there is no custom merge function but causes the error to go away.
                            // Link: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
                            return incoming;
                        },
                    },
                },
            },
        }
    })
})

export { embeddedClient };

/** replaces the default generateHash in createPersistedQueryLink.  This version adds the typename to
 *      the query and then checks the extracted_queries.json file for the hash, if it exists it returns it,
 *      if it does not exist it generates a new hash (the hash is always the same).
 *  @param query the DocumentNode with the query.
 *  @returns a String or the promise that, when resolved, will return the sha256 hash.*/
function generateHash(query: DocumentNode): string | PromiseLike<string> {
    const queryWithType = addTypenameToDocument(query);
    const queryText = print(queryWithType);
    const hashedSha256 = (hashedQueries ? hashedQueries[queryText] : undefined);
    return (hashedSha256 ? hashedSha256 : sha256(queryText));
}
