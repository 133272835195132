import React from "react";
import "./WrapInTooltip.scss";

/** left-handside, right-handside */
type orientation = 'lhs' | 'rhs';

export interface WrapInTooltipProps {
    tooltip: React.ReactNode;
    children: React.ReactNode;
    hint?: React.ReactNode;
    orientation?: orientation;
};


/** A simple component to apply a simple lightweight tooltip around any children */
export function WrapInTooltip ({ tooltip, hint, orientation, children }: WrapInTooltipProps) {
    return <div className="simple-tooltip-target d-inline-block">
        {children}
        <div className="simple-tooltip position-absolute w-2">
            <div className={`simple-tooltip-content position-absolute display-9 p-2 bg-dark text-light text-center opacity-7 shadow-lg text-break ${orientation ? `${orientation}` : ''}`}>
                <div>{tooltip}</div>
                {hint && <div className="display-10 mt-1">{hint}</div>}
            </div>
        </div>
    </div>;
    // Using a CSS based tooltip as of now because Blueprint's tooltip
    // control is displaying unpredictable behavior in some edge cases
    // and is a bit heavier than a simple CSS based tooltip
    // return <Tooltip2
    //     disabled={disabled}
    //     popoverClassName="w-max-2"
    //     content={<div className="text-center">
    //             {ttText}
    //             {ttHint && <div className="display-10 mt-1">{ttHint}</div>}
    //         </div>}
    //     position="bottom"
    //     interactionKind={Popover2InteractionKind.HOVER}
    // >{children}</Tooltip2>
};
