/** This module contains the ThirdPartyIntegrationApiService that can be used to query the third party integration
 *      service.
 *  @module
 */
import { ApiKeyMethodTypeEnum } from 'pages/integrations/modals/ApiKeyAuthParamsPanel';
import { ApiService } from 'utils/services/ApiService';

/** The URL for the API server. */
export const DEFAULT_API_VERSION = '1.0'
export const INTEGRATIONS_BASE_PATH = `/api/affogato/thirdPartyIntegrations/`;

export enum AuthenticationMethodTypeEnum {
    'BasicAuthentication' = 0,
    'ApiKeyAuthentication' = 1,
    'OAuth2' = 2,
    'ClientCertificate' = 3,
    'awsSignature' = 4,
}

export type ClientCertificateSource = string | undefined;

export type OAuth2GrantType = 'ClientCredentials' | 'Password';
export interface ApiKeyInterface {
    apiKey?: string;
    passingMechanism?: ApiKeyMethodTypeEnum | undefined;
    queryStringParamName?: string,
    authenticationSchemeName?: string;
    isEncoded?: boolean;
    customHeaderName?: string;
    additionalRequestHeaders?: Array<{ name: string, key: string }>;
    tokenName?: string;
    clientCertificate?: {
        certificate?: string | undefined,
        privateKey?: string | undefined,
        passPhrase?: string | undefined
    }
}

export interface BasicAuthInterface {
    username?: string | undefined,
    password?: string | undefined
}

interface OptionalParameter {
    key: string;
    value: string;
    sendVia: string;
}

export interface OAuth2Interface {
    grantType?: OAuth2GrantType | undefined; 
    userName?: string | undefined;
    password?: string | undefined;
    clientId?: string;
    clientSecret?: string;
    serverUri?: string;
    scope?: string;
    optionalParameters?: OptionalParameter[];
    clientCertificate?: {
        certificate?: string | undefined,
        privateKey?: string | undefined,
        passPhrase?: string | undefined
    }
}

export interface ClientCertificateUsingPemFormatInterface {
    certificate?: string | undefined;
    privateKey?: string | undefined;
    passPhrase?: string | undefined;
}

export interface AWSsignatureInterface {
    awsSignatureVersion?: string | undefined;
    accessKeyId?: string | undefined;
    secretAccessKey?: string | undefined;
    // regionName?: string | undefined; 
    // serviceName?: string | undefined;
}

export interface ProfileInterface {
    id?: string,
    name: string,
    description: string,
    eTag?: string,
    authenticationMethod: AuthenticationMethodTypeEnum | undefined,
    lastUpdateBy?: string,
    lastUpdateOn?: string,
    createdBy?: string,
    createdOn?: string,
    lastUpdatedBy?: string,
    lastUpdatedOn?: string,
    isVerified?: boolean,
    lastVerifiedOn?: number,
    lastVerifiedBy?: string,
    isEnabled?: boolean,
    sensitiveData?: BasicAuthInterface | ApiKeyInterface | OAuth2Interface | ClientCertificateUsingPemFormatInterface | AWSsignatureInterface | null | undefined;
    verifyUrl?: string,
    verifyHttpMethod?: string,
    verifyRequestBody?: string,
    verifyRequestHeaders?: any,
    runbooks?: string[],
    edgeDeviceIds?: string[];
}

/** this class defines the Third Party Integration API Service. */
class ThirdPartyIntegrationApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        const baseUri = INTEGRATIONS_BASE_PATH;
        super(baseUri)
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(version?: string): string {
        const apiVersion = version || DEFAULT_API_VERSION;

        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/thirdPartyIntegrations/${apiVersion}/`;
        } else {
            return `${this.baseApiUri}/${apiVersion}/`;
        }
    }

    /** returns all third party authentication profiles without the sensitive data.
     *  @returns a Promise which resolves to the returned authentication profiles.*/
    getRunbookAndIntegrationAuthProfiles(): Promise<ProfileInterface[]> {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.get<ProfileInterface[]>(`tenants/${tenantId}/profiles?profileType=Runbook&profileType=PredefinedIntegration`).then((results: ProfileInterface[] = []) => {
                resolve(results);
            }, err => {
                reject(err);
            })
        });
    }

    /** returns all third party authentication profiles without the sensitive data.
     *  @returns a Promise which resolves to the returned authentication profiles.*/
    getRunbookAuthProfiles(): Promise<ProfileInterface[]> {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.get<ProfileInterface[]>(`tenants/${tenantId}/profiles?profileType=Runbook`).then((results: ProfileInterface[] = []) => {
                resolve(results);
            }, err => {
                reject(err);
            })
        });
    }


    /** returns the third party authentication profile with the specified id.
     *  @param id a String with the id of the profile.
     *  @returns a Promise which resolves to the returned authentication profile.*/
     getAuthProfile(id: string): Promise<ProfileInterface> {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.getWithVersion<ProfileInterface>(`${this.getBaseUri('2.0')}tenants/${tenantId}/profiles/${id}`).then((result: ProfileInterface) => {
                resolve(result);
            }, err => {
                reject(err);
            })
        });
    }

    /** saves a new authentication profile.
     *  @param profile the new profile to be saved.
     *  @returns a Promise which resolves to the api response.*/
    saveAuthProfile(profile: ProfileInterface) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.post(`tenants/${tenantId}/profiles`, profile).then((result) => {
                resolve(result);
            }, err => {
                reject(err);
            })
        });
    }

    /** updates an existing authentication profile.
     *  @param id a String with the id of the profile.
     *  @param profile the existing profile to be saved.
     *  @returns a Promise which resolves to the api response.*/
    async updateAuthProfile(id: string, profile: ProfileInterface) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        const etag = profile.eTag || null;
        const headersWithEtag = await super.headers(false, etag);
        return new Promise((resolve, reject) => {
            super.put(`tenants/${tenantId}/profiles/${id}`, profile, false, {headers: headersWithEtag}).then((result) => {
                resolve(result);
            }, err => {
                reject(err);
            })
        });
    }

    /** deletes an existing authentication profile.
     *  @param id a String with the id of the profile.
     *  @param etag the etag for that profile.
     *  @returns a Promise which resolves to the api response.*/
    async deleteAuthProfile(id: string, etag: string) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        const headersWithEtag = await super.headers(false, etag);
        return new Promise((resolve, reject) => {
            super.delete(`tenants/${tenantId}/profiles/${id}`, false, {headers: headersWithEtag}).then((result) => { // async
                resolve(result);
            }, err => {
                reject(err);
            })
        });
    }

    /** disable an existing authentication profile.
     *  @param id a String with the id of the profile.
     *  @param etag the etag for that profile.
     *  @returns a Promise which resolves to the api response.*/
     async disableAuthProfile(id: string, etag: string) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        const headersWithEtag = await super.headers(false, etag);
        return new Promise((resolve, reject) => {
            super.put(`tenants/${tenantId}/profiles/${id}/toggleEnabledState`, {} ,false, {headers: headersWithEtag}).then((result) => {
                resolve(result);
            }, err => {
                reject(err);
            })
        });
    }

    /** verify a auth profile by sending a request
     *  @param body the request data and profile authentication info.
     *  @returns a Promise which resolves to the api response.*/
    verifyAuthProfile(body: any) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.post(`tenants/${tenantId}/profiles/verifyDetails`, body, false).then((result) => {
                resolve(result);
            }, err => {
                reject(err);
            })
        });
    }

    /** verify a auth profile by sending a request
     *  @param {string} profileId the id of the profiile
     *  @param body the request data and profile authentication info.
     *  @returns a Promise which resolves to the api response.*/
    verifyInstalledAuthProfile(profileId, body: any) {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.post(`tenants/${tenantId}/profiles/${profileId}/verifyDetails`, body, false).then((result) => {
                resolve(result);
            }, err => {
                reject(err);
            })
        });
    }
}

const ThirdPartyIntegrationService = new ThirdPartyIntegrationApiService();
export { ThirdPartyIntegrationService };
  