/** This file defines the various severity enums and constants that are used in many
 *  of the sdwan views.
 *  @module */
import { STRINGS } from "app-strings"

/** an enum which defines all the valid severity value. */
export enum SEVERITY {
    NORMAL          = "NORMAL",
    DEGRADED        = "DEGRADED",
    CRITICAL        = "CRITICAL",
    INITIALIZING    = "INITIALIZING",
    UNKNOWN         = "UNKNOWN"
}

/** A map of index values for severities to be used when sorting based on severity. */
export const SEVERITY_INDEX = {
    [SEVERITY.UNKNOWN]:         0,
    [SEVERITY.INITIALIZING]:    1,
    [SEVERITY.NORMAL]:          2,
    [SEVERITY.DEGRADED]:        3,
    [SEVERITY.CRITICAL]:        4
}

/** Reverse lookup of severity from severity index value. */
export const INDEX_TO_SEVERITY_MAP = {
    0: SEVERITY.UNKNOWN,
    1: SEVERITY.INITIALIZING,
    2: SEVERITY.NORMAL,
    3: SEVERITY.DEGRADED,
    4: SEVERITY.CRITICAL,
}

/** a constant which maps the severity enum value to a label. */
export const SEVERITY_TO_LABEL_MAP = {
    [SEVERITY.NORMAL]:          STRINGS.Normal,
    [SEVERITY.DEGRADED]:        STRINGS.Degraded,
    [SEVERITY.CRITICAL]:        STRINGS.Critical,
    [SEVERITY.INITIALIZING]:    STRINGS.Initializing,
    [SEVERITY.UNKNOWN]:         STRINGS.Unknown,
}

/** returns whether or not the specified object is a severity value.
 *  @param severity the value to test.
 *  @returns a boolean value, true if the specified severity is in the severity
 *      enum, false otherwise. */
export function isSeverity(severity: any): boolean {
    const severityValues: Array<string> = Object.values(SEVERITY);
    return severityValues.includes(severity);
}
