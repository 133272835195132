/** This file defines the liquid template editor React component.  The liquid template
 *  editor component is a simple text area with a toolbar that allows you to insert variables
 *  into the text area.
 *  @module */
import React, { useState, useRef, useEffect } from "react";
import { LiquidTemplateToolbar } from "components/common/graph/editors/transform/LiquidTemplateToolbar";
import { GenericKey } from "utils/runbooks/NodeUtil";
import { Liquid } from "liquidjs";
import { Variant } from "../../types/GraphTypes";

/** This interface defines the properties that are passed into the liquid template editor. */
export interface LiquidTemplateEditorProps {
    /** the runbook variant that being edited. */
    variant: Variant;
    /** the runtime, incident, and/or global variables. */
    variables: any;
    /** the list of expanded keys for the trigger. */
    triggerExpandedKeys: GenericKey[];
    /** the list of metrics for the trigger. */
    triggerMetrics?: GenericKey[];
    /** the list of expanded keys for the parent. */
    parentExpandedKeys: GenericKey[];
    /** the list of metrics for the parent. */
    parentMetrics?: GenericKey[];
    /** the handler for the variables selection. */
    onChange: (value: string) => void;
    /** the value of the template */
    value?: string;
    /** the placeholder for the text area. */
    placeholder: string;
    /** the style to be applied to the text area. */
    style?: any;
    /** the class to be applied to the text area. */
    className?: string;
}

/** Component for editing the liquid template.
 *  @param props the properties passed into the React component.
 *  @returns a JSX component with the liquid template editor. */
export const LiquidTemplateEditor = (props: LiquidTemplateEditorProps): JSX.Element => {
    const selectionEnd = useRef<number>(0);
    const [value, setValue] = useState<string>(props.value || "");
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(
        () => {
            const firstValue = props.value || "";
            const engine = new Liquid();
            engine.parseAndRender(firstValue, {}).then(
                (data) => {
                    //setError(undefined);
                }, (error) => {
                    setError(error);
                }
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(
        () => {
            if (value !== props.value) {
                // The value has changed between renders
                const newValue = props.value || "";
                setValue(newValue);
                const engine = new Liquid();
                engine.parseAndRender(newValue, {}).then(
                    (data) => {
                        setError(undefined);
                    }, (error) => {
                        setError(error);
                    }
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.value]
    )

    const style = props.style ? {...props.style} : {};
    //if (error) {
    //    style.color = "red";
    //}

    return <>
        <LiquidTemplateToolbar 
            triggerExpandedKeys={props.triggerExpandedKeys} triggerMetrics={props.triggerMetrics} 
            parentExpandedKeys={props.parentExpandedKeys} parentMetrics={props.parentMetrics} variables={props.variables} 
            handleVariableSelection={(varText) => {
                let newValue = value || "";
                if (selectionEnd.current >= 0 && selectionEnd.current <= newValue.length) {
                    newValue = newValue.substring(0, selectionEnd.current) + varText + newValue.substring(selectionEnd.current, newValue.length);
                    setValue(newValue);
                    props.onChange(newValue);
                }
            }}
            variant={props.variant}
        />
        <textarea
            data-testid="template"
            // We had this on several of the text areas which was nice but it messes up the unit tests
            //autoFocus
            placeholder={props.placeholder}
            value={value}
            style={style}
            className={props.className + (error ? " text-danger" : "")}
            onChange={e => {
                const newValue  = e.currentTarget.value;
                setValue(newValue);
                props.onChange(newValue);

                const engine = new Liquid();
                engine.parseAndRender(newValue, {name: 'alice'}).then(
                    (data) => {
                        setError(undefined);
                    }, (error) => {
                        setError(error);
                    }
                );
            }}
            onSelect={(event) => {
                const end = event.currentTarget.selectionEnd;
                selectionEnd.current = end >= 0 && end < Number.MAX_VALUE ? end : 0;
            }}
        />
        <textarea 
            style={{ resize: "both", width: "100%", height: "40px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
            className="bg-white text-black mt-3"
            disabled={true}
            value={error || ""} 
        />
    </>;
};
