import React from "react";
import { Dialog, Classes, Button } from "@blueprintjs/core";
import "./PaywallDialog.scss";
import { STRINGS } from "app-strings";
import { getURLPath } from "config";
import alluvioBackground from '../../../../assets/images/layout/alluvio_gradient.svg';
export interface PaywallDialogProps {
    subscriptionStatus: string
}

export function PaywallDialog(props: PaywallDialogProps): JSX.Element {
    return (
        <Dialog
            className={"paywall-background"}
            style={{backgroundImage: `url(${alluvioBackground})`}}
            isOpen={props.subscriptionStatus !== STRINGS.PAYWALL.status.active}
            autoFocus={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus={true}
            usePortal={true}
            portalClassName={"paywall-portal"}
        >
            <div className={Classes.DIALOG_BODY}>
                {props.subscriptionStatus === STRINGS.PAYWALL.status.inactive && <p>{STRINGS.PAYWALL.inactiveText}</p>}
                {props.subscriptionStatus === STRINGS.PAYWALL.status.cancelled && 
                    <>
                        <h1>{STRINGS.PAYWALL.cancelledHeader}</h1>
                        <p>{STRINGS.PAYWALL.cancelledText}</p>
                    </>
                }
            </div>
            <div>
                <a href={getURLPath("support")} className="text-decoration-none" target="_blank" rel="noreferrer">
                    <Button className={"paywall-button"}>{STRINGS.PAYWALL.contactUs}</Button>
                </a>
            </div>
        </Dialog>)
}