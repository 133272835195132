/** This module contains the enums and constants for the entity kinds.
 *  @module
 */
import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { itemType } from "../common/select/SelectInput";

/** an enum which defines all the valid entity kind values. */
export enum ENTITY_KIND {
    INTERFACE               = "network_interface",
    DEVICE                  = "network_device",
    APPLICATION             = "application",
    APPLICATION_SERVER      = "application_server",
    APPLICATION_LOCATION    = "application_location",
    NETWORK_SERVER          = "network_server",
    LOCATION                = "location",
    APPLICATION_ACTIVITY    = "application_activity",
};

/** A map of index values for entity kinds to be used when sorting. */
export const ENTITY_KIND_INDEX = {
    [ENTITY_KIND.INTERFACE]:            1,
    [ENTITY_KIND.DEVICE]:               2,
    [ENTITY_KIND.APPLICATION]:          3,
    [ENTITY_KIND.APPLICATION_SERVER]:   4,
    [ENTITY_KIND.APPLICATION_LOCATION]: 5,
    [ENTITY_KIND.NETWORK_SERVER]:       6,
    [ENTITY_KIND.LOCATION]:             7,
    [ENTITY_KIND.APPLICATION_ACTIVITY]: 8,
}

/** a constant which maps the entity kind enum value to a label. */
export const ENTITY_KIND_TO_LABEL_MAP: Record<string, string> = {
    [ENTITY_KIND.INTERFACE]:            STRINGS.entityKinds.networkInterface,
    [ENTITY_KIND.DEVICE]:               STRINGS.entityKinds.networkDevice,
    [ENTITY_KIND.APPLICATION]:          STRINGS.entityKinds.application,
    [ENTITY_KIND.APPLICATION_SERVER]:   STRINGS.entityKinds.applicationServer,
    [ENTITY_KIND.APPLICATION_LOCATION]: STRINGS.entityKinds.applicationLocation,
    [ENTITY_KIND.NETWORK_SERVER]:       STRINGS.entityKinds.networkServer,
    [ENTITY_KIND.LOCATION]:             STRINGS.entityKinds.location,
    [ENTITY_KIND.APPLICATION_ACTIVITY]: STRINGS.entityKinds.applicationActivity
}

/** a constant which maps the entity kind enum values to it's matching icon. */
export const ENTITY_KIND_TO_ICON_MAP: Record<string, string> = {
    [ENTITY_KIND.INTERFACE]:            IconNames.MERGE_LINKS,
    [ENTITY_KIND.DEVICE]:               IconNames.DEVICES,
    [ENTITY_KIND.APPLICATION]:          IconNames.APPLICATION,
    [ENTITY_KIND.APPLICATION_SERVER]:   IconNames.APPS,
    [ENTITY_KIND.APPLICATION_LOCATION]: IconNames.SITE,
    [ENTITY_KIND.NETWORK_SERVER]:       IconNames.APPS,
    [ENTITY_KIND.LOCATION]:             IconNames.GLOBE,
    [ENTITY_KIND.APPLICATION_ACTIVITY]: IconNames.APPLICATION,
}

/** returns a list of label value pair of entity kinds
 *  @returns the Array of items with the entity kinds. */
export const getEntityKindItemsListForDropdown = (): Array<itemType> => {
    const itemsList = new Array<itemType>();

    Object.keys(ENTITY_KIND).forEach( p => {
        const label = ENTITY_KIND_TO_LABEL_MAP[ENTITY_KIND[p]] || "";
        itemsList.push({
            value: p,
            label: label
        });
    })
    return itemsList;
};
