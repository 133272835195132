/** This file defines the RunbookContextUtils class.  The RunbookContextUtils class contains several utilities
 *  used by the RunbookContext class.  We had to move this into a separate file because there were import statements
 *  that conflicted with each other and the UI would not load.
 *  @module */

import { DataOceanKey, DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { dataSourceTypeOptions } from "utils/services/DataSourceApiService";

/** these variables get the environment and help URI from the runtime config. */
let { ENV } = window["runConfig"] || {};
export function setEnv(env: string): void  {
    ENV = env;
}

/** The class houses all utilities for the RunbookContext that could not be put in the RunbookContext class. */
export class RunbookContextUtils {
    /** returns the array of supported keys for the specified object type and the specified data sources.
     *  @param objType the DataOceanObjectType to check.
     *  @param displayedExpandedKeys any expanded keys currently displayed, these will be listed as available even if they are not supported.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @param dataOceanMetaData the DataOceanMetadata object with all the information on the data ocean keys and metrics.
     *  @returns an array of key names that are supported by the data sources. */
    public static getAvailableKeysForObjectType(
        objType: string, displayedExpandedKeys: string[], availableDataSources: dataSourceTypeOptions[], dataOceanMetaData: DataOceanMetadata
    ): string[] {
        const outputKeys: string[] = [];
        const keys: string[] = dataOceanMetaData.obj_types[objType].keys;
        for (const key of keys) {
            const keyObj: DataOceanKey | undefined = RunbookContextUtils.getAvailableKeys(
                dataOceanMetaData.keys[key], displayedExpandedKeys, availableDataSources
            );
            if (keyObj) {
                outputKeys.push(key);
            }
        }
        return outputKeys; 
    }

    /** returns the map of supported keys for the specified key map and the specified data sources.
     *  @param keyMap the map of keys to filter based on the data sources.
     *  @param displayedExpandedKeys any expanded keys currently displayed, these will be listed as available even if they are not supported.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @returns the map of supported keys where the map is a map of key name to DataOceanKey object. */
    public static getAvailableKeysForKeyMap(
        keyMap: Record<string, DataOceanKey>, displayedExpandedKeys: string[], availableDataSources: dataSourceTypeOptions[]
    ): Record<string, DataOceanKey> {
        const newKeyMap: Record<string, DataOceanKey> = {};
        if (keyMap && Object.keys(keyMap).length) {
            for (const key in keyMap) {
                const keyObj: DataOceanKey | undefined = RunbookContextUtils.getAvailableKeys(keyMap[key], displayedExpandedKeys, availableDataSources);
                if (keyObj) {
                    newKeyMap[key] = keyObj;
                }
            }
        }
        return newKeyMap;
    }

    /** returns whether or not the specified key supports the specified data sources.
     *  @param key the DataOceanKey to check.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @returns a boolean value, true if the specfied key supports the specified data sources. */
    public static keySupportsDataSources(key: DataOceanKey, availableDataSources: dataSourceTypeOptions[]): boolean {
        if (key.supported_data_sources?.length && availableDataSources?.length) {
            for (const dataSource of availableDataSources) {
                if (key.supported_data_sources.includes(dataSource)) {
                    return true;
                }
            }

        }
        return key.supported_data_sources?.length ? false : true;
    }

    /** returns the DataOceanKey for the specified keyDef and the specified data sources.
     *  @param keyDef the DataOceanKey object with the current key and its subkeys.
     *  @param displayedExpandedKeys any expanded keys currently displayed, these will be listed as available even if they are not supported.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @returns the data ocean key with its filtered sub keys or undefined if the key is not supported by the data sources or it has no
     *      sub keys. */
    public static getAvailableKeys(
        keyDef: DataOceanKey, displayedExpandedKeys: string[], availableDataSources: dataSourceTypeOptions[]
    ): DataOceanKey | undefined {
        if (keyDef) {
            let supportedKeys: DataOceanKey | undefined = undefined;
            const supportedEnvs = keyDef.supported_envs || ["dev", "staging", "prod"];
            if (keyDef && (
                (
                    RunbookContextUtils.keySupportsDataSources(keyDef, availableDataSources) && supportedEnvs.includes(ENV) && 
                    !keyDef.deprecated
                ) 
                //|| displayedMetrics.includes(keyDef.id)
            )) {
                supportedKeys = {...keyDef, properties: {}};
                if (keyDef?.properties) {
                    for (const subKey in keyDef.properties) {
                        const subKeyDef = RunbookContextUtils.getAvailableKeys(keyDef.properties[subKey], displayedExpandedKeys, availableDataSources);
                        if (subKeyDef) {
                            supportedKeys.properties![subKey] = subKeyDef;
                        }
                    }
                }
                if (Object.keys(supportedKeys.properties!).length === 0) {
                    delete supportedKeys.properties;
                } 
            }
            return supportedKeys;
        }
        return undefined; 
    }    
}
