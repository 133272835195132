/** This module contains the functional React component for rendering a data ocean limit.
 *  @module
 */
import React from 'react';
import { HTMLSelect, NumericInput } from "@blueprintjs/core";
import { HELP, STRINGS } from "app-strings";
import { DataOceanUtils } from "./DataOceanUtils";
import { DataOceanDuration } from './DataOceanDuration';
import { InlineHelp } from 'components/common/layout/inline-help/InlineHelp';
import { DataOceanMetric, DataOceanObjectType } from './DataOceanMetadata.type';
import { getTypes } from 'utils/stores/GlobalDataSourceTypeStore';

/** This interface defines the properties passed into the data ocean limit React component.*/
export interface DataOceanLimitProps {
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties: any;
    /** an object with the configuration for this data ocean's object type. */
    selectedObjectType: DataOceanObjectType;
    /** a string with entity label if any. */
    entityLabel?: string;
    /** a boolean value, if true, the duration control should be hidden because it will be displayed elsewhere. */
    hideDurationControl?: boolean;
    /** a callback function that should be called when the top by metric is set. */
    setTopByMetric: (id: string) => void;
    /** the handler for the duration change event. */
    onDurationChanged: () => void;
}

/** Renders the component to render the data ocean limit field.
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean limit component.*/
 export function DataOceanLimit (props: DataOceanLimitProps) {
    const { currentProperties, selectedObjectType, entityLabel, hideDurationControl = false, setTopByMetric, onDurationChanged } = props;
    const showLimit = true;

    const supportedSelectedTypeMetrics: DataOceanMetric[] = DataOceanUtils.getAvailableMetrics(
        selectedObjectType.metrics || [], currentProperties['topBy'] ? [currentProperties['topBy'][0].id] : [], getTypes(), true,
        currentProperties["timeSeries"] ? "time_series" : "summary"
    );
    const typeMetrics = DataOceanUtils.getMetricsDisplayData(supportedSelectedTypeMetrics.map(metric => metric.id));

    return (
        showLimit ?
        <>
            <tr><td className="display-7 font-weight-bold py-2" colSpan={2}>
                <InlineHelp 
                    helpMapping={HELP.RunbookNodeCategory.Dataquery[currentProperties.objType.replace('.', '_')]?.limit}>
                    {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.limitsSection}
                </InlineHelp>
            </td></tr>
            <tr>
                <td>
                    <label className="mb-0 mr-2">{ STRINGS.runbookEditor.nodeLibrary.propertyLabels.top }</label>
                    <NumericInput
                        data-testid="data_ocean_limit"
                        className="d-inline-block"
                        defaultValue={ currentProperties["limit"] }
                        name="limit"
                        style={ { width: "70px" } }
                        buttonPosition="none"
                        onBlur={ e => {
                            if (e.target.value) {
                                e.target.value = parseInt(e.target.value).toString()
                            }
                            currentProperties['limit'] = e.target.value;
                        } }
                    />
                    { entityLabel ? <span className="ml-2">{entityLabel}</span> : "" }
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <label className="mb-0 pb-1">{ STRINGS.runbookEditor.nodeLibrary.propertyLabels.by }</label><br />
                    <HTMLSelect
                        data-testid="data_ocean_top"
                        name="topBy.id"
                        fill={ true }
                        options={ typeMetrics }
                        defaultValue={ 
                            currentProperties['topBy'] ? currentProperties['topBy'][0].id : 
                                supportedSelectedTypeMetrics?.length ? supportedSelectedTypeMetrics[0].id : undefined
                        }
                        onChange={ e => {
                            const topBy = currentProperties['topBy'];
                            topBy[0].id = e.target.value;
                            const defaultSort = DataOceanUtils?.dataOceanMetaData?.metrics[topBy[0].id]?.order_by_direction || "desc";
                            topBy[0].direction = defaultSort;
                            setTopByMetric(e.target.value);
                        } }
                    />
                </td>
            </tr>
            {
                !hideDurationControl &&
                <DataOceanDuration
                    label={STRINGS.runbookEditor.nodeEditor.durationUnderTopBy}
                    currentProperties={currentProperties} onDurationChanged={onDurationChanged}
                />
            }
        </> :
        null
    );
};
