/** This file defines the various constants that are used to centralized the color 
 *  scheme used for the charts and some of the classes used in many of the React
 *  components.
 *  @module */

import  * as PRIORITY  from './Priority';
import { SEVERITY } from './Severity';

/** a constant which defines the status colors. */
export const STATUS_COLORS: Record<string, string> = {
    [SEVERITY.NORMAL]:      "#00DB7E",
    [SEVERITY.DEGRADED]:    "#FBB700",
    [SEVERITY.CRITICAL]:    "#E04B4B",
    [SEVERITY.UNKNOWN]:     "#999",
};

/** a constant which defines the darker status colors. */
export const STATUS_COLORS_DARKER: Record<string, string> = {
    [SEVERITY.NORMAL]:      "#11c494",
    [SEVERITY.DEGRADED]:    "#F3B100",
    [SEVERITY.CRITICAL]:    "#CB3030",
    [SEVERITY.UNKNOWN]:     "#b9b9b9"
};

/** a constant which defines the class specification for each of the severity values. */
export const SEVERITY_CLASS: Record<string, Record<string, string>> = {
    [SEVERITY.NORMAL]:      { text: "text-normal", bg: "bg-normal", border: "border-normal" },
    [SEVERITY.DEGRADED]:    { text: "text-degraded", bg: "bg-degraded", border: "border-degraded" },
    [SEVERITY.CRITICAL]:    { text: "text-critical", bg: "bg-critical", border: "border-critical" },
    [SEVERITY.UNKNOWN]:     { text: "text-unknown", bg: "bg-unknown", border: "border-unknown"}
};

/** a constant which defines the priority colors. */
export const PRIORITY_COLORS: Record<string, string> = PRIORITY.COLORS;

/** a constant which defines the class specification for each of the priority values. */
export const PRIORITY_CLASS: Record<string, Record<string, string>> = PRIORITY.CSS_CLASS;

/** this is the color list that is used in the deployment summary dashboard. */
export const NODE_COLOR_LIST: Array<string> = ["#BBB", "#00876c", "#e5835e", "#75aa7c", "#efbd88", "#bdcd9b", "#fcf1c8", "#d43d51"];

export const CHART_COLORS: Record<string, string> = {
    THRESHOLD_DEFAULT: "#818995",
    TITLE_DEFAULT: "#818995",
    LEGEND_DEFAULT: "#818995",
    LABEL_DEFAULT: "#818995",
    DATA_DEFAULT: "#111111",
    TITLE_DARKMODE: "#CCC",
    LEGEND_DARKMODE: "#CCC",
    LABEL_DARKMODE: "#CCC",
    DATA_DARKMODE: "#F9F9F9",
}
export const BULLET_COLORS= {
    DEFAULT:  "#1874CE"
}

/** this array contains the default chart series colors.  This array should be used in all charts except for
 *  those that might need a custom color palette. */
export const CHART_SERIES_COLORS: Array<string> = ["#624ba5", "#00BFFF", "#007FFF", "#579add", "#008E97","#82e3cd", "#6e70a5", "#77b2ce"];

/** a constant which defines some general colors that can be used throughout the application. */
export const GENERAL_COLORS: Record<string, string> = {
    success:    "#28a745",
    warning:    "#FBB700",
    error:      "#E04B4B",
};

export const FACET_FILTER_COLORS: Record<string, string> = {
    "incident/duration": "#624ba5",
    "incidents/isOngoing": "#00BFFF",
    "COMPLETION_STATUS": "#007FFF",
    "incident/status": "#579add",
    "incidents/status": "#008E97",
    "STATUS": "#6fc29f",
    "incident/priority": "#6e70a5",
    "incidents/priority": "#77b2ce",
    "incident/eventCategory": "#624ba5",
    "incidents/eventCategory": "#00BFFF",
    "EVENT_CATEGORY": "#007FFF",
    "incident/triggeredOn/kind": "#579add",
    "incident/triggeredOn/network_device/name": "#008E97",
    "incident/triggeredOn/network_device/ipaddr": "#6fc29f",
    "incident/triggeredOn/network_device/location/name": "#6e70a5",
    "incident/triggeredOn/network_interface/name": "#77b2ce",
    "incident/triggeredOn/network_interface/ipaddr": "#624ba5",
    "incident/triggeredOn/network_interface/ifindex": "#00BFFF",
    "incident/triggeredOn/network_interface/location/name": "#007FFF",
    "incident/triggeredOn/application_location/application/name": "#579add",
    "incident/triggeredOn/application_location/location/name": "#008E97",
    "incident/correlatedIndicators/kind": "#6fc29f",
    "incident/correlatedIndicators/network_device/name": "#6e70a5",
    "incident/correlatedIndicators/network_device/ipaddr": "#77b2ce",
    "incident/correlatedIndicators/network_device/location/name": "#624ba5",
    "incident/correlatedIndicators/network_interface/name": "#00BFFF",
    "incident/correlatedIndicators/network_interface/ipaddr": "#007FFF",
    "incident/correlatedIndicators/network_interface/ifindex": "#579add",
    "incident/correlatedIndicators/network_interface/location/name": "#008E97",
    "incident/correlatedIndicators/application_location/application/name": "#6fc29f",
    "incident/correlatedIndicators/application_location/location/name": "#6e70a5",
    "incident/indicators/entity/kind": "#77b2ce",
    "incident/indicators/entity/network_device/name": "#624ba5",
    "incidents/incidentIndicators/indicator/networkDevice/name": "#00BFFF",
    "DEVICE_NAME": "#007FFF",
    "incident/indicators/entity/network_device/ipaddr": "#579add",
    "incidents/incidentIndicators/indicator/networkDevice/ipaddr": "#008E97",
    "DEVICE_IP_ADDRESS": "#6fc29f",
    "incident/indicators/entity/network_device/hostname": "#6e70a5",
    "incident/indicators/entity/network_device/location/name": "#77b2ce",
    "incidents/incidentIndicators/indicator/networkDevice/location/name": "#624ba5",
    "DEVICE_LOCATION_NAME": "#00BFFF",
    "incident/indicators/entity/network_interface/name": "#007FFF",
    "incidents/incidentIndicators/indicator/networkInterface/name": "#579add",
    "INTERFACE_NAME": "#008E97",
    "incident/indicators/entity/network_interface/ipaddr": "#6fc29f",
    "incidents/incidentIndicators/indicator/networkInterface/ipaddr": "#6e70a5",
    "INTERFACE_IP_ADDRESS": "#77b2ce",
    "incident/indicators/entity/network_interface/ifindex": "#624ba5",
    "incident/indicators/entity/network_interface/location/name": "#00BFFF",
    "incidents/incidentIndicators/indicator/networkInterface/location/name": "#007FFF",
    "INTERFACE_LOCATION_NAME": "#579add",
    "incident/indicators/entity/application_location/application/name": "#008E97",
    "incidents/incidentIndicators/indicator/applicationLocation/application/name": "#6fc29f",
    "APPLICATION_NAME": "#6e70a5",
    "incident/indicators/entity/application_location/location/name": "#77b2ce",
    "incidents/incidentIndicators/indicator/applicationLocation/location/name": "#624ba5",
    "APPLICATION_LOCATION_NAME": "#00BFFF",
    "incident/impactedLocations": "#007FFF",
    "incidents/impactedLocations/name": "#579add",
    "IMPACTED_LOCATION": "#008E97",
    "incident/impactedApplications": "#6fc29f",
    "incidents/impactedApplications/name": "#6e70a5",
    "IMPACTED_APPLICATION": "#77b2ce",
    "incident/impactedUsers": "#624ba5",
    "incidents/impactedUsers/ipaddr": "#00BFFF",
    "IMPACTED_USER_IP_ADDRESS": "#007FFF",
    "incidents/impactedUsers/name": "#579add",
    "IMPACTED_USER_NAME": "#008E97",
    "network_device/name": "#6fc29f",
    "network_device/ipaddr": "#6e70a5",
    "network_device/location/name": "#77b2ce",
    "networkDevices/name": "#624ba5",
    "networkDevices/ipaddr": "#00BFFF",
    "networkDevices/location/name": "#007FFF",
    "networkDevices/hostname": "#579add",
    "NAME": "#008E97",
    "IP_ADDRESS": "#6fc29f",
    "HOSTNAME": "#6e70a5",
    "networkDevices/type": "#77b2ce",
    "network_interface/name": "#624ba5",
    "network_interface/ipaddr": "#00BFFF",
    "network_interface/ifindex": "#007FFF",
    "IF_INDEX": "#579add",
    "network_interface/location/name": "#008E97",
    "LOCATION_NAME": "#6fc29f",
    "networkInterfaces/name": "#6e70a5",
    "networkInterfaces/ipaddr": "#77b2ce",
    "networkInterfaces/ifindex": "#624ba5",
    "networkInterfaces/location/name": "#00BFFF",
    "application/name": "#007FFF",
    "applications/name": "#579add",
    "location/name": "#008E97",
    "locations/name": "#6fc29f",
    "locations/type": "#6e70a5",
    "TYPE": "#77b2ce",
    "locations/city": "#624ba5",
    "CITY": "#00BFFF",
    "locations/state": "#007FFF",
    "STATE": "#579add",
    "locations/country": "#008E97",
    "COUNTRY": "#6fc29f",
    "customProperties/name": "#6e70a5",
    "customProperties/description": "#77b2ce",
    "DESCRIPTION": "#624ba5",
    "customProperties/customPropertyValues/name": "#00BFFF",
    "customProperties/userId": "#007FFF",
    "customProperties/customPropertyAssociations/type": "#579add",
    "VALUE_NAME": "#008E97",
    "ENTITY_KIND": "#6fc29f",
};



