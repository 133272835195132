import React from 'react';
// Uncomment to demo the geomap
//import { SEVERITY, STATUS_COLORS } from 'components/enums';
//import { Layer, Source } from 'react-map-gl';
//import { Feature, FeatureCollection } from 'geojson';
//import { MapLayerProps } from '.';
export interface linksLayerProps {
    id: string
    children?: any,
    onMouseEnterLink?: Function,
    onMouseLeaveLink?: Function,
    onLinkClick?: Function,
}

/* uncomment to demo the geomap
function getLinkMouseEventData (mouseEvent, properties) {
    return {
        id: properties?.id,
        originalEvent: mouseEvent,
        properties: properties
    }
}

function MapLayer (props?: MapLayerProps) {
    return <></>;
}
*/

function LinksLayer (props:linksLayerProps) {
    /* uncomment to demo the geomap
    const dataSourceID = props.id;
    const linkLayerID = props.id + "_links";

    function onMouseEnterLink (e: MouseEvent | any, feature) {
        const layerID = feature?.layer?.id;
        if (layerID === linkLayerID) {
            if (props.onMouseEnterLink) {
                props.onMouseEnterLink(getLinkMouseEventData(e, feature.properties));
            }
        }
    }
    function onMouseLeaveLink (e: MouseEvent | any, feature) {
        const layerID = feature?.layer?.id;
        if (layerID === linkLayerID) {
            if (props.onMouseLeaveLink) {
                props.onMouseLeaveLink({ originalEvent: e.originalEvent });
            }
        }
    }
    function onLinkClick (e: MouseEvent | any, feature) {
        const layerID = feature?.layer?.id;
        if (layerID === linkLayerID) {
            if (props.onLinkClick) {
                props.onLinkClick(getLinkMouseEventData(e, feature.properties));
            }
        }
    }

    function convertChildrenToGeoJson (children) {
        const features:FeatureCollection = {
            type: "FeatureCollection",
            features: children ? React.Children.map(children, child => {
                const initializedLink = child.type(child.props);
                const feature:Feature = {
                    type: 'Feature',
                    id: initializedLink.props.id,
                    geometry: initializedLink.props.geometry,
                    properties: initializedLink.props.properties || {}
                };
                return feature;
            }) : []
        };
        return features;
    }
    const childrenInGeoJsonFormat = convertChildrenToGeoJson(props.children);
    */

    return <div>Uncomment this and fix the imports to add the maps back</div>;
/*    
    return (<MapLayer key={props.id} interactiveLayerIds={childrenInGeoJsonFormat.features.length > 0 ? [linkLayerID] : undefined}
            onMouseEnter={onMouseEnterLink} onMouseLeave={onMouseLeaveLink} onClick={onLinkClick}
        >
        { /+ Map Layer Provider for displaying links +/ }
        <Source id={dataSourceID} type="geojson" data={childrenInGeoJsonFormat}>
            <Layer type="line" id={linkLayerID} source={dataSourceID}
                paint={{
                    "line-color": [
                        "match",
                        ["get", "severity"],
                        SEVERITY.CRITICAL, STATUS_COLORS[SEVERITY.CRITICAL],
                        SEVERITY.DEGRADED, STATUS_COLORS[SEVERITY.DEGRADED],
                        SEVERITY.NORMAL, STATUS_COLORS[SEVERITY.NORMAL],
                        STATUS_COLORS[SEVERITY.UNKNOWN]
                    ],
                    "line-opacity": [ "case", ["get", "selected"], 1, 0.4 ],
                    "line-width": [
                        "case",
                        ["any", ["get", "selected"], ["get", "hovered"]],
                        ["+", ["get", "width"], 2],
                        ["get", "width"]
                    ]
                }}
            />
        </Source>
    </MapLayer>);
*/
}

export { LinksLayer };
