/** This module contains the component for creating the visualization framework custom grid layout.
 *  The visualization framework custom grid layout displays visualization widgets in a grid where the
 *  widgets can be moved from one grid location to another and resized.
 *  @module
 */

import React from "react";
import { LayoutConfig } from "./Layout.type";
import { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

/** this interface defines the properties passed into the ReactGridLayout React component. */
export interface LayoutProps {
    /** the array react elements with the widgets. */
    widgets: (React.ReactChild | React.ReactFragment | React.ReactPortal)[];
    /** the layout config. */
    layout: LayoutConfig;
    /** the handler for layout changes. */
    onLayoutChanged?: (layout: any) => void;
}

/** Renders the react grid layout.
 *  @param props the properties passed in.
 *  @returns JSX with the react grid layout component.*/
const ReactGridLayout = ({ widgets, layout, onLayoutChanged }: LayoutProps) => {
    // Auto-width calculated
    const AutoWidthGrid = WidthProvider(Responsive);

    const layoutOptions = layout.options || {};
    const widgetsWithLayout: JSX.Element[] = widgets.map((widget, i) => {
        let options = { w: 1, h: 1, x: i, y: 0 };
        for (const size in layoutOptions) {
            if (!layoutOptions[size]) { continue; }
            for (const widgetLayout of layoutOptions[size]) {
                if (widgetLayout.i === (widget as any).props.config.id) {
                    options = widgetLayout;
                    break;
                }
            }
        }
        return <div key={(widget as any).props.config.id} data-grid={options} className="bg-light">{widget}</div>;
    });

    return (
        <AutoWidthGrid
            className="layout"
            layouts={layout.options || {}}
            rowHeight={400}
            compactType="horizontal"
            measureBeforeMount={true}
            useCSSTransforms={false}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 2 }}
            onLayoutChange={(singleLayout, multiLayouts):void => {
                localStorage.setItem('rgl-visualization-framework', JSON.stringify(multiLayouts));
                if (onLayoutChanged) {
                    onLayoutChanged({type: layout.type, options: multiLayouts });
                }
            }}
        >
            {widgetsWithLayout}
        </AutoWidthGrid>
    );
}

export default ReactGridLayout;
