import React, { useState, useCallback } from 'react';
import { Button, Dialog, Intent, Label } from '@blueprintjs/core';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
	ErrorToaster,
	IconNames,
	SuccessToaster,
} from '@tir-ui/react-components';
import {
	Form,
	InputField,
	RadioButtonField,
	RadioButtonGroup,
} from 'components/common/form';
import { openConfirm } from 'components/common/modal';
import { defaultFormatter } from 'reporting-infrastructure/utils/formatters';
import { DETECTOR_TYPES, SetPolicyInput } from '../AnalyticsConfigurationPage';

import { HELP, STRINGS } from 'app-strings';

import './UpdatePolicySettingsModal.scss';
import { InlineHelp } from 'components/common/layout/inline-help/InlineHelp';

export enum MEASUREMENT_TYPES {
	SINGLE_MEASUREMENT = 'single_measurement',
	CONSECUTIVE_MEASUREMENT = 'consecutive_measurement',
	CUSTOM_MEASUREMENT = 'custom_measurement',
}

const UpdatePolicySettingsModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setPolicy(policy: any) {
			setPolicy(policy);
		},
		setPolicies(policies: any) {
			setPolicies(policies);
		},
		setUnit(unit: string) {
			setUnit(unit);
		},
		setMetricName(name: string) {
			setMetricName(name);
		},
		handleOpen() {
			setIsOpen(!isOpen);
		},
	}));

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [policies, setPolicies] = useState<any>();
	const [policy, setPolicy] = useState<any>();
	const [unit, setUnit] = useState<string>();
	const [metricName, setMetricName] = useState<string>();

	const handleClose = useCallback(() => {
		setIsOpen(false);
		setPolicy(null);
		setUnit('');
	}, []);

	const [updatePolicy] = useMutation<any, SetPolicyInput>(
		loader('./../queries/policies-mutation.graphql'),
		{
			onCompleted: (data) => {
				handleClose();
				SuccessToaster({
					message:
						STRINGS.ANALYTICS_CONFIGURATION_PAGE.modal
							.updateSuccessMessage,
				});
			},
			onError: (err) => {
				ErrorToaster({
					message:
						STRINGS.ANALYTICS_CONFIGURATION_PAGE.modal
							.updateErrorMessage,
				});
				console.error(err?.message);
			},
			refetchQueries: ['analyticsPolicies'],
		}
	);

	/***
	 * onSubmit Handler function for the support formik form
	 * Resets the form after successful submission
	 * @param values
	 */
	const handleSubmit = (values, { setSubmitting }) => {
		setLoading(true);
		let detector_n, detector_m;
		if (values.measurement === MEASUREMENT_TYPES.SINGLE_MEASUREMENT) {
			detector_n = 1;
			detector_m = 1;
		} else if (
			values.measurement === MEASUREMENT_TYPES.CONSECUTIVE_MEASUREMENT
		) {
			detector_n = values.detector_n_m;
			detector_m = values.detector_n_m;
		} else if (
			values.measurement === MEASUREMENT_TYPES.CUSTOM_MEASUREMENT
		) {
			detector_n = values.detector_n;
			detector_m = values.detector_m;
		}

		const newPolicy = {
			enabled: policy.enabled,
			entityKind: policy.entityKind,
			granularity: policy.granularity,
			metrics: policy.metrics,
			detector: {
				type: policy.detector.type,
				upper: policy.detector.upper
					? Number(values.iface_utilization)
					: policy.detector.upper,
				lower: policy.detector.lower
					? Number(values.iface_utilization)
					: policy.detector.lower,
				okValue: policy.detector.okValue,
				percentile: policy.detector.percentile
					? Number(values.iface_utilization)
					: policy.detector.percentile,
				tolerance: policy.detector.tolerance,
				n: Number(detector_n),
				m: Number(detector_m),
			},
			matchFunctions: policy.matchFunctions,
		};

		const payload = {
			variables: {
				input: [newPolicy].concat(policies),
			},
		};

		try {
			// @ts-ignore
			updatePolicy(payload).finally(() => {
				setLoading(false);
				setSubmitting(false);
			});
		} catch (error) {
			setLoading(false);
			setSubmitting(false);
			return Promise.reject('Error updating policy settings.');
		}
	};

	const handleResetToDefault = () => {
		setLoading(true);
		const defaultConfig = policy.defaultConfig;

		const defaultConfigCleaned = JSON.parse(JSON.stringify(defaultConfig));
		delete defaultConfigCleaned.__typename;
		delete defaultConfigCleaned.detector.__typename;
		delete defaultConfigCleaned.isCustom;

		const payload = {
			variables: {
				input: [defaultConfigCleaned].concat(policies),
			},
		};

		try {
			// @ts-ignore
			updatePolicy(payload).finally(() => {
				setLoading(false);
			});
		} catch (error) {
			setLoading(false);
			return Promise.reject('Error updating policy settings.');
		}
	};

	let measurement = MEASUREMENT_TYPES.SINGLE_MEASUREMENT;

	if (policy && policy.detector) {
		if (
			policy.detector.m === policy.detector.n &&
			policy.detector.m === 1 &&
			policy.detector.n === 1
		) {
			measurement = MEASUREMENT_TYPES.SINGLE_MEASUREMENT;
		} else if (policy.detector.m === policy.detector.n) {
			measurement = MEASUREMENT_TYPES.CONSECUTIVE_MEASUREMENT;
		} else {
			measurement = MEASUREMENT_TYPES.CUSTOM_MEASUREMENT;
		}
	}

	const utilValue =
		policy && policy.detector
			? policy.detector.type !== DETECTOR_TYPES.DYNAMIC_THRESHOLD
				? policy.detector.upper
					? policy.detector.upper
					: policy.detector.lower
					? policy.detector.lower
					: ''
				: policy.detector.percentile
				? policy.detector.percentile
				: ''
			: '';

	return (
		<React.Fragment>
			<Dialog
				title={
                    <InlineHelp helpMapping={HELP.thresholdConfiguration.policyEditButton}>
					    {STRINGS.ANALYTICS_CONFIGURATION_PAGE.editStaticThreshold + ': ' + metricName}
                    </InlineHelp>
                }
				isOpen={isOpen}
				autoFocus={true}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				enforceFocus={true}
				usePortal={true}
				onClose={handleClose}
				style={{ width: '600px' }}
			>
				<div className="tir-data-edit-static-threshold">
					<Form
						initialValues={{
							iface_utilization: defaultFormatter(utilValue, ''),
							measurement: measurement,
							detector_n_m:
								policy &&
								policy.detector &&
								policy.detector.n &&
								measurement ===
									MEASUREMENT_TYPES.CONSECUTIVE_MEASUREMENT
									? policy.detector.n
									: '',
							detector_n:
								policy &&
								policy.detector &&
								policy.detector.n &&
								measurement ===
									MEASUREMENT_TYPES.CUSTOM_MEASUREMENT
									? policy.detector.n
									: '',
							detector_m:
								policy &&
								policy.detector &&
								policy.detector.m &&
								measurement ===
									MEASUREMENT_TYPES.CUSTOM_MEASUREMENT
									? policy.detector.m
									: '',
						}}
						loading={loading}
						onSubmit={handleSubmit}
						onSuccessMessage={
							STRINGS.ANALYTICS_CONFIGURATION_PAGE
								.updateSuccessMessage
						}
					>
						<React.Fragment>
							<div className="tir-data-update-policy">
								<div className="row">
									<div className="col-md-8">
										<Label>
											{STRINGS.formatString(
												STRINGS
													.ANALYTICS_CONFIGURATION_PAGE
													.modal.texts.topText,
												metricName ? metricName : '',
												policy &&
													policy.detector &&
													policy.detector.upper
													? STRINGS
															.ANALYTICS_CONFIGURATION_PAGE
															.modal.texts
															.aboveText
													: STRINGS
															.ANALYTICS_CONFIGURATION_PAGE
															.modal.texts
															.belowText
											)}
										</Label>
									</div>
									<div className="col-md-2">
										<InputField
											name="iface_utilization"
											type="text"
											small={true}
											disabled={false}
										/>
									</div>
									<div className="col-md-2">
										{unit && unit !== 'none' ? unit : ''}
									</div>
								</div>
								<div className="row">
									<div className="col-md-1 mt-1">
										{
											STRINGS.ANALYTICS_CONFIGURATION_PAGE
												.modal.texts.for
										}
									</div>
									<div className="col-md-1">
										<RadioButtonGroup
											name={'measurement'}
											inline={false}
											selectedValue={measurement}
											onChange={(e) => {
												const measurement =
													e.currentTarget.value;
												if (
													measurement ===
													MEASUREMENT_TYPES.SINGLE_MEASUREMENT
												) {
													(
														document?.getElementById(
															'detector_n_m'
														) as HTMLInputElement
													).disabled = true;
													(
														document?.getElementById(
															'detector_n'
														) as HTMLInputElement
													).disabled = true;
													(
														document?.getElementById(
															'detector_m'
														) as HTMLInputElement
													).disabled = true;
												} else if (
													measurement ===
													MEASUREMENT_TYPES.CONSECUTIVE_MEASUREMENT
												) {
													(
														document?.getElementById(
															'detector_n_m'
														) as HTMLInputElement
													).disabled = false;
													(
														document?.getElementById(
															'detector_n'
														) as HTMLInputElement
													).disabled = true;
													(
														document?.getElementById(
															'detector_m'
														) as HTMLInputElement
													).disabled = true;
												} else {
													(
														document?.getElementById(
															'detector_n_m'
														) as HTMLInputElement
													).disabled = true;
													(
														document?.getElementById(
															'detector_n'
														) as HTMLInputElement
													).disabled = false;
													(
														document?.getElementById(
															'detector_m'
														) as HTMLInputElement
													).disabled = false;
												}
											}}
										>
											<RadioButtonField
												id={'single_measurement'}
												value={'single_measurement'}
												disabled={false}
											/>
											<RadioButtonField
												id={'consecutive_measurement'}
												value={
													'consecutive_measurement'
												}
												disabled={false}
											/>
											<RadioButtonField
												id={'custom_measurement'}
												value={'custom_measurement'}
												disabled={false}
											/>
										</RadioButtonGroup>
									</div>
									<div className="col-md-8">
										<div className="row mt-1">
											{
												STRINGS
													.ANALYTICS_CONFIGURATION_PAGE
													.modal.texts
													.singleMeasurement
											}
										</div>
										<div className="row mt-2">
											<div className="col-md-2">
												<InputField
													id="detector_n_m"
													name="detector_n_m"
													type="text"
													className="detector-n-m-field"
													label={''}
													small={true}
													disabled={
														measurement !==
														MEASUREMENT_TYPES.CONSECUTIVE_MEASUREMENT
													}
												/>
											</div>
											<div className="col-md-10">
												{
													STRINGS
														.ANALYTICS_CONFIGURATION_PAGE
														.modal.texts
														.consecutiveMeasurement
												}
											</div>
										</div>
										<div className="row">
											<div className="col-md-2">
												<InputField
													id="detector_n"
													name="detector_n"
													type="text"
													className="detector-n-field"
													small={true}
													disabled={
														measurement !==
														MEASUREMENT_TYPES.CUSTOM_MEASUREMENT
													}
												/>
											</div>
											<div className="col-md-2">
												{
													STRINGS
														.ANALYTICS_CONFIGURATION_PAGE
														.modal.texts.outOf
												}
											</div>
											<div className="col-md-2">
												<InputField
													id="detector_m"
													name="detector_m"
													type="text"
													className="detector-m-field"
													small={true}
													disabled={
														measurement !==
														MEASUREMENT_TYPES.CUSTOM_MEASUREMENT
													}
												/>
											</div>
											<div className="col-md-2">
												{
													STRINGS
														.ANALYTICS_CONFIGURATION_PAGE
														.modal.texts
														.measurements
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									paddingTop: '5px',
									paddingRight: '10px',
								}}
							>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<Button
										className="user-btn"
										onClick={() => {
											openConfirm({
												message: (
													<div
														className="px-2 py-2"
														dangerouslySetInnerHTML={{
															__html: STRINGS
																.ANALYTICS_CONFIGURATION_PAGE
																.modal.texts
																.resetConfirmationText,
														}}
													></div>
												),
												onConfirm: () => {
													handleResetToDefault();
												},
												icon: IconNames.INFO_SIGN,
												intent: Intent.PRIMARY,
											});
										}}
										disabled={
											(policy && !policy.isCustom) ||
											loading
										}
									>
										{
											STRINGS.ANALYTICS_CONFIGURATION_PAGE
												.buttons.resetBtnText
										}
									</Button>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<Button
										className="cancel
										-btn"
										onClick={() => {
											handleClose();
										}}
									>
										{
											STRINGS.ANALYTICS_CONFIGURATION_PAGE
												.buttons.cancelBtnText
										}
									</Button>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '5px',
										paddingRight: '5px',
									}}
								>
									<Button
										type="submit"
										className="tir-ui-btn-submit"
										intent={Intent.PRIMARY}
										disabled={loading}
									>
										{
											STRINGS.ANALYTICS_CONFIGURATION_PAGE
												.buttons.applyBtnText
										}
									</Button>
								</div>
							</div>
						</React.Fragment>
					</Form>
				</div>
			</Dialog>
		</React.Fragment>
	);
});

export { UpdatePolicySettingsModal };
