import React, { ReactNode } from 'react';
import { StatusLED, StatusLEDProps } from 'components/common/status-led/StatusLED';
import { SIZE } from 'components/enums';

export interface ItemListProps extends StatusLEDProps {
    title?: string;
    className?: string;
    size?: SIZE.s | SIZE.m;
    striped?: boolean;
    borderless?: boolean;
    children?: ReactNode; // TBD: Children should be of type ItemListItemProps | ItemListItemWithStatusProps
}

export function ItemList ({ title, children, className, size = SIZE.m, striped = true, borderless = false, icon, status, ...props }:ItemListProps) {
    let showStatusTitle = true;
    if (icon === undefined && status === undefined) {
        icon = "none";
        // If all the params (title, icon and status) are undefined, then don't show the title section
        if (title === undefined) {
            showStatusTitle = false;
        }
    }
    return <div className={"item-list" + (className ? " " + className : "")}>
        { showStatusTitle &&
            <StatusLED label={title} icon={icon} status={status} className={"display-7 mb-3" + (className ? " " + className : "")} {...props}/>
        }
        <table className={"table text-black m-0" +
                (striped ? " table-striped" : "") + 
                (size === SIZE.s ? " table-sm": "") +
                (borderless ? " table-borderless": "")
        }><tbody>
            {children}
        </tbody></table>
    </div>;
}
