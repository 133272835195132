import { STRINGS } from "app-strings";
import { NodeProperty, NodeWiresSetting, Variant } from "components/common/graph/types/GraphTypes";
import { cloneDeep, merge } from "lodash";
import { hasOwnProperty } from "@microsoft/applicationinsights-core-js";
import { RunbookEnvSetting } from "utils/services/RunbookApiService";

const CACHE_ENABLED: boolean = false;
/** this interface defines the NodeLibrary object. */
export interface NodeLibrarySpec {
    /** a String with the name of the library. */
    name: string;
    /** an Array of NodeLibraryCategorys. */
    categories?: Array<NodeLibraryCategory>;
}

/** this interface defines the node library category. */
export interface NodeLibraryCategory {
    /** a set of parameters that will be applied to children nodes by default. */
    defaults?: Partial<NodeLibraryNode>;
    /** a String with the name of the category. */
    name: string;
    /** a String with the hex color for the category.  Nodes may override this color. */
    color?: string;
    /** an Array of NodeLibraryNodes. */
    nodes?: Array<NodeLibraryNode>;
    /** Flags to control which environments should display this category. Will be enabled in all environments if not provided */
    env?: Array<'dev'|'staging'|'prod'>;
    /** the array of trigger types where this category is displayed.  Will be enabled for all triggers if not provided. */
    triggerTypes?: Array<'Webhook'>;
    /** this value if set says the category is deprecated and should not be allowed to be added to the graph, but 
     *  can still be seen if it is on an existing graph. */
    deprecated?: boolean;
    /** a boolean value that specifies what setting of the embed flag is required for this node.*/
    embed?: boolean;
}

/** this interface defines the node library node. */
export interface NodeLibraryNode {
    /** a String with the name of the node. When not provided, the type will be used instead */
    subflowName?: string;
    /** a string with the subflow id. */
    subflowId?: string;
    /** a String with the subflow description. */
    subflowDescription?: string,
    /** a String with the type of the node. */
    type: string;
    /** the array of sub types that are used to display different versions of the node in the drag and drop panel. */
    subTypes?: Array<NodeLibrarySubType>;
    /** An icon key that matches with entries in app_icons or iconNames. */
    icon?: string;
    /** an optional string with the help text. */
    help?: string;
    /** a String with the hex color for the node which overrides the category color. */
    color?: string;
    /** the specification for what wires are allowed in the node.*/
    wires?: NodeWiresSetting;
    /** the defined properties for this node. */
    properties?: Array<NodeLibraryNodeProperty>;
    /** this is only for subflows and specifies the environment variables that are available. */
    env?: Array<RunbookEnvSetting>;
    /** an optional string with the connector type that this subflow is using. */
    integrationId?: string;
    /** an array containing descriptions for context values defined inside the subflow input node editor. */
    inputOrOutputValuesDescriptions?: any;
    /** an optional IntegrationInfo object with the information about the integration that this subflow is using. */
    integrationInfo?: IntegrationInfo;
    /** attributes and flags that are meant to be used by the UI. These will not be saved to the backend */
    uiAttrs?: NodeUIAttrs;
    /** a string with the name of the sub type. */
    subType?: string;
    /** a boolean which is true if the subflow is built in, or false otherwise. */
    subflowBuiltIn?: boolean;
}

/** this interface defines the format for the integration information. */
export interface IntegrationInfo {
    /** a string with the id of the integration. */
    id: string;
    /** a string with the primary color for the integration. */
    primaryColor?: string;
    /** a string with the secondary color for the integration. */
    secondaryColor?: string;
    /** a string with the integration icon SVG. */
    icon?: string;
    /** a string with the integration name. */
    name: string;
}

/** this interface defines the format for the input and output subflow variables. */
export interface NodeSubflowVariableDefinition {
    /** a String with the name of the variable. */
    name: string; 
    /** a String with the type of variable. */
    type: string;
    /** a String with the variable unit. */
    unit?: string;
    /** an optional boolean value, that says whether a structured variable is time series or not time series. */
    isTimeseries?: boolean;
}

/** this interface defines the format for the input on demand runbook variables. */
export interface OnDemandRunbookVariableDefinition {
    /** a String with the name of the variable. */
    name: string; 
    /** a String with the type of variable. */
    type: string;
    /** a String with the variable unit. */
    unit?: string;
    /** an optional boolean value, that says whether a structured variable is time series or not time series. */
    isTimeseries?: boolean;
}

/** this interface defines the node library ui attributes for a node. */
export interface NodeUIAttrs {
    /** Should description field be displayed with in node editor for this type of node? */
    showDescriptionField?: boolean;
    /** Should debug field be displayed with in node editor for this type of node? */
    showDebug?: boolean;
    /** An array fo filter keys which will be considered as primary filters for this node and will always be displayed without clicking "Add Filter" */
    primaryFilters?: Array<string>;
    /** Should the user be forced to select a metric for this node? */
    metricReqd?: boolean;
    /** Flags to control which environments should display this node. Will be enabled in all environments if not provided */
    env?: Array<'dev'|'staging'|'prod'>;
    /** the array of trigger types where this node is displayed.  Will be enabled for all triggers if not provided. */
    triggerTypes?: Array<'Webhook'>;
    /** If true, then node editor will not show the controls until user connects an input to this node */
    connectInputToEdit?: boolean;
    /** this value if set says the node is deprecated and should not be allowed to be added to the graph, but 
     *  can still be seen if it is on an existing graph. */
    deprecated?: boolean;
    /** this flag if set to true allows users in the editor to toggle raw JSON editing which can be used during development
     *  to allow back-end teams to just copy in their JSON before the editor is completed. */
    allowRawJsonEdit?: boolean;
    /** a boolean value that specifies what setting of the embed flag is required for this node.*/
    embed?: boolean;
    /** a hint as to the variant that the node library is being used for. */
    variant?: Variant;
}

/** this interface defines the node library node for a subtype. */
export interface NodeLibrarySubType extends Partial<NodeLibraryNode> {
    /** a string with the name of the sub type. */
    subType: string;
    /** an array with the property defaults for the sub type. */
    defaults: Array<SubTypeDefault>;
}

/** this interface defines a node library sub type default.  The sub type default
 *  defines an override for the default property values for the node. */
export interface SubTypeDefault {
    /** a string with the name of the property. */
    name: string;
    /** the default value for the property that should be used for this sub type. */
    default: any;
}

/** this interface defines the node library node property. */
export interface NodeLibraryNodeProperty {
    /** a String with the name of the property. */
    name: string;
    /** a String with the display label for the property. */
    label: string;
    /** a String with the type of the property value. */
    type: string;
    /** the default value of the property. */
    default?: any;
    /** the options for the select element. */
    options?: Array<any>;
}

/** this class encapsulates the functionality of the node library for the runbook editor. */
export class NodeLibrary {
    public nodeLibrary: NodeLibrarySpec;
    public _quickAccessNodeCache: any = null;

    /** the constructor for the class which creates a new NodeLibrary instance. 
     *  @param library the NodeLibrarySpec with the JSON for the node library. */
    constructor(library: NodeLibrarySpec) {
        this.nodeLibrary = library;
    }

    /** returns the NodeLibrarySpec, this is the raw load library without the cache.
     *  @returns a reference to the NodeLibrarySpec with the raw node library without the cache. */
    public getNodeSpecification(): NodeLibrarySpec {
        return this.nodeLibrary;
    }

    /** returns the array of strings with the unique node types.
     *  @returns an array of Strings with the unique node types. */
    public getNodeTypes(): string[] {
        if (this.nodeLibrary) {
            const types: string[] = [];
            for (const category of this.nodeLibrary.categories || []) {
                for (const node of category.nodes || []) {
                    if (!types.includes(node.type)) {
                        types.push(node.type);
                    }
                }
            }
            return types;
        }
        return [];
    }

    /** returns the node for the specified type and sub type. 
     *  @param nodeType the type of node.
     *  @param nodeSubType the node subtype, this is optional only some nodes have it.
     *  @param categoryName the category name.
     *  @returns the node definition. */
    public getNode (nodeType: string, nodeSubType?: string, categoryName?: string): NodeLibraryNode | undefined {
        let node: NodeLibraryNode | undefined = undefined;
        const nodeTypeCacheKey = nodeType + (nodeSubType ? ":" + nodeSubType : "")
        if (nodeType && categoryName && this._quickAccessNodeCache?.byCategory[categoryName] && this._quickAccessNodeCache?.byCategory[categoryName][nodeTypeCacheKey]) {
            node = this._quickAccessNodeCache?.byCategory[categoryName][nodeTypeCacheKey];
        } else if (this._quickAccessNodeCache?.byType[nodeTypeCacheKey]) {
            node = this._quickAccessNodeCache?.byType[nodeTypeCacheKey];
        } else if (this.nodeLibrary?.categories) {
            for (const category of this.nodeLibrary.categories) {
                // If category wasn't provided or provided category matches current category
                if (category.nodes && (!categoryName || categoryName === category.name)) {
                    for (const eachNode of category.nodes) {
                        // If matching node was found
                        if (eachNode.type === nodeType) {
                            let finalNode = {
                                ...(category.defaults || {}),
                                ...eachNode
                            };
                            if (nodeSubType && finalNode.subTypes) {
                                const { subTypes, ...finalNodeSansSubTypes } = finalNode;
                                for (const subTypeEntry of subTypes) {
                                    const { subType, defaults, ...subTypeOverrideConfig } = subTypeEntry;
                                    if (subType === nodeSubType) {
                                        node = {
                                            ...finalNodeSansSubTypes,
                                            ...subTypeOverrideConfig,
                                            subType: subType,
                                        };
                                        const properties:Array<NodeLibraryNodeProperty> = [];
                                        if (defaults && node.properties) {
                                            for (const property of node.properties) {
                                                let matchingOverridesFound = false;
                                                // For each property, check if there is a default property object from subtype.
                                                // If it's present, then push the merged version of property.
                                                for (const subTypeDefault of defaults) {
                                                    if (subTypeDefault.name === property.name && subTypeDefault.default !== null && subTypeDefault.default !== undefined) {
                                                        properties.push({
                                                            ...property,
                                                            ...subTypeDefault,
                                                        });
                                                        matchingOverridesFound = true;
                                                        break;
                                                    }
                                                }
                                                if (!matchingOverridesFound) {
                                                    properties.push(property);
                                                }
                                            }
                                        }
                                        node.properties = properties;
                                    }
                                }
                            } else {
                                node = finalNode;
                            }
                            if (node && CACHE_ENABLED) {
                                this._quickAccessNodeCache = this._quickAccessNodeCache || {
                                    byType: {},
                                    byCategory: {}
                                };
                                if (categoryName) {
                                    if (this._quickAccessNodeCache.byCategory[categoryName] === undefined) {
                                        this._quickAccessNodeCache.byCategory[categoryName] = {};
                                    }
                                    this._quickAccessNodeCache.byCategory[categoryName][nodeTypeCacheKey] = finalNode;
                                }
                                this._quickAccessNodeCache.byType[nodeTypeCacheKey] = finalNode;
                            }
                        }
                    }
                }
            }
        }
        return node;
    }

    /** returns the node definition by figuring out its sub-type, if there is one, using the node properties.
     *  @param nodeType the type of node.
     *  @param properties the node properties.
     *  @param categoryName the category name.
     *  @returns the node definition. */
    public getNodeUsingProperties (nodeType: string, properties?: Array<NodeProperty>, categoryName?: string): NodeLibraryNode | undefined {
        let node: NodeLibraryNode | undefined = this.getNode(nodeType, undefined, categoryName);
        let subType: string | undefined = NodeLibrary.getSubType(node, properties);
        if (subType) {
            const node = this.getNode(nodeType, subType, categoryName);
            if (node) {
                return {
                    ...node,
                    subType: subType
                };
            } else {
                return node;
            }
        } else {
            return node;
        }
    }

    /** returns the sub type for the specified node or undefined if it has no subtype.  This function uses the 
     *      properties of the node to figure out what sub type it is.  This should work every time, however, a 
     *      better way to do this in the future would be to modify the back-end to support saving the sub type.
     *      If this function ever fails, we should consider saving the sub type to the back-end, but for now 
     *      this is a perfectly valid way to figure out the sub type.
     *  @param properties the node properties object.
     *  @param libraryNode the library node that has the definitions of the sub types.
     *  @returns a string with the sub type or undefined if there are no sub types. */
    public static getSubType(libraryNode: NodeLibraryNode | undefined, properties?: Array<NodeProperty>): string | undefined {
        let subType: string | undefined;
        if (hasOwnProperty(libraryNode, "subType")) {
            subType = libraryNode?.subType;
        } else if (properties && libraryNode && libraryNode.subTypes && libraryNode.subTypes.length > 0) {
            for (const subType of libraryNode.subTypes) {
                if (subType.defaults) {
                    let allFound = true;
                    for (const defProp of subType.defaults) {
                        const value = NodeLibrary.getProperty(defProp.name, properties);
                        if (defProp.default !== value) {
                            allFound = false;
                            break;
                        }
                    }
                    if (allFound) {
                        return subType.subType;
                    }
                }
            }
        }
        return subType;
    }

    /** gets a property out of the node.
     *  @param key the property key.
     *  @param properties the array of node properties to check for the specified property key.
     *  @returns the value for the key.*/
    public static getProperty(key: string, properties?: Array<NodeProperty>): any | null | undefined {
        if (properties) {
            for (const property of properties) {
                if (property.key === key) {
                    return property.value;
                }
            }
        }
        return null;
    }

    /** returns all the resource strings for the node specified by the type and subtype.
     *  @param type the node type.
     *  @param subType the node sub type.  This only exists on a subset of the nodes.
     *  @returns a dictionary with the node resource strings from the Strings file. */
    public static getNodeResourceStrings (type, subType?) {
        const typeResources = STRINGS.runbookEditor.nodeLibrary.nodes[type] || {};
        const subTypeResources = STRINGS.runbookEditor.nodeLibrary.nodes[type]?.subTypes?.[subType] || {};
        return merge(cloneDeep(typeResources), subTypeResources);
    }
}
