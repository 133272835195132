/** this class encapsulates the functionality for a Version object. */
export class Version {
    /** the major version number which should be an integer greater than or equal to 0. */
    public major: number;
    /** the minor version number which should be an integer greater than or equal to 0. */
    public minor: number;
    /** the patch version number which should be an integer greater than or equal to 0. */
    public patch: number;

    /** creates a new instance of Version.*/
    public constructor();

    /** creates a new instance of version.
     *  @param major the major version number which should be an integer greater than or equal to 0.
     *  @param minor the minor version number which should be an integer greater than or equal to 0.
     *  @param patch the patch version number which should be an integer greater than or equal to 0. */
    public constructor(major: number, minor: number, patch: number);

    /** creates a new instance of version.
     *  @param major the major version number which should be an integer greater than or equal to 0.
     *  @param minor the minor version number which should be an integer greater than or equal to 0.
     *  @param patch the patch version number which should be an integer greater than or equal to 0. */
    public constructor(major: number = 0, minor: number = 0, patch: number = 0) {
        this.major = major;
        this.minor = minor;
        this.patch = patch;
    }

    /** returns the major version number.
     *  @returns an integer value with the major version number. */
    public getMajor(): number {
        return this.major;
    }

    /** returns the minor version number.
     *  @returns an integer value with the minor version number. */
    public getMinor(): number {
        return this.minor;
    }

    /** returns the patch version number.
     *  @returns an integer value with the patch version number. */
    public getPatch(): number {
        return this.patch;
    }

    /** returns whether or not this version is equal to the specified version.
     *  @param version the Version to check against.
     *  @returns a boolean value which is true if the this version is equal to the specified version. */
    public isEqual(version: Version): boolean {
        return this.major === version.major && this.minor === version.minor && this.patch === version.patch;
    }

    /** returns whether or not this version is greater than the specified version.
     *  @param version the Version to check against.
     *  @returns a boolean value which is true if the this version is greater than the specified version. */
    public isGreaterThan(version: Version): boolean {
        if (this.major > version.major) {
            return true;
        } else if (this.major === version.major && this.minor > version.minor) {
            return true;
        } else if (this.major === version.major && this.minor === version.minor && this.patch > version.patch) {
            return true;
        }

        return false;
    }

    /** returns whether or not this version is greater than or equal to the specified version.
     *  @param version the Version to check against.
     *  @returns a boolean value which is true if the this version is greater than or equal to the specified version. */
    public isGreaterThanOrEqual(version: Version): boolean {
        return this.isGreaterThan(version) || this.isEqual(version);
    }

    /** returns whether or not this version is less than the specified version.
     *  @param version the Version to check against.
     *  @returns a boolean value which is true if the this version is less than the specified version. */
    public isLessThan(version: Version): boolean {
        return !this.isGreaterThan(version) && !this.isEqual(version);
    }

    /** returns whether or not this version is less than or equal to the specified version.
     *  @param version the Version to check against.
     *  @returns a boolean value which is true if the this version is less than or equal to the specified version. */
    public isLessThanOrEqual(version: Version): boolean {
        return !this.isGreaterThan(version);
    }

    /** returns the version as a string.
     *  @returns the version as a string. */
    public toString(): string {
        return this.major + "." + this.minor + "." + this.patch;
    }

    /** returns the version as an array.
     *  @returns the version as an array. */
    public toArray(): number[] {
        return [this.major, this.minor, this.patch];
    }

    /** parses the specified version and returns a version object.  If the version is not valid it returns 0.0.0
     *  @param version the version String to parse.
     *  @returns the Version object that corresponds to the version string or a Version with 0.0.0 if the string is
     *      invalid. */
    public static parse(version: string): Version {
        let major: number = 0;
        let minor: number = 0;
        let patch: number = 0;
        if (version) {
            const versionAr = version.split(".");
            if (versionAr.length > 0) {
                const majorValue = parseInt(versionAr[0]);
                if (majorValue >= 0 && Number.isInteger(majorValue)) {
                    major = majorValue;
                }
            }
            if (versionAr.length > 1) {
                const minorValue = parseInt(versionAr[1]);
                if (minorValue >= 0 && Number.isInteger(minorValue)) {
                    minor = minorValue;
                }
            }
            if (versionAr.length > 2) {
                const patchValue = parseInt(versionAr[2]);
                if (patchValue >= 0 && Number.isInteger(patchValue)) {
                    patch = patchValue;
                }
            }
        }
        return new Version(major, minor, patch);
    }
}
