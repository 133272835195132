/** This file defines the capacity planning table view react component.  The 
 *  capacity planning table view react component displays the interface predictions from
 *  AI in a table.
 *  @module */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Icon, IconNames, Table } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { STRINGS } from "app-strings";
import { AutoUpdateContext } from "pages/network-summary/NetworkSummaryPage";
import { PRIORITY_INDEX } from "components/enums";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { Button, Intent, PopoverPosition, Tag } from "@blueprintjs/core";
import Data from 'pages/prediction-summary/views/capacity-planning-table/InterfaceCapacityData.json';
//import SparklineData from "./SparklineData.json";
import CapacityPlanningDialog from "pages/incident-search/modals/capacity-planning/CapacityPlanningDialog";
import "./CapacityPlanningTableView.scss";

/** the limit to use when querying the top capacity planning results. */
const limit: number = 10;

/** an interface that describes the properties that can be passed in to the capacity planning table view component.*/
export interface CapacityPlanningTableViewProps {
    /** the handler for the click event on the table, it just forwards the event to the parent component. */
    onClickEvent?: (id: string| undefined) => void;
}

/** Renders the cpacity planning table react component.
 *  @param props the properties passed in.
 *  @returns JSX with the capacity planning table component.*/
export function CapacityPlanningTableView(props: CapacityPlanningTableViewProps): JSX.Element {
    const autoUpdate = useContext(AutoUpdateContext);
    /*
    let {loading, data, error, run} = useQuery({
        query: new Query(loader("./user-impact.graphql")),
        name: "users",
        queryVariables: { limit: limit },
        consumedFilters: [
            FILTER_NAME.priority,
            FILTER_NAME.incidentStatus,
            FILTER_NAME.completionStatus
        ],
        timeNotRequired: true,
    });
    */
    const [data, setData] = useState<any>();
    const error = null;
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(
        () => {
            setTimeout(() => {
                setData(Data.data);
                setLoading(false);
            }, 1 * 1);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current= autoUpdate.sequenceNumber;
                /*
                run({
                    fetchPolicy: "no-cache",
                    queryVariables: { limit: limit }
                });
                */
                setTimeout(() => {
                    setData(Data.data);
                    setLoading(false);
                }, 1 * 1);
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    // Capacity Planning Modal
    const capacityPlanningModal = useRef();
    const openCapacityPlanningModal = (editInfos = null) => {
        if (capacityPlanningModal.current) {
            // @ts-ignore
            capacityPlanningModal.current.handleOpen(editInfos);
        }
    }

    //const metricTooltipFormatter = tooltipFormatter ? tooltipFormatter : (data: timeSeriesTooltipFormatterParams) => formatTimeSeriesTooltip(data, unit);
/* Needed if we put back sparklines
    const metricTooltipFormatter = undefined;
    const chartData: any[][] = useMemo(
        () => {
            const data = new Array(10);
            for (let i = 0; i < 10; i++) {
                data[i] = SparklineData.map((data) => {
                    return {x: new Date(data.x), y: Math.random() * 100};
                });
            }
            return data;
        },
        []
    );
*/

    const columns = [
        // {
        //     id: "priority",
        //     // Header: STRINGS.incidentPriorities.label,
        //     Header: '',
        //     accessor: "priority",
        //     formatter: (record) => {
        //         //return <PriorityLEDFormatter isSummary priority={record.priority} showStatusAsBackground/>;
        //         return <Icon icon={IconNames.LAN} iconSize={20} />;
        //     },
        //     headerClassName: 'w-0-5'
        // },
        {
            id: "interface",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.interface,
            accessor: "interface",
            formatter: (record) => <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0 d-inline"}
                usePortal={false} content={record.interface} position={PopoverPosition.BOTTOM} transitionDuration={50}>
                    <span className="overflow-elipsis-table-cell position-relative d-block overflow-hidden text-nowrap">
                    <Icon icon={IconNames.LAN} iconSize={20} className="mr-2" /> {record.interface}
                    </span>
                </Tooltip2>,
            className: 'summary-interface-widget-max-width'
        },
/* Jeff said to remove the sparkline        
        {
            id: "sparkline",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.sparkline,
            accessor: "sparkline",
            formatter: (record) => {
                return <SparklineTile 
                    chartData={chartData[parseInt(record.id)]} thresholds={undefined} leadingMarker={false}
                    tooltipFormatter={metricTooltipFormatter} yMin={undefined} yMax={undefined}
                    chartCompData={undefined}
                />;
            },
            className: 'pr-0',
        },
*/        
        {
            id: "trend1",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.trend1,
            accessor: "trend1",
            formatter: (record) => {
                return <div>
                    <Icon
                        icon={record.trendOrder1 === "inc" ? IconNames.CIRCLE_ARROW_UP : IconNames.CIRCLE_ARROW_DOWN}
                        intent={record.trendOrder1 === "inc" ? Intent.DANGER : Intent.SUCCESS}
                        className="mr-1"
                    />
                    <div className="comparison-value-container">
                        <div className="value">{record.trendValue1}%</div>
                        {/* <div className="unit">%</div> */}
                        {/*<div>Last {record.trendInterval1} days</div>*/}
                    </div>
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "trend2",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.trend2,
            accessor: "trend2",
            formatter: (record) => {
                return <div>
                    <Icon
                        icon={record.trendOrder2 === "inc" ? IconNames.CIRCLE_ARROW_UP : IconNames.CIRCLE_ARROW_DOWN}
                        intent={record.trendOrder2 === "inc" ? Intent.DANGER : Intent.SUCCESS}
                        className="mr-1"
                    />
                    <div className="comparison-value-container">
                        <div className="value">{record.trendValue2}%</div>
                        {/* <div className="unit">%</div> */}
                        {/*<div>Last {record.trendInterval2} days</div>*/}
                    </div>
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "threshold1",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.threshold1,
            accessor: "threshold1",
            sortable: true,
            sortDescFirst: false,
            sortFunction: (a, b) => {
                if (a.daysToThreshold1 === "-" || b.daysToThreshold1 === "-") {
                    return a.daysToThreshold1 === "-" && b.daysToThreshold1 === "-" ? 0 : a.daysToThreshold1 === -1 ? 1 : -1;
                }
                return (a.daysToThreshold1 > b.daysToThreshold1 ? 1 : (b.daysToThreshold1 > a.daysToThreshold1 ? -1 : 0));
            },            
            formatter: (record) => {
                return <div className="d-flex flex-column align-items-center">
                    <div>
                        <span className="value">{record.threshold1}</span><span className="unit mr-2">% </span>
                        <Tag style={{width: "65px"}}
                            intent={record.daysToThreshold1 < 41 ? Intent.DANGER : record.daysToThreshold1 < 90 ? Intent.WARNING : Intent.NONE}
                        > 
                            {record.daysToThreshold1 === "-" ? <Icon icon={IconNames.MINUS} iconSize={16} /> : record.daysToThreshold1 + " days"}
                        </Tag>
                    </div>
                    {/* <div className="count font-weight-bold">{record.daysToThreshold1} dayss</div> */}
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "threshold2",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.threshold2,
            accessor: "threshold2",
            sortable: true,
            sortDescFirst: false,
            sortFunction: (a, b) => {
                if (a.daysToThreshold2 === "-" || b.daysToThreshold2 === "-") {
                    return a.daysToThreshold2 === "-" && b.daysToThreshold2 === "-" ? 0 : a.daysToThreshold2 === -1 ? 1 : -1;
                }
                return (a.daysToThreshold2 > b.daysToThreshold2 ? 1 : (b.daysToThreshold2 > a.daysToThreshold2 ? -1 : 0));
            },            
            formatter: (record) => {
                return <div className="d-flex flex-column align-items-center">
                    <div>
                        <span className="value">{record.threshold2}</span><span className="unit mr-2">% </span>
                        <Tag style={{width: "65px"}}
                            intent={record.daysToThreshold2 < 41 ? Intent.DANGER : record.daysToThreshold2 < 90 ? Intent.WARNING : Intent.NONE}
                        > 
                            {record.daysToThreshold2 === "-" ? <Icon icon={IconNames.MINUS} iconSize={16} /> : record.daysToThreshold2 + " days"}
                        </Tag>
                    </div>
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "view",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.view,
            accessor: "view",
            formatter: (record) => {
                return <Button 
                    icon={IconNames.CHART}
                    className="special-chart-ai"
                    minimal
                    // color="#0000FF"
                    onClick={(e) => {
                        openCapacityPlanningModal();
                    }}
                />;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
    ];
    let userData: Array<any> = [];
    if (data && data?.impactedUsers?.incidentsUsers?.length > 0) {
        let count = 0;
        for (const incident of data.impactedUsers.incidentsUsers) {
            userData.push(
                {
                    id: incident.id, interface: incident.interface, priority: incident.priority, description: incident.description, 
                    daysToThreshold1: incident.daysToThreshold1, trendOrder1: incident.trendOrder1, 
                    trendValue1: incident.trendValue1, interval1: incident.interval1, threshold1: incident.threshold1,
                    daysToThreshold2: incident.daysToThreshold2, trendOrder2: incident.trendOrder2, 
                    trendValue2: incident.trendValue2, interval2: incident.interval2, threshold2: incident.threshold2,
                }
            );
            if (++count === limit) {
                // We can't show more than 5 in this table, DAL needs to give us an order by and a limit
                break;
            }
        }
    }

    // Sort the data by count and then by priority
    userData.sort((a, b) => {
        if (!a.priority || !b.priority) {
            return 0;
        }
        if (PRIORITY_INDEX[a.priority] > PRIORITY_INDEX[b.priority]) {
            return 1;
        } else if (PRIORITY_INDEX[a.priority] < PRIORITY_INDEX[b.priority]) {
            return -1;
        } else {
            return 0;
        }
    }).sort((a, b) => {
        if (a.count === undefined || a.count === null || b.count === undefined || b.count === null) {
            return 0;
        }
        return a.count > b.count ? 1 : a.count < b.count ? -1 : 0;
    }).reverse();
    
    const dataAvailable = userData.length > 0;
    return <>
        <DataLoadFacade loading={loading} error={error} data={data} showContentsWhenLoading={dataAvailable} transparent={dataAvailable}>
        {
            dataAvailable ?
            <Table className="capacity-planning-table-view" id="usersList" columns={columns} data={userData || []} interactive={false} defaultPageSize={10}
                bordered
                striped
                condensed={true}
                sortBy={[{id: "threshold1", desc: false}]}
                // onRowClick={(e) => {
                //     openCapacityPlanningModal();
                //     /*
                //     if (props.onClickEvent) {
                //         props.onClickEvent(!e.ctrlKey ? e.id : null);
                //     }
                //         */
                // }}
                transparent
            /> :
            <div className="absolute-center">{STRINGS.no_data_to_display}</div>
        }
        </DataLoadFacade>
        {/*<CapacityPlanningModal ref={capacityPlanningModal} title="Ke e esto!?" refreshSearch={() => {}} />*/}
        <CapacityPlanningDialog ref={capacityPlanningModal} threshold1={80} threshold2={100} title="Ke e esto!?" refreshSearch={() => {}} />
    </>;
};
 