/** This file defines the TimeseriesTile React component.  The TimeseriesTile React component 
 *  displays a time series data along with the x-axis but no y-axis.
 *  @module */
import React, { useRef, useCallback } from "react";
import { Icon, IconName } from "@tir-ui/react-components";
import Highcharts from "highcharts";
import AnnotationsFactory from "highcharts/modules/annotations";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { HighchartsData, timeSeriesTooltipFormatter, timeSeriesTooltipFormatterParams } from "components/reporting/utils/Types";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { CHART_COLORS } from "components/enums";
import { DEFAULT_TIME_SERIES_OPTIONS } from "components/reporting/charts/defaults/HighchartDefaults";
import useResizeObserver  from "utils/hooks/useResizeObserver";
import "./TimeseriesTile.scss";

// This is needed to enable the highcharts no data functionality
NoDataToDisplay(Highcharts);
AnnotationsFactory(Highcharts);

/** This interface defines the properties passed into the TimeseriesTile component.*/
export interface TimeseriesTileProps {
    /** an object with additional highcharts otions that are merged into the default highcharts configuration. */
    config?: object;
    /** a string with the chart title. */
    title?: string | number;
    /** optional data that can be displayed above the chart. */
    data?: string | number;
    /** a String with the units to display for the optional data displayed above the chart. */
    unit?: string;
    /** the highcharts time series data that are to be displayed on the chart. */
    chartData?: HighchartsData;
    /** the comparison data that coincides with the chartData object and is in the format of a highcharts series data. */
    chartCompData?: HighchartsData;
    /** a number with the comparison data time offset in seconds. */
    comparisonOffset?: number;
    /** the suffix that should be appended to the name of the dataset in the legend. */
    comparisonSuffix?: string;
    /** an array of numbers with the thresholds to be displayed on the chart. */
    thresholds?: number[];
    /** a String with the class name. */
    className?: string;
    /** the name of the icon to display above the chart. */
    icon?: IconName;
    /** a string with the data to display to the right of the icon. */
    iconData?: string | number;
    /** a boolean value, if true show the marker. */
    leadingMarker?: boolean;
    /** a number with the minimum y-value to be displayed. */
    yMin?: number;
    /** a number with the maximum y-value to be displayed. */
    yMax?: number;
    /** a boolean value, if true show the data as bars, if false show as lines. */
    showAsBars?: boolean;
    /** a boolean value, if true show the data as steps. */
    showAsSteps?: boolean;
    /** a function that replaces the tooltip formatter for the chart. */
    tooltipFormatter?: timeSeriesTooltipFormatter;
}

/** Renders the TimeseriesTile React component.
 *  @param cardProps the properties passed in.
 *  @returns JSX with the TimeseriesTile React component.*/
export function TimeseriesTile(props: TimeseriesTileProps) {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    function getChartOptions() {
        let options = cloneDeep(DEFAULT_TIME_SERIES_OPTIONS);

        if (props.leadingMarker) {
            options.chart.events.load = function (this:any) {
                const [series] = this.series;
                if (series.data.length > 1) {
                    //set marker on last point
                    series.data[series.data.length - 1].update({ marker: { enabled: true } });
                }
            };
        }

        options.tooltip.formatter = function (this: timeSeriesTooltipFormatterParams) {
            if (props.tooltipFormatter) {
                return props.tooltipFormatter(this);
            } else {
                return this.y;
            }
        };

        if (props.showAsBars) {
            options.series[0].type = "column";
        }

        if (props.showAsSteps) {
            options.series[0].step = "true";
        }

        //merge chart config
        if (props.config) {
            options = merge(options, props.config);
        }
        if (props.yMin !== undefined) {
            options.yAxis.min = props.yMin;
        }
        if (props.yMax !== undefined) {
            options.yAxis.max = props.yMax;
        }
        //merge data
        if (props.chartData) {
            let tsData: HighchartsData = cloneDeep(props.chartData);
            markIsolatedPoints(tsData);
            options.series[0].data = tsData;
        }

        if (props.chartCompData) {
            let tsData: HighchartsData = cloneDeep(props.chartCompData);
            markIsolatedPoints(tsData);
            options.chart.type = 'line';
            options.series[0].type = 'line';
            options.series.unshift({
                color: new Highcharts.Color("#88C").setOpacity(0.4).get(),
                animation: {
                    duration: 500,
                },
                borderRadius: 4,
                borderColor: "transparent",
                maxPointWidth: 10,
                data: tsData
            });
        }

        if (props.thresholds && props.chartData?.length) {
            for (const threshold of props.thresholds) {
                options.series.push({
                    type: "line",
                    dashStyle: "Dash",
                    color: CHART_COLORS.THRESHOLD_DEFAULT,
                    lineWidth: 1,
                    data: Array.isArray(threshold) ? threshold : [...props.chartData].map(data => {
                        if (typeof data === "object" && (data as any).x !== undefined) {
                            return { x: (data as any).x, y: threshold };
                        } else {
                            return threshold;
                        }
                    }),
                });
            }
        }

        options.series = convertPointsToArrays(options.series);
        return options;
    }
    
    /** The onResize function must preserve its references between component re-renders, 
     * that's why I wrap it with the useCallback hook. 
     * Otherwise, the resize observer would be re-created on every re-render, 
     * which may lead to performance issues. */
    const onResize = useCallback((target: HTMLDivElement) => {
        chartRef.current?.chart.reflow();
      }, []);
    const resizedRef = useResizeObserver(onResize);

    return (
        <div
            className={`chart-tile timeseries-tile d-flex flex-row justify-content-center ${
                props.className ? props.className : ""
            }`}
            ref={resizedRef}
        >
            { (props.icon || props.iconData || props.title) && 
                <div className="p-2 d-flex align-items-center flex-column justify-content-center">
                    {(props.icon || props.iconData !== undefined) && (
                        <span className="text-nowrap">
                            {props.icon && <Icon icon={props.icon} iconSize={25} className="tile-icon"/>}
                            {props.iconData !== undefined && (
                                <span
                                    className="display-6 p-1"
                                    aria-label="icon-data"
                                >
                                    {props.iconData}
                                </span>
                            )}
                        </span>
                    )}
                    {props.title && (
                        <div
                            className="font-size-x-small font-size-lg-small font-weight-bold"
                            aria-label="title"
                        >
                            {props.title}
                        </div>
                    )}
                </div>
            }
            <div className="text-center d-flex flex-column justify-content-center flex-grow-1">
                {props.data !== undefined && (
                    <h6 className="m-0">
                        <span aria-label="timeseries-data">{props.data}</span>
                        {props.unit !== undefined && (
                            <span
                                className="font-size-x-small font-size-lg-small font-weight-bold p-1"
                                aria-label="unit"
                            >
                                {props.unit}
                            </span>
                        )}
                    </h6>
                )}
                {props.chartData && (
                    <div className="position-relative flex-grow-1 card-chart-holder">
                        <div className="position-absolute w-100 h-100">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={getChartOptions()}
                                containerProps={{
                                    style: { width: "100%", height: "100%" },
                                }}
                                ref={chartRef}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

/** Converts the series data points from objects to an array
 *  to allow highcharts to render more than 1000 points faster
 *  @param optionsSeries array of series to be converted.
 *  @returns The series array converted */
export function convertPointsToArrays(optionsSeries: any[]): Highcharts.Series[] {
    const newSeries: any = [];
    for (let series of optionsSeries) {
        const newData: any[] = [];
        const isolatedPoints: any[] = [];
        if (series.type === "line" && series.data[0]?.x !== undefined && series.data[0]?.y !== undefined) {
            for (let point of series.data) {
                if (point.marker?.enabled) {
                    isolatedPoints.push(point);
                } else {
                    newData.push([point.x, point.y]);
                }
            }
        } else if (series.type === "arearange" && series.data[0]?.high && series.data[0]?.low && series.data[0]?.x) {
            for (let point of series.data) {
                if (point.marker?.enabled) {
                    isolatedPoints.push(point);
                } else {
                    newData.push([point.x, point.low, point.high]);
                }
            }
        }
        const processedSeries = {...series};
        if (newData.length) {
            processedSeries.data = newData;
        }
        newSeries.push(processedSeries);

        if (isolatedPoints.length) {
            const processedMarkedPoints = {...series};
            if (series.type === "line") {
                // Might want to do this for arearange as well, but just trying to make the minimal change at this point
                // If we take a line and then take the issolated points and set the type to line again it will draw lines 
                // between the points if there is not a null between them.  We would either need to insert artificial nulls
                // or let's just convert the line to a scatter.
                processedMarkedPoints.type = "scatter";
                delete processedMarkedPoints.step;    
            }
            processedMarkedPoints.data = isolatedPoints;
            newSeries.push(processedMarkedPoints);
        }
    }
    return newSeries;
}

/** takes all the points that are not part of a line segment and adds a marker to those points so 
 *      can be displayed.
 *  @param points the array of point to search for isolated points that are not part of any line segments. */
export function markIsolatedPoints(points: Array<any>): void {
	for (let index = 0; index < points.length; index++) {
		if (index === 0 && points.length > 1 && points[1].y === null) {
			points[index].marker = {enabled: true, radius: 2};
		} else if (index === (points.length - 1) && points.length > 1 && points[points.length - 2].y === null) {
			points[index].marker = {enabled: true, radius: 2};
		} else if (index > 0 && index < (points.length - 1) && points[index - 1].y === null && points[index + 1].y === null) {
			points[index].marker = {enabled: true, radius: 2};
		} else if (points.length === 1) {
			points[index].marker = {enabled: true, radius: 2};
		}
	}
}
