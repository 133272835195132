import React, { useState } from "react";
import {
    ErrorToaster,
    Modal,
    IconNames,
    SuccessToaster,
} from "@tir-ui/react-components";
import { Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { ModalProps } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { openConfirm } from "components/common/modal";

const BEM_DELETE_RUNBOOK_MODAL = "DeleteRunbookModal-radio-input";

type DeleteOptions = "selected" | "all";

interface DeleteRunbookOutputsProps extends ModalProps {
    selectedIds: Array<string>;
    onClose: any;
}

type FilterTypes =
    | "NAME"
    | "TITLE"
    | "ENTITY_TYPE"
    | "VARIANT"
    | "STATUS"
    | "CREATED_BY"
    | "EXECUTION_METHOD";

interface DeleteRunbookOutputsMutationInput {
    filter: {
        filters: [
            {
                type: FilterTypes;
                values: Array<string>;
            },
        ];
        time: {
            endTime: string;
        };
    };
    search: string;
    searchFields: "TITLE";
}

export const DeleteRunbookOutputsModal = ({
    selectedIds,
    onClose,
    onSuccess,
}: DeleteRunbookOutputsProps) => {
    const [selectedOption, setSelectedOption] = useState<DeleteOptions>(
        selectedIds.length === 0 ? "all" : "selected",
    );
    const [deleteRunbookOutputs] =
        useMutation<DeleteRunbookOutputsMutationInput>(
            loader("./delete-runbook-outputs.graphql"),
            {
                onCompleted: () => {
                    SuccessToaster({
                        message: pluralSinglularSuccess,
                    });
                },
                onError: (_err) => {
                    ErrorToaster({
                        message: pluralSinglularError,
                    });
                },
            },
        );

    /** Chooses text based on amount of selections made */
    const pluralSinglularSuccess =
        selectedIds.length > 1
            ? STRINGS.runbookAnalysesPage.toastMessages
                  .successDeleteRunbookOutputMulti
            : STRINGS.runbookAnalysesPage.toastMessages
                  .successDeleteRunbookOutputSingle;

    /** Chooses text based on amount of selections made */
    const pluralSinglularError =
        selectedIds.length > 1
            ? STRINGS.runbookAnalysesPage.toastMessages
                  .errorDeleteRunbookOutputMulti
            : STRINGS.runbookAnalysesPage.toastMessages
                  .errorDeleteRunbookOutputSingle;

    /** Chooses text based on amount of selections made */
    const pluralSinglularConfirmation =
        selectedIds.length > 1
            ? STRINGS.runbookAnalysesPage.deleteConfirmationMulti
            : STRINGS.runbookAnalysesPage.deleteConfirmationSingle;

    const hasNoSelectedItems = selectedIds.length === 0;

    const onDeleteHandler = async () => {
        await deleteRunbookOutputs({
            variables: {
                ids: selectedIds,
            },
            notifyOnNetworkStatusChange: true,
        });
        onSuccess();
    };

    const onSubmitHandler = () => {
        openConfirm({
            message: pluralSinglularConfirmation,
            onConfirm: () => onDeleteHandler(),
            icon: IconNames.TRASH,
            intent: Intent.PRIMARY,
        });
    };

    return (
        <Modal
            title={STRINGS.runbookAnalysesPage.modalTitle}
            customLabels={{
                submit: STRINGS.runbookAnalysesPage.modalSubmit,
                close: STRINGS.runbookAnalysesPage.modalClose,
            }}
            onSubmit={() => onSubmitHandler()}
            onClose={() => onClose()}
            usePortal={false}
        >
            <div className="container">
                <RadioGroup
                    data-testid={BEM_DELETE_RUNBOOK_MODAL}
                    name="delete-options"
                    onChange={(e) => {
                        const updatedValue = e.currentTarget
                            .value as DeleteOptions;
                        setSelectedOption(updatedValue);
                    }}
                    selectedValue={selectedOption}
                    inline={true}
                    className="d-flex flex-column"
                >
                    <Radio
                        label={STRINGS.formatString(
                            STRINGS.CUSTOM_PROPERTIES_PAGE.deleteDialog
                                .selectedLabel,
                            selectedIds.length,
                        )}
                        value={"selected"}
                        className="my-2"
                        disabled={hasNoSelectedItems}
                    />
                </RadioGroup>
            </div>
        </Modal>
    );
};
