
import React, { useCallback } from 'react';
import { HTMLSelect, IOptionProps, InputGroup, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Icon } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import './CompareControl.scss'

export enum CompareControlTargetValueTypes {
    INTEGER = "integer",
    FLOAT = "float",
    STRING = "string",
    REGEX = "regex"
}
type HTMLInputType = {
    [key in CompareControlTargetValueTypes]: string
}

const htmlInputType: HTMLInputType = {
    [CompareControlTargetValueTypes.INTEGER]: "number",
    [CompareControlTargetValueTypes.FLOAT]: "number",
    [CompareControlTargetValueTypes.STRING]: "text",
    [CompareControlTargetValueTypes.REGEX]: "text",
}
export interface Operator {
    id: string,
    label: string,
    [key: string]: any,
}
export interface Control {
    id: string | number; // unique id that identifies each expression.
    // attribute name (LHS) this has to be one of the values in the ControlConfigSet. Multiple expressions/Controls could
    // have the same attribute though the current use case does not support it.
    attribute?: string | undefined;
    label?: string | undefined;
    operator?: Operator;
    functionOperator?: Operator;
    value: any;
    type?: CompareControlTargetValueTypes;
    unit?: Unit| undefined;
}
export enum Action {
    "UPDATE" = "UPDATE",
    "DELETE" = "DELETE",
    "CREATE" = "CREATE"
}
export interface UpdatedControl {
    id?: string | number;
    action: Action;
    attribute?: string;
    operator?: string;
    unit?: string;
    fnOperator?: string;
    value?: string | number | undefined;
    function?: string;
}
export interface ControlConfig {
    attributeId: string ;
    label: string;
    operators: Array<Operator>;
    functionOperators?: Array<Operator>;
    type?: CompareControlTargetValueTypes;
    unit?: Unit | undefined;
}

export interface CompareControlsProps {
    controlSet: Array<Control> | undefined,
    controlSetConfig?: Array<ControlConfig>,
    onChange: Function,
    hideFunctionOperators: boolean,
};

interface AttributeOperatorMap {
    [key: string]: { operators: Array<IOptionProps>, fnOperators: Array<IOptionProps> }
}
function getHtmlTypeFor(type: CompareControlTargetValueTypes | undefined) {
    let result = "text"
    if (type) {
        result = htmlInputType[type] ? htmlInputType[type] : result;
    }
    return result;
}

/**
 * Component for rendering and editing comparison expressions. It has support for LHS (attribute), Operator, RHS(value) and an 
 * optional function operator dropdown
 * @param controlSet A list of expression 
 * @param controlSetConfig  All possible dropdown values for an expression
 * @param onCange - callback function invoked when the expression is modified
 * @param hideFunctionOperators - hide or show function operator dropdown
 * @return  JSX component 
 */
export function CompareControls({
    controlSet, controlSetConfig, onChange, hideFunctionOperators
}: CompareControlsProps) {
    /**
    * Generate the list of drop down options for LHS attribute dropdown. This is genrated using the  control config set prop.
    */
    const controlSelectOptions: Array<IOptionProps> = useCallback(() => {
        if (controlSetConfig) {
            return controlSetConfig.map((ctrlConf) => {
                const label = ctrlConf.label;
                return { value: ctrlConf.attributeId, label: label };
            })
        } else {
            return [];
        }
    }, [controlSetConfig])();

    // Map with key as attribute and value as list of valid operators for a given attribute.
    const attributeOperatorsMap: AttributeOperatorMap  = useCallback(() => {
        // return an object which is a map of  controlType:OperatorList
        const operatorMap = controlSetConfig?.reduce((accum, cur) => {
            const operatorsList: IOptionProps[] = cur.operators.map((opr) => {
                return { value: opr.id, label: opr.label };
            })
            const fnOperatorsList: IOptionProps[] | undefined = cur?.functionOperators?.map((opr) => {
                return { value: opr.id, label: opr.label };
            })
            accum[cur.attributeId] = {
                operators: operatorsList,
                fnOperators: fnOperatorsList
            }
            return accum;
        }, {});
        return operatorMap ? operatorMap : {}
    }, [controlSetConfig])()

    /**
    * Generate jsx elements for each expresssion
    * @param controlSet A list of expression
    * @return  Array of jsx element for each expression
    */
    const generateControls = function (controlSet: Control[] | undefined): Array<JSX.Element> {
        if (!controlSet) {
            return [];
        }
        // Hide attributes that have already been selected
        const controlsJsx = controlSet.map((control: Control, index) => {
            const controlSelectOptionsFiltered = controlSelectOptions.filter((opt) => {
                const ctrlExists = controlSet.find((ctrl) => {
                    return ctrl.label === opt.label;
                })
                return (ctrlExists && ctrlExists.label === control.label) ? true : !ctrlExists;
            })
            let unitOptions= [];
            if(control.unit && control.unit.unit && control.unit.isScalable()) {
                unitOptions = control.unit.getScaledUnitOptions().map((u)=>{
                    return {label: u, value: u};
                });
            }

            let fnOperatorElement:JSX.Element = <></>;
            if (!hideFunctionOperators) {
                if (control.attribute && attributeOperatorsMap[control.attribute]?.fnOperators) {
                    fnOperatorElement = <HTMLSelect aria-label="expression function operator" options={attributeOperatorsMap[control.attribute].fnOperators} value={control.functionOperator ? control.functionOperator.id : "_blank_"} className="fn-operator mr-2 exp-fn-operator" onChange={(e) => {
                        const updatedControl: UpdatedControl = {
                            action: Action.UPDATE,
                            id: control.id,
                            fnOperator: e.currentTarget.value
                        }
                        onChange(updatedControl);
                    }} />;
                }
            }
            return <div key={index} className="d-flex flex-row pt-3">
                {(!hideFunctionOperators) &&
                    fnOperatorElement
                }
                <HTMLSelect aria-label="expression attribute" name="attribute" className="flex-grow-2 exp-attr" options={controlSelectOptionsFiltered} value={control.attribute} onChange={(e) => {
                    const updatedControl: UpdatedControl = {
                        action: Action.UPDATE,
                        id: control.id,
                        attribute: e.currentTarget.value
                    }
                    onChange(updatedControl);
                }} />

                <HTMLSelect aria-label="expression operator" name="opertor" className="flex-grow-1 ml-1 exp-operator" options={control.attribute ? attributeOperatorsMap[control.attribute]?.operators : []} value={control?.operator?.id} onChange={(e) => {
                    const updatedControl: UpdatedControl = {
                        action: Action.UPDATE,
                        id: control.id,
                        operator: e.currentTarget.value
                    }
                    onChange(updatedControl);
                }}
                />
                <InputGroup aria-label="expression value" type={getHtmlTypeFor(control.type)} className="flex-grow-1 ml-1 exp-value" value={control?.value} onChange={(e) => {
                    const updatedControl: UpdatedControl = {
                        action: Action.UPDATE,
                        id: control.id,
                        value: e.currentTarget.value
                    }
                    onChange(updatedControl);
                }}
                />
                {(control.unit !== undefined && control.unit.unit && control.unit.isScalable()) &&
                    <HTMLSelect aria-label="expression unit" name="unit" className="flex-grow-1 ml-1 exp-unit" options={unitOptions} value={control.unit?.getDisplayName()} onChange={(e) => {
                        const updatedControl: UpdatedControl = {
                            action: Action.UPDATE,
                            id: control.id,
                            unit: e.currentTarget.value
                        }
                        onChange(updatedControl);
                    }} />
                }
                <Button  aria-label="delete expression" className="close-button" role="close blade" icon={IconNames.CROSS} minimal={true} onClick={(e) => {
                    const updatedControl: UpdatedControl = {
                        action: Action.DELETE,
                        id: control.id,
                    }
                    onChange(updatedControl);
                }}
                />
            </div>
        });
        return controlsJsx;
    }
    const controls = generateControls(controlSet);
    return (
        <div className="d-flex flex-column compare-control p-2 ">
            {controls}
            <div aria-label="add expression" className="pt-3" onClick={(e) => {
                e.preventDefault();
                const updatedControl: UpdatedControl = {
                    action: Action.CREATE,
                }
                onChange(updatedControl);
            }}>
                <Icon icon={IconNames.PLUS} />
                <span className="add-text">{STRINGS.runbookEditor.nodeLibrary.propertyLabels.addCondition}</span>
            </div>
        </div>
    );
}
