import React from "react";
import { Tooltip2 } from "@blueprintjs/popover2";
import { PopoverInteractionKind } from "@blueprintjs/core";
import { STRINGS } from "app-strings";

export interface ListWithOverflowProps {
    className?: string;
    overflowAfter?: number;
    items?: Array<any>;
}

export function ListWithOverflow ({
    className,
    overflowAfter = 1,
    items
}: ListWithOverflowProps) {
    if (items && items.length > 0) {
        const itemsCopy = [...items];
        const itemsInTooltip = itemsCopy.splice(overflowAfter);
        return <div className={(className && itemsCopy.length > 0 ? className + " " : "") + "d-inline-block"}>
            {itemsCopy.join(", ")}
            {
                itemsInTooltip.length > 0 &&
                <div className="d-block display-10 btn-link pl-1">
                    <Tooltip2
                        interactionKind={PopoverInteractionKind.HOVER}
                        content={
                            <div className="h-max-1-5 overflow-auto p-2">{
                                itemsInTooltip.map((item, index) => <div key={"overflow-list-item-" + index}>{item}</div>)
                            }</div>
                        }
                    >
                        {itemsInTooltip.length + " " + STRINGS.more}
                    </Tooltip2>
                </div>
            }
        </div>;
    } else {
        return null;
    }
}