import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Handle, Mode, RangeSlider, RangeSliderPosition, Segment, Steps } from "../range-slider/RangeSlider";
import moment, { Moment } from "moment";
import './TimeRangeSlider.css'

export type TimeRangeSliderProps = {
    startTime: EPOCInMilliSec;
    endTime: EPOCInMilliSec;
    onNewRangeSelection: (arg0: RangeInfo) => void;
    onNewResolutionSelection: (arg0: ResolutionInfo) => void;
    resolution: RESOLUTION;
};
export interface ResolutionInfo {
    value?: RESOLUTION;
    index: number;
}
export interface RangeInfo {
    startTime: EPOCInMilliSec;
    endTime: EPOCInMilliSec;
}
export interface TimeRange {
    start: EPOCInMilliSec;
    end: EPOCInMilliSec;
}
export type EPOCInMilliSec = number;
export enum RESOLUTION {
    M15 = "M15",
    M30 = "M30",
    H1 = "H1",
    H2 = "H2",
    H4 = "H4",
    H6 = "H6",
    H8 = "H8",
    H12 = "H12",
    D1 = "D1",
    D2 = "D2",
    W1 = "W1",
    W2 = "W2",
    M1 = "M1", // 4 weeks
    M2 = "M2", // 8 weeks
    M3 = "M3" // 16 weeks
}
export const RESOLUTION_ORDERED: Array<RESOLUTION> = [
    RESOLUTION.M15,
    RESOLUTION.M30,
    RESOLUTION.H1,
    RESOLUTION.H2,
    RESOLUTION.H4,
    RESOLUTION.H6,
    RESOLUTION.H8,
    RESOLUTION.H12,
    RESOLUTION.D1,
    RESOLUTION.D2,
    RESOLUTION.W1,
    RESOLUTION.W2,
    RESOLUTION.M1,
    RESOLUTION.M2,
    RESOLUTION.M3];
const RESOLUTION_TO_STEPS_MAP = {
    M15: 15,
    M30: 30,
    H1: 60,
    H2: 24,     // 5 mins
    H4: 48,     // 5 mins
    H6: 72,     // 5 mins
    H8: 48,     // 10 mins
    H12: 72,    // 10 mins
    D1: 24,     // 1 hr
    D2: 24,     // 1 hr
    W1: 7 * 12,      // 2 hr
    W2: 14 * 12,     // 2 hr
    M1: 28,     // 1 day
    M2: 28 * 2, // 1 day
    M3: 28 * 3  // 1 day
}
const RESOLUTION_IN_MIN = {
    M15: 15,
    M30: 30,
    H1: 60,
    H2: 60 * 2,
    H4: 60 * 4,
    H6: 60 * 6,
    H8: 60 * 8,
    H12: 60 * 12,
    D1: 60 * 24,
    D2: 60 * 24 * 2,
    W1: 60 * 24 * 7,
    W2: 60 * 24 * 7 * 2,
    M1: 60 * 24 * 7 * 4,
    M2: 60 * 24 * 7 * 8,
    M3: 60 * 24 * 7 * 12,
}

export function TimeRangeSlider({
    startTime, endTime, resolution, onNewRangeSelection, onNewResolutionSelection
}: TimeRangeSliderProps) {

    const totalSegments: number = 4;
    const minStepstoBumpUpResolution: number = 2;
    const totalStepsInSlider: number = totalSegments * RESOLUTION_TO_STEPS_MAP[resolution];

    const computeSliderStartEndTime = useCallback((time: EPOCInMilliSec, res: RESOLUTION, shift = 0): TimeRange => {
        res = res ? res : resolution;
        const shiftedTime = time - (RESOLUTION_IN_MIN[res] * shift * 60 * 1000);
        const newSliderStartTime = roundToNearset(res, shiftedTime);
        const newSliderEndTime = newSliderStartTime + (totalSegments * RESOLUTION_IN_MIN[res] * 60 * 1000);
        return { start: newSliderStartTime, end: newSliderEndTime };
    }, [resolution])

    const getSegments = useCallback((trackStartTime: EPOCInMilliSec): Array<Segment> => {
        const segments: Array<Segment> = [];
        let resInMs = (RESOLUTION_IN_MIN[resolution] * 60 * 1000);
        let prevDate = moment(trackStartTime).format("ll")
        for (let i = 0; i <= totalSegments; i++) {
            const time = trackStartTime + (resInMs * i);
            const timeFormatted = moment(time).format("LT");
            const date = `${moment(time).format("D")} ${moment(time).format("MMM")} `;
            const style = i === totalSegments ?  {textAlign: 'right' as 'right'} : {textAlign: 'left' as 'left'};
            let segment = prevDate !== date ?
                {
                    id: `${timeFormatted} ${date}`,
                    label: <div className="label-format" style={style}> {timeFormatted}<div>{date}</div></div>
                } :
                {
                    id: timeFormatted,
                    label: <div className="label-format" style={style}> {timeFormatted} </div>
                }
            prevDate = date;
            segments.push(segment);
        }
        return segments;
    }, [resolution]);

    const convertTimeToSteps = useCallback((time: EPOCInMilliSec, trackStartTime: EPOCInMilliSec, trackEndTime: EPOCInMilliSec): Steps => {
        return (Math.round((time - trackStartTime) / (trackEndTime - trackStartTime) * (totalStepsInSlider)))
    }, [totalStepsInSlider]);

    const getMaxValidSteps = useCallback((trackStartTime: EPOCInMilliSec, trackEndTime: EPOCInMilliSec): Steps | undefined => {
        const curTime = moment().valueOf();
        if (curTime >= trackStartTime && curTime <= trackEndTime) {
            return convertTimeToSteps(curTime, trackStartTime, trackEndTime);
        }
        return undefined;
    }, [convertTimeToSteps]);

    // States for this component
    const sliderTimeRange: TimeRange = computeSliderStartEndTime(startTime, resolution);
    const [trackStartTime, setTrackStartTime] = useState<EPOCInMilliSec>(sliderTimeRange.start);
    const [trackEndTime, setTrackEndTime] = useState<EPOCInMilliSec>(trackStartTime + (totalSegments * RESOLUTION_IN_MIN[resolution] * 60 * 1000));
    const [rightHandleLabel, setRightHandleLabel] = useState(moment(endTime).format("LT"));
    const [leftHandleLabel, setLeftHandleLabel] = useState(moment(startTime).format("LT"));
    const [labels, setLabels] = useState(getSegments(sliderTimeRange.start));
    const [leftHandlePosition, setLeftHandlePosition] = useState<Steps>(convertTimeToSteps(startTime, trackStartTime, trackEndTime));
    const [rightHandlePosition, setRightHandlePosition] = useState<Steps>(convertTimeToSteps(endTime, trackStartTime, trackEndTime));
    // Max time that can be selected is current time.
    const maxRightHandlePosition = useRef<Steps | undefined>(getMaxValidSteps(trackStartTime, trackEndTime));
    const getHandleLabelForTime = useCallback((time: Moment): string => {
        let dateTimeFormat = isResolutionGreaterThan(resolution, RESOLUTION.D2) ? "DD MMM" : "LT"; // displays time
        return time.format(dateTimeFormat);
    }, [resolution]);

    const doesRangeFitWithinSlider = useCallback((sTime: EPOCInMilliSec, eTime: EPOCInMilliSec): boolean => {
        return sTime >= trackStartTime && eTime <= trackEndTime
    }, [trackStartTime, trackEndTime]);

    const validateResolution = useCallback((sTime: EPOCInMilliSec, eTime: EPOCInMilliSec, res: RESOLUTION): boolean => {
        const { start, end } = computeSliderStartEndTime(sTime, res);
        return (sTime >= start && eTime <= end);
    }, [computeSliderStartEndTime])

    const refreshSlider = useCallback((sTime: EPOCInMilliSec, eTime: EPOCInMilliSec, resolution: RESOLUTION, shift = 0): void => {
        const { start: trackStartTime } = computeSliderStartEndTime(sTime, resolution, shift);
        const newtrackEndTime = trackStartTime + (totalSegments * RESOLUTION_IN_MIN[resolution] * 60 * 1000)
        setTrackStartTime(trackStartTime);
        setTrackEndTime(newtrackEndTime);
        maxRightHandlePosition.current = getMaxValidSteps(trackStartTime, newtrackEndTime);
        setLeftHandlePosition(convertTimeToSteps(sTime, trackStartTime, newtrackEndTime));
        setRightHandlePosition(convertTimeToSteps(eTime, trackStartTime, newtrackEndTime));
        setLabels(getSegments(trackStartTime));
    }, [computeSliderStartEndTime, convertTimeToSteps, getSegments, getMaxValidSteps]);


    const getResolutionBasedOnRange = useCallback((sTime: EPOCInMilliSec, eTime: EPOCInMilliSec) => {
        let result = RESOLUTION_ORDERED.find((res) => {
            return validateResolution(sTime, eTime, res);
        });
        // pick a resultion that is one level lower than the current range for asthetic reasons.
        const adjResolution = getAdjacentResolution(result, 1);
        result = adjResolution ? adjResolution : result;
        return result;
    }, [validateResolution]);

    // Effects
    useEffect(() => {
        setRightHandleLabel(getHandleLabelForTime(moment(endTime)))
    }, [endTime, trackStartTime, trackEndTime, getHandleLabelForTime]);

    useEffect(() => {
        setLeftHandleLabel(getHandleLabelForTime(moment(startTime)))
    }, [startTime, trackStartTime, trackEndTime, getHandleLabelForTime]);

    useEffect(() => {
        if (!doesRangeFitWithinSlider(startTime, endTime)) {
            let newResolution = getResolutionBasedOnRange(startTime, endTime);
            onNewResolutionSelection({ value: newResolution, index: getResolutionIndex(newResolution) });
            refreshSlider(startTime, endTime, resolution);
        } else {
            setLeftHandlePosition(convertTimeToSteps(startTime, trackStartTime, trackEndTime));
            setRightHandlePosition(convertTimeToSteps(endTime, trackStartTime, trackEndTime));
        }
    }, [startTime, endTime, doesRangeFitWithinSlider, getResolutionBasedOnRange, convertTimeToSteps, refreshSlider, onNewResolutionSelection, trackEndTime, trackStartTime, resolution, totalStepsInSlider]);

    useEffect(() => {
        refreshSlider(startTime, endTime, resolution);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshSlider, resolution]);


    // Note: The following section of the code is commented since we are currently not displaying the resolution slider
    // Uncomment it if the slider needs to be exposed.
    // useEffect(() => {
    //     if (!validateResolution(startTime, endTime, resolution)) {
    //         let newResolution = getResolutionBasedOnRange(startTime, endTime);
    //         onNewResolutionSelection({ value: newResolution, index: getResolutionIndex(newResolution) });
    //         return;
    //     }
    //     refreshSlider(startTime, endTime, resolution);
    // }, [resolution, onNewResolutionSelection, startTime, endTime, getResolutionBasedOnRange, refreshSlider, validateResolution]);


    function expandRangeToLeft(newPosition: RangeSliderPosition): void {
        let sTime: EPOCInMilliSec = getDateTimeFromSteps(newPosition.leftPosition)
        let eTime: EPOCInMilliSec = getDateTimeFromSteps(newPosition.rightPosition)
        // Determine the total number of segments the End handle can be shifted without going out of range.
        if (sTime === trackStartTime) {
            const segAvilableToShift = Math.floor((trackEndTime - eTime) / (RESOLUTION_IN_MIN[resolution] * 60 * 1000));
            if (segAvilableToShift > 0) {
                refreshSlider(sTime, eTime, resolution, segAvilableToShift);
            } else {
                // The handlers cannot be adjusted, fall back to changing the resolution.
                const newResolution = getAdjacentResolution(resolution, +1);
                if (newResolution !== undefined) {
                    onNewResolutionSelection({ value: newResolution, index: getResolutionIndex(newResolution) });
                }
            }
        }

    }

    function expandRangeToRight(newPosition: RangeSliderPosition): void {
        let eTime: EPOCInMilliSec = getDateTimeFromSteps(newPosition.rightPosition)
        let sTime: EPOCInMilliSec = getDateTimeFromSteps(newPosition.leftPosition)
        // Determine the total number of segments the End handle can be shifted without going out of range.
        if (eTime === trackEndTime) {
            const segAvilableToShift = Math.floor(Math.abs(trackStartTime - sTime) / (RESOLUTION_IN_MIN[resolution] * 60 * 1000));
            if (segAvilableToShift > 0) {
                refreshSlider(sTime, eTime, resolution);
            } else {
                // The handlers cannot be adjusted, fall back to changing the resolution.
                const newResolution = getAdjacentResolution(resolution, 1);
                if (newResolution !== undefined) {
                    onNewResolutionSelection({ value: newResolution, index: getResolutionIndex(newResolution) });
                }
            }
        }
    }

    function getAdjacentResolution(res: RESOLUTION | undefined, shift: number): RESOLUTION | undefined {
        if (res === undefined) {
            // Fall back to the lowest resolution
            console.warn("Resolution exceded : This should not typically happen");
            return RESOLUTION.M3;
        }
        let index = getResolutionIndex(res);
        index += shift;
        // returns the same resolution if adjacent resolution does not exists.
        return (index >= 0 && index <= RESOLUTION_ORDERED.length) ? RESOLUTION_ORDERED[index] : res;
    }

    function getDateTimeFromSteps(steps: number | undefined): EPOCInMilliSec {
        if (steps === undefined) {
            throw new Error("invalid steps provided");
        }
        const totalMinsOffset = steps * Math.round(RESOLUTION_IN_MIN[resolution] / RESOLUTION_TO_STEPS_MAP[resolution]);
        return trackStartTime + (totalMinsOffset * 60 * 1000);
    }

    function roundToNearset(res: RESOLUTION, time: number) {
        // in milli sec
        const startOfDayTime = moment(time).startOf('day').valueOf();
        // resolution in milli sec
        const resInMs = (RESOLUTION_IN_MIN[res] * 60 * 1000);
        // check if the time is closer to the begining of the day. If yes then use that as slider start time
        // This ensures when uses use lower resoultion (ex day, week, month) the time starts at 12AM
        if (time - startOfDayTime < resInMs) {
            return startOfDayTime;
        }
        const offset = (time % resInMs);
        const roundedTime = time - offset;
        return roundedTime;
    }

    function onRangeSelection(newPosition: RangeSliderPosition) {
        onNewRangeSelection(
            {
                startTime: getDateTimeFromSteps(newPosition.leftPosition),
                endTime: getDateTimeFromSteps(newPosition.rightPosition)
            });
        if (newPosition.triggerHandleId === Handle.start) {
            expandRangeToLeft(newPosition);
        } else if (newPosition.triggerHandleId === Handle.end) {
            expandRangeToRight(newPosition);
        } else {
            expandRangeToLeft(newPosition);
            expandRangeToRight(newPosition);
        }

        // increase resolution when the sliders are very close to each other
        let leftSteps = newPosition.leftPosition !== undefined ? newPosition.leftPosition : 0;
        let rightSteps = newPosition.rightPosition !== undefined ? newPosition.rightPosition : 0;
        if (rightSteps - leftSteps <= minStepstoBumpUpResolution) {
            let newRes = getResolutionBasedOnRange(getDateTimeFromSteps(leftSteps), getDateTimeFromSteps(rightSteps));
            if (newRes !== undefined) {
                onNewResolutionSelection({ value: newRes, index: getResolutionIndex(newRes) });
            }
        }

    }

    function onHandlePositionChange(newPosition: RangeSliderPosition) {
        setLeftHandleLabel(getHandleLabelForTime(moment(getDateTimeFromSteps(newPosition.leftPosition))))
        setRightHandleLabel(getHandleLabelForTime(moment(getDateTimeFromSteps(newPosition.rightPosition))))
    }

    return (
        <RangeSlider segments={labels} stepsPerSegment={RESOLUTION_TO_STEPS_MAP[resolution]} id="timeslider"
            mode={Mode.rangeSlider}
            onNewRangeSelection={onRangeSelection}
            onHandlePositionChange={onHandlePositionChange}
            maxEndHandlePosition={maxRightHandlePosition.current}
            startHandlePosition={leftHandlePosition} endHandlePosition={rightHandlePosition}
            startHandleLabel={leftHandleLabel} endHandleLabel={rightHandleLabel} />
    )
}

function isResolutionGreaterThan(res: RESOLUTION, compareAgainst: RESOLUTION): boolean {
    return getResolutionIndex(res) > getResolutionIndex(compareAgainst);
}

export function getResolutionIndex(res: RESOLUTION | undefined): number {
    if (res === undefined) {
        throw new Error("undefined Resolution index passed as argument")
    }
    return RESOLUTION_ORDERED.findIndex((curRes) => curRes === res);
};

export function getResolution(index: number): RESOLUTION {
    return RESOLUTION_ORDERED[index];
}

// Utility functions for debugging
// function toHuman(time) {
//     return time ? moment(time).format("lll") : time;
// }
