/** This file defines the global event mappings store.  This store keeps track of which events
 *      are mapped in this user's tenant.
 *  @module 
 */

import { MappingConfigService, MappingConfig } from "utils/services/MappingConfigApiService";


/** the array of trigger types that are part of this tenant. */
let triggerTypes: Array<string> = [];

/** initializes the event mappings store. */
export async function init() {
    try {
        triggerTypes = [];
        const eventMappings: Array<MappingConfig> = await MappingConfigService.getMappings() as any;
        if (eventMappings?.length) {
            for (const mapping of eventMappings) {
                const triggerType: string = mapping.trigger;
                if (!triggerTypes.includes(triggerType)) {
                    triggerTypes.push(triggerType);
                }
            }    
        }
    } catch (error) {
        console.error("Could not get list of event mappings");
    }
}

/** returns whether or not the specified trigger type has an event mapping in this tenant.
 *  @param type the type of trigger to check for an event mapping.
 *  @returns a boolean value which is true if the specified trigger type is part of this tenant. */
export function hasEventMapping(triggerType: string): boolean {
    return triggerTypes.includes(triggerType);
}

/** returns the list of trigger types.
 *  @returns an Array with the trigger types that are in this tenant. */
export function getTriggerTypes(): string[] {
    return triggerTypes;
}
