import React from 'react';
import classNames from 'classnames';
import { Classes, Divider } from '@blueprintjs/core';
import { Form, InputField, SelectField, TextAreaField } from 'components/common/form';
import { STRINGS } from 'app-strings';
import { AuthenticationMethodTypeEnum } from 'utils/services/ThirdPartyIntegrationApiService';
import * as yup from 'yup';
import { FormikProps } from 'formik';

export interface IBasicAuthDetailsPanelProps {
	authProfileName?: string;
	authProfileDescription?: string;
    authProfileMethod?: AuthenticationMethodTypeEnum;
    onChangeName: (event) => void;
    onChangeDescription: (event) => void;
	onChangeAuthMethod: (event) => void;
	profileNameList?: string[];
}

export function getAuthMethod(authMethod) {
	if (authMethod && typeof authMethod === 'string') {
		if (['AwsSignatureV4', 'AwsSignatureV4A'].includes(authMethod)) {
			return AuthenticationMethodTypeEnum[4]
		}

		return authMethod as keyof typeof AuthenticationMethodTypeEnum;
	} else if (typeof authMethod === 'number') {
		return authMethod === 5 ? AuthenticationMethodTypeEnum[4] : AuthenticationMethodTypeEnum[authMethod];
	} else {
		return 0;
	}
}

export enum AuthenticationMethods {
	'ApiKeyAuthentication' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
		.basicDetails.fields.profileAuthMethod.apiKey,
	'awsSignature' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
		.basicDetails.fields.profileAuthMethod.awsSignatureAuth,
    'BasicAuthentication' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
		.basicDetails.fields.profileAuthMethod.basicAuth,
    'ClientCertificate' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
		.basicDetails.fields.profileAuthMethod.clientCertificateAuth,
    'OAuth2' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
		.basicDetails.fields.profileAuthMethod.openAuth,
}

const BasicAuthDetailsPanel: React.FC<IBasicAuthDetailsPanelProps> = (props) => {

	const { ENV } = window['runConfig'] ? window['runConfig'] : { ENV: '' };

	const validationSchema = yup.object().shape({
		profile_name: yup
			.string()
			.required()
			.max(128)
			.label(STRINGS.thirdPartyIntegrations.addAuthProfile.panels.basicDetails.fields.profileName.label)
			.notOneOf(props.authProfileName ? props.profileNameList || [] : []),
		profile_description: yup
			.string()
			.max(256)
			.label(STRINGS.thirdPartyIntegrations.addAuthProfile.panels
				.basicDetails.fields.profileDescription.label),
		profile_auth_method: yup
			.string()
			.required()
			.notOneOf([
				STRINGS.thirdPartyIntegrations.addAuthProfile.panels.basicDetails.selectMethod,
			])
			.label(STRINGS.thirdPartyIntegrations.addAuthProfile.panels
				.basicDetails.fields.profileAuthMethod.label),
	});

	const profileAuthOptions = getProfileAuthOptions(ENV);

	function authMethodChangeHandler(newValue) {
        if (props.onChangeAuthMethod) {
            props.onChangeAuthMethod(AuthenticationMethods[newValue]);
        }
    }

    return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{
						STRINGS.thirdPartyIntegrations.addAuthProfile.panels
							.basicDetails.title
					}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={{
					profile_name: props.authProfileName,
					profile_description: props.authProfileDescription,
                    profile_auth_method: props.authProfileMethod
				}}
				validationSchema={validationSchema}
				loading={false}
			>
				{(formProps: FormikProps<object>) => <>
				<InputField
					name="profile_name"
					type="text"
					required={true}
					label={STRINGS.thirdPartyIntegrations.addAuthProfile.panels.basicDetails.fields.profileName.label
					}
                    placeholder={
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .basicDetails.fields.profileName.placeholder
                    }
                    onBlur={(event) => {
                        const value = event.target.value?.trim();
						formProps.handleBlur(event);

                        props.onChangeName({target: {
                            value: value
                        }});
                    }}
					onChange={props.onChangeName}
					value={props.authProfileName}
					disabled={false}
				/>
                <TextAreaField
					name="profile_description"
					rows={5}
					label={
						STRINGS.thirdPartyIntegrations.addAuthProfile.panels
							.basicDetails.fields.profileDescription.label + ' '
						+ STRINGS.thirdPartyIntegrations.addAuthProfile.panels
						.basicDetails.fields.profileDescription.optional
					}
                    placeholder={
                        STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                            .basicDetails.fields.profileDescription.placeholder
                    }
					onChange={props.onChangeDescription}
					value={props.authProfileDescription}
					disabled={false}
				/>
				<SelectField
					label={
						STRINGS.thirdPartyIntegrations.addAuthProfile.panels
							.basicDetails.fields.profileAuthMethod.label
					}
					name="profile_auth_method"
					required={true}
					options={profileAuthOptions}
					onChange={(event) => {
						authMethodChangeHandler(event.target.value);
					}}
					value={AuthenticationMethods[getAuthMethod(props.authProfileMethod)]}
				/>
				</>}
			</Form>
		</div>
	);
};

export { BasicAuthDetailsPanel };

/**
 * Get Profile Auth Options
 * 
 * @param ENV 
 * @param awsSignatureAuthLabel 
 * @returns 
 */
export function getProfileAuthOptions(ENV: any) {
	return [
		STRINGS.thirdPartyIntegrations.addAuthProfile.panels.basicDetails.fields
			.profileAuthMethod.selectMethod,
	].concat(
		Object.keys(AuthenticationMethods).filter(
			(item) => item.includes(" ")
		)
	);
}

