import React from "react";
import { SEVERITY, SIZE } from "components/enums";
import { Mode, StatusLED } from "../status-led/StatusLED";
import { STRINGS } from "app-strings"


export const HealthSeverityToParamMap = {
    [SEVERITY.CRITICAL]: "critical",
    [SEVERITY.DEGRADED]: "degraded",
    [SEVERITY.NORMAL]: "normal",
    [SEVERITY.INITIALIZING]: "unknown",
    [SEVERITY.UNKNOWN]: "unknown"
};

export type HealthSummaryLEDProps = {
    normal?: number | undefined;
    degraded?: number | undefined;
    critical?: number | undefined;
    unknown?: number | undefined;
    size?: SIZE;
    alwaysShowAllLEDs?: boolean;
    showWhenZero?: boolean;
    onItemClick?: (e: SEVERITY) => void;
    mode?: Mode;
};

export function HealthSummaryLED({ normal, degraded, critical, unknown, size = SIZE.m, alwaysShowAllLEDs = false, showWhenZero = false, onItemClick, mode = Mode.icon }: HealthSummaryLEDProps) {
    const ledElements: Array<JSX.Element> = [];
    let firstElement = true;
    const ml2 = "ml-4";
    function handleOnClick(status: SEVERITY) {
        if (onItemClick) {
            onItemClick(status);
        }
    }
    if (normal || (normal === 0 && showWhenZero) || alwaysShowAllLEDs) {
        const count = normal ? normal : 0;
        ledElements.push(
            <StatusLED key={SEVERITY.NORMAL} status={SEVERITY.NORMAL} onClick={handleOnClick} toolTip={STRINGS.Normal} label={count} size={size} icon="square" className={firstElement ? "" : ml2} mode={mode}/>
        );
        firstElement = false;
    }
    if (degraded || (degraded === 0 && showWhenZero) || alwaysShowAllLEDs) {
        const count = degraded ? degraded : 0;
        ledElements.push(
            <StatusLED key={SEVERITY.DEGRADED} status={SEVERITY.DEGRADED} onClick={handleOnClick} toolTip={STRINGS.Degraded} label={count} size={size} icon="square" className={firstElement ? "" : ml2} mode={mode} />
        );
        firstElement = false;
    }
    if (critical || (critical === 0 && showWhenZero) || alwaysShowAllLEDs) {
        const count = critical ? critical: 0;
        ledElements.push(
            <StatusLED key={SEVERITY.CRITICAL} status={SEVERITY.CRITICAL} onClick={handleOnClick} toolTip={STRINGS.Critical} label={count} size={size} icon="square" className={firstElement ? "" : ml2} mode={mode} />
        );
        firstElement = false;
    }
    if (unknown || (unknown === 0 && showWhenZero) || alwaysShowAllLEDs) {
        const count = unknown ? unknown: 0;
        ledElements.push(
            <StatusLED key={SEVERITY.UNKNOWN} status={SEVERITY.UNKNOWN} onClick={handleOnClick} toolTip={STRINGS.Unknown} label={count} size={size} icon="square" className={firstElement ? "" : ml2} mode={mode}/>
        );
        firstElement = false;
    }
    return (
        <span className="d-inline-flex align-items-center ">
            {ledElements}
        </span>
    );
}
