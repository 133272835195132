/** This module contains the component that displays a dialog that shows the trend analysis chart
 *  for the capacity planning demo.  This version of the dialog just displays the trending chart.
 *  There is another version of the dialog that allows users to configure the thresholds and then 
 *  display the chart.  That dialog is called the CapacityPlanningModal.
 *  @module
 */
import React, { useState, useEffect, useCallback } from "react";
import { Classes, Dialog } from "@blueprintjs/core";
import classNames from "classnames";
import AnalysisChart from "./AnalysisChart";
import { STRINGS } from "app-strings";

/** Renders the capacity planning dialog React component.
 *  @param props the properties passed in.
 *  @returns JSX with the cpacity planning dialog component.*/
const CapacityPlanningDialog = React.forwardRef((props: any, ref): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	//const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
	const handleClose = useCallback(() => setIsOpen(false), []);
    const [editInfos, setEditInfos] = useState<any>(null);
    console.log(editInfos);

    React.useImperativeHandle(ref, () => ({
        handleOpen(editInfos) {
            setIsOpen(!isOpen);
            setEditInfos(editInfos);
        },
    }));

    useEffect(() => {
        /*
        if (props.inputs) {
            props.inputs.current = {};
        }
        if (props.editInfos) {
            props.setRunbookId(props.editInfos.runbook.id);
        }
        */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
		<>
			<Dialog title={STRINGS.capacityPlanning.wizard.title} isOpen={isOpen} autoFocus={true} canEscapeKeyClose={true}
				canOutsideClickClose={true} enforceFocus={true} usePortal={true} onClose={handleClose}
				style={{ width: 0.75 * window.innerWidth }}
			>
                <div className={classNames(Classes.DIALOG_BODY)} style={{}/*{width: "900px", height: "700px"}*/}>
                    <AnalysisChart height={480} timeout={0} threshold1={props.threshold1} threshold2={props.threshold2} />
                </div>
			</Dialog>
		</>
	);
});

export default CapacityPlanningDialog;

