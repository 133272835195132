import React, { ReactNode/*, useCallback, useEffect, useRef, useState*/ } from 'react';
//import MapGL from 'react-map-gl';
//import _ from 'lodash';

// import { FlyToInterpolator, TRANSITION_EVENTS } from 'react-map-gl';
//import 'mapbox-gl/dist/mapbox-gl.css';

// This is a dependency of react-map-gl even if you didn't explicitly install it
//import mapboxgl from "mapbox-gl";
//@ts-ignore react scripts build complains about this but we need this
// eslint-disable-next-line import/no-webpack-loader-syntax
//mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

//const { MAPBOX_API_KEY } = window["runConfig"] || {};

export interface MarkerLayerProps {
    interactiveLayerIds?: string[]
    children?: any //Source
}
export declare const MarkerLayer: React.MemoExoticComponent<(props:MarkerLayerProps) => null>;
export interface GeoMapProps {
    id?: string,
    dark?: boolean,
    zoom?: number,
    center?: [number, number],
    bounds?: [number, number, number, number],
    afterMapReady?: (mapHandle) => void,
    onMapUnmount?: () => void,
    onMapClicked?: () => void,
    onPositionChange?: Function,
    animatePositionChange?: boolean,
    children?: any, //TBD: Better typing here
    className?: string
}

export interface MapLayerProps {
    interactiveLayerIds?: string[]
    children: ReactNode
    onMouseEnter?: (event: any, feature: any) => void
    onMouseLeave?: (event: any, feature: any) => void
    onClick?: (event: any, feature: any) => void
}

function GeoMap ({ animatePositionChange = true, ...props } : GeoMapProps) {
    /*
    const initialized = useRef(false);
    const mounted = useRef(true);
    const lastFlyTo = useRef<any>();
    const flyToInProgress = useRef(false);

    const [ lng, lat ] = props.center || [];
    const [viewport, setViewport] = useState({
        latitude: lat || 0,
        longitude: lng || 0,
        zoom: props.zoom,
        bearing: 0,
        pitch: 0
    });
    const viewPortRef = useRef(viewport);
    viewPortRef.current = viewport;

    const mapRef = useRef<any>(null);
    function getMapHandle () {
        return mapRef.current?.getMap();
    }
    const getMapHandleRef = useRef(getMapHandle);
    getMapHandleRef.current = getMapHandle;

    // Set initialized flag to true after render completes.
    useEffect(() => {
        initialized.current = true;
        return () => { mounted.current = false; };
    }, []);

    // If component has initialized already and map handle is available
    if (initialized.current && mapRef.current && !(getMapHandle()?._moving || getMapHandle()?._zooming) && (props.zoom || props.center)) {
        const zoom = getMapZoom();
        const center = getMapCenter();
        // If zoom or center values aren't matching between props and actual value from map instance
        if ((props.zoom && props.zoom !== zoom) ||
            (props.center && (props.center[0] !== center?.lng || props.center[1] !== center?.lat))) {
            flyTo({ zoom: props.zoom, center: props.center });
        }
    }

    function flyTo (input) {
        // An extra safety check to prevent duplicate attempts to fly to same position
        if (input && !_.isEqual(lastFlyTo.current, input)) {
            lastFlyTo.current = input;
            flyToInProgress.current = true;
            const mapHandle = getMapHandleRef.current();
            if (mapHandle) {
                if (animatePositionChange) {
                    mapHandle.flyTo({ speed: 2, curve: 1, ...input });
                    // Update viewport variables after flying is complete
                    mapHandle.once('moveend', function() {
                        setViewport(Object.assign({}, viewPortRef.current, {
                            zoom: input.zoom,
                            longitude: input.center[0],
                            latitude: input.center[1]
                        }));
                    });
                } else {
                    setViewport(Object.assign({}, viewPortRef.current, {
                        zoom: input.zoom,
                        longitude: input.center[0],
                        latitude: input.center[1]
                    }));
                }
            }
        }
    }

    function getMapZoom () {
        const rawZoom = getMapHandleRef.current()?.getZoom() || undefined;
        return rawZoom ? Math.round(rawZoom * 100)/100 : undefined;
    }

    function getMapCenter () {
        const center = getMapHandleRef.current()?.getCenter();
        if (center && center.lat && center.lng) {
            // We don't want to pass center object directly as it has additional unnecessary attributes
            return {
                lat: Math.round(center.lat * 1000000) / 1000000,
                lng: Math.round(center.lng * 1000000) / 1000000
            };
        } else {
            return undefined;
        }
    }

    function afterMapReady (e) {
        // Sometimes ready event for the map fires in a slight delay. By that time, the geomap could've unmounted.
        // Adding a check here to prevent memory leaks that will be caused by firing event handlers on an umounted component.
        if (mounted.current) {
            if (props.afterMapReady) {
                props.afterMapReady(e.target);
            }
        }
    }

    const mapMoveTimer = useRef<any>();

    useEffect(() => {
        // const map = mapHandle;
        const map = getMapHandleRef.current();
        if (map) {
            map.on("zoomend", onMapMoved);
            map.on("moveend", onMapMoved);
        }
        var timerCount = 0;
        // When a zoom or move happens, debounce it within the below handler
        function onMapMoved () {
            if (props.onPositionChange) {
                if (mapMoveTimer.current) {
                    clearTimeout(mapMoveTimer.current);
                    mapMoveTimer.current = undefined;
                }
                mapMoveTimer.current = setTimeout(function () {
                    const zoom = getMapZoom();
                    const center = getMapCenter();
                    mapMoveTimer.current = null;
                    let triggerPositionChange = false;
                    timerCount++;
                    if (flyToInProgress.current && timerCount < 5) {
                        const currentPosition = { zoom, center: [center?.lng, center?.lat] };
                        // If flying is in progress, then call self to set another timeout and try again
                        if (map._moving || map._zooming) {
                            onMapMoved();
                        // If final location hasn't been reached yet
                        } else if (!_.isEqual(lastFlyTo.current, currentPosition)) {
                            onMapMoved();
                        // If not flying anymore and now it has reached it's end, update flags
                        } else {
                            flyToInProgress.current = false;
                            triggerPositionChange = true;
                        }
                    } else {
                        // Standard case where the user has caused a map move
                        flyToInProgress.current = false;
                        triggerPositionChange = true;
                    }
                    if (triggerPositionChange && props.onPositionChange && zoom && center && center.lat && center.lng) {
                        props.onPositionChange({ zoom, center });
                        timerCount = 0;
                    }
                }, 500);
            }
        }
        return function () {
            if (map) {
                map.off("zoomend", onMapMoved);
                map.off("moveend", onMapMoved);
            }
            if (props.onMapUnmount) {
                props.onMapUnmount();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef.current]);

    function onMapClicked (event) {
        if (props.onMapClicked) {
            // Check if click happened on an interactive item. If yes, then skip onMapClicked
            const map = getMapHandleRef.current();
            const features = map?.queryRenderedFeatures(event.point);
            const interactiveItem = features?.find(feature => interactiveLayersMap.current[feature.layer.id]);
            if (interactiveItem) {
                const layerGroup = interactiveLayersMap.current[interactiveItem.layer.id];
                lastMouseOverLayerInfo.current = {
                    group: layerGroup,
                    layer: { id: interactiveItem.layer.id }
                };
                if (layerGroup.onClick) {
                    event.map = map;
                    layerGroup.onClick(event, interactiveItem);
                }
            } else {
                props.onMapClicked();
            }
        }
    }

    // Handle changes to zoom and center props
    useEffect(() => {
        if (!flyToInProgress.current) {
            const [ lng, lat ] = props.center || [];
            let positionUpdates:{ zoom?:number, latitude?: number, longitude?: number } = {};
            if (props.zoom) {
                positionUpdates.zoom = props.zoom;
            }
            if (lat !== undefined && lng !== undefined) {
                positionUpdates.latitude = lat;
                positionUpdates.longitude = lng;
            }
            const newOptions = Object.assign(viewPortRef.current, positionUpdates);
            if (!_.isEqual(newOptions, viewPortRef.current)) {
                // Update lastFlyTo so that the next time a fly to happens, it knows to compare and start from here
                lastFlyTo.current = { zoom: viewPortRef.current.zoom, center: [viewPortRef.current.longitude, viewPortRef.current.latitude] };
                setViewport(newOptions);
            }
        }
    }, [props.zoom, props.center]);

    const flyInTimer = useRef<any>();
    useEffect(() => {
        if (props.bounds && !flyToInProgress.current && !props.zoom && !props.center) {
            const map = getMapHandleRef.current();
            // Perform fit bounds after a slight delay to give a slight animated effect when map loads without lat long
            flyInTimer.current = setTimeout(() => {
                flyInTimer.current = undefined;
                if (props.bounds) {
                    map.fitBounds(props.bounds);
                    map.once('zoomend', () => {
                        const center = getMapCenter();
                        const zoom = getMapZoom();
                        if (center) {
                            setViewport(Object.assign({
                                ...viewPortRef.current,
                                zoom,
                                longitude: center.lng,
                                latitude: center.lat
                            }));
                            lastFlyTo.current = { zoom, center };
                        }
                    });
                }
            }, 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bounds]);

    useEffect(() => {
        return () => {
            if (mapMoveTimer.current) {
                clearTimeout(mapMoveTimer.current);
                mapMoveTimer.current = undefined;
            }
            if (flyInTimer.current) {
                clearTimeout(flyInTimer.current);
                flyInTimer.current = undefined;
            }
        }
    }, []);

    // This is a list of IDs of layers that support interaction
    const interactiveLayerIds = useRef<string[]>([]);
    const interactiveLayersMap = useRef<{[x:string]:any}>({});
    const renderedLayers = useRef<any[]>([]);
    useEffect(() => {
        let newInteractiveLayerIds:string[] = [];
        let newInteractiveLayersMap:{[x:string]:any} = {};
        let newRenderedLayers:any[] = [];
        React.Children.forEach(props.children, child => {
            // Instantiate the child layer so that we can get get it's children and additional properties
            if (child?.type) {
                const renderedLayerGroup = child.type(child.props);
                if (renderedLayerGroup) {
                    if (renderedLayerGroup.props?.interactiveLayerIds) {
                        newInteractiveLayerIds = newInteractiveLayerIds.concat(renderedLayerGroup.props.interactiveLayerIds);
                        for (const layerId of renderedLayerGroup.props.interactiveLayerIds) {
                            newInteractiveLayersMap[layerId] = renderedLayerGroup.props;
                        }
                    }
                    newRenderedLayers.push(renderedLayerGroup.props.children);
                }
            }
        });
        interactiveLayerIds.current = newInteractiveLayerIds;
        interactiveLayersMap.current = newInteractiveLayersMap;
        renderedLayers.current = newRenderedLayers;
    }, [props.children]);

    const lastMouseOverLayerInfo = useRef<any | null>(null);
    const onMouseEnter = useCallback(event => {
        const map = getMapHandleRef.current();
        if (map) {
            event.map = map;
            const features = map.queryRenderedFeatures(event.point);
            const interactiveItem = features.find(feature => interactiveLayersMap.current[feature.layer.id]);
            if (interactiveItem) {
                const layerGroup = interactiveLayersMap.current[interactiveItem.layer.id];
                lastMouseOverLayerInfo.current = {
                    group: layerGroup,
                    layer: { id: interactiveItem.layer.id }
                };
                if (layerGroup.onMouseEnter) {
                    layerGroup.onMouseEnter(event, interactiveItem);
                }
            }
        }
    }, []);
    const onMouseLeave = useCallback(event => {
        if (lastMouseOverLayerInfo.current) {
            if (lastMouseOverLayerInfo.current.group.onMouseLeave) {
                lastMouseOverLayerInfo.current.group.onMouseLeave(event, {
                    layer: lastMouseOverLayerInfo.current.layer
                });
            }
            lastMouseOverLayerInfo.current = null;
        }
    }, []);
    */

    return <div>
        If you want to demo this component please uncomment the code below and run "npm install react-map-gl" and possibly
        "npm install mapbox-gl"
    </div>;
/*
    <MapGL
        key={props.id}
        {...viewport}
        width="100%"
        height="100%"
        attributionControl={false}
        mapStyle={props.dark ? "mapbox://styles/mapbox/dark-v10" : "mapbox://styles/mapbox/light-v10"}
        onViewportChange={setViewport}
        transitionDuration={0}
        mapboxApiAccessToken={MAPBOX_API_KEY}
        dragRotate={false}
        ref={mapRef}
        onLoad={afterMapReady}
        onClick={onMapClicked}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        interactiveLayerIds={interactiveLayerIds.current}
        className={props.className || ""}
    >{renderedLayers.current}</MapGL>;
*/    
}

export { GeoMap };
