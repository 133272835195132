/** This module contains the component for displaying the indicators in a chart.
 *  @module
 */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { INDICATOR_TO_ICON_MAP, INDICATOR_TYPE, TIME_FORMAT, isBaselineIndicatorType, isLowOrHighIndicatorType } from "components/enums";
import { FILTERS_OBJECT, FILTER_NAME, parseTimeFromDALToUnix, TIME_RANGE, useQuery, useQueryParams } from "utils/hooks";
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { TimeseriesTile } from "components/reporting";
import { formatToLocalTimestamp, formatToUtcTimestamp, scaleMetric } from "reporting-infrastructure/utils/formatters";
import { getLabelForMetric, getUnitForMetric, getMetricDef } from "utils/stores/GlobalUnitsStore";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { STRINGS } from "app-strings";
import { DEFAULT_TIME_SERIES_OPTIONS } from "components/reporting/charts/defaults/HighchartDefaults";
import { IndicatorKind } from "components/hyperion/controls/indicator-kind/IndicatorKind";
import { AnchorButton, Button, HTMLSelect, Intent, TextArea } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { Tooltip2 } from "@blueprintjs/popover2";
import { APP_ICONS, METRIC_NAME } from "components/sdwan/enums";
import { PARAM_NAME } from "components/enums/QueryParams";
import cloneDeep from "lodash/cloneDeep";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { annotateTimestamp } from "pages/incident-details/views/primary-indicator/PrimaryIndicatorView";
import { Incident, Indicator, IndicatorMapping, IndicatorSample, IndicatorTimeseries } from "pages/incident-list/Incident.type";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer";
import { RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { renderToString } from "react-dom/server";
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip';
import { DataOceanMetric } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { getGranularityText } from "pages/riverbed-advisor/views/runbook-view-container/InfoContent";
import './IndicatorDetailedView.scss';

/** the maximum viewable time range in milliseconds that the UI will choose when setting an interval. */
const MAX_INTERVAL: number = 2 * 24 * 60 * 60 * 1000;
/** the maximum viewable time range in milliseconds that the user can expand the interval to. */
const MAX_USER_INTERVAL: number = 6 * 24 * 60 * 60 * 1000;
/** when the user expands the time range this is the expansion increment in hours. */
const EXPAND_INCR_HRS: number = 12;

/** this constant specifies the regions should be shown as a band or as an area style in a series. */
const SHOW_REGIONS_AS_BANDS: boolean = true;

/** a boolean value that specifies whether we should allow users to shift or offset the window of data they are looking at. */
const ALLOW_OFFSET: boolean = false;
/** a boolean value that specifies whether we should allow users to change the reference of the window of data from the end 
 *  of the incident to the start of the incident. */
const ALLOW_REFERENCE: boolean = true;
/** a boolean value, if true show the reference control as a start and end button, if false, show it as a drop down. */
const SHOW_REFERENCE_BUTTONS: boolean = true;

/** the default time ranges to show in the charts in hours. */
const CHART_DEFAULT_TIME_RANGES = [1, 8, 24];

/** the color for the threshold line. */
const THRESHOLD_LINE_COLOR = "green";
/** the opacity for the threshold line. */
const THRESHOLD_LINE_OPACITY = 0.5;
/** the color for the river. */
const RIVER_RANGE_COLOR = "green";
/** the opacity for the river. */
const RIVER_RANGE_OPACITY = 0.2;

/** this enum defines all the valid values for the time reference, which specifies whether the 
 *  chart focusses on the start or end of the incident. */
enum TIME_REFERENCE {
    /** the enumerated value, that specifies the reference is the beginning of the incident. */
    START = "start",
    /** the enumerated value, that specifies the reference is the end of the incident. */
    END   = "end"
};

/** this interface defines the properties passed into the React component. */
export interface IndicatorDetailedViewProps {
    /** a string with the class name to use on the root div for the component. */
    className?: string;
    /** the timerange in which the indicator is expected to be found. */
    indicatorTimeRange: TIME_RANGE;
    /** the timerange of the incident. */
    incidentTimeRange?: TIME_RANGE;
    /** the filters to use to filter the list. */
    filters?: FILTERS_OBJECT;
    /** the list of indicator mappings to use to filter the indicator list. */
    indicatorMapping: IndicatorMapping;
    /** This optional flag can be used to force the indicator details view to be of baseline time or threshold type (if passed as false) */
    isBaselineType?: boolean;
    /** a boolean value, if true show the indicator kind summary. */
    showSummary?: boolean;
    /** the init chart time range in hours.  This specifies which of the 1h, 8h, or 24hr buttons to initially select. */
    initChartTimeRange?: number;
    /** the Incident which should be annotated on the chart. */
    annotationIncident?: Incident;
    /** the indicator which should be annotated on the chart. */
    annotationIndicator?: Indicator;
    /** the list of runbook outputs to use for the annotations on the chart. */
    runbooks?: Array<RunbookOutput>;
    /** a boolean value, true if the indicator we are displaying is the primary indicator, false otherwise. */
    isPrimaryIndicator?: boolean;
}

/** Renders the indicator detailed view component.
 *  @param props the properties passed into the component.
 *  @returns JSX with the indicator detailed view component.*/
export function IndicatorDetailedView({
    className, indicatorMapping, indicatorTimeRange, incidentTimeRange, filters, isBaselineType, showSummary = false, 
    initChartTimeRange = 1, runbooks, ...props
}: IndicatorDetailedViewProps): JSX.Element {
    let { params } = useQueryParams({ listenOnlyTo: [ PARAM_NAME.debug, PARAM_NAME.showSteps ] });
    const [showDebug, setShowDebug] = useState<boolean>(false);
    const showDebugInformation = params[PARAM_NAME.debug] === "true";
    const showSteps = params[PARAM_NAME.showSteps] !== "false";

    const [isBaseline, setIsBaseline] = useState(isBaselineType === undefined ? false : isBaselineType);
    const [isThreshold, setIsThreshold] = useState(false);
    const [chartTimeRange, setChartTimeRange] = useState<number>(0/*initChartTimeRange*/);
    const [nAdditionalIntervals, setNAdditionalIntervals] = useState<number>(0);
    const [nOffsets, setNOffsets] = useState<number>(0);
    const [timeReference, setTimeReference] =  useState<TIME_REFERENCE>(incidentTimeRange && incidentTimeRange.endTime - incidentTimeRange.startTime > MAX_INTERVAL ? TIME_REFERENCE.START : TIME_REFERENCE.END);
    
    // TBD: Apollo Client merges all records with same ID value even when I tried to set the fetch-type as "no-cache".
    // To circumvent this problem, I am running 2 queries; one to fetch the data without ID property and another to
    // fetch just the IDs. I combine the results of the 2 queries in the UI after it's received. We need a proper solution
    // for this behavior. (Possibly change the ID key in DAL from the standard "id" to "indicatorId" or something non-standard)
    const detailsQuery = useQuery({
        name: "IndicatorDetails",
        query: new Query(loader("./indicator-details-query.graphql")),
        filters: {
            [FILTER_NAME.indicatorMapping]: indicatorMapping,
            ...filters
        },
        //consumedFilters: [FILTER_NAME.indicatorMapping],
        requiredFilters: [FILTER_NAME.indicatorMapping],
        time: getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference),
        lazy: true
    });
    const samplesQuery = useQuery({
        name: "IndicatorSamples",
        query: new Query(loader("./indicator-samples-query.graphql")),
        filters: {
            [FILTER_NAME.indicatorMapping]: indicatorMapping,
            ...filters
        },
        //consumedFilters: [FILTER_NAME.indicatorMapping],
        requiredFilters: [FILTER_NAME.indicatorMapping],
        time: getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference),
        lazy: true
    });
    const timeseriesQuery = useQuery({
        name: "IndicatorTimeseries",
        query: new Query(loader("./indicator-timeseries-query.graphql")),
        filters: {
            [FILTER_NAME.indicatorMapping]: indicatorMapping,
            ...filters
        },
        //consumedFilters: [FILTER_NAME.indicatorMapping],
        requiredFilters: [FILTER_NAME.indicatorMapping],
        time: getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference),
        lazy: true
    });

    const lastChartRange = useRef<number | undefined>();
    useEffect(() => {
        //if (
        //    lastChartRange.current === undefined || 
        //    (lastChartRange.current === 0 && chartTimeRange !== 0) ||
        //    (lastChartRange.current !== 0 && chartTimeRange === 0)
        //) {
            detailsQuery.run({
                time: getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference)
            });
        //}
        lastChartRange.current = chartTimeRange;
    }, [chartTimeRange, detailsQuery, indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference])

    useEffect(() => {
        if (detailsQuery.data?.indicators?.nodes && indicatorMapping) {
            // Check for baseline metrics
            let baseline = false;
            if (isBaselineType === undefined) {
                if (isBaselineIndicatorType(indicatorMapping.kind as INDICATOR_TYPE)) {
                    baseline = true;
                }
            } else {
                baseline = isBaselineType;
            }
            if (baseline) {
                setIsBaseline(true);
                timeseriesQuery.run({
                    time: getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference)
                });
            } else {
                if (isBaselineType === false || indicatorMapping.kind === INDICATOR_TYPE.TOO_HIGH || indicatorMapping.kind === INDICATOR_TYPE.TOO_LOW) {
                    setIsThreshold(true);
                }
                samplesQuery.run({
                    time: getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference)
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsQuery.data, isBaselineType, indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets])

    const indicatorData = useMemo(() => {
        if (
            detailsQuery.data?.indicators?.nodes && indicatorMapping &&
            (timeseriesQuery.data || samplesQuery.data)
        ) {
            const unit = getUnitForMetric(indicatorMapping.metric) || "";

            let samples:any = [];
            let riverData:any = [];
            let maxValue = 0;
            let maxObservedValue = 0;
            let threshold = 0;
            let baseline = false;
            let granularity: number | undefined = detailsQuery.data?.indicators?.nodes?.length ? detailsQuery.data.indicators.nodes[0].details.granularity : undefined;
            if (isBaselineType === undefined) {
                if (isBaselineIndicatorType(indicatorMapping.kind as INDICATOR_TYPE)) {
                    baseline = true;
                }
            } else {
                baseline = isBaselineType;
            }
            let indicators = detailsQuery.data.indicators.nodes.map(node => {
                return {
                    ...node,
                    ...node.details,
                    details: undefined,
                    timeseries: undefined,
                };
            }).filter(indicator => {
                return (baseline === true && isBaselineIndicatorType(indicator.kind)) ||
                    (baseline === false && !isBaselineIndicatorType(indicator.kind));
            });
            const timeSeriesQueryNodes = timeseriesQuery.data?.indicators?.nodes;
            if (baseline) {
                if (timeSeriesQueryNodes && timeSeriesQueryNodes.length > 0) {
                    let samplesData:any[] = [];
                    const uniqueTimeStamps = {};
                    for (const tsNode of timeSeriesQueryNodes) {
                        if (tsNode.timeseries) {
                            for (const sample of tsNode.timeseries) {
                                if (uniqueTimeStamps[sample.timestamp] === undefined) {
                                    uniqueTimeStamps[sample.timestamp] = true;
                                    samplesData.push(sample);
                                }
                            }
                        }
                    }
                    samplesData.sort((a, b) => {
                        return Number(a.timestamp) - Number(b.timestamp);
                    });
                    if (showSteps && samplesData?.length && samplesData[samplesData.length - 1].actualValue !== null) {
                        const extraSample = cloneDeep(samplesData[samplesData.length - 1]);
                        extraSample.timestamp = String(Number(extraSample.timestamp) + 15 * 60);
                        samplesData.push(extraSample);
                    }
                    if (chartTimeRange !== 0) {
                        // Add an extra hour to the 24 hour period so we can see part of the previous 24 hours to see any
                        // periodicity
                        const lookBack = chartTimeRange * 60 * 60 * 1000 + (chartTimeRange === 24 ? 60 * 60 * 1000 : 0); 
                        const endTimeInUnix = parseTimeFromDALToUnix(samplesData[samplesData.length - 1].timestamp);
                        if (endTimeInUnix) {
                            const baselineSamplesStartTime = endTimeInUnix - lookBack;
                            // Filter down the time series samples to selected time range
                            samplesData = samplesData.filter(sample => {
                                return (parseTimeFromDALToUnix(sample.timestamp) || 0) >= baselineSamplesStartTime;
                            });
                            // Filter down the indicators to be rendered to selected time range
                            indicators = indicators.filter(indicator => {
                                return (parseTimeFromDALToUnix(indicator.startTime) || 0) >= baselineSamplesStartTime;
                            });
                        }
                    }
                    for (const sample of samplesData) {
                        const acceptableLowValue = sample.acceptableLowValue > 0 ? sample.acceptableLowValue : 0;
                        const acceptableHighValue = sample.acceptableHighValue >= 0 ? sample.acceptableHighValue : 0;
                        if (sample.actualValue > maxValue) {
                            maxValue = sample.actualValue;
                        }
                        if (sample.actualValue > maxObservedValue) {
                            maxObservedValue = sample.actualValue;
                        }
                        if (acceptableLowValue > maxValue) {
                            maxValue = acceptableLowValue;
                        }
                        if (acceptableHighValue > maxValue) {
                            maxValue = acceptableHighValue;
                        }
                        const x = parseTimeFromDALToUnix(sample.timestamp);
                        samples.push({
                            x,
                            y: sample.actualValue,
                        });
                        riverData.push({
                            x,
                            low: acceptableLowValue,
                            high: acceptableHighValue,
                        });
                    }
                }
            } else {
                if (samplesQuery.data?.samples?.nodes && samplesQuery.data?.samples?.nodes.length > 0) {
                    const uniqueTimeStamps = {};
                    for (const sampleNode of samplesQuery.data.samples.nodes) {
                        if (sampleNode.details?.samples) {
                            for (const sample of sampleNode.details.samples) {
                                if (uniqueTimeStamps[sample.timestamp] === undefined) {
                                    uniqueTimeStamps[sample.timestamp] = true;
                                    samples.push(sample);
                                }
                            }
                        }
                    }
                    samples.sort((a, b) => {
                        return Number(a.timestamp) - Number(b.timestamp);
                    });
                    if (showSteps && samples?.length > 1 && samples[samples.length - 1].value !== null) {
                        const extraSample = cloneDeep(samples[samples.length - 1]);
                        const interval = Number(samples[samples.length - 1].timestamp) - Number(samples[samples.length - 2].timestamp);
                        extraSample.timestamp = String(Number(extraSample.timestamp) + interval);
                        samples.push(extraSample);
                    }
                    if (chartTimeRange !== 0 && samples?.length) {
                        const lookBack = chartTimeRange * 60 * 60 * 1000 + (chartTimeRange === 24 ? 60 * 60 * 1000 : 0); 
                        const endTimeInUnix = parseTimeFromDALToUnix(samples[samples.length - 1].timestamp);
                        if (endTimeInUnix) {
                            const thresholdSamplesStartTime = endTimeInUnix - lookBack;
                            // Filter down the time series samples to selected time range
                            samples = samples.filter(sample => {
                                return (parseTimeFromDALToUnix(sample.timestamp) || 0) >= thresholdSamplesStartTime;
                            });
                            // Filter down the indicators to be rendered to selected time range
                            indicators = indicators.filter(indicator => {
                                return (parseTimeFromDALToUnix(indicator.startTime) || 0) >= thresholdSamplesStartTime;
                            });
                        }
                    }
                    samples = samples.map((sample, index) => {
                        if (sample.value > maxValue) {
                            maxValue = sample.value;
                        }
                        if (sample.value > maxObservedValue) {
                            maxObservedValue = sample.value;
                        }
                        return {
                            x: parseTimeFromDALToUnix(sample.timestamp),
                            y: sample.value,
                        };
                    });
                }
                const [firstIndicator] = indicators;
                threshold = firstIndicator?.acceptableLowValue || firstIndicator?.acceptableHighValue || threshold;
            }
            
            return {
                metric: indicatorMapping.metric,
                kind: indicatorMapping.kind,
                unit: unit,
                indicators,
                samples,
                riverData,
                maxObservedValue,
                maxValue,
                threshold,
                granularity
            }
        }
    }, [detailsQuery.data, samplesQuery.data, timeseriesQuery.data, chartTimeRange, isBaselineType, showSteps, indicatorMapping]);

    const uniqueIndicatorKinds = useMemo(() => (indicatorData?.indicators || [])
        .reduce((kindsMap, indicator) => {
            kindsMap[indicator.kind] = (kindsMap[indicator.kind] || 0) + 1;
            return kindsMap;
        }, {}), [indicatorData]);

    const initDialogState = {showDialog: false, title: STRINGS.primaryIndicatorView.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></>};
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    const timeChart = useMemo(() => {

        const seriesData = getSeriesData(indicatorData, showSteps);

        const plotBands = SHOW_REGIONS_AS_BANDS ? getHighlightedRegions(indicatorData) : undefined;
    
        function formatTooltipData(this: any) {
            let toolTip = formatToLocalTimestamp(new Date(this.x), TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT);
            const symbol = '&#9632;';
            const color = isBaseline ? this.points[0].color : this.point.color;
            const opacity = isBaseline ? this.points[0].series.options.opacity : this.point.series.options.opacity;
            const actualValue =
                    '<div><span style="font-size:16px;color:' +
                    color + ';opacity:' + opacity +
                    '">' +
                    symbol +
                    '</span>' +
                    '<b><span> ' +
                    STRINGS.incidents.columns.actual +
                    '</span></b> : <b>' +
                    scaleMetric(this.y, Unit.parseUnit(indicatorData?.unit || "")).formatted +
                    '</b></div>';
            toolTip += actualValue;
    
            if (isBaseline && this.points[1]) {
                const expectedRange =
                        '<div><span style="font-size:16px;color:' +
                        this.points[1].series.options.fillColor + ';opacity:' + this.points[1].series.options.opacity +
                        '">' +
                        symbol +
                        '</span>' +
                        '<b><span> ' +
                        STRINGS.incidents.timeseriesChart.expectedRange +
                        '</span></b> : <b>' +
                        scaleMetric(this.points[1].point.options.low, Unit.parseUnit(indicatorData?.unit || "")).formatted + '&ndash;' +
                        scaleMetric(this.points[1].point.options.high, Unit.parseUnit(indicatorData?.unit || "")).formatted +
                        '</b></div>';
                toolTip += expectedRange;
            } else if (indicatorData?.threshold) {
                const expectedRange =
                        '<div>' +
                            '<span style="font-size:16px;color:' + THRESHOLD_LINE_COLOR + ';opacity:' + THRESHOLD_LINE_OPACITY + '">' + symbol + '</span>' +
                            '<b><span> ' + STRINGS.incidents.timeseriesChart.threshold + '</span></b> : ' +
                            '<b>' + scaleMetric(indicatorData.threshold, Unit.parseUnit(indicatorData?.unit || "")).formatted + '</b>' +
                        '</div>';
                toolTip += expectedRange;
            }
            return toolTip;
        }
    
        let timeRange = getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference);
        if (chartTimeRange !== 0) {
            const lookBack = chartTimeRange * 60 * 60 * 1000 + (chartTimeRange === 24 ? 60 * 60 * 1000 : 0); 
            timeRange.startTime = timeRange.endTime - lookBack;
        }

        const annotationData = getAnnotationsNew(
            indicatorData, props.isPrimaryIndicator === true, props.annotationIncident, props.annotationIndicator, runbooks,
            timeRange.startTime, timeRange.endTime
        );

        const yAxisUnit = Unit.parseUnit(indicatorData?.unit || "");

        return <TimeseriesTile
            chartData={indicatorData?.samples}
            // Apply a max Y height of 100 when the indicator being displayed is a percentage type and the max seen value is between 10 and 100
            yMax={(indicatorData?.unit === "%" && indicatorData.maxValue < 100 && indicatorData.maxValue > 10) ? 100 : undefined}
            className="h-min-3"
            config={{
                chart: {
                    height: 300
                },
                xAxis: {
                    visible: true,
                    type: "datetime",
                    min: timeRange.startTime,
                    max: timeRange.endTime,
                    labels: {
                        formatter: function (this:any) {
                            return formatToLocalTimestamp(this.value, TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT);
                        }
                    },
                    crosshair: {
                        snap: true,
                        width: 1,
                    },
                    plotBands: plotBands
                },
                yAxis: {
                    visible: true, //isBaseline,
                    type: "number",
                    title: {
                        text: getLabelForMetric(indicatorData?.metric || "") + " " + 
                            (yAxisUnit && yAxisUnit.getDisplayName() !== '' ? '(' + yAxisUnit.getDisplayName() + ')' : ''),
                    },
                    labels: {
                        formatter: function (this: any) {
                            return scaleMetric(this.value, new Unit()).formatted;
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    shared: isBaseline,
                    split: false,
                    formatter: (isBaseline || isThreshold) ? formatTooltipData : function (this:any) {
                        return scaleMetric(this.y, Unit.parseUnit(indicatorData?.unit || "")).formatted
                    }
                },
                series: seriesData,
                annotations: annotationData
            }}
        />;
    }, [
        indicatorData, props.annotationIncident, props.annotationIndicator, props.isPrimaryIndicator, showSteps, 
        isBaseline, isThreshold, runbooks, indicatorTimeRange, incidentTimeRange, chartTimeRange, nAdditionalIntervals, 
        nOffsets, timeReference
    ]);

    return <>
        <BasicDialog dialogState={dialogState} className="indicator-detailed-view-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <DataLoadFacade loading={detailsQuery.loading || timeseriesQuery.loading || samplesQuery.loading} 
            showContentsWhenLoading={true}
            data={detailsQuery.data} error={detailsQuery.error || timeseriesQuery.error || samplesQuery.error}>
            <div className={"text-center" + (className ? " " + className : "")}>
                {
                    indicatorData?.metric &&
                    <div className="display-8 mt-3">
                        <div className="container-fluid indicator-chart-title">
                            <div className="row">
                                <div className="col"> {
                                    createTimeRangeButtons(
                                        chartTimeRange, initChartTimeRange, setChartTimeRange, 
                                        getTimeRange(indicatorTimeRange, incidentTimeRange, nAdditionalIntervals, nOffsets, timeReference), 
                                        incidentTimeRange !== null, nAdditionalIntervals, setNAdditionalIntervals, 
                                        nOffsets, setNOffsets, timeReference, setTimeReference
                                    )
                                }</div>
                                <div className="col-6">
                                    <span className="metric-name">
                                        {getLabelForMetric(indicatorData?.metric || "")}
                                        {getGranularitySummary(indicatorData?.granularity) || ""}
                                        <div>{getLegendForEnum(getMetricDef(indicatorData?.metric || ""))}</div>
                                    </span>
                                    {
                                        (indicatorData.metric === METRIC_NAME.utilization || indicatorData.metric === METRIC_NAME.in_utilization || indicatorData.metric === METRIC_NAME.out_utilization) &&
                                        indicatorData.maxObservedValue > 100 && 
                                        <Tooltip2
                                            className="d-inline-flex"
                                            content={<div className="w-max-3 text-justify">{STRINGS.incidents.misconfiguredMetric}</div>}
                                        >
                                            <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} className="ml-1"/>
                                        </Tooltip2>
                                    }
                                    {
                                        (indicatorData.indicators.length > 1 || showSummary === false) &&
                                        <div className="display-10">{
                                            Object.keys(uniqueIndicatorKinds).map(kind => {
                                                const indicatorKind = INDICATOR_TYPE[kind];
                                                if (indicatorKind) {
                                                    return <IndicatorKind key={"kind-" + kind} prefix={uniqueIndicatorKinds[kind]} kind={indicatorKind} className="mr-2"/>;
                                                } else {
                                                    return "";
                                                }
                                            }, [])
                                        }</div>
                                    }
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>
                }
                {timeChart}
            </div>
            {showDebugInformation && <div>
                <AnchorButton icon={IconNames.DIAGNOSIS} minimal={true} onClick={() => {
                    showDebugDialog(
                        getDebugInformation(
                            detailsQuery.data?.indicators?.nodes, samplesQuery.data?.samples?.nodes, timeseriesQuery?.data?.indicators?.nodes, 
                            indicatorMapping, filters, indicatorTimeRange, incidentTimeRange
                        ), 
                        dialogState, setDialogState
                    );
                }} className="indicator-details-view-debug-button"/>
                {false && createDebugPanel(detailsQuery.data, samplesQuery.data, timeseriesQuery.data, showDebug, setShowDebug)}
            </div>}
        </DataLoadFacade>
    </>;
}

/** Creates a popup that displays the debug information.
 *  @param json the JSON object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    json: Record<string, any>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={json} />;
    newDialogState.dialogFooter = <>
        <Button outlined
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
        <CopyToClipboard text={JSON.stringify(json || {}, null, 4)}>
            <Button intent={Intent.PRIMARY}
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
    </>;
    setDialogState(newDialogState);
}

/** This function outputs the debug information for the debug dialog.
 *  @param indicators the Array of indicators that were returned by DAL.
 *  @param samples the Array of threshold samples returned by DAL.
 *  @param timeseries the Array of timeseries samples returned by DAL.
 *  @param indicatorMapping the incidator mapping.
 *  @param filters the filters used in the query.
 *  @returns an object with the debug information. */
function getDebugInformation(
    indicators: Array<Indicator>, samples: Array<IndicatorSample>, timeseries: Array<IndicatorTimeseries>, 
    indicatorMapping: IndicatorMapping, filters: FILTERS_OBJECT | undefined, indicatorTimeRange: TIME_RANGE, 
    incidentTimeRange: TIME_RANGE | undefined
): Record<string, any> {
    if (indicatorTimeRange) {
        indicatorTimeRange = cloneDeep(indicatorTimeRange);
        indicatorTimeRange = {startTime: annotateDate(indicatorTimeRange.startTime), endTime: annotateDate(indicatorTimeRange.endTime)} as any;
    }

    if (incidentTimeRange) {
        incidentTimeRange = cloneDeep(incidentTimeRange);
        incidentTimeRange = {startTime: annotateDate(incidentTimeRange!.startTime), endTime: annotateDate(incidentTimeRange!.endTime)} as any;
    }

    if (indicators?.length) {
        indicators = cloneDeep(indicators);
        for (const indicator of indicators) {
            annotateTimestamp(indicator, "startTime");
            annotateTimestamp(indicator, "endTime");
         }
    }

    if (samples?.length) {
        samples = cloneDeep(samples);
        for (const sample of samples) {
            if (sample?.details?.samples?.length) {
                sample.details.samples.sort((a, b) => {return Number(a.timestamp) - Number(b.timestamp);});
                for (const sampleValue of sample.details.samples) {
                    annotateTimestamp(sampleValue, "timestamp");
                }
            }
         }
    }
    
    if (timeseries?.length) {
        timeseries = cloneDeep(timeseries);
        for (const tsNode of timeseries) {
            annotateTimestamp(tsNode, "endTime");
            if (tsNode.timeseries) {
                tsNode.timeseries.sort((a, b) => {
                    return Number(a.timestamp) - Number(b.timestamp);
                });
                for (const tsValue of tsNode.timeseries) {
                    if (tsValue.timestamp) {
                        annotateTimestamp(tsValue, "timestamp");
                    }
                }
            }
        }
    }

    return {timeRanges: {incidentTimeRange, indicatorTimeRange}, indicators, samples, timeseries, indicatorMapping, filters}
}

/** annotates the timestamp specified by the object and key with a modified timestamp that includes your 
 *      time and utc time.
 *  @param time a number with the time in milliseconds.
 *  @returns a string with the raw time and the time in the users time zone and utc. */
function annotateDate(time: number): string {
    if (time) {
        return time + " (your time: " + 
            formatToLocalTimestamp(new Date(time), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
            ", utc: " + 
            formatToUtcTimestamp(new Date(time), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
            ")";
    }
    return "";
}

/** generates the debug panel that is displayed below the indicator chart.
 *  @param detailsData the object with the indicator details.  The indicator details has the start and end 
 *      time of all the indicators and their expected and actual values.
 *  @param samplesData for threshold indicators this has all the samples for the threshold analysis.
 *  @param timeseriesData this has all the samples for the baseline analysis.
 *  @param showDebug a boolean value, true if the debug information should be opened.
 *  @param setShowDebug the function that sets the showDebug state.
 *  @returns the JSX with the debug panel. */
function createDebugPanel(
    detailsData: any, samplesData: any, timeseriesData: any, showDebug: boolean, setShowDebug: (debug: boolean) => void
): JSX.Element {
    let debugDetailsData: Array<any> | undefined = undefined;
    if (detailsData?.indicators?.nodes && detailsData?.indicators?.nodes?.length) {
        debugDetailsData = [];
        for (const node of detailsData?.indicators.nodes) {
            const data = JSON.parse(JSON.stringify(node));
            if (data.startTime) {
                data.startTime += " (your time: " + 
                formatToLocalTimestamp(new Date(parseInt(data.startTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                ", utc: " + 
                formatToUtcTimestamp(new Date(parseInt(data.startTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                ")";
            }
            if (data.endTime) {
                data.endTime += " (your time: " + 
                formatToLocalTimestamp(new Date(parseInt(data.endTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                ", utc: " + 
                formatToUtcTimestamp(new Date(parseInt(data.endTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                ")";
            }
            debugDetailsData.push(data);
        }
    }

    let debugSamplesData: Array<any> | undefined = undefined;
    if (samplesData?.samples?.nodes && samplesData?.samples?.nodes?.length) {
        debugSamplesData = [];
        const samplesFromQuery = JSON.parse(JSON.stringify(samplesData.samples.nodes[0].details?.samples || []));
        samplesFromQuery.sort((a, b) => {return Number(a.timestamp) - Number(b.timestamp);});
        for (const data of samplesFromQuery) {
            if (data.timestamp) {
                data.timestamp += " (your time: " + 
                formatToLocalTimestamp(new Date(parseInt(data.timestamp) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                ", utc: " + 
                formatToUtcTimestamp(new Date(parseInt(data.timestamp) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                ")";
            }
            debugSamplesData.push(data);    
        }
    }

    let debugTsData: Array<any> | undefined = undefined;
    if (timeseriesData?.indicators?.nodes && timeseriesData?.indicators?.nodes?.length) {
        debugTsData = [];
        const timeSeriesQueryNodes = JSON.parse(JSON.stringify(timeseriesData?.indicators?.nodes));
        for (const tsNode of timeSeriesQueryNodes) {
            if (tsNode.timeseries) {
                tsNode.timeseries.sort((a, b) => {
                    return Number(a.timestamp) - Number(b.timestamp);
                });
                for (const sample of tsNode.timeseries) {
                    if (sample.timestamp) {
                        sample.timestamp += " (your time: " + 
                        formatToLocalTimestamp(new Date(parseInt(sample.timestamp) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                        ", utc: " + 
                        formatToUtcTimestamp(new Date(parseInt(sample.timestamp) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
                        ")";
                    }
                }
            }
            debugTsData.push(tsNode);    
        }
    }

    return <>
        <div className="clickable mt-5 my-2"
            onClick={() => {
                    if (showDebug) {
                        setShowDebug(false);
                    } else {
                        setShowDebug(true);
                    }
            }}
        >
            <Icon icon={showDebug ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED}/>
            <span className="display-8">{STRINGS.incidents.debug.debugText}</span>
        </div>
        {showDebug && <table className="display-8"><tbody>
            {debugDetailsData && <tr>
                <td className="p-1 font-weight-800" style={{verticalAlign: "top"}}>{STRINGS.incidents.debug.detailsLabel}</td>
                <td className="p-1"><TextArea defaultValue={JSON.stringify(debugDetailsData, null, 4)} className="indicator-debug-textarea"/></td>
            </tr>}
            {debugSamplesData && <tr>
                <td className="p-1 font-weight-800" style={{verticalAlign: "top"}}>{STRINGS.incidents.debug.samplesLabel}</td>
                <td className="p-1"><TextArea defaultValue={JSON.stringify(debugSamplesData, null, 4)} className="indicator-debug-textarea"/></td>
            </tr>}
            {debugTsData && <tr>
                <td className="p-1 font-weight-800" style={{verticalAlign: "top"}}>{STRINGS.incidents.debug.tsLabel}</td>
                <td className="p-1"><TextArea defaultValue={JSON.stringify(debugTsData, null, 4)} className="indicator-debug-textarea"/></td>
            </tr>}
        </tbody></table>}
    </>;
}

/** returns the series that should be displayed in the chart.
 *  @param indicatorData the indicator data object that has all the indicators and their samples.
 *  @param showSteps a boolean value, if true show the steps.
 *  @returns an array of highcharts series. */
function getSeriesData (indicatorData: any, showSteps: boolean): Array<any> {
    let series:any[] = []; // TBD: Unable to use type SeriesOptionsType[]. Figure out why
    const [defaultFirstSeries] = DEFAULT_TIME_SERIES_OPTIONS?.series || [];
    if (defaultFirstSeries) {
        series.push({
            ...defaultFirstSeries,
            type: "line",
            step: showSteps ? true : false
        });
    }
    if (indicatorData?.indicators) {
        let minTime;
        let maxTime;
        for (const indicator of indicatorData.indicators) {
            // If unit for indicator is % and max value in timeseries that we see is between 10% and 100%, then we can
            // have the height of data points displayed in the chart to be set to 100. Else, set the height to be 5% higher
            // than the max value.
            //const value = (indicatorData.unit === "%" && indicatorData.maxValue < 100 && indicatorData.maxValue > 10) ? 100 :
            //    (indicatorData.maxValue === 0 ? 1 : (indicatorData.maxValue * 1.05));
            const indicatorStartTime = parseTimeFromDALToUnix(indicator.startTime);
            const indicatorEndTime = parseTimeFromDALToUnix(indicator.endTime);
            if (indicatorStartTime && indicatorEndTime) {
                minTime = minTime === undefined || indicatorStartTime < minTime ? indicatorStartTime : minTime;
                maxTime = maxTime === undefined || indicatorEndTime > maxTime ? indicatorEndTime : maxTime;
                // const barWidthInMins = 1;
                // const thinBarOffset = Math.abs((barWidthInMins / 2) * 60 * 1000);
                // const thinBarWidth = barWidthInMins * 60 * 1000;
                
                // Offset forward by half-width of indicator (center forward) timerange and show a thin bar
                // start time from indicator source + half the width of time frame to find the mid point - width of band to display
                // const indicatorDisplayedStartTime = indicatorStartTime + Math.floor((indicatorEndTime - indicatorStartTime)/2) - thinBarOffset;
                // const indicatorDisplayedEndTime = indicatorDisplayedStartTime + thinBarWidth;

                // Offset backward by half-width of indicator (center backward) timerange and show a thin bar
                // start time from indicator source - half the width of time frame to find the mid point - width of band to display
                // const indicatorDisplayedStartTime = indicatorStartTime - Math.floor((indicatorEndTime - indicatorStartTime)/2) - thinBarOffset;
                // const indicatorDisplayedEndTime = indicatorDisplayedStartTime + thinBarWidth;

                // Offset backward by width of indicator timerange and show a thin bar
                // start time from indicator source - width of time frame to find the mid point - width of band to display
                // const indicatorDisplayedStartTime = indicatorStartTime - (indicatorEndTime - indicatorStartTime) - thinBarOffset;
                // const indicatorDisplayedEndTime = indicatorDisplayedStartTime + thinBarWidth;

                // Offset it by width of indicator timerange
                // const indicatorSourceTimeWidth = indicatorEndTime - indicatorStartTime;
                // const indicatorDisplayedStartTime = indicatorStartTime - indicatorSourceTimeWidth;
                // const indicatorDisplayedEndTime = indicatorEndTime - indicatorSourceTimeWidth;

                // Show a thin bar at start time
                // const indicatorDisplayedStartTime = indicatorStartTime - thinBarOffset;
                // const indicatorDisplayedEndTime = indicatorDisplayedStartTime + thinBarWidth;

/* When this is here the thresholds don't show up.  When you go from 24 to 8, then 8 does not show up.  When you go 
   from 8 to 1 then 1 does not show up.  Then if you go up to 8, 8 does show up.  Not sure why moving this fixes 
   everything.
                // Use actual start and end times from indicator
                const indicatorDisplayedStartTime = indicatorStartTime;
                const indicatorDisplayedEndTime = indicatorEndTime;

                const indicatorKindInfo = INDICATOR_TO_ICON_MAP[indicator.kind];
                series.push({
                    type: "area",
                    lineColor: "transparent",
                    fillColor: indicatorKindInfo?.bgColor || "red",
                    opacity: 0.1,
                    data: [{
                        x: indicatorDisplayedStartTime,
                        y: value,
                    },{
                        x: indicatorDisplayedEndTime,
                        y: value,
                    }],
                    // Disable tooltips on this series
                    enableMouseTracking: false,
                });
*/
            }
        }
        if (indicatorData.riverData?.length) {
            series.push({
                type: "arearange",
                fillColor: RIVER_RANGE_COLOR,
                opacity: RIVER_RANGE_OPACITY,
                data: indicatorData.riverData,
                step: showSteps ? true : false
                // enableMouseTracking: false,
            });
        }
        if (indicatorData.threshold) {
            // Note: Instead of pushing a new series, we could also alternatively pass the below parameter
            // to TimeseriesTile to display the default style threshold line
            // thresholds={ indicatorData?.threshold ? [indicatorData.threshold] : undefined }
            if (minTime === undefined || (indicatorData.samples?.length > 0 && indicatorData.samples[0].x < minTime)) {
                minTime = indicatorData.samples[0].x;
            }
            if (maxTime === undefined || (indicatorData.samples?.length > 0 && indicatorData.samples[indicatorData.samples.length - 1].x > maxTime)) {
                maxTime = indicatorData.samples[indicatorData.samples.length - 1].x;
            }
            if (minTime && maxTime && isLowOrHighIndicatorType(indicatorData.kind)) {
                // Add the series that displays the threshold
                series.push({
                    type: "line",
                    color: THRESHOLD_LINE_COLOR,
                    dashStyle: "Dash",
                    opacity: THRESHOLD_LINE_OPACITY,
                    data: [{
                        x: minTime,
                        y: indicatorData.threshold,
                    }, {
                        x: maxTime,
                        y: indicatorData.threshold,
                    }],
                    enableMouseTracking: false,
                });
            }
        }
        // Had to move this here from above otherwise the thresholds don't show up.  When you go from 24 to 8, then 8 does not show up.  When you go 
        // from 8 to 1 then 1 does not show up.  Then if you go up to 8, 8 does show up.  Not sure why moving this fixes 
        // everything.
        // If unit for indicator is % and max value in timeseries that we see is between 10% and 100%, then we can
        // have the height of data points displayed in the chart to be set to 100. Else, set the height to be 5% higher
        // than the max value.
        const value = (indicatorData.unit === "%" && indicatorData.maxValue < 100 && indicatorData.maxValue > 10) ? 100 :
            (indicatorData.maxValue === 0 ? 1 : (indicatorData.maxValue * 1.05));

        // Sort the indicators ascending by start time
        indicatorData.indicators.sort((a, b) => {
            const tsA = parseTimeFromDALToUnix(a.startTime) || 0;
            const tsB = parseTimeFromDALToUnix(b.startTime) || 0;
            return tsA < tsB ? -1 : tsA > tsB ? 1 : 0;
        });

        // Merge the indicators so the chart renders quicker
        if (!SHOW_REGIONS_AS_BANDS) {
            const efficientIndicators: Array<Indicator> = [];
            let lastIndicator: Indicator | undefined = undefined;
            for (const indicator of indicatorData.indicators) {
                const iStart = parseTimeFromDALToUnix(indicator.startTime) || 0;
                const iEnd = parseTimeFromDALToUnix(indicator.endTime) || 0;
                if (lastIndicator) {
                    const lIStart = parseTimeFromDALToUnix(lastIndicator.startTime) || 0;
                    const lIEnd = parseTimeFromDALToUnix(lastIndicator.endTime) || 0;
                    if (iStart >= lIStart && iStart <= lIEnd) {
                        lastIndicator.endTime = iEnd > lIEnd ? indicator.endTime : lastIndicator.endTime;
                    } else {
                        efficientIndicators.push(indicator);
                        lastIndicator = indicator;    
                    }
                } else {
                    efficientIndicators.push(indicator);
                    lastIndicator = indicator;
                }
            }
    
            for (const indicator of efficientIndicators) {
                const indicatorStartTime = parseTimeFromDALToUnix(indicator.startTime);
                const indicatorEndTime = parseTimeFromDALToUnix(indicator.endTime);
                if (indicatorStartTime && indicatorEndTime) {
                    // Use actual start and end times from indicator
                    const indicatorDisplayedStartTime = indicatorStartTime;
                    const indicatorDisplayedEndTime = indicatorEndTime;
    
                    const indicatorKindInfo = INDICATOR_TO_ICON_MAP[indicator.kind];
                    series.push({
                        type: "area",
                        lineColor: "transparent",
                        fillColor: indicatorKindInfo?.bgColor || "red",
                        opacity: 0.1,
                        data: [{
                            x: indicatorDisplayedStartTime,
                            y: value,
                        },{
                            x: indicatorDisplayedEndTime,
                            y: value,
                        }],
                        // Disable tooltips on this series
                        enableMouseTracking: false,
                    });
                }
            }
        }
    }
    return series;
}

function getHighlightedRegions(indicatorData: any): any {
    const plotBands: any[] = [];

    if (indicatorData?.indicators) {
        // Merge the indicators so the chart renders quicker
        const efficientIndicators: Array<Indicator> = [];
        let lastIndicator: Indicator | undefined = undefined;
        for (const indicator of indicatorData.indicators) {
            const iStart = parseTimeFromDALToUnix(indicator.startTime) || 0;
            const iEnd = parseTimeFromDALToUnix(indicator.endTime) || 0;
            if (lastIndicator) {
                const lIStart = parseTimeFromDALToUnix(lastIndicator.startTime) || 0;
                const lIEnd = parseTimeFromDALToUnix(lastIndicator.endTime) || 0;
                if (iStart >= lIStart && iStart <= lIEnd) {
                    lastIndicator.endTime = iEnd > lIEnd ? indicator.endTime : lastIndicator.endTime;
                } else {
                    efficientIndicators.push(indicator);
                    lastIndicator = indicator;    
                }
            } else {
                efficientIndicators.push(indicator);
                lastIndicator = indicator;
            }
        }
        
        for (const indicator of efficientIndicators) {
            const indicatorStartTime = parseTimeFromDALToUnix(indicator.startTime);
            const indicatorEndTime = parseTimeFromDALToUnix(indicator.endTime);
            if (indicatorStartTime && indicatorEndTime) {
                // Use actual start and end times from indicator
                const indicatorDisplayedStartTime = indicatorStartTime;
                const indicatorDisplayedEndTime = indicatorEndTime;

                const indicatorKindInfo = INDICATOR_TO_ICON_MAP[indicator.kind];
                plotBands.push(
                    {
                        from: indicatorDisplayedStartTime,
                        to: indicatorDisplayedEndTime,
                        color: indicatorKindInfo?.bgColor || "red"
                    }
                )
            }
        }
    }

    return plotBands;
}

/** Creates the time range buttons above the chart.
 *  @param chartTimeRange the current chart time range in hours.
 *  @param initChartTimeRange the time range that the control should be initialized to in hours.
 *  @param setChartTimeRange the function to set the state of the time range.
 *  @param timeRange the TIME_RANGE that was used to query the data.  This is used to see if we 
 *      have hit the max time range.
 *  @param supportsFullTimeRange a boolean value, true if the full incident time range is supported.
 *  @param nAdditionalIntervals the number of additional intervals to add to the left of the range.
 *  @param setNAdditionalIntervals the function to set the nAdditionalIntervals state.
 *  @param nOffsets the number of offsets to add to the left of the range.
 *  @param setNOffsets the function to set the nOffsets state.
 *  @param timeReference the time reference which states whether to show the start or end of the incident.
 *  @param setTimeReference the function to set the time reference state.
 *  @returns the JSX with the time range buttons. */
function createTimeRangeButtons(
    chartTimeRange: number, initChartTimeRange: number, setChartTimeRange: (range: number) => void,
    timeRange: TIME_RANGE, supportsFullTimeRange: boolean, nAdditionalIntervals: number, 
    setNAdditionalIntervals: (range: number) => void, nOffsets: number, setNOffsets: (range: number) => void,
    timeReference: TIME_REFERENCE, setTimeReference: (ref: TIME_REFERENCE) => void
): JSX.Element {
    const timeRanges:number[] = [...CHART_DEFAULT_TIME_RANGES];
    if (initChartTimeRange && !timeRanges.includes(initChartTimeRange)) {
        timeRanges.push(initChartTimeRange);
    }
    if (supportsFullTimeRange) {
        timeRanges.push(0);
    }
    return <div className="time-range-buttons">
        {false && Object.values(timeRanges).sort((a, b) => a === 0 ? 1 : a - b)
            .map(range => {
                return <Button key={"time-" + range} outlined={true} active={chartTimeRange === range}
                    onClick= {() => {
                        setChartTimeRange(range);
                    }}>
                    {range === 0 ? STRINGS.TIME_RANGE_SELECTOR.fullIncident : STRINGS.formatString(STRINGS.TIME_RANGE_SELECTOR.hoursDisp, range)}
                </Button>;
            })
        }
        {ALLOW_REFERENCE && SHOW_REFERENCE_BUTTONS && <>
            <WrapInTooltip tooltip={STRINGS.indicatorDetailsView.referenceStartButtonTooltip}>
                <AnchorButton key={"time-reference-start"} outlined={true} active={false} 
                    disabled={timeRange && (timeRange.endTime - timeRange.startTime < MAX_INTERVAL)}
                    onClick= {() => {
                        setTimeReference(TIME_REFERENCE.START);
                        setNAdditionalIntervals(0);
                        setNOffsets(0);
                }}>{STRINGS.indicatorDetailsView.referenceStartButtonText}</AnchorButton>
            </WrapInTooltip>
        </>}
        <WrapInTooltip tooltip={STRINGS.formatString(STRINGS.indicatorDetailsView.addTimeEarlierTooltip, {increment: EXPAND_INCR_HRS})}>
            <AnchorButton key={"time-interval-plus-six-hours"} 
                outlined={true} active={false} icon={IconNames.STEP_BACKWARD} 
                disabled={timeRange && (timeRange.endTime - timeRange.startTime >= MAX_USER_INTERVAL)}
                onClick= {() => {
                    setNAdditionalIntervals(nAdditionalIntervals + 1);
            }} />
        </WrapInTooltip>
        <WrapInTooltip tooltip={STRINGS.formatString(STRINGS.indicatorDetailsView.addTimeLaterTooltip, {increment: EXPAND_INCR_HRS})}>
            <AnchorButton key={"time-interval-subtract-six-hours"} 
                outlined={true} active={false} 
                disabled={nAdditionalIntervals === 0}
                icon={IconNames.STEP_FORWARD}
                onClick= {() => {
                    setNAdditionalIntervals(nAdditionalIntervals - 1);
            }} />
        </WrapInTooltip>
        {ALLOW_OFFSET && <>
            <WrapInTooltip tooltip={STRINGS.formatString(STRINGS.indicatorDetailsView.shiftTimeEarlierTooltip, {increment: EXPAND_INCR_HRS})}>
                <AnchorButton key={"time-offset-plus-six-hours"} outlined={true} active={false}
                    onClick= {() => {
                        setNOffsets(nOffsets + 1);
                    }}
                >{STRINGS.formatString(STRINGS.indicatorDetailsView.shiftTimeEarlierText, {increment: EXPAND_INCR_HRS})}</AnchorButton>
            </WrapInTooltip>
            <WrapInTooltip tooltip={STRINGS.formatString(STRINGS.indicatorDetailsView.shiftTimeLaterTooltip, {increment: EXPAND_INCR_HRS})}>
                <AnchorButton key={"time-offset-subtract-six-hours"} outlined={true} active={false} disabled={nOffsets === 0}
                    onClick= {() => {
                        setNOffsets(nOffsets - 1);
                    }}
                >{STRINGS.formatString(STRINGS.indicatorDetailsView.shiftTimeLaterText, {increment: EXPAND_INCR_HRS})}</AnchorButton>
            </WrapInTooltip>
        </>}
        {!ALLOW_REFERENCE && <WrapInTooltip tooltip={STRINGS.indicatorDetailsView.restoreTooltip}><AnchorButton key={"time-full-interval"} 
            outlined={true} active={false} disabled={nAdditionalIntervals === 0 && nOffsets === 0}
            icon={IconNames.LOCATE}
            onClick= {() => {
                setNAdditionalIntervals(0);
                setNOffsets(0);
        }} /></WrapInTooltip>}
        {ALLOW_REFERENCE && <>
            {!SHOW_REFERENCE_BUTTONS && <>
                <span className="ml-2 mr-2 mt-1">Reference:</span>
                <WrapInTooltip tooltip={STRINGS.indicatorDetailsView.referenceDropdownTooltip}>
                    <HTMLSelect defaultValue={timeReference} style={{minWidth: "130px"}}
                        disabled={timeRange && (timeRange.endTime - timeRange.startTime < MAX_INTERVAL)}
                        options={[
                            {value: TIME_REFERENCE.START, label: STRINGS.indicatorDetailsView.referenceStartOptionText},
                            {value: TIME_REFERENCE.END, label: STRINGS.indicatorDetailsView.referenceEndOptionText},
                        ]}
                        onChange={(event) => {
                            const newReference: TIME_REFERENCE = event.currentTarget.value as TIME_REFERENCE;
                            setTimeReference(newReference);
                            setNAdditionalIntervals(0);
                            setNOffsets(0);
                        }} 
                    />
                </WrapInTooltip>
            </>}
            {SHOW_REFERENCE_BUTTONS && <>
                <WrapInTooltip tooltip={STRINGS.indicatorDetailsView.referenceEndButtonTooltip}>
                    <AnchorButton key={"time-reference-end"} outlined={true} active={false} 
                        disabled={timeRange && (timeRange.endTime - timeRange.startTime < MAX_INTERVAL)}
                        onClick= {() => {
                            setTimeReference(TIME_REFERENCE.END);
                            setNAdditionalIntervals(0);
                            setNOffsets(0);
                    }}>{STRINGS.indicatorDetailsView.referenceEndButtonText}</AnchorButton>
                </WrapInTooltip>
            </>}
        </>}
    </div>;
}

/** returns the annotations for the chart.  We are using the x and y offsets to position the annotations.
 *      note that in highcharts a negative value moves the annotation to the left and up respectively.
 *  @param indicatorData the indicator data that is displayed in the chart.
 *  @param isPrimaryIndicator a boolean value, true if we are displaying the primary indicator, false otherwise.
 *  @param annotationIncident the indicator that should be displayed in the annotations.
 *  @param annotationIndicator the indicator that should be displayed in the annotations.
 *  @param runbooks the list of runbooks that should be displayed in the annotations.
 *  @returns the array of annotations to be displayed in the chart. */
export function getAnnotationIcons(
    indicatorData: any, isPrimaryIndicator: boolean, annotationIncident: Incident | undefined, 
    annotationIndicator: Indicator | undefined, runbooks: Array<RunbookOutput> | undefined
): Array<any> {
    let annotations: any[] = [];

    let timeStampList: any[]= [];
    let minY = Number.MAX_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    if (indicatorData?.samples) {
        for (const indicator of indicatorData?.samples) {
            timeStampList.push(indicator.x);
            minY = Math.min(minY, indicator.y);
            maxY = Math.max(maxY, indicator.y);
        }
    }
/*    
    const widthY = maxY - minY;
    let minX = timeStampList.length >= 2 ? timeStampList[0] : 0;
    let maxX = timeStampList.length >= 2 ? timeStampList[timeStampList.length - 1] : 0;
    const widthX = timeStampList.length >= 2 ? timeStampList[timeStampList.length - 1] - timeStampList[0] : 0;
*/

    if (timeStampList?.length) {
        if (annotationIncident) {
            let searchedValue = parseTimeFromDALToUnix(annotationIncident.createdAt) || 0;
            const incidentStartTimestamp = timeStampList.reduce((prev, curr) => {
                return (Math.abs(curr - searchedValue) < Math.abs(prev - searchedValue) ? curr : prev);
            });
            if (incidentStartTimestamp && Math.abs(incidentStartTimestamp - searchedValue) <= 15 * 60 * 1000) {
                const incidentStartSample = indicatorData?.samples.find((item) => item.x === incidentStartTimestamp);
                annotations.push({
                    draggable: true,
                    labels: {
                        point: {
                            x: incidentStartTimestamp,
                            y: incidentStartSample.y,
                            xAxis: 0, yAxis: 0
                        },
                        //...getAnnotationOffset(incidentStartTimestamp, incidentStartSample.y, minX, maxX, minY, maxY),
                        x: 0, y: 5,
                        //distance: 5,
                        align: 'center',
                        verticalAlign: 'middle',
                        backgroundColor: 'transparent',
                        text: renderToString(<Icon size={20} icon={IconNames.LOCATE} />),
                        useHTML: true,
                        shape: 'callout',
                        //borderWidth: 0,
                        borderColor: 'transparent',
                        justify: true,
                        allowOverlap: true,
                        style: {
                            textAlign: 'center',
                            color: 'red',
                            opacity: 1
                        },
                    }
                });        
            }
            if (annotationIncident.endTime !== null && annotationIncident.endTime !== "0") {
                searchedValue = parseTimeFromDALToUnix(annotationIncident.endTime) || 0;
                const incidentEndTimestamp = timeStampList.reduce((prev, curr) => {
                    return (Math.abs(curr - searchedValue) < Math.abs(prev - searchedValue) ? curr : prev);
                });
                if (incidentEndTimestamp && Math.abs(incidentEndTimestamp - searchedValue) <= 15 * 60 * 1000) {
                    const incidentEndSample = indicatorData?.samples.find((item) => item.x === incidentEndTimestamp);
                    annotations.push({
                        draggable: true,
                        labels: {
                            point: {
                                x: incidentEndTimestamp,
                                y: incidentEndSample.y,
                                xAxis: 0, yAxis: 0
                            },
                            //...getAnnotationOffset(incidentEndTimestamp, incidentEndSample.y, minX, maxX, minY, maxY),
                            x: 0, y: 5,
                            useHTML: true,
                            //distance: 5,
                            align: 'center',
                            verticalAlign: 'middle',
                            backgroundColor: 'transparent',
                            text: renderToString(<Icon size={20} icon={IconNames.AIRPLANE} />),
                            shape: 'callout',
                            //borderWidth: 0,
                            borderColor: 'transparent',
                            justify: true,
                            allowOverlap: true,
                            style: {
                                textAlign: 'center',
                                color: 'red',
                                opacity: 1
                            },
                        }
                    });
                }
            }
        }
        if (annotationIndicator) {
            annotations.push({
                draggable: true,
                labels: {
                    point: {
                        x: parseTimeFromDALToUnix(annotationIndicator.startTime),
                        y: annotationIndicator?.details?.actualValue,
                        xAxis: 0, yAxis: 0
                    },
                    //...getAnnotationOffset(parseTimeFromDALToUnix(annotationIndicator.startTime) || 0, annotationIndicator?.details?.actualValue || 0, minX, maxX, minY, maxY),
                    x: -10, y: 0,
                    useHTML: true,
                    //distance: 5,
                    align: 'center',
                    verticalAlign: 'middle',
                    backgroundColor: 'transparent',
                    text: isPrimaryIndicator ? renderToString(<Icon size={20} icon={IconNames.FLAME} />) : STRINGS.incidents.correlatedIndicatorStartAnnotation,
                    shape: 'callout',
                    //borderWidth: 0,
                    borderColor: 'transparent',
                    justify: true,
                    allowOverlap: true,
                    style: {
                        textAlign: 'center',
                        color: 'red',
                        opacity: 1
                    },
                }
            });
        }
    }
    if (runbooks && timeStampList.length > 0) {
        for (const runbook of runbooks) {
            const searchedValue = parseTimeFromDALToUnix(runbook?.timestamp) || 0;
            const runbookExecTimestamp = timeStampList.reduce((prev, curr) => {
                return (Math.abs(curr - searchedValue) < Math.abs(prev - searchedValue) ? curr : prev);
            });
            if (runbookExecTimestamp && Math.abs(runbookExecTimestamp - searchedValue) <= 15 * 60 * 1000) {
                const indicatorSample = indicatorData?.samples.find((item) => item.x === runbookExecTimestamp);
                annotations.push({
                    draggable: true,
                    labels: {
                        point: {
                            x: runbookExecTimestamp,
                            y: indicatorSample.y,
                            xAxis: 0, yAxis: 0
                        },
                        //...getAnnotationOffset(runbookExecTimestamp, indicatorSample.y, minX, maxX, minY, maxY),
                        //distance: 5,
                        align: 'center',
                        useHTML: true,
                        verticalAlign: 'middle',
                        backgroundColor: 'transparent',
                        text: renderToString(<Icon size={20} icon={IconNames.LIGHTBULB} />),
                        shape: 'callout',
                        //borderWidth: 0,
                        borderColor: 'transparent',
                        justify: true,
                        allowOverlap: true,
                        style: {
                            textAlign: 'center',
                            color: '#eaaa00',
                            opacity: 1
                        },
                    }
                });
            }
        }
    }

/*    
    if (timeStampList.length >= 2) {
        annotations.sort((a, b) => {
            return a.labels.point.x < b.labels.point.x ? -1 : a.labels.point.x > b.labels.point.x ? 1 : 0;
        });

        for (let i = 1; i < annotations.length; i++) {
            // Try to adjust the x and y offsets to fix overlapping annotations
            if (
                (((annotations[i].labels.point.x - annotations[i - 1].labels.point.x)/widthX) < .05) &&
                (Math.abs((annotations[i].labels.point.y - annotations[i - 1].labels.point.y)/widthY) < .05)
            ) {
                if ((annotations[i].labels.point.y - minY)/widthY < .05) {
                    // The annotation is close to the bottom of the page
                    if (annotations[i].labels.point.y <= annotations[i - 1].labels.point.y) {
                        // Move the previous point up because the point is near the botton and it is lower than the other point
                        annotations[i - 1].labels.y += -30;
                        //annotations[i].labels.y += 30;
                    } else if (annotations[i].labels.point.y > annotations[i - 1].labels.point.y) {
                        // Move the current point up because it is near the bottom and higher than the other point
                        annotations[i].labels.y += -30;
                    }
                } else if ((maxY - annotations[i].labels.point.y)/widthY < .05) {
                    // The annotation is close to the top of the page
                    if (annotations[i].labels.point.y <= annotations[i - 1].labels.point.y) {
                        // Move the current point down because the point is near the top and it is lower than the other point
                        annotations[i].labels.y += 30;
                    } else if (annotations[i].labels.point.y > annotations[i - 1].labels.point.y) {
                        // Move the previous point down because it is near the top and this point is higher than the other point
                        annotations[i - 1].labels.y += 30;
                        //annotations[i].labels.y += -30;
                    }
                } else {
                    // The annotation is in the middle of the page.  Check where the two points are and move it down if the 
                    // point is below the previous point and move it up if the point is above the previous point
                    annotations[i].labels.y += annotations[i].labels.point.y <= annotations[i - 1].labels.point.y ? 30 : -30;
                }
            }

            // Try to adjust the distances to fix overlapping annotations
            //if (((annotations[i].labels.point.x - annotations[i - 1].labels.point.x)/widthX) < .05 && annotations[i - 1].labels.distance === 5) {
            //    // If the distance between the two annotations is less than 5 percent of the width
            //    annotations[i].labels.distance = 30;
            //}
        }    
    }
*/

    return annotations;
}

/** returns the annotations for the chart.  We are using the x and y offsets to position the annotations.
 *      note that in highcharts a negative value moves the annotation to the left and up respectively.
 *  @param indicatorData the indicator data that is displayed in the chart.
 *  @param isPrimaryIndicator a boolean value, true if we are displaying the primary indicator, false otherwise.
 *  @param annotationIncident the indicator that should be displayed in the annotations.
 *  @param annotationIndicator the indicator that should be displayed in the annotations.
 *  @param runbooks the list of runbooks that should be displayed in the annotations.
 *  @returns the array of annotations to be displayed in the chart. */
export function getAnnotations(
    indicatorData: any, isPrimaryIndicator: boolean, annotationIncident: Incident | undefined, 
    annotationIndicator: Indicator | undefined, runbooks: Array<RunbookOutput> | undefined
): Array<any> {
    let annotations: any[] = [];

    let timeStampList: any[]= [];
    let minY = Number.MAX_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    if (indicatorData?.samples) {
        for (const indicator of indicatorData?.samples) {
            timeStampList.push(indicator.x);
            minY = Math.min(minY, indicator.y);
            maxY = Math.max(maxY, indicator.y);
        }
    }
    const widthY = maxY - minY;
    let minX = timeStampList.length >= 2 ? timeStampList[0] : 0;
    let maxX = timeStampList.length >= 2 ? timeStampList[timeStampList.length - 1] : 0;
    const widthX = timeStampList.length >= 2 ? timeStampList[timeStampList.length - 1] - timeStampList[0] : 0;

    if (timeStampList?.length) {
        if (annotationIncident) {
            let searchedValue = parseTimeFromDALToUnix(annotationIncident.createdAt) || 0;
            const incidentStartTimestamp = timeStampList.reduce((prev, curr) => {
                return (Math.abs(curr - searchedValue) < Math.abs(prev - searchedValue) ? curr : prev);
            });
            if (incidentStartTimestamp && Math.abs(incidentStartTimestamp - searchedValue) <= 15 * 60 * 1000) {
                const incidentStartSample = indicatorData?.samples.find((item) => item.x === incidentStartTimestamp);
                annotations.push({
                    draggable: true,
                    labels: {
                        point: {
                            x: incidentStartTimestamp,
                            y: incidentStartSample.y,
                            xAxis: 0, yAxis: 0
                        },
                        ...getAnnotationOffset(incidentStartTimestamp, incidentStartSample.y, minX, maxX, minY, maxY),
                        //distance: 5,
                        align: 'center',
                        verticalAlign: 'middle',
                        backgroundColor: '#03a9f4',
                        text: STRINGS.incidents.incidentStartAnnotation,
                        shape: 'callout',
                        //borderWidth: 0,
                        borderColor: '#03a9f4',
                        justify: true,
                        allowOverlap: true,
                        style: {
                            textAlign: 'center',
                            color: 'white',
                        },
                    }
                });        
            }
            if (annotationIncident.endTime !== null && annotationIncident.endTime !== "0") {
                searchedValue = parseTimeFromDALToUnix(annotationIncident.endTime) || 0;
                const incidentEndTimestamp = timeStampList.reduce((prev, curr) => {
                    return (Math.abs(curr - searchedValue) < Math.abs(prev - searchedValue) ? curr : prev);
                });
                if (incidentEndTimestamp && Math.abs(incidentEndTimestamp - searchedValue) <= 15 * 60 * 1000) {
                    const incidentEndSample = indicatorData?.samples.find((item) => item.x === incidentEndTimestamp);
                    annotations.push({
                        draggable: true,
                        labels: {
                            point: {
                                x: incidentEndTimestamp,
                                y: incidentEndSample.y,
                                xAxis: 0, yAxis: 0
                            },
                            ...getAnnotationOffset(incidentEndTimestamp, incidentEndSample.y, minX, maxX, minY, maxY),
                            //distance: 5,
                            align: 'center',
                            verticalAlign: 'middle',
                            backgroundColor: '#03a9f4',
                            text: STRINGS.incidents.incidentEndAnnotation,
                            shape: 'callout',
                            //borderWidth: 0,
                            borderColor: '#03a9f4',
                            justify: true,
                            allowOverlap: true,
                            style: {
                                textAlign: 'center',
                                color: 'white',
                            },
                        }
                    });
                }
            }
        }
        if (annotationIndicator) {
            annotations.push({
                draggable: true,
                labels: {
                    point: {
                        x: parseTimeFromDALToUnix(annotationIndicator.startTime),
                        y: annotationIndicator?.details?.actualValue,
                        xAxis: 0, yAxis: 0
                    },
                    ...getAnnotationOffset(parseTimeFromDALToUnix(annotationIndicator.startTime) || 0, annotationIndicator?.details?.actualValue || 0, minX, maxX, minY, maxY),
                    //distance: 5,
                    align: 'center',
                    verticalAlign: 'middle',
                    backgroundColor: '#c74140',
                    text: isPrimaryIndicator ? STRINGS.incidents.primaryIndicatorStartAnnotation : STRINGS.incidents.correlatedIndicatorStartAnnotation,
                    shape: 'callout',
                    //borderWidth: 0,
                    borderColor: '#c74140',
                    justify: true,
                    allowOverlap: true,
                    style: {
                        textAlign: 'center',
                        color: 'white',
                    },
                }
            });
        }
    }
    if (runbooks && timeStampList.length > 0) {
        for (const runbook of runbooks) {
            const searchedValue = parseTimeFromDALToUnix(runbook?.timestamp) || 0;
            const runbookExecTimestamp = timeStampList.reduce((prev, curr) => {
                return (Math.abs(curr - searchedValue) < Math.abs(prev - searchedValue) ? curr : prev);
            });
            if (runbookExecTimestamp && Math.abs(runbookExecTimestamp - searchedValue) <= 15 * 60 * 1000) {
                const indicatorSample = indicatorData?.samples.find((item) => item.x === runbookExecTimestamp);
                annotations.push({
                    draggable: true,
                    labels: {
                        point: {
                            x: runbookExecTimestamp,
                            y: indicatorSample.y,
                            xAxis: 0, yAxis: 0
                        },
                        ...getAnnotationOffset(runbookExecTimestamp, indicatorSample.y, minX, maxX, minY, maxY),
                        //distance: 5,
                        align: 'center',
                        verticalAlign: 'middle',
                        backgroundColor: '#03a9f4',
                        text: STRINGS.incidents.runbookExecAnnotation,
                        shape: 'callout',
                        //borderWidth: 0,
                        borderColor: '#03a9f4',
                        justify: true,
                        allowOverlap: true,
                        style: {
                            textAlign: 'center',
                            color: 'white',
                        },
                    }
                });
            }
        }
    }

    if (timeStampList.length >= 2) {
        annotations.sort((a, b) => {
            return a.labels.point.x < b.labels.point.x ? -1 : a.labels.point.x > b.labels.point.x ? 1 : 0;
        });

        for (let i = 1; i < annotations.length; i++) {
            // Try to adjust the x and y offsets to fix overlapping annotations
            if (
                (((annotations[i].labels.point.x - annotations[i - 1].labels.point.x)/widthX) < .05) &&
                (Math.abs((annotations[i].labels.point.y - annotations[i - 1].labels.point.y)/widthY) < .05)
            ) {
                if ((annotations[i].labels.point.y - minY)/widthY < .05) {
                    // The annotation is close to the bottom of the page
                    if (annotations[i].labels.point.y <= annotations[i - 1].labels.point.y) {
                        // Move the previous point up because the point is near the botton and it is lower than the other point
                        annotations[i - 1].labels.y += -30;
                        //annotations[i].labels.y += 30;
                    } else if (annotations[i].labels.point.y > annotations[i - 1].labels.point.y) {
                        // Move the current point up because it is near the bottom and higher than the other point
                        annotations[i].labels.y += -30;
                    }
                } else if ((maxY - annotations[i].labels.point.y)/widthY < .05) {
                    // The annotation is close to the top of the page
                    if (annotations[i].labels.point.y <= annotations[i - 1].labels.point.y) {
                        // Move the current point down because the point is near the top and it is lower than the other point
                        annotations[i].labels.y += 30;
                    } else if (annotations[i].labels.point.y > annotations[i - 1].labels.point.y) {
                        // Move the previous point down because it is near the top and this point is higher than the other point
                        annotations[i - 1].labels.y += 30;
                        //annotations[i].labels.y += -30;
                    }
                } else {
                    // The annotation is in the middle of the page.  Check where the two points are and move it down if the 
                    // point is below the previous point and move it up if the point is above the previous point
                    annotations[i].labels.y += annotations[i].labels.point.y <= annotations[i - 1].labels.point.y ? 30 : -30;
                }
            }

            // Try to adjust the distances to fix overlapping annotations
            //if (((annotations[i].labels.point.x - annotations[i - 1].labels.point.x)/widthX) < .05 && annotations[i - 1].labels.distance === 5) {
            //    // If the distance between the two annotations is less than 5 percent of the width
            //    annotations[i].labels.distance = 30;
            //}
        }    
    }

    return annotations;
}

/** returns the annotations for the chart.  We are using the x and y offsets to position the annotations.
 *      note that in highcharts a negative value moves the annotation to the left and up respectively.
 *  @param indicatorData the indicator data that is displayed in the chart.
 *  @param isPrimaryIndicator a boolean value, true if we are displaying the primary indicator, false otherwise.
 *  @param annotationIncident the indicator that should be displayed in the annotations.
 *  @param annotationIndicator the indicator that should be displayed in the annotations.
 *  @param runbooks the list of runbooks that should be displayed in the annotations.
 *  @returns the array of annotations to be displayed in the chart. */
function getAnnotationsNew(
    indicatorData: any, isPrimaryIndicator: boolean, annotationIncident: Incident | undefined, 
    annotationIndicator: Indicator | undefined, runbooks: Array<RunbookOutput> | undefined,
    minX: number, maxX: number
): Array<any> {
    let annotations: any[] = [];

    let minY = Number.MAX_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    if (indicatorData?.samples) {
        for (const indicator of indicatorData?.samples) {
            minY = Math.min(minY, indicator.y);
            maxY = Math.max(maxY, indicator.y);
        }
    }
    //const widthY = maxY - minY;
    const widthX = maxX - minX;

    if (annotationIncident) {
        let searchedValue = parseTimeFromDALToUnix(annotationIncident.createdAt) || 0;
        if (searchedValue >= minX && searchedValue <= maxX) {
            annotations.push({
                draggable: true,
                labels: {
                    point: {
                        x: searchedValue,
                        y: 0,
                        xAxis: 0, yAxis: 0
                    },
                    ...getAnnotationOffset(searchedValue, 0, minX, maxX, minY, maxY),
                    //distance: 5,
                    align: 'center',
                    verticalAlign: 'middle',
                    backgroundColor: '#03a9f4',
                    text: STRINGS.incidents.incidentStartAnnotation,
                    shape: 'callout',
                    //borderWidth: 0,
                    borderColor: '#03a9f4',
                    justify: true,
                    allowOverlap: true,
                    style: {
                        textAlign: 'center',
                        color: 'white',
                    },
                }
            });        
        }
        searchedValue = parseTimeFromDALToUnix(annotationIncident.endTime !== null && annotationIncident.endTime !== "0" ? annotationIncident.endTime : 60 * 1000 * Math.floor(new Date().getTime() / (60 * 1000))) || 0;
        if (searchedValue >= minX && searchedValue <= maxX) {
            annotations.push({
                draggable: true,
                labels: {
                    point: {
                        x: searchedValue,
                        y: 0,
                        xAxis: 0, yAxis: 0
                    },
                    ...getAnnotationOffset(searchedValue, 0, minX, maxX, minY, maxY),
                    //distance: 5,
                    align: 'center',
                    verticalAlign: 'middle',
                    backgroundColor: '#03a9f4',
                    text: annotationIncident.endTime !== null && annotationIncident.endTime !== "0" ? STRINGS.incidents.incidentEndAnnotation : "Now",
                    shape: 'callout',
                    //borderWidth: 0,
                    borderColor: '#03a9f4',
                    justify: true,
                    allowOverlap: true,
                    style: {
                        textAlign: 'center',
                        color: 'white',
                    },
                }
            });
        }
    }
    if (annotationIndicator) {
        annotations.push({
            draggable: true,
            labels: {
                point: {
                    x: parseTimeFromDALToUnix(annotationIndicator.startTime),
                    y: annotationIndicator.details?.actualValue || 0,
                    xAxis: 0, yAxis: 0
                },
                ...getAnnotationOffset(parseTimeFromDALToUnix(annotationIndicator.startTime) || annotationIndicator.details?.actualValue || 0, 0, minX, maxX, minY, maxY),
                //distance: 5,
                align: 'center',
                verticalAlign: 'middle',
                backgroundColor: '#c74140',
                text: isPrimaryIndicator ? STRINGS.incidents.primaryIndicatorStartAnnotation : STRINGS.incidents.correlatedIndicatorStartAnnotation,
                shape: 'callout',
                //borderWidth: 0,
                borderColor: '#c74140',
                justify: true,
                allowOverlap: true,
                style: {
                    textAlign: 'center',
                    color: 'white',
                },
            }
        });
    }
    if (runbooks) {
        for (const runbook of runbooks) {
            const searchedValue = parseTimeFromDALToUnix(runbook?.timestamp) || 0;
            if (searchedValue >= minX && searchedValue <= maxX) {
                annotations.push({
                    draggable: true,
                    labels: {
                        point: {
                            x: searchedValue,
                            y: 0,
                            xAxis: 0, yAxis: 0
                        },
                        ...getAnnotationOffset(searchedValue, 0, minX, maxX, minY, maxY),
                        //distance: 5,
                        align: 'center',
                        verticalAlign: 'middle',
                        backgroundColor: '#03a9f4',
                        text: STRINGS.incidents.runbookExecAnnotation,
                        shape: 'callout',
                        //borderWidth: 0,
                        borderColor: '#03a9f4',
                        justify: true,
                        allowOverlap: true,
                        style: {
                            textAlign: 'center',
                            color: 'white',
                        },
                    }
                });
            }
        }
    }

    if (true) {
        annotations.sort((a, b) => {
            return a.labels.point.x < b.labels.point.x ? -1 : a.labels.point.x > b.labels.point.x ? 1 : 0;
        });

        for (let i = 1; i < annotations.length; i++) {
            // Try to adjust the x and y offsets to fix overlapping annotations
            if (
                (((annotations[i].labels.point.x - annotations[i - 1].labels.point.x)/widthX) < .05)
            ) {
                let numMatches = 0;
                for (let j = 0; j < i; j++) {
                    if (annotations[i].labels.point.y === annotations[j].labels.point.y) {
                        numMatches++;
                    }
                }
                if (numMatches > 0) {
                    annotations[i].labels.y += numMatches * 30;
                }
            }
        }    
    }

    return annotations;
}

/** get the offset for the annotation that takes into account the point it is connected to and whether
 *  it is at the top, bottom, left or right.
 *  @param x the x value of the point.
 *  @param y the y value of the point. 
 *  @param minX the minimum x value on the chart.
 *  @param maxX the maximum x value on the chart.
 *  @param minY the minimum y value on the chart.
 *  @param maxY the maximum y value on the chart.
 *  @returns the x and y offset that should be used for the annotation. */
function getAnnotationOffset(
    x: number, y: number, minX: number, maxX: number, minY: number, maxY: number
): {x: number, y: number} {
    const widthX = maxX - minX;
    const widthY = maxY - minY;

    return {
        x: ((x - minX)/widthX < .05 ? 90 : (maxX - x)/widthX < .05) ? -90 : -90, 
        y: ((y - minY)/widthY < .05 ? -30 : (maxY - y)/widthY < .05) ? +30 : -30
    };
}

/** returns the time range that will query the max allowable time range.  Right now since the highest time range button
 *      is 24 hrs this function will return a time range where the start time is 25 hours before then specified end time.
 *      We use 25 hours because that is what the baseline chart can display.
 *  @param indicatorTimeRange the timerange in which the indicator is expected to be found.
 *  @param incidentTimeRange the incident time range.
 *  @returns the max time range that will cover the largest time range to be displayed in the chart. */
export function getMaxTimeRange(indicatorTimeRange: TIME_RANGE, incidentTimeRange: TIME_RANGE | undefined, chartTimeRange: number): TIME_RANGE {
    if (!incidentTimeRange || chartTimeRange !== 0) {
        const newTimeRange = {...indicatorTimeRange};
        newTimeRange.startTime = newTimeRange.endTime - 25 * 60 * 60 * 1000;
        return newTimeRange;    
    } else {
        const newTimeRange = {...incidentTimeRange};
        newTimeRange.startTime = Math.min(newTimeRange.startTime, indicatorTimeRange.startTime);
        newTimeRange.startTime = Math.min(newTimeRange.startTime, newTimeRange.endTime - 1 * 60 * 60 * 1000);
        newTimeRange.startTime = Math.min(newTimeRange.startTime, incidentTimeRange.startTime - 1 * 60 * 60 * 1000);
        return newTimeRange;    
    }
}

/** returns the time range that will query the max allowable time range.  Right now since the highest time range button
 *      is 24 hrs this function will return a time range where the start time is 25 hours before then specified end time.
 *      We use 25 hours because that is what the baseline chart can display.
 *  @param indicatorTimeRange the timerange in which the indicator is expected to be found.
 *  @param incidentTimeRange the incident time range.
 *  @param nAdditionalIntervals the number of 6 hour additional intervals to add to the start time.
 *  @param nOffsets the offset in 6 hour increments
 *  @param timeReference the time reference which states whether to show the start or end of the incident.
 *  @returns the max time range that will cover the largest time range to be displayed in the chart. */
function getTimeRange(
    indicatorTimeRange: TIME_RANGE, incidentTimeRange: TIME_RANGE | undefined, nAdditionalIntervals: number, nOffsets: number,
    timeReference: TIME_REFERENCE
): TIME_RANGE {
    let newTimeRange = incidentTimeRange ? {...incidentTimeRange} : {...indicatorTimeRange};

    if (incidentTimeRange && timeReference === TIME_REFERENCE.START && (incidentTimeRange.endTime - incidentTimeRange.startTime) > MAX_INTERVAL) {
        // We cannot see both the start and end of the incident so center the full width of the max time 
        // range around the start time of the incident
        newTimeRange = {startTime: incidentTimeRange.startTime - (MAX_INTERVAL / 2), endTime: incidentTimeRange.startTime + (MAX_INTERVAL / 2)};
    } else {
        newTimeRange.startTime = Math.min(newTimeRange.startTime, indicatorTimeRange.startTime);
        newTimeRange.startTime = Math.min(newTimeRange.startTime, newTimeRange.endTime - 1 * 60 * 60 * 1000);
        if (incidentTimeRange) {
            newTimeRange.startTime = Math.min(newTimeRange.startTime, incidentTimeRange.startTime - 1 * 60 * 60 * 1000);
        }
        newTimeRange.startTime = Math.max(newTimeRange.startTime, newTimeRange.endTime - MAX_INTERVAL);    
    }

    // Make any changes to the interval initiated by the user and make sure they don't exceed the max query interval for the user
    if (nAdditionalIntervals) {
        newTimeRange.startTime = newTimeRange.startTime - nAdditionalIntervals * EXPAND_INCR_HRS * 60 * 60 * 1000;
    }
    if (nOffsets) {
        newTimeRange.endTime = newTimeRange.endTime - nOffsets * EXPAND_INCR_HRS * 60 * 60 * 1000;
        newTimeRange.startTime = newTimeRange.startTime - nOffsets * EXPAND_INCR_HRS * 60 * 60 * 1000;
    }
    newTimeRange.startTime = Math.max(newTimeRange.startTime, newTimeRange.endTime - MAX_USER_INTERVAL);    

    return newTimeRange;
}

/** returns the legend information for metrics that are enums. 
 *  @param metricDef the DataOceanMetric definition.
 *  @returns a String with the enum information. */
export function getLegendForEnum(metricDef: DataOceanMetric | undefined): string {
    let enumText = "";
    if (metricDef?.enum) {
        const enumKeys = Object.keys(metricDef.enum);
        const enumArray: Array<string> = [];
        for (const enumKey of enumKeys) {
            enumArray.push(enumKey + " = " + metricDef.enum[enumKey]);
        }
        enumText = " (" + enumArray.join(", ") + ")";
    }
    return enumText;
}

/** returns the granularity summary for the chart header. 
 *  @param granularity a number in minutes or undefined if there is no granularity.
 *  @returns a String with the granularity. */
export function getGranularitySummary(granularity: number | undefined): string {
    const granText = getGranularityText(granularity);
    return granText && granText?.trim()?.length ? " (every " + granText + ")" : "";
}
