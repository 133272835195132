import React, { useState } from 'react';
import { Alert, Button, Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as yup from 'yup';
import { STRINGS } from 'app-strings';
import { TenantManagementService } from 'utils/services/TenantManagementService';
import { Form, InputField, SelectField, TextAreaField } from 'components/common/form';

import {
    RadioButtonField,
    RadioButtonGroup,
} from 'components/common/form/RadioButton';

import 'pages/support/SupportCaseForm.scss';
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";

const AuthService = AuthServiceProvider.getService();

const issueTypeOptions = [
    STRINGS.SUPPORT_PAGE.techSupport.issueType.options.problem,
    STRINGS.SUPPORT_PAGE.techSupport.issueType.options.question,
    STRINGS.SUPPORT_PAGE.techSupport.issueType.options.accountAdministration,
    STRINGS.SUPPORT_PAGE.techSupport.issueType.options.featureRequest,
    STRINGS.SUPPORT_PAGE.techSupport.issueType.options.security,
    STRINGS.SUPPORT_PAGE.techSupport.issueType.options.other,
];

const priorities = [
    STRINGS.SUPPORT_PAGE.techSupport.priority.options.p1,
    STRINGS.SUPPORT_PAGE.techSupport.priority.options.p2,
    STRINGS.SUPPORT_PAGE.techSupport.priority.options.p3,
    STRINGS.SUPPORT_PAGE.techSupport.priority.options.p4,
];

const validationSchema = yup.object().shape({
    contact_phone_number: yup
        .string()
        .notRequired()
        .test(
            'contact_phone_number',
            STRINGS.SUPPORT_PAGE.techSupport.contactPhoneNumber.validation,
            function (value) {
                if(!!value) {
                    const schema = yup.number();
                    return schema.isValidSync(value);
                }
                return true;
            }
        ),
    case_subject: yup
        .string()
        .label(STRINGS.SUPPORT_PAGE.techSupport.subject.label)
        .required(),
    case_description: yup
        .string()
        .label(STRINGS.SUPPORT_PAGE.techSupport.caseDescription.label)
        .required(),
});

/***
 * Creates a support link for the provided case item
 * @param caseItem
 */
const createLinkForCase = (caseItem) => {
    const supportUrl = `https://support.riverbed.com/content/support/my_riverbed/cases_and_rmas/view_case.html?caseId=${ caseItem.sfdc_case_id }`;

    return (
        '<a href=' +
        supportUrl +
        ' target="_blank" rel="noopener noreferrer"><u>' +
        caseItem.sfdc_case_number +
        '</u></a>'
    );
};

const renderEmail = () => {
    return '<b><i>' + AuthService.getUserAccount().getUsername() + '</i></b>';
};

const SupportCaseForm = () => {
    const [error, setError] = useState();
    const [caseItem, setCaseItem] = useState<any>();
    const [confirmOpen, setConfirmOpen] = useState(false);

    /***
     * onSubmit Handler function for the support formik form
     * Resets the form after successful submission
     * @param values
     * @param resetForm
     */
    const handleSubmit = (values, { resetForm }) => {
        const tenantId = AuthService.getTenantId();
        return new Promise ((resolve, reject) => {
            TenantManagementService.post(
                `common/public/tenant/${ tenantId }/support-ticket`,
                {
                    issue_type: values['issue_type'],
                    product: values['product'],
                    contact_email: AuthService.getUserAccount().getUsername(),
                    contact_phone_number: values['contact_phone_number'],
                    priority: values['priority'],
                    subject: values['case_subject'],
                    case_description: values['case_description'],
                }
            ).then ( response => {
                setCaseItem(response);
                setConfirmOpen(true);
                resetForm();
                resolve(response);
            }, error => {
                setError(error.message);
                setCaseItem(undefined);
                setConfirmOpen(false);
                reject(error);
            });
        });
    };

    const handleConfirm = () => {
        setCaseItem(undefined);
        setConfirmOpen(false);
    };

    return (
        <div className="support-form">
            <Form
                initialValues={ {
                    issue_type: issueTypeOptions[0],
                    //TODO: Check if we have a variable for this?
                    product: 'visibility',
                    priority: priorities[2],
                    contact_phone_number: '',
                    case_subject: '',
                    case_description: '',
                } }
                validationSchema={ validationSchema }
                loading={ false }
                onSubmit={ handleSubmit }
            >
                <SelectField
                    label={ STRINGS.SUPPORT_PAGE.techSupport.issueType.label }
                    name="issue_type"
                    options={ issueTypeOptions }
                />

                <InputField
                    name="username"
                    type="text"
                    className="input-field"
                    label={ STRINGS.SUPPORT_PAGE.techSupport.email.label }
                    placeholder={ AuthService.getUserAccount().getUsername() }
                    disabled={ true }
                />

                <InputField
                    name="contact_phone_number"
                    type="text"
                    className="input-field"
                    label={
                        STRINGS.SUPPORT_PAGE.techSupport.contactPhoneNumber
                            .label
                    }
                    placeholder={
                        STRINGS.SUPPORT_PAGE.techSupport.contactPhoneNumber
                            .placeholder
                    }
                />

                <RadioButtonGroup
                    label={ STRINGS.SUPPORT_PAGE.techSupport.priority.label }
                    name="priority"
                    inline={ true }
                >
                    <RadioButtonField
                        label={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options.p1
                        }
                        value={ priorities[0] }
                        helperText={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options
                                .p1HelpText
                        }
                    />
                    <RadioButtonField
                        label={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options.p2
                        }
                        value={ priorities[1] }
                        helperText={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options
                                .p2HelpText
                        }
                    />
                    <RadioButtonField
                        label={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options.p3
                        }
                        value={ priorities[2] }
                        helperText={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options
                                .p3HelpText
                        }
                    />
                    <RadioButtonField
                        label={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options.p4
                        }
                        value={ priorities[3] }
                        helperText={
                            STRINGS.SUPPORT_PAGE.techSupport.priority.options
                                .p4HelpText
                        }
                    />
                </RadioButtonGroup>

                <InputField
                    data-testid="case-subject"
                    name="case_subject"
                    required={true}
                    type="text"
                    className="subject-field"
                    maxLength={ 255 }
                    label={ STRINGS.SUPPORT_PAGE.techSupport.subject.label }
                    placeholder={
                        STRINGS.SUPPORT_PAGE.techSupport.subject.placeholder
                    }
                />

                <TextAreaField
                    data-testid="case-description"
                    required={true}
                    name="case_description"
                    inline={ false }
                    maxLength={ 32000 }
                    className="description-field"
                    label={
                        STRINGS.SUPPORT_PAGE.techSupport.caseDescription.label
                    }
                    placeholder={
                        STRINGS.SUPPORT_PAGE.techSupport.caseDescription
                            .placeholder
                    }
                />
                { error ? (
                    <Callout intent={ Intent.DANGER }>{ error }</Callout>
                ) : null }
                <React.Fragment>
                    <Button
                        data-testid="btn-support-submit"
                        type="submit"
                        className="tir-ui-btn-submit"
                        intent={ Intent.PRIMARY }
                    >
                        { STRINGS.submitButtonLabel }
                    </Button>
                </React.Fragment>
                { caseItem ? (
                    <Alert
                        confirmButtonText={ STRINGS.runbooks.okBtnText }
                        icon={ IconNames.TICK }
                        intent={ Intent.SUCCESS }
                        isOpen={ confirmOpen }
                        onConfirm={ handleConfirm }
                    >
                        <span
                            dangerouslySetInnerHTML={ {
                                __html: STRINGS.formatString(
                                    STRINGS.SUPPORT_PAGE.techSupport
                                        .successMessage,
                                    createLinkForCase(caseItem),
                                    renderEmail()
                                ).toString(),
                            } }
                        />
                    </Alert>
                ) : null }
            </Form>
        </div>
    );
};

export { SupportCaseForm };
