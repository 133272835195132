import { StatusLEDProps, StatusLED } from 'components/common/status-led/StatusLED';
import React, { ReactNode } from 'react';
import "./CardsHolder.scss";

export interface CardsHolderProps extends StatusLEDProps {
    title?: string
    className?: string
    children?: ReactNode
}

export function CardsHolder ({ title, className, children, ...props }: CardsHolderProps) {
    const content = <div className={"cards-holder d-flex flex-wrap" + (className ? " " + className : "")} >{children}</div>;
    if (title !== undefined) {
        props.icon = props.icon || "none";
        return <div className="cards-holder-with-title">
            <StatusLED className="display-7 mb-3" label={title} {...props}/>
            {content}
        </div>
    } else {
        return content;
    }
}