import React from 'react';
import moment from 'moment';
import { CHART_COLORS } from 'components/enums';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import {
	TimeChart,
	TimeChartDatum,
} from 'components/common/time-chart/TimeChart';
import { LineStyle } from 'components/common/chart-base/ChartToolbar';
import { Unit } from 'reporting-infrastructure/types/Unit.class';

import { STRINGS } from 'app-strings';


/** the properties passed into the React component. */
interface DataIngestGraphProps {
	loading: boolean;
	data: Array<any> | undefined;
	error: string;
}

/** Renders the data ingest graph.
 *  @param props the properties passed in.
 *  @returns JSX with the analytics graph.*/
export const DataIngestGraph = (props: DataIngestGraphProps): JSX.Element => {
	const { error, data, loading } = props;
	const tsKeyData: Array<TimeChartDatum> = [];

	if (
		!loading &&
		data &&
		data['statistics'] &&
		'dailyMetricsUsage' in data['statistics']
	) {
		const primaryData = [...data['statistics'].dailyMetricsUsage].sort(
			(a, b) => {
				return a.timestamp - b.timestamp;
			}
		);

		if (primaryData && primaryData.length > 0) {
			let ts: Array<any> = [];
			primaryData.forEach((item) => {
				ts.push({
					x: item['timestamp'] * 1000,
					y:
						item['value'] || item['value'] === 0
							? item['value']
							: null,
				});
			});
			tsKeyData.push({
				groupName:
					STRINGS.HEALTH_MONITORING_PAGE.graphs.dataIngest.metricName,
				groupId: 'data-ingest',
				metricName:
					STRINGS.HEALTH_MONITORING_PAGE.graphs.dataIngest.metricName,
				metricId: 'data-ingest',
				unit: new Unit('count'),
				data: ts,
			});
		}
	}

	const chartOptions: Highcharts.Options = {
		title: {
			text: STRINGS.HEALTH_MONITORING_PAGE.graphs.dataIngest.title,
			align: 'left',
			x: 45,
			style: {
				color: CHART_COLORS.TITLE_DEFAULT,
			},
		},
		legend: {
			enabled: false,
			align: 'left',
			verticalAlign: 'bottom',
			floating: false,
		},
		plotOptions: {
			series: {
				connectNulls: false,
			},
		},
		xAxis: {
			labels: {
				formatter: function () {
					return moment(this.value)
						.format('MMM Do, HH:mm')
						.toString();
				},
				step: 2,
				enabled: true,
			},
		},
		time: {
			useUTC: false,
		},
	};

	return (
		<React.Fragment>
			<DataLoadFacade
				loading={loading}
				error={error}
				data={data}
				showContentsWhenLoading={true}
				transparent={true}
			>
				<TimeChart
					loading={loading}
					showChartSubtitle={false}
					height="350px"
					options={chartOptions}
					primaryData={tsKeyData}
					settings={{ style: LineStyle.area, showMore: false }}
					transparent={true}
					enableFullScreen={false}
				/>
			</DataLoadFacade>
		</React.Fragment>
	);
};
