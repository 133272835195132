/** This module contains all the types for the layout framework.
 *  @module
 */

/** this enum defines the valid layouts. */
export enum LAYOUT_TYPE {
    /** the enumerated value for the horizontal layout. */
    HORIZONTAL  = "horizontal",
    /** the enumerated value for the vertical layout. */
    VERTICAL    = "vertical",
    /** the enumerated value for the grid layout. */
    GRID        = "grid",
    /** the enumerated value for the grid layout. */
    CUSTOM        = "custom",
}

/** defines the layout configuration object. */
export type LayoutConfig = {
    /** the type of layout. */
    type: LAYOUT_TYPE,
    /** the layout options. */
    options?: ResponsiveCustomLayouts
}

/** defines the layouts matrix. */
export type ResponsiveCustomLayouts = {
    lg: CustomLayout[],
    md: CustomLayout[],
    sm: CustomLayout[],
    xs: CustomLayout[],
    xxs: CustomLayout[],
}

/** defines the react grid layout options. */
export type CustomLayout = {
    /** a string with the widget id. */
    i: string;
    /** a number with the react grid width. These are all in grid units, not px */
    w: number;
    /** a number with the react grid height. These are all in grid units, not px */
    h: number;
    /** a number with the react grid x grid location.  x starts with 0 on the left. */
    x: number;
    /** a number with the react grid y grid location. y starts with 0 on the top. */
    y: number;
    /** a number with the react grid minimun Width. Default 0 */
    minW?: number;
    /** a number with the react grid maximun Width. It could be Infinity */
    maxW?: number;
    /** a number with the react grid minimun Height. Default 0 */
    minH?: number;
    /** a number with the react grid maximun Width. It could be Infinity */
    maxH?: number;
    /** If true, equal to `isDraggable: false, isResizable: false */
    static?: boolean;
    /** If false, will not be resizable. Overrides `static` */
    isResizable?: boolean;
    /** If false, will not be draggable. Overrides `static` */
    isDraggable?: boolean;
    /** */
    moved?: boolean;
}
