/** This file defines the SearchItemDesc React component.  The SearchItemDesc react 
 *  component displays the search result description in the search result output.
 *  @module */
import React, { ReactNode } from 'react';

/** this interface defines the properties passed into the SearchItemDesc component. */
export interface SearchItemDescProps {
    /** the children that are to be displayed in the search item description. */
    children?: ReactNode;
}

/** Creates the the search item description component which displays the description in a search result.
 *  @param props an object with the properties passed to the search item description component.
 *  @returns JSX with the search item description component.*/
export function SearchItemDesc({ children }: SearchItemDescProps) {
    return <div className="mt-2 font-italic">
        {children}
    </div>
}

