/** This module contains some general formatters for timestamps
 *  @module
 */
import moment from "moment";
import { TIME_FORMAT } from 'components/enums';
import { Samples } from "types";
import { timeSeriesTooltipFormatterParams } from "components/reporting/utils/Types";
import { scaleMetric } from ".";
import { Unit } from 'reporting-infrastructure/types/Unit.class';

/** formats a date object to a formatted string in the users local timezone.
 *  @param timestamp the Date to format.
 *  @param formatter the format to use when formatting the date.
 *  @returns a String with the formatted date in the user's local timezone. */
export const formatToLocalTimestamp = (timestamp?: Date, formatter?: string): string => {
    const defaultFormatter = TIME_FORMAT.DEFAULT_FORMAT;
    if (timestamp) {
        if (formatter) {
            return moment.utc(timestamp).local().format(formatter);
        }
        return moment.utc(timestamp).local().format(defaultFormatter);
    } else {
        return "";
    }
}

/** formats a date object to a formatted string in the gmt timezone.
 *  @param timestamp the Date to format.
 *  @param formatter the format to use when formatting the date.
 *  @returns a String with the formatted date in the gmt timezone. */
export const formatToUtcTimestamp = (timestamp?: Date, formatter?: string): string => {
    const defaultFormatter = TIME_FORMAT.DEFAULT_FORMAT;
    if (timestamp) {
        if (formatter) {
            return moment.utc(timestamp).format(formatter);
        }
        return moment.utc(timestamp).format(defaultFormatter);
    } else {
        return "";
    }
}

/** formats a unix timestamp to a formatted string in the users local timezone.
 *  @param timestamp the unix timestamp to format.
 *  @param formatter the format to use when formatting the timestamp.
 *  @returns a String with the formatted timestamp in the user's local timezone. */
export const formatUnixToLocalTimestamp = (timestamp?:number, formatter: string = TIME_FORMAT.DISPLAY_TIME_FORMAT): string => {
    return timestamp ? moment.unix(timestamp).format(formatter) : "";
}

/** formats a duration into a String.
 *  @param durationInSeconds a Number with the duration in seconds.
 *  @param shortForm a boolean value, true if the short form should be displayed.  This defaults to false.
 *  @returns a String with the formatted duration. */
export const formatDurationToString = (durationInSeconds: number, shortForm = false): string => {
    let durationString = "";
    if (durationInSeconds && durationInSeconds > 0) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds - (hours * 3600)) / 60);
        const secs = Math.floor(durationInSeconds) - ((hours * 3600) + (minutes * 60));
        if (shortForm) {
            durationString = (hours > 0 ? hours + "h" : "") +
                (hours > 0 && (minutes > 0 || secs > 0) ? " " : "") + // Check and add space between hours and mins
                (minutes > 0 ? minutes + "m" : "") +
                (minutes > 0 && secs > 0 ? " " : "") + // Check and add space between mins and secs
                (secs > 0 ? secs + "s" : "");
        } else {
            if (hours > 0) {
                durationString = hours + " hour" + (hours > 1 ? "s" : "");
            }
            if (minutes > 0) {
                if (durationString !== "") {
                    durationString += secs > 0 ? " " : " and "
                }
                durationString = durationString + minutes + " min" + (minutes > 1 ? "s" : "");
            }
            if (secs > 0) {
                durationString += (hours > 0 || minutes > 0) ? " and " : ""
                durationString += secs + " sec" + (secs > 1 ? "s" : "");
            }
        }
    }
    return durationString;
}

/** calculates the complement and returns it.
 *  @param num the number.
 *  @param whole the max value.
 *  @returns the complement. */
export function complement(num: number, whole = 100) {
    return whole - num;
}

/** takes a set of samples and outputs an array of numbers for the perf chart.
 *  @param samples the samples.
 *  @returns an array of numbers with the perf chart data. */
export function getPerformaceChartData(samples: Array<Samples>): Array<number | null> {
    if (samples) {
        return samples
            .map((s) => {
                if (typeof s?.severity?.score === "number") {
                    return complement(s?.severity?.score);
                } else {
                    return null;
                }
            }).filter((s) => s !== null);
    } else {
        return [];
    }

}
// export function getPerformaceChartData(samples: Array<Samples>): Array<{ x: Date, y: number }> {
//     let performanceData:Array<{ x: Date, y: number }> = [];
//     if (samples) {
//         for (const s of samples) {
//             if (typeof s?.severity?.score === "number" && s?.timestamp) {
//                 const parsedTime = parseTimeFromDAL(s.timestamp);
//                 if (parsedTime) {
//                     performanceData.push({
//                         x: parseTimeFromDAL(s.timestamp) || new Date(),
//                         y: complement(s?.severity?.score || 0),
//                     });

//                 }
//             }
//         }
//     }
//     return performanceData;
// }

/** formats the tooltip for a timeseries chart.
 *  @param data the data to format.
 *  @param unit the Unit.
 *  @param format the format.
 *  @returns a String with the formatted tooltip. */
export function formatTimeSeriesTooltip (data: timeSeriesTooltipFormatterParams, unit?: Unit, format: TIME_FORMAT = TIME_FORMAT.DISPLAY_TIME_ONLY_FORMAT) {
    return scaleMetric(data.y || 0, unit).formatted + (data.x ? "<br/>" + formatToLocalTimestamp(data.x, format) : "")
}
