/** This module contains the function React component for rendering a data ocean filter.  This component
 *  renders one and only one filter.
 *  @module
 */
import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { Icon, IconNames } from "@tir-ui/react-components";
import { HTMLSelect, Intent, TextArea } from "@blueprintjs/core";
import { DataOceanUtils, ENABLE_VANTAGE_POINT } from "./DataOceanUtils";
import { 
    GraphDef, NodeDef, RunbookInfo, Variant, VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS 
} from '../../types/GraphTypes';
import { ContextMode, ContextSource, CONTEXT_MODE, RunbookContext, VariableContextByScope } from 'utils/runbooks/RunbookContext.class';
import { getNodeFromGraphDef } from 'utils/runbooks/RunbookUtils';
import { DS_OPTION_VALUE, FilterMetadata, FilterMetadataOptionsValue, USER_OPTION_VALUE } from './DataOceanFilters';
import { STRINGS } from 'app-strings';
import { VariableContext } from 'utils/runbooks/VariableContext';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { CustomProperty, CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { MultiSelectInput } from 'components/common/multiselect/MultiSelectInput';
import { getArDataSources } from 'utils/stores/GlobalDataSourceTypeStore';
import { getFilterVariableSources, VariableContextSource } from './DataOceanFilterUtils';

/** This interface defines the properties passed into the data ocean filter React component.*/
export interface DataOceanFilterProps {
    /** a boolean value, true if the filter is required false otherwise. */
    isRequired?: boolean;
    /** the current set of properties for the data ocean editor across all the different 
     *  user configurable attributes.*/
    currentProperties: Record<string, any>;
    /** an object with the properties that define the filter. */
    filterProps: FilterMetadata;
    /** a string with the filter label. */
    label: string;
    /** a reference to the parent data ocean node, if there is a parent data ocean node. */
    parentNode?: NodeDef;
    /** the runbook object with the runbook configuration. */
    activeRunbook?: RunbookInfo;
    /** a string with the filter name used as the key in the data ocean. */
    filterName: string;
    /** a string with the do node id. */
    doNodeId: string;
    /** the GraphDef with the definition of the entire runbook. */
    graphDef: GraphDef;
    /** the handler for onChange events. */
    onChange?: (value) => void;
    /** the variant of runbook that we are editing. */
    variant?: Variant;
};

/** Renders the component to render filter drop-downs and text fields
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean filter component.*/
export const DataOceanFilter = (props: DataOceanFilterProps): JSX.Element | null => {
    const {getVariables} = useContext(VariableContext);
    const [showUserInput, setShowUserInput] = useState(false);
    const [showDataSources, setShowDataSources] = useState(false);
    const { isRequired = false, currentProperties, filterProps, label, parentNode, activeRunbook, filterName, doNodeId, graphDef } = props;

    const [optionsForFilterDropDown, setOptionsForFilterDropDown] = useState(new Array<FilterMetadataOptionsValue>());

    const [selectFilterValue, setSelectFilterValue] = useState<string>("");
    const [defaultUserInputValue, setDefaultUserInputValue] = useState([]);

    const customProperties = useContext(CustomPropertyContext);

    const variant = props.variant;
    useEffect(() => {
        // The options need to be set only if the filter field type is a select drop-down
        const variables: VariableContextByScope = {
            runtime: getVariables(RUNTIME_SCOPE),
            incident: !VARIANTS_WITH_INCIDENT_VARS.includes(variant!)
                ? {primitiveVariables: [], structuredVariables: []} 
                : getVariables(INCIDENT_SCOPE),
            global: !VARIANTS_WITH_GLOBAL_VARS.includes(variant!) 
                ? {primitiveVariables: [], structuredVariables: []} 
                : getVariables(GLOBAL_SCOPE)
        };
        if (filterProps.fieldType === "select") {
            setOptionsForFilterDropDown(getOptions(
                filterProps, parentNode, activeRunbook, isRequired, filterName, doNodeId, graphDef, variables, customProperties, false
            ));
        }
    },[filterProps, parentNode, activeRunbook, isRequired, setOptionsForFilterDropDown, filterName, doNodeId, graphDef, getVariables, variant, customProperties]);

    useEffect(() => {
        const filterValues = currentProperties["filters"][filterProps.key];
        let defaultFilterValue = "";
        let defaultUserInputValue;

        if (filterProps.fieldType === "text") {
            if(filterValues && filterValues.length) {
                defaultFilterValue = filterValues[0];
            }
        } else if (filterProps.fieldType === "select" && optionsForFilterDropDown && optionsForFilterDropDown.length) {
            // Check and apply all valid options only if the filter field type is a select drop-down
            if (filterValues && filterValues.length) {

                // If the array has an entry of type string it means that the value is either $trigger or $node.<filter-type
                // If the array has entries of type object then its an user specified value which is in the format
                // {name: value}
                if(typeof(filterValues[0]) === "string" && filterValues[0].indexOf("$") === 0 && !filterValues[0].startsWith("$id")) {
                    defaultFilterValue = getDefaultFilterValue(filterValues[0], optionsForFilterDropDown);
                    defaultUserInputValue = [];
                } else if (typeof(filterValues[0]) === "string") {
                    defaultFilterValue = DS_OPTION_VALUE;
                } else {
                    defaultUserInputValue = getUserInputInNameValuePair(filterValues);
                    defaultFilterValue = USER_OPTION_VALUE;
                }
            } else {
                defaultFilterValue = getDefaultFilterValue(defaultFilterValue, optionsForFilterDropDown);
            }
        }
        if (defaultFilterValue) {
            currentProperties["filters"][filterProps.key] = defaultFilterValue === USER_OPTION_VALUE 
                ? defaultUserInputValue 
                : defaultFilterValue === DS_OPTION_VALUE ? filterValues : [defaultFilterValue];

            setSelectFilterValue(defaultFilterValue);
            setDefaultUserInputValue(defaultUserInputValue);
            if (defaultFilterValue === USER_OPTION_VALUE) {
                setShowUserInput(true);
            }
            if (defaultFilterValue === DS_OPTION_VALUE) {
                setShowDataSources(true);
            }
            if (props.onChange) {
                props.onChange(currentProperties['filters'][filterProps.key]);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProperties, optionsForFilterDropDown, filterProps.key]);

    if (!optionsForFilterDropDown) {
        return null;
    }

    return (
        <React.Fragment>
            <tr>
                <td className="p-1" colSpan={2}>
                    <label className='mb-0 pb-1'>{ label }</label>
                    { isRequired ? <Icon icon={ IconNames.ASTERISK } intent={ Intent.DANGER } iconSize={ 8 }/> : null }
                    <br />
                    {
                        renderFieldBasedOnType({
                            filterProps,
                            currentProperties,
                            optionsForFilterDropDown,
                            isRequired,
                            selectFilterValue,
                            setSelectFilterValue,
                            setShowUserInput,
                            setShowDataSources,
                            onChange: props.onChange
                        })
                    }
                </td>
            </tr>
            {
                showUserInput && false ?
                    renderTextArea({
                            defaultUserInputValue,
                            label,
                            filterProps,
                            currentProperties,
                            onChange: props.onChange
                        }
                    )
                    :
                    null
            }
            {showDataSources && <tr>
                <td colSpan={2}>
                    <MultiSelectInput
                        sortable
                        items={ (getArDataSources() || []).map(ds => {
                            return { display: ds.name, value: ds.id }
                        }) }
                        selectedItems={ (currentProperties['filters'][filterProps.key] || []).map(dsId => {
                            const ds = (getArDataSources() || []).find(dsObj => {
                                // RBO wants a $id in front of the id of the data source to know whether it is an 
                                // id or name.  Pull the id from the item
                                dsId = dsId.startsWith("$id:") ? dsId.substring(4, dsId.length) : dsId;
                                return dsObj.id === dsId;
                            });
                            return ds ? { display: ds.name, value: ds.id } : undefined;
                        }).filter(ds => ds !== undefined && ds !== null) || [] }
                        onChange={ updatedValues => {
                            if (updatedValues) {
                                const ids: string[] = updatedValues.map(item => {
                                    // RBO wants a $id in front of the id of the data source to know whether it is an 
                                    // id or name
                                    return "$id:" + item.value;
                                });
                                currentProperties['filters'][filterProps.key] = new Array<string>(...ids);
                                if (props.onChange) {
                                    props.onChange(currentProperties['filters'][filterProps.key]);
                                }
                            } else {
                                currentProperties['filters'][filterProps.key] = null;
                            }
                        } }
                        placeholder={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.dataSourcePlaceHolder }
                        disabled={false}
                    />
                </td>
            </tr>}
        </React.Fragment>
    )
};

/** Render the text area with given params
 *  @param props
 *  @param defaultUserInputValue . 
 *  @param label a String with the label to display with the text area. 
 *  @param filterProps the filter metadata that defines the filter and all its potential options.
 *  @param currentProperties the dictionary with all the properties currently being edited and their values. 
 *  @param onChange the handler for property change events.
 *  @returns the React component with the text area. */
const renderTextArea = (props: {
    defaultUserInputValue: any, label: string, filterProps: FilterMetadata, 
    currentProperties: Record<string, any>, onChange?: (value) => void
}): JSX.Element => {
    const { defaultUserInputValue, label, filterProps, currentProperties, onChange} = props;
    return (
        <tr>
            <td className="p-1">
                <TextArea
                    required={ true }
                    defaultValue={ defaultUserInputValue ? defaultUserInputValue.map( item => item["name"]).join(", "): ""}
                    name={ `${ label }_user_input` }
                    growVertically={ true }
                    fill={ true }
                    placeholder={ filterProps.inputPlaceHolder }
                    onBlur={ e => {
                        if (e.target.value) {
                            currentProperties['filters'][filterProps.key] = new Array<{ name: string }>();
                            e.target.value.split(",").forEach( value => {
                                currentProperties['filters'][filterProps.key].push({
                                    name: value.trim()
                                });
                            });
                            if (onChange) {
                                onChange(currentProperties['filters'][filterProps.key]);
                            }
                        } else {
                            currentProperties['filters'][filterProps.key] = null;
                        }
                    } }
                />
            </td>
        </tr>);
};

/** Function to convert an array of strings into an array of objects with name value pair
 *  @param defaultUserInputValue
 *  @returns .*/
const getUserInputInNameValuePair = (defaultUserInputValue: Array<any>): Array<{name: string}> => {
    if (typeof defaultUserInputValue[0] === "string") {
        return defaultUserInputValue.map( value => ({name: value.trim()}));
    } else {
        return defaultUserInputValue;
    }
}

/** Check if thee currentFilterValue exists in the optionsForFilterDropDown. If yes return currentFilterValue else
 *      return first option in the optionsForFilterDropDown
 *  @param currentFilterValue a String with the current value of this filter.
 *  @param optionsForFilterDropDown the current set of options for this filter.
 *  @returns a String with the default filter value. */
const getDefaultFilterValue = (currentFilterValue: string, optionsForFilterDropDown: Array<FilterMetadataOptionsValue>): string => {
    let filterValue: string = "";
    if (optionsForFilterDropDown.filter(option => {
        return option.value === currentFilterValue;
    }).length > 0) {
        filterValue = currentFilterValue;
    } else {
        const firstValidOption = optionsForFilterDropDown.find(option => {
            return (option.value !== "" && option.value !== USER_OPTION_VALUE && !option.disabled);
        });
        if (firstValidOption) {
            filterValue = firstValidOption.value;
        } else if (optionsForFilterDropDown.length > 0) {
            filterValue = optionsForFilterDropDown[0].value;
        }
    }

    return filterValue;
}

/** Filter options in filterProps object based on the given activeRunbook and required params
 *  @param filterProps the filter metadata that defines the filter and all its potential options.
 *  @param parentNode the NodeDef with the closest parent DO node, if any.
 *  @param activeRunbook the RunbookInfo with the runbook definition.
 *  @param isRequired a boolean value, true if the filter is a required filter.
 *  @param filterName a String with the name of the filter, for example: network_interface, network_device, etc.
 *  @param doNodeId a String with the id of the DO node that is being edited.
 *  @param graphDef the GraphDef object with the graph definition including nodes and edges.
 *  @param variables the dictionary of variables by scope that have been defined.
 *  @param customProperties the array of CustomPropertys that have the custom properties and values for all the 
 *      entity types.
 *  @param exactMatch if true only allow exact matches on filter keys.  If false, allow filter
 *      keys that are compatible but not exact matches.
 *  @returns the list of options for the specified filter. */
export function getOptions(
    filterProps: FilterMetadata, parentNode: NodeDef | undefined, activeRunbook: RunbookInfo | undefined, 
    isRequired: boolean, filterName, doNodeId: string, graphDef: GraphDef,
    variables: VariableContextByScope, customProperties: CustomProperty[], exactMatch: boolean
): FilterMetadataOptionsValue[] {
    let renderOptions = new Array<string>();//filterProps.renderOptionsByRunbookType["default"].slice();

    const optionsValidity = {
        trigger: false, // Filter from trigger
        node: false, // Filter from previous node
        user: false, // User-specified filter value
        variable: false, // Filter from variable
        datasource: (getArDataSources() || []).length > 0 && ENABLE_VANTAGE_POINT,
        alldatasource: (getArDataSources() || []).length > 0 && ENABLE_VANTAGE_POINT,
        optional: true, // The top (optional) option
    }

    // if trigger type for the filter matches the active runbook input type then add the trigger option
    let node: NodeDef | null = null;
    let context: RunbookContext | null = null;
    if (doNodeId && graphDef) {
        node = getNodeFromGraphDef(doNodeId, graphDef);
        if (node) {
            context = new RunbookContext(node, graphDef, DataOceanUtils.dataOceanMetaData, customProperties);
        }
    }

    // If the trigger has the filterName in its keys array
    if (context && context.triggerProvidesFilter(filterName, exactMatch)) {
        optionsValidity.trigger = true;
    }

    // if an ancestor data ocean node object type has the filterName in its keys array
    if (context && context.nodeProvidesFilter(filterName, undefined, exactMatch)) {
        optionsValidity.node = true;
    }

    // If the trigger has the filterName in its keys array
    if (variableProvidesFilter(filterName, variables, exactMatch)) {
        optionsValidity.variable = true;
    }

    // If this node has a connected node in front that outputs data of this type,
    // then show "From Previous Node" as the first and most appropriate option
    if (optionsValidity.node) {
        renderOptions.push("node");
        if (optionsValidity.trigger && optionsValidity.variable) {
            renderOptions.push("trigger", "variable", "user", "datasource", "alldatasource");
        } else if (optionsValidity.trigger) {
            renderOptions.push("trigger", "user", "variable", "datasource", "alldatasource");
        } else {
            renderOptions.push("user", "trigger", "variable", "datasource", "alldatasource");
        }
    // Else, if at least the trigger type matches this filter type, then show
    // the "From Trigger" option as the first and most appropriate option
    } else if (optionsValidity.trigger) {
        renderOptions.push("trigger");
        if (optionsValidity.variable) {
            renderOptions.push("variable", "user", "node", "datasource", "alldatasource");
        } else {
            renderOptions.push("user", "node", "variable", "datasource", "alldatasource");
        }
    // else if a variable matches this filter type, then show the variables option
    // as the first option
    } else if (optionsValidity.variable) {
        renderOptions.push("variable", "user", "trigger", "node", "datasource", "alldatasource");
    // If neither previous node or trigger doesn't match this filter type, then
    // show the "Specific <filtertype>" option as the first one. The other two
    // options will show up as disabled after it.
    } else {
        renderOptions.push("user", "trigger", "node", "variable", "datasource", "alldatasource");
    }

    if (!isRequired) {
        renderOptions = ["optional"].concat(renderOptions);
    }
   
    const options: FilterMetadataOptionsValue[] = [];
    for (const optionKey of renderOptions) {
        if (filterProps.options[optionKey]) {
            if (optionKey === "node") {
                // We need to expand out all the parent nodes that can provide that filter
                const sources: Array<ContextSource> = context?.getFilterNodeSources(filterName, exactMatch) || [];
                for (const source of sources) {
                    switch (CONTEXT_MODE) {
                        case ContextMode.DIRECT_PARENT:
                        case ContextMode.CLOSEST_PARENT:
                            options.push({
                                label: filterProps.options[optionKey]?.label || "", 
                                value: filterProps.options[optionKey]?.value || "", 
                                disabled: !optionsValidity[optionKey]
                            });
                            break;
                        case ContextMode.ANY_PARENT:
                            options.push({
                                label: STRINGS.formatString(filterProps.options[optionKey]?.label || "", source.name), 
                                value: STRINGS.formatString(filterProps.options[optionKey]?.value || "", source.id), 
                                disabled: !optionsValidity[optionKey]
                            });
                            break;        
                    }
                    if (CONTEXT_MODE !== ContextMode.ANY_PARENT) {
                        // We only allow one source when we are supporting either direct parents or closest parents
                        break;
                    }
                }
            } else if (optionKey === "variable") {
                // We need to expand out all the variables that can provide that filter
                const sources: VariableContextSource[] = getFilterVariableSources(filterName, variables, exactMatch) || [];
                for (const source of sources) {
                    options.push({
                        label: STRINGS.formatString(filterProps.options[optionKey]?.label || "", source.name), 
                        value: STRINGS.formatString(filterProps.options[optionKey]?.value || "", source.name), 
                        disabled: !optionsValidity[optionKey]
                    });
                }
            } else {
                options.push({...filterProps.options[optionKey], disabled: !optionsValidity[optionKey]});
            }
        }
    }
    return options;
};

/** Render the text area field with provided properties
 *  @param props the properties that are passed in to the component.
 *  @returns the react node with the text field. */
const renderTextField = (props: {
    filterProps: FilterMetadata, currentProperties: Record<string, any>, optionsForFilterDropDown, 
    isRequired, selectFilterValue, setSelectFilterValue, setShowUserInput, onChange
}): ReactNode => {
    const { filterProps, currentProperties, isRequired, selectFilterValue, onChange } = props;
    return (
        <TextArea
            required={ isRequired }
            onKeyDown={ (e) => {
                if (e.keyCode === 13)
                {
                    //method to prevent from default behaviour
                    e.preventDefault();
                }
            }}
            key={ filterProps.key }
            name={ filterProps.key }
            fill={ true }
            placeholder={filterProps.inputPlaceHolder}
            defaultValue={ selectFilterValue?.name}
            onChange={ e => {
                if(!e.currentTarget.value) {
                    delete currentProperties['filters'][filterProps.key];
                } else {
                    currentProperties['filters'][filterProps.key] = [{name: e.currentTarget.value.trim()}];
                }
                if (onChange) {
                    onChange(currentProperties['filters'][filterProps.key]);
                }
            } }
        />
    )
};

/** Render select field with provided properties
 *  @param props the props that are passed in.
 *  @returns the react node with the select control. */
const renderSelectField = (props: {
    filterProps: FilterMetadata, currentProperties: Record<string, any>, optionsForFilterDropDown, 
    isRequired, selectFilterValue, setSelectFilterValue, setShowUserInput, setShowDataSources, onChange
}): ReactNode => {
    const {
        filterProps, currentProperties, optionsForFilterDropDown, isRequired, selectFilterValue,
        setSelectFilterValue, setShowUserInput, setShowDataSources, onChange
    } = props;
    return (
        <HTMLSelect
            data-testid={ filterProps.key }
            required={ isRequired }
            key={ filterProps.key }
            name={ filterProps.key }
            fill={ true }
            options={ optionsForFilterDropDown }
            value={ selectFilterValue }
            onChange={ e => {
                setSelectFilterValue(e.target.value);
                if (!e.currentTarget.value) {
                    delete currentProperties['filters'][filterProps.key];
                    setShowUserInput(false);
                    setShowDataSources(false);
                } else if (e.currentTarget.value === USER_OPTION_VALUE) {
                    currentProperties['filters'][filterProps.key] = null;
                    setShowUserInput(true);
                    setShowDataSources(false);
                } else if (e.currentTarget.value === DS_OPTION_VALUE) {
                    currentProperties['filters'][filterProps.key] = [];
                    setShowUserInput(false);
                    setShowDataSources(true);
                } else {
                    currentProperties['filters'][filterProps.key] = [e.currentTarget.value];
                    setShowUserInput(false);
                    setShowDataSources(false);
                }
                if (onChange) {
                    onChange(currentProperties['filters'][filterProps.key]);
                }
            } }
        />
    );
};

/** Renders the field based on the type
 *  @param fieldProps the properties passed into the component.
 *  @returns the react node that has the text or select field. */
function renderFieldBasedOnType (fieldProps: {
    filterProps: FilterMetadata, currentProperties: Record<string, any>, optionsForFilterDropDown, 
    isRequired, selectFilterValue, setSelectFilterValue, setShowUserInput, setShowDataSources, onChange
}): ReactNode {
    if (fieldProps.filterProps.fieldType === "select") {
        return renderSelectField(fieldProps);
    } else {
        return renderTextField(fieldProps);
    }
}

/** checks to see if the specified filter key is supported by any of the node contexts. 
 *  @param filterKey a string with the filter key.
 *  @param variables the dictionary of variables by scope that have been defined.
 *  @param exactMatch a boolean value which specifies whether we require an exact match.  If false 
 *      an exact match is not required and the compatible keys are checked. 
 *  @returns a boolean value, true if the one of the node contexts provides the specified filter, false otherwise. */
function variableProvidesFilter(filterKey: string, variables: VariableContextByScope, exactMatch: boolean): boolean {
    if (variables) {
        for (const scope in variables) {
            const varCollection = variables[scope];
            if (varCollection.structuredVariables) {
                for (const variable of varCollection.structuredVariables) {
                    if (variable.type === filterKey || (!exactMatch && RunbookContext.areFiltersCompatible(variable.type, filterKey))) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
}

