/** This module contains the component for rendering the locations card in the impact summary.
 *  The locations card displays the count and list of impacted locations.
 *  @module
 */
import React  from 'react';
import { SIZE } from 'components/enums';
import { IconNames } from '@tir-ui/react-components';
import { SummaryCard, SummaryCardProps } from 'components/common/layout/summary-card/SummaryCard';
import { STRINGS } from 'app-strings';
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams";

/** the properties passed into the React component. */
interface SiteCardProps extends SummaryCardProps {
    /** a number with the count of locations. */
	count?: number;
    /** the string array with the list of locations. */
	data?: string[];
    /** the handler to use for details requests. */
    onDetails?: (icon: string, title: string, subTitle: string, data: string[]) => void;
    /** a boolean value, if true show the footer, if false do not, the default is true. */
    showFooter?: boolean;
}

/** Renders the locations card in the impact summary.
 *  @param props the properties passed in.
 *  @returns JSX with the locations card component.*/
const SitesCard = ({count = 0, data = [], showFooter = true, ...props}: SiteCardProps): JSX.Element => {
	const sites = [...data].sort();
	const handleOnClick = () => {
        if (props.onDetails) {
            props.onDetails(
                IconNames.GLOBE, STRINGS.incidents.impactSummaryView.locations, STRINGS.incidents.impactSummaryView.impactedLocations, data
            );
        }
    };

	return (
		<SummaryCard
			title={
				<span className="text-black">
					{STRINGS.incidents.cards.sites.title}
				</span>
			}
			icon={IconNames.GLOBE}
			size="flex"
			height={SIZE.m}
			className="w-min-3-force"
            hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            hideBorder={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
			footerContent={<>
				{false && <span className="opacity-7 display-10">{STRINGS.showingMockData}</span>}
				{(showFooter && sites.length > 0) && <div className="display-9 float-right text-primary clickable" onClick={handleOnClick}>{STRINGS.viewAll}</div> }
			</>}
		>
			<div className="overflow-hidden h-100 justify-content-center">
				<div className="d-inline-block display-6 font-weight-900 text-black">
					{/* {count} Using length as of now because query data is mocked and so there can be a mismatch of count and list of sites */}
					{sites.length}
				</div>
				<div className="display-9 pl-2 pt-2 text-truncate">{sites.join(", ")}</div>
			</div>
		</SummaryCard>
	);
};

export { SitesCard };
