/** This module contains a react component that displays the info content in the view container
 *  info dialog.
 *  @module
 */
import React, { useCallback, useReducer, useEffect } from "react";
import { Intent, Tree } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { TIME_FORMAT } from "components/enums";
import { PARAM_NAME } from "components/enums/QueryParams";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { formatTimeToUserFriendlyString } from "reporting-infrastructure/utils/formatters/date-formatter/DateFormatter";
import { useQueryParams, useStateSafePromise } from "utils/hooks";
import { ActualTimeInfo, DataSet, DATA_TYPE, ErrorInfo } from "../runbook-view/Runbook.type";
import { clone } from "lodash";
//import DOMPurify from 'dompurify';
import { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import './InfoContent.css';

/** if true purify the content and sanitize it, if false display errors as is. */
//const purifyContent = false;

/** a list of do attributes to show when the debug URL parameter is transmitted (&debug=true). */
const doDebugAttributes: Array<string> = ["errorCode", "SessionId", "ErrorId"];

/** if true always show the data ocean debug information, even if the URL does not have "debug=true". */
const alwaysShowDoDebugAttributes = true;

/** a boolean value, if true show the information as a tree, if false show a subset of the 
 *  information items in a table where we pick off the most important items. */
const showTree = true;

/** defines the NodePath type. */
type NodePath = number[];

/** defines the TreeAction type. */
type TreeAction = { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" } | { type: "INITIALIZE"; payload: Array<any> };

/** executes the specified visit function for the specified set of nodes and their
 *      children.
 *  @param nodes the nodes for which the visit function is to be run.
 *  @param path the path.
 *  @param visitFn the visit function.*/
function forEachNode(nodes: any[] | undefined, visitFn: (node: any) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        visitFn(node);
        forEachNode(node.childNodes, visitFn);
    }
}

/** executes the specified callback for the tree node specified by the path.
 *  @param nodes the nodes in the tree.
 *  @param path the path.
 *  @param callback the callback function.*/
function forNodeAtPath(nodes: any[], path: NodePath, callback: (node: any) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

/** the reducer function.
 *  @param state the current state of the tree.
 *  @param action the current action.
 *  @returns the new state of the tree after taking the specified action.*/
function treeReducer(state: any[], action: TreeAction) {
    switch (action.type) {
        case "INITIALIZE": {
            const newState = clone(action.payload);
            return newState;
        }
        case "DESELECT_ALL": {
            const newState = clone(state);
            forEachNode(newState, node => (node.isSelected = false));
            return newState;
        }
        case "SET_IS_EXPANDED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState;
        }
    }
}

/** This interface defines the properties passed into the runbook view container component.*/
export interface InfoContentProps {
    /** the data set that contains the DataSetInfo object that gives the following information: the actual times, data sources, filters, errors, etc. */
    datasets?: Array<DataSet>;
}

/** Renders the runbook view container.
 *  @param props the properties passed in.
 *  @returns JSX with the runbook view container React component.*/
export const InfoContent = (props: InfoContentProps): JSX.Element => {
    const { datasets } = props;

    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.debug] });
    const showDebugInfo = params[PARAM_NAME.debug] === "true";
        
    const [executeSafely] = useStateSafePromise();
    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(
            (response: DataOceanMetadata) => {
                // When the data ocean has been initialized dispatch an action to update the trie
                dispatch({
                    payload: createTree(datasets, showDebugInfo, response),
                    type: "INITIALIZE",
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }, [executeSafely, datasets, showDebugInfo]);

    // Setup the tree.
    const INITIAL_STATE: Array<any> = []; //createTree(datasets, showDebugInfo, objMetricMetaData!);
    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const info = datasets?.length && datasets[0] ? datasets[0].info : undefined;
    let granularities: Array<string> = getGranularities(datasets?.length && datasets[0] ? datasets[0] : undefined);

    const primaryTime: ActualTimeInfo | undefined = datasets?.length && datasets[0] && datasets![0].info?.actualTimes?.length ? datasets![0].info.actualTimes[0] : undefined;
    let comparisonTime: ActualTimeInfo | undefined = undefined;
    if (datasets?.length === 2 && datasets[1] && datasets[1].isComparison) {
        if (datasets[1].info?.actualTimes?.length) {
            comparisonTime = datasets[1].info.actualTimes[0];
        }
    }

    const errors: Array<JSX.Element> = [];
    const warnings: Array<JSX.Element> = [];
    for (const dataset of datasets!) {
        if (dataset?.info?.error) {
            const details: Record<string, string> = getErrorDetails(dataset.info.error);
            const code: string = dataset.info.error.code || "GENERAL_ERROR";            
            errors.push(<tr>
                <td className="p-1 text-nowrap"><label>{STRINGS.runbookOutput.errorLabel}</label></td>
                <td className="p-1"><label>{STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details)}</label></td>
            </tr>);
        }
        if (dataset?.info?.warning) {
            const details: Record<string, string> = getErrorDetails(dataset.info.warning);
            const code: string = dataset.info.warning.code || "GENERAL_WARNING";
            warnings.push(<tr>
                <td className="p-1 text-nowrap"><label>{STRINGS.runbookOutput.warningLabel}</label></td>
                <td className="p-1"><label>{STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details)}</label></td>
            </tr>);
        }
    }
    return <>
        {showTree && <div style={{ minHeight: "400px", maxHeight: "400px", overflowY: "auto" }}>
            <div className="info-content-content display-8">
                <Tree contents={nodes} onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} />
            </div>
        </div>}
        {!showTree && <table><tbody>
            {primaryTime && primaryTime.startTime && primaryTime.endTime && <tr>
                <td className="p-1 text-nowrap"><label>{STRINGS.runbookOutput.timeLabel}</label></td>
                <td className="p-1">
                    <label>{
                        formatToLocalTimestamp(new Date(parseFloat(primaryTime.startTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) +
                        " - " +
                        formatToLocalTimestamp(new Date(parseFloat(primaryTime.endTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT)
                    }</label>
                </td>
            </tr>}
            {comparisonTime && comparisonTime.startTime && comparisonTime.endTime && <tr>
                <td className="p-1 text-nowrap"><label>{STRINGS.runbookOutput.comparisonTimeLabel}</label></td>
                <td className="p-1">
                    <label>{
                        formatToLocalTimestamp(new Date(parseFloat(comparisonTime.startTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) +
                        " - " +
                        formatToLocalTimestamp(new Date(parseFloat(comparisonTime.endTime) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT)
                    }</label>
                </td>
            </tr>}
            {granularities.length > 0 && <tr>
                <td className="p-1 text-nowrap"><label>{STRINGS.runbookOutput.granularityLabel}</label></td>
                <td className="p-1"><label>{granularities.join(",")}</label></td>
            </tr>}
            {info?.dataSources?.length && info.dataSources.length > 0 && <tr>
                <td className="p-1 text-nowrap"><label>{STRINGS.runbookOutput.dsLabel}</label></td>
                <td className="p-1"><label>{info.dataSources.map((ds, index) => {
                    const name = ds.name ? ds.name : ds.url ? ds.url.substring(ds.url.indexOf("://") + 3, ds.url.indexOf(".")) : ds.type;
                    return <><a href={ds.url} target="_blank" rel="noreferrer">{name}</a>{index < info.dataSources!.length - 1 && <>, </>}</>;
                })}</label></td>
            </tr>}
            {info?.actualFilters && <tr>
                <td className="p-1 text-nowrap align-top"><label>{STRINGS.runbookOutput.filtersLabel}</label></td>
                <td className="p-1"><textarea style={{ width: "400px", height: "200px", maxHeight: "300px", border: "none" }} defaultValue={JSON.stringify(JSON.parse(info.actualFilters), null, 4)} /></td>
            </tr>}
            {errors}
            {warnings}
        </tbody></table>}
        {showDebugInfo && info?.error?.innerError?.trace && getErrorContent(info.error.message, info.error.innerError.trace)}
        {showDebugInfo && info?.warning?.innerError?.trace && getErrorContent(info.warning.message, info.warning.innerError.trace)}
    </>;
}

/** returns the granularities from the actual times.
 *  @param dataset the DataSet whose info object has the actual times with the granularity information.
 @returns an Array of Strings with the list of granularity strings. */
function getGranularities(dataset: DataSet | undefined): Array<string> {
    let granularities: Array<string> = [];
    if (dataset) {
        const info = dataset.info;
        if (info?.actualTimes?.length && info.actualTimes.length > 0 && info.actualTimes[0].granularities) {
            granularities = info.actualTimes[0].granularities.map((gran) => {
                return getGranularityText(gran);
            });
        }
    }
    return granularities;
}

/** returns the granularity summary for the chart header. 
 *  @param granularity a number in minutes or undefined if there is no granularity.
 *  @returns a String with the granularity. */
export function getGranularityText(gran: string | number | undefined): string {
    if (gran !== undefined) {
        let duration = typeof gran === "string" ? parseInt(gran) : gran;
        let key;
        if (duration > 24 * 60 * 60) {
            duration = Math.floor(duration / (24 * 60 * 60));
            key = duration > 1 ? "granularityDays" : "granularityDay";
        } else if (duration > 60 * 60) {
            duration = Math.floor(duration / (60 * 60));
            key = duration > 1 ? "granularityHours" : "granularityHour";
        } else if (duration > 60) {
            duration = Math.floor(duration / (60));
            key = duration > 1 ? "granularityMinutes" : "granularityMinute";
        } else {
            key = duration > 1 ? "granularitySeconds" : "granularitySecond";
        }
        return STRINGS.formatString(STRINGS.runbookOutput[key], duration.toString());    
    }
    return "";
}

/** adds a filter object to the tree.
 *  @param filterObj the filter object to add.
 *  @param parentNode the parent node that the filter will be attached to.
 *  @param fKeyPath an array with all the filters keys up to this point. 
 *  @param DoMetaData the DataOceanMetadata with the objects, metrics and keys for the data ocean. */
function addFilterObjectToTree(filterObj: Record<string, string>, parentNode: any, fKeyPath: Array<string>, DoMetaData: DataOceanMetadata): void {
    const filterChildren: Array<any> = [];
    for (const filterKey in filterObj) {
        const filter = filterObj[filterKey];
        const filterKeyName = getFilterKeyName(filterKey, fKeyPath.concat(filterKey), DoMetaData);
        const node = {
            id: "fobj-" + parentNode.id + "-" + filterKey, key: "fobj-" + parentNode.id + "-" + filterKey,
            depth: parentNode.depth + 1, path: parentNode.path.concat(filterChildren.length),
            label: <span>{filterKeyName}</span>
        }
        if (typeof filter === "string" || (typeof filter === "object" && filter === null)) {
            node.label = <span>{filterKeyName}: {String(filter)}</span>;
        } else if (Array.isArray(filter)) {
            if ((filter as Array<any>).length === 1) {
                // If there is only one item in the array do not show the array in the tree
                addFilterObjectToTree(filter[0], node, fKeyPath.concat(filterKey), DoMetaData);
            } else {
                addFilterArrayToTree(filter, node, fKeyPath.concat(filterKey), DoMetaData);
            }
        } else if (typeof filter === "object") {
            addFilterObjectToTree(filter, node, fKeyPath.concat(filterKey), DoMetaData);
        }
        filterChildren.push(node);
    }
    parentNode.childNodes = filterChildren;
}

/** adds a filter array to the tree.
 *  @param filterArr the filter array to add.
 *  @param parentNode the parent node that the filter will be attached to.
 *  @param fKeyPath an array with all the filters keys up to this point. 
 *  @param DoMetaData the DataOceanMetadata with the objects, metrics and keys for the data ocean. */
function addFilterArrayToTree(filterArr: Array<any>, parentNode: any, fKeyPath: Array<string>, DoMetaData: DataOceanMetadata): void {
    const filterChildren: Array<any> = [];
    for (let filterIndex = 0; filterIndex < filterArr.length; filterIndex++) {
        const filter = filterArr[filterIndex];
        const node = {
            id: "farr-" + parentNode.id + "-[" + filterIndex + "]", key: "farr-" + parentNode.id + "-[" + filterIndex + "]",
            depth: parentNode.depth + 1, path: parentNode.path.concat(filterChildren.length),
            label: <span>[{filterIndex}]</span>
        }
        if (typeof filter === "string") {
            node.label = <span>[{filterIndex}]: {filter}</span>;
        } else if (Array.isArray(filter)) {
            if (filter.length === 1) {
                // If there is only one item in the array do not show the array in the tree
                addFilterObjectToTree(filter[0], node, fKeyPath, DoMetaData);
            } else {
                addFilterArrayToTree(filter, node, fKeyPath, DoMetaData);
            }
        } else if (typeof filter === "object") {
            addFilterObjectToTree(filter, node, fKeyPath, DoMetaData);
        }
        filterChildren.push(node);
    }
    parentNode.childNodes = filterChildren;
}

/** Searches for a specified key in the do meta data.
 *  @param filterKey a string with the filter key.
 *  @param fKeyPath an array with the nested filter keys.
 *  @param DoMetaData the DataOceanMetadata with the objects, metrics and keys for the data ocean. 
 *  @returns the name of the filter key in the do metadata and if not found it returns the specified key. */
function getFilterKeyName(filterKey: string, fKeyPath: Array<string>, DoMetaData: DataOceanMetadata): string {
    let doMetaDataKeyObj: any = null;
    for (let index = 0; index < fKeyPath.length; index++) {
        if (index === 0) {
            doMetaDataKeyObj = DoMetaData.keys[fKeyPath[index]];
        } else {
            doMetaDataKeyObj = doMetaDataKeyObj.properties[fKeyPath[index]];
        }
        if (!doMetaDataKeyObj) {
            break;
        }
    }
    return doMetaDataKeyObj?.label ? doMetaDataKeyObj?.label : filterKey;
}

/** returns the k/v pairs with the error details. 
 *  @param error the ErrorInfo object with the error information.
 *  @returns a map of the error details values to their keys.  The keys are referred to in the 
 *      error messages in the STRINGS file. */
function getErrorDetails(error: ErrorInfo): Record<string, string> {
    const details: Record<string, string> = {};
    if (error?.innerError?.properties?.length) {
        for (const prop of error.innerError.properties) {
            // Replace any "." in the key with an __.  The localization API does not support
            // nested objects.
            details[prop.key.replace(/\./g, "__")] = prop.value;
        }
    }
    return details;
}

/** returns the content that should be displayed when a runbook fails.
 *  @param errorMsg a string with the error message from the back-end.
 *  @param stackTrack a string with the stack trace information, if any.
 *  @returns the JSX with the content that should be displayed. */
function getErrorContent(errorMsg: string | undefined, stackTrace: Array<string>): JSX.Element {
    return <>
        <br />
        {errorMsg && errorMsg.length > 0 && <div className="display-8 mt-2">
            {STRINGS.formatString(STRINGS.viewRunbooks.error.errorText, errorMsg)}
        </div>}
        {stackTrace && stackTrace.length > 0 && formatStackTrace(stackTrace)}
    </>;
}

/** formats the stack trace.
 *  @param stackTrack a string with the stack trace information, if any.
 *  @returns the JSX with the stack trace information. */
function formatStackTrace(stackTrace: Array<string>): JSX.Element {
    const traceLines: Array<JSX.Element> = [];
    let lineIndex = 1;
    for (const line of stackTrace) {
        if (!line) {
            continue;
        }
        const subLines = line.split("\n");
        for (const subLine of subLines) {
            const margin = getMargin(subLine);
            const settings = margin > 0 ? { className: "ml-" + margin } : {};
            traceLines.push(<li {...settings} key={"line" + lineIndex++}>{subLine}</li>)
        }
    }

    return <div className="display-8 mt-2">
        {STRINGS.viewRunbooks.error.stackTraceText}
        <ul className="no-bulltes mt-2 stacktrace-container">
            {traceLines}
        </ul>
    </div>;
}

/** returns the margin for the stacktrace lines.
 *  @param line the String with the text for the line.
 *  @returns the margin size for the bootstrap "mr-" css value or 0 if there is no margin. */
function getMargin(line: string): number {
    if (line && line.startsWith(" ")) {
        let count = 0;
        while (count < line.length && line.charAt(count) === ' ') {
            count++;
        }
        return Math.min(5, count);
    }
    return 0;
}

/** creates the info tree from the datasets.
 *  @param datasets the Array of DataSets.
 *  @param showDebugInfo a boolean which specifies whether debug information should be displayed.
 *  @param DoMetaData the DataOceanMetadata with the objects, metrics and keys for the data ocean. 
 *  @returns an Array with the root tree nodes. */
function createTree(datasets: Array<DataSet> | undefined, showDebugInfo: boolean, DoMetaData: DataOceanMetadata): Array<any> {
    const INITIAL_STATE: Array<any> = [];
    if (datasets) {
        for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
            const dataset = datasets[datasetIndex];
            if (dataset && dataset.info) {
                let children: Array<any> = [];
                const info = dataset.info;
                if (info.actualTimes?.length) {
                    const actualTime = info.actualTimes[0];
                    const timeInterval = formatTimeToUserFriendlyString({ startTime: new Date(parseFloat(actualTime.startTime) * 1000).getTime(), endTime: new Date(parseFloat(actualTime.endTime) * 1000).getTime() });
                    children.push({
                        id: "ti-" + datasetIndex, key: "ti-" + datasetIndex, depth: 1, path: [datasetIndex, 0],
                        label: <span>{STRINGS.runbookOutput.timeLabel} {timeInterval}</span>
                    });
                    if (actualTime.granularities) {
                        let granularities: Array<string> = getGranularities(datasets[datasetIndex]);
                        if (granularities?.length) {
                            children.push({
                                id: "gran-" + datasetIndex, key: "gran-" + datasetIndex, depth: 1, path: [datasetIndex, children.length],
                                label: <span>{STRINGS.runbookOutput.granularityLabel} {granularities.join(",")}</span>
                            });
                        }
                    }
                }
                if (info.dataSources?.length && info.dataSources[0].type && info.dataSources[0].url) {
                    children.push({
                        id: "ds-" + datasetIndex, key: "ds-" + datasetIndex, depth: 1, path: [datasetIndex, children.length],
                        className: "info-warning-error-label", label: <div className="d-flex icon-and-label-div">
                            <span>{STRINGS.runbookOutput.dsLabel} {info.dataSources.map((ds, index) => {
                            const name = ds.name ? ds.name : ds.url ? ds.url.substring(ds.url.indexOf("://") + 3, ds.url.indexOf(".")) : ds.type;
                            return <><a href={ds.url} target="_blank" rel="noreferrer">{name}</a>{index < info.dataSources!.length - 1 && <>, </>}</>;
                        })}</span></div>
                    });
                }
                if (info.actualFilters) {
                    const filters = JSON.parse(info.actualFilters);
                    if (filters) {
                        const node = {
                            id: "fi-" + datasetIndex, key: "fi-" + datasetIndex, depth: 1, path: [datasetIndex, children.length],
                            label: <span>{STRINGS.runbookOutput.filtersLabel}</span>,
                            isExpanded: datasetIndex === 0, childNodes: []
                        };
                        addFilterObjectToTree(filters, node, [], DoMetaData);
                        children.push(node);
                    }
                }
                if (info.error) {
                    const details: Record<string, string> = getErrorDetails(info.error);
                    const code: string = info.error.code && STRINGS.runbookOutput.errorsAndWarnings[info.error.code] ? 
                        info.error.code : "GENERAL_ERROR";
                    if (code === "GENERAL_ERROR" && info.error.code) {
                        details.errorCode = info.error.code;
                    }

                    let errorText = STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details);
                    if ((showDebugInfo || alwaysShowDoDebugAttributes) && doDebugAttributes?.length) {
                        // If debug is true then collect up any attributes that the DO team has said will help with 
                        // debugging and show them to the user
                        const attrs: Array<string> = [];
                        for (const attribute of doDebugAttributes) {
                            if (details[attribute]) {
                                attrs.push(attribute + "=" + details[attribute]);
                            }
                        }
                        if (attrs.length) {
                            errorText += " (" + attrs.join(", ") + ")";
                        }
                    }
                    // This caused the compile to get an out of memory exception, this is not needed right now, but at
                    // some point I would like to figure out what caused that.
                    //if (purifyContent) {
                        // If purify content is turned on then sanitize the error string.  This causes a problem for 
                        // URLs in the error by removing the target attribute.
                        //errorText = DOMPurify.sanitize(errorText);
                    //}

                    if (code === "MultipleErrors") {
                        errorText = "<div style='overflow: auto'>" + STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details) + "<br /><ol>"
                        info.error.details?.forEach((item, key) => {
                            errorText += "<li key=" + {key} + ">" + item.message + "</li>";
                        })
                        errorText += "</ol><div>";
                    }

                    const errorComp = <div className="d-flex icon-and-label-div">
                        <Icon icon={IconNames.ERROR} className="mr-2" intent={Intent.DANGER}/>
                        <span dangerouslySetInnerHTML={{__html: errorText}}/>
                    </div>;

                    children.push({
                        id: "error-" + datasetIndex, key: "error-" + datasetIndex, depth: 1, path: [datasetIndex, children.length],
                        className: "info-warning-error-label",
                        label: errorComp
                    });
                }
                if (info.warning) {
                    const details: Record<string, string> = getErrorDetails(info.warning);
                    const code: string = info.warning.code && STRINGS.runbookOutput.errorsAndWarnings[info.warning.code] ? 
                        info.warning.code : "GENERAL_WARNING";
                    if (code === "GENERAL_WARNING" && info.warning.code) {
                        details.errorCode = info.warning.code;
                    }
                    
                    let errorText = STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details);
                    if ((showDebugInfo || alwaysShowDoDebugAttributes) && doDebugAttributes?.length) {
                        // If debug is true then collect up any attributes that the DO team has said will help with 
                        // debugging and show them to the user
                        const attrs: Array<string> = [];
                        for (const attribute of doDebugAttributes) {
                            if (details[attribute]) {
                                attrs.push(attribute + "=" + details[attribute]);
                            }
                        }
                        if (attrs.length) {
                            errorText += " (" + attrs.join(", ") + ")";
                        }
                    }
                    // This caused the compile to get an out of memory exception, this is not needed right now, but at
                    // some point I would like to figure out what caused that.
                    //if (purifyContent) {
                        // If purify content is turned on then sanitize the error string.  This causes a problem for 
                        // URLs in the error by removing the target attribute.
                        //errorText = DOMPurify.sanitize(errorText);
                    //}

                    if (code === "MultipleWarnings") {
                        errorText = "<div style='overflow: auto'>" + STRINGS.formatString(STRINGS.runbookOutput.errorsAndWarnings[code], details) + "<br /><ol>"
                        info.warning.details?.forEach((item, key) => {
                            errorText += "<li key=" + {key} + ">" + item.message + "</li>";
                        })
                        errorText += "</ol><div>";
                    }

                    const warningComp = <div className="d-flex icon-and-label-div">
                        <Icon icon={IconNames.WARNING_SIGN} className="mr-2" intent={Intent.WARNING}/>
                        <span dangerouslySetInnerHTML={{__html: errorText}}/>
                    </div>;

                    children.push({
                        id: "warning-" + datasetIndex, key: "warning-" + datasetIndex, depth: 1, path: [datasetIndex, children.length],
                        className: "info-warning-error-label",
                        label: warningComp
                    });
                }
                let queryNameKey = (dataset.type === DATA_TYPE.SUMMARY ? "summary" : "ts") + (dataset.isComparison ? "Comparison" : "") + "QueryText";
                INITIAL_STATE.push({
                    id: "Dataset" + datasetIndex, key: "Dataset" + datasetIndex, label: STRINGS.runbookOutput[queryNameKey],
                    isExpanded: datasetIndex === 0, childNodes: children
                });
            }
        }
    }
    return INITIAL_STATE;
}
