/** This file defines the incident distribution view react component.  The 
 *  incident distribution view react component displays the distribution of
 *  incidents in a Sankey chart.
 *  @module */
import React, {useContext, useEffect, useRef} from "react";
import { Query } from "reporting-infrastructure/data-hub";
import { FILTER_NAME, useQuery } from "utils/hooks";
import { loader } from "graphql.macro";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { SankeyChart, SankeyChartData } from "components/reporting";
import { STRINGS } from "app-strings";
import { INCIDENT_STATUS, INCIDENT_STATUS_TO_LABEL_MAP, PRIORITY, PRIORITY_COLORS, PRIORITY_TO_LABEL_MAP } from "components/enums";
import { sortBasedOnIncidentStatus, sortBasedOnPriority } from "reporting-infrastructure/utils/commonUtils";
import { AutoUpdateContext } from "pages/network-summary/NetworkSummaryPage";
import { GroupMetricEvent, GroupMetricSource } from "components/common/chart-base/ChartBase";

export interface IncidentDistributionViewProps {
    /** the handler for the click event. */
    onGroupMetricSelection?: (event: GroupMetricEvent) => void; 
}

/** Renders the incident distribution sankey chart react component.
 *  @param props the properties passed in.
 *  @returns JSX with the incident distribution sankey chart component.*/
export function IncidentDistributionView(props: IncidentDistributionViewProps): JSX.Element {
    const autoUpdate = useContext(AutoUpdateContext);
    
    const { loading, data, error, run } = useQuery({
        query: new Query(loader("./incident-distribution.graphql")),
        name: "distribution",
        timeNotRequired: true,
        consumedFilters: [FILTER_NAME.priority, FILTER_NAME.completionStatus],
    });

    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current= autoUpdate.sequenceNumber;
                run({ noCache: true });
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    const sankeyChartData = useRef<SankeyChartData>([]);
    const sankeyChartNodes = useRef<any>([]);
    const priorityTotals = {};
    const stateTotals = {};

    if (sankeyChartNodes.current.length === 0) {
        const priorityNodes:any = [];
        for (const priority of Object.keys(PRIORITY)) {
            const priorityLabel = PRIORITY_TO_LABEL_MAP[priority] || priority;
            const priorityColor = PRIORITY_COLORS[priority] || PRIORITY_COLORS.UNKNOWN;
            priorityNodes.push({
                id: priority,
                color: priorityColor,
                name: priorityLabel,
            });
        }
        const statusNodes:any = [];
        const statusColors = ["#03a9f4", "#2196f3"];
        let statusColorIndex = -1;
        for (const status of Object.keys(INCIDENT_STATUS)) {
            statusColorIndex++;
            if (statusColorIndex >= statusColors.length) {
                statusColorIndex = 0;
            }
            const statusLabel = INCIDENT_STATUS_TO_LABEL_MAP[status] || status;
            if (stateTotals[status] === undefined) {
                stateTotals[status] = 0;
                statusNodes.push({
                    id: status,
                    color: statusColors[statusColorIndex],
                    name: statusLabel,
                });
            }
        }
        sankeyChartNodes.current = [...priorityNodes, ...statusNodes];
    }

    if (!loading && data?.summary?.incidentsPriority) {
        sankeyChartData.current = [];
        const incidentsPriority = [...data.summary.incidentsPriority];
        const pathCombinationsMap = {};
        for (const subQueryData of incidentsPriority) {
            const priority = subQueryData.priority;
            priorityTotals[priority] = subQueryData.count;
            const priorityColor = PRIORITY_COLORS[priority] || PRIORITY_COLORS.UNKNOWN;
            for (const statusPerPriorityData of subQueryData.status) {
                if (statusPerPriorityData.count > 0) {
                    const status = statusPerPriorityData.status;
                    stateTotals[status] += statusPerPriorityData.count;
                    const pathID = priority + "-" + status;
                    sankeyChartData.current.push({
                        id: pathID,
                        from: priority,
                        to: status,
                        weight: statusPerPriorityData.count,
                        color: priorityColor,
                    });
                    pathCombinationsMap[pathID] = true;
                }
            }
        }
        sankeyChartData.current.sort((a, b) => sortBasedOnPriority({ priority: b.from }, { priority: a.from }));
        sankeyChartData.current.sort((a, b) => sortBasedOnIncidentStatus({ status: b.to }, { status: a.to }));
    }
    
    function onNodeClicked (payload) {
        if (props.onGroupMetricSelection) {
            const nodeType = PRIORITY[payload.id] ? "priority" : (INCIDENT_STATUS[payload.id] ? "status": "unknown");
            props.onGroupMetricSelection({
                source: GroupMetricSource.LEGEND,
                selected: true,
                groups: [nodeType],
                metrics: [payload.id],
            });
        }
    }

    function onPathClicked (payload) {
        if (props.onGroupMetricSelection) {
            props.onGroupMetricSelection({
                source: GroupMetricSource.SERIES,
                selected: true,
                groups: [payload.from],
                metrics: [payload.to],
            });
        } 
    }
    const incidentDistributionChartKey = useRef("sankey");
    // We want the sankey chart to re-mount when a priority or state gets added or removed. But when only the counts change, we want it to update.
    // To handle that, we're passing a key which will change in the former. If we don't do this, highcharts sankey chart has a hard time reconciling
    // the information and starts showing outdated data
    const prioritiesCount = Object.keys(priorityTotals).length;
    const statesCount = Object.keys(stateTotals).length;
    if (prioritiesCount > 0 && statesCount > 0) {
        incidentDistributionChartKey.current = "sankey-" + Object.keys(priorityTotals).length + "-" + Object.keys(stateTotals).length;
    }
    const dataAvailable = sankeyChartData.current.length > 0;
    return <DataLoadFacade loading={loading} error={error} data={data} showContentsWhenLoading={dataAvailable} transparent={dataAvailable}>
        {
            dataAvailable ?
            <>
                <SankeyChart
                    key={incidentDistributionChartKey.current}
                    chartData={sankeyChartData.current}
                    onNodeClicked={onNodeClicked}
                    onPathClicked={onPathClicked}
                    height="260px" 
                    options={{
                        chart: { margin: [0, 95, 0, 75] },
                        series: [{
                            nodeWidth: 70,
                            nodes: sankeyChartNodes.current,
                            dataLabels: {
                                style: {
                                    fontFamily: "open sans",
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                },
                                useHTML: true,
                                nodeFormatter: function (node) {
                                    const that:any = this;
                                    return `<div class="position-relative w-0-5 text-center text-black" style="height: 5px;">
                                        ${that.point.linksFrom.length === 0 ? 
                                            '<div class="position-absolute text-left text-truncate clickable" style="right: -100px; width: 85px; top: 0;">' + that.point.name + '</div>':
                                            '<div class="position-absolute text-right text-truncate clickable" style="left: -100px; width: 85px; top: 0;">' + that.point.name + '</div>'
                                        }
                                        ${that.point.sum}
                                    </div>`;
                                }
                            }
                        }],
                        tooltip: {
                            formatter: function (this: any) {
                                if (this.point) {
                                    if (this.point.from && this.point.to && this.point.weight) {
                                        return this.point.weight + " out of " + this.point.fromNode.sum + " (" + Math.ceil((this.point.weight * 100)/this.point.fromNode.sum) + "%)";
                                    } else {
                                        return this.point.name || this.point.id || false;
                                    }
                                } else {
                                    return false;
                                }
                            }
                        }
                    } as any}
                    settings={{fromLabel: STRINGS.incidentPriorities.label, toLabel: STRINGS.incidentStatus.label, showMore: true}}
                    enableFullScreen={true}
                    fullScreenTitle={STRINGS.networkDashboard.incidentDistribution}
                />
            </>:
            <div className="absolute-center">{STRINGS.no_data_to_display}</div>
        }
    </DataLoadFacade>;
};
   