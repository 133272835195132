/** This module contains the implementation for the runbook template view.  The runbook template
 *  view displays the runbook configuration graph that is uneditable and has all the controls 
 *  stripped off.
 *  @module
 */
import React, { useState, useEffect, useRef } from "react";
import { useStateSafePromise } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { GraphDef, Variant } from "components/common/graph/types/GraphTypes";
import { IconTitle } from "components/common/icon-title/IconTitle";
import { SIZE } from "components/enums";
import { SDWAN_ICONS } from "components/sdwan/enums";
import { createSubflowNodes, getGraphDefFromRunbookConfig } from "pages/create-runbook/views/create-runbook/CreateRunbookView";
import { RunbookNode } from "utils/services/RunbookApiService";
import { runbookService } from 'utils/runbooks/RunbookUtils';
import IncidentRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/node_library.json';
import LifecycleRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/lifecycle_node_library.json';
import SubflowRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/subflow_node_library.json';
import OnDemandRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/on_demand_node_library.json';
import ExternalRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library_external.json";
import { NodeLibrary, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { RunbookIntegrationDetails } from "pages/integrations/types/IntegrationTypes";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import { RunbookPathTraversal } from "pages/incident-details/views/runbook-path-traversal/RunbookPathTraversal";
import { RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import "./RunbookTemplateView.scss";

/** an interface that describes the properties that can be passed in to the component.*/
export interface runbookTemplateViewProps {
    /** Flow template to be displayed */
    template?: any;
    /** the runbook output to be displayed in the node traversal view. */
    output?: RunbookOutput;
    /** the runbook variant, incident or lifecycle. */
    variant: Variant
}

/** this is the node library to use when evaluating subflows. */
const subflowNodeLibrary = new NodeLibrary(
	SubflowRunbookNodeLibrary as NodeLibrarySpec
);

/** Creates the runbook template view, which is a React component that displays the runbook configuration
 *      graph with all the controls stripped off of the view and panning and zooming disabled.
 *  @param props an object with the properties passed to the runbook template view.
 *  @returns JSX with the runbook template view React component.*/
 export function RunbookTemplateView (props: runbookTemplateViewProps): JSX.Element {
    const [subflows, setNodeRedSubflows] = useState<Array<RunbookNode>>([]);
    const graphDef = useRef<GraphDef | null>(null);
    // Commmented out when we switched to showing the node traversal
    //const history = useHistory();

	let InitNodeLibrary: NodeLibrarySpec;
    switch (props.variant) {
        case Variant.INCIDENT:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.LIFECYCLE:
            InitNodeLibrary = LifecycleRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.SUBFLOW:
            InitNodeLibrary = SubflowRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.ON_DEMAND:
            InitNodeLibrary = OnDemandRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.EXTERNAL:
            InitNodeLibrary = ExternalRunbookNodeLibrary as NodeLibrarySpec;
            break;
        default:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
    }
	const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(InitNodeLibrary as NodeLibrarySpec));

    const rawSubflows = useRef<RunbookNode[]>([]);
	useEffect(
		() => {
			async function fetchMyAPI() {
				let newSubflows: Array<RunbookNode> = [];
				try {
					const retFlows = await runbookService.getRunbooks(Variant.SUBFLOW, true);
					if (retFlows) {
						for (const flow of retFlows) {
							newSubflows.push(flow);
						}
                        rawSubflows.current = retFlows;
					}
				} catch (error) {
					console.log(error);
				}
				newSubflows = createSubflowNodes(newSubflows, subflowNodeLibrary, integrationsCache.current);
                graphDef.current = props.template ? getGraphDefFromRunbookConfig(nodeLibrary.current, props.template, newSubflows, integrationsCache?.current) : null;
                setNodeRedSubflows(newSubflows);
			}
			fetchMyAPI();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

    /** Retrieve the integrations list. */
	const [executeSafely] = useStateSafePromise();
	const [integrations, setIntegrations] = useState<RunbookIntegrationDetails[] | undefined>(undefined);
	const integrationsCache = useRef<RunbookIntegrationDetails[] | undefined>();
	useEffect(() => {
		const integrationsPromise = new Promise<RunbookIntegrationDetails[]>(async (resolve, reject) => {
			try {
				const newIntegrations = await IntegrationLibraryService.getRunbookIntegrations();
                resolve(newIntegrations as RunbookIntegrationDetails[]);    
			} catch (error) {
				reject(error);
			}
		});
		executeSafely(integrationsPromise).then(
			(integrations) => {
				integrationsCache.current = integrations;
				setIntegrations(integrations);
                if (subflows.length) {
                    let newSubflows: Array<RunbookNode> = createSubflowNodes(rawSubflows.current, subflowNodeLibrary, integrationsCache.current);
                    graphDef.current = props.template ? getGraphDefFromRunbookConfig(nodeLibrary.current, props.template, newSubflows, integrationsCache?.current) : null;
                }
			}, 
			() => {
				integrationsCache.current = integrations;
				setIntegrations([]);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return (props.template ? <div className="runbook-template">
        <div className="d-flex justify-content-between">
            <IconTitle icon={SDWAN_ICONS.RUNBOOK}
                title={STRINGS.runbooks.runbook + ": " + props.template.name}
                size={SIZE.m} showIconWithoutBg={true} className="mr-2"/>
            {/*<Button icon={<Icon icon={APP_ICONS.EDIT}/>} text={STRINGS.runbooks.edit} minimal
                onClick={() => {
                    history.push(getURL(
                        getURLPath("create-runbook"),
                        {   [PARAM_NAME.rbConfigId]: props.template.id,
                            [PARAM_NAME.rbConfigNm]: props.template.name,
                            [PARAM_NAME.variant]: props.variant
                        },
                        { replaceQueryParams: true}
                    ));
                }}
            />*/}
        </div>
        {
            graphDef.current && <div className="template-container border bg-light mt-3">
                {/* commented out when we switched to showing the node traversal
                <ReactFlowProvider>
                    <ReactFlowGraph 
                        nodeLibrary={nodeLibrary.current} 
                        graphDef={graphDef.current} 
                        subflows={subflows} 
                        static={true} 
                        showToolbar={false}
                        fitContainerHeightToContent 
                        centerOnRender 
                        integrations={integrationsCache.current}
                        variant={props.variant}
                    />
                </ReactFlowProvider>*/}
                <RunbookPathTraversal runbook={props.template} runbookOutput={props.output} />
            </div>
        }
    </div> : <></>);
}
