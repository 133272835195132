import React from 'react';
import classNames from 'classnames';
import { IconNames } from '@blueprintjs/icons';
import { Classes, Divider, Label, Icon } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';

export interface IReviewPanelProps {
    gatewayName: string;
    gatewayType: string;
    staticIpConfigEnabled: boolean;
    staticCidr: string;
    staticGatewayIp: string;
    staticIpNameservers: React.ReactNode[];
    proxyEnabled: boolean;
    websocketsEnabled: boolean;
    proxyHostname: string;
    proxyProtocol: string;
    proxyPort: string;
    proxyAuthEnabled: boolean;
    proxyUsername: string;
    proxyPassword: string;
}

// Mask password function
const mask = (passwordString, reveal = 3, maskingChar = '*') => (passwordString).slice(0, -reveal).replace(/./g, maskingChar) + (passwordString).slice(-reveal);

const ReviewPanel: React.FC<IReviewPanelProps> = (props) => {
    return (
        <div className={classNames(Classes.DIALOG_BODY)} style={{overflowY: "auto", overflowX: "hidden"  }}>
            <p>
                <b>
                    {
                        STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels.review
                            .title
                    }
                </b>
            </p>
            <Divider />
            <br />
            <div>
                <div className="row">
                    <Label className="font-weight-bold col-md-4">
                        {
                            STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
                                .review.fields.gatewayName
                        }
                        :
                    </Label>
                    <Label className="col-md-8">
                        {props.gatewayName ? props.gatewayName : ''}
                    </Label>
                </div>
                <div className="row">
                    <Label className="font-weight-bold col-md-4">
                        {
                            STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
                                .review.fields.gatewayType
                        }
                        :
                    </Label>
                    <Label className="col-md-8">
                        {props.gatewayType ? props.gatewayType : ''}
                    </Label>
                </div>
                {props.staticIpConfigEnabled ? (
                    <React.Fragment>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.staticIpCidr
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.staticCidr}
                            </Label>
                        </div>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.staticIpGateway
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.staticGatewayIp}
                            </Label>
                        </div>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields
                                        .staticIpNameservers
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.staticIpNameservers.join(', ')}
                            </Label>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.ipConfig
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.ipConfig.fields.useDhcp
                                }
                            </Label>
                        </div>
                    </React.Fragment>
                )}
                {props.proxyEnabled && (
                    <React.Fragment>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.proxyEnabled
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.proxyEnabled ? (
                                    <Icon icon={IconNames.TICK} />
                                ) : (
                                    <Icon icon={IconNames.SMALL_CROSS} />
                                )}
                            </Label>
                        </div>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.proxyHostname
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.proxyHostname ? props.proxyHostname : ''}
                            </Label>
                        </div>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.proxyProtoPort
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.proxyProtocol && props.proxyPort
                                    ? props.proxyProtocol +
                                    '&' +
                                    props.proxyPort
                                    : ''}
                            </Label>
                        </div>

                    </React.Fragment>
                )}
                {props.proxyEnabled && props.proxyAuthEnabled && (
                    <React.Fragment>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.proxyUsername
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.proxyUsername ? props.proxyUsername : ''}
                            </Label>
                        </div>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.proxyPassword
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.proxyPassword ? mask(props.proxyPassword) : ''}
                            </Label>
                        </div>
                    </React.Fragment>
                )}
                {props.websocketsEnabled && (
                    <React.Fragment>
                        <div className="row">
                            <Label className="font-weight-bold col-md-4">
                                {
                                    STRINGS.DATA_SOURCES.addEdgeGatewayDialog
                                        .panels.review.fields.websocketsEnabled
                                }
                                :
                            </Label>
                            <Label className="col-md-8">
                                {props.websocketsEnabled ? (
                                    <Icon icon={IconNames.TICK} />
                                ) : (
                                    <Icon icon={IconNames.SMALL_CROSS} />
                                )}
                            </Label>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export { ReviewPanel };
