import React, { useState } from "react";
import { RunbookInputsForm, InputConfig } from "../../../runbook-invocations/views/runbook-invocations-list/RunbookInputsForm";
import { RunbookInputs, INVOCATION_TYPE } from "utils/services/RunbookApiService";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { validIP } from "utils/validators/Validators";
import { STRINGS } from "app-strings";

export const ScheduledRunbookInputs = ({ runbookId, onJsonInputChange, currentInputs, editInfos }) => {
    const [hasInvalidBoolean, setHasInvalidBoolean] = useState<boolean>(false);
    const [hasInvalidIpAddress, setHasInvalidIpAddress] = useState<boolean>(false);

    return (
        <div className={classNames({
            [Classes.DIALOG_BODY]: true,
            "overflow-scroll": true
        })}>
            <RunbookInputsForm
                allowRunbookSelection={true}
                invocationType={INVOCATION_TYPE.onDemand}
                selectedRunbook={runbookId}
                runOnDemandRunbook={true}
                isScheduledRunbook={true}
                scheduleName={editInfos?.details.name}
                onRunbookInputChange={(inputs: RunbookInputs | undefined, inputConfigObj: InputConfig) => {
                    const jsonInput: any = inputs;
                    if (jsonInput) {
                        jsonInput.primarySearchItem = inputConfigObj?.primarySearchItem;
                        jsonInput.secondarySearchItem = inputConfigObj?.secondarySearchItem;
                    }
                    currentInputs.current = jsonInput;
                    onJsonInputChange(jsonInput);
                    if (jsonInput?.variableOverrides && jsonInput?.variableTypes) {
                        let hasInvalidBoolean = false;
                        let hasInvalidIpAddress = false;
                        for (const [key, value] of Object.entries(jsonInput.variableOverrides)) {
                            if (jsonInput.variableTypes[key] === "boolean" && value && !["true", "false"].includes(value as string)) {
                                hasInvalidBoolean = true;
                                setHasInvalidBoolean(true);
                            } else if (jsonInput.variableTypes[key] === "ipaddr" && value && !validIP(value as string)) {
                                hasInvalidIpAddress = true;
                                setHasInvalidIpAddress(true);
                            }
                        }
                        if (!hasInvalidBoolean) {
                            setHasInvalidBoolean(false);
                        }
                        if (!hasInvalidIpAddress) {
                            setHasInvalidIpAddress(false);
                        }
                    }
                }}
                currentInputs={currentInputs.current}
                editInfos={editInfos}
            />
            {hasInvalidBoolean && <p className="text-danger">{STRINGS.scheduleRunbook.wizard.panelSpecify.errorInvalidBooleans}</p>}
            {hasInvalidIpAddress && <p className="text-danger">{STRINGS.scheduleRunbook.wizard.panelSpecify.errorInvalidIpAddresses}</p>}
        </div>
    );
}
