/** This file defines the Unit class.  The Unit class should be the sole object
 *      used to represent a unit.
 *  @module */
import { STRINGS } from "app-strings";

/** this type defines the result of a scaling operation. */
export type ScaleResult = {
    /** the scale factor.  You can divide the value by this to get the updated value. */
    scale: number;
    /** the new unit with the prefix that was used. */
    unit: Unit;
}

/** this class encapsulates the functionality for Units. */
export class Unit {
    /** the list of unit prefixes from:
     *  https://www.nist.gov/pml/weights-and-measures/metric-si-prefixes*/
    public static UNIT_PREFIXES = ["", "k", "M", "G", "T", "P", "E"];
    static TIME_PREFIX = {
        "": 1,
        "m": Math.pow(1000, -1),
        "u": Math.pow(1000000, -1)
        // add more prefixes as needed in the future. Also make sure you add it to the scaledUnitOptions object
        // "n" : Math.pow(1000, -3)
    }

    /** an array with the base units.  If you want the parser to work properly add any new base units here. */
    public static BASE_UNITS = ["bps", "bytes", "B", "pps", "cps", "%", "pct", "s", "connections", "packets", "files", ""];

    /** array of base units used by the runboook/incident/global variables */
    public static VAR_UNITS = ["bps", "B", "pps", "cps", "%", "s", "connections", "packets", "files", ""];

    /** an array with the units that should not be scaled. */
    public static NON_SCALABLE_UNITS = ["%", "pct"];

    /** a String with the type, for example: "Site", "Application", ... */
    public type: string;
    /** a String with the field. */
    public field: string;
    /** a String with the unit. */
    public unit: string;
    /** a string with the unit prefix. */
    public prefix: string;
    /** Use this for generating dropdown options */
    private scaledUnitOptions = {
        "s": ["s", "ms", "us"],
        "B": ["B", "kB", "MB", "GB", "TB", "PB"],
        "bps": ["bps", "kbps", "Mbps", "Gbps"]
    }

    /** Returns the scaled unit options for the current base unit.
     *  @returns array of unit options.  */
    public getScaledUnitOptions(){
        return this.scaledUnitOptions[this.unit];
    }
    /** creates a new instance of unit.*/
    public constructor();

    /** creates a new instance of unit.
     *  @param unit a String with the unit.*/
     public constructor(unit: string);

     /** creates a new instance of unit.
     *  @param unit a String with the unit.
     *  @param prefix a String with the prefix for the unit.*/
     public constructor(unit: string, prefix: string);

     /** creates a new instance of unit.
     *  @param unit a String with the unit.
     *  @param prefix a String with the prefix for the unit.
     *  @param type a String with the type, for example: "Site", "Application", ....
     *  @param field a String with the field.*/
     public constructor(unit: string, prefix: string, type: string, field: string);

    /** creates a new instance of unit.
     *  @param unit a String with the unit.
     *  @param prefix a String with the prefix for the unit.
     *  @param type a String with the type, for example: "Site", "Application", ....
     *  @param field a String with the field.*/
     public constructor(unit: string = "", prefix: string = "", type: string = "", field: string = "") {
        this.type = type;
        this.field = field;
        this.prefix = prefix;

        // Translate some unit keys in DO to keys the UI is using
        switch (unit) {
            case "percent":
            case "pct":
                unit = "%";
                break;
            case "bytes":
                unit = "B";
                break;
            case "none":
                // Data Ocean returns a column with no unit as "none"
                unit = "";
                break;
        }
        this.unit = unit;
    }

    /** returns whether or not the unit is scalable.
     *  @returns a boolean value which if true specifies that the unit maybe scaled and 
     *      if false indicates that the unit may not be scaled.*/
    public isScalable(): boolean {
        return !Unit.NON_SCALABLE_UNITS.includes(this.unit);
    }

    /** returns the scaling information for the value.
     *  @param value the value to be scaled.
     *  @param factor the scale factor to use.
     *  @returns a ScaleResult object with the result of the scaling operation.*/
    public getScaledUnit(value: number, factor: number = 1000): ScaleResult {
        let unit = this;

        if (!this.isScalable()) {
            return {scale: 1, unit: unit};
        }
        let scale = 1;

        // Check to see if the unit already has a prefix, if so express it in the base units
        if (this.prefix) {
            const prefixIndex = Unit.UNIT_PREFIXES.indexOf(this.prefix);
            if (prefixIndex > 0) {
                scale = 1 / Math.pow(factor, prefixIndex);
            }
        }
        if (this.unit === 's'){
            let curScale =  Unit.TIME_PREFIX[this.prefix] ? Unit.TIME_PREFIX[this.prefix] : 1;
            // Convert to seconds.
            const valueInSec = value * curScale;
            for (const key in Unit.TIME_PREFIX) {
                scale = 1/Unit.TIME_PREFIX[key];
                const scaledValue = valueInSec * scale;
                if(scaledValue >= 1) {
                    const updatedScaleRatio = curScale / Unit.TIME_PREFIX[key] ;
                    return {scale: 1/updatedScaleRatio, unit: new Unit(this.unit, key, this.type, this.field)};
                }
                scale  = scale  * (1/ Unit.TIME_PREFIX[key]);
            }
        } else {
            // Handle non time units
            for (const prefix of Unit.UNIT_PREFIXES) {
                const scaledValue = Math.abs(value) / scale;
                // Keep it below 1000
                if (scaledValue > 0 && scaledValue < 1000) {
                    return {scale: scale, unit: new Unit(this.unit, prefix, this.type, this.field)};
                }
                scale = scale * factor;
            }
        }
        return {scale: 1, unit: unit};
    }

    /** converts the value expressed in this unit to another compatible unit.
     *  @param value the value to be scaled.
     *  @param toUnit the new unit in which the value can be expressed.
     *  @param factor the scale factor to use.
     *  @returns the converted value or NaN if the conversion cannot be done.*/
    public convert(value: number, toUnit: Unit, factor: number = 1000): number {
        let scale = 1;

        if (this.isEqual(toUnit)) {
            // Don't try to convert units that are the same
            return value;
        }

        if (!this.isScalable()) {
            return Number.NaN;
        }

        if (this.unit !== toUnit.unit) {
            return Number.NaN;
        }
        // Check to see if the unit already has a prefix, if so express it in the base units
        if (this.prefix) {
            const prefixIndex = Unit.UNIT_PREFIXES.indexOf(this.prefix);
            if (prefixIndex > 0) {
                scale = 1 / Math.pow(factor, prefixIndex);
            }
            if (Unit.TIME_PREFIX[this.prefix]) {
                scale = 1 / Unit.TIME_PREFIX[this.prefix];
            }
        }
        // Check to see if the to unit has a prefix
        if (toUnit.prefix) {
            const prefixIndex = Unit.UNIT_PREFIXES.indexOf(toUnit.prefix);
            if (prefixIndex > 0) {
                scale = scale * Math.pow(factor, prefixIndex);
            }
            if (Unit.TIME_PREFIX[toUnit.prefix]) {
                const fromScale = Unit.TIME_PREFIX[this.prefix];
                const toScale = Unit.TIME_PREFIX[toUnit.prefix];
                scale = toScale / fromScale
            }
        }
        return value / scale;
    }

    /** returns the display name for the unit. 
     *  @returns a String with the display name.*/
    public getDisplayName(): string {
        return `${this.prefix}${STRINGS.UNITS[this.unit] || this.unit}`;
    }

    /** returns the display name for the unit. 
     *  @returns a String with the display name.*/
    public toString(): string {
        let prefix = this.prefix;
        let unit = this.unit;
        switch (unit) {
            case "%":
            case "pct":
                // Data ocean uses percent for %
                unit = "percent";
                break;
            case "B":
                // Data ocean uses bytes for B
                unit = "bytes";
                break;
            case "":
                // Data Ocean returns a column with no unit as "none"
                // Do we need this?????
                //unit = "none";
                break;
        }
        return `${prefix}${unit}`;
    }

    /** clones this unit.
     *  @returns the cloned unit.*/
    public clone(): Unit {
        return new Unit(this.unit, this.prefix, this.type, this.field);
    }

    /** compares this unit to another unit and returns true if equal and false otherwise.  Only the prefix
     *      and unit fields are compared.  It does not look at any other field.
     *  @param unit the unit to compare to.
     *  @returns true if the units are equal, false otherwise.*/
    public isEqual(unit: Unit): boolean {
        return unit.prefix === this.prefix && unit.unit === this.unit;
    }

    /** parses a unit string and returns the correct unit object with a base unit and 
     *      prefix.  If the base unit is not in the static array of base units, then there
     *      is no way that the prefix can be figured out.  In that case the unit will not
     *      be correctly divided into a base unit and a prefix and the base unit will 
     *      include the prefix.
     *  @param string a Unit string.
     *  @returns the Unit that was parsed from the unit string.*/
    public static parseUnit(unit: string): Unit {
        if (unit && unit !== "none") {
            for (const baseUnit of this.BASE_UNITS) {
                if (!Unit.NON_SCALABLE_UNITS.includes(baseUnit) && unit.endsWith(baseUnit)) {
                    const prefix = unit.substring(0, unit.lastIndexOf(baseUnit));
                    if (Unit.UNIT_PREFIXES.includes(prefix)) {
                        return new Unit(baseUnit, prefix);
                    } else if (Unit.TIME_PREFIX[prefix]) {
                        return new Unit(baseUnit, prefix);
                    }
                }
            }
        }
        return new Unit(unit || "");
    }
}
