import {
    FeatureFlagService,
    defaultFFObj,
} from "utils/services/FeatureFlagService";

let ffSources = defaultFFObj;

/** retrievs all feature flags into a `Record<string, boolean>` */
export async function retrieveTenantFeatureFlags() {
    try {
        ffSources = await FeatureFlagService.getFFResults();
        return ffSources;
    } catch (error) {
        console.error("Feature flags query has failed:", error);
        return ffSources;
    }
}

export const tenantFF = () => {
    return ffSources;
};
