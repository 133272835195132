/** This module contains the toolbar panel that controls the graph
 *  @module
 */
import React, { Component } from 'react'
import { InputGroup, ButtonGroup, Button, Divider, Intent, Menu, MenuItem, AnchorButton } from '@blueprintjs/core';
import { RunbookInfo } from './types/GraphTypes';
import { STRINGS, HELP } from "app-strings";
import { Classes, IconNames } from '@tir-ui/react-components';
import { Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';
import { IconTitle } from '../icon-title/IconTitle';
import { APP_ICONS, SDWAN_ICONS } from 'components/sdwan/enums';
import { SIZE } from 'components/enums';
import { canEditRunbook } from 'utils/runbooks/RunbookUtils';
import { RunbookConfig } from 'utils/services/RunbookApiService';
import { ItemList, ItemListItem } from '../layout/item-list';
import { PageTopLevelHelp } from "@tir-ui/react-components";
import DOMPurify from 'dompurify';
import { WrapInTooltip as WrapInTooltipComponent } from '../wrap-in-tooltip/WrapInTooltip';
import { EventNames, trackEvent } from 'utils/appinsights';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { HelpSources } from 'utils/appinsights/AppInsights';
import { SHOW_VARIABLE_HEADER } from './DragAndDropPanel';
import { UserAssistanceValue } from 'types/UserAssistance';
import { IconVideo } from '../icon-video/IconVideo';
import { IS_EMBEDDED } from 'components/enums/QueryParams';
import { Version } from 'utils/Version.class';
import './GraphToolbar.css';

/** this constant has the base URI for the help. */
const { HELP_URI } = window['runConfig'] || {HELP_URI :''};

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** this interface defines the properties that are passed into the graph toolbar. */
interface GraphToolbarProps {
    /** a boolean value, which if true will cause the search control to be rendered, if false it will not be shown. */
    showSearch?: boolean;
    /** notifies any listeners about a toolbar action. */
    notifyToolbarAction?: (type: ToolbarAction, value: any) => void;
    /** handler for the search changed event. */
    searchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /** the list of runbooks. */
    runbooks?: Array<RunbookInfo>;
    /** the current active runbook. */
    activeRunbook?: RunbookInfo;
    /** an array with the erors. */
    errors?: Array<string>;
    /** an array with the warnings. */
    warnings?: Array<string>
    /** the title to display at the top of the editor. */
    title?: string;
    /** Flag to disable the 'New' button */
    disableNew?: boolean;
    /** if true disable the save button, if false enabled it. */
    disableSave?: boolean;
    /** if true disable the save button, if false enabled it. */
    disableExport?: boolean;
    /** specifies whether or not to show the reset button. */
    showReset?: boolean;
    /** specifies whether or not to disable the reset button. */
    disableReset?: boolean;
    /** specifies whether or not to show the zoom controls. */
    showZoomControls?: boolean;
    /** specifies whether or not to show the reset view control. */
    showResetView?: boolean;
    /** specifies whether or not to show the fit to screen control. */
    showFitToScreen?: boolean;
    /** Controls displaying the auto-connect nodes toggle button. */
    showAutoConnectNodesControl?: boolean;
    /** State of the auto-connect nodes toggle button */
    autoConnectNodes?: boolean;
    /** specifies whether or not to show the layout controls. */
    showRefreshLayoutControl?: boolean;
    /** State of the auto-layout toggle button */
    autoLayout?: boolean;
    /** specifies whether or not to show the layout direction controls. */
    showLayoutDirectionControls?: boolean;
    /** specifies whether or not to show the export control. */
    showExportControl?: boolean;
    /** specifies whether or not to show the import control. */
    showImportControl?: boolean;
    /** specifies whether or not to show the clear runbook control. */
    showClearControl?: boolean;
    /** specifies whether or not to show the delete runbook control. */
    showDeleteControl?: boolean;
    /** specifies whether or not to show the select runbook control. */
    showSelectRunbookControl?: boolean;
    /** a boolean value, if true show the errors and warnings. */
    showErrors?: boolean;
    /** a boolean value, if true show the version information, if false or not provided do not show the version. */
    showVersion?: boolean;
    /** if true the undo control should be enabled, if false it should be disabled. */
    undoAvailable?: boolean;
    /** if true the redo control should be enabled, if false it should be disabled. */
    redoAvailable?: boolean;
    /** a boolean value, if true, show both the undo and redo controls.  If false, show neither. */
    showUndoRedoControls?: boolean;
    /** Indicates the multiselect button's activated state. */
    multiSelectActivated?: boolean;
    /** a boolean value, if true it indicates the preview control should be displayed. */
    showPreviewControl?: boolean;
    /** a boolean value, if true it indicates the test control should be displayed. */
    showTestControl?: boolean;
    /** a boolean value that specifies whether to show development features. */
    showDevel?: boolean;
}

/** this enum specifies all the supported toolbar action types.  The toolbar action type is passed
 *  to the handler for toolbar actions.*/
export enum ToolbarAction {
    /** this enumerated type specifies that the user clicked on the zoom in button and the graph should zoom in. */
    ZOOM_IN             = "zoom_in",
    /** this enumerated type specifies that the user clicked on the zoom out button and the graph should zoom out. */
    ZOOM_OUT            = "zoom_out",
    /** this enumerated type specifies that the user clicked on the reset zoom button and the graph should reset the zoom state. */
    ZOOM_RESET          = "zoom_reset",
    /** this enumerated type specifies that the user clicked on the fit to screen button and the graph should fit the rb to the screen. */
    ZOOM_FIT_TO_SCREEN  = "zoom_fit_to_screen",
    /** this enumerated type specifies that the user clicked on the clear runbook button.  This indicates all nodes should be removed from the graph. */
    CLEAR_RUNBOOK       = "clear_runbook",
    /** An action to reset any and all unsaved changes in the runbook and return it to it's previous state. */
    RESET_RUNBOOK       = "reset_runbook",
    /** this enumerated type specifies that the user clicked on the reload initial runbook button.  This is no longer in use. */
    RELOAD_INIT_RUNBOOK = "reload_init_runbook",
    /** this enumerated type specifies that the user selected a runbook.  This indicates the viewer should switch to that runbook. */
    RUNBOOK_SELECTED    = "runbook_selected",
    /** this enumerated type specifies that the user clicked on the import runbook button. */
    IMPORT_RUNBOOK      = "import_runbook",
    /** this enumerated type specifies that the user clicked on the export runbook button. */
    EXPORT_RUNBOOK      = "export_runbook",
    /** this enumerated type specifies that the user clicked on the sync runbook button. */
    SYNC_RUNBOOK        = "sync_runbook",
    /** this enumerated type specifies that the user clicked on the edit runbook settings button.  Settings
     *  include the name, description and input type. */
    SETTINGS_RUNBOOK    = "sync_settings_for_runbook",
    /** this enumerated type specifies that the user clicked on the delete runbook button. */
    DELETE_RUNBOOK      = "delete_runbook",
    /** this enumerated type specifies that the user clicked on the view runbook button. */
    VIEW_RUNBOOK        = "view_runbook",
    /** this enumerated type specifies that the user clicked on the test runbook button. */
    TEST_RUNBOOK        = "test_runbook",
    /** Show the runbook in vertical top-to-down layout */
    LAYOUT_VERTICAL     = "layout_vertical",
    /** Show the runbook in horizontal left-to-right layout (default) */
    LAYOUT_HORIZONTAL   = "layout_horizontal",
    /** Run the layout algorithm in the graph. */
    LAYOUT              = "layout",
    /** Enable/Disable autoconnnecting nodes when dropped to the work area */
    AUTO_CONNECT        = "autoconnect",
    /** Enable/Disable auto-layout of nodes */
    AUTO_LAYOUT         = "autolayout",
    /** Enable/Disable errors and warnings */
    ERRORS_AND_WARNINGS = "errors_and_warnings",
    /** undo a change. */
    UNDO                = "undo",
    /** redo a change. */
    REDO                = "redo",
    /** toggles whether or not multi-select is turned on. */
    MULTI_SELECT        = "multi_select",
    /** toggles whether or not variable editing is displayed. */
    VARIABLES           = "variables",
}

/** A simple internal component to make it easier to apply blueprint's tooltip to node components */
function WrapInTooltip ({ ttKey, children }) {
    if (children) {
        const tooltipStrings = STRINGS.runbookEditor.toolbar.tooltips;
        const ttText = tooltipStrings[ttKey] || ttKey || "";
        const ttHint = tooltipStrings[ttKey + "Hint"];
        return <WrapInTooltipComponent tooltip={ttText} hint={ttHint} children={children}/>;
    } else {
        return children;
    }
};

/** this class defines a react component for displaying the graph toolbar. */
export default class GraphToolbar extends Component<GraphToolbarProps, { horizontal: boolean }> {
    /** this context type refers to the app insights context which is used to log information to app insights. */
    static contextType = AppInsightsContext;
    
    /** the constructor for the class.
     *  @param props the properties passed in.*/
    constructor(props) {
        super(props);

        this.state = { horizontal: true };

    }

    // Support hot key actions by listening to keydown event on document
    onKeyDown = (e) => {
        const elementFocusedOnKeyPress = e.target?.tagName;
        // Prevent events from being triggered when other elements are focused
        if (!['BODY'].includes(elementFocusedOnKeyPress)) {
            return;
        }

        // If undo attempted
        if (this.props.showUndoRedoControls && e.key === "z" && (e.ctrlKey || (e.metaKey && !e.shiftKey))) {
            if (this.props.undoAvailable) {
                this.sendNotification(ToolbarAction.UNDO);
            }
            e.preventDefault();
        // If redo attempted
        } else if (this.props.showUndoRedoControls && (
            (e.key === "y" && (e.ctrlKey || e.metaKey)) ||
            (e.key === "z" && e.metaKey && e.shiftKey))
        ) {
            if (this.props.redoAvailable) {
                this.sendNotification(ToolbarAction.REDO);
            }
            e.preventDefault();
        // Save key combo was pressed
        } else if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
            this.sendNotification(ToolbarAction.SYNC_RUNBOOK);
            e.preventDefault();
        }
    }
    componentDidMount () {
        document.addEventListener("keydown", this.onKeyDown);
    }
    componentWillUnmount () {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    sendNotification (action: ToolbarAction, value: any = null): void {
        if (this.props.notifyToolbarAction) {
            this.props.notifyToolbarAction(action, value);
        }
    }

    /** Renders the graph toolbar.
     *  @returns JSX with the graph toolbar component.*/
     render(): JSX.Element {
        const defaultValue = this.props.activeRunbook && this.props.runbooks && this.props?.runbooks?.length > 0 ? this.props.activeRunbook.id : undefined;
        let activeRunbookName = STRINGS.runbookEditor.toolbar.unnamed;
        let editRunbookName = this.props.activeRunbook ? this.props.activeRunbook.label : STRINGS.runbookEditor.toolbar.unnamed;
        let editRunbookVersion = Version.parse(this.props.activeRunbook ? this.props.activeRunbook.version || "" : "").toString();
        const options: Array<{label: string, value: string}> = [];
        const helpInfo: UserAssistanceValue = HELP.runbookEditor;
        if (this.props.runbooks) {
            for (const runbook of this.props.runbooks) {
                const label = (runbook?.i18nNameKey ? STRINGS.defaultRunbooks[runbook.i18nNameKey] : runbook?.label);
                options.push({value: runbook.id, label: label});
                if (runbook.id === defaultValue) {
                    activeRunbookName = label;
                    if (editRunbookName === runbook?.label) {
                        // There has been no user change of the label so set it equal to the i18n corrected label
                        editRunbookName = label;
                    }
                }
            }
        }

        const warnings: Array<JSX.Element> = [];
        const errors: Array<JSX.Element> = [];
        if (this.props.showErrors) {
            if (this.props.warnings) {
                for (let warningindex = 0; warningindex < this.props.warnings.length; warningindex++) {
                    warnings.push(<ItemListItem key={"warning" + warningindex}><span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.warnings[warningindex])}} /></ItemListItem>);
                }
            }
            if (this.props.errors) {
                for (let errorIndex = 0; errorIndex < this.props.errors.length; errorIndex++) {
                    errors.push(<ItemListItem key={"error" + errorIndex}><span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.errors[errorIndex])}} /></ItemListItem>);
                }
            }    
        }

        const saveDisabled = this.props.disableSave || !canEditRunbook(this.props.activeRunbook as RunbookConfig);
        const videoURL = {"Runbook_Editor_Tour": STRINGS.runbookEditor.toolbar.tooltips.videoDialogTitle};

        return (
            <div className="graph-toolbar display-8 pr-3">
                <ButtonGroup minimal>
                    {!IS_EMBEDDED && <WrapInTooltip ttKey="new">
                        <AnchorButton icon={IconNames.DOCUMENT} disabled={this.props.disableNew}
                            onClick={() => this.sendNotification(ToolbarAction.RUNBOOK_SELECTED)}
                        />
                    </WrapInTooltip>}
                    <WrapInTooltip ttKey="save">
                        <AnchorButton icon={IconNames.FLOPPY_DISK} disabled={saveDisabled}
                            onClick={() => this.sendNotification(ToolbarAction.SYNC_RUNBOOK)}
                        />
                    </WrapInTooltip>
                    {this.props.showImportControl && 
                        <WrapInTooltip ttKey="import">
                            <AnchorButton icon={IconNames.IMPORT} onClick={() => this.sendNotification(ToolbarAction.IMPORT_RUNBOOK)}/>
                        </WrapInTooltip>
                    }
                    {this.props.showExportControl && 
                        <WrapInTooltip ttKey="export">
                            <AnchorButton disabled={this.props.disableExport} icon={IconNames.EXPORT} onClick={() => this.sendNotification(ToolbarAction.EXPORT_RUNBOOK)}/>
                        </WrapInTooltip>
                    }
                    { this.props.showDeleteControl &&
                        <WrapInTooltip ttKey="delete">
                            <AnchorButton icon={IconNames.TRASH} onClick={() => this.sendNotification(ToolbarAction.DELETE_RUNBOOK)}/>
                        </WrapInTooltip>
                    }
                    <Divider/>
                    { this.props.showClearControl &&
                        <WrapInTooltip ttKey="clear">
                            <AnchorButton icon={IconNames.DOCUMENT} onClick={() => this.sendNotification(ToolbarAction.CLEAR_RUNBOOK, null)}/>
                        </WrapInTooltip>
                    }
                    {this.props.showReset &&
                        <Popover2
                            disabled={this.props.disableReset}
                            content={<div className="p-2">
                                <IconTitle title={STRINGS.runbookEditor.toolbar.resetTitle} icon={APP_ICONS.RESET} size={SIZE.s} className="font-weight-bold mt-1"/>
                                <div className="p-2 w-max-2">{STRINGS.runbookEditor.toolbar.resetWarningText}</div>
                                <div className="d-flex justify-content-between">
                                    <AnchorButton className={Classes.POPOVER_DISMISS} icon={IconNames.UNDO} 
                                        text={STRINGS.runbookEditor.toolbar.cancelBtnText}
                                    />
                                    <AnchorButton className={Classes.POPOVER_DISMISS} icon={IconNames.TICK} intent="danger"
                                        text={STRINGS.runbookEditor.toolbar.yesBtnText} 
                                        onClick={() => this.sendNotification(ToolbarAction.RESET_RUNBOOK)}
                                    />
                                </div>
                            </div>}
                            autoFocus={false}
                            enforceFocus={false}
                        >
                            <WrapInTooltip ttKey="reset">
                                <AnchorButton disabled={this.props.disableReset} icon={IconNames.RESET} />
                            </WrapInTooltip>
                        </Popover2>
                    }
                    {this.props.showUndoRedoControls && <>
                        <WrapInTooltip ttKey="undo">
                            <AnchorButton icon={IconNames.UNDO} onClick={() => this.sendNotification(ToolbarAction.UNDO)} disabled={!Boolean(this.props.undoAvailable)}/>
                        </WrapInTooltip>
                        <WrapInTooltip ttKey="redo">
                            <AnchorButton icon={IconNames.REDO} onClick={() => this.sendNotification(ToolbarAction.REDO)} disabled={!Boolean(this.props.redoAvailable)}/>
                        </WrapInTooltip>
                    </>}
                    <Divider/>
                    {this.props.showZoomControls && <>
                        <WrapInTooltip ttKey="zoomIn">
                            <AnchorButton icon={IconNames.ZOOM_IN} onClick={() => this.sendNotification(ToolbarAction.ZOOM_IN)}/>
                        </WrapInTooltip>
                        <WrapInTooltip ttKey="zoomOut">
                            <AnchorButton icon={IconNames.ZOOM_OUT} onClick={() => this.sendNotification(ToolbarAction.ZOOM_OUT)}/>
                        </WrapInTooltip>
                        {this.props.showResetView && <WrapInTooltip ttKey="resetView">
                            <AnchorButton icon={IconNames.MINIMIZE} onClick={() => this.sendNotification(ToolbarAction.ZOOM_RESET)}/>
                        </WrapInTooltip>}
                        {this.props.showFitToScreen && <WrapInTooltip ttKey="fitToScreen">
                            <AnchorButton icon={IconNames.MAXIMIZE} onClick={() => this.sendNotification(ToolbarAction.ZOOM_FIT_TO_SCREEN)}/>
                        </WrapInTooltip>}
                        <Divider/>
                    </>}
                    <WrapInTooltip ttKey="multiSelect">
                        <AnchorButton active={this.props.multiSelectActivated} icon={IconNames.WIDGET}
                            onClick={() => {
                                this.sendNotification(ToolbarAction.MULTI_SELECT, true);
                            }}
                        />
                    </WrapInTooltip>
                    {this.props.showRefreshLayoutControl && <>
                        <WrapInTooltip ttKey="layout">
                            <AnchorButton icon={IconNames.CLEAN}
                                onClick={() => {
                                    this.sendNotification(ToolbarAction.LAYOUT);
                                }}
                            />
                        </WrapInTooltip>
                        {/* <Switch
                            checked={this.props.autoLayout}
                            label={STRINGS.runbookEditor.toolbar.autoLayout}
                            className="display-10 text-nowrap mb-0 d-flex align-items-center"
                            onChange={e => {
                                this.sendNotification(ToolbarAction.AUTO_LAYOUT, e.currentTarget.checked);
                            }}
                        />
                        <Divider/> */}
                    </>}
                    {this.props.showAutoConnectNodesControl && <>
                        <WrapInTooltip ttKey="autoConnect">
                            <AnchorButton icon={IconNames.NEW_LINK}
                                active={this.props.autoConnectNodes}
                                onClick={() => {
                                    this.sendNotification(ToolbarAction.AUTO_CONNECT, !this.props.autoConnectNodes);
                                }}
                            />
                        </WrapInTooltip>
                    </>}
                    {this.props.showLayoutDirectionControls && <>
                        <WrapInTooltip ttKey="horizontalLayout">
                            <AnchorButton active={this.state.horizontal} icon={IconNames.LAYOUT_HIERARCHY} className="layout-button"
                                onClick={() => {
                                this.setState({ horizontal: true });
                                this.sendNotification(ToolbarAction.LAYOUT_HORIZONTAL);
                            }}/>
                        </WrapInTooltip>
                        <WrapInTooltip ttKey="verticalLayout">
                            <AnchorButton active={!this.state.horizontal} icon={IconNames.LAYOUT_HIERARCHY} 
                                onClick={() => {
                                    this.setState({ horizontal: false });
                                    this.sendNotification(ToolbarAction.LAYOUT_VERTICAL);
                            }}/>
                        </WrapInTooltip>
                        <Divider/>
                    </>}
                    {!SHOW_VARIABLE_HEADER && <>
                        <Divider/>
                        <WrapInTooltip ttKey="viewVariables">
                            <AnchorButton id="variables-icon" icon={IconNames.VARIABLE} onClick={() => this.sendNotification(ToolbarAction.VARIABLES)}/>
                        </WrapInTooltip>
                    </>}
                    {(this.props.showTestControl || this.props.showPreviewControl) && <Divider/>}
                    { this.props.showPreviewControl && <WrapInTooltip ttKey="viewOutput">
                        <AnchorButton icon={IconNames.EYE_OPEN} onClick={() => this.sendNotification(ToolbarAction.VIEW_RUNBOOK)}/>
                    </WrapInTooltip>}
                    { this.props.showTestControl && <WrapInTooltip ttKey="testOutput">
                        <AnchorButton 
                            icon={IconNames.LAB_TEST} onClick={() => this.sendNotification(ToolbarAction.TEST_RUNBOOK)}
                            disabled={false/*this.props.activeRunbook?.triggerType === InputType.WEBHOOK && !this.props.showDevel*/}
                        />
                    </WrapInTooltip>}
                </ButtonGroup>
                { this.props.showSelectRunbookControl && <>
                    <span className="graph-toolbar-separator" />
                    <span><label className="mr-2 mt-1">{STRINGS.runbookEditor.toolbar.runbookLabelText}</label></span>
                    <Popover2 minimal 
                        content={<Menu>
                            {options.map(option => {
                                return <MenuItem text={option.label} 
                                    onClick={() => this.sendNotification(ToolbarAction.RUNBOOK_SELECTED, option.value)} 
                                    active={option.value === defaultValue} key={option.value}
                                />
                            })}
                        </Menu>}
                        autoFocus={false}
                        enforceFocus={false}                        
                    >
                        <Button rightIcon={IconNames.CHEVRON_DOWN} text={activeRunbookName} className="text-nowrap"/>
                    </Popover2>
                    {this.props.showSearch && <>
                        <span className="graph-toolbar-separator" />
                        <InputGroup placeholder="Search Nodes..." 
                            onChange={(evt) => {
                                if (this.props.searchChange) {
                                    this.props.searchChange(evt);
                                }
                            }} 
                        />
                    </>}
                </>}
                {(this.props.title || editRunbookName) && <>
                    <div className="ml-2 w-100 text-center d-none d-sm-block">
                        {this.props.disableSave ? "" : <div className='unsaved'><sup className="text-critical font-weight-bold">* (Unsaved)</sup></div>}
                        <div className='runbook-title-wrapper'>
                            <IconTitle
                                title={<>{this.props.title || editRunbookName}</>}
                                icon={SDWAN_ICONS.RUNBOOK} showIconWithoutBg
                                size={SIZE.s}
                                className='font-weight-bold'
                            />
                            <span style={{verticalAlign: "text-bottom"}}>
                                <WrapInTooltip ttKey="edit">
                                    <AnchorButton minimal icon={IconNames.EDIT} onClick={() => this.sendNotification(ToolbarAction.SETTINGS_RUNBOOK)}/>
                                </WrapInTooltip>
                            </span>
                        </div>
                        {this.props.showVersion && editRunbookVersion && <div className="version">Version {editRunbookVersion}</div>}
                    </div>
                </>}
                {this.props.showErrors && <div className="mx-3 font-weight-bold w-100 text-right d-none d-sm-block">
                    { ((this.props.warnings && this.props.warnings.length > 0) || (this.props.errors && this.props.errors.length > 0)) && 
                        <Button minimal small={true} text={<>
                            { this.props.warnings && this.props.warnings.length > 0 && 
                                <Popover2 usePortal lazy
                                    content={<ItemList className="w-max-8 overflow-auto">
                                        {warnings}
                                    </ItemList>}
                                    interactionKind={Popover2InteractionKind.HOVER}
                                    hoverOpenDelay={1000}
                                    transitionDuration={150}
                                    autoFocus={false}
                                    enforceFocus={false}
                                >
                                    <AnchorButton minimal className="toolbar-warning-button" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} text={String(this.props.warnings.length)} /> 
                                </Popover2> }
                            { this.props.errors && this.props.errors.length > 0 && 
                                <Popover2 usePortal lazy
                                    content={<ItemList className="w-max-8 overflow-auto">
                                        {errors}
                                    </ItemList>}
                                    interactionKind={Popover2InteractionKind.HOVER}
                                    hoverOpenDelay={1000}
                                    transitionDuration={150}
                                    autoFocus={false}
                                    enforceFocus={false}
                                >
                                    <AnchorButton minimal className="toolbar-error-button" icon={IconNames.ERROR} intent={Intent.DANGER} text={String(this.props.errors.length)} />
                                </Popover2> }
                            </>} onClick={() => this.sendNotification(ToolbarAction.ERRORS_AND_WARNINGS)}
                        />
                    }
                </div>}
                {!IS_EMBEDDED && <IconVideo videoData={videoURL} />}
                {
                    (!IS_EMBEDDED && helpInfo) && <div>
                        <PageTopLevelHelp
                            comment={helpInfo.comment}
                            helpSummary={helpInfo.helpSummary}
                            showMore={Boolean(helpInfo.showMore)}
                            url={HELP_URI + helpInfo.url}
                            onHelpAction={(action, url) => {
                                // report metrics to App Insights
                                if (this.context) {
                                    const properties = {
                                        name: action === "launch" ? EventNames.HELP_INVOKED : EventNames.HELP_POPUP_INVOKED, 
                                        properties: {
                                            topic: helpInfo?.url || "Unknown",
                                            type: HelpSources.PAGE
                                        }
                                    };
                                    trackEvent(this.context, AuthService, properties);
                                }
                            }}
                        />
                    </div>
                }
            </div>
        );
    };
}
