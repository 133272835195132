import React, { useCallback, useRef, useState, useEffect } from 'react'
import { ButtonProps, DialogStep, Intent, MultistepDialog } from '@blueprintjs/core';
import StepSelect from './StepSelect'
import StepSpecify from './StepSpecify';
import StepSchedule from './StepSchedule';
import StepReview from './StepReview';
import { ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { validIP } from 'utils/validators/Validators';
import { runbookService } from 'utils/runbooks/RunbookUtils';
import { Variant } from 'components/common/graph/types/GraphTypes';
import { sortBy } from 'lodash';
import './ScheduleRunbookModal.scss';

export const convertIfIndexToString = jsonObj => JSON.stringify(jsonObj, (key, value) => key === 'ifindex' ? String(value) : value);

export const formatPostDate = (dateValue) => {
    if (typeof dateValue === 'string') {
        return String(dateValue);
    } else {
        // convert from Date to nanosecs string
        const asMillisecs = new Date(dateValue).getTime()
        return String(asMillisecs / 1000)
    }
}

export const getInputs = (inputs) => {
    const variableOverrides = inputs?.variableOverrides ?? {};
    const variableTypes = inputs.variableTypes ?? {};
    return Object.entries(variableOverrides)
        .map(([name, value]) => ({
            name,
            value,
            type: variableTypes[name]
        }));
};

const ScheduleRunbookModal = React.forwardRef((props: any, ref) => {
    React.useImperativeHandle(ref, () => ({
        handleOpen(editInfos) {
            setIsOpen(!isOpen);
            setEditInfos(editInfos);
        },
    }));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editInfos, setEditInfos] = useState<any>(null);
    const isWizardSubmit = useRef<boolean>(false);
    const handleClose = useCallback(() => {
        setIsOpen(false);
        setRunbookID('');
        setSchedule({ name: '', startOn: new Date(), repeat: '', every: 0, daysOfWeek: [] });
        setScheduleType('isSaved');
        inputs.current = {};
        setDescription('');
        setEditInfos(null);
        setSummary('');
        setCanEdit(false);
        setHasValidInputs(true);
        setLoadingSchedules(true);
    }, []);
    const wizardStrings = STRINGS.scheduleRunbook.wizard.queryMessages;

    // Mutation create schedule
    // const [CreateRunbookSchedule] = useMutation<any, IScheduleInputFields>(
    const [CreateRunbookSchedule] = useMutation<any, any>(
        loader('./create-schedule-mutation.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message: isWizardSubmit.current ? wizardStrings.successCreateJob : wizardStrings.successCreate,
                });
                isWizardSubmit.current = false;
            },
            onError: (err) => {
                ErrorToaster({
                    message: isWizardSubmit.current ? wizardStrings.errorCreateJob : wizardStrings.errorCreate,
                });
                console.error(err?.message);
                isWizardSubmit.current = false;
            },
        }
    );

    const [UpdateRunbookSchedule] = useMutation<any, any>(
        loader('./edit-schedule-mutation.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message: isWizardSubmit.current ? !editInfos ? wizardStrings.successCreateJob : wizardStrings.successEditJob : wizardStrings.successEdit,
                });
                isWizardSubmit.current = false;
            },
            onError: (err) => {
                ErrorToaster({
                    message: isWizardSubmit.current ? !editInfos ? wizardStrings.errorCreateJob : wizardStrings.errorEditJob : wizardStrings.errorEdit,
                });
                console.error(err?.message);
                isWizardSubmit.current = false;
            },
        }
    );

    const [runbookID, setRunbookID] = useState<string>('');
    const inputs = useRef<any>({});

    const [schedule, setSchedule] = useState({ name: '', startOn: new Date(), repeat: '', every: 0, daysOfWeek: [] });
    const [description, setDescription] = useState<string>('');
    const [summary, setSummary] = useState('');
    const [scheduleType, setScheduleType] = useState('isSaved');
    const [canEdit, setCanEdit] = useState(false);
    const [hasValidInputs, setHasValidInputs] = useState<boolean>(true);
    const [loadingSchedules, setLoadingSchedules] = useState<boolean>(true);

    useEffect(() => {
        if (editInfos && isOpen) {
            const { name, startOn, repeat, every, daysOfWeek } = editInfos.details;
            setSchedule({ name, startOn, repeat, every, daysOfWeek });
            setDescription(editInfos.schedule.description);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, editInfos]);

    const handleSubmit = async () => {
        isWizardSubmit.current = true;
        const mappedInputs = convertIfIndexToString(inputs?.current?.detection?.entity);
        const payload = {
            variables: {
                input: {
                    name: schedule.name,
                    startOn: formatPostDate(schedule.startOn),
                    repeat: schedule.repeat,
                    every: Number(schedule.every),
                    description: description,
                    daysOfWeek: schedule.daysOfWeek,
                    runbook: {
                        tenantId: inputs?.current?.utid,
                        detection: {
                            id: inputs?.current?.detection?.id,
                            entity: mappedInputs
                        },
                        incidentId: inputs?.current?.incidentId,
                        inputs: getInputs(inputs?.current),
                        runbookId: runbookID
                    }
                }
            }
        }
        try {
            scheduleType === 'isSaved' ? await UpdateRunbookSchedule(payload) : await CreateRunbookSchedule(payload);
            handleClose();
            if (props.refreshSearch) {
                props.refreshSearch();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const finalButtonProps: Partial<ButtonProps> = {
        intent: Intent.PRIMARY,
        loading: false,
        disabled: false,
        onClick: handleSubmit
    };

    const validateInputs = (inputs) => {
        if (inputs?.variableOverrides && inputs?.variableTypes) {
            let hasInvalidBoolean = false;
            let hasInvalidIpAddress = false;
            for (const [key, value] of Object.entries(inputs.variableOverrides)) {
                if (inputs.variableTypes[key] === "boolean" && value && !["true", "false"].includes(value as string)) {
                    hasInvalidBoolean = true;
                } else if (inputs.variableTypes[key] === "ipaddr" && value && !validIP(value as string)) {
                    hasInvalidIpAddress = true;
                }
            }
            if ((hasInvalidBoolean || hasInvalidIpAddress || inputs.errors?.length)) {
                return false;
            }
            return true;
        }
        return true;
    };

    const [runbookList, setRunbookList] = useState<any[]>([]);

    const fetchRunbooks = () => {
        runbookService.getRunbooks(Variant.ON_DEMAND)
            .then(runbooks => {
                setRunbookList(sortBy(runbooks, runbook => runbook.name.toLowerCase().trim()))
            })
            .catch(error => setRunbookList([]));
    }
    useEffect(fetchRunbooks, []);

    return (
        <>
            <div data-testid="ScheduleRunbook-modal" id="schedule-runbook-modal">
                <MultistepDialog
                    className="schedule-runbook-modal"
                    title="Schedule Runbook"
                    autoFocus
                    canEscapeKeyClose
                    enforceFocus
                    isCloseButtonShown
                    resetOnClose
                    canOutsideClickClose={false}
                    isOpen={isOpen}
                    onClose={handleClose}
                    finalButtonProps={finalButtonProps}
                >
                    <DialogStep
                        id="select"
                        title="Select Runbook"
                        panel={<StepSelect runbookId={runbookID} runbookList={runbookList} setRunbookId={setRunbookID} inputs={inputs} editInfos={editInfos} />}
                        nextButtonProps={{ disabled: !runbookID }}
                    >
                    </DialogStep>
                    <DialogStep
                        id="specify"
                        title="Specify Inputs"
                        panel={<StepSpecify id={runbookID} onJsonInputChange={json => { 
                            if (json) {
                                inputs.current = json;
                                setHasValidInputs(validateInputs(json));
                            }
                        }} currentInputs={inputs} editInfos={editInfos} />}
                        nextButtonProps={{ disabled: !hasValidInputs }}
                    >
                    </DialogStep>
                    <DialogStep
                        id="schedule"
                        title="Schedule"
                        panel={
                            <StepSchedule
                                canEdit={canEdit}
                                setCanEdit={setCanEdit}
                                runbookID={runbookID}
                                inputs={inputs.current}
                                description={description}
                                scheduleType={scheduleType}
                                setScheduleType={setScheduleType} 
                                setScheduleSummary={setSummary} 
                                schedule={schedule} 
                                setSchedule={setSchedule} 
                                editInfos={editInfos}
                                loadingSchedules={loadingSchedules}
                                setLoadingSchedules={setLoadingSchedules}
                            />
                        }
                        backButtonProps={{ disabled: canEdit }}
                        nextButtonProps={{ 
                            disabled: !Object.values(schedule).every(value => value || 
                                [true, false].includes(value as any)) || 
                                canEdit || 
                                (editInfos && loadingSchedules) ||
                                (schedule.repeat === 'WEEK' && schedule.daysOfWeek.length === 0)
                        }}
                    >
                    </DialogStep>
                    <DialogStep
                        id="review"
                        title="Review"
                        panel={<StepReview inputs={inputs} summary={summary} description={description} setDescription={setDescription} />}>
                    </DialogStep>
                </MultistepDialog>
            </div>
        </>
    )
});

export { ScheduleRunbookModal }
