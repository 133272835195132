import React from 'react';
import { Divider, H6 } from '@blueprintjs/core';
import { getRoutes } from 'config';
import { STRINGS, HELP } from 'app-strings';
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { SupportCaseForm } from './SupportCaseForm';
import './Support.scss';


function Support () {
    const link = (
        <a
            href="https://support.riverbed.com/content/support/contact_support.html"
            target="_blank"
            rel="noopener noreferrer"
        >
            <u>{STRINGS.SUPPORT_PAGE.techSupport.linkLabel}</u>
        </a>
    );

    return (
        <PageWithHeader name={Support.name} addPadding={true} showTimeBar={false} showGlobalFilters={false} { ...getRoutes().support }  helpInfo={HELP.supportPage} >
            <div className="w-max-6">
                <H6>{STRINGS.SUPPORT_PAGE.techSupport.caseTitle}</H6>
                <SupportCaseForm />
            </div>
            <Divider className="my-3"/>
            <H6>
                {STRINGS.contact} {link}
            </H6>
        </PageWithHeader>
    );
};

export { Support };