/** This module contains all the types for the visualization library widgets.
 *  @module
 */

import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings"

/** defines the data type enum.*/
export enum WIDGET_TYPE {
    /** the table enumerated type.*/
    TABLE = "table",
     /** the text view enumerated type.*/
    TEXT = "text",
    /** the cards enumerated type.*/
    CARDS = "cards",
    /** the bar chart enumerated type.*/
    BAR = "bar",
    /** the pie chart enumerated type.*/
    PIE = "pie",
    /** the time series enumerated type.*/
    TIMESERIES = "timeseries",
    /** the bubble chart enumerated type.*/
    BUBBLE = "bubble",
    /** the correlation chart enumerated type.*/
    CORRELATION = "correlation",
    /** the gauges enumerated type.*/
    GAUGES = "gauges",
    /** the connection graph enumerated type.*/
    CONNECTION_GRAPH = "connection_graph",
    /** the debug view enumerated type.*/
    DEBUG = "debug",
    /** the enumerated type for the map view. */
    GEO_MAP = "geo_map"
}

/** defines the widget type. */
export type Widget = {
    /** a string with the id of the chart that is attached to this data set. */
    id: string;
    /** a string with the optional name property.*/
    name?: string;
    /** the optional widget type, the back-end should leave this blank, the UI will figure it out based
     *  on the runbook definition.  Examples of widget types are bar, pie, cards, timeseries, etc.*/
    type?: WIDGET_TYPE;
     /** a number with the widget row, it should be a number starting from 1 for the first row and ascending, but there 
     *  is no problem if a number is skipped or if a number repeates.*/
    row?: number;
    /** any chart configuration options. */
    options?: WidgetOptions;
}

/** defines the widget options type. */
export type WidgetOptions = {
    /** a string with the notes that are to be placed to the left of the bar chart. */
    notes?: string;
    /** a string with the notes position which is one of the following: "left" or "right". */
    notesPosition?: string;
    /** allow any string key. */
    [anyKey: string]: string[] | string | number | boolean | undefined;
}

/** this constant maps the widget types to labels. */
export const WIDGET_TYPE_TO_LABEL = {
    [WIDGET_TYPE.BAR]:              STRINGS.visFramework.widgetTypes.barChartLabel,
    [WIDGET_TYPE.PIE]:              STRINGS.visFramework.widgetTypes.pieChartLabel,
    [WIDGET_TYPE.TIMESERIES]:       STRINGS.visFramework.widgetTypes.timeChartLabel,
    [WIDGET_TYPE.BUBBLE]:           STRINGS.visFramework.widgetTypes.bubbleChartLabel,
    [WIDGET_TYPE.CORRELATION]:      STRINGS.visFramework.widgetTypes.correlationChartLabel,
    [WIDGET_TYPE.TABLE]:            STRINGS.visFramework.widgetTypes.tableLabel,
    [WIDGET_TYPE.CONNECTION_GRAPH]: STRINGS.visFramework.widgetTypes.connectionGraphLabel,
    [WIDGET_TYPE.CARDS]:            STRINGS.visFramework.widgetTypes.cardsLabel,
    [WIDGET_TYPE.GAUGES]:           STRINGS.visFramework.widgetTypes.gaugesLabel,
    [WIDGET_TYPE.DEBUG]:            STRINGS.visFramework.widgetTypes.debugLabel,
    [WIDGET_TYPE.GEO_MAP]:          STRINGS.visFramework.widgetTypes.geoMapLabel,
}

/** this constant maps the widget types to icon names. */
export const WIDGET_TYPE_TO_ICON = {
    [WIDGET_TYPE.BAR]:          IconNames.HORIZONTAL_BAR_CHART,
    [WIDGET_TYPE.PIE]:          IconNames.PIE_CHART,
    [WIDGET_TYPE.TIMESERIES]:   IconNames.TIMELINE_LINE_CHART,
    [WIDGET_TYPE.BUBBLE]:       IconNames.HEATMAP,
    [WIDGET_TYPE.CORRELATION]:  IconNames.SCATTER_PLOT,
    [WIDGET_TYPE.TABLE]:        IconNames.TH,
    [WIDGET_TYPE.GEO_MAP]:      IconNames.GLOBE,
}

/** defines the widget configuration type. */
export interface WidgetConfig {
    /** a String with the id of the visualization framework widget. */
    id: string;
    /** a String with the name of the visualization framework widget. */
    name: string;
    /** the type of widget which must be one of the enumerate WIDGET_TYPES. */
    widgetType: WIDGET_TYPE;
    /** any chart configuration options. */
    options?: WidgetOptions;
    /** if the view is interactive this contains the list of interactions for this widget. */
    interactions?: InteractionConfig[];
}

/** defines the interaction configuration type. */
export type InteractionConfig = {
    /** the type of interaction: group or metric, etc. */
    type: INTERACTION_TYPE;
    /** the widgets receiving this interaction. */
    widgetIds: string[];
}

/** this enum defines all the interaction types. */
export enum INTERACTION_TYPE {
    /** the enumerated value for the group interaction type. */
    GROUP   = "GROUP",
    /** the enumerated value for the metric interaction type. */
    METRIC  = "METRIC",
}
