/** This module contains the component for displaying the runbook details page
 *  @module
 */
import React, { useCallback, useEffect, useState } from 'react';
import { STRINGS, HELP } from 'app-strings';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader';
import { SDWAN_ICONS } from 'components/sdwan/enums/icons';
import { ViewCollection } from 'components/common/layout/view-collection/ViewCollection';
import { OneColumnContainer } from 'components/common/layout/containers/one-column-container/OneColumnContainer';
import { PARAM_NAME, INCIDENT_DETAILS_STYLE, IS_EMBEDDED } from 'components/enums/QueryParams';
import { RunbookView } from 'pages/riverbed-advisor/views/runbook-view/RunbookView';
import { PriorityReason, RunbookOutput, RUNBOOK_STATUS, RUNBOOK_STATUS_PROPS, LogEntry } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { IncidentImpactSummaryView } from 'pages/incident-details/views/impact-summary/IncidentImpactSummaryView';
import { IconTitle } from 'components/common/icon-title/IconTitle';
import { useQueryParams } from 'utils/hooks';
import { PRIORITY, PRIORITY_COLORS, SIZE } from 'components/enums';
import { getEntityDescriptionForRunbook, getEntityName } from 'utils/runbooks/EntityUtils';
import { Button, Intent, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';
import { Icon, IconNames, useStateSafePromise } from '@tir-ui/react-components';
import { CardsHolder } from 'components/common/layout/cards-holder/CardsHolder';
import { UsersCard } from 'pages/incident-details/views/impact-summary/cards/UsersCard';
import { SitesCard } from 'pages/incident-details/views/impact-summary/cards/SitesCard';
import { ApplicationsCard } from 'pages/incident-details/views/impact-summary/cards/ApplicationsCard';
import { EntryType, PriorityReasonsView } from 'pages/incident-details/views/priority-reason/PriorityReasonsView';
import { DetailsPanel } from 'components/reporting';
import { BladeContainer } from 'components/common/layout/containers/blade-container/BladeContainer';
import { ImpactSummaryDetailsPane } from 'pages/incident-details/views/impact-summary/panes/ImpactSummaryDetailsPane';
import Markdown from 'markdown-to-jsx';
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { showTitleIcons } from 'utils/runbooks/RunbookUtils';
import { useQuery } from "utils/hooks";
import { PrimitiveVariableType } from 'utils/runbooks/VariablesUtils';
import { ProfileInterface, ThirdPartyIntegrationService } from 'utils/services/ThirdPartyIntegrationApiService';
import { DURATION, durationToRoundedTimeRange } from "utils/stores/GlobalTimeStore";
import { ErrorDialogContent } from 'pages/incident-details/views/runbook-outputs-tab/ErrorDialogContent';
import { BasicDialog, updateDialogState } from 'components/common/basic-dialog/BasicDialog';
import { DebugDialogContent } from 'pages/incident-details/views/runbook-outputs-tab/DebugDialogContent';
import { RunbookBriefBlade } from 'pages/runbook-invocations/views/runbook-brief-blade/RunbookBriefBlade';
import { InputType } from 'components/common/graph/types/GraphTypes';
import { openRunbookNodesTraversedDialog } from "pages/view-runbook/views/view-runbook/openRunbookNodesTraversedDialog";
import { getURL } from 'utils/hooks/useQueryParams';
import { getURLPath } from 'config';
import { useHistory } from 'react-router-dom';
import { RunbookConfig } from 'utils/services/RunbookApiService';
import './RunbookDetailsPage.css';

/** this enum creates an enumeration for all the potential details blade contents */
enum BladeContent {
    /** the constant for the impact summary blade. */
    IMPACT_SUMMARY      = "IMPACT_SUMMARY",
    /** the constant for the priority reasons blade. */
    PRIORITY_REASONS    = "PRIORITY_REASONS"
}

/** the id of the dom element to put the details panel. */
const PANEL_ANCHOR_ELEMENT_ID = "panel-anchor-element";

/** Renders the runbook details page.
 *  @param props the properties passed in.
 *  @returns JSX with the runbook details page component.*/
export default function RunbookDetailsPage(props): JSX.Element {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.runbookId, PARAM_NAME.embed, PARAM_NAME.isRunbookOnDemand] });
    const runbookId: string = params[PARAM_NAME.runbookId];
    const isRunbookOnDemand = !!params[PARAM_NAME.isRunbookOnDemand];
    const [executeSafely] = useStateSafePromise();
    const history = useHistory();

    const [authProfiles, setAuthProfiles] = useState<ProfileInterface[]>();
    const fetchProfiles = useCallback(() => {
        return executeSafely(ThirdPartyIntegrationService.getRunbookAndIntegrationAuthProfiles()).then(
            (response: ProfileInterface[]) => {
                const authProfiles = response.filter(profile => profile.isEnabled).sort((a, b) => a.name.localeCompare(b.name));
                setAuthProfiles(authProfiles);
            },
            _ => {
                setAuthProfiles([]);
            }
        );
    }, [executeSafely]);

    const edgeConfig = useQuery({
        name: 'EdgeConfig',
        query: new Query(loader('../../components/common/graph/editors/subflow/edge-config-list.graphql')),
        queryVariables: {
            ...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
        },
        lazy: IS_EMBEDDED === true
    });

    const [showBlade, setShowBlade] = useState<boolean>(false);
    const [bladeContent, setBladeContent] = useState<BladeContent>(BladeContent.IMPACT_SUMMARY);
    const [impactSummaryDetails, setImpactSummaryDetails] = useState<{icon: string, title: string, subTitle: string, data: string[]} | undefined>(undefined);
    const [priorityReasonsDetails, setPriorityReasonsDetails] = useState<{icon: string, title: string, data: PriorityReason[]} | undefined>(undefined);

    const [dataForBlade, setDataForBlade] = useState<{ runbookId: string } | undefined>(undefined);
    const [runbookData, setRunbookData] = useState<{ runbookId: string, runbookOutput: RunbookOutput } | undefined>(undefined);

    const impactedUsers: Array<string> = runbookData?.runbookOutput?.impactedUsers?.map((item) => {
        return item?.name || item?.deviceName || item?.ipAddress || "";
    }) || [];
    const impactedLocations: Array<string> = runbookData?.runbookOutput?.impactedLocations?.map((item) => {
        return item?.name || "";
    }) || [];
    const impactedApplications: Array<string> = runbookData?.runbookOutput?.impactedApplications?.map((item) => {
        return item?.name || "";
    }) || [];

    const [traversalDialogState, setTraversalDialogState] = useState<any>({ showDialog: false, title: "Runbook Nodes Traversed", loading: false, dialogContent: null, dialogFooter: null });
    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: true, title: STRINGS.viewRunbooks.inputDialogTitle, dialogContent: null, dialogFooter: null});
    const [runbookOutput, setRunbookOutput] = useState<RunbookOutput | undefined>(undefined);

    useEffect(() => {
        // Fetch auth profiles on load.
        fetchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (<>
        <BasicDialog dialogState={dialogState} className="runbook-details-error-dialog" onClose={() => {
            setDialogState(updateDialogState(dialogState, false, false, []));
        }} />
        <BasicDialog dialogState={traversalDialogState} className={"view-runbook-nodes-traversed-dialog"} onClose={() => {
            setTraversalDialogState(updateDialogState(traversalDialogState, false, false, []));
        }} />
        <PageWithHeader name="RunbookDetailsPage"
            title={"Runbook Details for " + JSON.parse(runbookData?.runbookOutput?.template || "{}")?.name /*+ " launched on " + 
                ((runbookData?.runbookOutput as any)?.entity ? getEntityName((runbookData?.runbookOutput as any).entity) : "")*/
            }
            icon={SDWAN_ICONS.TRIGGER} addPadding={true}
            showTimeBar={false} helpInfo={HELP.runbookList}
        >
            <div className="position-absolute w-100 p-0  d-flex flex-row justify-content-end absolute-scrollbar-space">
                <div className={"d-none bg-light shadow border-left runbook-details-side-panel" + (showBlade ? " d-flex" : "")} 
                    id={PANEL_ANCHOR_ELEMENT_ID}>
                </div>
            </div>
            {showBlade && <DetailsPanel floating={true} anchorElement={PANEL_ANCHOR_ELEMENT_ID} visible={false}>
                {bladeContent === BladeContent.IMPACT_SUMMARY &&
                    <BladeContainer
                        className="impact-summary-blade"
                        status={PRIORITY[runbookData?.runbookOutput?.priority || PRIORITY.UNKNOWN]}
                        isPriority={true}
                        icon={impactSummaryDetails?.icon}
                        title={<div className="">{impactSummaryDetails?.title}</div>}
                        onCloseClicked={() => {
                            setShowBlade(false);
                        }}
                        size={SIZE.s}
                        showIconWithoutBg={true}
                        noContentPadding
                    >
                        <div className="p-4"><ImpactSummaryDetailsPane subTitle={impactSummaryDetails?.subTitle || ""} 
                            data={impactSummaryDetails?.data || []} 
                        /></div>
                    </BladeContainer>
                }
                {bladeContent === BladeContent.PRIORITY_REASONS &&
                    <BladeContainer
                        className="priority-reasons-blade"
                        status={PRIORITY[runbookData?.runbookOutput?.priority || PRIORITY.UNKNOWN]}
                        isPriority={true}
                        icon={isRunbookOnDemand ? undefined : priorityReasonsDetails?.icon}
                        title={<div className="">{priorityReasonsDetails?.title}</div>}
                        onCloseClicked={() => {
                            setShowBlade(false);
                        }}
                        size={SIZE.s}
                        showIconWithoutBg={true}
                        noContentPadding
                    >
                        {priorityReasonsDetails?.data?.length && <div className="display-8 p-4"><ul className={"priority-reasons-list" + (runbookData?.runbookOutput?.priority ? " " + runbookData?.runbookOutput?.priority.toLocaleLowerCase() : "")}>{priorityReasonsDetails.data.map((item, index) => {
                            return <li className={"mb-3 priority-reason" + (item.priority ? " " + item.priority.toLocaleLowerCase() : "")} key={"priority-reason-" + index}><Markdown>{item.text}</Markdown></li>;
                        })}</ul></div>}    
                    </BladeContainer>
                }
            </DetailsPanel>}
                <OneColumnContainer key="runbook" noPadding={true} showBackButton={false}
                    backText={
                        <span className="pt-4 pb-4 runbook-output-tab-runbook-bg display-7">
                            Runbook analysis for "{JSON.parse(runbookData?.runbookOutput?.template || "{}")?.name}" launched on 
                            <span style={{marginLeft: "4px"}}>
                                {(runbookData?.runbookOutput as any)?.entity ? getEntityName((runbookData?.runbookOutput as any).entity) : ""}
                            </span>
                        </span>
                    } 
                    onBackClicked={() => {
                        setShowBlade(false);
                        //updateView("invocations", { [PARAM_NAME.incidentId]: "", [PARAM_NAME.runbookId]: "" });
                    }}
                >
                    <br /><br />
                    {false && <IncidentImpactSummaryView key={"incident-impact-" + props.incidentId} incidentId={props.incidentId} className="mt-2" />}
                    <div className="d-flex flex-wrap" >
                        {(!IS_EMBEDDED && !isRunbookOnDemand) && <div className={"incident-impact-summary"}>
                            <IconTitle 
                                icon={showTitleIcons ? SDWAN_ICONS.ALERT : undefined} 
                                title={STRINGS.incidents.impactSummaryView.title} size={SIZE.m}
                                className="mb-2 font-weight-500" 
                            />
                            <CardsHolder className="pb-3 w-max-12">
                                <UsersCard count={impactedUsers.length} data={impactedUsers} 
                                    onDetails={(icon: string, title: string, subTitle: string, data: string[]) => {
                                        setShowBlade(true);
                                        setBladeContent(BladeContent.IMPACT_SUMMARY);
                                        setImpactSummaryDetails({icon, title, subTitle, data});
                                    }}
                                />
                                <SitesCard count={impactedLocations.length} data={impactedLocations}  
                                    onDetails={(icon: string, title: string, subTitle: string, data: string[]) => {
                                        setShowBlade(true);
                                        setBladeContent(BladeContent.IMPACT_SUMMARY);
                                        setImpactSummaryDetails({icon, title, subTitle, data});
                                    }}
                                />
                                <ApplicationsCard count={impactedApplications.length} data={impactedApplications}  
                                    onDetails={(icon: string, title: string, subTitle: string, data: string[]) => {
                                        setShowBlade(true);
                                        setBladeContent(BladeContent.IMPACT_SUMMARY);
                                        setImpactSummaryDetails({icon, title, subTitle, data});
                                    }}
                                />
                            </CardsHolder>
                        </div>}
                        <PriorityReasonsView showTitleIcons={showTitleIcons} runbookOutput={runbookData?.runbookOutput} 
                            type={isRunbookOnDemand || IS_EMBEDDED ? EntryType.INSIGHT : EntryType.PRIORITY}
                            onDetails={(icon: string, title: string, data: PriorityReason[] | LogEntry[]) => {
                                setShowBlade(true);
                                setBladeContent(BladeContent.PRIORITY_REASONS);
                                setPriorityReasonsDetails({icon, title, data: data as PriorityReason[]});
                            }}
                        />
                    </div>
                    <div className="mb-4">
                        <IconTitle 
                            icon={showTitleIcons ? SDWAN_ICONS.TRIGGER: undefined} 
                            title={STRINGS.incidents.runbookTitle} size={SIZE.m} className="mb-2 font-weight-500" 
                        />
                            <ViewCollection activeView="runbook">
                                <div key="runbook" className="runbook-output-tab">
                                {createHeaderWithMoreButton(runbookOutput, renderInputsPopover, setDialogState, setTraversalDialogState, isRunbookOnDemand, history)}
                                <RunbookView
                                    showWidgetToolbar={!isRunbookOnDemand}
                                    //incidentId={incidentId}
                                    runbookId={runbookId}
                                    //onRunbookTemplateAvailable={onRunbookTemplateAvailable}
                                    //onRunbookReceived={onRunbookOutputAvailable}
                                    className="pb-4 runbook-output-tab-runbook-bg"
                                    onRunbookReceived={(runbook) => {
                                        if (!runbookOutput) {
                                            setRunbookOutput(runbook);

                                            // This was returned by the list on the runbook invocations page
                                            const newVars: any = {
                                                //[PARAM_NAME.incidentId]: incidentId,
                                                [PARAM_NAME.runbookId]: runbookId, [PARAM_NAME.rbOutput]: runbook
                                            };
                                            if (/*incidentId &&*/ runbookId) {
                                                setRunbookData({ ...newVars });
                                            }                    
                                        }
                                    }}
                                />
                                </div>
                            </ViewCollection>
                    </div>
                </OneColumnContainer>
            {dataForBlade && <RunbookBriefBlade
                key={"blade-" + dataForBlade?.runbookId}
                description={JSON.parse(dataForBlade[PARAM_NAME.rbOutput]?.template)?.name}
                runbookOutput={dataForBlade[PARAM_NAME.rbOutput]}
                {...{impactedApplications, impactedLocations, impactedUsers}}
                {...dataForBlade}
                onBladeClosed={() => setDataForBlade(undefined)}
            />}
        </PageWithHeader>
    </>);
    /**
     * Render the runbook inputs list
     * 
     * @returns 
     */
    function renderInputsPopover(runbookOutput: RunbookOutput | undefined) {
        const runbookConfig = JSON.parse(runbookOutput?.template || '{}');
        // Get the runtime input variables from the runbook template and then get the run values from the runbook output variables
        const hasVariables = runbookOutput?.variableResults || runbookOutput?.variableResults?.primitiveVariables?.length === 0;
    
        const inputVariablesValues =  hasVariables ? runbookOutput?.variableResults.primitiveVariables.filter(variable => {
            if (!variable?.name) {
                return false;
            }

            return variable.name?.includes("runtime") && !variable.name?.includes("builtin")
        }) : [];
        
        if (!runbookConfig) {
            return;
        }

        const onDemandRunbookInputNode = runbookConfig.nodes?.find(node => node.type === InputType.ON_DEMAND);
        const inputVariables = onDemandRunbookInputNode?.properties.inputVariables.map(variable => {
            const variableWithValue = inputVariablesValues.find((el) => el.name === variable);

            return {
                name: variable,
                value: variableWithValue?.value,
                type: variableWithValue?.type
            }
        });

        return (
            <Popover2
                usePortal
                lazy
                content={getListContent(inputVariables)}
                interactionKind="click"
                placement="bottom-start"
                hoverOpenDelay={500}
                transitionDuration={150}
            >
                {/* eslint-disable-next-line */}
                <a href="#" onClick={(e) => {e.preventDefault()}} className="text-truncate d-inline-block text-underline" style={{ maxWidth: "150px", marginTop: "0.3em" }}>
                    {STRINGS.runbooks.inputsPopup.toggleButton}
                </a>
            </Popover2>
        );

        /**
         * Get the input list content for the Popover
         * 
         * @returns JSX.Elemenbt
         */
        function getListContent(inputVariables: Array<any>): string | JSX.Element | undefined {           
            return <div className='p-4' style={{ minWidth: "300px", maxWidth: "600px" }}>
                <h2 className="mt-2 font-size-md-large font-weight-bold ">{STRINGS.runbooks.inputsPopup.title}</h2>
                <div className="mt-2 py-2 container px-0" style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
                    {inputVariables?.length > 0 ? renderInputs(inputVariables): STRINGS.runbooks.inputsPopup.noInputsMessage}
                </div>
            </div>;
        }

        /**
         * Get the input list item for the Popover
         * 
         * @returns JSX.Elemenbt
         */
        function renderInputs(inputVariables: any): React.ReactNode {
            if (!inputVariables) {
                return;
            }

            return inputVariables.map(function (input, i) {
                const inputName = input.name.replace('runtime.', '');
                let inputDisplayValue = input?.value || '';

                // Get Edge name instead of id
                if (input?.type === PrimitiveVariableType.ALLUVIO_EDGE) {
                    const edge = edgeConfig?.data?.edges?.find(edge => edge?.id === input?.value);
                    inputDisplayValue = edge?.name || inputDisplayValue; // fallback if not found
                }

                // Get auth profile name instead of id
                if (input?.type === PrimitiveVariableType.AUTH_PROFILE) {
                    const authProfile = authProfiles?.find(profile => profile.id === input?.value)
                    inputDisplayValue = authProfile?.name || inputDisplayValue; // fallback if not found
                }

                return <div key={i} className="row my-2" style={{ wordBreak: "break-all" }}>
                    <span className="col">{inputName}</span>
                    <span className="col font-weight-bold"> {inputDisplayValue}</span>
                </div>;
            });
        }
    }
};

/** returns the latest version of the header from Damien's mock-ups.
 *  @param runbookOutput the RunbookOutput object with the full runbook output data.
 *  @returns a JSX.Element with the header content. */
function createHeaderWithMoreButton(
    runbookOutput: RunbookOutput | undefined, renderInputsPopover: (_arg: RunbookOutput | undefined) => JSX.Element | undefined, 
    setDialogState: (dialogState: any) => void, setTraversedDialogState: (dialogState: any) => void,
    isRunbookOnDemand: boolean, history: any
): JSX.Element {
    let runbookModifiedTime;
    let isActive = true, isOneOfTriggerActive = true;
    const entity = runbookOutput?.entity;
    let runbookConfig: RunbookConfig | undefined;

    let hasError = false, hasWarning = false, hasVariables = false, hasInput = false, hasDebug = false;
    if (runbookOutput) {
        if (runbookOutput.datasets) {
            for (const dataset of runbookOutput.datasets) {
                if (dataset.info?.error) {
                    hasError = true;
                    break;
                }
                if (dataset.info?.warning) {
                    hasWarning = true;
                }
                if (dataset.debug) {
                    hasDebug = true;
                }
            }
        }
        if (runbookOutput.errors?.length) {
            hasError = true;
        }
        if (
            runbookOutput.variableResults && 
            (runbookOutput.variableResults.primitiveVariables?.length || runbookOutput.variableResults.structuredVariables?.length)
        ) {
            hasVariables = true;
        }
        if (runbookOutput.triggerInputs) {
            hasInput = true;
        }
    }

    if (runbookOutput?.template) {
        runbookConfig = JSON.parse(runbookOutput.template);
    }

    const moreMenuItems: Array<JSX.Element> = [];
    /*
    moreMenuItems.push(
        <MenuItem disabled={false} text={STRINGS.incidents.runbookOutputs.reRunRunbook} active={false} key={"rerun"} icon={IconNames.REPEAT}
            onClick={async () => {
                ShowToaster({
                    message: STRINGS.viewRunbooks.rerunRunbookNotAvailable,
                    intent: Intent.WARNING
                });
            }} 
        />
    );
    */
    if (!IS_EMBEDDED) {
            moreMenuItems.push(
            <MenuItem disabled={false} text={STRINGS.incidents.runbookOutputs.seeNodesTraversed} active={false} key={"open"} icon={IconNames.GRAPH}
                onClick={() => {
                    openRunbookNodesTraversedDialog(runbookConfig, runbookOutput, setTraversedDialogState);
                }}
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={!runbookOutput} text={STRINGS.incidents.runbookOutputs.editRunbook} active={false} key={"edit"} icon={IconNames.EDIT}
                onClick={() => {
                    if (runbookOutput?.template) {
                        const selectedRunbook = JSON.parse(runbookOutput.template);
                        history.push(getURL(
                            getURLPath("create-runbook"),
                            {   [PARAM_NAME.rbConfigId]: selectedRunbook.id,
                                [PARAM_NAME.rbConfigNm]: selectedRunbook.name,
                                [PARAM_NAME.variant]: "on_demand"
                            },
                            { replaceQueryParams: true}
                        ));    
                    }
                }}
            />
        );    
    }

    const entityElement: JSX.Element = getEntityDescriptionForRunbook(entity, runbookOutput?.mapping?.triggerType);

    return <div className={"d-flex align-items-center runbook-output-tab-runbook-bg runbook-output-dialog-triggers" + (INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" ? " ml-1 mb-2" : " px-2 m-3 rounded")}>
        {entityElement}
        {isRunbookOnDemand && renderInputsPopover(runbookOutput)}
        {(runbookModifiedTime || !isActive || !isOneOfTriggerActive) && <div className="d-flex flex-wrap justify-content-center flex-grow-1 align-items-center">
            <Icon icon={IconNames.WARNING_SIGN} iconSize={14} className="mr-2" style={{color: (!isOneOfTriggerActive ? PRIORITY_COLORS[PRIORITY.CRITICAL] : PRIORITY_COLORS[PRIORITY.MODERATE])}}/>
            {runbookModifiedTime && <span className="mr-2">{STRINGS.formatString(STRINGS.incidents.runbookOutputs.modifiedWarning, runbookModifiedTime)}</span>}
            {(!isActive && isOneOfTriggerActive) && <span className="mr-2">{STRINGS.incidents.runbookOutputs.switchWarning}</span>}
            {(!isActive && !isOneOfTriggerActive) && <span>{STRINGS.incidents.runbookOutputs.noActiveError1}
                <Button minimal disabled={false} className="" text={<span className="btn-link">{STRINGS.incidents.runbookOutputs.noActiveError2}</span>} 
                    onClick={() => console.log("view runbook")} 
                    style={{paddingLeft: "4px", paddingRight: "4px", paddingBottom: "8px"}}
                />
                {STRINGS.incidents.runbookOutputs.noActiveError3}
            </span>}
        </div>}
        <div className="d-flex flex-wrap justify-content-end flex-grow-1 align-items-center">
            {hasError && runbookOutput && <Icon className="mr-2" icon={IconNames.ERROR} intent={Intent.DANGER} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            {hasWarning && !hasError && runbookOutput?.datasets?.length && <Icon className="mr-2" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            {!hasWarning && !hasError && (hasVariables || hasInput) && runbookOutput && <Icon className="mr-2" icon={IconNames.INFO_SIGN} intent={Intent.SUCCESS} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            <span className="mr-2">{STRINGS.incidents.runbookOutputs.runbookLastRun}:</span>
            <span className="pr-2 font-weight-bold" >{RUNBOOK_STATUS_PROPS[runbookOutput?.status || RUNBOOK_STATUS.UNKNOWN]?.label}</span>
            {hasDebug && runbookOutput?.datasets?.length && <Icon className="ml-2" icon={IconNames.DIAGNOSIS} onClick={() => {
                showDebugDialog(runbookOutput, setDialogState);
            }} style={{cursor: "pointer"}} />}
            <div onClick={(e) => {e.stopPropagation();}}>
                <Popover2 position={Position.BOTTOM_RIGHT} 
                    interactionKind={Popover2InteractionKind.CLICK} 
                    content={
                        <Menu>{moreMenuItems}</Menu>
                } >
                    <Button aria-label="incident-details-runbook-output-more-button" 
                        icon={IconNames.MORE} minimal className="incident-details-runbook-output-action-icon ml-2" 
                        disabled={false} onClick={(e) => {}} 
                    />
                </Popover2>
            </div>
        </div>
    </div>;
}

/** Creates a popup that displays the runbook error information.
 *  @param runbookOutput the output from the runbook.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param debug a boolean value, if true show debug information, if false, do not. */
function showErrorAndWarningDialog(
    runbook: RunbookOutput, setDialogState: (dialogState: any) => void, debug: boolean
): void {
    const dialogState: any = {showDialog: true, loading: false, title: STRINGS.incidents.runbookOutputs.errorDialogTitle};
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <ErrorDialogContent runbook={runbook} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the runbook debug information.
 *  @param runbookOutput the output from the runbook.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    runbook: RunbookOutput, setDialogState: (dialogState: any) => void
): void {
    let json = undefined;
    const dialogState: any = { showDialog: true, loading: false, title: STRINGS.incidents.runbookOutputs.debugDialogTitle };
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <DebugDialogContent runbook={runbook} onJsonOutputChanged={(jsOutput) => { json = jsOutput }} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.copyBtnText} onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(json));
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}
