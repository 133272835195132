/** This module contains the component for displaying the list of filters for the faceted search.  The
 *  filters include the facets as well as the time filter.
 *  @module
 */
import React from "react";
import { Button, HTMLSelect, Intent, Tag } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { TimePeriodMoreView } from "components/common/time-period-more-view/TimePeriodMoreView";
import { TIME_OPTION_VALUES } from "components/common/time-range-selector/TimeRangeSelector";
import { CHART_SERIES_COLORS, FACET_FILTER_COLORS } from "components/enums";
import { DURATION, TIME_RANGE } from "utils/hooks";
import { formatTimeToUserFriendlyString } from "reporting-infrastructure/utils/formatters/date-formatter/DateFormatter";
import { TIME_DURATION } from "utils/stores/GlobalTimeStore";
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";
import { SearchQueryPreference } from "utils/services/UserPrefsTypes";
import { FacetValue } from "utils/services/SearchApiService";

/** This interface defines the properties passed into the filter view React component.*/
interface FilterViewProps {
    /** the classname to apply to the div for the component. */
    className?: string;
    /** the list of select facets. */
    selectedFacets?: Record<string, Array<FacetValue>>;
    /** the time range that is currently selected.  Undefined means no time range. */
    timeRange?: Partial<TIME_RANGE & TIME_DURATION> | undefined;
    /** the current SEARCH_TYPE. */
    type?: SEARCH_TYPE;
    /** the array of search queries, if any. */
    searchQueries?: SearchQueryPreference[];
    /** a boolean value, which if true specifies that the type control should be displayed, if false or omitted do not display the control. */
    showTypeControl?: boolean;
    /** a boolean value, which if true specifies that the query control should be displayed, if false or omitted do not display the control. */
    showQueryControl?: boolean;
    /** the handler for time range changes. */
    onTimeChange?: (timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined) => void;
    /** the handler for facet selection changes. */
    onFacetSelected?: (facetValue: FacetValue, facetCategory: string, selected: boolean) => void;
    /** the handler to clear all filters. */
    clearAllFilters?: () => void;
    /** the handler for search type changes. */
    onTypeChange?: (searchType: SEARCH_TYPE) => void;
    /** the handler for load query events. */
    loadQuery?: (queryName: string) => void;
    /** the handler for query save requests. */
    querySaveRequested?: (queryName: string) => void;
    /** the handler for query delete requests. */
    queryDeleteRequested?: (queryName: string) => void;
}

/** Renders the filter view.
 *  @param props the properties passed in.
 *  @returns JSX with the filter view component.*/
const FilterView = (props: FilterViewProps) => {
    let timeValue: string | undefined = props.timeRange ? formatTimeToUserFriendlyString(props.timeRange) : undefined;

    const filtersJsx: Array<JSX.Element> = [];
    if (props.selectedFacets) {
        for (const facetCategory in props.selectedFacets) {
            const facetCategoryName = STRINGS.incidentSearch.facetView.facets[facetCategory]?.name || facetCategory;
            let tagGroup: JSX.Element[] = [];
            for (const facet of props.selectedFacets[facetCategory]) {
                const label = facet;
                const value = label + "";
                const displayName = STRINGS.incidentSearch?.facetView?.facets[facetCategory] && STRINGS.incidentSearch?.facetView?.facets[facetCategory].valueDisplayMap &&
                    STRINGS.incidentSearch?.facetView?.facets[facetCategory].valueDisplayMap[value]
                    ? STRINGS.incidentSearch?.facetView?.facets[facetCategory].valueDisplayMap[value] : label;
                tagGroup.push(
                    <Tag key={facetCategoryName + "_" + label}
                        style={{ backgroundColor: FACET_FILTER_COLORS[facetCategory] }}
                        // TODO: is the ternary necessary now? It'll always be
                        // true
                        className={"filter-tag ml-1 my-1 py-1 px-2 align-middle" + (true ? "" : " opacity-non-essential")}
                        title={value} 
                        onRemove={() => {
                            if (props.onFacetSelected) {
                                props.onFacetSelected(facet, facetCategory, false);
                            }
                        }}
                    >{displayName || STRINGS.incidentSearch.facetView.facetXcases.Unset}</Tag>
                );
            }
            filtersJsx.push(
                <span data-testid="FilterView-Pill" className="spacer ml-2" key={"tags-" + facetCategoryName}>
                    <span className="text-secondary">{facetCategoryName} =</span>
                    {tagGroup}
                </span>
            );
        }
    }

    return <div className={"d-flex justify-content-between w-100" + (props.className ? " " + props.className : "")}>
        <div data-testid="FilterView-PillContainer" className="left-controls">
            {props.showTypeControl && 
            <HTMLSelect
                className="mr-1"
                value={props.type}
                onChange={e => {
                    if (props.onTypeChange) {
                        props.onTypeChange(e.currentTarget.value as SEARCH_TYPE);
                    }
                }}
                data-testid="status-selector"
            >
                <option label="Incidents" value="incident"/>
                <option label="Devices" value="device"/>
                <option label="Interfaces" value="interface"/>
                <option label="Applications" value="application"/>
                <option label="Locations" value="location"/>
                <option label="Custom Properties" value="properties"/>
            </HTMLSelect>}
            {filtersJsx}
            {props.timeRange && 
            <span className="spacer ml-2" key="tags-time">
                <span className="text-secondary">{STRINGS.globalFilters.filterLabels.time} =</span>
                <Tag key="time-value"
                    style={{ backgroundColor: CHART_SERIES_COLORS[0] }}
                    className="ml-1 my-1 py-1 px-2 align-middle"
                    title={timeValue}
                    data-type="time"
                    icon={<Icon icon={IconNames.TIME} />}
                    onRemove={() => {
                        if (props.onTimeChange) {
                            props.onTimeChange(undefined);
                        }
                    }}
                >{timeValue}</Tag>
            </span>}
            {(props.timeRange || filtersJsx.length > 0) &&
                <Button key="clear-filter-btn" minimal small 
                    intent={Intent.DANGER} 
                    className="ml-2 btn-link" 
                    text={STRINGS.globalFilters.clearAll} 
                    onClick={() => {
                        if (props.clearAllFilters) {
                            props.clearAllFilters();
                        }
                    }} 
                />
            }
        </div>

        {props.showQueryControl && (props.type === SEARCH_TYPE.incident || props.type === SEARCH_TYPE.tcpconnection || props.type === SEARCH_TYPE.ondemandrunbooks) &&
            <div className="right-controls w-min-1-5">
                <TimePeriodMoreView
                    showInline={false}
                    sideBySideLayout={true}
                    dontFilterOptionsBasedOnScreenSize
                    showTimePeriodOptions={[
                        DURATION.HOUR_1,
                        DURATION.HOUR_2,
                        DURATION.HOUR_12,
                        DURATION.DAY_1,
                        DURATION.DAY_2,
                        DURATION.DAY_7,
                        DURATION.LAST_1_MONTH,
                    ]}
                    durationSelectionHandler={(option, timeRange) => {
                        if (option === TIME_OPTION_VALUES.CUSTOM) {
                            if (timeRange) {
                                if (props.onTimeChange) {
                                    props.onTimeChange(timeRange);
                                }
                            }
                        } else {
                            if (props.onTimeChange) {
                                props.onTimeChange({duration: option});
                            }
                        }
                    }}
                    timeOption={props.timeRange}
                    moreIcon={IconNames.CALENDAR}
                />
        </div>}
    </div>;
};

export default FilterView;

