/** This module contains the editor for the aggregator node.  The aggregator node
 *  is used to group data returned by a data ocean or other node that produces data.
 *  @module
 */
import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { Callout, HTMLSelect, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { NodeDef } from '../../types/GraphTypes';
import { UniversalNode } from '../../UniversalNode';
import { SimpleNodeEditorProps } from '../simple/SimpleNodeEditor';
import { AggregateNodeUtil } from './AggregatorNodeUtils';
import { STRINGS } from 'app-strings';
import { dataOceanNodes, subflowNodes, transformNodes } from 'utils/runbooks/RunbookUtils';
import { useStateSafePromise } from 'utils/hooks';
import { AGGREGATOR_NODE_EDIT_PROPS } from './AggregatorNodeUtils';
import { AggregateField, AggregateFieldsControl, FunctionTemplate, UpdateEventType, UpdateEvent, OriginalField } from './AggregateFieldsControl';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { Context, RunbookContext } from 'utils/runbooks/RunbookContext.class';
import { RunbookContextSummary } from '../RunbookContextSummary';
import { DataOceanMetadata } from '../data-ocean/DataOceanMetadata.type';
import { SHOW_CONTEXT } from 'components/enums/QueryParams';
import { DataOceanUtils } from '../data-ocean/DataOceanUtils';
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';

/** this interface defines the properties that are passed in to the aggregator node editor. */
interface AggregatorNodeProperties {
    groupByMode: string;
    groupBy?: string;
    aggregators?: AggregateField<FunctionTemplate>[];
}
/**Interface that describes a Runbook sythn field attributes. This is persisted as part of the runbook template*/
interface RBSynthField {
    id: string,
    label: string,
    type: string,
    function: string,
    included: boolean,
    originalField: RBOriginalField,
}
/**Interface that describes an original field based on which a synth field is generated.
 *  This is persisted as part of the runbook template*/
interface RBOriginalField {
    id: string,
    label: string,
    isKey: boolean,
    [k: string]: any
}
interface RBSynthMetric extends RBSynthField {
    unit?: string
}
/** All supported aggreate functions .*/
export enum AggregatorFunction {
    MAX = "max",
    MIN = "min",
    AVG = "avg",
    SUM = "sum",
    COUNT = "count"
}

/** Types of Fields.*/
export enum FIELD_TYPE {
    KEY = "KEY",
    METRIC = "METRIC"
}
/** Metric field aggregate option and their labels .*/
export const metricAggregatorOptions: FunctionTemplate[] = [
    {
        id: AggregatorFunction.MAX,
        label: "max"
    },
    {
        id: AggregatorFunction.MIN,
        label: "min"
    },
    {
        id: AggregatorFunction.AVG,
        label: "average"
    },
    {
        id: AggregatorFunction.SUM,
        label: "sum"
    }
]
/** Key field aggregate option and their labels .*/
export const keyAggregatorOptions = [
    {
        id: AggregatorFunction.COUNT,
        label: "count"
    },
]

/** Component for editing the properties of an Aggregator node
 *  @param selectedNode - Currently selected active aggregator node
 *  @param libraryNode- Selected aggregator node's meata data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @return  JSX component. */
export const AggregatorNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef }: SimpleNodeEditorProps, ref: any) => {
    let selNodeId = selectedNode?.getId();
    selNodeId = selNodeId ? selNodeId : "";
    // Immediate parent Dataocean node properties
    const doOrXformNodeNode = AggregateNodeUtil.getParentNode(selNodeId, graphDef, [...dataOceanNodes, ...transformNodes, ...subflowNodes]);
    const editorDisabled = doOrXformNodeNode ? false : true;
    const GROUP_BY_DEFAULT_OPTIONS = {
        ALL_ROWS: "allRows",
        UNSELECTED: ""
    }
    const enum GROUP_BY_MODE {
        ALL = "ALL",
        KEY = "KEY",
    }
    const SYNTH_FIELD_PREFIX = "agg_";
    // If existing runbook get groupBy info from the selectedNode else default it.
    const groupByFromSelNode = selectedNode?.getProperty(AGGREGATOR_NODE_EDIT_PROPS.GROUP_BY) ? selectedNode?.getProperty(AGGREGATOR_NODE_EDIT_PROPS.GROUP_BY) : GROUP_BY_DEFAULT_OPTIONS.UNSELECTED;
    //  State objects
    // Meta data about the object types, list of metrics and keys.
    const [executeSafely] = useStateSafePromise();
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [curProperties, setCurProperties] = useState<AggregatorNodeProperties>({
        groupBy: groupByFromSelNode?.length && groupByFromSelNode[0] ? groupByFromSelNode[0] : "",
        groupByMode: selectedNode?.getProperty(AGGREGATOR_NODE_EDIT_PROPS.GROUP_BY_MODE)
    });

    const runbookContext = useRef<RunbookContext | undefined>();

    const customProperties = useContext(CustomPropertyContext);

    // Meta data about the various input types and the key/metrics associated with them.
    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                setObjMetricMetaData(response);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely]
    );

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData])

    useEffect(() => {
        if (selectedNode && graphDef && objMetricMetaData) {
            runbookContext.current = new RunbookContext(
                selectedNode?.node as NodeDef, graphDef, objMetricMetaData, customProperties
            );
        }
    }, [selectedNode, graphDef, objMetricMetaData, customProperties])

    useEffect(() => {
        if (selectedNode && objMetricMetaData) {
            let gByList = selectedNode.getProperty(AGGREGATOR_NODE_EDIT_PROPS.GROUP_BY);
            let gBy = GROUP_BY_DEFAULT_OPTIONS.UNSELECTED;
            let gByMode = GROUP_BY_MODE.ALL;
            if (gByList && gByList.length > 0 && gByList[0]) {
                gBy = gByList[0];
                gByMode = GROUP_BY_MODE.KEY;
            }
            setCurProperties({
                groupByMode: gByMode,
                groupBy: gBy,
                aggregators: convertRBSynthFieldsToAggregateFields()
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, objMetricMetaData])

    /** Translates all Runbook synthetic fields stored in the runbook template to Aggreate field format. 
     *  AggregateField object decouples the Runbook fields from the  components that render them.
     *  @returns  AggregateField<FunctionTemplate>[]. */
    function convertRBSynthFieldsToAggregateFields(): AggregateField<FunctionTemplate>[] {
        const rbSynthMetrics: RBSynthMetric[] = selectedNode?.getProperty(AGGREGATOR_NODE_EDIT_PROPS.SYNTH_METRICS);
        let rbSynthMetricMap: { [k: string]: RBSynthMetric } = rbSynthMetrics?.reduce((accum, item) => {
            accum[item.originalField.id] = item;
            return accum;
        }, {})
        rbSynthMetricMap = rbSynthMetricMap ? rbSynthMetricMap : {};
        let doSynthFields: AggregateField<FunctionTemplate>[] = generateAggFieldsFromDONode();
        // The list of metric/key aggregators should be all metrics/keys selected in the parent DO node
        // The values needs to be overriden by the synth fields that are presisted in the runbook
        const consolidatedAggregators = doSynthFields.map((doSynthField: AggregateField<FunctionTemplate>) => {
            let fieldType: FIELD_TYPE = FIELD_TYPE.KEY;
            let rbSynthField: RBSynthMetric | undefined;
            let rbOriginalField: OriginalField = { id: "", label: "", isKey: false };
            let aggregateFuncs;
            if (rbSynthMetricMap[doSynthField.originalField.id]) {
                rbSynthField = rbSynthMetricMap[doSynthField.originalField.id];
                rbOriginalField = rbSynthField.originalField
                fieldType = rbOriginalField.isKey ? FIELD_TYPE.KEY : FIELD_TYPE.METRIC;
                aggregateFuncs = rbOriginalField.isKey ? keyAggregatorOptions : metricAggregatorOptions;
            }
            if (rbSynthField) {
                return {
                    id: `${SYNTH_FIELD_PREFIX}${rbOriginalField.id}`,
                    label: rbSynthField.label,
                    aggregatorFunction: rbSynthField.function,
                    aggregatorFunctionOptions: aggregateFuncs,
                    originalField: rbOriginalField,
                    included: rbSynthField.included,
                    userModified: false,
                    fieldType: fieldType
                }
            }
            return doSynthField;
        })
        return consolidatedAggregators;
    }

    /** Translates all AggreateField to Runbook synth fileds format. this is persisted in the runbook template. 
     *  AggregateField object decouples the Runbook fields from the  components that render them.
     *  @param cprops Properties in selected node that need to updated.
     *  @returns  RBSynthMetric[] .*/
    function convertAggregateFieldsToRBSynthFields(cprops: AggregatorNodeProperties) {
        let rbSynthMetrics: RBSynthMetric[] = [];
        let keysMap: Record<string, any> = {};
        let metricsMap:  Record<string, any> = {};
        if (doOrXformNodeNode && runbookContext.current) {
            let contexts: Array<Context> = runbookContext.current.getNodeContexts();
            const expandedKeys = contexts[contexts.length -  1].expandedKeys || [];
            const metrics = contexts[contexts.length -  1].metrics || [];
            keysMap = expandedKeys?.reduce((accum, item) => {
                accum[item.id] = item;
                return accum;
            }, {});
            metrics.forEach(metric => {
                metricsMap[metric.id] = metric;
            });
        }
        cprops.aggregators?.forEach((field: AggregateField<FunctionTemplate>) => {
            let origField = field.originalField;
            let metricMeta = field.fieldType === FIELD_TYPE.METRIC ? metricsMap[origField.id] : keysMap[origField.id];
            // currently the only operator for key is count which is of type integer
            let metricType = field.fieldType === FIELD_TYPE.METRIC ? metricMeta.type : "integer"
            let unit = field.fieldType === FIELD_TYPE.METRIC ? metricMeta.unit : "none";
            rbSynthMetrics.push(
                {
                    id: field.id,
                    label: field.label,
                    type: metricType,
                    function: field.aggregatorFunction,
                    included: field.included,
                    originalField: field.originalField,
                    unit: unit
                });
        })
        return { rbSynthMetrics }
    }

    /** Update the selected node porperties. This is invoked when done button is clicked
     *  on the node editor dialog.
     *  @param properties -  Properties in selected node that need to updated
     *  @param selectedNode - node being editied
     *  @param libraryNode - object that specifies all the editable properties for a given node. */
    function updateNode(properties: AggregatorNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        let selNodeId = selectedNode?.getId();
        selNodeId = selNodeId ? selNodeId : "";
        const parentDoOrXformNode = AggregateNodeUtil.getParentNode(selNodeId, graphDef, [...dataOceanNodes, ...transformNodes, ...subflowNodes]);
        if (!parentDoOrXformNode) {
            return;
        }
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        let newGroupBy = [properties.groupBy];
        if (properties.groupByMode === GROUP_BY_MODE.ALL) {
            newGroupBy = [];
        }
        selectedNode.setProperty(AGGREGATOR_NODE_EDIT_PROPS.GROUP_BY, newGroupBy);
        const { rbSynthMetrics } = convertAggregateFieldsToRBSynthFields(properties);
        selectedNode.setProperty(AGGREGATOR_NODE_EDIT_PROPS.SYNTH_METRICS, rbSynthMetrics);
    }

    function getNodeProperties(properties: AggregatorNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        let selNodeId = selectedNode?.getId();
        selNodeId = selNodeId ? selNodeId : "";
        const parentDoOrXformNode = AggregateNodeUtil.getParentNode(selNodeId, graphDef, [...dataOceanNodes, ...transformNodes, ...subflowNodes]);
        if (!parentDoOrXformNode) {
            return;
        }
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        let newGroupBy = [properties.groupBy];
        if (properties.groupByMode === GROUP_BY_MODE.ALL) {
            newGroupBy = [];
        }

        const outputProperties = {};
        outputProperties[AGGREGATOR_NODE_EDIT_PROPS.GROUP_BY] = newGroupBy;
        const { rbSynthMetrics } = convertAggregateFieldsToRBSynthFields(properties);
        outputProperties[AGGREGATOR_NODE_EDIT_PROPS.SYNTH_METRICS] = rbSynthMetrics;
        return outputProperties;
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            if (curProperties.groupByMode === GROUP_BY_MODE.KEY) {
                if (!curProperties.groupBy || curProperties.groupBy === "unselected") {
                    errorMessages.push(STRINGS.runbookEditor.errors.aggregateNode.groupByKey);
                }
            }
            let metricSelected = false;
            curProperties?.aggregators?.forEach((field: AggregateField<FunctionTemplate>) => {
                metricSelected = field.included ? true : metricSelected;
            })
            if (!metricSelected) {
                errorMessages.push(STRINGS.runbookEditor.errors.aggregateNode.noMetric);
            }

            return errorMessages;
        }
    };

    /** Utility function to fetch a specific aggregate filed form the curProperties state object.
     *  @param id : indentifier of the filed that being requested for.
     *  @returns   AggregateField<FunctionTemplate> | undefined. */
    function getFieldFromCurrentProperties(id: string | number): AggregateField<FunctionTemplate> | undefined {
        return curProperties?.aggregators?.find((agg) => {
            return agg.id === id;
        })
    }

    function getGroupByKeyOptions() {
        if (doOrXformNodeNode && runbookContext.current) {
            let contexts: Array<Context> = runbookContext.current.getNodeContexts();
            const expandedKeys = (contexts[contexts.length -  1].expandedKeys || []).filter((key) => key.hidden !== true);
            let groupByOptions = expandedKeys.map((key) => {
                return <option key={key.id} label={key.label} value={key.id} />
            })
            return groupByOptions;
        }
    }

    function getLabelForKey(objMetricMetaData, key) {
        let label = "";
        if (objMetricMetaData && key) {
            label = objMetricMetaData.obj_types[key]?.label;
        }
        return label;
    }

    /** Event handler that handles events triggered when an aggregate field is edited.
     *  @param event : UpdateEvent triggered by the AggregateFieldControl component.
     *  @returns void. */
    function handleAggregateFieldSelectorChange(event: UpdateEvent) {
        switch (event.type) {
            case UpdateEventType.SELECTION_CHANGED: {
                const aggField = getFieldFromCurrentProperties(event.id);
                if (aggField) {
                    aggField.included = event.value;
                }
                setCurProperties({ ...curProperties });
                break;
            }
            case UpdateEventType.NAME_CHANGED: {
                const aggField = getFieldFromCurrentProperties(event.id);
                if (aggField) {
                    aggField.label = event.value;
                    aggField.userModified = true;
                }
                setCurProperties({ ...curProperties });
                break;
            }
            case UpdateEventType.AGG_FUNCTION_CHANGED: {
                const aggField = getFieldFromCurrentProperties(event.id);
                if (aggField) {
                    aggField.aggregatorFunction = event.value;
                    aggField.label = `${aggField.originalField.label} (${aggField.aggregatorFunction})`
                }
                setCurProperties({ ...curProperties });
                break;
            }
        }
    }

    /** Generate Aggregator fields based on the metrics and keys selected in tha data ocean node.
     *  @returns AggregateField<FunctionTemplate>[]. */
    function generateAggFieldsFromDONode(): AggregateField<FunctionTemplate>[] {
        if (doOrXformNodeNode && runbookContext.current) {
            let contexts: Array<Context> = runbookContext.current.getNodeContexts();
            const expandedKeys = (contexts[contexts.length -  1].expandedKeys || []).filter((key) => key.hidden !== true);
            const metrics = contexts[contexts.length -  1].metrics || [];
            let normalizedMetrics: Array<any> = [];
            let normalizedKeys: Array<any> = [];
            if (metrics) {
                normalizedMetrics = metrics.map((metricMeta) => {
                    const label = `${metricMeta.label} (${AggregatorFunction.AVG})`;
                    return {
                        id: `${SYNTH_FIELD_PREFIX}${metricMeta.id}`,
                        label: label,
                        aggregatorFunction: AggregatorFunction.AVG,
                        aggregatorFunctionOptions: metricAggregatorOptions,
                        originalField: { id: metricMeta.id, label: metricMeta.label, isKey: false },
                        included: true,
                        userModified: false,
                        fieldType: FIELD_TYPE.METRIC
                    }
                });
            }
            normalizedKeys = expandedKeys.map((key) => {
                const label = `${key.label} (${AggregatorFunction.COUNT})`;
                return {
                    id: `${SYNTH_FIELD_PREFIX}${key.id}`,
                    label: label,
                    aggregatorFunction: AggregatorFunction.COUNT,
                    aggregatorFunctionOptions: keyAggregatorOptions,
                    originalField: { id: key.id, label: key.label, isKey: true },
                    included: false,
                    userModified: false,
                    fieldType: FIELD_TYPE.KEY
                }
            });
            return [...normalizedMetrics, ...normalizedKeys];
        }
        return [];
    }

    const doInputType = AggregateNodeUtil.getPropertyFromNode("objType", doOrXformNodeNode)?.value;

    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData) {
        return <tr><td colSpan={2}><DataLoadFacade loading/></td></tr>;
    }

    return (
        <>
            {editorDisabled &&
                <tr><td colSpan={4}> <Callout intent={Intent.WARNING} className='d-flex align-items-center pt-3 pb-3'><span className='pl-3 display-9'>{STRINGS.runbookEditor.nodeEditor.unconnectedNodeMessage1}</span> </Callout></td></tr>
            }
            <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.grouping}</td></tr>
            <tr>
                <td colSpan={2}>
                    <div className="d-flex flex-row pt-3">
                        <div className='flex-nowrap align-self-center'>Group <b>{`${getLabelForKey(objMetricMetaData, doInputType)}`}:</b></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <RadioGroup
                        name="groupByMode"
                        onChange={e => {
                            curProperties.groupByMode = e.currentTarget.value
                            setCurProperties({ ...curProperties })
                        }}
                        selectedValue={curProperties.groupByMode ? curProperties.groupByMode : GROUP_BY_MODE.ALL}
                        inline={true}
                        disabled={editorDisabled}
                        className="pl-4 align-self-center"
                    >
                        <Radio
                            label={STRINGS.runbookEditor.nodeEditor.allTogether}
                            value={GROUP_BY_MODE.ALL}
                            className="mb-0 d-block"
                        />
                        <Radio
                            data-testid="data_ocean_time_series"
                            label={STRINGS.runbookEditor.nodeEditor.by}
                            value={GROUP_BY_MODE.KEY} className="mb-0 pt-2"
                        />
                        <HTMLSelect
                            aria-label="group by"
                            name="groupBy"
                            fill={false}
                            disabled={editorDisabled || curProperties.groupByMode !== GROUP_BY_MODE.KEY}
                            onChange={e => {
                                curProperties.groupBy = e.currentTarget.value;
                                setCurProperties({ ...curProperties })
                            }}
                            value={curProperties.groupBy}
                        >
                            <option key={GROUP_BY_DEFAULT_OPTIONS.UNSELECTED} label={STRINGS.runbookEditor.nodeEditor.defDropDownOption} value={undefined} />
                            {getGroupByKeyOptions()}
                        </HTMLSelect>
                    </RadioGroup>
                </td>
            </tr>
            <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.metrics}</td></tr>
            <tr>
                <td className="p-1 pt-4 pb-3" colSpan={2}>
                    <AggregateFieldsControl onChange={(e) => { handleAggregateFieldSelectorChange(e) }} aggregateFields={curProperties.aggregators} disabled={editorDisabled} />
                </td>
            </tr>
            {SHOW_CONTEXT && <RunbookContextSummary 
                currentProperties={getNodeProperties(curProperties, selectedNode, libraryNode)} showMetrics={SHOW_CONTEXT}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={true}
            />}
        </>
    );
});
