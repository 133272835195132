import { IconNames } from "@tir-ui/react-components";
import { STRINGS, HELP } from "app-strings";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import React from "react";
import { ApiAccessListView } from "./views/ApiAccessListView";

/** Renders the api access list page.
 *  @param props the properties passed in.
 *  @returns JSX with the api access list page.*/
const ApiAccessPage = (props): JSX.Element => {

    return (
        <PageWithHeader 
            name="ApiAccessPage" title={STRINGS.apiAccess.title} icon={IconNames.KEY} addPadding={true} showTimeBar={false}
            helpInfo={HELP.apiAccess}
        >
            <ApiAccessListView/>
        </PageWithHeader>
    );
};

export default ApiAccessPage;
