/** This file defines the SankeyChart React component.  The SankeyChart React component renders a
 *  a Sankey diagram using highcharts.
 *  @module */
import React, { useCallback, useRef, useState } from "react";
import { Classes, Dialog } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from "highcharts/modules/sankey";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import OfflineExporting from "highcharts/modules/offline-exporting";
import fullscreen from "highcharts/modules/full-screen";
import merge from "lodash/merge";
import { BaseChartProps } from "components/common/chart-base/ChartBase";
import { SankeyChartSettings } from "components/common/chart-base/ChartToolbar";
import "./SankeyChart.scss";

// This is needed to enable the highcharts no data functionality
HighchartsSankey(Highcharts);
fullscreen(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

/** an interface that describes the sankey data point format. */
export interface SankeyChartDataPoint {
    id: string;
    from: string;
    to: string;
    weight: number;
    name?: string;
    color?: string;
}

/** an interface that describes the sankey data format. */
export interface SankeyChartData extends Array<SankeyChartDataPoint> {}

/** an interface that describes the properties that can be passed in to the sankey chart component.*/
export interface SankeyChartProps extends BaseChartProps {
    /** the sankey chart data. */
    chartData?: SankeyChartData;
    /** a String with the optional title. */
    title?: string;
    /** a boolean value, if true show the sankey chart vertically. */
    vertical?: boolean;
    /** the SankeyChartSettings object with the basic settings for the chart such as the from label and to label. */
    settings?: SankeyChartSettings;
    /** the handler for the on node clicked event. */
    onNodeClicked?: (payload: { id?: string; name?: string }) => void;
    /** the handler for the on path clicked event. */
    onPathClicked?: (payload: {
        id?: string;
        name?: string;
        from?: string;
        to?: string;
    }) => void;
}

/** Creates the the sankey chart view.
 *  @param sankeyProps an object with the properties passed to the sankey chart view.
 *  @returns JSX with the sankey chart component.*/
export function SankeyChart(sankeyProps: SankeyChartProps) {
    const { vertical = false, ...props } = sankeyProps;
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    const handleClose = useCallback(() => setIsOpen(false), []);

    const { fromLabel, toLabel } = props.settings || {};

    function getChartOptions() {
        let config = {
            chart: {
                reflow: true,
                backgroundColor: "transparent",
                style: {
                    fontFamily: "arial",
                },
                inverted: vertical,
            },
            exporting: {
                enabled: true,
                filename: `${props.fullScreenTitle ? props.fullScreenTitle : "sankey"}-riverbed`,
                buttons: {
                    contextButton: {
                        menuItems: [
                            {
                                text: STRINGS.chartToolbar.toggleFullScreen,
                                onclick: () => {
                                    handleOpen();
                                },
                            },
                            "downloadCSV",
                            "downloadPNG",
                        ],
                    },
                },
                chartOptions: {
                    chart: {
                        backgroundColor: "#fff",
                    },
                },
            },
            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    type: "sankey",
                    nodeWidth: vertical ? 30 : 100,
                    minLinkWidth: 3,
                    dataLabels: {
                        allowOverlap: true,
                        style: {
                            // necessary for PNG output to look readable
                            textOutline: "none",
                            fontFamily: "open sans",
                            fontWeight: "normal",
                            fontSize: "12px",
                            groupPadding: 0.01,
                            pointPadding: 0.25,
                        },
                    },
                    animation: { easing: "easeOut" },
                    data: props.chartData || [],
                    point: {
                        events: {
                            mouseOver: function (e) {
                                const point = e?.target;
                                let pathsToHighlight: {
                                    [x: string]: boolean;
                                } | null = null;
                                // If user hovers over an individual path element
                                if (point?.shapeType === "path") {
                                    // If path ID was provided in chart data for the complete path
                                    if (point.options?.id) {
                                        // Add the path ID to pathsToHighlight map so that the entire path will be highlighted from start to end
                                        pathsToHighlight = {
                                            [point.options?.id]: true,
                                        };
                                    }
                                    // If user hovers over a node between the several paths
                                } else if (point?.isNode) {
                                    // Build the pathsToHighlight object with IDs of all the paths that are entering the node so that
                                    // all paths that are passing through this node get highlighted end-to-end
                                    pathsToHighlight = point.linksFrom.reduce(
                                        (output, link) => {
                                            if (link.options?.id) {
                                                output[link.options.id] = true;
                                            }
                                            return output;
                                        },
                                        {},
                                    );
                                }

                                if (pathsToHighlight !== null) {
                                    // loop through all the sub-path elements
                                    const allPoints = point.series?.points;
                                    for (const p of allPoints) {
                                        // If the path has an ID value matching one of the paths to be highlighted
                                        // then set it's state to 'hover' so that highcharts will highlight it.
                                        // Removing the hover state when mouse moves out will be automatically
                                        // taken care of by highcharts.
                                        if (pathsToHighlight[p.options?.id]) {
                                            p.setState("hover");
                                        }
                                    }
                                }
                            },
                            click: (event) => {
                                const elementOptions = event?.point?.options;
                                if (elementOptions) {
                                    if (elementOptions.isNode) {
                                        if (props.onNodeClicked) {
                                            props.onNodeClicked({
                                                id: elementOptions.id,
                                                name: elementOptions.name,
                                            });
                                        }
                                    } else if (props.onPathClicked) {
                                        if (props.onPathClicked) {
                                            props.onPathClicked({
                                                id: elementOptions.id,
                                                name: elementOptions.name,
                                                from: elementOptions.from,
                                                to: elementOptions.to,
                                            });
                                        }
                                    }
                                }
                            },
                        },
                    },
                },
            ],
            tooltip: {
                formatter: function (this: any) {
                    return (
                        this.point.from +
                        " - " +
                        this.point.to +
                        " (" +
                        this.point.weight +
                        ")"
                    );
                },
            },
        };
        if (props.options) {
            merge(config, props.options);
        }
        return config;
    }

    const getChart = (popup: boolean = false) => {
        return (
            <div
                className={
                    popup
                        ? Classes.DIALOG_BODY
                        : `chart-widget sankey-widget d-flex flex-row justify-content-center ${
                              props.className ? props.className : ""
                          }`
                }
            >
                <div className="flex-column flex-grow-1 w-100">
                    {props.title && (
                        <div className="flex-row text-center d-flex justify-content-center position-relative">
                            <h5 aria-label="title">{props.title}</h5>
                        </div>
                    )}
                    {fromLabel && toLabel && (
                        <div className="d-flex justify-content-between mx-4 mb-3 mt-2 font-weight-600 display-9">
                            <span className="d-inline-block w-0-5 text-center">
                                {fromLabel}
                            </span>
                            <span className="d-inline-block w-0-5 text-center">
                                {toLabel}
                            </span>
                        </div>
                    )}
                    <div
                        className={
                            "flex-row flex-grow-1 w-min-4" +
                            (props.onNodeClicked ? " node-clickable" : "") +
                            (props.onPathClicked ? " path-clickable" : "")
                        }
                    >
                        {props.chartData && (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={getChartOptions()}
                                containerProps={{
                                    style: {
                                        width: props.width
                                            ? props.width
                                            : "100%",
                                        height: popup
                                            ? 0.9 * window.innerHeight -
                                              100 +
                                              "px"
                                            : props.height
                                              ? props.height
                                              : "100%",
                                        padding: popup ? "10px" : "",
                                    },
                                }}
                                ref={chartRef}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Dialog
                title={props.fullScreenTitle ? props.fullScreenTitle : ""}
                isOpen={isOpen}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                onClose={handleClose}
                style={{
                    width: 0.75 * window.innerWidth,
                    height: 0.9 * window.innerHeight,
                }}
            >
                {getChart(true)}
            </Dialog>
            {getChart(false)}
        </>
    );
}
