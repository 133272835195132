import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { LangEN, STRINGS } from 'app-strings';
import { Divider, Button } from '@blueprintjs/core';
import * as yup from 'yup';
import { Classes, IconNames } from '@tir-ui/react-components';
import { Form, InputField, TextAreaField, SelectField } from 'components/common/form';
import { ClientCertificateSource } from 'utils/services/ThirdPartyIntegrationApiService';
import { Icon } from "@blueprintjs/core";
import { Tooltip2 } from '@blueprintjs/popover2';

import './ClientCertificateParamsPanel.scss';
import { FormikProps } from 'formik';

export interface ClientCertificateParamsPanelProps {
    isEdit: boolean;
    clientCertificateError: boolean;
    clientCertificateSource: ClientCertificateSource;
    clientCertificateInPemFormat: string | undefined;
    clientPrivateKey: string | undefined;
    passphraseForPemFormat: string | undefined;
    onChangeClientCertificateSource: (event) => void;
    onChangeClientCertificateInPemFormatFromTextField: (event) => void;
    onChangeClientCertificateInPemFormatFromFile: (event) => void;
    onChangeClientPrivateKeyFromTextField: (event) => void;
    onChangeClientPrivateKeyFromFile: (event) => void;
    onChangePassphraseForPemFormat: (event) => void;
}


const ClientCertificateParamsPanel = (props: ClientCertificateParamsPanelProps) => {
    const hiddenUploadCertificateFileInput = useRef<HTMLInputElement>(null); 
    const hiddenUploadPrivateKeyFileInput = useRef<HTMLInputElement>(null); 
    const [revealPrivateKeyField, setRevealPrivateKeyField] = useState<boolean>(false);
    const translations:LangEN["thirdPartyIntegrations"]["addAuthProfile"]["panels"]["clientCertificateAuthParams"] = STRINGS.thirdPartyIntegrations.addAuthProfile.panels.clientCertificateAuthParams;
    const { 
        isEdit,
        clientCertificateError,
        clientCertificateSource,
        onChangeClientCertificateSource,
        clientCertificateInPemFormat,
        onChangeClientCertificateInPemFormatFromTextField,
        onChangeClientCertificateInPemFormatFromFile,
        clientPrivateKey,
        onChangeClientPrivateKeyFromTextField,
        onChangeClientPrivateKeyFromFile,
        passphraseForPemFormat,
        onChangePassphraseForPemFormat
    } = props;

    useEffect(() => {
        if (isEdit) { // revisit this check when implementing the PFX certificate auth method
            onChangeClientCertificateSource({
                target: {
                    value: 'ClientCertificateUsingPemFormat'
                }
            })
        }
        if (clientCertificateError) {
            setRevealPrivateKeyField(true);
        }
    }, [isEdit, onChangeClientCertificateSource, clientPrivateKey, clientCertificateError]);

    const clientCertificateSources: Array<{ label: string, value: ClientCertificateSource }> = [{
        label: 'Certificate and Private Key',
        value: 'ClientCertificateUsingPemFormat'
    }];

    const validationSchema = yup.object().shape({
        grant_type: yup.string(),
        client_certificate_in_pem_format:  
            yup
                .string()
                .required()
                .label(translations.fields.clientCertificateUsingPemFormat.label),
        client_private_key: isEdit ? 
            yup
                .string()
                .label(translations.fields.clientPrivateKey.label):
            yup
                .string()
                .required()
                .label(translations.fields.clientPrivateKey.label),
        certificate_and_private_key_passphrase: yup
            .string()
            .label(translations.fields.passphrase.label),
    });

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p>
                <b>
                    {
                        translations.title
                    }
                </b>
            </p>
            <Divider />
            <Form
                className='mt-2'
                initialValues={{
                    client_certificate_in_pem_format: clientCertificateInPemFormat,
                    client_private_key: clientPrivateKey,
                    passphrase_for_pem_format: passphraseForPemFormat
                }}
                validationSchema={validationSchema}
                loading={false}
            >
                {(formProps: FormikProps<object>) => <>
                <SelectField 
                    name="clientCertificateSource"
                    label={ translations.fields.clientCertificateSource.label }
                    onChange={onChangeClientCertificateSource}
                    value={clientCertificateSource}
                >
                        <option disabled={true} value="">
                            { translations.fields.clientCertificateSource.placeholder }
                        </option>
                    {clientCertificateSources?.map((type, i) => <option key={i} value={type.value}>{type.label}</option>)}
                </SelectField>
                { clientCertificateSource === 'ClientCertificateUsingPemFormat' && renderFieldsForPemFormat(formProps) }
                </>}
            </Form>
        </div>
    );

    /**
     * Render the fields for ClientCertificateUsingPemFormat source type
     * 
     * @returns {JSX}
     */
    function renderFieldsForPemFormat(formProps: FormikProps<object>) {
        return (<>
            <div className="authentication-parameters-field-wrap mt-4">
                <TextAreaField
                    name="client_certificate_in_pem_format"
                    required={true}
                    label={ translations.fields.clientCertificateUsingPemFormat.label }
                    placeholder={ translations.fields.clientCertificateUsingPemFormat.placeholder }
                    onBlur={(event) => {
                        const value = event.target.value?.trim();

                        formProps.handleBlur(event);
                        props.onChangeClientCertificateInPemFormatFromTextField && props.onChangeClientCertificateInPemFormatFromTextField({target: {
                            value: value
                        }});
                    }}
                    onChange={onChangeClientCertificateInPemFormatFromTextField}
                    value={clientCertificateInPemFormat}
                    disabled={false}
                />
                <Tooltip2 content={translations.tooltip} placement="right" usePortal={false}>
                    <Icon icon={IconNames.HELP} />
                </Tooltip2>
                <div className="authentication-parameters-upload-field-wrap">
                    <Button intent="primary" onClick={() => { hiddenUploadCertificateFileInput.current?.click(); }} type="button">{translations.upload}</Button>
                    <input type="file" ref={hiddenUploadCertificateFileInput} onChange={onChangeClientCertificateInPemFormatFromFile} />
                </div>
            </div>
            <div className="authentication-parameters-field-wrap client-private-key mt-4">
                {(!isEdit || revealPrivateKeyField) && <TextAreaField
                    name="client_private_key"
                    required={!isEdit}
                    label={ translations.fields.clientPrivateKey.label }
                    placeholder={
                        isEdit ? translations.fields.clientPrivateKey.placeholderForEdit : translations.fields.clientPrivateKey.placeholder
                    }
                    onBlur={(event) => {
                        const value = event.target.value?.trim();
    
                        formProps.handleBlur(event);
                        props.onChangeClientPrivateKeyFromTextField && props.onChangeClientPrivateKeyFromTextField({target: {
                            value: value
                        }});
                    }}
                    onChange={onChangeClientPrivateKeyFromTextField}
                    value={clientPrivateKey}
                    disabled={false}
                />}
                {(!isEdit || (isEdit && revealPrivateKeyField)) && 
                    <Tooltip2 content={translations.tooltip} placement="right" usePortal={false}>
                        <Icon icon={IconNames.HELP} />
                    </Tooltip2>}
                {isEdit && !revealPrivateKeyField && 
                    <div className="privateKeyClearedHeading">
                        { translations.fields.clientPrivateKey.labelWhenClear }
                        <span><Icon icon="tick-circle" size={17} intent="success" /> {translations.uploaded}</span>
                        <Button intent="primary" onClick={() => {
                            setRevealPrivateKeyField(true);
                        }} type="button">{translations.clear}</Button>
                    </div>
                }
                <div className="authentication-parameters-upload-field-wrap">
                    {(!isEdit || revealPrivateKeyField) && <Button intent="primary" onClick={() => { hiddenUploadPrivateKeyFileInput.current?.click(); }} type="button">{translations.upload}</Button>}
                    <input type="file" ref={hiddenUploadPrivateKeyFileInput} onChange={onChangeClientPrivateKeyFromFile} />
                </div>
            </div>
            <InputField
                name="passphrase_for_pem_format"
                type="password"
                required={false}
                label={
                    translations.fields.passphrase.label
                }
                placeholder={
                    isEdit ?
                        translations.fields.passphrase.placeholderForEdit:
                        translations.fields.passphrase.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    props.onChangePassphraseForPemFormat && props.onChangePassphraseForPemFormat({target: {
                        value: value
                    }});
                }}
                onChange={onChangePassphraseForPemFormat}
                value={passphraseForPemFormat}
                disabled={false}
                showPasswordOption={true}
            />
        </>);
    }
};

export { ClientCertificateParamsPanel };
