/** This module contains the MappingService
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';

/** this type defines a MappingConfig. */
export type MappingConfig = {
	id: string;
    order: number;
    expression: string;
	trigger: string;
	runbookId: string;
	name: string;
	description: string;
	enabled: boolean;
	runbookName: string;
	lastUpdatedBy: string;
	lastUpdatedOn: string;
	expression: string;
	order: number;
	/** a catch-all for any additional properies of the mapping. */
	[anyOtherKey: string]: any;
};

// The URL for the API server.
export const MAPPING_CONFIG_BASE_URL = '/api/affogato/';

/** this class defines the MappingApiService. */
class MappingConfigApiService extends ApiService {
	/** the constructor for the class. */
	constructor() {
		super(MAPPING_CONFIG_BASE_URL);
	}

	/** returns the base uri, this can be overridden in subclasses to allow the uri to change
	 *      after construction.
	 *  @returns a String with the base uri. */
	protected getBaseUri(): string {
		if (ApiService.USE_REGION) {
			const region = ApiService.AUTH_SERVICE.getRegion();
			return `/api/iq/${region}/`;
		} else {
			return this.baseApiUri;
		}
	}

	/** returns all mappings.
	 *  @returns a Promise which resolves to the returned mappings. */
	getMappings(): Promise<MappingConfig> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.get<MappingConfig>(`automation/1.0/tenants/${utid}/mappings`)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** updates an existing mapping.
	 *  @param id a String with the id of the runbook.
	 *  @param payload Mapping object
	 *  @param etag a String with the etag.
	 *  @returns a Promise. */
	updateMapping(id: string, payload: any, etag: string | undefined): Promise<string> {
		return new Promise((resolve, reject) => {
			const tenantId = ApiService.AUTH_SERVICE.getTenantId();
			super
				.put<string>(
					`/automation/1.0/tenants/${tenantId}/mappings/${id}`,
					payload,
					undefined,
					undefined,
					etag
				)
				.then(
					(response: any) => {
						resolve(response);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}
}

/** a constant with an instance of the MappingApiService. */
const MappingConfigService = new MappingConfigApiService();
export { MappingConfigService };
