/** This module contains utilities for editing trigger nodes and validating trigger nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef } from "../../types/GraphTypes";
import { NodeUtils } from "../../../../../utils/runbooks/NodeUtil";
import { getProperties } from "utils/runbooks/RunbookUtils";
import { TimeReferenceOptions } from "./TriggerNodeEditor";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";

/** an enum with all of the valid trigger node properties. */
export enum TRIGGER_NODE_EDIT_PROPS {
    /** the time reference property. */
    TIME_REFERENCE = "timeReference",
    /** the time offset property. */
    TIME_OFFSET = "timeOffset",
    /** the enumerated value for the debug property. */
    DEBUG = 'debug',
}

/** Utility class for trigger node,*/
export class TriggerNodeUtils extends NodeUtils {
    /** the error messages for the trigger node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.triggerNode;

    /** Check if a trigger node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes. 
     *  @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables);
        this.validateNodeProperties(getProperties(curNode), errors);
    }

    /** Validates the property values for a given trigger node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        currentProperties: Record<string, any>, errors: Array<string>
    ): Array<string> {
        if (currentProperties.timeReference === TimeReferenceOptions.PRIMARY_INDICATOR && currentProperties.timeOffset === undefined) {
            errors.push(TriggerNodeUtils.errMsgs.timeOffset);
        }
        return errors;
    }
}
